import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, renameNodeGroup } from '../../redux/model/actions';
import NodeIdTextField from '../NodeIdTextField/NodeIdTextField';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const EditNodeGroupModal = ({ groupId }) => {
	const [inputConf, setInputConf] = useState({});
	const dispatch = useDispatch();

	const handleEdit = () => {
		if (inputConf.validation || isEmpty(inputConf)) {
			return;
		}

		dispatch(registerStateBeforeDispatchingAction(renameNodeGroup(groupId, inputConf.nodeId)));
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleEdit();
		}
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.editNodeGroup} />}
				saveBtnTitle={<FormattedMessage {...messages.editNodeGroup} />}
				onSave={handleEdit}
			>
				<NodeIdTextField
					autoFocus
					nodeId={groupId}
					caretPosition={groupId.length}
					onKeyPress={handleKeyPress}
					label={<FormattedMessage {...messages.groupName} />}
					handleChange={setInputConf}
				/>
			</SaveCloseModalTemplate>
		</div>
	);
};

EditNodeGroupModal.propTypes = {
	groupId: PropTypes.string,
};

export default EditNodeGroupModal;
