import * as Sentry from '@sentry/react';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { clone, filter, flatten, merge, mergeAll, omit, pick, remove } from 'ramda';
import { notificationTypes } from '../../constants';
import { PROPERTY_TYPES, RESPONSE_TYPES, TRANSITION_TYPES } from '../../core/configs';
import { STT_BOOST } from '../../core/configs/consts';
import { rsaaAuthFormData } from '../../core/requests';
import { INTL, messages } from '../../intl';
import { createActionsMap, makeActionCreator } from '../../utils/action-utils';
import isNilOrEmpty from '../../utils/isNilOrEmpty';
import { createModel, deleteImage, fetchModel, saveModelVersion } from '../model-table/actions';
import {
	getSelectedModelName,
	selectedModelId as selectActiveModelId,
	selectedModelVersionId as selectedModelVersionIdSelector,
	getSelectedModel,
	getModelAssets,
} from '../model-table/selectors';
import { showNotification } from '../notification/actions';
import { cud, psf } from '../utils';
import { DEFAULT_NODE_GROUP_DATA } from './reducers';
import {
	getActiveNodeGroup,
	getActiveNodeGroupId,
	getActiveNodeId,
	getAllDiagramGroupsNames,
	getConfiguration,
	getDisplayClosestNodesOnly,
	getGlobalStartNodeId,
	getIntent,
	getIntentIds,
	getIsModelLocked,
	getNode,
	getNodeGroup,
	getNodeGroups,
	getNodeGroupsForDiagram,
	getNodeIds,
	getNodeOutputtedMarkdownOptions,
	getNodeResponsesByType,
	getNodes,
	getNodeVariables,
	getProjectData,
	getProjectName,
	getRawYaml,
	getSelectedNodes,
	getYamlErrors,
	ID,
} from './selectors';
import {
	appendDuplicitySuffix,
	formatImageMarkdown,
	getNearbyDiagramPosition,
	hasTooManyNodes,
	matchNodeIdWithTemplateColor,
	sortNodeResponsesAndMarkdownOptions,
} from './utils';

export const actionTypes = createActionsMap(ID, [
	'COPY_SELECTED_NODES',
	'CREATE_CONDITION',
	'CREATE_UPDATE_CONFIG_PROPERTY',
	'CREATE_UPDATE_NODE',
	'CREATE_UPDATE_SMART_FUNCTION',
	'CREATE_UPDATE_TRANSITION_ACTION',
	'CREATE_UPDATE_TRANSITION_GOTO',
	'CREATE_UPDATE_TRANSITION_GOTO_REUSE_UTTERANCE',
	'CREATE_UPDATE_TRANSITION_SIGNAL',
	'CREATE_UPDATE_VARIABLE',
	'CREATE_UPDATE_VARIABLES_TO_RESET',
	'REMOVE_NODE',
	'REPLACE_NODE_IN_TRANSITIONS',
	'REMOVE_NODE_GROUPS',
	'REMOVE_NODES_FROM_GROUP',
	'REPLACE_NODE_GROUPS',
	'REMOVE_PREVIOUS_MODEL',
	'REMOVE_RESPONSE',
	'REMOVE_SMART_FUNCTION',
	'REMOVE_VARIABLE',
	'REMOVE_VARIABLES_TO_RESET',
	'SET_YAML_ERROR_WARNING',
	'REMOVE_YAML_ERROR_WARNING',
	'RENAME_VARIABLE',
	'CREATE_NODE_GROUP',
	'SET_GROUP_START_NODE',
	'SET_ACTIVE_NODE',
	'ADD_TO_SELECTED_NODES',
	'REMOVE_FROM_SELECTED_NODES',
	'CLEAR_SELECTED_NODES',
	'REGISTER_STATE_HISTORY',
	'UNDO_STATE_HISTORY',
	'REDO_STATE_HISTORY',
	'SET_CANVAS_HEADER_HEIGHT',
	'SET_DIAGRAM_POSITIONS',
	'SET_NODE_COLOR',
	'SET_SHOW_COLOR_CAPTIONS',
	'SET_COLOR_CAPTIONS',
	'SET_VOCABULARY',
	'SET_STOPWORDS',
	'REMOVE_COLOR_CAPTION',
	'REFRESH_COLOR_CAPTIONS',
	'SET_PROJECT_DATA',
	'IMPORT_FROM_JSON',
	'SET_START_GROUP',
	'SET_YAML_UI_CONFIG',
	'SET_GLOBAL_START_NODE',
	'TOGGLE_CREATE_GROUP',
	'TOGGLE_DISPLAY_CLOSEST_NODES_ONLY',
	'TOGGLE_GROUP_VIEW',
	'TOGGLE_LOCK_MODEL',
	'TOGGLE_NODES_VISIBILITY',
	'SET_ACTIVE_NODE_GROUP',
	'UPDATE_ALL_NODE_GROUPS',
	'UPDATE_CANVAS_OFFSET',
	'UPDATE_CANVAS_ZOOM',
	'UPDATE_CLOSEST_NODES_TARGET',
	'UPDATE_NODE_GROUP',
	'UPDATE_REMOVE_CONDITION',
	'UPDATE_YAML_DATA',
	'SET_ZOOM_LEVEL',
	...cud('INTENT'),
	...cud('TRANSITIONS'),
	...cud('UTTERANCE'),
	...psf('GET_IMAGE'),
	...psf('UPLOAD_IMAGE'),
	...psf('AUTO_POSITION_NODES'),
]);

// PROJECT CONTROLS: Actions regarding configuration and versions of the project
export const setProjectData = makeActionCreator(actionTypes.SET_PROJECT_DATA, 'data');
export const importFromJson = makeActionCreator(actionTypes.IMPORT_FROM_JSON, 'data');
export const createUpdateConfigProperty = makeActionCreator(actionTypes.CREATE_UPDATE_CONFIG_PROPERTY, 'path', 'value');
export const updateYamlData = makeActionCreator(actionTypes.UPDATE_YAML_DATA, 'rawYaml');
export const setYamlEditorUiConfig = makeActionCreator(actionTypes.SET_YAML_UI_CONFIG, 'config');
export const removePreviousModel = makeActionCreator(actionTypes.REMOVE_PREVIOUS_MODEL);

export const backupModelVersion = () => (dispatch, getState) => {
	const state = getState();
	const projectName = getProjectName(state) ?? new Date().toISOString().replace(/:/g, '-');
	const data = JSON.stringify(getProjectData(state), null, 3);

	const element = document.createElement('a');
	element.href = URL.createObjectURL(new Blob([data], { type: 'application/json' }));
	element.download = `${projectName}.json`;
	element.click();
};

/**
 * In larger handlers, it is more readable to call this right at the start of the function since it should be recorded only once for each ui operation and not each store action.
 */
export const registerStateHistory = makeActionCreator(actionTypes.REGISTER_STATE_HISTORY, 'state');

/**
 * Only a few actions that are triggered by the user and operate with the nodes and nodeGroups should be registered.
 * To be able to undo actions, we need to register the state before the whole batch of actions and not after each individual step.
 * Manually wrapping the actions before dispatch provides the necessary granularity because sometimes the actions should be possible to undo and sometimes not (e.g. when being invoked by another action)
 */
export const registerStateBeforeDispatchingAction = (action) => {
	return function thisWouldNormallyBeTheActionItself(dispatch) {
		dispatch(registerStateHistory());
		dispatch(action);
	};
};

export const undoStateHistory = makeActionCreator(actionTypes.UNDO_STATE_HISTORY, 'state');
export const redoStateHistory = makeActionCreator(actionTypes.REDO_STATE_HISTORY, 'state');

// DIAGRAM CONTROLS: Actions related to how the nodes are displayed in the flow editor
export const _toggleDisplayClosestNodesOnly = makeActionCreator(actionTypes.TOGGLE_DISPLAY_CLOSEST_NODES_ONLY, 'bool');
export const _toggleGroupView = makeActionCreator(actionTypes.TOGGLE_GROUP_VIEW, 'bool');
export const setActiveNodeGroup = makeActionCreator(actionTypes.SET_ACTIVE_NODE_GROUP, 'groupId');
export const setActiveNode = makeActionCreator(actionTypes.SET_ACTIVE_NODE, 'id');

export const setDiagramPositions = makeActionCreator(actionTypes.SET_DIAGRAM_POSITIONS, 'positions');
export const setZoomLevel = makeActionCreator(actionTypes.SET_ZOOM_LEVEL, 'zoomLevel');
export const setCanvasHeaderHeight = makeActionCreator(actionTypes.SET_CANVAS_HEADER_HEIGHT, 'value');
export const updateCanvasZoom = makeActionCreator(actionTypes.UPDATE_CANVAS_ZOOM, 'data');
export const updateCanvasOffset = makeActionCreator(actionTypes.UPDATE_CANVAS_OFFSET, 'canvasOffset', 'groupId');
export const toggleLockModel = makeActionCreator(actionTypes.TOGGLE_LOCK_MODEL, 'isModelLocked');

export const setManualDiagramPositions = (positions) => (dispatch, getState) => {
	// Changing a node's position in closestNodesDisplay should not overwrite the manually set positions in the normal view
	if (!getDisplayClosestNodesOnly(getState())) {
		dispatch(setDiagramPositions(positions));
	}
};

export const showActiveNodeGroup = (groupId) => (dispatch, getState) => {
	const state = getState();

	dispatch(showActiveNode(null));
	dispatch(clearSelectedNodes());
	dispatch(setActiveNodeGroup(groupId));

	if (getDisplayClosestNodesOnly(state)) {
		if (groupId) {
			const nodeGroup = getNodeGroup(groupId, state);
			dispatch(updateClosestNodesTarget(nodeGroup.startNode));
		} else {
			const firstNodeGroup = getAllDiagramGroupsNames(state)[0];
			dispatch(updateClosestNodesTarget(firstNodeGroup));
		}
	}
};

export const toggleDisplayClosestNodesOnly = (toggleOn) => (dispatch, getState) => {
	const state = getState();
	const activeNodeId = getActiveNodeId(state);
	const activeNodeGroupId = getActiveNodeGroupId(state);

	if (toggleOn) {
		if (!activeNodeGroupId) {
			const nodeGroupId = getAllDiagramGroupsNames(state)[0];
			dispatch(updateClosestNodesTarget(nodeGroupId));
		} else if (activeNodeGroupId && !activeNodeId) {
			const activeNodeGroup = getActiveNodeGroup(state);

			dispatch(updateClosestNodesTarget(activeNodeGroup.startNode));
		}
	}

	dispatch(_toggleDisplayClosestNodesOnly(toggleOn));
};

export const setShowColorCaptions = makeActionCreator(actionTypes.SET_SHOW_COLOR_CAPTIONS, 'showColorCaptions');
export const setColorCaptions = makeActionCreator(actionTypes.SET_COLOR_CAPTIONS, 'captions');
export const removeColorCaption = makeActionCreator(actionTypes.REMOVE_COLOR_CAPTION, 'color');
export const refreshColorCaptions = makeActionCreator(actionTypes.REFRESH_COLOR_CAPTIONS);

export const setVocabulary = makeActionCreator(actionTypes.SET_VOCABULARY, 'vocabulary');
export const setStopwords = makeActionCreator(actionTypes.SET_STOPWORDS, 'stopwords');

// NODE: Actions for creating, updating, deleting nodes
export const setGlobalStartNode = makeActionCreator(actionTypes.SET_GLOBAL_START_NODE, 'id');
export const _copySelectedNodes = makeActionCreator(actionTypes.COPY_SELECTED_NODES, 'selectedNodes');
export const _removeNode = makeActionCreator(actionTypes.REMOVE_NODE, 'nodeId');

export const removeNodesFromGroup = makeActionCreator(actionTypes.REMOVE_NODES_FROM_GROUP, 'nodeIds');
export const toggleNodeVisibility = makeActionCreator(actionTypes.TOGGLE_NODES_VISIBILITY, 'nodeId');
export const createUpdateNode = makeActionCreator(actionTypes.CREATE_UPDATE_NODE, 'id', 'node');
export const addToSelectedNodes = makeActionCreator(actionTypes.ADD_TO_SELECTED_NODES, 'ids');
export const removeFromSelectedNodes = makeActionCreator(actionTypes.REMOVE_FROM_SELECTED_NODES, 'id');
export const clearSelectedNodes = makeActionCreator(actionTypes.CLEAR_SELECTED_NODES);
export const setNodeColor = makeActionCreator(actionTypes.SET_NODE_COLOR, 'color');
export const updateClosestNodesTarget = makeActionCreator(actionTypes.UPDATE_CLOSEST_NODES_TARGET, 'nodeId');
export const replaceNodeInTransitions = makeActionCreator(
	actionTypes.REPLACE_NODE_IN_TRANSITIONS,
	'nodeId',
	'newNodeId',
	'filterFunction'
);

export const copySelectedNodes = () => (dispatch, getState) => {
	const state = getState();
	const selectedNodes = getSelectedNodes(state);
	// Nodes get unselected first, then the newly copied ones are selected instead
	dispatch(clearSelectedNodes());
	dispatch(_copySelectedNodes(selectedNodes));
};

export const updateReferencesToNode = (newNodeId, prevNodeId, showActiveNodeAfterRename = true) => (
	dispatch,
	getState
) => {
	if (newNodeId === prevNodeId) {
		return;
	}

	// If newNodeId matches a preset template, change its color accordingly
	const templateColor = matchNodeIdWithTemplateColor(newNodeId);
	if (templateColor) {
		const node = getNode(prevNodeId, getState());
		if (node) {
			dispatch(createUpdateNode(prevNodeId, { ...node, color: templateColor }));
		}
	}

	let projectData = JSON.stringify(getProjectData(getState()));
	projectData = projectData.replace(new RegExp('"' + prevNodeId + '"', 'g'), '"' + newNodeId + '"');
	projectData = JSON.parse(projectData);

	dispatch(setProjectData(projectData));
	if (showActiveNodeAfterRename) {
		dispatch(setActiveNode(newNodeId));
	}
};

export const showActiveNode = (nodeId) => (dispatch, getState) => {
	const state = getState();
	const node = getNode(nodeId, state);
	const activeNodeGroupId = getActiveNodeGroupId(state);

	if (!isNilOrEmpty(nodeId) && !activeNodeGroupId) {
		dispatch(updateClosestNodesTarget(nodeId));
	}
	if (!getIsModelLocked(state)) {
		if (node) {
			dispatch(setActiveNodeGroup(node.group));
		}
		dispatch(setActiveNode(nodeId));
	}
};

export const removeNode = (nodeId, isDeletingNodeGroup = false) => (dispatch, getState) => {
	const state = getState();

	if (nodeId === getGlobalStartNodeId(state)) {
		dispatch(showNotification(INTL.formatMessage(messages.removeGlobalStartNodeNotification), notificationTypes.ERROR));
		return;
	}

	const node = getNode(nodeId, state);

	if (node.group) {
		const group = clone(getNodeGroup(node.group, state));

		// Cannot remove the starting node unless the whole group is deleted at the same time
		if (group.startNode === nodeId && !isDeletingNodeGroup) {
			dispatch(
				showNotification(INTL.formatMessage(messages.removeGroupStartNodeNotification), notificationTypes.ERROR)
			);
			return;
		}

		// Remove the reference to the node in its former group
		group.nodes = group.nodes.filter((groupNodeId) => groupNodeId !== nodeId);
		dispatch(updateNodeGroup(group));
	}

	if (nodeId === getActiveNodeId(state)) {
		dispatch(showActiveNode(null));
	}

	dispatch(_removeNode(nodeId));
};

/**
 * Creates new node and possibly select it.
 * In case it is a first node, set it as start node.
 */
export const createNode = ({
	newNodeId = 'RENAME_NEW_NODE',
	shouldShowActiveNode = true,
	baseNodeId = '',
	newNodeProperties = {},
}) => (dispatch, getState) => {
	const state = getState();
	const activeNodeGroup = getActiveNodeGroup(state);
	const globalStartNodeId = getGlobalStartNodeId(state);
	const hasStartNode = getNode(globalStartNodeId, state);

	let newNodeName = activeNodeGroup ? `${activeNodeGroup.name}_${newNodeId}` : newNodeId;

	// prevent nodeId duplicity
	newNodeName = appendDuplicitySuffix(newNodeName, getNodeIds(state));

	// set node as the start node if there is none
	if (!hasStartNode) {
		dispatch(setGlobalStartNode(newNodeName));
	}

	// add to diagram group if we are in groupView mode
	if (activeNodeGroup) {
		dispatch(
			updateNodeGroup({
				...activeNodeGroup,
				startNode: activeNodeGroup.startNode ? activeNodeGroup.startNode : newNodeName,
				nodes: [...activeNodeGroup.nodes, newNodeName],
			})
		);
		newNodeProperties = { ...newNodeProperties, group: activeNodeGroup.name };
	}

	// set manual position
	dispatch(
		setDiagramPositions({
			[newNodeName]: { diagramPosition: getNearbyDiagramPosition(baseNodeId || globalStartNodeId, state) },
		})
	);

	// create new node in model
	dispatch(createUpdateNode(newNodeName, newNodeProperties));

	// select node if it should be
	if (shouldShowActiveNode) {
		dispatch(addToSelectedNodes([newNodeName]));
		dispatch(showActiveNode(newNodeName));
	}
};

export const splitNode = (nodeId, shouldShowActiveNode = false) => (dispatch, getState) => {
	const state = getState();
	const originalNode = getNode(nodeId, state);
	let splittedNodeId = nodeId + INTL.formatMessage(messages.splittedNodeTail);

	// Prevent nodeId duplicity
	splittedNodeId = appendDuplicitySuffix(splittedNodeId, getNodeIds(state));

	const allTypes = Object.keys(mergeAll([TRANSITION_TYPES, RESPONSE_TYPES, PROPERTY_TYPES]));

	const nodeCopy = Object.assign({}, originalNode);
	dispatch(createUpdateNode(splittedNodeId, nodeCopy));

	const originalNodeUpdated = omit(allTypes, { ...originalNode });
	originalNodeUpdated[TRANSITION_TYPES.GOTO] = splittedNodeId;
	dispatch(createUpdateNode(nodeId, originalNodeUpdated));

	if (originalNode.group) {
		const group = getNodeGroup(originalNode.group, state);
		dispatch(updateNodeGroup({ ...group, nodes: [...group.nodes, splittedNodeId] }));
	}

	if (shouldShowActiveNode) {
		dispatch(showActiveNode(splittedNodeId));
	}
};

// NODE GROUP: Actions concerning groups of nodes
export const _createNodeGroup = makeActionCreator(actionTypes.CREATE_NODE_GROUP, 'group');
export const _removeNodeGroups = makeActionCreator(actionTypes.REMOVE_NODE_GROUPS, 'groupIds');
export const replaceNodeGroups = makeActionCreator(actionTypes.REPLACE_NODE_GROUPS, 'groups');
export const updateNodeGroup = makeActionCreator(actionTypes.UPDATE_NODE_GROUP, 'group', 'groupId');

export const _setGroupStartNode = makeActionCreator(actionTypes.SET_GROUP_START_NODE, 'groupId', 'nodeId');

export const setGroupStartNode = (groupId, nodeId) => (dispatch, getState) => {
	const state = getState();
	const updatedGroup = getNodeGroup(groupId, state);

	// Only the startNode of each group can be targetted with transitions from other groups
	// This will go through all nodes that are not in the same group and reassign the transitions
	if (updatedGroup.startNode) {
		const filterFunction = ([, node]) => node.group !== groupId;
		dispatch(replaceNodeInTransitions(updatedGroup.startNode, nodeId, filterFunction));
	}

	dispatch(_setGroupStartNode(groupId, nodeId));
};

export const addNodesIntoNodeGroup = (nodeGroupId, nodeIds) => (dispatch, getState) => {
	const state = getState();
	const nodeGroup = getNodeGroup(nodeGroupId, state);

	// With the current implementation, this action has to be dispatched before the nodeIds are changed
	dispatch(removeNodesFromGroup(nodeIds));

	for (const nodeId of nodeIds) {
		dispatch(createUpdateNode(nodeId, { ...clone(getNode(nodeId, getState())), group: nodeGroupId }));
	}

	dispatch(updateNodeGroup({ ...nodeGroup, nodes: [...nodeGroup.nodes, ...nodeIds] }, nodeGroupId));

	dispatch(clearSelectedNodes());
	dispatch(addToSelectedNodes(nodeIds));

	dispatch(
		showNotification(
			INTL.formatMessage(messages.nodesAddedIntoNodeGroup, { nodeGroupId, nodes: nodeIds.join(', ') }),
			notificationTypes.INFO
		)
	);
};

export const renameNodeGroup = (groupId, newGroupId) => (dispatch, getState) => {
	const state = getState();

	const renamedGroup = clone(getNodeGroup(groupId, state));

	// updating nodes
	for (const nodeId of renamedGroup.nodes) {
		const node = clone(getNode(nodeId, getState()));
		node.group = newGroupId;
		dispatch(updateReferencesToNode(nodeId, nodeId, false));
		dispatch(createUpdateNode(nodeId, node));
	}

	dispatch(updateNodeGroup({ ...renamedGroup, name: newGroupId }, groupId));
	dispatch(showActiveNodeGroup(newGroupId));
	// Update referenceNodeDiagramPositions in other groups which still target the oldGroupId
	dispatch(updateReferencesToNode(newGroupId, groupId, false));
};

export const removeNodeGroups = (groupIds) => (dispatch, getState) => {
	const state = getState();

	const allNodeGroups = getAllDiagramGroupsNames(state);
	const globalStartNodeId = getGlobalStartNodeId(state);
	const activeNodeGroupId = getActiveNodeGroupId(state);

	// There has to be at least one group remaining
	if (groupIds.length >= allNodeGroups.length) {
		dispatch(showNotification(INTL.formatMessage(messages.removeLastGroupNotification), notificationTypes.ERROR));
		return;
	}

	const groupsToDestroy = getNodeGroupsForDiagram(state).filter((group) => groupIds.includes(group.name));
	// The filter is a double-check for cases where the nodes got already switched to a different group
	const nodesFromDestroyedGroups = flatten(groupsToDestroy.map((group) => group.nodes)).filter((nodeId) => {
		const node = getNode(nodeId, state);
		return groupIds.includes(node.group);
	});

	if (nodesFromDestroyedGroups.includes(globalStartNodeId)) {
		dispatch(
			showNotification(INTL.formatMessage(messages.removeGlobalStartNodeInGroupNotification), notificationTypes.ERROR)
		);
		return;
	}

	for (const nodeId of nodesFromDestroyedGroups) {
		dispatch(removeNode(nodeId, true));
	}

	dispatch(_removeNodeGroups(groupIds));

	if (groupIds.includes(activeNodeGroupId)) {
		dispatch(showActiveNodeGroup(null));
	}
};

export const createNodeGroup = (groupName) => (dispatch, getState) => {
	const state = getState();

	let startingNodeId = `${groupName}_START`;

	// Prevent nodeId duplicity
	startingNodeId = appendDuplicitySuffix(startingNodeId, getNodeIds(state));

	dispatch(createUpdateNode(startingNodeId, { group: groupName }));

	const globalStartNodeId = getGlobalStartNodeId(state);
	if (!globalStartNodeId) {
		dispatch(setGlobalStartNode(startingNodeId));
	}

	const newGroup = { ...DEFAULT_NODE_GROUP_DATA, name: groupName, nodes: [startingNodeId], startNode: startingNodeId };

	dispatch(_createNodeGroup(newGroup));
	dispatch(clearSelectedNodes());
	dispatch(showActiveNodeGroup(groupName));
	// init position of starting node when new group is created
	dispatch(
		setDiagramPositions({
			[startingNodeId]: { diagramPosition: getNearbyDiagramPosition(globalStartNodeId, state) },
		})
	);
};

// TRANSITIONS: Actions regarding conditions, intents, utterances, etc
export const createUpdateTransitionAction = makeActionCreator(
	actionTypes.CREATE_UPDATE_TRANSITION_ACTION,
	'sourceNodeId',
	'targetNodeId',
	'intentName',
	'ssiGroup'
);

export const createUpdateTransitionSignal = makeActionCreator(
	actionTypes.CREATE_UPDATE_TRANSITION_SIGNAL,
	'sourceNodeId',
	'targetNodeId',
	'signal'
);

// A different action since it creates sourceNodeId: [targetNodeId, utterance]
export const createUpdateTransitionGotoReuseUtterance = makeActionCreator(
	actionTypes.CREATE_UPDATE_TRANSITION_GOTO_REUSE_UTTERANCE,
	'sourceNodeId',
	'targetNodeId',
	'utterance'
);

export const createUpdateTransitionGoto = makeActionCreator(
	actionTypes.CREATE_UPDATE_TRANSITION_GOTO,
	'gotoType',
	'sourceNodeId',
	'targetNodeId',
	'variable'
);

export const updateTransitions = makeActionCreator(
	actionTypes.UPDATE_TRANSITIONS,
	'nodeId',
	'transitionType',
	'transitions'
);

export const removeTransitions = makeActionCreator(actionTypes.REMOVE_TRANSITIONS, 'nodeId', 'transitionType');
export const removeResponse = makeActionCreator(actionTypes.REMOVE_RESPONSE, 'nodeId', 'indexInNodeResponses');

export const createUpdateResponses = (nodeId, { markdown, speech, markdownOptions }) => (dispatch, getState) => {
	const state = getState();
	const node = { ...getNode(nodeId, state) };

	const responses = sortNodeResponsesAndMarkdownOptions({
		markdown: markdown || getNodeResponsesByType(nodeId, RESPONSE_TYPES.MARKDOWN, state),
		speech: speech || getNodeResponsesByType(nodeId, RESPONSE_TYPES.SPEECH, state),
		markdownOptions: markdownOptions || getNodeOutputtedMarkdownOptions(nodeId, state)[RESPONSE_TYPES.MARKDOWN_OPTIONS],
	});

	// update responses
	const nodeModified = merge(pick(filter(isNaN, Object.keys(node)), node), responses);

	dispatch(createUpdateNode(nodeId, nodeModified));
};

export const setSttBoostEntry = (nodeId, boostEntry, existingIndex = -1) => (dispatch, getState) => {
	const variables = getNodeVariables(nodeId, getState()) || [];

	let sttBoostIndex = variables.findIndex(([variableName]) => variableName === STT_BOOST);
	let boostEntries = [];

	if (sttBoostIndex > -1) {
		boostEntries = JSON.parse(variables[sttBoostIndex][1]);
	} else {
		sttBoostIndex = variables.length;
	}

	if (existingIndex > -1) {
		boostEntries[existingIndex] = boostEntry;
	} else {
		boostEntries.push(boostEntry);
	}

	dispatch(createUpdateVariable(nodeId, sttBoostIndex, STT_BOOST, JSON.stringify(boostEntries)));
};

export const unsetSttBoostEntry = (nodeId, boostEntryIndex) => (dispatch, getState) => {
	const variables = getNodeVariables(nodeId, getState()) || [];

	const sttBoostIndex = variables.findIndex(([variableName]) => variableName === STT_BOOST);

	if (sttBoostIndex > -1) {
		const boostEntries = JSON.parse(variables[sttBoostIndex][1]);
		const updatedBoostEntries = remove(boostEntryIndex, 1, boostEntries);
		dispatch(createUpdateVariable(nodeId, sttBoostIndex, STT_BOOST, JSON.stringify(updatedBoostEntries)));
	}
};

export const createUpdateVariable = makeActionCreator(
	actionTypes.CREATE_UPDATE_VARIABLE,
	'nodeId',
	'varId',
	'varName',
	'varValue'
);

export const removeVariable = makeActionCreator(actionTypes.REMOVE_VARIABLE, 'nodeId', 'varId');

export const createUpdateVariablesToReset = makeActionCreator(
	actionTypes.CREATE_UPDATE_VARIABLES_TO_RESET,
	'nodeId',
	'varsToReset'
);

export const removeVariablesToReset = makeActionCreator(actionTypes.REMOVE_VARIABLES_TO_RESET, 'nodeId');

export const createUpdateSmartFunction = makeActionCreator(
	actionTypes.CREATE_UPDATE_SMART_FUNCTION,
	'nodeId',
	'funcId',
	'propertyType',
	'smartFunc'
);

export const removeSmartFunction = makeActionCreator(
	actionTypes.REMOVE_SMART_FUNCTION,
	'nodeId',
	'propertyType',
	'funcId'
);

export const createCondition = makeActionCreator(
	actionTypes.CREATE_CONDITION,
	'nodeId',
	'condValue',
	'condTargetNodeId'
);

export const updateRemoveCondition = makeActionCreator(
	actionTypes.UPDATE_REMOVE_CONDITION,
	'nodeId',
	'conditionIndex',
	'condValue',
	'condTargetNodeId'
);

export const addIntent = makeActionCreator(actionTypes.ADD_INTENT, 'intentId', 'utterances');
export const updateIntent = makeActionCreator(actionTypes.UPDATE_INTENT, 'prevIntentId', 'nextIntentId');
export const removeIntent = makeActionCreator(actionTypes.REMOVE_INTENT, 'intentId');
export const addUtterance = makeActionCreator(actionTypes.ADD_UTTERANCE, 'intentId', 'utteranceId');
export const renameVariable = makeActionCreator(actionTypes.RENAME_VARIABLE, 'varPrev', 'varNew');
export const updateUtterance = makeActionCreator(
	actionTypes.UPDATE_UTTERANCE,
	'intentId',
	'prevUtteranceId',
	'nextUtteranceId'
);
export const removeUtterance = makeActionCreator(actionTypes.REMOVE_UTTERANCE, 'intentId', 'utteranceId');

export const copyIntents = (selectedIntentIds) => (dispatch, getState) => {
	const state = getState();

	for (const intentId of selectedIntentIds) {
		const utterances = clone(getIntent(intentId, state));

		let newIntentId = `COPY_${intentId}`;

		// Prevent intentId duplicity
		newIntentId = appendDuplicitySuffix(newIntentId, getIntentIds(getState()));

		dispatch(addIntent(newIntentId, utterances));
	}
};

export const uploadImage = (fileData, fileName) => (dispatch, getState) => {
	const state = getState();
	const selectedModelId = selectActiveModelId(state);
	const formData = new FormData();

	formData.append('file', fileData, fileName);
	formData.append('modelId', selectedModelId);

	return dispatch(
		rsaaAuthFormData(
			'uploadImage',
			[actionTypes.UPLOAD_IMAGE_PENDING, actionTypes.UPLOAD_IMAGE_SUCCESS, actionTypes.UPLOAD_IMAGE_FAIL],
			formData
		)
	);
};

export const uploadImageAsMarkdown = ({ fileData, fileName, nodeId }) => async (dispatch, getState) => {
	const state = getState();
	const projectId = selectActiveModelId(state);

	const { payload: getImageUrl } = await dispatch(uploadImage(fileData, fileName));
	const { errors } = await dispatch(fetchModel(projectId));

	const imageUrlWithSize = getImageUrl + '&__size__=192x192';
	const imageFormattedAsMarkdown = formatImageMarkdown(fileName, imageUrlWithSize);

	if (getImageUrl && !errors) {
		const markdown = {
			...getNodeResponsesByType(nodeId, RESPONSE_TYPES.MARKDOWN, state),
		};

		markdown[size(markdown) + 1] = {
			[RESPONSE_TYPES.MARKDOWN]: imageFormattedAsMarkdown,
		};

		dispatch(createUpdateResponses(nodeId, { markdown }));
	}
};

export const removeImageResponse = (nodeId, indexInNodeResponses, imageId) => (dispatch) => {
	dispatch(toggleLockModel(true));
	dispatch(removeResponse(nodeId, indexInNodeResponses));
	dispatch(deleteImage(imageId));
	dispatch(toggleLockModel(false));
};

export const setYamlErrorWarning = makeActionCreator(actionTypes.SET_YAML_ERROR_WARNING, 'value', 'type');
export const removeYamlErrorWarning = makeActionCreator(actionTypes.REMOVE_YAML_ERROR_WARNING, 'index', 'type');

// EXPORT
export const downloadYaml = () => (dispatch, getState) => {
	const state = getState();
	const projectName = getProjectName(state) ?? new Date().toISOString().replace(/:/g, '-');
	const yamlData = getRawYaml(state);

	const element = document.createElement('a');
	element.href = URL.createObjectURL(new Blob([yamlData], { type: 'text/yaml' }));
	element.download = `${projectName}.yaml`;
	element.click();
};

export const downloadResponses = () => (dispatch, getState) => {
	const state = getState();
	const projectName = getProjectName(state) ?? new Date().toISOString().replace(/:/g, '-');
	const nodeIds = getNodeIds(state);
	const csvArray = [['Node', 'Markdown', 'Speech']];

	for (const nodeId of nodeIds) {
		const markdown = Object.values(getNodeResponsesByType(nodeId, RESPONSE_TYPES.MARKDOWN, state));
		const speech = Object.values(getNodeResponsesByType(nodeId, RESPONSE_TYPES.SPEECH, state));
		// Create an array of [nodeId, markdown, speech] and add it to the total array
		for (let i = 0; i < markdown.length || i < speech.length; i++) {
			const row = [
				nodeId,
				markdown[i] ? markdown[i][RESPONSE_TYPES.MARKDOWN] : '',
				speech[i] ? speech[i][RESPONSE_TYPES.SPEECH] : '',
			];
			csvArray.push(row);
		}
	}

	// Add tilde as the CSV separator and make each entry on a new line
	const csvPayload = csvArray.map((row) => row.join('~')).join('\n');

	const element = document.createElement('a');
	element.href = URL.createObjectURL(new Blob([csvPayload], { type: 'text/csv;charset=utf-8' }));
	element.download = `${projectName}.csv`;
	element.click();
};

export const downloadCompleteConfig = () => (dispatch, getState) => {
	const state = getState();
	const zip = new JSZip();
	const configs = zip.folder('configs');
	const projectName = getProjectName(state) ?? new Date().toISOString().replace(/:/g, '-');
	const modelId = getSelectedModel(state);
	const assets = getModelAssets(modelId.id, state);

	const assetFolder = zip.folder('assets');

	// Each asset has its own folder containing the asset file itself and its js object in JSON form
	// Announcements are assets too, so this approach covers them as well
	const assetList = assets.map(async (asset) => {
		const response = await fetch(asset.data.url);
		const data = await response.blob();
		const singleFolder = assetFolder.folder(asset.name);
		singleFolder.file(asset.name, new Blob([data], { type: asset.data.mimetype }));
		singleFolder.file(`config.json`, new Blob([JSON.stringify(asset, null, 3)], { type: 'application/json' }));
	});

	// Typical yaml export
	const yamlData = getRawYaml(state);
	configs.file(`${projectName}.yaml`, new Blob([yamlData], { type: 'text/yaml' }));
	// Typical JSON export
	configs.file(
		`${projectName}.json`,
		new Blob([JSON.stringify(getProjectData(state), null, 3)], { type: 'application/json' })
	);
	// We need to fetch asset files, so we need to resolve promises first
	Promise.all(assetList).then(() => {
		zip.generateAsync({ type: 'blob' }).then((content) => {
			saveAs(content, `${projectName}.zip`);
		});
	});
};

/**
 * Imports YAML and creates internal representation of the model from it.
 * The example YAML looks like the one below:
 *
 * configuration:
 *     start_node: START
 *     language: cs
 *
 * flow:
 *     START:
 *         1:
 *             MARKDOWN: "Dobrý den. Budu vám psát zvířata."
 *         GOTO: ANIMALS
 *
 *     ANIMALS:
 *         SET:
 *             - language: "'cs'"
 *         1:
 *             MARKDOWN: "Chcete příklad býložravce nebo masožravce?"
 *         RESET:
 *             - ok_lang
 *         ACTIONS:
 *             ANIMALS_GRASS:
 *                 - býložravec
 *                 - tráva
 *                 - kráva
 *                 - ovce
 *             ANIMALS_MEAT:
 *                 - masožravec
 *                 - dravci
 *                 - tigr
 *                 - lev
 *             ENGLISH:
 *                 - meat
 *                 - grass
 *         GOTO: ANIMALS_FALLBACK
 *
 *     ANIMALS_GRASS:
 *         EXTRACT:
 *             - animal: utterance
 *             - ok_lang: [utterance_language, {'require_language': '${language}', 'fail_on_match': False}]
 *         1:
 *             MARKDOWN:
 *                 - "{animal} je býložravec!"
 *                 - Býložrout jak vyšitej.
 *                 - Býložravec, nemusíš se bát, že tě sežere. Maximálně tě zašlápne.
 *                 - "{animal} je vegetarián."
 *         GOTO: ANIMALS
 *
 *     ANIMALS_MEAT:
 *         EXTRACT:
 *             - animal: utterance
 *             - ok_lang: [utterance_language, {'require_language': 'cs', 'fail_on_match': False}]
 *         1:
 *             MARKDOWN:
 *                 - "{animal} je masožravec! Pokud ho vidíš volného poblíž, zdrhej!"
 *                 - To je masožrout.
 *                 - Masožrout!
 *                 - Žere maso.
 *                 - "{animal} žere i tebe..."
 *         GOTO: ANIMALS
 *
 *     ANIMALS_FALLBACK:
 *         EXTRACT:
 *             - ok_lang: [utterance_language, {'require_language': 'cs', 'fail_on_match': False}]
 *         1:
 *             MARKDOWN: "Bohužel jsme si neporozuměli. Napiš mi název zvířete a já Ti **možná** řeknu, co žere"
 *         ACTIONS:
 *             ANIMALS_GRASS: []
 *             ANIMALS_MEAT: []
 *             ENGLISH: []
 *             INTRODUCTION: []
 *         GOTO: ANIMALS_FALLBACK
 *
 *     ENGLISH:
 *         EXTRACT:
 *             - other_lang: [utterance_language, {'require_language': '${language}', 'fail_on_match': True}]
 *         1:
 *             MARKDOWN: "This looks like English"
 *         2:
 *             MARKDOWN: "Can you pls try in Czech?"
 *         GOTO: ANIMALS
 */
export const importModelFromFile = (inputData, type = 'yaml') => async (dispatch, getState) => {
	const state = getState();
	const selectedModelId = selectActiveModelId(state);
	const { project, description } = getConfiguration(state);
	const projectName = getSelectedModelName(state);
	const organizationId = getSelectedModel(state)?.organization?.id ?? null;
	// Creates project id for further GraphQL handling
	if (!selectedModelId && (projectName || project)) {
		// createModel needs to be before setFlow, startNode and such, it clears the model data.
		await dispatch(createModel(projectName || project, description));
	}

	if (type === 'yaml') {
		try {
			dispatch(updateYamlData(inputData));
			const errors = getYamlErrors(getState());
			// Errors regarding the content of the YAML itself
			if (!isEmpty(errors)) {
				dispatch(showNotification(INTL.formatMessage(messages.yamlUnknownError), notificationTypes.ERROR));
				return;
			}
			// Errors regarding our code raised during the parsing process
		} catch (e) {
			console.error(e);
			Sentry.captureException(e, { extra: { yaml: inputData } });
			dispatch(
				showNotification(
					INTL.formatMessage(messages.yamlParsingError, { error: e.toString().replace(/\n/g, ' ') }),
					notificationTypes.ERROR
				)
			);
			return;
		}
	} else if (type === 'json') {
		let json;
		try {
			json = JSON.parse(inputData);
			// Do not import projectName and projectId
			if (projectName) {
				json.configuration.project = projectName;
			}
			if (selectedModelId) {
				json.configuration.project_id = selectedModelId;
			}
			if (organizationId) {
				json.configuration.organization_id = organizationId;
			}
		} catch (e) {
			Sentry.captureException(e, { extra: { json: inputData } });
			console.error(e);
			dispatch(
				showNotification(
					INTL.formatMessage(messages.jsonUnknownError, { error: e.toString().replace(/\n/g, ' ') }),
					notificationTypes.ERROR
				)
			);
			return;
		}
		dispatch(importFromJson(json));
	} else {
		throw new Error(`Unknown type ${type} to import data.`);
	}

	// closest nodes only
	const nodes = getNodes(getState()); // we need fresh state with imported YAML here
	const nodeGroups = getNodeGroups(getState());
	dispatch(toggleDisplayClosestNodesOnly(hasTooManyNodes(nodes) && nodeGroups?.length <= 1));

	// Creates modelVersion for imported YAML
	const selectedModelVersionId = selectedModelVersionIdSelector(state);
	if (!selectedModelVersionId && project) {
		await dispatch(saveModelVersion());
	}
};
