import * as requests from '../../core/requests';
import {
	createSipAccountQl,
	deleteSipAccountQl,
	getPhoneCallsQL,
	getRecordingQl,
	getSipAccountsForModelQl,
	getSipAccountsQl,
	updateSipAccountQl,
} from '../../graphql/sip';
import { createActionsMap } from '../../utils/action-utils';
import { selectedModelId } from '../model-table/selectors';
import { psf } from '../utils';

export const ID = 'sip';

export const actionTypes = createActionsMap(ID, [
	...psf('FETCH_PHONE_CALLS'),
	...psf('FETCH_SIP_ACCOUNTS'),
	...psf('FETCH_SIP_ACCOUNTS_FOR_MODEL'),
	...psf('FETCH_RECORDING'),
	...psf('CREATE_SIP_ACCOUNT'),
	...psf('UPDATE_SIP_ACCOUNT'),
	...psf('DELETE_SIP_ACCOUNT'),
]);

export const fetchPhoneCallsForActiveModel = () => (dispatch, getState) => {
	const modelId = selectedModelId(getState());
	if (modelId) {
		dispatch(
			requests.qlAuthRequest(
				[
					actionTypes.FETCH_PHONE_CALLS_PENDING,
					actionTypes.FETCH_PHONE_CALLS_SUCCESS,
					actionTypes.FETCH_PHONE_CALLS_FAIL,
				],
				{ query: getPhoneCallsQL, variables: { modelId } }
			)
		);
	}
};

export const fetchSipAccounts = () => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_SIP_ACCOUNTS_PENDING,
				actionTypes.FETCH_SIP_ACCOUNTS_SUCCESS,
				actionTypes.FETCH_SIP_ACCOUNTS_FAIL,
			],
			{
				query: getSipAccountsQl,
			}
		)
	);

export const fetchSipAccountsForModel = (modelId) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_SIP_ACCOUNTS_FOR_MODEL_PENDING,
				actionTypes.FETCH_SIP_ACCOUNTS_FOR_MODEL_SUCCESS,
				actionTypes.FETCH_SIP_ACCOUNTS_FOR_MODEL_FAIL,
			],
			{
				query: getSipAccountsForModelQl,
				variables: { modelId },
			}
		)
	);

export const fetchRecording = (id, isSip) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[actionTypes.FETCH_RECORDING_PENDING, actionTypes.FETCH_RECORDING_SUCCESS, actionTypes.FETCH_RECORDING_FAIL],
			{
				query: getRecordingQl,
				variables: { id, isSip },
			}
		)
	);

export const createSipAccount = (formFields) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.CREATE_SIP_ACCOUNT_PENDING,
				actionTypes.CREATE_SIP_ACCOUNT_SUCCESS,
				actionTypes.CREATE_SIP_ACCOUNT_FAIL,
			],
			{
				query: createSipAccountQl,
				variables: formFields,
			}
		)
	);

export const updateSipAccount = (formFields) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.UPDATE_SIP_ACCOUNT_PENDING,
				actionTypes.UPDATE_SIP_ACCOUNT_SUCCESS,
				actionTypes.UPDATE_SIP_ACCOUNT_FAIL,
			],
			{
				query: updateSipAccountQl,
				variables: formFields,
			}
		)
	);

export const deleteSipAccount = (accountUri) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.DELETE_SIP_ACCOUNT_PENDING,
				actionTypes.DELETE_SIP_ACCOUNT_SUCCESS,
				actionTypes.DELETE_SIP_ACCOUNT_FAIL,
			],
			{
				query: deleteSipAccountQl,
				variables: { accountUri },
			}
		)
	);
