const intents = {
	I_CIR_00_00_A_NONSENSE_INTENT__TECHNICAL: [
		'15',
		'anna',
		'automechanicke dovednosti',
		'clovek',
		'houbacka',
		'houby',
		'jablko',
		'jidlo',
		'knedlik',
		'kolobezka',
		'látok',
		'lyzovacka',
		'melon',
		'osum set osum',
		'pop',
		'stavba',
		'stroj',
		'to',
		'že',
	],
	AKTIVACIA__ACTIVATE_SERVICE: [
		'Ako si mám aktivovať novú službu?',
		'chcel by som nové služby',
		'Chcel by som si aktivovať novú službu',
		'Chceme si aktivovať iné služby',
		'chcem nové služby',
		'chcem sa pobaviť o aktivácii služby',
		'Chcem si aktivovať ďalšie služby',
		'Chcem si aktivovať novú službu',
		'chcem zaktivovať službu',
		'išlo mi o aktiváciu iných služieb',
		'Kúpila som novú službu a chcem ju aktivovať',
		'Mala by som záujem o aktiváciu kúpenej služby',
		'mal by som záujem o aktiváciu novej služby',
		'mal by som záujem o novú službu',
		'mal by som záujem o službu',
		'mám záujem o aktiváciu novej služby',
		'mám záujem o aktiváciu nových služieb',
		'mám záujem o novú službu',
		'mám záujem o službu',
		'nová služba',
		'nové služby',
		'novú službu',
		'potrebujem aktiváciu služby',
		'povedz mi, že ako si u vás aktivovať služby',
		'Treba mi novú službu',
		'Volám kvôli aktivácii služby',
	],
	AKTIVACIA__AKTIVACNA_SMS_INTERNET: [
		'aktivačná SMS k internetu',
		'aktivačnú správu na internet',
		'potreboval by som aktivačnú sms k internetu',
		'potrebujem aktivačnú smsku k internetu',
		'SMS na aktiváciu internetu',
		'treba mi aktivačnú smsku k netu',
	],
	AKTIVACIA__AKTIVUJ_MAGIO: [
		'aktivácia Magio go',
		'aktivovať Magio',
		'aktivujte mi Magio',
		'chcel by som si aktivovať magio go',
		'chcem si aktivovať magio go',
		'chcem si od vás aktivovať Magio',
		'ja by som sa chcel opýtať ako aktivovať mágio go',
	],
	AKTIVACIA__BUY_INTERNET: [
		'chcela by som internet',
		'chcela by som objednať si internet',
		'chcel by som internet',
		'chcel by som kúpiť váš internet',
		'chcel by som objednať internet',
		'chcel by som od vás internet',
		'chcel by som od vás kúpiť internet',
		'chcel by som od vás zakúpiť pevný internet',
		'chcem internet',
		'no ja by som chcel internet ale wifi',
		'chcem si vybrať internet',
		'ja chcem internet',
		'ja sa chcem opýtať na ponuky internetu',
		'máme záujem o internetové pripojenie',
		'máme záujem o pevné internetové pripojenie',
		'objednať internet',
		'ponuky na služby internetu',
		'potreboval by som internet',
		'potrebujem ghz internetom mágiom vzduchom',
		'potrebujem internet',
		'treba mi ponuku na internet',
		'treba mi si vybrať internet',
	],
	AKTIVACIA__BUY_MAGIO: [
		'chcel by som si zriadiť magio go',
		'chceli by sme ďalšie Magio',
		'chceme ďalšie Mágio',
		'chcem Magio',
		'nové mágio no veď televízor mágio',
		'chcem si od vás vybrať Magio',
		'chcem si od vás zobrať Magio',
		'chcem si vziať vaše Magio',
		'chcem si zriadiť magio go',
		'ja by som sa chcel opýtať na mágio televíziu či môžem zobrať k svojmu paušálu',
		'máme záujem o magio',
		'potrebujeme magio televíziu',
		'zobrať Magio',
	],
	AKTIVACIA__BUY_MAGIO_SKRINKY: [
		'chcel by som si kúpiť skrinku',
		'chcel by som skrinku',
		'chceme skrinku telekom',
		'chcem si vziať skrinku telekom',
		'ja by som si chcela zobrať dve skrinky od telekomu',
		'ja by som si chcela zobrať dve skrinky telecom',
		'mám záujem o tú telekom skrinku',
		'skrinka k magiu',
	],
	AKTIVACIA__BUY_NUMBER: [
		'Chcem mať číslo u vás',
		'chcem nové číslo',
		'kúpiť čísla u vás',
		'potrebujem kúpiť číslo',
		'potrebujem nové číslo',
		'potrebujem telefónne čísla',
		'potrebujem tri telefónne čísla',
	],
	AKTIVACIA__CARD: [
		'Ako aktivujem kartu',
		'Aktivácia čísla',
		'Aktivácia karty',
		'Aktivácia SIM karty',
		'aktivovať kartu',
		'aktivujte mi vašu sim kartu',
		'chcela by som aktivovať sim kartu',
		'chcel by som aktivovať vašu sim kartu',
		'chcel by som mať simku',
		'chcel by som si objednať nejakú kartu bez záväzkov',
		'chcel by som vašu kartu',
		'chcem aktivovať kartu',
		'chcem mať simku',
		'chcem simku',
		'chcem si objednať kartu bez záväzkov',
		'chcem vašiu simku',
		'Chcem vašu kartičku.',
		'len si aktivovať easy kartu',
		'mám záujem o kartu bez záväzkov',
		'mám záujem o vašu kartičku',
		'objednať kartu',
		'rada by som si aktivovala vašu kartičku',
		'rád by som aktivoval kartu',
		'rád by som spojazdnil vašu kartu',
	],
	AKTIVACIA__CENOVA_PONUKA: [
		'aká je vaša cenová ponuka',
		'cenová ponuka',
		'cenovú ponuka',
		'dajte mi cenovú ponuku',
		'nejakú cenovú ponuku',
		'sprav mi cenovú ponuku',
	],
	AKTIVACIA__CONNECT_BUY: [
		'Chcel by som kúpiť pevnú ip adresu',
		'Chcel by som sa opýtať ohľadom kúpy statickej ip adresy',
		'chcem statickú ip adresu',
		'dajte mi pevnú ip adresu',
		'kúpiť pevnú ip adresu',
		'potreboval by som kúpiť pevnú ip adresu',
		'treba mi statickú ip adresu',
	],
	AKTIVACIA__CONNECT_INTERNET: [
		'chcela by som si dať do domácnosti zapojiť ten internet',
		'chcela by som si dať zapojiť internet do domácnosti',
		'chcela som vás poprosiť aktivovať internet cez pevnú sieť',
		'Chcel by som sa informovať či je možné pripojiť internet do domu',
		'chcel by som si dať zapojiť internet',
		'Chcem podať žiadosť na pripojenie na sieť ktorú na na našej ulici pripájajú',
		'chcem si vybaviť internet',
		'ja by som si chcela dať do domácnosti zapojiť ten internet',
		'môžem si u vás vybaviť internet na doma?',
		'namonotovať internet na firme',
		'Napojenie internetu',
		'Napojenie k internetu',
		'potrebuje internet cez pevnú linku',
		'potrebujem aktivovať internet cez linku',
		'potrebujem internet cez linku ak sa dá',
		'potrebujem zamontovať internet do firmy',
		'Potrebujem zapojit internet pre toto telefonne cislo',
		'pripojenie internetu',
		'pripojiť sa k internetu',
		'vybavenie internetu na dome',
		'zamontovať internet',
		'zamontovať internet doma',
		'zapnúť internet',
		'zapojenie internetu',
		'zapojiť internet',
		'zapojiť internet do domácnosti',
		'zaviesť nový internet',
		'zriadenie internetu',
		'zriadiť internet',
		'zariadiť internet',
	],
	AKTIVACIA__CONNECT_MAGIO: [
		'chcem zapojiť Magio',
		'ide o pripojenie magia',
		'Ja by som chcel Magio zapojiť cez pevnú linku',
		'Ja by som chcel nanovo zapojiť Magio domov',
		'potreboval by som zapojiť magio',
		'potrebujem vedieť o pripojení magio televízie',
		'potrebujem zapojiť magio',
		'pripojiť magio televíziu',
		'Zapojenie Magio',
		'zapojiť magio',
		'zapojte mi Magio',
	],
	AKTIVACIA__CONNECT_MAGIO_BOX: [
		'chcel by som zapnúť mágio box',
		'chcem zapnúť magio box',
		'rád by som zapol mágio box',
		'zapnútie magio boxu',
		'zapnúť mágio box',
	],
	AKTIVACIA__CONNECT_MIX: [
		'Chcem internetové pripojenie a paušál',
		'Chcem internetové pripojenie a televiziu',
		'Chcem pripojiť telku a internet',
		'Chcem volania a pripojenie a televiziu',
		'Chcem zaviest telekom internet a televiziu',
		'Hľadám balík na telefonovanie, internet na doma a televíziu',
		'pripojenie magio televízie a internetu',
		'volám ohľadne pripojenia televízie a internetu',
	],
	AKTIVACIA__CONNECT_SATELIT: [
		'chcel by som zapojiť satelit',
		'chcem zapojiť satelit',
		'Napojenie satelitu',
		'napojiť satelit',
		'napojte mi satelit',
		'zapojiť satelit',
		'zapojte mi satelit',
		'mám záujem o zriadenie magia cez satelit',
		'mágio televízia cez satelit zakúpiť',
		'chceli by sme si dať mágio televíziu cez satelit',
	],
	AKTIVACIA__CONNECT_TV: [
		'chcel by som zapojiť ďalšiu telku',
		'chcel by som zaviesť telku',
		'chcem zapojiť televíziu',
		'potreboval by som zapojiť telku',
		'potrebujem si zapojiť 2. televízor',
		'potrebujem si zapojiť druhý televízor',
		'zapojiť televízor',
		'zapojiť u nás televíziu',
		'zavedenie televízie',
		'zavedenie televízie do rodinného domu',
		'zavedenie telky',
		'zaviesť televíziu',
		'zaviesť telku',
	],
	AKTIVACIA__INSTAL_TV: [
		'chcel by som inštalovať telku',
		'chcem inštalovať televíziu',
		'inštalácia televízie',
		'inštalácia telky',
		'inštalovať televíziu',
		'inštalovať televízor',
		'inštalovať u nás televíziu',
		'potreboval by som inštalovať telku',
		'potrebujem inštalovať druhý televízor',
		'potrebujem si nainštalovať televízor',
	],
	AKTIVACIA__KOD: [
		'Chcel by som aktivačný kód',
		'chcem dostať aktivačný kód',
		'kód na aktiváciu',
		'môžem si u vás kúpiť kód na aktiváciu služby?',
		'potreboval by som kódik k aktivácie',
		'Potrebujem zaktivovať cez kód',
	],
	AKTIVACIA__LOGIN_MAGIO: [
		'chcem prihlásiť do Magia',
		'chcem sa prihlásiť na Magio',
		'potreboval by som si prihlásiť mágio',
		'potrebujem sa prihlásiť do Magia',
		'prihlásiť mágio',
	],
	AKTIVACIA__NEW_PRODUCTS: [
		'chcem nejaké nové produkty',
		'chcem nové produkty',
		'ja by som prosila nejaké produkty nové',
		'nejaký nový produkt',
		'nové produkty',
		'produkty nové',
		'prosil by som o produkty nové',
		'prosím o nové produkty',
	],
	AKTIVACIA__OPTIKA: [
		'inštalácia optického internetu',
		'Chcem mať optický internet',
		'Chcem mať optiku',
		'chcem optiku',
		'Chcem zapojenie optickej telky',
		'pripojiť optiku',
		'zapojenie optickej televízie alebo internetu',
		'zapojiť optiku',
		'zaviesť optiku',
		'zriadenie optického internetu',
		'zriadiť optiku',
		'nové pripojenie a domov prípojky a zas k vašej optiky',
	],
	AKTIVACIA__ORDER_BUY_SOMETHING: [
		'chcel by som si niečo kúpiť',
		'chcel by som si objednať jednu vec',
		'chceli by sme si niečo objednať od telekomu, no nie',
		'chcem si niečo objednať',
		'chcem si objednať nejakú vec',
		'chcem si objednať voľačo',
		'chcem si voľačo kúpiť',
		'potrebujem si objednať niečo',
		'rád by som si voľačo kúpil',
		'treba mi jednu vec',
		'mala by som záujem o objednávku',
	],
	AKTIVACIA__ORDER_MAGIO: [
		'Chcel by som si u vás objednať Mágio',
		'chceme si objednať magiobox',
		'chceme si objednať magio televíziu',
		'chcem si objednať Magio',
		'objednať magiobox',
		'objednať Mágio televíziu',
		'objednať si magiobox',
		'potrebujeme si objednať magio televíziu',
		'potrebujem si objednať Mágio',
		'potrebujem si objednať Mágio televíziu',
	],
	AKTIVACIA__ORDER_TV: [
		'chcel by som zriadiť telku',
		'chcem si objednať televíziu',
		'chcem zriadiť televíziu',
		'obbjednala by som si rada televíziu',
		'objednať by som chcela plazmovú televíziu',
		'objednať telku',
		'plazmovú telku si chcem objednať',
		'rád by som si objednal televízor',
		'televíziu si chceme objednať',
		'zriadenie telky',
		'zriadiť telku',
		'zriadiť u vás televíziu',
	],
	AKTIVACIA__OTHERS_HAVE_ACTIVATED: [
		'chcela by som rovnaké služby ako má moja dcéra',
		'kamarát má super službu od vás, chcem tiež',
		'mohol by som mať aj ja službu ako má kamoš?',
		'moja priateľka má službu, ktorú by som chcel tiež',
		'môj syn má jednu super službu, môžem mať aj ja?',
		'môžem mať tú istú službu ako kamoška?',
		'môžem si aktivovať rovnakú službu ako kamarát?',
		'niekto mi vravel o zaujímavej službe, chcem ju tiež',
		'susedka má od vás služby. môžem mať aj ja?',
		'známy má vašu službu, ja by som si ju tiež chcel dať',
	],
	AKTIVACIA__PLAIN: [
		'aktivácia',
		'aktivácia telekom sk',
		'aktivovať',
		'aktivovať telekom sk',
		'chcela som vás poprosiť aktivovať',
		'chcel by som vedieť ako si aktivovať',
		'Chcem aktiváciu.',
		'Chcem aktivovať.',
		'Chcem si aktivovať váš balík',
		'Chcem si niečo aktivovať',
		'Potreboval by som aby ste mi aktivovali program',
		'Prosím o aktiváciu',
		'Viete mi aktivovať nový balík?',
		'zaktivovať',
		'Že by som si chcela niečo aktivovať',
	],
	AKTIVACIA__PRENES_INTERNET_DO_TELEKOM: [
		'chcem sa prehlásiť internet cez orangeu do telekomu',
		'chcem sa prehlásiť chcem prehlásiť internet z orindžu k vám do telekomu',
	],
	AKTIVACIA__QA_NEW_SERVICE: [
		'chcel by som sa spýtať aj na ďalšie ponuky internetu a televízie',
		'chcel by som sa spýtať aj na ďalšie ponuky pripojenia',
		'chcem sa informovať ohľadom novej ponuky',
		'chcem sa informovať o vašej novej ponuke',
		'info o novej ponuke',
		'Ja mám pár otázok o tom novom programe.',
		'Ja som počul, že ponúkate nové balíky',
		'mám otázku na novú ponuku',
		'mám otázku o novej ponuky',
		'Máte niečo nové v ponuke teraz?',
		'Potreboval by som pár detailov o novej službe',
		'V čom konkrétne je výhoda nového programu?',
		'Viete mi dať nejaké info o vašej ponuke?',
		'V reklame som videla, že ponúkate nový bonus a chcela by som o tom vedieť viac.',
	],
	AKTIVACIA__STATUS: [
		'ako je to momentálne s tou aktiváciou?',
		'ako to ide s aktiváciou mojich služieb?',
		'aktivované už?',
		'čakám na odpoveď k zavedeniu na internetu vzduchom',
		'Chcel by som sa spýtať jako ide aktivácia, ktorú ste mi sľúbili.',
		'je to už aktivované?',
		'mám to už aktivované?',
		'Mám už aktivované služby?',
		'rád by som vedel, či je tá moja služba aktivovaná už?',
		'tak ako, mám aktivované?',
	],
	AKTIVACIA__TELEFON: [
		'chcem si zaviesť telefón',
		'potrebujem zapojiť telefón',
		'pripojiť telefón',
		'zaviesť telefón',
		'zriadenie telefón',
		'zriadiť telefón',
	],
	AKTIVACIA__TERMIN_ZRIADENIA_INTERNETU: [
		'aký dátum by bol možný na zriadenie internetu?',
		'aký je možný termín zriadenia internetu?',
		'aký termín by ste mi vedeli dať na zriadenie internetu?',
		'chcel by som zistiť možný termín zriadenia internetového pripojenia',
		'chcem zistiť možný dátum zriadenia internetu',
		'chcem zistiť možný termín zriadenia internetového pripojenia',
		'možný dátum zriadenia internetového pripojenia',
		'možný dátum zriadenia internetu',
		'možný termín zriadenia internetového pripojenia',
		'možný termín zriadenia internetu',
		'zistiť možný termín zriadenia internetového pripojenia',
	],
	AKTIVACIA__TURN_ON_MAGIO: [
		'chcel by som zapnúť magio',
		'chcem zapnúť magio',
		'potrebujem zapnúť magio',
		'zapnite mi magio',
		'zapnúť mágio',
	],
	AKTIVACIA__TURN_ON_SERVICE: [
		'Chcem si zapnúť novú službu',
		'Chcem si zapnúť službu',
		'Chcem si zapojiť služby',
		'chcem spustiť novú službu',
		'chcem spustiť službu',
		'mám novú službu a chcem ju spustiť',
		'potrebujem spustenie novej sluzby',
		'potrebujem spustiť novú službu',
		'Rada by som spojazdnila službu',
		'spustenie novej služby',
		'zapnutie novej služby',
		'zapojenie novej služby',
		'zavedenie služby',
		'zriadenie služby',
	],
	AKTIVACIA__TV: [
		'aktivácia tv',
		'aktivovať televíziu',
		'chcel by som aby ste mi zaktivovali telku',
		'chcem aby ste mi aktivovali televíziu',
		'chcem si aktivovať moju tv',
		'potreboval by som aktivovať moju tv',
		'potrebujem aktivovať telku',
		'potrebujem sa dohodnúť ohľadom televízie',
		'volám ohľadne televízie',
	],
	AKTIVACIA__ZAPOJ_PEVNU_LINKU: [
		'chcela som zriadiť pevnú linku',
		'môžem si u vás vybaviť pevnú linku?',
		'Napojenie pevnej linky',
		'zriadenie pevnej linky',
		'zriadenie služby pevnej linky',
		'zriadiť pevnú linku',
	],
	MAGENTA_1__ADD: [
		'chcel by som zmeniť magentu',
		'chcem ísť do magenty ku synovy',
		'Chcem pridať do skupiny Magenta rodinných príslušníkov',
		'Chcem sa pridať do Magenta 1',
		'Chcem sa prihlásiť do skupiny Magenta',
		'Chcem si pridať člena do skupiny',
		'kamarát je v magente, chcem byť tiež',
		'Kamarátka má magenta skupinu chcem aj ja',
	],
	MAGENTA_1__ADD_HOW: [
		'ako mám prijať pozvánku do skupiny magenty',
		'ako ma pridajú do skupiny magenty',
		'nemám číslo v m1 skupine, treba ho pridať',
		'nemám internet ako mám prijať pozvánku do skupiny magenty',
		'neviem si pridať číslo do magenty',
		'Prečo nemám číslo v m1 skupine?',
		'viete mi pridať číslo do magenty?',
	],
	MAGENTA_1__ADMINISTRATE: [
		'chcela by som spravovat svoju magenta skupinu',
		'chcela by som spravovať svoju magenta skupinu',
		'chcela by som spravovat svoju skupinu',
		'chcela by som spravovať svoju skupinu',
		'Chcem si administrovať skupinu Magenta',
		'chcem zmeniť správcu skupiny Magenta',
	],
	MAGENTA_1__ADVANTAGES: [
		'a čo mám za výhody z magenty',
		'aké mám výhody byť v magente',
		'aké sú hlavné výhody byť v magente',
		'Aké sú vyhody skupiny Magenta',
		'Aké sú výhody skupiny Magenta',
		'a na čo mi je dobrá magenta',
		'čím je pre mňa výhodné byť v skupine magenta',
		'ktoré výhody pre mňa plynú z členstva v magente',
		'Má každý člen skupiny Magenta rovnaké výhody?',
	],
	MAGENTA_1__CANCELED: [
		'prišiel som o členstvo v magente',
		'prišla mi SMS, že som stratila výhody magenta skupiny. Prečo?',
		'už nie som v skupine',
		'zrušila sa mi skupina',
		'zrušili ste mi magentu skupinu',
	],
	MAGENTA_1__CREATE: [
		'Chcem vytvoriť novú skupinu Magenta 1',
		'Chcem začať skupinu Magenta',
		'Chcem založiť skupinu Magenta',
		'spravte mi skupinu Magenta',
		'treba mi spraviť skupinu Magenta',
		'vytvorte mi skupinu Magenta',
		'založte mi skupinu Magenta',
	],
	MAGENTA_1__DATA_SHARE: [
		'Chcem rozdeliť dáta',
		'Dá sa darovať dáta členom magenty viackrát za mesiac?',
		'dá sa previesť dáta',
		'je možné manželke preposlať dáta',
		'Je objem darovaných dát rozdelený automaticky medzi vybraných členov skupiny magenta?',
		'Môžem darovať dáta členom magenty každý mesiac?',
		'Môžem darovať dáta v rámci skupiny magenta viacerým členom?',
		'Môžem moje nevyužité darované dáta darovať inemu členovi skupiny magenta?',
		'Môžem prijímať darované dáta bez ohľadu na služby, ktoré využívam?',
		'Môžem rozdeliť dáta medzi všetkých členov skupiny magenta?',
		'Môžem zdieľať dáta s ktorýmkoľvek členom skupiny magenta?',
		'potrebujem previesť dáta na druhé telefónne číslo',
		'preniesť dáta na iné číslo v skupine magenta',
		'preposlať dáta na iné číslo v skupine magenta',
		'previesť dáta',
		'previesť dáta manželovi',
		'previesť dáta v rámci magenty',
		'Som členom magenty, môžem prijímať darované dáta bez ďalšich podmienok?',
	],
	MAGENTA_1__DATA_SHARE_HOW_MUCH: [
		'Ako určím množstvo zdieľaných dát v rámci skupiny magenta?',
		'Ako zistím množstvo zdieľaných dát na magenta skupine?',
		'Ako zistím množstvo zdieľaných dát v rámci skupiny magenta?',
		'chcem vedieť množstvo zdieľaných dát v skupine magenta?',
		'Koľko dát máme v našej skupine magenta?',
		'Koľko dát mám v rámci skupiny magenta?',
		'treba mi vedieť koľko zdieľaných dát je v rámci skupiny magenta?',
	],
	MAGENTA_1__HOW: [
		'Ako funguje Magenta',
		'Ako si spustim Magentu?',
		'Ako si spustím Magentu?',
		'ako spustiť magentu',
		'Chcem riešiť tú Magentu',
	],
	MAGENTA_1__KOLKO_CLENOV: [
		'Koľko členov môže byť súčasťou skupiny Magenta?',
		'Koľko členov môže byť v skupine Magenta?',
		'Koľko členov môže mať skupina Magenta?',
		'Koľko ľudí si môžeme pridať do skupiny Magenta?',
		'Koľko nás môže byť v magenta skupine',
	],
	MAGENTA_1__LEAVE_OR_END: [
		'ako zruším moje členstvo v magente?',
		'chcem opustiť magentu',
		'chcem z magenty odísť',
		'chcem z magenty von',
		'Môže ktorýkoľvek člen skupiny Magenta zo skupiny vystúpiť?',
		'Môžem kedykoľvek opustiť skupinu Magenta?',
		'už viac nechcem byť členom skupiny magenta',
	],
	MAGENTA_1__WHAT_IS_MAGENTA: [
		'čo je magenta',
		'čo je to magenta',
		'info o magente',
		'Magenta',
		'Magenta skupina',
		'Magenta skupiny',
		'viete mi dať informácie k Magente?',
	],
	MAGENTA_1__WHY_MAGENTA_GROUP: [
		'bola som pridaná do magenta skupiny',
		'prečo som v dajakej magenta skupine',
		'prečo som v magenta skupine',
		'prečo ste ma dali do magenta skupiny',
		'Prišla mi SMS že som bola priradená do dajakej skupiny čo to je?',
		'som bola priradená do dajakej skupiny?',
	],
	PROLONGACIA__AKCIOVA_CENA: [
		'akciová cena mi končí',
		'akciová cena mi práve končí',
		'chcem predĺžiť službu čo mám so zľavou',
		'chcem si predĺžiť zľavnenú akciovú službu',
		'dobrý deň ja som dostala od vás smsku že mi už končí zľava na mágio internet no a neviem čo ďalej',
		'dostala som od vás smsku že mi končí zľava na mágio internet',
		'dostala som sms že si mám predĺžiť magio služby s extra zľavami',
		'dostal som sms že si mám predĺžiť magio službu s extra zľavou',
		'končí mi akciová cena',
		'končí mi zľava na magio internet',
		'končí mi zľava',
		'mám záujem o prdĺženie služby so zľavou',
		'predĺžiť magio služby s extra zľavou',
		'Skončila mi akciová cena',
		'už nemám akciovú cenu',
		'zľava mi skončila a neviem čo ďalej',
	],
	PROLONGACIA__AUTOMATIC_COMMITMENT: [
		'automatické predlžovanie viazanosti',
		'dá sa nastaviť automatické predlžovanie viazanosti?',
		'funguje u vás automatické predlžovanie viazanosti?',
		'Je viazanosť automaticky predlžovaná?',
		'Končí mi viazanosť a chcela by som to obnovit',
		'predlžovanie viazanosti prebieha automaticky?',
		'Predlžuje sa viazanosť automaticky?',
		'viazanosť sa mi predĺži automaticky či ako?',
		'viazanosť sa mi predlžuje automaticky?',
		'viazanosť sa mi u vás predlžuje automaticky?',
	],
	PROLONGACIA__CALLS: [
		'Ako si predĺžim volanie zadarmo ?',
		'chcem si predĺžiť volanie zadarmo',
		'Jak si môžem predĺžiť volania zadarmo',
		'mám záujem si predĺžiť volania zdarma',
		'predĺženie volaní zdarma',
		'predĺžiť si volania zadarmo',
		'predĺžiť volania zadarmo',
		'zdarma volania si chcem predĺžiť',
	],
	PROLONGACIA__CHYTRY_BALIK: [
		'chcel by som predĺžiť chytrý balík od telekomu',
		'chcem predĺžiť chytrý balík',
		'končí balík zliav',
		'končí mi chytrý balík a rád by som predĺžil',
		'končí mi chytrý balík od telekomu tak už som dostal správy',
		'mám záujem o predĺženie chytrého balíka',
		'predĺžte mi chytrý balík',
		'prišla mi sms že mi končí chytrý balík, chcem predĺžiť',
		'skončil mi chytrý balík',
	],
	PROLONGACIA__COMMITMENT: [
		'bude mi končiť viazanosť',
		'chcel by som predĺžiť viazanosť',
		'chcem si predĺžiť viazanosť',
		'končí mi viazanosť a chcela by som ju obnoviť',
		'Konci mi viazanost a chcla by som to obnovit',
		'končí mi viazanosť',
		'predĺženie viazanosti',
		'predlžiť viazanosť',
		'predĺžte mi viazanosť',
		'rád by som predĺžil viazanosť',
	],
	PROLONGACIA__CONTINUE: [
		'ako pokračovať vo využívaní služby?',
		'chceli by sme pokračovať s akciou',
		'chcem, pokračovať v službe?',
		'čo spraviť aby mi služba pokračovala?',
		'Musím niečo urobiť, ak chcem, aby mi služba pokračovala?',
	],
	PROLONGACIA__CONTRACT: [
		'chcel by som predĺžiť svoju zmluvu',
		'chcel by som predĺžiť zmluvu lebo mi končí',
		'chcem ďalej pokračovať so zmluvou',
		'chcem predĺžiť, lebo mi už končí',
		'Chcem predĺžiť moju zmluvu na pevnú linku',
		'Chcem predĺžiť zmluvu',
		'došla mi od vás smska, že si mám predĺžiť',
		'Ja som mala zavolať na toto číslo kde ide o zľavu pri predĺženi zmluvy',
		'končí mi zmluva chcem predĺžiť',
		'Mala by nám končí zmluva a prosím vás chceme tú zmluvu predĺžiť',
		'mám záujem prolongovať zmluvu',
		'No potrebujem ohľadom predĺženia zmluvy pre pevnú linku',
		'ohľadne predĺženia mojej zmluvy',
		'poslali ste mi sms že si mám predĺžiť zmluvu',
		'dostala som sms a mám zavolať tak niečo tá vtom zmysle koniec o zmluvy',
		'potrebujem predĺžiť zmluvu',
		'predĺženie tej zmluvy',
		'predĺženie zmluvy',
		'predĺžiť zmluvu',
		'predĺžte mi zmluvu',
		'prišla mi od vás sms že si mám predĺžiť',
		'Prisla mi SMSka o predlzenie zmluvy',
		'prišla mi smska potrebovala by som predĺžiť zmluvu',
		'rada by som si predĺžila svoju zmluvu',
		'sms som teraz dostal od vás, že mám si predĺžiť',
		'zmluvu chcem predĺžiť',
	],
	PROLONGACIA__ENDING_PAUSAL: [
		'čo robiť keď mi končí paušál',
		'Končí mi paušál, čo mi viete ponúknuť.',
		'končí mi paušál',
		'končí sa mi paušál',
		'končí viazanosť paušálu mesačného, tak potrebujem informácie',
		'mesačný paušál mi končí tak by som potrebovala informácie',
		'paušálne obdobie skončilo a ja chcem uzavrieť paušál',
		'paušálne obdobie skončilo, chcem nový paušál',
		'rada by som sa informovala ohľadne paušálu mesačného, končí viazanosť tak by som potrebovala informácie',
		'volám ohľadom paušálu dneska by mala asi končiť',
		'Volám ohladom paušálu, dnes mi má skončiť',
	],
	PROLONGACIA__EXPIRED_CONTRACT: [
		'ako by sa dalo predĺžiť si zmluvu',
		'ako si predĺžim skončenú zmluvu',
		'chcem sa informovať o predĺžení zmluvy',
		'chcem sa spýtať na zmluvu, čo sa týka predĺženia',
		'poradíte mi ako si predĺžim zmluvu',
		'Potrebovala by som sa opýtať ohľadom mojej zmluvy čo sa týka predĺženia',
	],
	PROLONGACIA__HOW_TO_PROLONG_PAUSAL: [
		'Ako si mám predĺžiť svoj paušál ?',
		'Ako si môžem predĺžiť paušál?',
		'Ako si predĺžim paušál ?',
		'chcem vedieť ako si predĺžim paušál',
		'jak sa dá predĺžiť si paušál?',
		'jak si môžem predĺžiť paušál',
		'jak si predĺžim paušál',
	],
	PROLONGACIA__INTERNET: [
		'Chcela by som si predĺžiť internet',
		'Chcel by som si predlzit internet',
		'Chcel by som si predĺžiť internet',
		'Chcem predĺžiť môj Internet',
		'internet chcem predĺžiť',
		'končí mi internet, chcem predĺžiť',
		'no potrebujem predĺžiť internet',
		'potrebujem predĺžiť internet',
		'potrebujem predĺžiť viazanosť internetu',
		'potrebujem predĺžiť viazanosť tej zmluvy na internet',
		'potrebujem predĺžiť zmluvu na internet',
		'potrebujem predĺžiť zmluvu na internet skončila mi jej platnosť',
		'potrebujem si predĺžiť internet',
		'predĺženie internetu',
		'predĺžiť internet, končí mi mágio internet',
		'predĺžiť internet',
		'rád by som predĺžil internet',
		'rád by som predĺžil mágio internet',
		'treba mi predĺžiť internet',
	],
	PROLONGACIA__NEW_OFFER: [
		'chcem cenu pre verných zákazníkov',
		'chcem lepšiu ponuku na predĺženie viazanosti',
		'Chcem retenčnú ponuku',
		'Som verný zákazník akú mi viete dať cenu',
		'Uvažujem o predĺžení viazanosti, ale chcel by som dostať lepšiu ponuku',
	],
	PROLONGACIA__NOT_SENT_NEW_OFFER: [
		'Končí mi viazanosť a dostal som od vás SMS ponuku',
		'Končí mi viazanosť a nedostal som žiadnu ponuku',
		'Nedali ste mi vedieť o novej ponuke po uplynutí doby viazanosti',
		'nedostal som novú ponuku po konci viazanosti',
		'zatiaľ ste mi neponúkli nič nové po ukončení viazanosti',
	],
	PROLONGACIA__OBNOVIT_MAGIO: [
		'chcem obnovu pripojenia magia',
		'dostal som som výzvu na prihlásenie sa na obnovu pripojenia mágia',
		'mal by som záujem o obnovenie pripojenia magia',
		'mám záujem o obnovenie magia',
		'obnova pripojenia mágia',
		'obnoviť magio',
		'obnoviť pripojenie magia',
		'obnovu pripojenia mágia',
		'potreboval by som si obnoviť pripojenie magia',
		'potrebujem si obnoviť magio',
		'rád by som si obnovil pripojenie magia',
	],
	PROLONGACIA__OBNOVIT_PAUSAL: [
		'chcel by som obnoviť paušál',
		'chcem ohcem obnoviť paušál',
		'dobrý deň potrebovala by som si obnoviť paušál',
		'mám záujem obnoviť paušál',
		'obnova paušálu',
		'obnoviť paušál',
		'obnovte mi paušál',
		'obnovu paušálu mi treba',
		'treba mi obnoviť paušál',
	],
	PROLONGACIA__PAUSAL_END: [
		'chcem riešiť končí mi paušál a internet optický kábel',
		'Chcel by som si predĺžiť končiaci paušál',
		'chcem riešiť končí mi paušál',
		'ide mi skončiť paušál, viete mi ho predĺžiť?',
		'končí mi paušál, chcem ho predĺžiť',
		'mohol by som si predĺžiť paušál čo mi skončil?',
		'predĺženie končiaceho paušálu',
		'predĺženie skončeného paušálu ma zaujíma',
		'predĺžiť paušál chcem, skončil mi',
	],
	PROLONGACIA__PEVNA_LINKA: [
		'chcel by som si predĺžiť zmluvu na pevnú linku',
		'chcem pokračovať zmluve pevnú linku',
		'chcem predĺžiť pevnú linku',
		'chcem predĺžiť zmluvu na pevnú linku',
		'chcem si obnoviť zmluvu na pevnú linku',
		'jedná sa mi o predĺženie pevnej linky',
		'mám záujem predĺžiť pevnú linku',
		'o chvíľu mi končí zmluva na pevnú linku, chcem predĺžiť',
		'ohľadne predĺženia zmluvy pevnej linky',
		'ohľadom predĺženia zmluvy na pevnú linku',
		'potreboval by som predĺžiť pevnú',
		'predĺženie mojej zmluvy na pevnú linku',
		'predĺženie pevnej linky',
		'predĺžiť pevnú linku',
		'predĺžiť zmluvu na pevnú linku',
		'rada by som si predĺžila zmluvu na pevnú',
		'skončila mi zmluva na pevnú linku, chcem predĺžiť',
		'za mesiac mi končí zmluva na pevnú linku, mám záujem predĺžiť',
	],
	PROLONGACIA__PLAIN: ['Potreboval by som to predĺžiť', 'Predĺženie', 'Predĺžiť', 'Predĺžiť to', 'Prolongácia'],
	PROLONGACIA__PREDLZIT_MAGIO: [
		'Chcem predĺžiť moje Magio',
		'Chcem predĺžiť moju Magio TV',
		'Mam mágio internet aj televíziu v jednom balíku a potrebovala by som si to predĺžiť',
		'mám záujem o predĺženie magia',
		'potreboval by som si mágio telku predĺžiť',
		'potrebujem si predĺžiť magio',
		'predĺžiť magio',
		'predĺžiť Magio služby',
		'predĺžte mi moje Magio',
		'rád by som si predĺžil moje magio',
	],
	PROLONGACIA__PROLONG_PAUSAL: [
		'chcel by som si predĺžiť paušál?',
		'Chcem predĺžiť paušál',
		'chcem sa informovať ohľadom predĺženia paušálu',
		'chcem sa spýtať na predĺženie paušálu',
		'Chcem si predĺžiť paušál',
		'informácie ohľadom predĺženia paušálu',
		'mohol by som si predĺžiť paušál?',
		'ohľadom predĺženia paušálu',
		'o predĺžení paušálu',
		'potrebujem predĺžiť paušál',
		'predĺženie paušálu ma zaujíma',
		'predĺženie paušálu',
		'predĺžiť paušál',
		'predĺžiť si paušál',
		'predĺžiť svoj paušál',
		'predĺžiť u vás paušál',
		'predĺž mi paušál',
		'Rada by som predĺžila svoj paušál',
	],
	PROLONGACIA__SERVICE: [
		'Ako si predĺžim službu, čo mi končí?',
		'Ako si predĺžim službu?',
		'Akými spôsobmi sa dá predĺžiť viazanosť?',
		'Chcem aby mi neskončili služby',
		'Chcem si predĺžiť služby',
		'Kde môzem predĺžiť službu?',
		'Kde môžem predĺžiť služby?',
		'Kde si môžem predĺžiť služby?',
		'končí mi služba?',
		'Môžem si predĺžiť viacero služieb zároveň?',
		'môžem si predĺžiť viazanosť služby, ktorá mi ešte nekončí?',
		'Potrebujem predĺžiť viazanost na telefonnej linky co u vas mame',
		'prišla mi správa, že mi končí služba?',
	],
	PROLONGACIA__WANT_NEW_OFFER: [
		'Asi mi končí viazanosť, čo mi ponúknete?',
		'Končí mi viazanosť, čo nového môžem dostať?',
		'Máte pre mňa nejakú novú ponuku po konci viazanosti?',
		'som na konci viazanosti, máte novú ponuku pre mňa',
		'viazanosť mi končí ponúknete mi niečo nové?',
		'chcem novú viazanosť viac aj sita',
	],
	VIAZANOST__CHECK: [
		'aká je moja viazanosť?',
		'ako dlho mám viazanosť telekom viazanosť?',
		'ako dlho som viazaná telekomu chcem vedieť',
		'Ako to mám s viazanostou ?',
		'Ako to mám s viazanosťou ?',
		'Ako zistím obdobie viazanosti cez internet?',
		'chcela by som zistiť kedy sa mi končí viazanosť',
		'Chcel by som sa poinformovať dokedy mám zmluvu s vami',
		'chcel som len viazanosť',
		'chcel som len viazanosť zistiť',
		'chcem si preveriť koniec viazanosti.',
		'chcem vedieť ako dlho som viazaný',
		'doba viazanosti môjho paušálu',
		'Dokedy som u vas viazany?',
		'Dokedy som u vás viazaný?',
		'Dostanem nejaké upozornenie o konci viazanosti?',
		'Kedy mi končí viazanosť?',
		'Končí mi viazanosť, čo s tým?',
		'Mám ešte viazanosť?',
		'mám otázku na viazanosť',
		'mohol by som sa spýtať na viazanosť',
		'môžem sa spýtať na viazanosť?',
		'no ja sa potrebujem spýtať na viazanosť',
		'opýtať sa na viazanosť',
		'potrebujem sa spýtať na viazanosť',
		'potrebujem zistiť kedy mi končí viazanosť',
		'Prečo som u vás ešte viazaný?',
		'spýtať sa na viazanosť',
		'Viazanosť?',
		'Viazanosť',
		'viete dobu viazanosti paušálu',
		'Viete mi povedať kedy mi končí viazanosť?',
	],
	VIAZANOST__CHECK_CONTRACT_END: [
		'Ako dlho ešte budem mať platnú zmluvu',
		'Ako dlho mám ešte zmluvu aby sa nenavýšila cena?',
		'Aký je termín ukončenia zmluvy?',
		'Chcem vedieť dokedy mám akciu',
		'Chcem zistiť, dokedy mám zmluvu',
		'Dokedy mám u vás zmluvu?',
		'Do kedy mám zmluvu?',
		'dokedy som sa s vam upísal?',
		'dokedy som váš vazal?',
		'Kedy mi končí zmluva',
		'Kedy mi u vás končia zmluvy?',
		'Ktorým dňom presne mi končí zmluva?',
		'No chcem vedieť kedy mi končí akcia',
		'Potrebujem vedieť dokedy nám platí mágio internetové',
		'potrebujem zistiť ukončenie zmluvy',
	],
	VIAZANOST__CHECK_PAUSAL_END: [
		'chcel by som preveriť termín kedy končí paušál na čísle',
		'chcem zistiť kedy mi končí paušál',
		'ja by som potrebovala vedieť že dokedy je paušál lebo nemám papiere',
		'kedy končí môj paušál',
		'kedy končí viazanosť na paušál',
		'Kedy mi končí dátumovo paušál?',
		'Kedy mi končí paušál?',
		'kedy mi končí viazanosť na paušál',
		'potrebovala by som preveriť podľa telefónneho čísla kedy končí paušál',
		'potrebujem sa spýtať kedy končí paušál na tomto čísle',
		'potrebujem zistiť kedy mi končí viazanosť na paušál',
	],
	VIAZANOST__CHECK_TEL_CISLO: [
		'akú mám viazanosť na toto telefónne číslo',
		'akú mám viazanosť telefónneho čísla',
		'Chcela by som vedieť na toto telefónne čislo dokedy mám',
		'chcem zistiť viazanosť tohto telefónneho čísla',
		'viazanosť na telefónne číslo',
		'viazanosť telefónneho čísla',
		'Zistiť viazanosť telefónneho čísla',
	],
	VIAZANOST__CONTRACT_END_INFO: [
		'čo sa stane keď sa mi skončí zmluva?',
		'dostal som sms, je tam, že mi končí paušál',
		'končí mi lehota',
		'končí mi zmluva',
		'končí nám lehota u vás',
		'máme skončenú zmluvu',
		'písali ste že mi končí zmluva a mám zavolať',
		'potrebujem vám oznámiť že mi končí lehota u vás',
		'Prišla mi SMS že mi končí zmluva a mám zavolať',
		'skončila mi zmluva',
		'skončila nám u vás lehota',
		'vypršala nám zmluva a skončila',
		'Zrušia mi všetky služby v deň konca mojej zmluvy?',
	],
	VIAZANOST__DATA: [
		'akú mám dáta viazanosť',
		'akú mám viazanosť dáta',
		'chcem zistiť viazanosť na dáta',
		'dokedy mám viazanosť na dáta',
		'len potrebujem s viazanosťou na dáta poradiť',
		'zistiť viazanosť dát',
	],
	VIAZANOST__INTERNET: [
		'chcem sa spýtať lebo mi končí viazanosť magio internet',
		'dokedy mám viazanosť na internet',
		'informácie o viazanosti internetu',
		'informovať sa ohľadne viazanosti internetu',
		'informovať sa ohľadom internetu ohľadom viazanosti',
		'Kedy mi končí viazanosť internetu?',
		'kedy mi skončí viazanosť na internet',
		'končí mi viazanosť na internet, čo mi viete ponúknuť',
		'Končí mi viazanosť na internet, čo s tým?',
		'končí mi viazanosť na internete',
		'potrebujem info ohľadom internetu a viazanosti',
		'potrebujem sa informovať ohľadom internetu ohľadom predĺženia a viazanosti',
		'viazanosť internetu',
		'viazanosť na internet',
		'zistiť akú mám viazanosť na internet',
		'zistiť viazanosť internetu',
	],
	VIAZANOST__INTERNET_TV: [
		'dokedy mám viazané magio tv a internet',
		'dokedy som viazaný s internetom a magio tv',
		'kedy mi končí viazanosť na televíziu a internet',
		'potrebujem zistiť že dokedy máme mágia televízor zmluvu',
		'viazanosť magio televízie a magio internetu',
		'viazanosť televízie a internetu',
	],
	VIAZANOST__KONCI_BALIK: [
		'čo sa stane keď sa mi skončí balík?',
		'končíme u vás s balíkom',
		'končí mi zľava na balík',
		'končíme zľava na balík',
		'končí nám balík zľava u vás',
		'máme skončenú zľavu na balík',
		'vypršala nám zľava a skončil balík',
	],
	VIAZANOST__MAGIO_TV: [
		'akú mám viazanosť na magio tv',
		'dokedy mám viazanosť na mgio televíziu',
		'kedy je koniec viazanost magio tv',
		'kedy mi končí viazanosť magio televízie',
		'viazanosť magio televízie',
		'viazanosť mágio televízie',
		'viazanosť na magio televíziu',
	],
	VIAZANOST__PEVNA_LINKA: [
		'aká je moja viazanosť pevnej linky?',
		'Ako to mám s viazanosťou na pevnej linke?',
		'Ako to mám s viazanostou pevnej linky?',
		'končí mi viazanosť na pevnú linku a chcem vedieť čo ďalej',
		'končí mi viazanosť pevnej linky',
		'končí sa viazanosť na mojej pevnej linke',
		'nám končila viazanosť na pevnú linku',
		'nám končila zmluva na pevnú linku',
		'potrebujem informáciu o viazanosti na pevnej linke telekomu',
		'potrebujem informáciu o viazanosti pevnej linky?',
		'volám ohľadom konca viazanosti pevnej linky',
		'volám ohľadom viazanosti pevnej linky',
	],
	VIAZANOST__TELEFON: [
		'aká je moja viazanosť na telefón',
		'ako to mám s viazanosťou na telefón',
		'akú mám viazanosť telefónu',
		'Kedy mi končí viaznosť telefónu',
		'mám otázku ohľadne viazanosti na telefón',
		'viazanosť na telefón',
		'viazanosť telefónu',
		'zaujíma ma viazanosť telefónu',
	],
	HROZBA_ODCHODU__CHCE_ODIST: [
		'no moja mama mala u vás účet a a zomrela tak by som to chcela zrušiť všetko',
		'chcel by som u vás skončiť',
		'Chcem odist',
		'Chcem od vás odísť',
		'Chcem s vami skončiť, nechcem s vami už nič mať spoločné.',
		'Chcem u vás skončiť',
		'Nechcem byť už vaším zákazníkom',
		'podmienky zrušenia',
		'zrušenie',
		'zrušiť',
		'zrušiť to chcem',
		'zomrel zrušiť to chcem',
	],
	HROZBA_ODCHODU__RISK_OF_TERMINATION: [
		'konkurencia ma širšiu ponuku služieb za podobné ceny',
		'Lepšia ponuka od konkurencie',
		'Nespokojnosť so službami (dlhodobá)',
		'Nevyhovuje mi cena za využívané služby',
		'Nezdá sa mi, že má zmysel naďalej používať vaše služby',
		'nie som s vami vôbec spokojný',
		'Služba je príliš drahá',
		'U konkurencie sú nižšie ceny za podobné služby',
		'Váš program sa mi neoplatí',
	],
	HROZBA_ODCHODU__TERMINATION_CONTRACT: [
		'Budem si rušiť zmluvu s vami.',
		'chcel by som ukončiť zmluvu',
		'chcem dať výpoveď zmluvy',
		'chcem rušiť zmluvu',
		'chcem zrušiť moju zmluvu z telekomu',
		'chcem zrušiť viazanosť',
		'chcem zrušiť zmluvu',
		'dať výpoveď zmluvy',
		'Dobrý deň, chcela by som si zrušiť všetky vaše zmluvy',
		'dostal som výpoveď poskytovania služieb',
		'Ide o zrušenie zmluvy',
		'ja som dal výpoveď zmluvy o poskytovaní vašich služieb',
		'ja som s vami skončila, ja potrebujem zrušiť zmluvu',
		'Kedy môžem u vás skončiť?',
		'koniec zmluvy',
		'nechcem mať u vás zmluvu',
		'Odchádzam do zahraničia, budem musieť ukončiť zmluvu',
		'odstúpenie od zmluvy',
		'potrebujem ukončenie zmluvy',
		'potrebujem ukončiť zmluvu',
		'potrebujem zistiť ako na ukončenie zmluvy',
		'potrebujem zrušiť moju zmluvu z telekomu',
		'potrebujem zrušiť zmluvu',
		'prerušenie vlastne zmluvy',
		'Skončila mi zmluva a už nechcem predĺžiť',
		'ukončenie zmluvy',
		'ukončiť viazanosť',
		'vypovedať zmluvu',
		'výpoveď zmluvy',
		'zrušenie mojej zmluvy z telekomu',
		'Zrušenie zmluvy',
		'zrušiť viazanosť',
		'zrušiť zmluvu z telekomu',
		'Zrušte mi zmluvu. Chcem podať výpoveď.',
	],
	HROZBA_ODCHODU__TERMINATION_HOW: [
		'Ako môžem vypovedať zmluvu?',
		'Ako u vás skončím?',
		'čo musím spraviť aby som mohla odísť?',
		'dokedy musím podať výpoveď?',
		'Kedy môžem najskôr podať výpoveď?',
	],
	HROZBA_ODCHODU__TERMINATION_INTERNET: [
		'nechcem používať váš internet',
		'vypovedať internet',
		'nechcem váš internet',
		'nepotrebujem váš internet, budem si vybavovať iný',
		'nepotrebujem váš internet',
		'váš internet je na nič, budem mať iný',
	],
	HROZBA_ODCHODU__TERMINATION_OPTINT: [
		'chcem riešiť že som dostal výpoveď zo služby za to že nemáme optický kábel',
		'dostali sme výpoveď a teraz tu je možnosť ten optický kábel zaviesť',
		'vraj nám skončí služba kvôli prechodu na optiku',
		'že nás odpojíte lebo nemáme optiku',
	],
	HROZBA_ODCHODU__TERMINATION_SERVICE: [
		'Ako sa dá služba zrušiť?',
		'Ako sa u vás rušia služby?',
		'chcel by som deaktivovať službu',
		'chcem deaktivovať službu',
		'chcem zrušiť službu',
		'deaktivácia služby',
		'deaktivovanie služby',
		'deaktivovať službu',
		'K akému termínu môžem zrušiť službu?',
		'potrebujem deaktivovať jednu službu',
		'pozastavenie služby',
		'vypnutie služby',
		'Zrušenie pevných služieb',
		'zrušenie služby',
		'zrušiť službu',
	],
	HROZBA_ODCHODU__TERMINATION_TV: [
		'Chcem odpojiť telku',
		'Chcem zrušiť telku',
		'odpojte mi televízor',
		'odpojte mi telku',
		'už nechcem zapojenú telku',
		'zrušenie mágia',
		'zrušenie televízie',
	],
	HROZBA_ODCHODU__TERMINATION_TV_BALICKY: [
		'deaktivovať televízne balíčky',
		'potrebujem deaktivovať balíky k telke',
		'potrebujem deaktivovať televízne balíčky',
		'skončiť televízne balíčky',
		'treba mi deaktivovať balíky k televízii',
		'zrušiť televízne balíčky',
	],
	HROZBA_ODCHODU__TO_RIVAL_COMPANIES: [
		'Chcel by som prejsť do 02',
		'Chcel by som vyskúšať iného operátora',
		'Chcem odísť do O2',
		'Chcem zmeniť operátora',
		'idem k inému operátorovi',
		'mením mobilného operátora',
		'Prosím vás, ja chcem prejsť inam',
		'u iného operátora mi bude lepšie',
		'Volám, lebo som si našiel lepší program v 02',
	],
	HROZBA_ODCHODU__VRATENIE_HW: [
		'aké zariadenia mám vrátiť',
		'aké zariadneia musím vrátiť',
		'chcela by som vrátiť router',
		'chcem vedieť ktoré zariadenia vrátiť',
		'ktoré všetky zariadenia treba vrátiť',
		'ktoré zariadenia mám vrátiť',
		'ktoré zo zariadení musím vrátiť',
		'Potrebujem vediet ake zariadenia mam vrátiť',
		'vrátiť magiobox',
		'vrátiť router',
		'vrátiť vaše zariadenie',
		'kvôli vráteniu zariadenia',
	],
	HROZBA_ODCHODU__ZRUS_INTERNET: [
		'Budem si rušiť internet u vás.',
		'chcela by som internet zrušiť',
		'chcel by som zastaviť internet',
		'chcel by som zrušiť internet',
		'chceli by sme internet zrušiť',
		'chcem internet zrušiť',
		'chcem odpojenie internetu',
		'chcem si zrušiť váš internet',
		'chcem zastaviť internet',
		'chcem zrušiť internet',
		'internet zrušiť',
		'odhlásiť pevný internet',
		'odpojiť pevný internet',
		'odpojte mi internet',
		'poprosím vás rušiť internet',
		'potrebujem zrušiť internet',
		'potrebujem zrušiť napojenie na internet',
		'prosím odpojiť mi ten internet',
		'volám kvôli zrušeniu internetu a televízie',
		'zastaviť internet',
		'zrušenie internetu a televízie',
		'zrušiť internet',
	],
	HROZBA_ODCHODU__ZRUS_KARTU: [
		'chcem od vás o stornovanie kartu',
		'chcem si stornovať kartu',
		'chcem zrušiť kartu',
		'potrebujem stornovať kartu',
		'potrebujem zrušiť kartu',
		'stornovať kartu',
		'stornujte mi kartu',
		'žiadam o stornovanie mobilnej karty',
		'žiadam o zrušenie mobilnej karty',
		'žiadam vás o zrušenie kartu',
		'zrušiť kartu',
		'zrušte mi kartu',
	],
	HROZBA_ODCHODU__ZRUS_MOBIL: [
		'chcem zrušiť mobil',
		'potreboval by som zrušiť mobil',
		'potreboval by som zrušiť nejaké telefónne čísla',
		'potrebujem zrušiť manželov telefón lebo zomrel',
		'potrebujem zrušiť telefón',
		'žiadam o zrušenie mobilného telefónu',
		'žiadam vás o zrušenie telefónneho čísla',
		'žiadam vás o zrušenie telefónu',
		'zrušiť mobil',
		'zrušte mi mobil',
	],
	HROZBA_ODCHODU__ZRUS_PAUSAL: [
		'Budem si rušiť paušál s vami.',
		'Budem si zrušiť u vás paušál.',
		'chcel by som zrušiť paušál',
		'chceme sa informovať o zrušení paušálu',
		'chcem požiadať o zrušenie paušálu',
		'chcem rušiť paušál',
		'potreboval by som zrušiť nejaké telefónne čísla na paušáli',
		'potrebujem ukončenie paušálu',
		'potrebujem ukončiť paušál',
		'potrebujem zrušiť paušál',
		'potrebujem zrušiť paušál lebo mi končí obdobie',
		'ukončenie paušálu',
		'ukončiť paušál',
		'chcem informácie ohľadom ukončenia paušálu svojho otca',
		'zrušenie paušálu',
		'zrušiť paušál',
	],
	HROZBA_ODCHODU__ZRUS_PEVNU_LINKU: [
		'ako mám postupovať pri zrušení pevnej linky',
		'Budem si rušiť pevnú linku u vás.',
		'chcem odhlásenie pevnej linky',
		'chcem si zrušiť vašu pevnú linku',
		'ja som chcela odhlásiť pevnú linku',
		'možnosť zrušenia pevnej linky',
		'odhlásiť pevnú linku',
		'odhlásiť pevnú telefónnu linku',
		'odpojte mi pevnú linku',
		'poprosím vás rušiť telefónnu linku',
		'potrebujem zrušiť napojenie na pevnú linku',
		'potrebujem zrušiť pevnú linku',
		'prosím odpojiť mi tu pevnú linku',
		'volám ohľadom zrušenie telefónnej linky',
		'zákazník zomrel, ako zrušiť pevnú linku',
		'že mne pred mesiacom zomrela mama a chcela by som odhlásiť pevnú linku a neviem ako mám postupovať',
		'zrušenie telefónnej linky',
		'zrušiť telefónnu linku',
	],
	DOPLNKOVA_SLUZBA__ARCHIV: ['som chcel aktivovať tv archív', 'aktivovať tv archív', 'Chcem aktivovať tv archív'],
	DOPLNKOVA_SLUZBA__AUDIOTEXT: [
		'audiotext',
		'Chcel by som Audiotex',
		'Chcem si kúpiť Audiotex',
		'čo to tu je za audiotext',
		'kúpiť audiotext',
		'mám tu dajaký audiotext, zrušte mi to neviem čo to je.',
		'mám záujem zakúpiť si audiotext',
		'zrušiť audiotext',
	],
	DOPLNKOVA_SLUZBA__BLOKOVANIE: [
		'blokovanie čísel',
		'blokovanie čísla',
		'chcem blokovať číslo',
		'máte službu blokovanie čísla na pevnej linke?',
		'viem si bloknúť číslo?',
	],
	DOPLNKOVA_SLUZBA__CAKANIE_NA_HOVOR: [
		'chcem informácie o službe čakanie na hovor',
		'Mám záujem o čakanie na hovor',
		'služba čakanie na hovor',
		'aktivácia služby čakanie na hovor',
	],
	DOPLNKOVA_SLUZBA__CANCEL: [
		'chcel by som zablokovať jednu službu',
		'chcem zablokovať jednu službu',
		'potreboval by som zablokovať jednu službu',
		'potrebujem zablokovať jednu službu',
		'zablokovať jednu službu',
		'zablokujte mi jednu službu',
	],
	DOPLNKOVA_SLUZBA__CANCEL_EU_NONSTOP: [
		'chcel by som zruśiť európa non stop',
		'chcem zrušiť službu európa non stop',
		'Poprosila by som zrušiť službu európa non stop',
		'prosím o zrušenie služby európa nonstop',
		'zrušiť službu európa non stop',
	],
	DOPLNKOVA_SLUZBA__CANCEL_PAID_SMS: [
		'chcel by som zrušiť spoplatnené sms',
		'Chcem si zrušiť spoplatnené sms',
		'potrebujem zrušiť hneď spoplatnené smsky',
		'zrušiť spoplatnené sms',
		'zrušte mi okamžite spoplatnené sms',
	],
	DOPLNKOVA_SLUZBA__CLIP_CLIR: [
		'Ako sa vypína CLIR?',
		'Chcel by som si aktivovať CLIP.',
		'chcem odtajniť číslo',
		'CLEAR',
		'CLIP',
		'CLIR',
		'deaktivovať utajovanie čísla',
		'mám utajené číslo tak ho potrebujem odtajniť',
		'nechcem mať utajené číslo',
		'Nemám CLIP a chcem ho aktivovať',
		'Neviem čo je CLIR zrušte mi to.',
		'odtajnenie čísla',
		'odtajniť číslo',
		'Potrebujem deaktivovat CLEAR',
		'Potrebujem deaktivovať CLEAR',
		'potrebujem odtajniť číslo',
		'potrebujem odtajniť číslo na novom telefóne',
		'potrebujem zrušiť utajovanie čísla',
		'Treba mi doaktivovať CLIP a CLIR',
		'zrušenie utajovaného čísla',
		'zrušte mi utajovanie čísla',
	],
	DOPLNKOVA_SLUZBA__DEEZER: [
		'chcel by som info o službe deezer',
		'chcem informácie o službe deezer',
		'deezer',
		'info o službe deezer',
		'informácie o službe deezer',
		'Mám záujem o deezer',
	],
	DOPLNKOVA_SLUZBA__GOOGLE_PLAY: [
		'chcel by som google play hry',
		'chcel by som si odblokovať google play hry',
		'chcem odblokovať google play hry',
		'chcem zrušiť hry',
		'deaktivovať hry',
		'google play hry',
		'odblokovanie google play hier',
		'odblokovať google play hry',
		'rušiť, deaktivovať hry',
		'zrušiť aktiváciu hier',
	],
	DOPLNKOVA_SLUZBA__INTERESTED_IN: [
		'Aké máte doplnkové balíčky',
		'Chcem doplnkové balíčky',
		'Chcem doplnkové služby',
		'Chcem doplnkovú službu',
		'Chcem si dokúpiť doplnkovú službu.',
		'Chcem si ešte dokúpiť niečo',
		'Doplnková služba',
		'doplnkové balíčky',
		'Doplnkové služby chcem',
		'Doplnkové služby',
		'Potrebujem si doplniť ďalšiu službu',
		'zaujímajú ma doplnkové služby',
	],
	DOPLNKOVA_SLUZBA__NEMA_CLIP: [
		'keď mi niekto volá, tak nevidím číslo',
		'neukazuje mi, keď mi niekto iný volá',
		'nevidím číslo, čo mi volá',
		'nevidím číslo volajúceho',
		'nezobrazí sa mi číslo čo mi volá',
		'niekto mi volá a ja nevidím jeho číslo',
	],
	DOPLNKOVA_SLUZBA__ODBLOKOVANIE_SMS: [
		'chcem odblokovať smsky',
		'Odblokovanie SMS',
		'odblokovanie textových správ',
		'odblokovať sms',
		'odblokujte mi sms',
		'potrebujem odblokovanie sms',
		'treba mi odblokovanie sms',
	],
	DOPLNKOVA_SLUZBA__PLATBA_KARTOU: [
		'ako mám aktivovať platby cez kartu',
		'ako mám aktivovať platby kartou',
		'ako môžem nastaviť platby kartou',
		'Chcel by som poprosiť že ako mám aktivovať platby cez kartu',
		'chcem platiť cez kartu',
		'chcem platiť kartou',
		'rád by som si nastavil platby kartou',
	],
	DOPLNKOVA_SLUZBA__PREMIUM_NUMBER: [
		'Chcel by som prémiové číslo',
		'chcem požiadať o prémiové číslo',
		'mala by som záujm o premiové číslo',
		'mám záujem o prémiové číslo',
		'Môžem mať prémiové číslo?',
		'prémiové číslo',
		'rád by som získal prémiové číslo',
		'Vraj u vás môžem mať prémiové číslo. Ako?',
		'zaujímam sa o prémiové číslo',
	],
	DOPLNKOVA_SLUZBA__REDIRECTION_CALLS: [
		'Chcem presmerovanie pevnej linky',
		'Chcem presmerovať hovory keď som obsadený',
		'Chcem si presmerovat hovory',
		'Dá sa u vás nastaviť presmerovanie volania?',
		'Je možné presmerovať moje volania na iné číslo?',
		'potreboval by som presmerovať pevnú linku kvôli poruche',
		'potrebujem presmerovať pevnú linku z dôvodu jej poruchy',
		'potrebujem presmerovať pevnú linku z dôvodu poruchy',
		'požadujem presmerovanie hovorov',
		'presmerovanie hovorov',
		'presmerovanie pevnej linky',
		'presmerovanie volaní',
		'presmerovať hovory',
		'presmerovať volania',
		'žiadam vás o presmerovanie hovorov',
		'Zriadte mi presmerovanie',
	],
	DOPLNKOVA_SLUZBA__SMARTHOME: [
		'chcel by som smarthome',
		'Dá sa u vás doobjednať smart home?',
		'Máte smart home?',
		'Máte smarthome?',
		'môžem si objednať smarthome?',
		'rád by som mal smart home',
		'smarthome',
		'Smarthome je u vás v ponuke?',
		'veľmi rád by som si doobjednal smarthome',
	],
	DOPLNKOVA_SLUZBA__STREAM_ON: [
		'chcem informácie o službe stream on',
		'info o službe stream on',
		'Mám záujem o stream on',
		'služba stream on',
		'stream on',
		'žiadam viac info o službe stream on',
	],
	DOPLNKOVA_SLUZBA__T_PAYS: [
		'aktivovať platby?',
		'aktivujtemi prosím tú t-platbu?',
		'Chcel by som odblokovať mikroplatby.',
		'chcem si aktivovať t-platbu?',
		'Dali by sa aktivovať T-platby?',
		'Dá sa u vás aktivovať T-platba?',
		'Môžem si u vás aktivovať platby?',
		'Môžem si u vás aktivovať t platbu?',
		'odblokujte mi mikroplatby.',
		'odblokujte mi platby.',
		'prosím odblokovať mikroplatby.',
	],
	DOPLNKOVA_SLUZBA__VIRTUALNE_CISLO: [
		'chcel by som vedieť viac o virtuálnom čísle',
		'chcem sa spýtať na virtuálne čísla',
		'chcem sa spýtať na virtuálne číslo',
		'potreboval by som sa informovať ohľadom virtuálneho čísla ako to funguje',
		'potrebujem nejaké info o virtuálnom čísle',
		'Virtuálne číslo',
	],
	DOPLNKOVA_SLUZBA__VOICEMAIL: [
		'chcem spustiť odkazovú schránku.',
		'odkazová schránka',
		'odkazovka',
		'poprosím o spustenie odkazovky',
		'poprosím o vypnutie odkazovky',
		'treba mi zapnúť odkazovú schránku.',
		'vypnite mi odkazovú schránku',
		'Zapnite mi odkazovač.',
		'Zapnite mi odkazovku.',
		'Zapnite mi odkazovú schránku.',
	],
	DOPLNKOVA_SLUZBA__ZRUSIT_SUTAZNE_SMS: [
		'treba mi zrušenie sms správ na súťaž',
		'volám ohľadne zrušenie herných sms',
		'volám ohľadne zrušenie sms hra súťaž',
		'volám ohľadne zrušenie súťažných platených sms',
		'zrušiť súťažné sms správy',
	],
	EXISTENCIA_TKZ__BUILD_PERMIT: [
		'Chcem bližšie informácie k žiadosti k stavebnému povoleniu',
		'chcem sa spýtať na žiadosť o stavebné povolenie',
		'chcem vedieť viac o žiadosti k stavebnému povoleniu',
		'informujte ma bližšie k žiadosti k stavebnému povoleniu',
		'žiadosť o stavebné povolenie',
	],
	EXISTENCIA_TKZ__INFORMATION: [
		'Chcem bližšie informácie k TKZ',
		'chcem vedieť viac o tkz',
		'informujte ma bližšie k tkz',
		'k tkz',
		'niečo viac k tkz',
		'o TKZ',
		'Potreboval by som informacie k vyjadreniu o existencii telekomunikačných sietí',
		'potrebujem zistiť kedy prídu vytýčiť siete',
		'telekomunikacne siete',
		'TKZ',
		'viac detailov o TKZ',
		'viac info k TKZ',
		'viac informácií o TKZ',
		'viac k tkz',
		'vytýčiť siete',
	],
	HW_ZMENA_KUPA__BUY: [
		'chcela by som si kúpiť',
		'chcem kúpiť',
		'chcem si kúpiť',
		'kúpa',
		'kúpiť',
		'kúpiť si',
		'kúpu',
		'mám záujem kúpiť',
		'rada by som si kúpila',
		'rád by som si kúpil',
		'zvažujem kúpu',
	],
	HW_ZMENA_KUPA__DOSTUPNOST_MOBIL: [
		'chcel by som vedieť či je dostupný smartfón',
		'chcem vedieť či je dostupný telefón',
		'chcem zistiť či máte dostupný fón',
		'Dá sa u vás dostať ten huawei?',
		'dostupnosť mobilov',
		'dostupnosť mobilu',
		'Máte na sklade tento iPhone',
		'Máte na sklade tento telefón?',
		'Máte ten nový samsung?',
	],
	HW_ZMENA_KUPA__NAROK_NA_MOBIL: [
		'chcem zistiť či mám nárok na nový telefón',
		'Ja by som sa chcela opýtať, či by mi na paušál dali telefón',
		'mal by som nárok na smartfón?',
		'mám nárok na nový telefón?',
		'Mam u vas pausal a chcela by som vediet, ci mam nárok na telefon',
		'nárok na telefón',
		'zistiť či mám nárok na nový telefón',
	],
	HW_ZMENA_KUPA__NEW_HW: [
		'Chcel by som dokúpiť k telefónu nejaké doplnky',
		'Chcem ďalší Magio box',
		'Chcem ďalší settopbox',
		'kúpiť Extender',
		'kúpiť magio box',
		'Mám záujem o smartwatch',
		'Potrebovala by som 4 nove dialkove ovladace na televiziu znacky Magio',
		'potreboval by som nový diaľkový ovládač pre magio box',
		'potreboval by som nový ovládač',
		'potrebujem dátový kábel k nabíjačke na telefón',
		'potrebujem druhý settopbox',
		'potrebujem nový ovládač k mágiu',
		'Potrebujem vymeniť zariadenie',
		'chcel by som nové zariadenie k existujúcemu paušálu',
	],
	HW_ZMENA_KUPA__NEW_MOBILE: [
		'Ahoj, kúpila by som si nový mobil',
		'Ako si kúpim nový mobil ?',
		'Chcela by som si zobrať telefón na paušál na splátky',
		'chcel by som si objednať telefón',
		'Chcel by som si zobrať dajaký mobil s kartou',
		'Chcem nový huawei telefón',
		'Chcem nový iPhone',
		'Chcem nový telefón',
		'chcem sa informovať o kúpe telefónu',
		'Chcem si kupiť mobil',
		'chcem si kúpiť telefón',
		'chcem si objednať mobil',
		'chcem si objednať telefón',
		'chcem telefón s paušálom',
		'Chcem zobrať telefón k paušálu',
		'informácie ohľadom kúpy nového mobilného telefónu',
		'kúpa telefónu',
		'kúpila by som si mobil',
		'kúpiť iphone',
		'kúpiť mobil',
		'kúpiť smartfón',
		'mohol by som si objednať mobil?',
		'môžem mať smartfón',
		'môžem si objednať telefón?',
		'objednanie mobilu',
		'objednať mobil',
		'objednať smartfón',
		'objednať telefón',
		'o kúpe telefónu',
		'Pokazil sa mi mobil viem si zobrať nový?',
		'potrebovali by sme telefón s paušálom',
		'potrebujem telefón s paušálom',
		'volám kvôli o môjmu telefónu',
	],
	HW_ZMENA_KUPA__NEW_PEVNA_LINKA: [
		'chcel by som novú pevnú linku',
		'chcem novú pevnú linku',
		'mám záujem o novú pevnú linku',
		'môžem si kúpiť novú pevnú linku?',
		'novú pevnú linku',
		'novú pevnú linku potrebujem',
		'pretože potrebujem no telefón na pevnú linku',
	],
	HW_ZMENA_KUPA__NEW_ROUTER: [
		'Chcel by som využiť Prémiový router',
		'Chcem info ku kúpe nového routra',
		'Chcem Nový router',
		'Chcem prenajať Nový router',
		'chcem router do prenájmu',
		'informujem sa, že ako kúpiť nový router',
		'Ja si potrebujem kúpiť nový router',
		'kúpa nového routra',
		'potreboval by som prenájom routera',
	],
	HW_ZMENA_KUPA__NOVY_PRISTROJ: [
		'chcem si kúpiť nový telefón na pevnú linku',
		'mám záujem o nový prístroj na pevnú linku',
		'nákup tlačidlového telefónu k pevnej linke',
		'nový telefón na pevnú linku by som potrebovala',
		'potrebovala by som nový telefónny aparát prístroja na pevnú linku',
		'treba mi vymeniť starý aparát na pevnú linku',
	],
	HW_ZMENA_KUPA__PONUKA_KOLOBEZKA: [
		'aby som zistil o elektrické kolobežke, či ju máte dostupnú',
		'chcem zistiť či máte dostupnú elektrickú kolobežku',
		'elektrická kolobežka',
		'máte dostupnú xiaomi elektrickú kolobežku',
		'máte v ponuke kolobežku?',
		'ohľadne kolobežky elektrickej',
	],
	HW_ZMENA_KUPA__PONUKA_MOBIL: [
		'akú máte ponuku mobilov?',
		'akú máte ponuku telefónov?',
		'chcem vedieť akú máte ponuku na telefóny',
		'chcem vedieť ponuku na telefóny',
		'ponuka mobilných telefónov',
		'ponuka mobilov',
		'ponuka smartfónov',
		'ponuka telefónov',
		'zaujíma ma akú máte ponuku na mobily',
		'zaujíma ma ponuka telefónov',
	],
	HW_ZMENA_KUPA__TABLET: [
		'Chcel by som od vás Tablet',
		'chcem si kúpit tablet',
		'jedná sa o tablet',
		'kúpil by som si tablet',
		'kúpiť tablet',
		'rád by som si kúpil tablet',
	],
	HW_ZMENA_KUPA__VYMENA: [
		'chcela by som si vymeniť',
		'chcem si vymeniť',
		'chcem vymeniť',
		'rada by som si vymenila',
		'rád by som si vymenil',
		'výmena',
		'vymeniť',
		'vymeniť si',
		'výmenu',
	],
	HW_ZMENA_KUPA__VYMENA_MAGIOBOX: [
		'chcel by som vymeniť magiobox',
		'chcem vymeniť magiobox',
		'potrebujem vymeniť magiobox',
		'treba mi zameniť magiobox',
		'výmena magioboxu',
		'vymeniť magiobox',
		'zameniť magiobox',
	],
	HW_ZMENA_KUPA__VYMENIT_MOBIL: [
		'chcel by som vymeniť svj mobil za nový',
		'chcem si zmeniť starý telefón za nový mobil',
		'chcem vymeniť starý telefón za nový',
		'rada by som vymenila smartfón za nový',
		'volám kvôli výmene telefónu',
		'volám o zmene telefónu',
	],
	HW_ZMENA_KUPA__ZMENA_PEVNEJ_LINKY: [
		'chcel by som vymeniť pevnú linku',
		'chcel by som zameniť telefónnu linku',
		'chcel by som zmeniť pevnú linku',
		'chcel by som zmeniť telefónnu linku',
		'chcem vymeniť pevnú linku',
		'chcem vymeniť telefónnu linku',
		'volám kvôli pevnej linke chcela by som zmeniť niečo',
		'vymeniť pevnú linku',
		'vymeniť telefónnu linku',
		'zameniť pevnú linku',
		'zmeniť pevnú linku',
	],
	ZMENA_TECHNOLOGIE__TO_OPTIC_INTERNET: [
		'chcel by som zmeniť internetové pripojenie na optické',
		'chcel by som zmeniť pripojenie z metalického na optické',
		'Chcem prejsť na tú optiku',
		'Chcem zmeniť ADSL na optiku',
		'chem vás požiadať o zmenu internetového pripojenia na optiku',
		'Dá sa zmeniť internet na optický?',
		'Mám vo vchode dotiahnutú optiku, chcem na ňu prejsť',
		'Môžeme tu prejsť na optiku',
		'Rád by som prešiel na optiku',
	],
	ZMENA_TECHNOLOGIE__TYPE_OF_INTERNET: [
		'Bolo by možné zmeniť typ internetového pripojenia?',
		'Chcela by som zmeniť ADSL internet',
		'Chcela by som zmeniť ADSL',
		'Chcel by som si zmeniť typ internetu.',
		'Chcem prejsť na VDSL',
		'Chcem zmeniť ADSL na VDSL',
		'Chcem zmeniť typ internetového pripojenia',
		'Mám záujem o upgrade ADSL na VDSL',
		'Rada by som zmenila typ internetu',
		'žiadam o zmenu typu internetového pripojenia',
	],
	ZMENA_TECHNOLOGIE__WHEN_OPTIC_INTERNET: [
		'Kedy bude spustená optika',
		'Kedy mi zapnete optický internet?',
		'Kedy plánujete spustiť optiku?',
		'Plánuje sa u nás optika?',
		'termín spustenia optiky chcem',
		'viete mi dať dátum keď už bude spustená optika',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__AKTIVOVANA_INA_SLUZBA: [
		'aktivovali ste mi inú službu než som si objednal',
		'aktivovali ste mi službu, ktorú som nechcel',
		'aktivovali ste mi službu, ktorú som si neobjednal',
		'ja som si inú službu chcel aktivovať',
		'ja som si neobjednal službu, ktorú ste mi aktivovali',
		'kúpil som si inú službu než ste mi aktivovali',
		'objednal som si aktiváciu inej služby',
		'spustili ste mi inú službu ako som si kupoval',
		'zapli ste mi inú služb než som chcel',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__CANCEL: [
		'Chcem zrušiť objednavku',
		'Chcem zrušiť objednávku',
		'Mám záujem zrušiť objednávku',
		'potrebujem zrušiť objednávku',
		'rád by som moju objednávku zrušil',
		'rušenie objednávky',
		'ruším dnešnú objednávku',
		'ruším objednávku',
		'ruším svoju objednávku',
		'zrušiť objednávku',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__CANCEL_ORDERED_THINGS: [
		'Chcem zrušiť objednané veci',
		'mám záujem zrušiť objednané veci',
		'nemám už záujem o objednané veci',
		'no ja ruším všetky objednané veci',
		'objednané veci ruším',
		'rád by som zrušil objednané veci',
		'rušenie objednaných vecí',
		'ruším všetky objednané veci',
		'zrušiť objednané veci',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__CHCE_CISLO_OBJEDNAVKY: [
		'chcel by som číslo objednávky',
		'chcem číslo na objednávky',
		'dajte mi číslo objednávky',
		'mi dajte číslo na moju objednávku',
		'potreboval by som číslo na objednávky',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__CHCE_CISLO_PODNETU: [
		'chcel by som číslo podnetu',
		'chcem číslo na podnetu',
		'dajte mi číslo podnetu',
		'podnet',
		'mi dajte číslo na podnet',
		'potreboval by som číslo na podnet',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__COMPLAINT: [
		'reklamovať potrebujem a platbu',
		'reklamovať omylom ste mi stiahli za paušál keďže ja už nie som u vás',
		'Ako môžem reklamovať službu?',
		'Chcel by som reklamovať službu',
		'chcem podať sťažnosť',
		'Chcem riešiť reklamáciu',
		'chcem sa sťažovať',
		'Kam sa mám obrátiť ohľadom reklamácie?',
		'mám na vás sťažnosť',
		'Nefunguje mi objednaná služba',
		'no potrebujem reklamovať',
		'Potrebujem niečo zreklamovať',
		'potrebujem pomoc s reklamáciou',
		'potrebujem sa vám posťažovať',
		'Rád by som reklamoval službu',
		'Reklamácia',
		'reklamácie by som chcela riešiť',
		'reklamácie chcem riešiť',
		'reklamácie',
		'reklamácie potrebujem riešiť',
		'reklamáciu chcem riešiť',
		'sťažnosť',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__COMPLAINT_SOLUTION: [
		'kedy bude vybavená moja reklamácia?',
		'Kedy bude vyriešená moja reklamácia?',
		'kedy vybavíte moju reklamáciu?',
		'Kedy vybavíte moju žiadosť o reklamáciu?',
		'Kedy vyriešite moju reklamáciu?',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__COMPLAINT_STATUS: [
		'ako je na tom reklamácia nefunkčného zariadenia?',
		'chcela by som sa informovať či evidujete v systéme reklamáciu',
		'evidujete moju reklamáciu?',
		'máte v systéme moju reklamáciu?',
		'môžete mi skontrolovať moju reklamáciu?',
		'Prosím o kontrolu reklamácie',
		'V akom stave je reklamácia nefunkčného HW?',
		'V akom stave je reklamácia nefunkčného prístroja?',
		'V akom stave je reklamácia nefunkčného zariadenia?',
		'V akom stave je reklamácia nefunkčnej služby?',
		'žiadam vás o kontrolu reklamácie',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__CUDZIA_OBJEDNAVKA: [
		'doručili ste mi cudzie veci',
		'Doručili ste mi zmluvu a s ňou aj wifi router, ja som ho však nemala objednaný',
		'došlo k chybnej zásielke',
		'Kuriér mi doručil cudziu zásielku',
		'Poslali ste mi úplne niečo iné, než čo som si objednal',
		'priniesli ste mi balík ale ja som nič nekupoval',
		'prišiel mi od vás balík, ale ja som nič neobjednával',
		'prišiel nám na dobierku nejaký balík a nevieme o čo sa jedná',
		'prišlo nám niečo na dobierku a nevieme o čo sa jedná',
		'V balení mám iné veci',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__KOD_PREVZATIE: [
		'chcel by som požiadať o kód na prevzatie zásielky',
		'chcem kód na prevzatie mojej zásielky',
		'potreboval by som kód na prevzatie zásielky',
		'potrebujem kód na prevzatie zásielky',
		'treba mi kód na prevzatie zásielky',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__MAGIOBOX: [
		'chcel by som reklamovať magio box',
		'chcem reklamovať Magio GO TV Box',
		'potrebovala by som zreklamovať magio box',
		'potreboval by som reklamovať magiobox',
		'potrebujem reklamovať magiobox',
		'reklamácia Box',
		'reklamácia magiobox',
		'reklamovať Magio Box',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__NAJST_ZASIELKU: [
		'nájsť moju zásielku',
		'potreboval by som nájsť zásielku',
		'potrebujem nájsť zásielku telefónu',
		'potrebujem vyhľadať moju zásielku',
		'vedeli by ste mi vyhľadať zásielku',
		'viete nájsť moju zásielku?',
		'vyhľadať moju zásielku',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__NEDODANA_SLUZBA: [
		'ešte stále mi to nedošlo',
		'ja som si u vás objednal telefón s paušálom a stále mi to nedošlo',
		'ja som si u vás vybral telefón s paušálom, ešte stále mi to nedošlo',
		'nedostal objednaný tovar ?',
		'neprišla mi objednávka',
		'prečo som este nedostal objednaný tovar ?',
		'Prečo som ešte nedostal objednávku ?',
		'prečo som este nedostal tovar čo som mal objednaný ?',
		'prečo ste mi ešte stále nedodali moju službu?',
		'stále mi neprišla objednávka',
		'Stále ste mi nedodali moju službu',
		'Stále ste mi nedodali službu',
		'u vás som si vybral telefón s paušálom, ešte stále mi nedošiel',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__NEOZVALI_SA: [
		'nikto nezareagoval na moju reklamáciu',
		'reklamoval som službu a stále sa mi nikto neozval',
		'Som reklamovala telefón a ešte ste sa mi neozvali',
		'Som reklamovala telku a stále nič',
		'Viete čo, ja mám u vás reklamáciu a zatiaľ sa mi nikto neozval',
		'Volám kvôli reklamácii, mal sa mi niekto od vás ozvať ale neozval',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__OBJEDNAVKA: [
		'chcem ohľadom objednávky',
		'mám u vás objednané',
		'objednala som si',
		'volám kvôli a objednávke internetu',
		'objednala som si internet',
		'objednala som si magio internet',
		'objednal som si',
		'objednal som si magio internet',
		'objednal som si mobil',
		'objednal som si u vás',
		'Objednávka',
		'Objednavku na Magiu',
		'potreboval by som riešiť moju objednávku',
		'Potrebujem riešiť moju objednávku',
		'spýtať sa na bjednávku',
		'Volam ohladom objednavky, ktoru mam u vas',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__OBJEDNAVKA_EVIDENCIA: [
		'chcem sa spýtať či evidujete moju objednávku',
		'chcem vedieť či evidujete moju objednávku',
		'evidencia objednávky',
		'Evidujete moju objednávku?',
		'ja som sa chcel iba opýtať, či nám prešla objednávka ktorú sme si u vás robili',
		'potrebujem sa uistiť či ste zaevidovali moju objednávku',
		'sme si robili obejdnávku u vás a chcem sa spýtať či to prešlo alebo nie',
		'sme si robili objednávku u vás cez internet a že či nám to prešlo alebo nie',
		'som si robil objednávku a chcem vedieť či to prešlo a eviduejte ju',
		'urobil som u vás objednávku a chcem sa uistiť či to prešlo',
		'zaevidovali ste moju objednávku?',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__OBJEDNAVKA_KEDY: [
		'Ako dlho potrvá dodanie objednaného mobilu?',
		'ako dlho potrvá dodanie objednávky?',
		'čas dodania objednávky',
		'kedy dôjde môj objednaný tovar',
		'kedy dôjde telefón čo som si objednala',
		'kedy mi príde objednaný telefón',
		'koľko bude trvať dodanie objednaného tovaru?',
		'Koľko potrvá dodanie objednaných vecí?',
		'mali sme od vás objednaný telefon a nič neprišlo',
		'mali sme od vás objednaný telefon, kedy príde',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__OBJEDNAVKA_POTVRDENIE: [
		'nedošlo mi potvrdenie objednávky',
		'Neprišlo mi potvrdenie mojej objednávky',
		'neprišlo mi žiadne potvrdenie o mojej objednávke',
		'potvrdenie mojej objednávky',
		'stále nemám potvrdenie mojej objednávky',
		'stále ste mi nezaslali potvrdenie mojej objednávky',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__ORDER_STATUS: [
		'ako je na tom moja objednávka?',
		'Ako to vyzerá s mojou objednávkou?',
		'chcel by som si skontrolovať moju objednávku',
		'Chcem skontrolovať moju objednávku',
		'chcem zistiť v akom stave je moja objednávka',
		'informovať sa aký je stav mojej objednávky',
		'Mohol By som si skontrolovať moju objednávku?',
		'ohľadom stavu mojej objednávky',
		'potreboval by som vedieť aký je stav mojej objednávky?',
		'V akom stave je moja objednávka?',
		'volám ohľadom stavu mojej objednávky',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__OVERIT_AKTIVACIU: [
		'chcel by som overiť či je zapnutá služba, o ktorú som žiadal',
		'Chcem si overiť, že ste mi aktivovali službu o ktorú som požiadala',
		'overenie aktivácie služby, ktorú som si objednal',
		'overiť zapnutie služby o ktorú som žiadal',
		'potreboval by som overiť, či ste mi zapli službu, ktorú som si objednal',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__PEVNA_LINKA: [
		'chcel by som vyreklamovať pevnú linku',
		'chcem reklamovať pevnú linku',
		'chcem riešiť reklamáciu pevnej linky',
		'reklamácia pevnej linky',
		'reklamáciu pevnej linky',
		'reklamovať pevnú linku',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__VRATENIE_PENAZI: [
		'ako dlho potrvá vracanie peňazí za reklamáciu',
		'dokedy mám čakať na vrátenie peňazí za reklamáciu?',
		'Kedy mi vratite peniaze za reklamaciu ?',
		'Kedy mi vrátite peniaze za reklamáciu ?',
		'Reklamoval som službu, kedy mi vrátite peniaze?',
		'Služba nespĺňa moje očakávania, ako dlho potrvá vrátenie peňazí?',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__ZASIELKA: [
		'Chcem riešiť moju zásielku',
		'info o mojej zásielke',
		'informácie o mojej zásielke',
		'potreboval by som riešiť moju zásielku',
		'Potrebujem riešiť moju zásielku telefóna',
		'zásielka',
	],
	EXISTUJUCA_OBJEDNAVKA_REKLAMACIA__ZASIELKA_KEDY: [
		'ako dlho bude trvať kým mi dodáte zásielku',
		'Ako dlho potrvá dodanie zásielky mobilu?',
		'doba doručenia zásielky',
		'Koľko potrvá dodanie zásielky?',
		'koľko potrvá doručenie zásielky?',
		'trvanie dodania zásielky',
	],
	ADMINISTRACIA_UDAJOV__ADD: [
		'chcel by som doplniť údaje',
		'Chcem doplniť údaj',
		'chcem doplniť údaje',
		'doplnenie údajov',
		'doplniť údaj',
		'doplňte mi tento údaj',
		'mám záujem doplniť údaj',
		'mám záujem doplniť údaje',
	],
	ADMINISTRACIA_UDAJOV__CHANGE_ADDRESS: [
		'Chcem nahlásiť zmenu bydliska',
		'Chcem si preložiť služby na inú adresu',
		'chýba tam časť mojej adresy',
		'mám od vás službu na doma a potrebujem Zmeniť miesto bývania',
		'mám službu telekom ale adresu umiestnenia mám úplne inú ako mám adresu doručenia',
		'mám úplne inú adresu umiestnenia ako adresu doručenia',
		'mám úplne odlišnú adresu bydliska ako mám adresu doručenia',
		'Máte tam moju zlú adresu',
		'Môžem nahlásiť zmenu adresy?',
		'Zmeniť adresu',
		'Zmeniť bydlisko',
	],
	ADMINISTRACIA_UDAJOV__CHANGE_MAIL: [
		'chcem nastaviť mailovú adresu',
		'Chcem zmeniť kontaktný mail',
		'mám iný kontaktný mail',
		'nastavenie e-mailovej adresy',
		'nastaviť e-mailovú adresu',
		'nastaviť mailovú adresu',
		'no ale ja už som zmenil mail',
		'potrebovala by som nastaviť e-mailovú adresu',
		'používam už iný mail',
		'Už mám iný mail',
		'už som ale zmenil mail',
		'Zmenil som si svoj mail',
		'Zmeniť kontaktný mail',
		'Zmeniť môj mail',
	],
	ADMINISTRACIA_UDAJOV__CHANGE_NAME: [
		'chcela by som vám nahlásiť zmenu mena',
		'Chcem nahlásiť zmenu mena',
		'Chcem prepísať služby na iného účastníka',
		'chýba vám tam celé moje meno',
		'Dá sa mi zmeniť meno čo mám na zmluve?',
		'rada by som nahlásila zmenu mena',
		'vydala som sa a zmenila som meno',
		'zmena mena',
		'zmeníla som meno',
		'Zmeniť meno',
		'Zmeniť iniciály',
		'volali vaši kolegovia aby sme predložili paušál doma ip a chceli moje iniciály z občianskeho preukazu',
		'Zmeniť moje iniciály z občianskeho preukazu',
	],
	ADMINISTRACIA_UDAJOV__CHANGE_PHONE: [
		'chcel by som prepísať moje číslo',
		'došlo k zmene môjho čísla',
		'došlo k zmene môjho kontaktného telefónneho čísla',
		'mám iné telefónne číslo',
		'moje telefónne číslo sa zmenilo',
		'žiadam o zmenu kontaktného telefónu',
		'zmena kontaktného telefónu',
		'zmenila som svoje kontaktné číslo',
		'zmenil som číslo',
		'Zmeniť kontaktný telefón',
	],
	ADMINISTRACIA_UDAJOV__MODIFICATION: [
		'aktualizácia údajov na zmluve',
		'bolo by treba zmeniť moje údaje na zmluve',
		'Chcem si zmeniť moje údaje',
		'Chcem zmenu údajov',
		'nahlásenie zmeny občianskeho preukazu',
		'Potrebovala by som zmeniť údaje',
		'Potrebujem aktualizovať osobné údaje',
		'pozmeniť osobné údaje',
		'žiadam vás o zmenu kontaktných údajov',
		'zmena osobných údajov',
		'zmeniť osobné údaje',
	],
	ADMINISTRACIA_UDAJOV__PROSTA_ADMINISTRACIA: [
		'administrácia',
		'administrácia mojich vecí',
		'administrácia údajov',
		'administrovať',
	],
	ADMINISTRACIA_UDAJOV__WRONG: [
		'Chybne ste mi vyplnili kontaktné údaje',
		'chybne ste mi vyplnili údaje na zmluve',
		'Dostal som zmluvu so zlými údajmi',
		'Mám chybu v mene na zmluve',
		'nemám na zmluve správne údaje',
		'nemáte správne údaje na zmluve',
		'nesprávne údaje na zmluve',
		'nesprávne údaje ste mi dali do zmluvy',
		'údaje na zmluve sú nesprávne',
		'Zle ste mi vyplnili údaje na zmluve',
		'zmluva mi prišla s chybnými údajmi',
	],
	ADMINISTRACIA_UDAJOV__ZMENA_PLATCU: [
		'chcel by som zmeniť meno platcu',
		'chcel by som zmenu majiteľa',
		'chcel by som zmenu majiteľa telefónnej linky',
		'chcel by zmeniť majiteľa linky',
		'chcem prehodiť meno platiteľa',
		'ja by som chcel prehodiť iného platiteľa proste som na manželku',
		'som na niekoho iného, prosím prepísať meno platcu',
		'zemniť majiteľa',
		'zmena majiteľa',
		'zmena majiteľa linky',
		'zmeniť meno platcu',
		'zmeniť meno platiteľa',
		'zmenu majiteľa linky',
	],
	KURIER__COURIER_CONTACT_PROBLEM: [
		'Ako mám kontaktovať kuriéra?',
		'Kuriér ma zatiaľ nekontaktoval',
		'Kuriér neodpovedá',
		'Kuriér sa stále neozval',
		'Nemám od kuriéra žiaden telefonát',
		'Neviem sa skontaktovať s kuriérom',
		'Neviem sa skontatkovat s kurierom',
		'Neviem sa spojit s kurierom',
		'Neviem sa spojiť s kuriérom',
		'Prečo sa mi kuriér neozýva?',
	],
	KURIER__COURIER_LATE: [
		'kuriér mal dojsť ale nedošiel',
		'kuriér mal prísť, neprišiel',
		'Kuriér mešká',
		'Kuriér mešká príliš dlho',
		'Kuriér neprišiel',
		'Kuriér neprišiel v dohodnutý termín',
		'Kuriérovi to trvá hrozne dlho',
		'mal prísť kuriér a neprišiel',
		'mal prísť kuriér minulý týždeň a neprišiel',
	],
	KURIER__COURIER_PROBLEM: [
		'Čo je s tým kuriérom?',
		'kurier',
		'kuriér',
		'Kuriér posiela správy o doručení cez Viber a nie cez sms',
		'Mal mi prísť telefon a stále ho nemám.',
		'Minula som sa s kuriérom, doručíte mi to aj tak?',
		'nedostal som avízo od kuriéra prečo?',
		'Neprišiel mi telefón kde je?',
	],
	KURIER__INCOMPLETE_PACKAGE: [
		'Kuriér mi doručil nekompletnú zásielku',
		'Kuriér mi nedoručil kompletnú zásielku',
		'Zásielka mi prišla ale v balení mi chýba pár vecí',
		'Zásielka mi prišla nekompletná',
		'Zásielku ste mi neposlali celú',
	],
	KURIER__PARCEL_INFO: [
		'mohol by vyzdvihnúť moju zásielku niekto iný',
		'môže dakto iný vyzdvihnúť zásielku',
		'Môže niekto iný vyzdvihnúť zásielku?',
		'môže vyzdvihnúť zásielku aj iná osoba',
		'smie aj iná osoba vyzdvihnúť zásielku',
		'smie zásielku vyzdvihnúť niekto iný',
	],
	KURIER__PARCEL_PROBLEM: [
		'Kuriér mi doručil poškodenú zásielku',
		'Kuriér mi doručil rozbalenú zásielku',
		'Kuriér mi doručil rozbitú zásielku',
		'Kuriér mi pomliaždil balík',
		'Kuriér mi poškodil balík',
		'Kuriér mi úplne rozbil ten váš balík',
		'doručovanie zásielok',
	],
	TECHNIK__ARRIVAL_BAD_TIME: [
		'aby technici neprišli ku mne zapojiť telefón lebo nie som doma',
		'čas príchodu technika mi nevyhovuje',
		'Chcem si zmeniť termín s technikom',
		'Kedy príde ten technik?',
		'nechcem aby prišli technici ku mne zapojiť telefón lebo nie som doma',
		'nech technik nepríde zapájať telefón, lebo nie som doma',
		'nie som doma, tak nech technici neprídu',
		'prečo mám v SMS také rozpätie pre termín technika?',
		'prečo mám v SMS také rozpätie pre termín technika? to ako môže kedykoľvek prísť?',
		'prečo mám v SMS taký termín technika?',
		'prišla mi SMS s termínom technika a ja nemôžem byť 4 hodiny preto doma.',
		'Váš termín na technika je hrozný',
	],
	TECHNIK__DIDNT_COME: [
		'ešte ma nezapojili na internet, aj keď sme volali',
		'ešte stále ste ma nezapojili',
		'Nikto mi ešte neprišiel opraviť internet',
		'Nikto nám to neprišiel opraviť, aj keď sme volali',
		'Nikto neprišíel opraviť poruchu',
		'objednal som si u vás služby a nič sa nedeje, kedy príde technik?',
		'objednal som si u vás služby a nič sa nedeje, viete poslať technika?',
		'stále ma nikto neprišiel zapojiť',
		'technici nevošli ani do budovy',
		'technici sa neprišli pozrieť dnu.',
		'Technik neprišiel v dohodnutý termín',
		'videla som auto telekom, zrejme technici ale ani sa neprišli pozrieť dnu.',
		'zriadil som si u vás služby a technici neprišli',
		'zriadil som si vaše služby a technici neprišli',
		'zriaďoval som si u vás služby a nič sa nedeje.',
		'zriaďoval som si u vás služby a technici neprišli',
	],
	TECHNIK__DIDNT_HELP: [
		'Máme problém s technikom',
		'Po tom čo technik odišiel tak to zasa prestalo ísť',
		'Technik čo tu bol to spravil úplne zle.',
		'Technik mi nezriadil službu, lebo to technicky nie je možné',
		'Technik mi nezriadil všetky dohodnuté služby',
		'technik nám nepomohol',
		'technik nepomohol',
		'Technik všetko zmeral a stále mi to nejde',
		'Technik zle opravil veci, čo je so stavom opravy?',
	],
	TECHNIK__JOB_NOT_DONE: [
		'a kedy mi zavediete objednaný internet?',
		'by som sa chcela spýtať že kedy nám dôjdu zapojiť káblovku, už je to prekopané',
		'chcem zistiť kedy mi bude zavedený internet ktorý som objednala',
		'Kedy budeme mať nainštalovanú wifi?',
		'kedy mi bude zavedený internet ktorý som objednala',
		'kedy mi to namontujete?',
		'Kedy my to prídete nahodiť?',
		'kedy nám prídu zapojiť káblovku?',
		'Nedokončili ste mi inštaláciu',
		'Nedokončili ste mi zriadenie služby',
		'potrebujem zistiť kedy bude namontovaný internet',
		'prišli ste ale ešte to musíte dokončiť',
		'stále ste mi to nedorobili?',
		'už je to prekopané, kedy nám prídu zapojiť káblovku',
	],
	TECHNIK__NO_CONTACT: [
		'Bolo mi povedané, že prídu a nikto sa neukázal.',
		'Hlásila som poruchu a technik ma doteraz nekontaktoval',
		'mali sme dohodnutého technika a neprišiel',
		'mali ste ma kontaktovať a nič',
		'Nik ma nekontaktoval napriek prísľubu',
		'Nik sa nám neozval',
	],
	TECHNIK__REQUEST: [
		'chcel by som kontakt na servis technikov',
		'chcem kontakt na technika',
		'chcem kontakt na technikov montujúcich optickú sieť',
		'Chcem si dohodnúť návštevu technika',
		'je mi treba riadneho technika',
		'Je technik prítomný na predajni?',
		'Niečo sa nám pokazilo, potrebujeme technika',
		'Potrebovala by som technikov z Telekomu, cislo na nich',
		'potreboval by som kontakt na servis technikov',
		'potrebujem kontakt na servis technikov',
		'potrebujem nejakého technika',
		'potrebujem sa spojiť s technikom',
		'potrebujem technika',
		'Prosím o technika, neviem zapojiť internet',
		'Vie ku mne prísť technik?',
		'žiadam o technika',
	],
	TECHNIK__STATUS: [
		'mal by prísť technik od vás',
		'mal dojst technik zapojiť ma',
		'mal prísť technik',
		'No čo je s tým technikom',
		'technik mi mal prísť zapojiť',
		'technik',
		'chcel by som vedieť informácie ohľadom technika',
		'info ohľadom technika',
	],
	DOSTUPNOST_SLUZIEB__ACCESSIBILITY: [
		'dostupnosť magia',
		'dostupnosť na mojej adrese',
		'dostupnosť služieb',
		'viete mi overiť dostupnosť služieb',
		'viete mi pozrieť dostupnosť na mojej adrese',
	],
	DOSTUPNOST_SLUZIEB__ACCESSIBILITY_OPTIC_NET: [
		'chcel by som informáciu ohľadom optického internetu našej obci',
		'chcel by som vedieť ako je to s optickým internetom v našej obci',
		'dostupnosť optického internetu',
		'dostupnosť optiky',
		'potrebujem zistiť či je dostupný internet cez optické káble',
		'potrebujem zistiť či sa nachádza internet cez optické káble',
		'viete mi overiť dostupnosť internetu cez optické káble u nás',
		'viete mi pozrieť dostupnosť optického internetu na mojej adrese',
		'chcem sa spýtať aký najrýchlejší internet je v obci',
	],
	DOSTUPNOST_SLUZIEB__DOSTUPNE_SPOSOBY: [
		'chcem zistiť spôsob pripojenia na internet v našej obci',
		'chcel by som vedieť dostupné spôsob pripojenia na internet',
		'dostupnosť optického spôsobu pripojenia internetu',
	],
	DOSTUPNOST_SLUZIEB__DOSTUPNOST_NET: [
		'Aká je dostupnosť internetu na mojej adrese?',
		'Aký internet je dostupný na adrese?',
		'Aký máte na mojom čísle dostupný internet?',
		'čo je za internet dostupný u nás?',
		'potreboval by som zistiť či sa nachádza net',
		'potrebujem zistiť či sa nachádza internet',
		'zistiť dostupnosť internetu',
	],
	DOSTUPNOST_SLUZIEB__DOSTUPONOST_PEVNA_SIET: [
		'aká je dostupnosť pevných sietí u nás',
		'chcel by som informáciu o dostupnosti pevných sietí',
		'chcem sa spýtať na dostupnosť pevných sietí',
		'dostupnosť no pevných sietí',
	],
	DOSTUPNOST_SLUZIEB__UMIESTNENIE_KABLOV: [
		'aké je umiestnenie káblov',
		'ako sú umiestnené káble',
		'chcem sa informovať ohľadom umiestnenia káblov',
		'potreboval by som informáciu o umiestnení káblov',
		'Potrebujem informáciu o umiestnení káblov',
		'umiestnenie káblov',
	],
	PRELOZENIE_SLUZIEB__INTERNET: [
		'chcela by som preložiť internet na druhú adresu',
		'chcela by som riešiť prenesenie internetu na druhú adresu',
		'chcem si dať internet na druhú adresu',
		'chcem si dať internet na novú adresu',
		'chcem si preložiť internetovej krabice',
		'chcem si preložiť internet',
		'potrebujem zmeniť adresu používania internetu',
		'preloženie internetu na novú adresu',
		'preložiť internet krabičku',
		'preložiť internet',
		'preniesť krabicu od internetu na novú adresu',
		'presťahoval som, som na inej adrese a chcem tam dať svoj internet',
		'sťahujem sa a chcem si zobrať internetu na novú adresu',
		'volám ohľadom preloženia internetu',
	],
	PRELOZENIE_SLUZIEB__ODBERNE_MIESTO: [
		'ako si nastaviť novú adresu odberného miesta',
		'ako si preložím odberné miesto?',
		'chcel by som preložiť odberné miesto',
		'chcem zmeniť adresu odberu služieb',
		'chcem zmeniť účastnícku adresu',
		'potrebujeme prepísať odberné miesto',
		'potrebujem urobiť prekládku odberného miesta',
		'preloženie odberného miesta',
	],
	PRELOZENIE_SLUZIEB__PEVNA_LINKA: [
		'chcem si dať pevnú linku na inú adresu',
		'chcem si zmeniť adresu využívania pevnej linky',
		'dá sa vziať pevná linka na novú adresu?',
		'potrebujem sa spojiť s centrom služieb zákazníkov ohľadom preloženia telefonnej linky',
		'preloženie pevnej linky',
		'preložiť si pevnú linku',
		'viem si zobrať svoju pevnú linku na novú adresu?',
		'zmeniť adresu poskytovania pevnej linky',
	],
	PRELOZENIE_SLUZIEB__SLUZBY: [
		'chcem nahlásiť zmenu adresy využívania služieb',
		'chcem si dať služby na inú adresu',
		'chcem si dať služby z jednej adresy na druhú',
		'chcem si preložiť službu',
		'chcem si preniesť služby',
		'chcem zmeniť adresu poskytovania služby',
		'dať služby na inú adresu',
		'preložiť službu',
		'preložiť služby',
		'presťahoval som sa a chcem si vziať služby so sebou na novú adresu',
		'sťahujem sa, preto chcem zmeniť adresu služby',
		'viem si zobrať svoje služby na novú adresu?',
		'volám ohľadom preloženia služby',
		'zmeniť adresu služby',
	],
	PRELOZENIE_SLUZIEB__TV: [
		'chcel by som si preložiť svoju televíziu na novú adresu',
		'chcel by som zmeniť adresu využívania mojej telky',
		'chcem si dať televíziu na novú adresu',
		'chcem si preniesť televíziu na novú adresu',
		'dá sa preniesť si televíziu na inú adresu?',
		'preloženie televízie',
		'preložiť si tv',
		'presťahoval som sa, chcem si tam vziať svoju tv',
		'viem si zobrať svoju tv na novú adresu?',
	],
	AKCIOVA_PONUKA__DARCEKY: ['darčeky', 'Mám záujem o darčeky.', 'treba mi darčeky'],
	AKCIOVA_PONUKA__DVOJICKY: [
		'Chcela by som tie dvojičky,',
		'dvojičky',
		'Mám záujem o dvojičky.',
		'Som videl v televízore že ponúkate tie dvojičky.',
	],
	AKCIOVA_PONUKA__GENERAL: ['akciová ponuka'],
	AKCIOVA_PONUKA__VIAC_SLUZIEB: [
		'sa informovať a aké sú ceny keby som si zobral od vás internet aj televíziu',
		'aké sú ceny keby som si zobral od vás viac služieb',
	],
	AKCIOVA_PONUKA__VIANOCNA_AKCIA: [
		'Bude vianočná akcia?',
		'chcela novú akciu vianočnú',
		'chcela ohľadne vianočnej akcii',
		'chcela ohľadom nového paušálu vianočných zliav',
		'chcela ohľadom tých vianočných akcií niečo pozisťovať',
		'chceme využiť vianočnú akciu',
		'chcem mať vianočnú akciu čo ponúkate notebooky',
		'chcem riešiť vianočné akcie',
		'chcem vianočná akciu',
		'informácie vianočným akciám',
		'nejaká nejaký vianočné zľavy',
		'nejaké akciové zľavy vianočné',
		'teraz nejaké vianočné lepšie zľavy máte',
		'Už máte vianočnú akciu?',
		'vianočná akcia',
		'vianočné akcie služby',
		'vianočnú akciu',
		'Viete mi povedať viac o vianočnej akcii',
	],
	AKCIOVA_PONUKA__VIANOCNA_PONUKA: [
		'Budete mať vianočné ponuky?',
		'chcela by som vianočnú ponuku končí mi',
		'chcela požiadať novú ponuku vianočnú',
		'chcela vás vianočnú ponuku zobrať',
		'chcem ohľadom tej vianočnej ponuky',
		'chcem vedieť možnosti vianočnej ponuky',
		'chcem vianočnú ponuku',
		'mala záujem novú ponuku vianočnú',
		'mám záujem vianočnú novú ponuku',
		'vianočná ponuka',
		'vianočnú ponuku',
	],
	AKCIOVA_PONUKA__VIANOCNE_VECI: [
		'Akú máte vianočnú kampaň',
		'chcem vianočný playstation 5',
		'končí viazanosť chcem vianočné',
		'vianočný paušál',
	],
	AKCIOVA_PONUKA__ZLAVU: [
		'chcem sa opýtať dostal som ponuku a a je tam napísané v zátvorke počas viazanosti akcia',
		'chcem sa pýtať ohľadom akcie obal na nový mobil',
		'no prosím vás prišla mi správa že aby som nestratil zľavu na pevný balík na pevnú linku tak by som sa chcel spýtať ako pokračovať',
		'zľava',
		'zľava na balík',
		'zľavu',
		'zľavu zľava',
		'zľavu zľavu zľavu hlavičku',
	],
	DIGI__GENERAL: [
		'Chcem Digi',
		'Chcem Digi TV',
		'Chcem sa opýtať na Digi',
		'Digi služba',
		'Digi TV',
		'Informácie o digi TV',
	],
	SMART_KARTA__GENERAL: [
		'Ako si aktivujem smart kartu?',
		'Aktivácia smart karty',
		'Aktivovať smart kartu',
		'Chcem si aktivovať smart kartu',
		'smart karte',
		'smart karty',
		'Volám ohľadom smart karty',
	],
	ZRYCHLENIE_INTERNETU__GENERAL: [
		'Akú najvyššiu rýchlosť internetu mi viete dať?',
		'Chcel by som si zvýšiť rýchlosť internetu',
		'Chcel by som vyššiu rýchlosť mojho internetu',
		'Chcem prejsť na rýchlejší internet',
		'Chcem rýchlejšie pripojenie internetu',
		'Chcem rýchlejší internet',
		'Chcem si zrýchliť internet',
		'Mám záujem o rýchlejší internet',
		'Počul som že môžem mať vyššiu rýchlosť',
		'Potrebujem dosahovať vyššie rýchlosti',
		'Zmena nastavenia rýchlosti internetu',
		'Zrýchliť internet',
		'Potrebujem lepší internet ako mám teraz',
		'Potrebujem navýšiť internet',
		'Chcem lepší internet',
		'Chcem prejsť na lepší internet',
		'Teraz mám program internetu L a chcem XL internet',
	],
	RETENCIA__GENERAL: [
		'Chcem hovorit priamo s retenciou',
		'Končí mi zmluva, chcem hovoriť s retenciou.',
		'Môžete ma prepojiť priamo na retenciu?',
		'Prepojte ma na retenciu',
		'Prepojte ma na retenčné oddelenie',
		'retencia',
		'retencie',
		'retenciou',
		'retenciu',
		'retenčné oddelenie',
		'retenčným oddelením',
		'spojte ma na retenčné oddelenie',
		'spojte ma s retenčným oddelením',
		'Volali mi z retencie, môžete ma tam prepojiť?',
	],
	ADMINISTRATIVNY_POPLATOK__GENERAL: [
		'Čo je to administratívny poplatok?',
		'Na faktúre mám položku administratívny poplatok.',
		'Prečo mam zaúčtovaný administratívny poplatok?',
		'Volám vám kvôli administratívnemu poplatku.',
	],
	PREPIS_UCASTNIKA__GENERAL: [
		'chcel by som prepísať zmluvu na môjho kamaráta',
		'chcel by som vedieť ako prepísať číslo na 2. majiteľa',
		'chcem predať byt a budem sa musieť odsťahovať, na novej adrese nemám mágio',
		'nasťahovali sme sa do nového bytu a chceme na seba prepísať internet',
		'odsťahovali sme sa a chcem prepísať zmluvu',
		'potrebujem preneste číslo nie zo služby na moju dcéru',
		'prepísať číslo',
		'prepis telefónneho čísla',
		'zmena majitela telefónneho čísla',
	],
	MOJE_SLUZBY_A_PRODUKTY__ABOUT_CHYTRY_BALIK: [
		'chcem info o chytrom balíku',
		'chcem informácie o chytrom balíku',
		'chytrý balík',
		'čo je to chytrý balík?',
		'čo znamená chytrý balík?',
		'čo znamená chytrý balík, to je nejaký program?',
		'o chytrom balíku',
		'potrebujem info o chytrom balíku',
		'rád by som vedel viac o chytrom balíku',
	],
	MOJE_SLUZBY_A_PRODUKTY__ASK_ABOUT_MAGIO: [
		'chcela by som vedieť či mám na Magio L alebo XL program',
		'Chcem info o Magiu',
		'Chcem informáciu ohľadom môjho Magia',
		'chcem sa spýtať na magio go',
		'Chcem sa spýtať na magio',
		'ja by som sa chcel spýtať mágiom čo internet',
		'len by som chcel informáciu o magiu',
		'len by som potreboval info o magiu',
		'potrebujem sa informovať o mágiu',
		'mobilný internet mágio',
		'povedzte mi, čo máme za magio program',
		'mágio televízia a internet',
		'mám nárok na vysielanie mágia go ak mám od vás balík troch služieb pevnú linku internet a televízor',
	],
	MOJE_SLUZBY_A_PRODUKTY__CALLS: [
		'Aké mám volania?',
		'aké mám volania v paušále',
		'Aký mám paušál na volanie?',
		'koľko minút mám na volania?',
		'koľko volaní mám k dispozícii',
		'mám neobmedzené volania?',
		'Mám u vás neobmedzené volania?',
		'mám u vás volania zadarmo',
		'neviem aké mám volania',
	],
	MOJE_SLUZBY_A_PRODUKTY__CHANGE: [
		'chcem prejsť na iný program internetovej služby',
		'chcem zmeniť rozsah služby',
		'chcem zmeniť rozsah služieb',
		'chcem zmeniť rozsah užívania služby',
		'chcem zmenu programu internetovej služby',
		'potrebujem prejsť na iný program internetovej služby',
		'potrebujem trochu zmeniť užívanie služby',
		'potrebujem zmeniť užívanie služby',
		'prejsť na iný program internetovej služby',
		'treba mi zmeniť užívanie služby',
		'zmena internetového programu',
		'zmena internet programu',
		'zmena programu internet',
		'Zmena služby',
		'Zmena užívania služby',
		'zmeniť služby',
		'zmeniť trochu užívanie služby',
		'zmeniť užívanie služby',
		'zmenu programového balíčka',
		'zmenu programu internet',
	],
	MOJE_SLUZBY_A_PRODUKTY__CHCEM_INFO: [
		'chcel by som overiť si svoj telefón a služby',
		'Chcem informacie o svojich produktoch',
		'Chcem informácie o svojich službách',
		'chcem si overiť svoj telefón a svoje služby',
		'Info o službách',
		'Informácia o službách',
		'informácie o mojich službách',
		'Informácie o produktoch',
		'Informácie o zmenách',
		'potrebujem overiť si svoj telefón a služby',
		'potrebujem si overiť svoj telefón a služby ktoré využívam',
		'preverenie zoznamu služieb ktoré využívam',
		'preveriť zoznam služieb ktoré používam',
		'produkt',
		'produkty',
		'prosíme preveriť zoznam služieb zákazníka',
		'prosím o preverenie zoznamu mojich služieb',
		'služba',
		'služby',
		'zoznam mojich služieb',
	],
	MOJE_SLUZBY_A_PRODUKTY__CHCEM_INFO_GENERAL: [
		'Chcela by som sa niečo opýtať',
		'chcela by som sa spýtať',
		'chcel by som sa informovať',
		'chcel by som sa vás dačo opýtať',
		'chcel by som sa vás dǎco opýtať',
		'Chcel by som vedieť viac',
		'Chcel by som viac informácií',
		'Chcem info',
		'Chcem informácie',
		'Chcem o tom viac vedieť',
		'chcem sa naň ohľadom niečoho poinformovať',
		'chcem sa vás na dačo spýtať',
		'informácia',
		'Informácie chcem',
		'informácie',
		'Informácie',
		'informáciu',
		'informovať',
		'informujte ma',
		'ja by som potrebovala vedieť',
		'Mam otázky',
		'Mám pár otázok.',
		'Potrebujem sa dačo spýtat',
		'potrebujem sa informovať',
		'potrebujem vedieť',
		'prosím vás pekne ja by som sa chcela informovať',
		'Som počula o tom, ale nemám info',
		'Vedeli by ste mi poskytnúť viac informácií?',
		'viac informácií',
		'Viete ma informovať?',
		'Zaujímalo by ma',
		'zaujíma ma',
	],
	MOJE_SLUZBY_A_PRODUKTY__CONTROL: [
		'chcel by som zistiť či mám túto službu',
		'chcem si skontrolovať či mám službu',
		'chcem s skontrolovať službu či ju mám',
		'kontrola služby',
		'mám túto službu?',
		'potrebujem zistiť či mám jednu službu',
		'treba mi kontrolu jednej služby, že či ju mám',
	],
	MOJE_SLUZBY_A_PRODUKTY__INFO_CHANGE_GENERAL: [
		'chcel by som sa informovať o zmene',
		'chcem sa informovať o zmene',
		'info o zmene',
		'informácie o zmene',
		'informáciu o zmene',
		'informovať sa o zmene',
		'potreboval by som sa informovať o zmene',
	],
	MOJE_SLUZBY_A_PRODUKTY__INFO_MY_NEW_PAUSAL: [
		'mám nabehnutý nový paušál?',
		'nabehol mi nový paušál?',
		'už mi beží nový paušál?',
		'už používam môj nový paušál?',
		'využívam už môj nový paušál?',
	],
	MOJE_SLUZBY_A_PRODUKTY__INFO_MY_NEW_PROGRAM: [
		'beží mi už nový program?',
		'informácie k novej službe',
		'je môj nový program nabehnutý?',
		'nabehol už môj nový program',
		'potrebujem informácie k novej službe ktorú som si objednala',
		'potrebujem zistiť či mi nabehol nový program',
		'už beží môj nový program?',
	],
	MOJE_SLUZBY_A_PRODUKTY__INFO_MY_NEW_SERVICE: [
		'je moja nová služba zapnutá',
		'je zapnutá moja nová služba?',
		'mám už zapnutú moju novú službu?',
		'prosím vás, už ste mi zapli moju novú službu',
		'zapli ste mi moju novú službu',
	],
	MOJE_SLUZBY_A_PRODUKTY__INFO_SIM_KARTA: [
		'info ohľadom sim karty',
		'mm otázky na sim karty',
		'Na SIM kartu by som sa chcela opýtať',
		'potrebuje informácie ohľadom sim karty',
		'Sim karta',
		'spýtať sa na simku',
	],
	MOJE_SLUZBY_A_PRODUKTY__INTERNET: [
		'Aký mám u vás kúpený internet?',
		'Čo za internet u vás mám?',
		'Mal by som záujem informovať sa o internete na mojom mene',
	],
	MOJE_SLUZBY_A_PRODUKTY__INTERNET_IP_ADDRESS: [
		'chcel by som konfiguráciu ip adresy',
		'chcem konfiguráciu ip adresy',
		'chcem vedieť či mám od vás pevnú IP adresu',
		'mám od vás pevnú IP adresu?',
		'mám od vás zriadenú pevnú ip adresu',
		'pevnú IP adresu mám od vás?',
		'potreboval by som konfiguráciu ip adresy kvôli kamerovému systému',
		'potrebujem konfiguráciu ip adresy kvôli kamerovému systému',
		'potrebujem konfiguráciu ip adresy',
		'rád by som vedel či mám pevnú IP adresu od vás',
		'zriadili ste mi pevnú ip adresu?',
	],
	MOJE_SLUZBY_A_PRODUKTY__INTERNET_PRICE: [
		'Akú mám cenu za internet ?',
		'čo stojí môj internet ?',
		'Koľko platím za internet?',
		'Koľko platím za svoj internet?',
		'Koľko platím za váš internet ?',
		'Koľko stojí môj internet ?',
	],
	MOJE_SLUZBY_A_PRODUKTY__INTERNET_SPEED: [
		'Akú mám rýchlosť internetu',
		'Aký rýchly je môj internet',
		'Chcel by som zistiť či sa dá zväčšiť rýchlosť na internete u nas',
		'Chceme si zvýšiť rýchlosť svojho internetu',
		'potrebujem aby ste mi zdvihli rýchlosť internetu',
		'potrebujeme rýchlejší internet',
	],
	MOJE_SLUZBY_A_PRODUKTY__INTERNET_VOLUME: [
		'chcem zmeniť veľkosť dát môjho internetu',
		'chcem zmeniť veľkosť internetu',
		'chcem zmeniť veľkosť magio internetu',
		'prosím o zmenu veľkosti môjho internetu',
		'zmena veľkosti magio internetu',
		'zmeniť veľkosť dát internetu',
	],
	MOJE_SLUZBY_A_PRODUKTY__MOBILNE_DATA: [
		'aké mobilné dáta máme?',
		'chcem sa opýtať keďže máme internet od vás aké mobilné dáta využívame?',
		'chcem vedieť aké mobilné dáta od vás využívam',
		'čo za mobilné dáta u vás máme?',
		'ktoré mobilné dáta mám?',
		'máme od vás internet a chcem vedieť, aké mobilné dáta využívame?',
		'potrebujem zistiť aké mobilné dáta využívam?',
	],
	MOJE_SLUZBY_A_PRODUKTY__MOJE_CISLO: [
		'aké je moje telefónne číslo',
		'aké mám telefónne číslo',
		'manžel si zabudol číslo kúpil si u vás kartu',
		'prosím vás ja by som chcela vedieť moje číslo',
		'zabudol som svoje telefónne číslo',
	],
	MOJE_SLUZBY_A_PRODUKTY__MY_SERVICES: [
		'Aké balíčky mám u vás kúpené?',
		'Aké služby využívam a čo obsahujú?',
		'chcel by som vedieť aké balíčky som si u vás kúpil',
		'chcel by som vedieť za čo všetko platím',
		'Čo všetko u vás platím?',
		'info o svojom balíčku',
		'informácie o svojom balíčku ktorý využívam',
		'o balíčku',
		'o svojom balíčku',
		'potrebujem informácie o svojom balíčku',
		'Za aké služby platím ?',
	],
	MOJE_SLUZBY_A_PRODUKTY__NEOBMEDZENE_SMS: [
		'ako mám SMS v paušále',
		'Aký mám paušál na SMS?',
		'chcem sa opýtať či tam sú aj nejaké voľné sms',
		'koľko SMS mám k dispozícii',
		'koľko SMS mám na voľných?',
		'koľko volných sms má môj paušál?',
		'mám neobmedzené SMS?',
		'Mám u vás neobmedzené SMS?',
		'mám u vás SMS zadarmo',
	],
	MOJE_SLUZBY_A_PRODUKTY__NEPLATNA_SIM_KARTA: [
		'chcem vymeniť Sim kartu',
		'potreboval by som si vymeniť sim karta',
		'skoncila mi platnosť karty',
		'vymeniť simku',
		'vypršala mi platnosť karty',
		'zrušiť akciu čo u vás mám',
	],
	MOJE_SLUZBY_A_PRODUKTY__OVERIT_HLAS_BIOMETRIU: [
		'chcem overiť hlasovú biometriu, či mi funguje',
		'chcem zistiť či mi funguje hlasová biometria',
		'overenie hlasovej biometrie, či mi funguje',
		'overiť hlasovú biometriu',
		'potreboval by som zistiť či mi funguje hlasová biometria',
		'potrebujem zistiť či mi ide hlasová biometria',
		'zistite mi, či mi funguje hlasová biometria',
	],
	MOJE_SLUZBY_A_PRODUKTY__PARAMETRE_TELEFONU: [
		'aké sú parametre smartfónu',
		'aké sú parametre telefónu',
		'chcem zistiť parametre telefónu',
		'parametre mobilného telefónu',
		'parametre mobilu',
		'parametre smartfónu',
		'parametre telefónu',
		'potrebujem parametre gigasetu telefónu',
		'potrebujem parametre telefónu gigaset',
		'potrebujem parametre telefónu značky',
		'potrebujem zistiť parametre telefónu',
	],
	MOJE_SLUZBY_A_PRODUKTY__PAUSAL: [
		'administrácia môjho nového paušál',
		'administrovať svoj paušál',
		'Aké mám paušály?',
		'akú mám paušálku',
		'Aký je môj paušál?',
		'Aký paušál momentálne mám',
		'Aký paušál používam?',
		'chcel by som sa informovať ohľadom môjho paušálu',
		'chcel by som vedieť o aktivácii môjho nového paušál',
		'info o aktivácii môjho paušál',
		'Jaká je moja paušálka',
		'Koľko stojí môj paušál ?',
		'ktorý paušál využívam?',
		'môj paušál potrebujem riešiť',
		'ohľadne môjho mobilného paušálu',
		'potreboval by som riešiť svoj paušál',
		'potrebujem riešiť môj paušál',
		'potrebujem riešiť svoj paušál',
		'volám ohľadne svojho mobilného paušálu',
		'potrebujem informácie o svojom paušále',
		'chcel by som riešiť l paušál',
		'riešiť svoj paušál',
		'potrebujem informáciu o svojom paušále',
	],
	MOJE_SLUZBY_A_PRODUKTY__PEVNA_LINKA: [
		'aké mám služby na pevnej linke',
		'aké presne služby mám na pevnej linke?',
		'čo za služby pevnej linky využívam',
		'mám pevnú linku ale neviem, aké služby na nej využívam',
		'moje služby na pevnej linke',
		'môžete mi povedať, aké služby využívam na mojej pevnej linke?',
		'neviem jaké služby využívam na pevnej',
	],
	MOJE_SLUZBY_A_PRODUKTY__PRENOS_DAT: [
		'bolo by možné dáta preniesť?',
		'chcem sa spýtať na prenos dát',
		'dajú sa preniesť dáta?',
		'dali by sa preniesť dáta?',
		'dalo by sa preniesť dáta?',
		'dá sa preniesť dáta?',
		'je možné preniesť si dáta?',
		'je možný prenos dát?',
		'mohol by som si dáta preniesť?',
		'môžem si preniesť dáta do ďalšieho mesiaca?',
		'potrebujem odpoveď na prenos dát',
		'potrebujem sa spýtať na prenos dát',
		'preniesť dáta',
		'prenos dát',
	],
	MOJE_SLUZBY_A_PRODUKTY__SKONTROLUJ_CISLO: [
		'potrebujem overiť či funguje jedno číslo',
		'potrebujem overiť fungovanie tohto čísla',
		'Potrebujem skontrolovať funkciu jedného čísla',
		'skontrolovať funkčnosť môjho čísla',
		'treba mi skontrolovať také číslo',
	],
	MOJE_SLUZBY_A_PRODUKTY__STATUS_INFO: [
		'chcem zistiť či to mám nahodené',
		'mám to nahodené?',
		'potrebujem si pozrieť či ste mi to už nahodili',
		'treba mi zistiť či mi to už beží',
		'už mi to beží?',
	],
	MOJE_SLUZBY_A_PRODUKTY__TEST: [
		'chcel by som otestovať služby',
		'chcem otestovať službu',
		'otestovať službu',
		'otestujte mi službu',
		'potrebujem otestovať službu',
		'test služby',
		'test služieb',
	],
	MOJE_SLUZBY_A_PRODUKTY__TV: [
		'Aké kanály mám na mojej TV',
		'aké mám mať kanály na svojej televízii',
		'Aké mám programové balíčky',
		'aký tv balík mám zakúpený?',
		'Aký TV balík používam?',
		'je v mojom TV balíčku',
		'Koľko programov mám',
		'Mám nejake programy pre dospelých',
	],
	MOJE_SLUZBY_A_PRODUKTY__TV_CHANNEL_DEAKTIVOVANE: [
		'chcem sa opýtať na hbo na 30 dní zadarmo a že či to mám deaktivované',
		'chcem zistiť či som vypol službu hbo go',
		'je hbo go už deaktivované?',
		'mám deaktivované tv na 30 dní zadarmo?',
		'mám tu sms že mám deaktivované HBO',
		'prišla mi smska že mám deaktivované',
		'už ste vypli hbo go?',
		'vypol som službu hbo go',
	],
	MOJE_SLUZBY_A_PRODUKTY__TV_CHANNEL_MAM: [
		'mám kanál',
		'Mám primu?',
		'Mám stanicu markíza?',
		'mám tv nova?',
		'mám v balíčku aj stanicu',
		'Mám v balíčku stanicu JOJ?',
	],
	MOJE_SLUZBY_A_PRODUKTY__VIAC_ODBERNYCH_MIEST: [
		'ako si nastaviť dve odberné miesta',
		'ako si zariadim viac odberných miest?',
		'Dve odberné miesta by som chcel mať',
		'mám viac odberných miest',
		'môžem mať aj viac ako jedno odberné miesto?',
		'treba mi tri odberné miesta',
	],
	MOJE_SLUZBY_A_PRODUKTY__VRATENIE_HW: [
		'chcela by som sa spýtať ohľadom vrátenia satelitu',
		'Čo mám robiť keď mi po vás ostal starý hw?',
		'Čo mám robiť s HW? Dokedy a ako ho mám vrátiť?',
		'Čo mám robiť s HW?',
		'Dokedy a ako mám vrátiť hw?',
		'kedy si prídete vziať váš hw',
		'mám váš hardware, ako vám ho dať',
		'potrebovala by som informáciu ohľadom vrátenia satelitu',
	],
	MOJE_SLUZBY_A_PRODUKTY__ZARUKA: [
		'ako dlho platí záruka',
		'chcel by som sa spýtať koľko platí záruka',
		'chcem sa spýtať na dĺžku záručnej doby',
		'koľko platí záruka',
		'potrebujeme vedieť koľko platí záruka na mobil',
		'záručná doba',
		'záruka',
		'záruka na',
	],
	MOJE_SLUZBY_A_PRODUKTY__ZMENA_ODBERATELA: [
		'potrebujem zmeniť odberateľa služby',
		'treba mi zmeniť odberateľa',
		'Zmena odberateľa',
		'zmeniť odberateľa',
		'zmeniť odberateľa služby',
	],
	MOJE_SLUZBY_A_PRODUKTY__ZNIZ_PROGRAM: [
		'chcem prejsť na nižší program',
		'chcem znížiť svoj program',
		'meniť vyššiu službu na nižšiu',
		'znížiť môj program',
		'znížte mi program čo mám',
	],
	MOJE_SLUZBY_A_PRODUKTY__ZRUS_AKCIU: [
		'chcem deaktivovať akciu',
		'chcem vypnúť akciu službu',
		'chcem zrušiť akciu čo u vás mám',
		'chcem zrušiť akciu',
		'chcem zrušiť promoakciu',
		'potrebujem zrušiť zvýhodnenú službu ktorá končí',
		'treba mi zrušiť zvýhodnenú službu',
		'zrušenie zvýhodnenej služby',
		'zrušiť akciu',
		'zrušiť zvýhodnenú službu ktorá mi končí o',
		'zrušiť zvýhodnenú službu',
		'zrušte mi akciovú službu',
		'zrušte mi zvýhodnenú službu ktorá mi končí za',
	],
	MOJE_SLUZBY_A_PRODUKTY__ZRUS_AKCIU_3_MESIACE: [
		'chcel by som zrušiť trojmesačný ten rýchly internet',
		'chcem deaktivovať službu na tri mesiace',
		'chcem zrušiť akciu tri mesiace',
		'chcem zrušiť službu na tri mesiace',
		'chcem zrušiť trojmesačný akciový program',
		'deaktivovať službu akciu na tri mesiace',
		'mám tri mesiace a chcem si to zrušiť',
		'mne končí akciová ponuka na 3 mesiace a chcel by som to zrušiť',
		'poslali ste sms že končí trojmesačná ponuka',
		'volám kvôli trojmesačnej ponuke',
		'zrušiť trojmesačnú službu',
		'zrušiť trojmesačný akciový internet',
	],
	PONUKA_HLASOVYCH_PAUSALOV__ADVICE: [
		'chcel by som radu k paušálom',
		'chcem poradiť paušál',
		'chcem sa poradiť o paušáloch',
		'poradiť paušál',
		'poraďte mi nejaký paušál',
		'potreboval by som nejaké rady ohľadne paušálov',
		'treba sa mi poradiť s paušálom',
	],
	PONUKA_HLASOVYCH_PAUSALOV__ASK: [
		'chcela by som sa spýtať na služby ktoré mi poskytnete k paušálu',
		'chcem sa opýtať na paušál',
		'chcem sa opýtať na veci okolo paušálu',
		'Chcem sa porozpravat o paušáloch',
		'Chcem vedieť informácie o paušály XL',
		'informácie o ponukách k paušálu',
		'informácie paušál',
		'ja by som sa chcel informovať ohľadom paušálu',
		'Kde môžem dostať informácie o paušáloch ?',
		'Koľko máte paušálov ?',
		'Mobilny paušál',
		'paušále',
		'paušál k telefónu',
		'paušál na telefón',
		'paušál',
		'potreboval by som nejaké informácie ku paušálu',
		'potrebujem informácie k paušálu',
		'viete mi povedať info o paušále?',
	],
	PONUKA_HLASOVYCH_PAUSALOV__CALLS: [
		'chcel by som nové volania',
		'Chcem novú ponuku volaní',
		'nové volania',
		'rád by som dostal novú ponuku volaní',
		'volacie programy',
	],
	PONUKA_HLASOVYCH_PAUSALOV__CHANGE: [
		'chcel by som zmeniť paušál',
		'chceli by sme zmenu paušálu',
		'Chcem zmeniť HAPPY na ÁNO',
		'chcem zmeniť paušál',
		'Hľadám nový balík na telefón',
		'Mali sme zavolať v apríli na zmenu paušálu',
		'potrebujem zmeniť paušál',
		'treba mi zmeniť paušál',
		'Uvažujem o zmene programu',
		'zmena balíčka',
		'zmena balíčkov',
		'zmena program',
		'zmena tarif',
	],
	PONUKA_HLASOVYCH_PAUSALOV__DATA: [
		'aké dáta máte v paušáloch',
		'aký paušál má najlepšie dáta',
		'Aký paušál má najviac dát ?',
		'Koľko stoja dáta v paušáloch ?',
		'najviac ma na paušále zaujímajú dáta',
		's ktorým paušálom mám najviac dát',
	],
	PONUKA_HLASOVYCH_PAUSALOV__DOSTAL_PONUKU: [
		'chceli ste mi ponúknuť paušál',
		'ja by som mal záujem o nejakú ponuku na paušál keď som zákazníkom mám doma internet televíziu od vás',
		'mal by som záujem o ponuku na paušál keď som zákazníkom a mám televíziu od vás',
		'niekto od vás mi ponúkal ponuku na paušál',
		'ponúkali ste mi paušál',
		'ponuka na paušál',
		'volala som s vami a vraj máte pre mňa paušál',
		'volali ste mi a ponúkli paušál',
	],
	PONUKA_HLASOVYCH_PAUSALOV__NOVINKY: [
		'čo máte nové medzi paušálmi',
		'čo máte nové v oblasti paušálov',
		'mae nejaké novinky medzi paušálmi?',
		'Máte dačo nové v paušáloch?',
		'Máte dajaké nové volacie programy?',
		'máte nejaké novinky medzi paušálmi?',
		'Sa spýtať na tú smsku to mi včera prišla na ten nový paušál',
		'Teraz dostala smsku že nový paušál',
	],
	PONUKA_HLASOVYCH_PAUSALOV__OPTIONS: [
		'Aká je aktuálna ponuka paušálov?',
		'Aké máte ANO paušály?',
		'Aké máte firemné paušály?',
		'Aké máte paušály bez viazania ?',
		'Aké máte paušály?',
		'Aké máte paušály s viazanosťou ?',
		'Aké ponúkate paušály?',
		'Aké sú nové paušály ÁNO?',
		'Akú máte ponuku paušálov?',
		'chcela by som požiadať o mobil na ten paušál ktorý používam',
		'chcela by som sa len informovať o ponukách, ktoré by mi boli poskytnuté k paušálu',
		'Chcel by som aktuálnu ponuku paušálov',
		'Chcem ponuku paušálov',
		'Chcem si vybrať paušál so zariadením',
		'chcem si zobrať paušál telefónom',
		'Čo za paušál mi viete ponúknuť?',
		'môžete mi povedať viac o ponuke paušálov?',
		'paušál na telefóne',
		'Ponúkate nejaké dobré paušály?',
		'ponuku haló',
		'vybaviť si paušál na telefóne',
	],
	PONUKA_HLASOVYCH_PAUSALOV__PRICE: [
		'aká je cena paušálu?',
		'aké máte ceny paušálov?',
		'Aké sú ceny paušálov?',
		'Aké sú najlacnejśie paušály?',
		'Aký je najlacnejší paušál ?',
		'cenník paušálov',
		'Čo všetko je súčasťou najdrahších paušálov?',
		'Koľko stoja paušály čo ponúkate ?',
		'lacnejší paušál',
		'máte najlacnejší paušál?',
		'najdrahšie paušály',
		'najlacnejšie paušály',
		'ponuka drahších paušálov',
		'Potrebujem lacný paušál',
		'ste na trhu najlacnejší paušál',
		'v akom rozmedzí sa pohybujú ceny vašich paušálov?',
	],
	PONUKA_HLASOVYCH_PAUSALOV__PROGRAM: [
		'aké sú možnosti paušálneho programu',
		'možnosti paušálneho programu',
		'potrebujem vedieť aké sú možnosti programu základ',
		'potrebujem vedieť možnosti hlasového programu',
		'pozerala som si na internete vaše programy.',
	],
	PONUKA_HLASOVYCH_PAUSALOV__REKLAMA: [
		'Videla som reklamu na paušalku.',
		'videl som takú reklamu na paušál',
		'videl som zaujímavú reklamu na paušál',
		'všimla som si zaujímavú reklamu na paušál',
		'zaujímavá reklama na paušál',
	],
	PONUKA_HLASOVYCH_PAUSALOV__RIGHT_FOR: [
		'aký paušál by bol vhodný deťom',
		'Aký paušál by sa hodil pre školáka',
		'aký paušál by ste mi odporučili pre deti',
		'Aký paušál je vhodný pre seniora?',
		'Ktorý paušál je najvhodnejší pre školáka?',
		'ktorý z paušalov odporúčate pre śkolákov',
		'ktorý z paušalov sa hodí pre staršie osoby?',
		'odporučte mi paušál pre môjho dedka',
		'paušál pre babku',
	],
	PONUKA_HLASOVYCH_PAUSALOV__UPGRADE_PROGRAM: [
		'chcela by som sa spýtať na navýšenie internetu na paušál',
		'Chcem lepší program',
		'chcem navýšiť a spýtať sa na internet na paušál',
		'chcem rozšíriť program',
		'Do konca viazanosti ostáva veľa, chcem ale zlepšiť môj program',
		'Mám záujem o rozšírenie programu',
		'Mám záujem o upgrade programu',
		'Môžem upgradenúť môj program pred uplynutím doby viazanosti?',
		'navýšiť internet na paušál',
	],
	PONUKA_HLASOVYCH_PAUSALOV__VLASTNY_PAUSAL: [
		'a keď som si nevybral žiadny z vašich paušálov, môžem si vytvoriť podľa seba?',
		'Ak mi nevyhovuje žiadny paušál, môžem si nakombinovať vlastný?',
		'dalo by sa nakombinovať si vlastný paušál?',
		'keď mi nevyhovuje žiadny z vašich paušalov, môžem si vytvoriť vlastný?',
		'môžem si nakombinovať vlastný paušál?',
	],
	PONUKA_HLASOVYCH_PAUSALOV__WANT_NEW: [
		'chcela by som paušál',
		'chcela by som prejsť na nový paušál',
		'chcela by som si zobrať paušál na predajni',
		'chcela by som si zobrať paušál',
		'chcel by som nový paušál',
		'chcel by som objednať paušál',
		'chcel by som paušál',
		'Chcel by som paušál v telekome',
		'chcel by som riešiť paušál',
		'chcel by som si zobrať nový paušál',
		'chcel by som si zobrať paušál',
		'chcem nový paušál',
		'Chcem paušál bez viazanosti',
		'chcem paušál k telefónu',
		'chcem paušál',
		'Chcem paušál s viazanosťou',
		'Chcem si u vas spravit paušál',
		'Chcem si u vás spraviť paušál',
		'dajte mi nový paušál',
		'kúpiť nový paušál',
		'kúpiť paušál',
		'mal by som záujem o paušál',
		'mám záujem o nový paušál',
		'mám záujem o paušál k telefónu',
		'mám záujem o paušál',
		'Možem dostať paušál.',
		'Možem dostať paušály.',
		'no chcela by som paušál',
		'nový paušál',
		'objednať si paušál',
		'o nový paušál',
		'Potrebujem nový paušál',
		'vybrala som si paušál',
		'vybral som si paušál k zariadeniu',
		'vybral som si paušál',
		'vziať paušál',
		'zoberiem si u vás paušál',
		'zobral by som si paušál',
		'zobrať paušál',
	],
	PONUKA_HLASOVYCH_PAUSALOV__WANT_PAUSALKU: [
		'chcel by som kartu s paušálom',
		'chcem info čo sa týka paušálových kariet',
		'chcem kartu s paušálom',
		'chcem paušálovú kartu',
		'Ja by som chcela paušalku.',
		'Ja by som chcela paušalovu kartu.',
		'ja by som poprosil zistiť o paušálových kartách',
		'mala by som záujem o paušálku',
		'mám záujem o paušálku',
		'mám záujem o paušálovú kartu',
		'paušálové karty',
	],
	MEDZINARODNE_SLUZBY_ROAMING__CALLS: [
		'Môžem volať do Brazílie?',
		'Môžem volať do tejto krajiny?',
		'Môžem volať zo zahraničia?',
		'neobmedzené volania zo zahraničia',
		'potrebujem si aktivovať neobmedzené volania zo zahraničia',
		'Sú prichádzajúce hovory zo zahraničia zadarmo?',
		'volania zo zahraničia',
	],
	MEDZINARODNE_SLUZBY_ROAMING__DATA_ABROAD: [
		'Budú mi fungovať aj mobilné dáta v zahraničí?',
		'fungujú mobilné dáta v zahraničí?',
		'informácie o využívaní dát zahraničí prosím',
		'Ja by som mala chcela informácie k internetu v zahraničí',
		'Potrebujem si kúpiť dátové balíčky v Číne',
		'Potrebujem si kúpiť dátové balíčky v zahraničí',
		'využívanie dát zahraničí',
	],
	MEDZINARODNE_SLUZBY_ROAMING__EU_NONSTOP: [
		'aktivácia non stop európa',
		'aktivovať nonstop eu',
		'chcel by som využívať nonstop eu',
		'chcem balíček európa non-stop',
		'chcem nonstop eu',
		'chcem si aktivovať európa nonstop',
		'chcem využívať európa non stop',
		'európa nonstop',
		'nonstop eu',
		'zišlo by sa mi nonstop eu',
		'zišlo by sa mi používať nonstop eu',
	],
	MEDZINARODNE_SLUZBY_ROAMING__GENERAL_QUESTIONS: [
		'Ako deaktivujem roaming?',
		'Ako si aktivujem roaming?',
		'Ako vypnem roaming?',
		'Ako zapnem roaming?',
		'aky je stav mojho roaming?',
		'aký je stav môjho roaming?',
		'Chcel by som sa dozvedieť viac o roamingu',
		'chcel by som sa viac dozvediet o roamingu',
		'chcel by som si aktivovat roaming',
		'Chcem sa opytat na roaming',
		'Chcem sa opýtať na roaming',
		'Kde sa zapina roaming?',
		'Kde sa zapína roaming?',
		'mám aktivny roaming?',
		'Mám aktívny roaming?',
		'potrebujem pomoc ohľadom paušálu roamingu',
		'roaming',
	],
	MEDZINARODNE_SLUZBY_ROAMING__HOW_IT_WORKS_ABROAD: [
		'Ako funguje roaming v Ázii?',
		'Ako funguje roaming v Európskej únii?',
		'Ako sa pripojím na služby v zahraničí?',
		'Ako to funguje keď mi volá niekto zo zahraničia?',
		'ako volať do zahraničia',
		'ako volať zo slovenska do portugalska',
		'Budem môcť volať v tejto krajine?',
		'Na akého operátora v zahraničí sa mám pripojiť?',
		'Sú niekde v Európe špeciálne príplatky za používanie nejakej služby?',
	],
	MEDZINARODNE_SLUZBY_ROAMING__PRICE: [
		'Aké sú ceny za medzinárodné volania?',
		'Aké sú ceny za SMS do zahraničia?',
		'Čo stojí hovor do zahraničia?',
		'Koľko platím za smsky v EU?',
		'Koľko stoja dáta v Anglicku?',
		'Koľko stojí volanie do Španielska?',
		'Koľko zaplatim za zahraničný hovor',
	],
	MEDZINARODNE_SLUZBY_ROAMING__SMS: [
		'potrebujeme si zistite prečo mi chodia smsky keď ja som doma a z roamingového telefonátu',
		'takže by som chcela sa porozprávať roamingu prečo mi chodia smsky keď ja som doma',
		'ako si zapnúť roamingové sms?',
		'Môžem poslať SMS do zahraničia?',
		'Môžem poslať SMS na zahraničné číslo?',
		'Nechodia mi sms keď prejdem hranice.',
		'otravujú ma SMS po prechode hraníc chcem to vypnúť.',
	],
	POISTENIE__CANCEL: [
		'chcem ukončiť poistenie',
		'chcem zrušiť poistenie',
		'Dá sa zrušiť poistenie mobilného telefónu?',
		'mohol by som u vás zrušiť poistenie?',
		'Môžem zrušiť poistenie?',
		'ukončenie poistenia',
		'ukončiť poistenie',
		'zrušenie poistenia',
		'zrušiť poistenie',
	],
	POISTENIE__GENERAL_QUESTIONS: [
		'ako to je u vás s poistením',
		'čo sa stane, keď si u vás poistím telefón?',
		'Čo všetko pokrýva poistenie mobilného zariadenia?',
		'Ktoré zariadenia si môžem poistiť?',
		'Môžem sa poistiť cez smsku',
		'Môžem si poistiť zariadenie aj dodatočne?',
		'Poistenie cez SMS',
		'poistenie',
		'Vzťahuje sa poistenie mobilu aj na krádež?',
	],
	POISTENIE__MOBIL: [
		'poistenie iphoea',
		'poistenie mobilného zariadenia',
		'poistenie mobilu',
		'poistenie samsungu',
		'poistenie tabletu',
		'poistenie telefónu',
	],
	POISTENIE__OPTIONS: [
		'aké druhy poistenia ponúkate?',
		'Ake mate moznosti poistenia?',
		'Aké máte možnosti poistenia?',
		'Aké máte poistenie faktúry?',
		'aké máte poistenie?',
		'Aké máte poistenie zariadenia?',
		'Aké poistenia ponúkate?',
		'Aké sú spôsoby poistenia nového telefónu?',
		'aké sú u vás typy poistenia?',
		'aké typy poistenia máte?',
		'Máte poistenie mobilného telefónu?',
	],
	POISTENIE__PRICE: [
		'aká je cena za poistenie?',
		'akú sumu platím za poistenie?',
		'koľko peňazí platím za poistenie?',
		'koľko sa platí za poistenie?',
		'koľko stojí poistenie?',
		'platí sa veľa za poistenie?',
	],
	POISTENIE__WANT: [
		'ako to je s kúpou poistenia?',
		'chcela by som poistku na telefón',
		'Chcela by som si poistiť mobil',
		'chcela by som zabezpečiť telefón poistkou',
		'chcel by som zabezpečiť telefón poistkou',
		'Chcem kupit poistenie',
		'Chcem kúpiť poistenie',
		'chcem poistenie',
		'chcem poistku na mobil',
		'chcem poistku na telefón',
		'chcem sa poistiť',
		'Chcem si kúpiť poistenie',
		'chcem si poistiť mobil',
		'Je mozne sa u vas poistit?',
		'Je možné sa u vás poistiť?',
		'Koľko budem platiť pri poistení telefónu?',
		'kúpiť poistenie',
		'mám záujem kúpiť poistenie',
		'mám záujem o poistenie',
		'mám záujem sa poistiť',
	],
	DATOVE_BALICKY__BUY: [
		'Ako si kúpiť dáta na internet cez smsku?',
		'Chcem nove dáta',
		'Chcem si nakúpiť dáta',
		'mal by som zaujem o nove dáta',
		'Môžem si kúpiť dátový balíček cez SMS?',
		'Viete mi predať dáta?',
	],
	DATOVE_BALICKY__BUY_MORE: [
		'Chcem si doplniť dáta',
		'potrebujem doplniť mame dáta, nevie si to urobiť',
		'potrebujem predĺžiť mame internetové dáta a je v nemocnici a nevie to urobiť',
		'Viem si u vás doplniť dáta?',
		'Viem si u vás predĺžiť dáta?',
	],
	DATOVE_BALICKY__EASY_KARTA_INTERNET: [
		'chcem easy kartu s internetom',
		'chcem internet na easy kartu',
		'dajte mi internet cez easy kartu',
		'internet na easy karte',
		'Môžem mať internet na easy karte?',
	],
	DATOVE_BALICKY__INTERNET_COVERAGE: [
		'aké je vaše pokrytie internetu',
		'Ake mate internetove pokrytie?',
		'Aké máte internetové pokrytie?',
		'Aké máte pokrytie dát?',
		'Aké máte pokrytie mobilného internetu?',
		'aké veľké územie pokrýva váš internet',
		'akú časť územia pokrýva váš internet',
		'Akú máte dostupnosť mobilného internetu?',
		'Chcem dáta, ktoré sú dostupné všade',
		'Kde môžem chytiť dáta?',
		'Kde môžem chytiť mobilný internet?',
		'zaujíma ma kde všade chytím váš internet',
	],
	DATOVE_BALICKY__MOBILNY_INTERNET: [
		'chcel by som mobilný internet',
		'chcem mobilný internet',
		'chcem si kúpiť mobilný internet',
		'ja by som chcela mobilný internet',
		'mal by som záujem zakúpiť si mobilný internet',
		'mám záujem o mobilný internet',
		'mobilný internet',
	],
	DATOVE_BALICKY__NEED_VOLUME: [
		'Chcem navýšiť data',
		'Chcem navýšiť kapacitu mobilného internetu',
		'Chcem navýšiť objem dát',
		'Potrebujem aspoň 20GB dát mesačne',
		'Potrebujem aspoň 40GB dát mesačne',
		'Potrebujem aspoň 60GB dát mesačne',
	],
	DATOVE_BALICKY__NEED_VOLUME_ROAMING: [
		'kúpiť zahraničné dáta',
		'navýšiť dáta, som zahraničí a už som vyčerpala všetky dáta',
		'navýšiť dáta v zahraničí',
		'som zahraničí a už nemám dáta',
		'vyčerpala som všetky dáta zahraničí',
	],
	DATOVE_BALICKY__OFFER: [
		'Aké dátové balíky ponúkate?',
		'Aké dátové balíky u vás máte ?',
		'Aké denné dátové balíčky máte?',
		'Aké máte dátové balíčky',
		'aké máte mobilné dáta?',
		'aké mobilné dáta ponúkate?',
		'Aké sú dátové balíčky?',
		'Aké sú najlacnejšie dátové balíčky?',
		'ako vyzera ponuka datovych balickov momentalne u vas?',
		'Akú ponuku dátových balíčkov máte?',
		'Aký internet do mobilu máte?',
		'balíčky mobilných dát',
		'chcem sa spýtať na mobilné dáta',
		'Čo za dáta máte v ponuke?',
		'dátové balíčky',
		'dátový balíček',
		'Ktorý dátový balíček si mám vziať?',
		'Máte lepšie dátové balíčky?',
		'mobilné dáta',
		'Sú dostupné internetové balíčky spolu s volaniami a sms?',
	],
	DATOVE_BALICKY__SPEED: [
		'Aká je rýchlosť downloadu?',
		'Aká je rýchlosť odosielania dát?',
		'Aká je rýchlosť prijímania dát?',
		'Aká je rýchlosť uploadu?',
		'Ako rýchle dáta máte?',
		'Aký rýchly internet máte?',
		'Aký rýchly internet v mobile máte?',
		'Chcel by som čo najrýchlejší internet do mobilu',
		'Chcem dáta s čo najrýchlejším downloadom',
		'chcem dáta s čo najrýchlejším uploadom',
		'Potrebujem rýchlejśí internet',
	],
	DATOVE_BALICKY__VOLUME: [
		'Aké množstvo dát si môžem dokúpiť po prečerpaní?',
		'Aký dátový balíček obsahuje najviac dát?',
		'Chcel by som neobmedzený internet',
		'Mám vypnuté automatické navyšovanie dát v mobile po prečerpaní?',
		'Máte neobmedzené dáta do mobilu?',
		'Prenášajú sa neprečerpané dáta?',
		'prenášanie dát do ďalšieho obdobia',
		'Pridávajú sa mi k objemu dát nevyčerpané dáta z minulého mesiaca?',
	],
	TELEVIZNE_BALICKY__ARCHIVE_QUESTIONS: [
		'ako je to u vás z televíznym archívom?',
		'Koľko dní je uložených v archíve?',
		'Koľko dní má archív?',
		'Mám k dispozícii aj televízny archív?',
		'Má vaša televizia aj archív?',
	],
	TELEVIZNE_BALICKY__CHANNELS: [
		'Aké máte televízne kanály a stanice?',
		'Aké stanice sú u vás dostupné?',
		'chcela by som si dať objednať šláger 2',
		'chcel by som pozerat kanál šláger 1',
		'chcel by som pozerat kanal SPORT1',
		'chcel by som si kúpiť nové kanály prosim',
		'Chcel by som viac televíznych kanálov',
		'chcel by som zmeniť program na mágio televízii',
		'chcem si odblokovať stanicu',
		'Dajú sa dokúpiť nové kanály?',
		'dokúpiť balíček programov na tv',
		'dokúpiť balíček šláger tv',
		'hľadám kanál šláger 1',
		'Koľko staníc má Magio Go?',
		'Máte maďarské programy',
		'prečo nemám prístup k stanici TA3',
		'rozšíriť balíček programov na tv',
	],
	TELEVIZNE_BALICKY__COVERAGE: [
		'Aká je dostupnosť televízie na mojej adrese?',
		'aká je dostupnosť televízie u mňa doma?',
		'ako je to s dostupnosťou televízie na mojej adrese?',
		'ako je to s dostupnosťou u mňa doma?',
		'je dostupná televízia na mojej adrese?',
		'je u mňa doma dostupná televízia?',
		'televízia je dostupná na mojej adrese?',
	],
	TELEVIZNE_BALICKY__GET_BACK_CHANNELS: [
		'chcem naspäť televízor so všetkými kanálmi čo som mal',
		'chcem naspäť všetky kanály na televízor',
		'chcem vrátiť všetky kanály ktoré som mal',
		'naspäť všetky kanály čo som mal na televízor',
		'vráťte mi späť všetky kanály čo som mal',
		'vráťte naspäť všetky kanály, ktoré som mal',
	],
	TELEVIZNE_BALICKY__MAGIO_GO_PREMIUM: [
		'chcel by som balíček magio go premium',
		'chcem magio go premium',
		'chcem prémiový magio go balíček',
		'magio go premium',
		'magio go prémium',
		'mágio go premium',
		'mal by som záujem o magio go premium',
		'vyšší balíček magio go premium',
	],
	TELEVIZNE_BALICKY__MOBILE: [
		'ako funguje Magio Go?',
		'Ako funguje StreamOn?',
		'čo je to ten StreamOn?',
		'Máte televíziu v mobile?',
		'Na koľkých zariadeniach môžem sledovať Magio Go?',
	],
	TELEVIZNE_BALICKY__MOVIES_SERIES: [
		'Aké filmy máte v knižnici?',
		'Aké filmy máte v ponuke?',
		'Akú máte ponuku Magio Televízie?',
		'ktore filmy môžem u vas pozerat?',
		'Máte Netflix na televíziu?',
		'Máte videopožičovňu?',
		'Máte v ponuke aj filmy a seriály?',
	],
	TELEVIZNE_BALICKY__OPTIONS: [
		'Aké máte dostupné balíky na telku?',
		'Aké máte televízne balíčky?',
		'Chcem si kúpiť xl televízny balíček',
		'chcem si navoliť a balíčky do mágia',
		'chcem si navoliť a balíčky na telku',
		'Čo musím preto urobiť aby som si aktivoval športový balíček',
		'Mám záujem o maďarský balíček',
		'potrebujem ten maďarský balík',
		'televízny balíček',
	],
	TELEVIZNE_BALICKY__PRICE: [
		'Aká je cena za Magio Go?',
		'cena za Magio GO',
		'čo stojí Magio Go?',
		'koľko stojí Magio Go?',
		'zaujíma ma cena za Magio Go?',
		'zaujíma ma koľko stojí Magio Go?',
	],
	TELEVIZNE_BALICKY__VARIOUS_QUESTIONS: [
		'Ako si od vás môžem objednať telku ?',
		'Aký je zriaďovací poplatok za televíziu?',
		'čo si môžem doobjednať ešte ku TV?',
		'Môžem pozerať na mobile telku?',
		'Televízia cez kábel',
		'Televízia cez satelit',
	],
	TELEVIZNE_BALICKY__VIDEOPOZICOVNA: [
		'funguje u vás videopožičovňa?',
		'je k dispozícii videopožičovňa?',
		'je u vás aj nejaká videopožičovňa?',
		'máte k dispozícii videopožičovňu?',
		'videopožičovňa',
	],
	TELEVIZNE_BALICKY__ZRIADOVACI_POPLATOK: [
		'aká je výška zriaďovacieho poplatku za televíziu',
		'aký veľký je zriaďovací poplatok za tv?',
		'koľko je zriaďovací poplatok za televíziu',
		'koľko ma bude stáť zriaďovací poplatok za televíziu?',
		'zriaďovací poplatok za televíziu',
	],
	ZMLUVA__ABOUT: [
		'chcel by som komunikovať ohľadne zmluvy',
		'chcel by som sa opýtať na tú zmluvu',
		'chcem sa spýtať na moje zmluvy',
		'chcem sa spýtať na moju zmluvu',
		'chcem sa spýtať na tú zmluvu',
		'chcem sa spýtať na zmluvu',
		'Dostala som zmluvu a chcem sa nieco informovat k tejto zmluve',
		'Informácia o zmluve',
		'informácie ohľadne zmluvy o poskytovaní verejných služieb',
		'informácie o zmluve o poskytovaní verejných služieb',
		'máme mať novú zmluvu a chcem sa o nej poinformovať',
		'Mám otázky k mojej zmluve',
		'mám otázky o zmluve',
		'no ja som sa chcela opýtať na tú zmluvu',
		'no ja som sa chcela spýtať na tú zmluvu',
		'potreboval by som informácie ohľadne zmluvy o poskytovaní verejných služieb',
		'potreboval by som komunikovať ohľadom zmluvy',
		'potrebujem informácie ohľadne zmluvy o poskytovaní verejných služieb',
		'potrebujem komunikovať ohľadom zmluvy na internet',
		'potrebujem komunikovať ohľadom zmluvy',
		'potrebujem poriešiť zmluvy k mojim službám',
		'potrebujem vedieť dĺžku zmluvy na nedalo volať hlavným',
	],
	ZMLUVA__CONTRACTS_ADDONS: [
		'chcel by som vedieť na koho sa obrátiť s dodatkami k zmluvám',
		'Chcem niekoho kto má na starosti zmluvy a dodatky k zmluvám',
		'chcem nový dodatok k zmluve',
		'chcem sa spýtať na dodatky k zmluvám',
		'moje dodatky k zmluvam o využívaní služieb',
		'návrh dodatku v zmluve mágio internet k tomu chcem vysvetlenie',
		'no tak ja tu mám dodatok k zmluve a chcem sa informovať že čo s tým',
		'poslali ste mi zas nový dodatok k zmluve',
		'potrebujem uzavrieť nový dodatok o zmluve',
		'zmluvy a dodatky k zmluvam',
	],
	ZMLUVA__CONTRACTS_CHANGE: [
		'aktualizácia čísla zmluvy',
		'aktualizovať číslo na zmluve',
		'aktualizovať zmluvu',
		'chcel by som požiadať o zmenu zmluvy',
		'chcel by som zmeniť zmluvu',
		'chcem zmeniť zmluvu',
		'potreboval by som zmeniť zmluvu',
		'rada by som zmenila zmluvu',
		'treba zmeniť moju zmluvu',
		'zmeniť zmluvu by som potreboval',
		'zmeniť zmluvu',
	],
	ZMLUVA__CONTRACTS_PLAIN: [
		'kontrakt na internet',
		'kontrakty',
		'kontrakt',
		'moja zmluva na magio',
		'moja zmluva',
		'moje zmluvy o využívaní služieb',
		'moje zmluvy',
		'Nova zmluva',
		'zmluva k internetu',
		'Zmluva',
		'Zmluvy',
	],
	ZMLUVA__CONTRACTS_PROBLEM: [
		'chybný kontrakt',
		'mám problém so zmluvou',
		'moja zmluva má chybu',
		'problém u zmluvy',
		'problém v zmluve',
		'riešim problém so zmluvou',
	],
	ZMLUVA__CONTRACTS_SIGN_PAPER: [
		'máme u vás podpísanú zmluvu',
		'máme u vás zmluvu',
		'nemám zmluvu o využívaní služieb',
		'podpísal som vtedy zmluvu',
		'podpisovala som zmluvu',
		'podpisoval som papiere',
		'Predlzujeme Magio a prisi mi papiere podpisat a neviem kolkokrat podpisat',
		'Som dostala papiere co podpisat a neviem kolkokrat podpisat',
	],
	ZMLUVA__EXPLAIN_CONTRACT: [
		'chcela by som vysvetlenie platenia v návrhu novej zmluvy',
		'chcela by som vysvetliť návrh novej zmluvy čo sa platenia týka',
		'chcem vysvetliť návrh novej zmluvy čo sa platenia týka',
		'chcem zistiť o jaké mám za zazmluvnené momentálne služby nemôžem sa dopátrať dokumentov',
		'dostal som návrh zmluvy',
		'Potrebovala by som vysvetlit navrh novej zmluvy co sa tyka platenia',
		'Potrebovala by som vysvetlit návrh novej zmluvy co sa tyka platenia',
		'potrebujem vysvetliť návrh novej zmluvy čo sa týka platenia',
		'potrebujem vysvetliť platenie v návrhu novej zmluvy',
		'zmluvná dokumentácia',
	],
	ZMLUVA__PREPIS_INTERNETU: [
		'chcem sa informovať ohľadom internetu či sa to dá prepísať z jedného človeka na 2.',
		'dá sa prepísať zmluva k internetu z jedného človeka na druhého',
		'prepísať internet z jedného človeka na druhého',
		'prepísať zmluvu k internetu',
		'prepis internetu z jedného človeka na druhého',
	],
	ZMLUVA__STATUS: [
		'aký je stav mojich zmluv',
		'chcela by som vedieť v jakom stave sú moje zmluvy',
		'chcem vedieť aký je stav mojich zmluv',
		'chcem zistiť stav mojich zmluv',
		'potrebujem zistiť aký je stav mojich zmluv',
		'stav zmluvy',
		'stav zmluv',
		'v akom stave sú moje zmluvy',
		'zistenie stavu zmluv',
		'zistiť stav zmluv',
	],
	ZMLUVA__WHEN_CONTRACT_ARRIVES: [
		'kedy dostanem zmluvu o využívaní služieb',
		'kedy mám očakávať zmluvu o využívaní vašich služieb',
		'kedy mi doručíte zmluvu o využívaní služieb',
		'Kedy mi príde zmluva o využívaní vašich služieb',
		'kedy príde zmluva o využívaní služieb',
		'neprišla mi ešte zmluva',
		'opätovné zaslanie zmluvných podmienok',
		'prišla mi ďalšie zmluva',
		'prišla mi druhá zmluva',
		'prišla mi zmluva',
	],
	ZMLUVNA_POKUTA__GENERAL: [
		'Aká by bola zmluvná pokuta?',
		'aká ma čaká zmluvná pokuta?',
		'Akú mám zmluvnú pokutu?',
		'Akú pokutu budem platiť za predčasné zrušenie služby?',
		'kam sa dá poslať zmluvná pokuta?',
		'koľko musím zaplatiť ak chcem odísť',
		'Mám zmluvnú pokutu',
		'poplatok za odstúpenie',
		'ja by som sa chcel opýtať aký bude mať poplatok za zrušenie zmluvy v telekome',
	],
	POWERLINE_ADAPTER__GENERAL: [
		'adaptér by som chcel',
		'Chcel by som si objednať Powerline adaptér',
		'Chcem sa informovať ohľadom Powerline adaptéru',
		'Čo je to powerline adaptér?',
		'mám adaptér?',
		'Máte v ponuke nejaký powerline adaptér?',
	],
	POWERLINE_ADAPTER__INTERNET_CEZ_ZASUVKU: [
		'Ako si mám zriadiť internet cez zásuvku?',
		'Chcem si prepojiť v dome internet cez zásuvku',
		'Je možné doma rozšíriť internet cez zásuvku?',
		'Je možné si doma rozšíriť mágio cez zásuvku?',
		'Potrebujem mať internet cez zásuvku',
	],
	POWERLINE_ADAPTER__ZOSILNOVAC: [
		'zosilňovač na internet by som chcel',
		'Chcel by som si objednať zosilňovač na internet',
		'Chcem sa informovať ohľadom zosilňovača na net',
		'máte zosilňovač na internet',
		'zosilňovač na internet',
	],
	MAGIO_SECURITY__SLUZBA_ANTIVIRUS: [
		'Antivírus',
		'inštalovať antivírový program',
		'Antivírus mám od vás a nejde',
		'chcel by som Antivírus',
		'Chcem od vás ten antivír',
		'mám od vás Antivírus',
		'máte Antivírus?',
		'Počul som, že ponúkate antivírus',
		'Ponúkate Eset antivírus?',
		'Potrebujem antivírus',
		'Potrebujem antivírus security',
		'Potrebujem ochranu počítača',
		'mám u vás mágio internet a chcela by som sa spýtať ako si tam viem antivírový program nainštalovať',
	],
	MAGIO_SECURITY__SLUZBA_MAGIO_SECURITY: [
		'A dá sa u vás doobjednať si Magio Security',
		'Ako sa platí to Mágio internet security',
		'Chcel by som doobjednať Magio sekurity',
		'Chcel by som Mágio internet security',
		'Chcel by som si doobjednať Magio Security',
		'Chcem Magio sekurity',
		'chcem si zriadiť Magiou Security',
		'Čo je to Mágio internet security',
		'dali by ste mi Magio Security',
		'Koľko stojí Mágio internet security',
		'Magio Security',
		'prosím vás o Magio Security',
		'prosím vás zriadiť Magio Security',
		'rád by som si doobjednal Magio Security',
		'šlo by si tu doobjednať Magio security?',
		'Viem si tu doobjednať Magio security?',
		'zriadiť Magio Security',
	],
	I_CIR_00_01_C_FAILURES__BREAKDOWN: [
		'Bol u vás hlásený nejaký hromadný výpadok?',
		'hlási mi tu nejaký výpadok',
		'Mám výpadok, urgentne potrebujem funkčné služby',
		'Som odpojená alebo máte výpadok?',
		'ten váš krám zasa vypadol',
		'Vypadla mi telka',
	],
	I_CIR_00_01_C_FAILURES__CHYBA: [
		'má chybu',
		'máte chybu',
		'nastala chyba',
		'nejaké chyby sú tu',
		'píše to tu chyby',
		'vypisuje to nejaké chyby',
	],
	I_CIR_00_01_C_FAILURES__GENERAL: [
		'blbne mi to',
		'celkovo to nejak nejde',
		'mám problém',
		'mám problémy so signálom',
		'nefunguje',
		'nejde',
		'nejde mi',
		'nič nejde',
		'Od rána mi nič nejde ako mám teraz pracovať?',
		'prestalo mi tu všetko ísť',
		'prestalo to ísť',
		'problém',
		'problémy so signálom',
		'proste to nefunguje',
		'tak zas nejde',
		'vôbec to nejde',
		'Zas mi nejdú vaše služby',
	],
	I_CIR_00_01_C_FAILURES__INFO_PORUCHA: [
		'chcem informácie o riešení porúch',
		'chcem sa informovať o poruche',
		'informácie o poruchách',
		'potrebujem informáciu o poruchách',
	],
	I_CIR_00_01_C_FAILURES__PORUCHA: [
		'celé sa to porúchalo',
		'chcem nahlásiť poruchu',
		'máte poruchu',
		'nahlásujem poruchu',
		'nastala nam tu porucha',
		'odstrániť poruchu',
		'porucha',
		'poruchy',
		'potrebujem odstrániť poruchu',
	],
	I_CIR_00_01_C_FAILURES__SERVIS: [
		'chceme servis',
		'chceme zabezpečiť servis',
		'mal by ste nám zabezpečiť servis',
		'potrebovali by sme servis',
		'servis',
		'treba servis',
		'zabezpečiť servis',
		'zabezpečte servis',
	],
	TV_KABLOVA__CHANNELS_FAILURE: [
		'Idú mi len prvé dva programy',
		'kanály na telke nám nejdú',
		'máme poruchu televíznych programov',
		'Mám nedostupné stanice',
		'nefunguje mi televízna stanica',
		'Nefungujú niektoré programy',
		'Nejde mi káblovka, polka kanálov nefunguje',
		'nejdú mi na televízore programy',
		'Nemôžem nájsť niektoré programy',
		'neviem chytiť žiadny program',
		'niektoré kanály nám vypadli',
		'Prestala mi fungovať polka kanálov',
		'televízne kanály sú neodstupné',
	],
	TV_KABLOVA__FAILURE: [
		'Chcem nahlásiť poruchu s káblovou televíziou',
		'Chcem oznámiť poruchu káblovky',
		'Káblová televízia mi hlási poruchu',
		'Káblovka je pokazená',
		'Mám pokazenú káblovku',
		'Mám pokazenú káblovú televíziu',
		'Mám pokazenú káblovú telku',
		'mám problem s káblovkou',
		'Mám problém s káblovou televíziou',
		'Nahlasujem problém s káblovou telkou',
		'Nefunguje mi doma káblová televízia',
		'Nejde káblovka',
		'Nejdú mi programy na káblovke',
		'Pokazila sa káblovka',
		'Potrebujem niekoho aby mi opravil káblovku',
		'prestala mi ísť káblová televízia',
		'Telka na kábel mi nefunguje',
		'Zas mi nejde káblovka',
	],
	TV_KABLOVA__FAILURE_GENERALLY: [
		'chyba na televíznom prijímači',
		'chcem nahlásiť poruchu televízora',
		'dobrý chcem nahlásiť poruchu televízora',
		'hlásiť poruchu televízora',
		'mám problém s televíziou',
		'Mne nejde televízia',
		'nahlásiť poruchu televízora',
		'Nefunguje mi televízia',
		'nefunguje televízor',
		'Nejde mi spustiť telka',
		'Nejde mi televízor',
		'Nejde mi vobec televízor, utierala som prach a vypadol mi obraz',
		'Nemôžem kukat telku, lebo sa pokazila',
		'no to opraviť televízor aby som mohla pozerať televíziu',
		'porucha televízie',
		'porucha televízora',
		'porucha telky',
		'porucha tv',
		'Prestala mi fungovať telka',
		'Vypadol mi signál na TV',
		'Zas je dačo s telkou',
		'Zas mi nejde tota telka',
		'Znova mi štrajkuje telka',
	],
	TV_KABLOVA__MAGIO_FAILURE: [
		'bola búrka a nejde mi mágio televízia',
		'magio televízia mi vôbec nejde',
		'máme poruchu na mágiu televíziu',
		'na obrazovke magia televízie slabý alebo žiadny signál',
		'nefunguje mi mágio televízia',
		'Nefunguje nam mágio, už máme nahlásenú poruchu televízie',
		'nejde mágio televízia',
		'nejde mi mágio televízia',
		'pokazilo sa mi magio televízia',
		'Potrebujem opraviť Magio televízia',
		'Prestalo mi fungovať mágio televízia',
	],
	TV_KABLOVA__MAGIO_TV_FAILURE: [
		'ja mám mágio televízor, na obrazovke slabý alebo žiadny signál',
		'je porucha na magio televízii',
		'magio televízia má poruchu',
		'magio TV je uzavreté',
		'máme poruchu na mágio televízia',
		'mám prerušené magio TV',
		'mám vypnuté magio TV',
		'Nefunguje mi mágio TV',
		'nefunguje mi televízor magio',
		'nefunguje mi televízor s magiom',
		'nejde magio televízia',
		'nejde mi magio televízor',
		'nejde televizia magio',
		'neviem zapnúť mágio TV',
		'technický problém s mágiom televíziou',
		'vypadáva mágio televízia potrebujem pomôcť',
	],
	TV_KABLOVA__MAGIOSAT_FAILURE: [
		'MagioSat nefunguje',
		'magiosat nejak nefunguje',
		'magiosat nejak nejde',
		'magiosat nejde',
		'MagioSat',
		'Nefunguje mi MagioSat',
		'Prečo mi nejde MagioSat?',
		'vaš magiosat zasa nejde',
		'Zas mi nejde MagioSat',
	],
	TV_KABLOVA__SATELIT_PROBLEM: [
		'mám od vás satelitnú telku a nejde',
		'Mám problém so satelitnou televíziou',
		'Nefunguje mi doma televízia cez satelit',
		'Nejde mi satelitka',
		'Prečo mi nejde satelitná televízia?',
		'Prečo mi nejde satelitná telka?',
		'vaša satelitné televízia zasa nejde',
		'Vypadol mi signál na satelitnej TV',
	],
	TV_KABLOVA__SATELLITE_FAILURE: [
		'Chcel som si dnes pozrieť program ale váš satelit nám píše, že nemáme signál',
		'Chcem nahlásiť poruchu satelitu',
		'Ćo so satelitom, keď mi nejde?',
		'máme váš satelit, ale včera prestal ísť',
		'Potrebujem vediet co mám zase so satelitom',
		'Potrebujem vedieť čo mám zase so satelitom',
		'preblikáva mi obraz na satelite',
		'satelit je strašne nespoľahlivý',
		'Satelit nejde',
		'satelit',
		'stratili sme signál na satelite',
		'vôbec nám nejde satelit',
		'Zas mi nejde satelit',
		'Zas sa mi pokazil satelit',
	],
	TV_KABLOVA__SCREEN_FAILURE: [
		'chcela nahlásiť poruchu máme niekedy keď pozeráme televízor ani jak keby seká zastaví sa tak jak rozmaze obraz za tak seká',
		'celá televízna obrazovka je čierna',
		'Kablovku mám zaseknutu na čiernej obrazovke',
		'Káblovku mám zaseknutú na čiernej obrazovke',
		'mám zamrznutý obraz',
		'Mám zamrznutý obraz',
		'mrzne nám obraz',
		'Na telke mám čiernu obrazovku',
		'nejde mi obraz',
		'obrazovka zamŕza',
		'televízor som zapla a nejde mi obraz',
		'tv som zapol a nejde mi obraz',
		'zamrznutý obraz',
		'Zamrzol mi obraz',
		'zamrzol nám obraz',
		'zas je zamrznutá obrazovka',
	],
	MOBIL_VOLANIA__AKTIVOVAT_DATA: [
		'aktivácia dát je nefunkčná',
		'nejde mi aktivovať dáta',
		'nezafungovala aktivácia dát',
		'potrebujem si aktivovať dáta a nejde mi to',
		'treba mi dáta ale nefunguje aktivácia',
	],
	MOBIL_VOLANIA__CREDIT_CHARGE_CARD: [
		'chcel som si som si dobiť kartu a kredit mi nedošiel',
		'Chcem si dobiť kartu a nejde to',
		'dobil som si kartu a kredit mi nedošiel',
		'kredit mi nedošiel, no ja som si tú kartu dobil',
		'mi nedobilo kredit cez internet banking',
		'nedá sa mi nabiť vaša karta',
		'nedobil sa mi kredit cez internet banking a neviem že z akého dôvodu',
		'nejde dobíjanie karty',
		'nejde mi dobiť kartu',
	],
	MOBIL_VOLANIA__CREDIT_CHARGE_DATA: [
		'chcel som si som si dobiť dáta ale nefunguje to',
		'Chcem si dobiť dáta a nejde to',
		'dobil som si dáta aale neprišli mi',
		'mal by som mať dobité dáta, no nie sú',
		'mi nedobilo dáta',
		'nedá sa mi nabiť vaše dáta',
		'nedobili sa mi dáta z nejakého dôvodu',
		'nejde dobíjanie dát',
		'nejde mi dobiť dáta',
		'nemám mobilné dáta a mala by som ich mať',
	],
	MOBIL_VOLANIA__CREDIT_PREPAID_EASY_CARD_ISSUE: [
		'Mám problém, mám EASY kartu a nejde',
		'Mám problém s EASY kartou',
		'Nefunguje mi EASY karta',
		'prečo moja easy karta vykazuje problém?',
		'problém na EASY karte',
		'prosím vás, odišla mi easy karta',
	],
	MOBIL_VOLANIA__CREDIT_PREPAID_NUMBER_ISSUE: [
		'chcem nahlásiť poruchu na telefónnom čísle',
		'mám nejaké problémy s telefónnym číslom',
		'mám problém s telefónnym číslom',
		'mám technický problém a na dvoch telefónnych číslach',
		'mám technický problém a na svojom telefónnom čísle',
		'porucha na telefónnom čísle',
		'Problém s telefónnym číslom',
		's telefónnym číslom je dajaký problém',
		's týmto telefónnym číslom je akýsi problém',
	],
	MOBIL_VOLANIA__CREDIT_PREPAID_SIM_CARD_ISSUE: [
		'keď som vymenil SIM kartu, prestal mi fungovať mobil',
		'Nejde mi nový mobil vymenili sme tam SIM kartu',
		'Nejde mi nový telefón vymenili sme tam SIM kartu',
		'po výmene SIM karty mi nejde mobil',
		'po výmene SIM karty mi nejde telefón',
		'vymenila som sim kartu a telefón mi nejde',
	],
	MOBIL_VOLANIA__DATA_ABROAD: [
		'mám problém s mobilným internetom v zahraničí',
		'mobilný inernet mi nefunguje dobre v zahraničí',
		'nejde mi poriadne mobilný internet v zahraničí',
		'problém s mobilným internetom v zahraničí',
		's mobilným internetom mám v zahraničí stále nejaký problém',
		'v zahraničí mi nefunguje mobilný intenrnet',
	],
	MOBIL_VOLANIA__DATA_BREAKDOWNS: [
		'Čo mám s dátami? padáte mi',
		'internet v mobile mi často vypadáva',
		'prečo mi vypadávajú dáta?',
		'váš mobilný internet je nepoužiťeľný',
		'váš mobilný internet je nespoľahlivý',
	],
	MOBIL_VOLANIA__DATA_PROBLEM: [
		'cez mobilný internet nenačítam ani obyčajnú webovú stránku',
		'dátové pripojenie mi nejde',
		'mám problém s dátovým internetom',
		'mám problém s internetm v mobile',
		'mám problémy s dátovým internetom',
		'mobilný internet mi nefunguje poriadne',
		'Na tomto telefónnom čísle mi prestali fungovať dáta',
		'Na tomto telefónnom čísle mi prestal ísť internet',
		'Nefunguje mi dáta mi nejdú',
		'nefunguje mi dátové pripojenie',
		'nefunkčné internetové pripojenie na mobile',
		'nefunkčnosť internetu na mobile',
		'nefunkčnosť internetu v mobile',
		'nefunkčný internet na mobile',
		'nejde dátové pripojenie v mobile',
		'nejde internet v mobile',
		'Nejde mi internet v mobile',
		'Nejdú mi dáta',
		'prestalo mi fungovať dátové pripijenie v mobile',
		'problém s dátovým internetom',
		'problém s internetom v mobile',
		'problém s mobilným internetom',
		'úplná nefunkčnosť internetu na mobile',
		'váš internet v mobile mi je fakt na nič',
	],
	MOBIL_VOLANIA__DATA_SLOW: [
		'Máme pomalé dáta',
		'Mám pomalé dáta',
		'Mám problém s dátami',
		'mám spomalený mobilný internet',
		'mobilný internet mám neskutocňe spomalený',
		'na vašom mobilnom nete sa nedá nič robiť, iba mi to dookola načítava',
		'nedalo by sa nejak zrýchliť internet v mobile?',
		'pomaly mi cez mobilný internet načítava stránky',
	],
	MOBIL_VOLANIA__INCOMING_CALLS: [
		'Kamarát sa mi nevie dovolať',
		'mám problémy s prichádzajúcimi hovormi',
		'nechodia mi hovory',
		'nechodia mi zmeškané hovory',
		'nedá sa prijímať hovory',
		'nemôže sa mi nikto dovolať na telefón',
		'nemôžu sa mi dovolať',
		'neprichádzajú mi hovory',
		'Neviem prijímať hovory',
		'nikomu sa nedá dovolať na môj telefón',
		'nikto sa mi nemôźe dovolať',
		'nikto sa mi nevie dovolať',
		'prosím vás nemôže sa mi nikto dovolať na telefón',
		'som furt nedostupná, nedá sa mi dovolať',
		'vôbec mi neprichádzajú hovory',
	],
	MOBIL_VOLANIA__INTERRUPTED_CALLS: [
		'hovor mi po chvíli preruší',
		'hovory mávam prerušené',
		'na mobile mi padajú hovory',
		'nejak často sa mi prerušia hovory',
		'padajú mi hovory',
		'padajú mi hovory na mobile',
		'padá mi hovor',
		'Po chvíli mi vždy preruší hovor',
		'prečo sa mi v poslednej dobe často preruší prebiehajúci hovor?',
		'preruší sa mi každý hovor',
		'prerušuje mi stále volanie',
		'prerušuje mi volanie',
		'stále mi prerušuje volania',
		'stále mi prerušuje volanie neviem čo s tým mám robiť',
		'volanie mi prerušuje',
		'vpadáva hovor',
		'v poslednej dobe mi často padajú hovory',
		'vypadávajú mi hovory',
		'vypadáva mi hovor',
	],
	MOBIL_VOLANIA__NEDORUCENA_SMS: [
		'furt mi to tu ukazuje že sms správa nedoručena',
		'moja sms je stále značená ako nedoručená',
		'nedoručená sms',
		'sms je označená ako nedoručená',
		'sms je stále nedoručená',
		'sms je že vraj nedoručená',
		'stále mi ukazuje sms ako nedoručenú',
	],
	MOBIL_VOLANIA__NEFUNGUJE_SMS: [
		'mi nejdú MMS',
		'mi nejdú SMS',
		'nefungujú mi MMS',
		'nefungujú mi poriadne smsky',
		'nefungujú mi riadne správy mms',
		'nejak mi nefungujú sms',
	],
	MOBIL_VOLANIA__NEREGISTROVANY_V_SIETI: [
		'nejde sa mi registrovať v sieti',
		'ale nejde a niesom registrovaný',
		'nie som registrovaný v sieti',
		'po nejakej dobe píše, že som neregistrovaná sieti',
		'vaše to mi píše že nie som registrovaná v sieti',
	],
	MOBIL_VOLANIA__NEZOBRAZUJE_CELU_SMS: [
		'bola prijatá iba časť dlhšej správy sms',
		'došla mi iba časť sms správy',
		'mám prijatú iba časť dlhej sms',
		'nemám zobrazenú celú sms len časť',
		'nezobrazuje mi celú smsku',
		'nezobrazuje sa mi celá smska',
		'prišla mi len časť dlhej sms',
		'zobrazuje mi len časť dlhšej sms',
	],
	MOBIL_VOLANIA__NEZOBRAZUJE_MMS: [
		'nedá sa mi zobraziť MMS',
		'nefunguje mi zobrazovanie MMS správ',
		'Nejde mi zobraziť MMS',
		'nejde mi zobrazovanie MMS',
		'nemôžem zobraziť MMsku',
		'nezobrazujú sa mi MMS správy',
		'prečo mi nezobrazuje MMSku?',
	],
	MOBIL_VOLANIA__ODOSIELANIE_SMS: [
		'Aký je dôvod že mi nejde odoslať sms',
		'Mám kredit a aj tak neviem poslať sms do súťaže',
		'Mám kredit a aj tak neviem poslať sms MHD lístok',
		'Mám kredit a aj tak neviem poslať sms parkovanie',
		'Mám problém s posielaním SMS',
		'moje správy sa nedoručujú ľuďom',
		'nechce mi odoslať sms',
		'nedá sa mi odosielať smsky',
		'nedá sa mi odoslať sms',
		'nedoručujú sa ľuďom moje správy',
		'nedoručujú sa nikomu moje sms',
		'nefunguje mi odosielanie smsiek',
		'nefunguje mi odosielanie sms',
		'nefunguje mi odosielanie textových správ',
		'nejde mi odosielať sms',
		'nejde mi posielať ani prijímať smsky',
		'Nejde mi poslať MMS',
		'Nejde mi poslať textové správy',
		'odoslal som kamoške smsku a nedošla jej',
		'prečo mi nejde odosielať mms',
		'prišla mi od vás správa po odoslaní mms, že mi to nefunguje',
		'smsky sa nedoručujú ľuďom z môjho mobilného telefónu',
		'smsky sa nedoručujú ľuďom z môjho mobilu',
		'smsky sa nedoručujú ľuďom z môjho telefónu',
		'sms správy odoslanie zlyháva',
	],
	MOBIL_VOLANIA__OUTCOMING_CALLS: [
		'prosím pomôžte mi mobilom neviem volať',
		'mám od vás mám telefón prosím pomôžte mi mobilom neviem volať',
		'chcem volať, ale to nejde',
		'Keď chcem niekomu zavolať nefunguje to',
		'keď chcem volať, tak to nejde',
		'Mám problém s volaním',
		'Mám problém že keď chcem volať tak to nejde.',
		'mi nejdú volania',
		'Musel som vám volať z iného telefónu, lebo mi úplne odišli volania na mojom mobile',
		'nefunguje hovor',
		'nefunguje mi hovor',
		'nejde hovor',
		'nejde mi hovor',
		'nejde mi volať',
		'Nejde mi volať',
		'nejde mi volať na mobile',
		'Nejde sa mi dovolať na číslo',
		'Nikomu sa neviem dovolať',
		'Prečo mi nefuguje volanie ?',
		'vôbec sa mi nedá volať',
	],
	MOBIL_VOLANIA__PRIJIMANIE_SMS: [
		'Aký je dôvod že mi nechodia mi mmsky',
		'nechodia mi mms správy',
		'nechodia mi sms ani mm správy',
		'nechodia mi žiadne smsky',
		'nedá sa mi prijímať správy',
		'nejde mi prijímať smsky',
		'prečo mi nejde prijať žiadnu sms',
	],
	MOBIL_VOLANIA__RESTART_NET: [
		'chcel by som reštart mobilného internetu',
		'chcem reštartovať mobilný internet',
		'Potrebovala by som restartovanie mobilneho internetu',
		'Reštartovať mobilný internet',
		'reštartujte mi mobilný internet',
		'žiadam vás o reštart mobilného netu',
	],
	MOBIL_VOLANIA__ROAMING_CALLS: [
		'aktivoval som si roaming ešte pred cestou a nefunguje',
		'kam sa môžem obrátiť, keď mi nejde roaming?',
		'mám problém s číslami zo zahraničia',
		'mám problém so spojením do zahraničia',
		'mám problém so spojením na talianske telefónne číslo',
		'mám problémy spojiť sa s talianskym číslom',
		'Nedajú sa mi prijímať hovory v zahraničí',
		'Nefunguje mi roaming',
		'Neviem prijímať hovory v zahraničí',
		'Neviem volať do zahraničia',
		'neviem volať zo zahraničia, čo mám robiť?',
		'problém so spojením na talianske telefónne číslo',
		'roaming by mal byť aktívny ale nejde mi volať',
		'roaming mám zaplatený a odrazu mi nefungujú volania',
		'som v Taliansku a nedá sa mi nikomu dovolať',
		'telefón som vypol a zapol, roaming stále nič',
		'volala som priateľovi do rakúska ale nefunguje mi roaming',
	],
	MOBIL_VOLANIA__ROAMING_CHECK: [
		'ako si pozrieť či je roaming aktívny?',
		'kde nájdem či mám roaming aktivovaný?',
		'kde si môžem skontrolovať, či mám roaming aktívny?',
		'kde si skontrolovať roaming?',
		'mám roaming aktívny?',
	],
	MOBIL_VOLANIA__SIGNAL: [
		'Furt mi vypadáva signál na mobile',
		'Mám zlý signál v mobile',
		'Nefunguje mi telefón, neukazuje mi sieť',
		'nefunguje vám mobilná sieť',
		'Nemám sieť na mobile',
		'Nemám signál, keď chcem volať',
		'Nemám signál na karte',
		'problém s mobilnými sieťami',
		'slabý signál na mobile',
		'vypadáva mi sieť v mobile',
		'vypadáva mi signál v mobile',
	],
	MOBIL_VOLANIA__SIGNAL_EMERGENCY: [
		'dá sa volať iba tiesňová linka',
		'ide mi volať iba na tiesňovú linku',
		'idú mi volania len na tiesňovú linku',
		'len tiesňová linka sa dá volať',
		'môžem volať len tiesňovú linku',
		'môžem zavolať iba na tiesňovú linku',
	],
	MOBIL_VOLANIA__SILENCE: [
		'hlucho v mobile',
		'hlucho v telefóne',
		'Mám hlucho v mobile',
		'mám hluchý telefón',
		'mohli by ste mi pomôcť môj telefón je hluchý',
		'Nič nepočujem keď volám z mobilu',
		'Nič nepočujem keď zavolám ľuďom',
		'nič nepočujem s niekým volám mobilom',
		'vôbec neni počuť keď volám na mobile',
	],
	MOBIL_VOLANIA__ZAPNUT_DATA: [
		'nedarí sa mi zapnúť dáta',
		'Neviem zapnúť dáta',
		'Prečo ste mi vypli dáta',
		'zapnite mi dáta',
		'zapnúť dáta',
	],
	PEVNA_LINKA_PORUCHA__PROBLEMS: [
		'chcel som volať pevnou linkou ale mám tam stále jeden tón',
		'Dajak mi nefunguje pevná',
		'hluchá linka',
		'keď chcem volať káblovým telefónom tak mi nejde, iba tutúka',
		'linka je nefungujúca',
		'linka má poruchu',
		'máme hluchú linku',
		'mám hluchú linku',
		'mám nefungujúcu linku',
		'mám pevnú linku ale prestala ísť',
		'mám pokazenú pevnú linku',
		'mohli by ste mi pomôct mám pokazenú pevnú linku',
		'naša pevná linka sa nikam nedovolá',
		'Nedá sa mi dovolať na pevnú linku',
		'nedá sa mi volať pevnou linkou',
		'nedovolám sa starým telefónom',
		'Nefunguje nám pevná linka',
		'nefungujúca linka ma trápi',
		'nefungujúca linka',
		'nefungujúca pevná linka',
		'Nejde mi volať doma',
		'niečo sa stalo s našou pevnou linkou',
		'Pevná linka hučí',
		'Pevná linka je hluchá',
		'pevná linka má poruchu',
		'Pevná linka šumí',
		'pevnou linkou sa už nikomu nedovolám',
		'porucha pevnej linky',
		'poruchu na pevnej linke',
		'potreboval by som nahlásiť poruchu na pevnej linke',
		'pri volaní cez pevnú linku nič nepočujem?',
		'telefónna linka má poruchu',
		'telefónna linka má poruchu prosím o odstránenie',
		'V pevnej linke je ticho',
		'výpadok pevnej linky',
	],
	FIXNY_INTERNET__DOESNT_WORK: [
		'chcem ohlásiť poruchu na internete',
		'doma nám blbne internet',
		'hapruje mi pevný internet',
		'internet doma mám zaplatený a prestal mi ísť',
		'internet doma mi niekedy funguje a niekedy nefunguje',
		'internet je nefungujúci',
		'ja by som chcela nahlásiť poruchu internetu od vás',
		'Mám dačo s netom',
		'Máme od vás ten pevný internet ktorý po včerajšej búrke nejak nefunguje',
		'mám nefunkčný domáci internet',
		'Mám pokazený internet a už ma to fakt začína otravovať. Chcem aby ste mi to opravili',
		'Mám pokazený internet',
		'Nefunguje mi doma internet mohli by ste mi to už konečne prosím vás opraviť.',
		'Nefunguje mi internet doma',
		'nefunguje mi internet',
		'Nefunguje mi Mágio internet',
		'Nefunguje mi pevný internet',
		'nefunguje mi to cez internet mi svieti červená kontrolka aj napriek niekolkym restartom',
		'nefungujúci internet',
		'nefunkčnosť internetu',
		'nehlásená porucha internetu',
		'Nejde mi internet doma',
		'Nejde mi internet',
		'nejde mi internet magio',
		'nejde mi magio internet',
		'Nejde mi opäť internet ten istý problém',
		'Nejde mi pustiť web',
		'Nejde nám internet',
		'nemám internet',
		'nemôžem sa pripojiť na internet',
		'Net od vás mi zas nejde',
		'Neviem sa napojiť na internet',
		'Niečo mám s netom',
		'ozývam sa kvôli nefunkčnosti internetu',
		'Porucha internetu',
		'som tu kvôli nefunkčnosti internetu',
		'už fakt neviem, čo robiť s pevným internetom',
		'Vôbec mi nejde net',
		'Voľačo je s internetom',
		'volám vám kvôli nefunkčnosti internetu',
		'Zapojili mi dneska internet a ešte nejde, ako dlho to trva?',
	],
	FIXNY_INTERNET__KONTROLA: [
		'kontrola internetového pripojenia.',
		'kontrola internetu',
		'kontrolovať internet',
		'potrebujem aby ste mi pozreli internet',
		'skontrolovali by ste mi internet',
		'treba mi skontrolovať internetové pripojenie',
	],
	FIXNY_INTERNET__LOADING_ISSUE: [
		'mám lagy',
		'mrzne mi domáci internet',
		'nejde mi poriadne načítať ani youtube, mám lagy',
		'pevný internet sa mi zasekáva',
		'Seká mi doma internet',
	],
	FIXNY_INTERNET__MA_PROBLEM: [
		'Chcem nahlásiť problém s internetom',
		'mám problém s internetom',
		'potrebujem vyriešiť problémy s internetom',
		'problém s mojm internetom',
	],
	FIXNY_INTERNET__PASSWORD: [
		'asi ste mi zabudli dať prístup na internet',
		'heslo na internet',
		'nedali ste mi prístup na môj internet',
		'Nefunguje internet prístup',
		'nemám prístup na môj net',
		'Neviem heslo na internet',
		'Neviem naše heslo od internetu',
		'zabudol som heslo na net',
	],
	FIXNY_INTERNET__PROBLEM_S_PRIPOJENIM: [
		'mám problém s pripojením internetu',
		'mám problém s pripojením internetu vypisuje že nie je sieť',
		'mám tu taký problém s pripojením na internet a vypisuje mi to tu že nie je sieť',
		'problém s internetovým pripojením, nejde sieť',
		'problém s pripojením internetu, nie je sieť',
		'vyskytol sa u mňa problém s internetovým pripojením, a že nie je sieť',
	],
	FIXNY_INTERNET__REKLAMACIA: [
		'chcel by som reklamovať zapojenie internetu',
		'chcel by som vyreklamovať internet',
		'chcem reklamovať internet',
		'chcem riešiť reklamáciu internetu',
		'chcem vyreklamovať internet',
		'ja potrebujem reklamovať internet',
		'potreboval by som reklamovať internet',
		'potreboval by som reklamovať zapojenie internetu',
		'reklamácia internetu',
		'reklamáciu internetu',
		'reklamovať internet',
	],
	FIXNY_INTERNET__SIET_VYPADAVA: [
		'mám výpadok sieť',
		'mi z ničoho nič vyplo sieť',
		'padá nám sieť',
		'sieť občas vypadne',
		'so sieťou máme hlásené výpadok siete',
		'Vypadáva mi sieť',
		'vypína mi sieť',
		'vyplo mi sieť',
	],
	FIXNY_INTERNET__SLOW: [
		'Ide mi pomaly internet',
		'internet na doma je pomalší než ste uvádzali',
		'internet na doma nie je tak rýchly ako ste sľubovali',
		'Mám nízku rýchlosť pripojenia.',
		'Mám veľmi pomalý internet',
		'Posledné dni nám ide internet veľmi pomaly',
		'riadne nám spomalil internet',
		'rýchlosť pevného internetu je kolísavá',
		'Zrazu máme pomalý internet',
	],
	FIXNY_INTERNET__SPOTTY: [
		'internet občas vypadne',
		'mám výpadok internetu',
		'padá nám internet',
		'padol mi internet',
		'potrebujem pomôcť internetom pretože mi z ničoho nič vypol proste',
		'raz internet funguje, a potom ho vypne',
		'raz mi internet ide, potom  nie',
		'raz mi pevný internet ide, potom zas nie',
		'Vypadáva mi internet',
		'Vypadol mi internet. Toto sa stalo už asi tretí krát za posledné mesiace',
		'vypína mi internet',
		'vyplo mi internet',
	],
	FIXNY_INTERNET__TECHNICKA_PODPORA: [
		'chcem technickú podporu internetu',
		'pomoc s technickou podporou internetu',
		'pomôcť s technickou podporou internetu',
		'potrebujem technickú podporu internetu',
		'technická podpora internet',
		'technická podpora internetu by sa mi zišla',
		'technická podpora internetu',
		'technická podpora internetu mi je treba',
		'zišla by sa technická podpora internetu',
	],
	FIXNY_INTERNET__VARIOUS: [
		'nedá sa načítať vôbec žiadna adresa',
		'nedá sa načítať žiadna stránka',
		'nefunguje mi načítanie žiadnej adresy',
		'Nejde mi načítať žiadnu adresu',
		'nejde mi načítať žiadnu stránku',
		'nie je možné načítať žiadnu adresu',
		'nie je možné načítať žiadnu stránku',
	],
	FIXNY_INTERNET__WIFI_MODEM_OK: [
		'dá sa mi pripojiť k modemu cez kábel, takže wifi nefunguje',
		'funguje mi to na modeme cez kábel, wifi bude problém',
		'keď sa pripojím k modemu cez kábel, je to ok, ale wifi nejde',
		'pripojenie k modemu cez kábel funguje, wifi je problém',
		'všetko mi ide keď sa pripojím k modemu cez kábel, no wifi je problém',
		'Wifi nejde, ale keď som sa pripojil k modemu cez kábel, išlo to',
	],
	FIXNY_INTERNET__WIFI_PRIPOJENIE: [
		'Chcel som sa pripojiť na wifi a vôbec sa mi nejde',
		'nedá sa pripojiť na wi-fi',
		'nejde pripojenie na wifi',
		'nemôžem sa doma pripojiť na wi-fi',
		'Neviem sa pripojiť na wifi',
		'skúšame sa pripojiť na wifi a nejde to',
		'skúšam sa doma s mobilom pripojiť na wifinu, ale nejde to',
	],
	FIXNY_INTERNET__WIFI_PROBLEM: [
		'Nefunguje mi wifi',
		'Nefunguje nám doma wifi',
		'niečo máme s wifi doma',
		'odišla nám doma wifi',
		'prestala nám ísť wifina',
	],
	FIXNY_INTERNET__WIFI_RESTART: [
		'aj keď vypnem a zapnem modem, wi-fi stále nič',
		'modem som reštartoval no wifi aj tak nejde',
		'nepomáha ani vypnúť a zapnúť mode, wifi nejde',
		'reštartoval som modem ale wifina stále nejde',
		'spravil som reštart modemu, wifi stále nefunguje',
		'vypol som a zapol modem ale wi-fi aj tak nejde',
	],
	FIXNY_INTERNET__WIFI_SIGNAL: [
		'asi mám problém s wifi signálom  preto mi to nejde',
		'Mám problém s wifi signálom',
		'niečo asi bude s wifi signálom',
		'signál wifi',
		'signál wifi je silný ale internet nejde',
		'Viete mi premerať signál?',
		'wifi signál',
	],
	FIXNY_INTERNET__WIFI_VYPADKY: [
		'často nám vypadáva wifi',
		'padá nám wifina',
		'padá wifi',
		'vypadáva wifi',
		'Vypadlo nám wifi',
		'Wifi furt vypadáva',
		'wifina nám vypadáva',
		'wifi stále vypadáva',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__ADDING_DATA: [
		'dopĺňanie dát cez stránku nefunguje',
		'nedá sa dopĺňat dáta cez stránku',
		'nejde vám stránka kde môžem dopĺňať dáta',
		'stránka dopĺňania dát nejde',
		'stránka na dopĺňanie dát nefunguje',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__APP_DOESNT_WORK: [
		'Ako je možné že si chcem kúpiť internet ale nemôžem lebo vaša aplikácia sa mi nenačíta',
		'Appka je nefunkčná, čo mám robiť',
		'chcel by som spraviť nákup cez aplikáciu ale tá nejde',
		'chcel som nakúpiť cez vašu appku, ale nenačítava sa',
		'Mám problém s aplikáciou',
		'Mám problém s Telekom aplikáciou',
		'Mám zapnuté wifi a aplikácia nereaguje.',
		'Máte pokazenú appku',
		'Nefuguje mi apka',
		'Nefunguje mi Telekom appka',
		'nefunguje vám appka',
		'Nejde mi načítať apku',
		'Nejde mi tá vaša appka',
		'nejde mi využiť vašu aplikáciu na nákup, nenačítala sa mi',
		'Potreboval by som pomôcť s telekom aplikaciou, nefunguje',
		'Potreboval by som pomôcť spustiť aplikaciou, nefunguje',
		'chcem spustiť aplikáciu',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__CONTROL: [
		'kontrola aplikácie',
		'kontrola appky',
		'potrebujem skontrolovať aplikáciu',
		'prosím o kontrolu aplikácie',
		'prosím skontrolovať app',
		'skontrolovať aplikáciu',
		'skontrolovať appku',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__GO_ACTIVATION_DOESNT_WORK: [
		'Magio Go sa mi neaktivuje',
		'nedá sa aktivovať Magio Go',
		'neviem aktivovať magio go',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__GO_DOESNT_WORK: [
		'Magio Go mi nefunguje',
		'Magio Go nejde',
		'Magio GO seká',
		'Mám problém s Magio GO.',
		'máte nejaký výpadok na mágio go?',
		'Nefunguje mi Magio GO',
		'Nejde mi magio go',
		'nejde vám magio go',
		'neviem zapnúť Magio Go',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__HOW_DOWNLOAD: [
		'Ako si stiahnem aplikáciu',
		'ako stiahnuť aplikáciu',
		'chcem si stiahnuť aplikáciu',
		'neviem ako si stiahnuť aplikáciu',
		'neviem kde si stiahnuť aplikáciu',
		'neviem si stiahnuť aplikáciu',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__LOGIN: [
		'chcem sa prihlásiť do mágio go',
		'lognúť sa do mágio go',
		'potrebujem prihlásenie do mágio go',
		'potrebujem sa prihlásiť do mágio go',
		'prihlásenie do magio go',
		'prihlásenie sa do mágio go',
		'rád by som sa prihlásil do magio go',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__LOGIN_VARIOUS: [
		'Ako sa prihlásiť',
		'Aký je môj login?',
		'Nedá sa mi prihlásiť do Magio Go',
		'Neviem si obnoviť magio go heslo',
		'neviem svoj login?',
		'viete mi povedať login?',
		'zabudol som môj login?',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__PORTAL_DOESNT_WORK: [
		'Čo je zase s tým portálom ?',
		'Mám problém s Telekom portálom',
		'Nefunguje mi portál',
		'nefunguje mi váš portál',
		'Nemôžem sa dostať do portálu',
		'niečo je s tým vašim portálom',
		'portál nejde',
		'ten portál zas nejde',
		'zasa máte niečo s tým portálom',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__PRIDAJ_TEL_CISLO: [
		'chcem si vložiť do appky nové číslo',
		'potrebujem pridať do aplikácie moje telefónne číslo',
		'potrebujem si pripísať do aplikácie nové telefónne číslo',
		'pridať do aplikácie telekom nové telefónne číslo',
		'viete mi pomôcť si vložiť telefónne číslo do appky? mám nové',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__PRISTUPOVY_KOD: [
		'chcel by som poprosiť o prístupový kód na aktivovanie magio go benefitu',
		'chcem prítupový kód pre aktiváciu magio go benefitu',
		'ja potrebujem prístupový kód na aktivovanie magio go benefitu',
		'potrebujem prístupový kód na aktivovanie magio go benefitu',
		'prístupový kód na aktivovanie magio go benefitu',
		'treba mi prístupový kód na aktiváciu magio go benefitu',
		'kódy na magio go',
		'magio go kódy',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__REGISTRATION_APP: [
		'ako sa mám zaregistrovať do aplikácie',
		'jak sa mám registrovať do aplikácie',
		'jak sa mám registrovať do aplikácie keď to vypisuje chybu',
		'Nedá sa mi registrovať do aplikácie',
		'nefunguje registrovanie do aplikácie',
		'registrácia do aplikácie zlyháva',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__REGISTRATION_PORTAL: [
		'Ako sa registrujem na portáli?',
		'chcem sa registrovať na portáli?',
		'jak sa mám registrovať na portáli?',
		'nefunguje vám registrácia do portálu',
		'nejde mi registrácia do portálu',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__SET_PROGRAM: [
		'chcel by som nastaviť program v aplikácii',
		'nastavenie programu prostredníctvom aplikácie',
		'nastavenie programu v aplikácii',
		'nastavenie programu v appke',
		'nastavenie programu vo vašej aplikácii',
		'nastaviť program cez appku',
		'nastaviť program v aplikácii',
		'nastaviť program v appke',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__USAGE_ISSUE: [
		'Cez internet nie je možné skontrolovať spotrebu',
		'chcel som si na internete pozrieť spotrebu a nedá sa',
		'nedá sa mi pozrieť spotrebu cez internet',
		'Nemôžem sa pozrieť na moju spotrebu, mám tu internet vzduchom',
		'Nemôžem si na nete pozrieť moju spotrebu',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__VARIOUS: [
		'mali ste mi poslať potvrdenie o zmene na portály',
		'neposlali ste mi potvrdenie po zmene v portáli',
		'Neprišiel mi potvrdzujúci email po zmene v portáli',
		'Neprišiel mi potvrdzujúci SMS po zmene v portáli',
		'robil som úpravy na portály a mali ste mi o tom poslať správu',
		'inštalovať magio go',
		'magio go',
		'magio go televíziu na mojom mobile',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__WRONG_LOGIN: [
		'heslo do mágio aplikácie',
		'nedá sa mi dostať do mágio aplikácie',
		'nedá sa mi dostať do telekom magio aplikácie',
		'neviem sa dostať do mágio',
		'neviem sa prihlásiť do aplikácie.',
		'potrebujem heslo do magia',
		'potrebujem prihlasovacie údaje',
		'potrebujem sa prihlásiť na mágio go aplikácie',
		'Stratil som heslo a mail na obnovenie hesla',
		'Stratil som prihlasovacie udaje',
		'Vraj mám zlé prihlasovacie údaje',
		'Zabudol som prihlasovacie údaje.',
		'potreboval by som poradiť respektíve povedať prihlasovacie údaje do magiogo',
		'zistiť heslo do mágio',
		'zistiť heslo do telekom aplikácie',
		'potrebujem prihlasovacie meno do magiogo',
	],
	APLIKACIA_PORTAL_A_MAGIO_GO__WRONG_MAIL: [
		'mám nesprávny mail na app',
		'máte na mňa zlý mail v appke',
		'nesprávny mail pre appku',
		'pre appku mám nesprávny mail',
		'zlý mail pre appku',
	],
	PORUCHA_HW__FIX_MOBILE_PHONE: [
		'akou formou môžem žiadať o opravu',
		'Neviem si opraviť mobil',
		'oprava mobilu',
		'opravte mi mobil',
		'potrebujem opraviť telefón',
	],
	PORUCHA_HW__MAGIOBOX: [
		'Chcem nahlásiť poruchu mágio boxu',
		'Mágio box sa mi pokazil',
		'Mám pokazený magio box',
		'mám problém s magioboxom',
		'Mám problém s Magio GO TV Box',
		'Mám problém s tým boxom k TV',
		'Nefunguje mi Magio Box',
		'Nefunguje mi TV Box',
		'Nejde mi magio box',
		'problém magiobox',
		'zdochol mi váš tv box',
	],
	PORUCHA_HW__MOBILE_PHONE: [
		'čo mám robiť, nejde mi mobil vôbec',
		'hrozne mi šumí telefón',
		'hučí mi telefón',
		'mame pokazený telefón',
		'Mám pokazený iPhone a stalo sa to zase. Môžte mi ho už poriadne opraviť?',
		'Mám pokazený telefón',
		'mám problém s telefónom',
		'Mobil je v cudu',
		'Nefunguje mi mobil',
		'Nefunguje mi telefon',
		'neviem čo je s telefónom',
		'Neviem spustiť mobil',
		'pokazil sa nám telefón',
		'Porucha na telefóne',
		'Potrebujem zistiť ako reklamovať telefón',
		'Telefón mi odišiel',
		'telefón sa nám pokazil',
		'telefón sa pokazil',
	],
	PORUCHA_HW__NASTAVENIA_TELEFONU: [
		'aké mám nastavenie telefónu?',
		'aké sú nastavenia telefónu?',
		'nastavenia telefónu',
		'nastavenie telefónu',
		'potreboval by som zistiť nastavenia telefónu',
		'potrebujem zistiť nastavenia telefónu',
		'treba mi zistiť nastavenia telefónu',
	],
	PORUCHA_HW__RECORD: [
		'Nechce sa mi zapnúť nahrávanie',
		'Nefunguje mi nahrávanie',
		'Nefunguje mi nahrávať',
		'nejde mi nahrávanie',
		'prestalo mi ísť nahrávanie',
		'vaša služba mi nechce nahrávať',
	],
	PORUCHA_HW__ROUTER: [
		'Ako si naištalujem router?',
		'Asi nemám doma dobré káble',
		'Kábel z boxu do routra je príliš krátky, čo s tÝm?',
		'kúpil som si od vás router a nejde ani zapnúť',
		'Mám problém s modemom.',
		'Nefunguje mi router',
		'Nejde mi nainštalovať router',
		'Nejde mi router od vás',
		'Nejde mi spustit router',
		'Nemôžem zmeniť heslo na routeri',
		'Neviem sa pripojiť na router',
		'Sa mi pokazil router',
	],
	PORUCHA_HW__TABLET: [
		'čo mám robiť, nejde mi tablet',
		'hučí mi tablet',
		'jednoducho mi nejde tablet od vás telekomu',
		'mame pokazený tablet',
		'Mám pokazený tablet',
		'mám problém s tabletom',
		'Nefunguje tablet',
		'Neviem spustiť tablet',
		'pokazil sa nám tablet',
		'Porucha na tablete',
		'Potrebujem zistiť ako reklamovať tablet',
		'tablet mi odišiel',
		'tablet sa nám pokazil',
		'tablet sa pokazil',
	],
	PORUCHA_HW__TV_ACCESSORIES: [
		'Nefunguje mi kalkulačka ku magiu',
		'Nejde mi krabička ku televízoru',
		'Odišla mi skrinka na televízii',
	],
	PORUCHA_HW__TV_ARCHIVE: [
		'archív nám nefunguje',
		'archív nám nejde',
		'mám problém s archívom',
		'mám problémy s mojim archívom',
		'nefunguje archív',
		'Nefunguje mi archív',
		'nejde archív',
		'nejde mi archív',
	],
	PORUCHA_HW__VARIOUS: [
		'mám pokazene sluchadla',
		'Mám pokazené slúchadlá',
		'Mám pokazený aparát',
		'Mám pokazený laptop a potrebujem s niekým hovoriť lebo si ho musím dať opraviť',
		'mám poruchu prístroja',
		'Mám problém s doplnkovým HW',
		'Mám problém s fixným HW',
		'Mám problém s mobilným HW',
		'mám vašu krabicu ale nefunguje',
		'nefunguje mi váš hardware',
		'nefunguje prístroj',
		'Nejde mi skrinka',
		'porucha prístroja',
		'Ten box je pokazený',
		'ukazuje mi to porucha prístroja',
	],
	INY_TECHNICKY_PROBLEM__APP_CHANGE_SERVICE: [
		'Ako si modifikujem služby v Telekom appke?',
		'Ako si prenastavím iné služby v Telekom appke?',
		'ako si zmením služby v telekom aplikácii',
		'Ako si zmením služby v Telekom appke?',
		'Ako si zmeniť služby v Telekom appke?',
	],
	INY_TECHNICKY_PROBLEM__APP_INSTALL: [
		'ako si nainštalujem aplikáciu?',
		'Ako si naištalujem Telekom apku?',
		'Ako si naištalujem Telekom appku?',
		'chcem si nainštalovať vašu aplikáciu',
		'Chcem si nainštalovať vašu appku.',
	],
	INY_TECHNICKY_PROBLEM__APP_SETUP: [
		'Chcem si prenastaviť telekom appku',
		'na appke mi treba zmeniť nastavenie',
		'prenastaviť aplikáciu',
		'prenastaviť appku',
		'zmeniť nastavenia na appku',
	],
	INY_TECHNICKY_PROBLEM__BROKEN_INFRASTRUCTURE: [
		'máme popadané káble od vás',
		'mám popadané vaše káble príďte s tým niečo urobiť',
		'Mám za domom popadané vaše káble, príďte si to odstrániť.',
		'popadali mi vaše káble dojdite to odstrániť',
		'popadali nám vaše káble',
		'vaše káble popadali',
		'za domom mi popadali vaše káble tak mi s tým prosím poďte pomôcť',
	],
	INY_TECHNICKY_PROBLEM__CANNOT_INSTALL: [
		'inštalácia mi zjavne nefunguje',
		'nedá sa mi to nainštalovať',
		'nefunguje mi inštalácia',
		'nejde mi inštalácia',
		'nejde mi to nainštalovať',
		'nemôžem to nainštalovať',
	],
	INY_TECHNICKY_PROBLEM__DATA_VOUCHER: [
		'hovorili mi že dostanem dátový kupón dokiaľ odstránia poruchu',
		'mám dostať kupón na dáta dokiaľ neodstránia poruchu',
		'Povedali, že mi dajú kupón na dáta kým sa odstráni porucha tak volám.',
		'sĺúbili mi kupón na dáta dokým sa odstráni porucha',
		'sľúbili že mi dajú kupón na dáta do odstránenia poruchy',
		'volám lebo mi sľúbili dátový kupón dokým odstránia poruchu',
	],
	INY_TECHNICKY_PROBLEM__HOW_TO_DO: [
		'ako mám presne postupovať',
		'aký je presný postup',
		'chcem vediět aký presný postup',
		'potreboval by som presný postup',
		'potrebujem presný postup',
		'treba mi presný postup',
	],
	INY_TECHNICKY_PROBLEM__HOW_TO_INSTALL: [
		'Pošlite mi návod na inštaláciu',
		'Pošlite mi postup inštalácie',
		'Pošlite mi postup nastavenia',
		'postup nastavenia',
		'treba mi manuál aby som to nainštaloval',
	],
	INY_TECHNICKY_PROBLEM__HW: [
		'mám iné káble než mi treba',
		'nemám správne káble',
		'Prišiel mi kábel ale je iný než čo chcem',
		'Prišiel mi zlý kábel',
		'tento kábel nie je dobrý',
	],
	INY_TECHNICKY_PROBLEM__MAGIO: [
		'ako inštalovať Magio box',
		'Ako si nainštalujem Magio box?',
		'Ako si nastavím Magio GO Box?',
		'Ako si nastavím magio go?',
		'Ako si spárovať ovládač s Magio Boxom?',
		'Môžete mi pomôcť s nastavením Magio GO TV Box?',
		'nejak sa mi nedarí nastaviť Magio go',
		'Nejde mi nainštalovať Magio box',
		'neviem si nastaviť Magio go',
		'potrebujem pomoc s nastavením Magio go',
	],
	INY_TECHNICKY_PROBLEM__NEED_TECH_HELP: [
		'mám technický problém',
		'mám u vás technický problém',
		'potrebujem pomoc s technickým problémom',
		'Potrebujem technicku pomoc',
		'technický problém',
	],
	INY_TECHNICKY_PROBLEM__SERVISNA_LINKA: [
		'chcem servisnú linku',
		'potreboval by som servisnú linku',
		'potrebujem servisnú linku',
		'servisná linka',
		'servisnú linku',
		'treba mi servisnú linku',
	],
	INY_TECHNICKY_PROBLEM__SIM_CARD: [
		'Asi mám niečo so sim kartou',
		'Mám poškodenú SIM kartu',
		'mobil mi neregistruje simku',
		'pokazila sa mi sim karta',
		'Po vložení sim karty mi mobil vypisuje “vložte sim kartu"',
		'simka sa tvári že nie je vložená',
		'nefunguje mi sim karta',
	],
	INY_TECHNICKY_PROBLEM__TV_LOCK: [
		'Mám na TV rodičovský zámok, neviem aký je tam PIN?',
		'na tv mám nastavený rodičovský zámok a neviem pin',
		'nepamätám si PIN na tv rodičovský zámok',
		'neviem aký je PIN na rodičovskom zámku na tv',
		'potrebujem PIN na TV rodičovský zámok',
		'zabudol som PIN na TV rodičovský zámok',
	],
	INY_TECHNICKY_PROBLEM__TV_NA_OPTICKY: [
		'chcel by som zapojiť tv na optický kábel',
		'chcem zapojiť televízor na optický kábel',
		'potreboval by som zapojiť televíziu na optický kábel',
		'potrebujem zapojiť televízor na optický kábel',
		'potrebujem zapojiť telku na optický kábel',
		'treba mi pripojiť televízor na optický kábel',
		'tv pripojiť na optický kábel',
	],
	INY_TECHNICKY_PROBLEM__WIFI_PASS: [
		'Ako nastavím heslo na wifi?',
		'Ako nastaviť wifi heslo?',
		'Ako zmením heslo na wifi?',
		'Potrebujem zmeniť kód na wifi',
		'treba mi nastaviť nový prístup na wifi',
	],
	AKTUALNY_STAV_PORUCHY__FAILURE_GENERAL: [
		'Aký je stav poruchy, ktorú som hlásil?',
		'Je už môj hardware opravený',
		'Každý deň volám a nikto mi nevie nič povedať.',
		'kedy budú funkčné služby?',
		'Kedy mi bude fungovať',
		'Kedy mi budú fungovať služby, už som vám to nahlásila',
		'Kedy mi už opravíte veci ?',
		'Kedy opravíte poruchu?',
		'Kedy sa dostanete k odstráneniu poruchy?',
		'mali ste odstávku a ja som vám to, už hlásil ale ešte ste sa mi neozvali',
		'mám nahlásenú poruchu, už ste to riešili',
		'mám u vás otvorenú opravu poruchy a nik sa mi s tým ešte neozval',
		'máte už vyriešenú moju poruchu',
		'nahlásil som že mi nefunguje vaša služba a vy ste mi ešte neposlali odpoveď',
		'Služby sú po dňoch stále nefunkčné',
		'Už som to ohlásila ale stále mi nič nejde',
		'už ste riešili čo som volal, kvôli poruche?',
		'Už ste začali riešiť moju poruchu?',
		'v akom stave je porucha čo som hlásil minulý týždeň',
		'viete mi povedať stav riešenia mojej poruchy',
	],
	AKTUALNY_STAV_PORUCHY__INTERNET: [
		'a kedy bude fungovať internet',
		'Je už moja wifi opravená ?',
		'Kedy mi bude znova fungovať internet?',
		'Mám už opravený internet ?',
		'Nemám ešte opravenú wifi. Ako môže trvať týždne opraviť jednu obyčajnú wifi',
		'nie je ešte opravený internet?',
		'viete mi povedať kedy zas bude funkčný internet',
	],
	AKTUALNY_STAV_PORUCHY__MOBILE: [
		'ako dlho budem čakať na opravu toho mobilu?',
		'ako dlho ešte mám čakať na opravu toho mobilu?',
		'Ako je na tom oprava môjho mobilu ?',
		'dokedy mi opravíte ten mobil?',
		'kedy mi opravíte mobil?',
		'mám už mobil opravený?',
		'Opravili ste mi už mobil ?',
		'už je opravený mobil?',
	],
	AKTUALNY_STAV_PORUCHY__TV: [
		'ako dlho budem čakať kým televízia bude opäť fungovať',
		'ako dlho si ešte počkám na funkcňú telku?',
		'dokedy bude opravená televízia?',
		'dokedy mám čakať kým moja tv znova pôjde',
		'Kedy mi bude fungovať ten box? Už som vám kvôli tomu volal',
		'Kedy mi bude znova fungovať telka?',
		'kedy mi zas bude fungovať televízia?',
		'kedy mi zas pôjde telka',
		'nahlasoval som si, že mi nejde váš box, aký je tam stav?',
	],
	PORUCHA_VIACERYCH_SLUZIEB__DOESNT_WORK: [
		'Chcem nahlasit poruchu pevnej linky a internetu',
		'Dnes nám od rána nejde telka ani internet nič',
		'linka a internet nám nejde',
		'máme poruchu na telefóne na pevnej linke aj na internete',
		'mám poruchu na pevnej linke aj na telefóne či internete',
		'nefunguje mágio televízia a internet',
		'nie fungujem program ani internet',
		'nefunkčnosť televízie a a internetu',
		'Nejde nám telka ani internet',
		'nemáme signál na príjem na ten televízor ani na internet',
		'veľkú prosbu na vás viete čo nejde mi internet ani televízia',
		'pevná linka a internet nám nejdú',
		'porucha internetu, telefónu aj pevnej linky',
		'Potrebujem opraviť služby Telekom',
		'Nefungujú mi služby',
		'nemáme signál na službách',
		'prestali mi služby ísť',
		'služby mi nejdú',
		'služby mi úplne prestali ísť',
		'služby nejdú',
		'služby sú nefunkčné',
		'všetky služby sú nefunkčné',
		'Potrebovala by som telefonne cislo na technicku podporu',
	],
	I_CIR_00_01_D_INVOICE__DOSTAL_FAKTURU: [
		'došli mi faktúry',
		'faktúra mi prišla cez sms',
		'faktúra telekom mi prišla smskou',
		'ja volám ohľadom faktúry mi prišla sms kou',
		'mám tu od vás faktúry',
		'mesačné faktúry za internet',
		'mesačné faktúry za televíziu a internet',
		'mi prišla smskou faktúra',
		'poslali ste mi faktúru',
		'prišla mi faktúra',
		'prišla mi faktúra za telefón',
		'prišla mi smskou faktúra',
		'volám ohľadne faktúry mi prišla smskou',
	],
	I_CIR_00_01_D_INVOICE__FAKTURA_SLUZBY: [
		'fakturačné služby',
		'info o fakturačných službách',
		'ohľadne fakturačných služieb',
		'potrebujem sa informovať na fakturačné služby',
		'spýtať sa na fakturačné služby',
	],
	I_CIR_00_01_D_INVOICE__FAKTURACNE_ODDELENIE: [
		'chcem sa spýtať na finančné oddelenie',
		'chcem telefónne číslo na fakturačné',
		'číslo na fakturačné',
		'fakturačné oddelenie',
		'finančné oddelenie',
		'na fakturačné',
		'na fakturačné oddelenie',
		'Potrebujem finančné oddelenie',
		'Potrebujem sa dostať na fakturačné oddelenie',
		'potrebujem telefónne číslo na fakturačné oddelenie',
		'spojiť ma s fakturačným oddelením',
		'telefónne číslo na fakturačné oddelenie',
		'treba mi číslo na fakturačné oddelenie',
		'treba mi riešiť veci na vašom finančnom oddelení',
	],
	I_CIR_00_01_D_INVOICE__GENERAL: [
		'som potreboval vedieť ohľade ohľadom faktúry telekom',
		'ako máte faktúry?',
		'chcela by som sa niekým baviť faktúrach',
		'chceme riešiť faktúru',
		'chceme vybaviť faktúru',
		'chcem faktúru',
		'Chcem informácie k faktúram',
		'chcem sa spýtať na faktúru',
		'chcem sa spýtať na faktúru ohľadom internetu a mágio televízie',
		'chcem sa spýtať ohľadne faktúr',
		'chcem si vybaviť jednu faktúru',
		'chcem vedieť niečo o faktúre',
		'fakturácia',
		'faktúra',
		'faktúra ma zaujíma',
		'faktúrami',
		'faktúru áno',
		'faktúru',
		'faktúru potrebujem preriešiť',
		'Faktúru riešiť',
		'faktúry',
		'Faktúry na internet vzduchovou wifi',
		'hej faktúry',
		'informácie o faktúre',
		'len faktúry',
		'mám otázky ohľadom faktúry',
		'mám pripomienku k faktúre',
		'obraciam sa na vás kvôli faktúre',
		'o faktúrach',
		'ohľade faktúry volám',
		'ohľadne faktúry volám',
		'overiť faktúru',
		'potreboval by som nejaké informácie ohľadom faktúr',
		'Potrebujem sa informovať ohľadom faktúr',
		'potrebujem s vami poriešiť faktúru',
		'rád by som poriešil faktúru',
		's faktúrami',
		'Teraz by som sa chcela rozprávať niekým o faktúrach',
		'Volám ohľadne faktúry',
		'Volám ohľadne faktúry na moje meno',
		'Volám vám ohľadne faktúry',
		'volám vám ohľadne faktúry, je na moje meno',
	],
	I_CIR_00_01_D_INVOICE__NEROZUMIE_FAKTURE: [
		'mám nejaké nejasnosti ohľadom faktúry potrebujem to spresniť',
		'no poradiť sa s faktúrami',
		'poradiť sa s faktúrami',
		'poradiť sa s faktúrou',
		'poradiť s faktúrami',
		'potreboval by som pomôcť s faktúrou',
		'potrebujem pomoc s faktúrou',
		'potrebujem pomôcť s faktúrou',
		'potrebujem pomôcť s mojou faktúrou',
		'potrebujem poradiť s faktúrou',
		'potrebujem sa poradiť s faktúrami',
	],
	I_CIR_00_01_D_INVOICE__PROBLEM: [
		'faktúry sú problematické',
		'je problém s faktúrou',
		'mám problém s faktúrami',
		'mám problém s faktúrou',
		'mám problém s výškou faktúry',
		'mám veľký problém s faktúrami',
		'problém s faktúrou',
		'problémy s faktúrami',
		's faktúrami mám problém',
	],
	I_CIR_00_01_D_INVOICE__SEKY_HOW: [
		'ako posielate faktúry na zaplatenie',
		'ako sa teraz posielajú faktúry?',
		'ako sa teraz posielajú šeky na zapaltenie telefónu na pevnú linku',
		'ako sa teraz posielajú šeky na zaplatenie telefónu na pevnú linku',
		'chcela by som vedieť jak sa teraz posielajú šeky',
		'jak sa teraz posielajú faktúry na zaplatenie?',
		'jak sa teraz posielajú šeky na zaplatenie',
		'jak sa teraz posielajú šeky na zaplatenie telefónu',
		'rada by som vedela ako sa posielajú šeky',
	],
	I_CIR_00_01_D_INVOICE__SUMA_FAKTURY: ['zistiť faktúru za mágio televíziu', 'zistiť výšku faktúry za'],
	NEPRISLA_MI_FAKTURA_SMS__NECHODIA_SEKY: [
		'nechodia mi šeky',
		'nedošiel mi šek',
		'nejak mi nechodia šeky',
		'neposielate mi šek',
		'Neprišiel mi šek',
		'neviem prečo mi neposielate šek',
		'no prestali mi chodiť šeky',
		'potrebujeme zaplatiť faktúru nedošiel šek',
		'potrebujem faktúru zaplatiť nedošiel mi šek',
		'potrebujem zaplatiť a nedošiel mi šek',
		'prestali mi chodiť šeky',
		'šeky mi prestali chodiť',
		'treba zaplatiť faktúru a nedošiel mi šek',
	],
	NEPRISLA_MI_FAKTURA_SMS__NOT_SENT: [
		'Ako mám zaplatiť keď mi nedošla ešte faktúra ?',
		'Čakám stále na faktúru.',
		'no potrebujem sa spýtať ohľadom faktúry nebolo mi doručená',
		'Chcem aby ste mi poslali faktúru ktorú mám zaplatiť',
		'chcem riešiť, že mi neprišla tento mesiac faktúra',
		'elektornická faktúra mi nedošla.',
		'Ešte mi na mail neprišla faktúra.',
		'ešte stále nemám faktúru za telefón',
		'faktúra neprišla',
		'Faktúru stále nemám v schránke.',
		'faktúru za telefón nemám',
		'faktúry mi nechodia',
		'Mohli by ste mi poslať faktúru? Lebo mi nedošla',
		'nechodia mi faktúry',
		'nechodia nam domov faktury mohli by ste sa na to prosim teraz pozriet?',
		'nechodí mi faktúra',
		'Nedoručili ste mi papierovú faktúru načas.',
		'Nedoručili ste mi žiadnu faktúru.',
		'Nedošiel mi mail s faktúrou',
		'Nedošla mi fakturácia',
		'Nedošla mi faktúra.',
		'Nedošlo mi vyúčtovanie z minulého mesiaca ?',
		'nedostala som faktúru na tento meiac na mobil',
		'nedostala som faktúru na zaplatenie',
		'nedostala som faktúru na zaplatenie telefónu',
		'nedostala som faktúru',
		'Nedostal som elektronickú faktúru',
		'nedostal som faktúru na zaplatenie telefónu',
		'nedostal som faktúru',
		'Nedostal som papierovú faktúru',
		'nemáme faktúru',
		'nemám faktúru',
		'nemám faktúru za telefón',
		'Neobdržal som svoju faktúru',
		'neprišla mi faktúra koľko zaplatiť',
		'neprišla mi faktúra',
		'neprišla mi faktúra že koľko zaplatiť za telefón',
		'neprišla mi tento mesiac faktúra',
		'neprišla mi za toto obdobie faktúra',
		'Neprišla mi žiadna faktúra.',
		'neprišla nám faktúrka za pevnú linku',
		'neprišlo mi koľko zaplatiť za telefón',
		'Neviem zaplatiť lebo mi neprišla faktúra',
		'Potreboval by som faktúru za Magio Go',
		'potrebujem riešiť že mi nechodia faktúry mám s tým problém od',
		'potrebujem riešiť že mi nechodia faktúry',
		'potrebujem riešiť že prečo mi nechodia faktúr',
		'Potrebujem vyplatiť a nedostal som šek',
		'Prišla mi len sms o platbe, ale neprišla mi faktúra',
		'riesit veci ohľadne chýbajúcej faktúry',
		'Stále nemám faktúru.',
		'volám kvôli tomu že neprišlo mi koľko zaplatiť za telefón',
		'zase tento mesiac neprišla faktúra',
	],
	NEPRISLA_MI_FAKTURA_SMS__PREPOSLAT_FAKTURU: [
		'ako sa dá znova poslať faktúru?',
		'chcem znova odoslať faktúru?',
		'pošlete mi aj elektronickú faktúru?',
		'pošlite mi tú faktúru na mail',
		'preposlali by ste mi faktúru',
		'preposlať faktúru',
		'prepošle sa mi faktúra?',
		'viete mi znova zaslať faktúru?',
	],
	NEPRISLA_MI_FAKTURA_SMS__WHEN: [
		'a kedy chodí faktúra?',
		'ako dlho budem čakať na faktúru',
		'ako dlho si počkám na faktúru',
		'dokedy by mi mala faktúra dojsť?',
		'Kedy mi pošlete elektronickú faktúru?',
		'kedy mi pošlete tú faktúru',
		'Kedy mi príde faktúra?',
		'Kedy najneskôr zasielate faktúru?',
		'kedy obdržím faktúri?',
		'kedy sa dočkám e-faktúry',
	],
	NEPRISLA_MI_FAKTURA_SMS__WHERE: [
		'kam mi mala prísť faktúra?',
		'kam ste mi poslali faktúru?',
		'kam ste mi poslali šek?',
		'kam ste zaslali moju faktúru?',
		'kde bola poslaná faktúra?',
		'kde mi prišiel šek za tento mesiac?',
		'kde mi prišiel tento mesiac šek vyúčtovania za telefón?',
		'kde mi prišla faktúra za tento mesiac?',
		'kde ste mi mali poslať šek?',
	],
	NEPRISLA_MI_FAKTURA_SMS__WHY_NOT_SENT: [
		'Ako je možné, že mi eśte neprišla faktúra?',
		'ako je to možné že mi nedošla faktúra?',
		'ako to že mi nedošiel šek',
		'ako to že mi nemôže dôjsť dva mesiace po sebe faktúra?',
		'Ako to že som nedostal fakturaciu?',
		'chcem informacie o tom ze prečo mi nechodí faktúra',
		'chcem vedieť prečo mi nedošiel šek na mágio televíziu',
		'potreboval by od vas vediet prečo mi nedošla faktúra',
		'Potrebujem sa spýtať ze prečo mi nechodí faktúra',
		'preči mi nedošiel šek',
		'Prečo mi nechodí faktúra',
		'prečo mi nechodi faktúra za synov telefon?',
		'prečo mi nedošla faktúra?',
		'prečo mi neprišla faktúra',
		'Prečo mi už nechodia papierové faktúry ?',
		'Prečo som ešte nedostal faktúru?',
		'tak chcem vediet prečo mi nechodí faktúra',
		'Z akého dôvodu ste mi ešte neposlali papierovú faktúru?',
	],
	NOTIFIKACIE_O_FAKURACH__NASTAVIT: [
		'chcem dostávať notifikácie o faktúrach',
		'chcem notifikácie na faktúru',
		'chcem notifikácie',
		'ja by som chcela dostávať nejaké notifikácie k faktúre',
		'nastaviť notifikácie na faktúru',
		'posielať notifikácie k platbám',
	],
	NOTIFIKACIE_O_FAKURACH__NOT_SENT: [
		'neprichádzajú mi SMS notifikácie',
		'Neprišla mi sms notifikácia k faktúre',
		'notifikácia',
		'Notifikácia o blížiacej sa splatnosti faktúry mi prišla skoro.',
		'Posielate notifikácie o vystavení faktúry?',
		'Prečo mi neprišlo potvrdenie faktúry ?',
	],
	NOTIFIKACIE_O_FAKURACH__NOTIFIKACNE_CISLO: [
		'nastaviť notifikačné číslo',
		'notifikačné číslo k faktúram',
		'notifikačné číslo',
		'rád by som zadal notifikačné číslo k faktúram',
		'zadať notifikačné číslo',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__FIX: [
		'aby ste mi znova zapojili pevnú linku',
		'Potrebujem znovuzapojiť pevnú linku?',
		'Potrebujem znovuzapojiť pevnú linku',
		'Zaplatil som, môžte mi znovuzapojiť pevnú linku ?',
		'znovuzapojiť pevnú linku',
		'znovupripojenie linky',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__FIX_STILL_DISCONNECTED: [
		'ako to, že nie som už znovazapojený keď platím ?',
		'chcela by som spojazdniť telefónnu linku po uhradení nedoplatku',
		'ja som opäť na tej linke už sme si povedali chcela by som spojazdniť telefónnu linku po uhradení nedoplatku',
		'nedoplatok bol uhradený chcem aby bolo spojazdnené telefónne číslo',
		'nedoplatok uhradený a chcem zapojiť telefónnu linku naspäť',
		'po uhradení nedoplatku by som chcela spojazdniť telefónnu linku',
		'Prečo mám odpojenú linku keď som to už zaplatil ?',
		'Prečo mám odpojenú pevnú linku keď platím ?',
		'Prečo ste ma ešte nezapojili keď platím ?',
		'Zaplatil som faktúru tak prečo ste ma odpojili?',
		'Zaplatil som za linku, a som stále odpojený?',
		'Zaplatil som za telefón, prečo som stále odpojený?',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__INTERNET: [
		'Mám zaplatenú faktúru, môžte mi už konečne zapojiť internet',
		'Poprosím znovu zapojenie internetu',
		'Prečo mi nefunguje internet keď som vám zaplatil',
		'Pred pár dňami som zaplatil faktúru, môžte mi znovu zapojiť internet',
		'vedeli by ste ma znovu zapojiť na internet?',
		'zistenie znovupripojenia internetu',
		'znovupripojenie internetu',
		'znovu zapojenie internetu',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__INTERNET_STILL_DISCONNECTED: [
		'ako je možné že došlo k odpojeniu môjho internetu',
		'Ako to že mám ma odpájate od interneta?',
		'Ako to že mám odpojený internet?',
		'Ako to, že mám stále odpojený internet, keď som vám zaplatil',
		'ja mám problém odpojenie internetu, televízie',
		'ja mám problém odpojili ste mi internet a televíziu',
		'odpojenie internetu',
		'odpojili ste mi internet',
		'prečo došlo k odpojeniu internetu?',
		'Preco mám odpojený internet ked platim ?',
		'prečo mám odpojený internet?',
		'prečo ste ma odpojili od internetu?',
		'problém s odpojením internetu a televízie',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__MOBILE: [
		'Aj po zaplatení faktúry mi nefunguje telefón',
		'Nie je zapnutý mobil aj po úhrade faktúr',
		'Potrebujem zapojiť mobil',
		'Potrebujem znovuzapojiť mobil',
		'Prečo nie je môj telefón zapnutý, keď sú zaplatené faktúry?',
		'už som vám zaplatil tak mi láskavo zapojte zas ten mobil',
		'veď som zaplatil, tak zapojte ten mobil',
		'Zaplatil som, môžte mi zapojiť mobil ?',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__MOBILE_STILL_DISCONNECTED: [
		'dávno som za mobil zaplatil tak prečo som furt odpojený?',
		'nechápem prečo som odpojený keď som za ten mobil už zaplatil',
		'za mobil mám zaplatené tak z akého dôvodu som ešte odpojený',
		'za mobil som zaplatil tak prečo som furt odpojený?',
		'za mobil zaplatené a stále som odpojený',
		'Zaplatil som za mobil, prečo som stále odpojený?',
		'zaujíma prečo som stále odpojený keď som za mobil už zaplatil',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__NUMBER: [
		'chcem vás požiadať o zapojenie môjho čísla',
		'zapojenie čísla',
		'zapojte mi číslo',
		'žiadam vás o zapojenie čísla',
		'žiadam vás o znovuzapojenie čísla',
		'znovuzapojenie čísla',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__SERVICES: [
		'Ako mám požiadať o znovuzapojenie?',
		'chcela by som preposlať úhradu za telefóny služby aby bol telefón znovu účinných',
		'chcem aby nás zapojili, už som volala, že nás vypli',
		'Chcem zapojiť služby',
		'Ja by som chcela vedieť prečo nemám zapnutý paušál keď som ho vyplatila',
		'ja som volala že nás vypli chcem aby nás zapojili',
		'mal som  problém že som nezaplatil faktúru, včera som uhradil a dneska som neni zapojený, prečo to nejde?',
		'mám zaplatený paušál a chcem opäť zapnúť služby',
		'Mohli by ste ma zase zapojiť, už som totiž zaplatil.',
		'Mohli by ste ma znova zapojiť?',
		'nezaplatil som faktúru, včera som to uhradil ale dneska nie som zapojený. Prečo to nejde?',
		'no mal som taký problém nevyplatil som faktúru ale včera som to uhradil a dneska som neni zapojený, prečo to nejde?',
		'obnovenie ďalšej dodávky služby',
		'Po odpojení som už zaplatil.',
		'Poprosím o opätovné zapojenie, všetko som uhradil.',
		'prečo som bol odpojený keď mám faktúru zaplatenú?',
		'Prosím o znovuzapojenie, už som zaplatil.',
		'Ta volam ohladom pausalu ze ma vypli aj ohladom Magia',
		'Všetko mám zaplatené, môžete ma znovu zapojiť.',
		'vypli nás a chcem aby nás zase zapojili',
		'vypli ste mi mobil a že mám zaplatený paušál a chcem ho opäť zapnúť',
		'vypli ste mi mobil, mám zaplatený paušál, chcem to opäť zapnúť',
		'zaplatila faktúru bola som dočasne vypnutá',
		'zaplatila som faktúra potrebujem vedieť že či kedy budem zapnutá',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__SERVICES_STILL_DISCONNECTED: [
		'chcem vás poprosiť o obnovenie služieb',
		'chcem vás poprosiť o znovupripojenie služieb',
		'chcem vás poprosiť o znovu pripojenie',
		'chcem vás poprosiť o znovuzapojenie služieb',
		'chcem znovu zapojiť služby',
		'Prečo som odpojený keď mám faktúru zaplatenú ?',
		'Priateľ nezaplatil faktúru má tam dlžnú čiastku a nejdú mu všetky služby',
		'prosím o obnovenie služieb',
		'prosím o znovu zapojenie služby',
		'Zaplatena faktura a stále som odpojený od telekomunikačných služieb',
		'znovazapojenie',
		'znovu obnovenie služieb',
		'znovuzapojenie služieb',
	],
	ZNOVUZAPOJENIE_ZAKAZNIKA__TV: [
		'Mám vyplatené faktury a nemám zapojené Magio',
		'Potrebujem zapojiť tv',
		'Potrebujem znovuzapojiť magio tv',
		'prosím o znovuzapojenie mágio televízie',
		'už by ste mi mohli zase zapnúť tú telku, zaplatil som',
		'veď som už zaplatil tak mi znovazapojte tú televíziu',
		'veď som všetko zaplatil tak mi znova tú telku zapnite',
		'Vypadla mi telka a faktúry som zaplatil.',
		'zaplatil som a v ste mi stále nezapojili naspäť tú tv',
		'Zaplatil som, môžte mi zapojiť televíziu ?',
		'znovupripojení televízie',
		'znovu zapojenie mágio televízie a internetu',
		'znovuzapojenie mágio televízie',
		'znovuzapojte mi mágio televíziu a internet',
		'znovupripojeni káblové televízie',
	],
	ZMENA_NASTAVENIA_FAKTURY__ADDRESS: [
		'Ako to že mi faktúra chodí iba na email, chcem to mať tak ako som mal',
		'chcela by som zmeniť fakturačnú adresu',
		'chcela by som aby ste mi faktúru posielali domov na adresu',
		'Chcem, aby ste mi faktúry zasielali inam.',
		'chcem požiadať o zmenu adresy na zasielanie faktúr',
		'Chcem zmeniť adresu kam mi zasielate faktúru.',
		'Chcem zmeniť môj mail kam mi chodí faktúra.',
		'Faktúra mi chodí na starú adresu, ako to zmením ?',
		'posielajte mi faktúry inde',
		'Potrebujem aktualizovať fakturačnú adresu',
		'Potrebujem si zmeniť adresu na faktúre',
		'Potrebujem si zmeniť fakturačnú adresu',
		'potrebujem zmeniť adresu kam mi posielate faktúru',
		'Potrebujem zmeniť číslo, kam mi chodí faktúra.',
		'potrebujem zmeniť e-mailovú adresu na doručovanie faktúr',
		'Presťahoval som sa, tak mi to tam musíte prepísať na novú fakturačnú adresu',
		'Smiem zmeniť adresu kde mi chodí faktúra?',
		'som menil e-mail na doručovanie faktúr',
		'Uz nechcem fakturu postou, ale mailom',
		'žiadam vás o zmenu adresy kam mi chodia faktúry',
		'Žiadam vás o zmenu mojej mailovej schŕanky kam mi pravidelne zasielate faktúru.',
		'Zmeniť adresu fakturačnú',
		'zmeňte mi adresu na posielanie faktúr',
		'Zmeňte mi adresu na zasielanie faktúr.',
		'zmeňte mi email, kde mi posielate faktúru.',
	],
	ZMENA_NASTAVENIA_FAKTURY__EMAIL: [
		'aktivovať zasielanie faktúry mailom',
		'chcem aby ste mi zasielali faktúry emailom',
		'chcem faktúru e-mailom',
		'faktúra e-mailom',
		'faktúru cez email',
		'faktúru e-mail',
		'faktúry chcem do mailu',
		'faktúry chcem dostávať do mailu',
		'faktúry do mailu',
		'faktúry e-mailom',
		'faktúry emailom',
		'faktúry mi posielaje mailom',
		'faktúry za mobil do mailu',
		'mailové zasielanie faktúr',
		'môžete mi začať posielať faktúru na mail',
		'zapnite mi to tak aby ste mi zasielali faktúry mailom',
		'zasielajte mi faktúry mailom',
		'zasielanie faktúr emailom',
	],
	ZMENA_NASTAVENIA_FAKTURY__FORM: [
		'Chcem už len elektronickú faktúru',
		'Chcem zmeniť faktúru na elektronickú',
		'elektronická faktúra by mi viac vyhovovala',
		'elektronická faktúra',
		'preferoval by som elektronickú faktúru',
		'Prosím o elektronickú formy faktúry.',
		'Prosím o zmenu formy faktúry.',
		'Prosím o zmenu formy zasielania faktúry.',
		'vyhovovala by mi elektronická faktúra',
	],
	ZMENA_NASTAVENIA_FAKTURY__FORM_ONLINE: [
		'chcel by som online faktúru',
		'chcem dostávať online faktúry',
		'online faktúru',
		'poprosím online faktúru',
		'prosím o online faktúru',
		'zasielanie online faktúry',
		'želám si dostávať onlineovú fakturu',
	],
	ZMENA_NASTAVENIA_FAKTURY__FORMA_PAPIER: [
		'Chcem faktúru na papieri',
		'Chcem papierovú faktúru',
		'chcem papierovú podobu faktúry',
		'chcem si zmeniť papierová formu faktúry.',
		'fakturácia v papierovej podobe',
		'Mohli by ste mi posielať faktúru papierovo',
		'Nevyhovuje mi papierová forma faktúry.',
	],
	ZMENA_NASTAVENIA_FAKTURY__FORMA_SEK: [
		'chcela by som faktúru aby mi bola zasielaná tak ako doteraz šekom',
		'chcela by som faktúru šekom',
		'chcela by som platbu šekom',
		'Chcem aby mi chodil šek ako faktúra',
		'Chcem aby mi chodil šek',
		'Chcem faktúru šekom',
		'Chcem zaplatiť faktúru šekom',
		'poprosila by som platiť šekom',
		'potrebujem tú istú formu faktúry šekom',
	],
	ZMENA_NASTAVENIA_FAKTURY__GENERAL: [
		'chcel by som zmeniť faktúru',
		'chcem zmeniť faktúru',
		'pprosím o zmenu faktúry',
		'zmena faktúry',
		'zmeniť faktúru',
	],
	ZMENA_NASTAVENIA_FAKTURY__IMPLICITNE_NA_ELEKTRO: [
		'chcela by som zrušiť zasielanie faktúr',
		'chcem zrušiť zasielanie faktúry poštou',
		'chcem zrušiť zasielanie faktúr',
		'rada by som zrušila zasielanie faktúr',
		'rád by som si zrušil posielanie faktúr',
		'zrušenie posielania faktúr',
		'zrušenie zasielania poštou',
		'zrušiť posielanie faktúr',
		'zrušiť zasielanie faktúry poštou',
		'zrušte mi zasielanie poštou',
	],
	ZMENA_NASTAVENIA_FAKTURY__NORMAL_FORM: [
		'Chcem už len normálnu faktúru',
		'Chcem zmeniť faktúru na normálnu',
		'normálnu faktúru',
		'preferoval normálnu faktúru',
		'Prosím o normálnu formu faktúry.',
		'Prosím o zmenu faktúry na normálnu',
	],
	ZMENA_NASTAVENIA_FAKTURY__ZMEN_PAPIER_ELEKTRO: [
		'Chcem zmeniť formu z elektronickej faktúry na papierovú.',
		'Ja by som chcela posielanie faktur na e-mail zmenit',
		'Posielajte mi radšej elektronickú faktúru než papierovú.',
		'Rád by som prepol formu faktúry z papiera na elektronickú.',
		'Rád by som zmenil papierovú faktúru na elektronickú.',
	],
	FAKTURACNE_OBDOBIE__CHANGE: [
		'Ako si zmeniť dátum kedy vám platím',
		'Chcem iné obdobie na fakturáciu',
		'Chcem zmeniť fakturačné obdobie.',
		'Chcem zmenit kedy mi chodi faktura',
		'chcem zmeniť zúčtovacie obdobie.',
		'Chcem zmenu dátumu na faktúre',
		'mohli by ste mi zmeniť dátum platby za faktúru?',
		'môžeme zmenit den platenia?',
		'môžem zmeniť fakturačné obdobie?',
		'Môžte mi dať iný dátum kedy mi chodí faktúra ?',
		'Potrebujem upraviť fakturačné obdobie.',
		'Preferujem platiť za faktúru v inom období.',
		'Prosím o zmenu dátumu platby faktúry.',
		'Rád by som platil za faktúru v inom časovom intervale.',
		'Za faktúru chcem platiť inokedy.',
		'Žiadam vás o úpravu zúčtovacieho obdobia.',
	],
	FAKTURACNE_OBDOBIE__GENERAL: [
		'chcem riešiť fakturačné obdobie',
		'chcem sa spýtať na fakturačné obdobie',
		'fakturačné obdobie',
		'ja volám kvôli fakturačnému obdobiu',
		'mám pár otázok, čo sa týka fakturačného obdobia',
		'Nestíham platiť lebo výplatu mám až o týždeň',
		'Peniaze mi chodia vždy 3, nemôžem faktúru platiť vtedy ?',
		'potrebujem vedieť o fakturačnom období',
		'riešiť fakturačné obdobie',
	],
	POTVRDENIE_PLATBY__AMOUNT_OWED: [
		'aká je moja dlžná čiastka',
		'aká suma je na mojej faktúre',
		'aká veľká je suma za faktúru z predošlého mesiaca?',
		'akú čiastku vám dlžím?',
		'akú sumu vám dlžím?',
		'aký mám u vás dlh?',
		'aký mám u vás doplatok',
		'aký mám u vás nedoplatok',
		'chcela by som vedieť presný a nedoplatok tam',
		'chcel by som vedieť ohľadom faktúry aká čiastka je neuhradená tam, asi je dohodnuté na splátkový kalendár',
		'chcem sa spýtať na sumu faktúry',
		'chcem sa spýtať na to, že koľko mi dáte sumu na faktúre',
		'dlžná čiastka',
		'koľko je čiastka čo dlžím',
		'Koľko mám dohromady zaplatiť, aby som už nebol dlžný?',
		'Koľko mám doplatok?',
		'Koľko mám nedoplatok?',
		'kolko mám u vás dlh',
		'Koľko vám dlžím',
		'kolko vám mám uhradiť dlžné?',
		'ohľadom faktúry aká čiastka je neuhradená dohodnutá na splátkový kalendár',
		'Potrebujem zistiť celkovú dlžnú čiastku',
		'suma faktúry',
		'sumu faktúry',
		'viete mi dať info o sume faktúry',
		'viete mi povedať koľko je suma faktúry',
		'vraj mám u vás dlh, kolko musím zaplatiť?',
		'vraj vám dlžím, kolko mám zaplatiť?',
		'Zistiť koľko mám ešte doplatok faktúry',
	],
	POTVRDENIE_PLATBY__CONTROL: [
		'Došla mi sms, viete skontrolovať, či prešla platba?',
		'kontrola faktúry či je všetko v poriadku',
		'kontrola faktúry',
		'mohli by ste pozrieť či sú moje faktúry v poriadku?',
		'mohli by ste pozrieť na moje faktúry či je všetko v poriadku?',
		'skontrolovať faktúru',
		'skontrolovať faktúry, či je všetko ok',
	],
	POTVRDENIE_PLATBY__HOW_MUCH: [
		'aká je hodnota poslednej faktúry?',
		'aká je výška mojej faktúry?',
		'akú mám faktúru tento mesiac',
		'Akú mám výšku faktúry',
		'Akú vysokú faktúru mám tento mesiac',
		'chcela by som poslednú faktúru',
		'chcem info o výške faktúry',
		'chcem informáciu o výške faktúry',
		'chcem vedieť hodnotu poslednej faktúry',
		'chcem výšku mojej faktúry',
		'faktúra za mesiac',
		'faktúru za tento mesiac',
		'hodnota poslednej faktúry',
		'informáciu o výške faktúry',
		'Len chcem vedieť výšku faktúry',
		'potreboval by som informácie o poslednej faktúre',
		'potreboval by som informáciu faktúra ktoré platím',
		'potrebujem informácie ohľadne poslednej faktúry',
		'Potrebujem upresniť výšku faktúry',
		'výška faktúry',
	],
	POTVRDENIE_PLATBY__HOW_MUCH_TO_PAY: [
		'Ako zistim koľko mám zaplatiť',
		'akú čiastku treba zaplatiť za faktúru?',
		'akú sumu mám presne zaplatiť za faktúru?',
		'koľko mám zapaltiť za faktúru?',
		'koľko mám zaplatiť',
		'koľko platím za faktúru?',
		'koľko vam mám platit za faktúru?',
		'koľko vám mám zaplatiť?',
		'koľko zaplatiť za faktúru?',
	],
	POTVRDENIE_PLATBY__HOW_TO: [
		'Aká platba vám prejde najrýchlejšie ?',
		'Aké mám možnosti pri platení faktúry ?',
		'ako mám zaplatiť?',
		'Ako môžem zaplatiť faktúru?',
		'Ako urobím platobný prevod ?',
		'Ako zaplatím faktúru na pošte ?',
		'ako zaplatiť faktúru?',
		'ako zaplatiť ?',
		'chcem faktúru zaplatiť čo najrýchlejšie.',
		'chcem uhradiť faktúru, ako na to?',
		'chcem zaplatiť faktúru, ako na to?',
		'Chcem zaplatiť faktúru niekomu inému. Ako na to?',
		'chcem zaplatiť faktúru',
		'Dakujem, to vsetko je v poriadku, len platba',
		'Dá sa platiť faktúra v hotovosti ?',
		'Dá sa zaplatiť faktúra na pobočke?',
		'jak zaplatiť?',
		'Mám problém s platením faktúry',
		'mám problém s úhradami našej pevnej linky',
		'mám problém s úhradami',
		'mám problém uhradiť',
		'Môžem platiť faktúru kartou ?',
		'Neviem či mám zadať aj variabilný symbol k platbe.',
		'Potrebovala by som sa dostat k fakturam pretoze mi neprisli a nemam ako zaplatit',
	],
	POTVRDENIE_PLATBY__MADE_MISTAKE: [
		'Peniaze som asi poslal na zlý účet.',
		'pomýlil som sa pri platbe',
		'pomýlil som si číslo účtu',
		'poslal som peniaze za faktúru na zlé číslo účtu',
	],
	POTVRDENIE_PLATBY__NEED_DATA: [
		'aké máte číslo účtu k platbe za faktúru?',
		'aké sú údaje k platbe?',
		'Aké údaje potrebujem na zaplatenie faktúry',
		'Aké údaje potrebujem na zaplatenie ?',
		'Aký je môj variabilný symbol?',
		'Aký mám použiť IBAN na platenie faktúry ?',
		'chýba mi variabilný symbol k platbe.',
		'Čo je to ten variabilný symbol ?',
		'Kde nájdem variabilný symbol k platbe?',
		'Môžete mi poslať moj variabilný symbol ?',
		'Na aký účet mám poslať peniaze za faktúru?',
		'Nemám údaje k platbe.',
		'Nemám údaje k platbe za tretiu osobu.',
		'Neviem nájsť číslo účtu k platbe za faktúru.',
		'Neviem svoj variabilný symbol',
		'Neviem vase cislo uctu',
		'Potrebuje variabilný symbol',
		'Som išla platiť ale zabudla som udaje',
	],
	POTVRDENIE_PLATBY__NEUHRADENE_FAKTURY: [
		'ešte tam mám nejaké faktúry neuhradené?',
		'ktoré faktúry mám neuhradené?',
		'mám ešte neuhradené faktúry?',
		'mám stále nejaké neuhradené faktúry',
		'mám tam ešte furt neuhreadenú faktúru?',
		'neuhradená faktúra',
		'neuhradené faktúry',
		'už by som nemal mať neuhradené faktúry, je to tak?',
	],
	POTVRDENIE_PLATBY__NOT_SENT: [
		'ešte ste mi neposlali notifikáciu o zaplatení',
		'Kedy mi príde notifikácia o prijatej úhrade?',
		'Moja platba nebola potvrdená',
		'nebola potvrdená platba',
		'nechodia mi SMS správy k platbám',
		'Nedošla mi notifikácia o prijatej úhrade.',
		'nemám potvrdenie o zaplatení',
		'Neprišla mi notifikácia o zaplatení.',
		'notifikácia o zaplatení mi nedošla',
		'Prečo som ešte nedostal potvrdenie o zaplatení?',
		'stále nemám nejaké potvrdenie o zaplatení',
		'stále ste mi neposlali potvrdenie o zaplatení',
	],
	POTVRDENIE_PLATBY__OVERIT: [
		'chcem preveriť platbu',
		'chcem si overiŤ či evidujete platbu',
		'chcem si overiť platbu',
		'overenie platby',
		'rád by som preveril platbu',
		'zaevidovali ste moju platbu?',
		'Zaplatil som, evidujete moju platbu?',
	],
	POTVRDENIE_PLATBY__OVERIT_SPLATNOST: [
		'faktúra overenie splatnosti',
		'kontrola splatnosti faktúry',
		'mám faktúru potrebujem overiť splatnosť',
		'overiť splatnosť faktúry',
		'overiť splatnosti termínu',
		'overkedy je splatnosť faktúry',
		'overte mi splatnosť faktúry',
	],
	POTVRDENIE_PLATBY__PLAIN_PLATBA: [
		'chcem info o uhradení faktúry',
		'chcem riešiť uhradenie faktúry',
		'ja volám zaplatiť faktúru',
		'ja volám za uhradenie faktúry',
		'mám zaplatené faktúry',
		'mám zaplatené',
		'ohľadom platby za služby',
		'Platba',
		'Platbu',
		'platiť faktúru',
		'úhrada faktúr',
		'úhrada faktúry',
		'uhradená faktúra',
		'uhradené faktúry',
		'uhradila som',
		'uhradiť faktúru',
		'Úhrady faktúr',
		'volám kvôli úhrade faktúry',
		'volám ohľadom platby za služby',
		'zaplatil som',
		'zaplatiť faktúru',
		'zaplatiť za faktúru',
	],
	POTVRDENIE_PLATBY__REMINDER_COMPLAINT: [
		'čo mi chodia upozornenia, keď som vám zaplatil',
		'faktúru som zaplatil, prečo mi chodia upozornenia?',
		'prečo mi došla notifikácia o nezaplatení? Ja som zaplatil.',
		'Preco mi dosli notifikacie o nezaplateni?',
		'prečo mi došlo pripomenutie o nezaplatení? Ja som zaplatil',
		'Preco mi doslo pripomenutie o nezaplateni?',
		'prišla mi sms správa o nezaplatení faktúry ktorú som už uhradil',
		'Prisla mi SMS, ze som neuhradila fakturu, ale ja som platila',
		'prisla mi upomienka, že som nezaplatil faktúru, ale ja som zaplatil',
		'prišlo mi že som faktúru neuhradila no ja som uhradila',
	],
	POTVRDENIE_PLATBY__STATUS: [
		'aký je stav mojej faktúry',
		'Potrebovala by som zistit stav faktur',
		'potrebujem aby ste mi pozreli faktúru',
		'potrebujem zistiť stav faktúry',
		'pozrite mi faktúru číslo',
		'stav mojej faktúry',
		'stav zaplatenia faktúry',
		'zistiť stav faktúry',
	],
	POTVRDENIE_PLATBY__UHRADENE_ZAPLATENE: [
		'Ako sa dozviem, že som zaplatil za faktúru?',
		'Ako viem ze som zaplatil fakturu ?',
		'Ako viem že som zaplatil faktúru ?',
		'chcela by som sa informovať o nezaplatení faktúry',
		'chcela by som sa informovať o zaplatení faktúry',
		'chcela by som sa spýtať je zaplatená faktúra posledná',
		'chcela by som vedieť či som uhradila faktúru',
		'chcel by som, či mám dobre zaplatené faktúry',
		'chcel by som vedieť či som uhradil faktúru',
		'chcem vedieť či máme zaplatenú faktúru na tomto telefónnom čísle',
		'Chcem vedieť či mám uhradenú faktúru',
		'či prišla už moja platba do telekomu',
		'je zaplatená posledná faktúra',
		'mám zaplatenú faktúra?',
		'Prišli vám peniaze?',
		'Som zaplatila fakturu, vidite to tam?',
		'Vidite v systéme, že som uhradila sumu?',
		'vieš či mám zaplatené faktúry?',
		'volám kvôli tomu že som platila za faktúry',
		'volám kvôli tomu že som zaplatila fakturu, vidite to tam?',
	],
	ODKLAD_PLATBY__GENERAL: [
		'chýbajú mi peniaze ktoré musím zaplatiť za faktúru',
		'chýba mi suma ktorú musím zaplatiť',
		'mám zaplatiť faktúru ale nemám toľko peňazí',
		'Nemôžem teraz uhradiť faktúru',
		'nemôžem vám zaplatiť za faktúru včas lebo nemám peniaze',
		'nemôžem zaplatiť načas, chýbajú mi peniaze',
		'Nemôžem zaplatiť, o 5 dní by som chcela',
		'Nestíham zaplatiť faktúru',
		'Peniaze budem mať až o týždeň',
		'Predĺžiť splatnosť faktúry',
	],
	ODKLAD_PLATBY__NEODPOJIT: [
		'Chcem aby mi neodpojili telefón',
		'nehcem aby mi odpojili telefón',
		'neodpojte mi telefón',
		'potrebujem zrušiť odpojenie telefóna',
		'zastavte mi odpájanie telefóna',
	],
	ODKLAD_PLATBY__ODKLAD: [
		'Chcela by som odlozit platbu',
		'chcel by som si pozastaviť platbu faktúr',
		'Chcem odklad platby, da sa to?',
		'Môžem si tento mesiac odložiť platbu faktúry?',
		'môžem uhradiť platbu neskôr?',
		'Nemáme zaplatenú faktúru, môžem uhradiť neskôr?',
		'odklad',
		'poprosím o predĺženie splatnosti termínu',
		'poprosím posunúť splatnosť faktúry',
		'posunutie splatnosti faktúry',
		'Potrebujem zaplatiť faktúru neskôr ako mám v zmluve',
		'pozastavenie platby faktúr',
		'pozastaviť platbu faktúr',
		'prosím vás chcel by som pozastaviť platbu faktúr',
		'Smiem zaplatiť trochu neskôr?',
		'Viete mi spraviť odklad platby ?',
	],
	ODKLAD_PLATBY__PO_SPLATNOSTI: [
		'čo v prípade keď mám faktúru po splatnosti',
		'faktúra po dobe splatnosti',
		'faktúra po splatnosti',
		'faktúry po splatnosti',
		'máme tu faktúru po dobe splatnosti',
		'mám faktúru po splatnosti',
	],
	ODPIS_FAKTURY__SEND_ME: [
		'Chcem odpis faktúry za minulý mesiac',
		'Chcem odpis vyúčtovania za minulý mesiac',
		'Poslali by ste mi prosím odpis faktúry mailom?',
		'Potrebujem odpis',
		'Žiadam vás o odpis faktúry.',
	],
	DLZNA_CIASTKA__3_STRANA: [
		'ako bude dlh vymáhať 3.strana?',
		'ako vymáha dlh 3.strana?',
		'Čo sa deje ak budete vymáhať cez 3.stranu?',
		'čo to je za  3.strana čo bude vymáhať?',
		'Čo znamená, že dlh bude vymáhať 3.strana?',
	],
	DLZNA_CIASTKA__GENERAL: [
		'dlh',
		'dlžoba',
		'dlžobu',
		'doplatok',
		'mám dlh',
		'mám nedoplatok',
		'nedoplatok',
		'o dlhu na faktúru',
		'ohľadom dlhu na faktúru',
		'potrebujem info ohľadom dlhu na faktúru za telefón',
	],
	DLZNA_CIASTKA__ODKLAD_PROSIM: [
		'ak to mám platiť tak mi treba odklad',
		'musím dostať odloženie platenia',
		'Nezvládam platiť, prosím o odklad splátky.',
		'Nezvládnem takto platiť, musíte mi dať odklad.',
		'potrebujeme aby ste nám odložili splácanie',
		'treba mi preložiť splátky.',
	],
	DLZNA_CIASTKA__POHLADAVKA: [
		'chcem riešiť pohľadávku',
		'chcem sa spýtať na pohľadávku',
		'chcem vedieť niečo o pohľadávke',
		'máte na mňa pohľadávku',
		'pohľadávka',
		'pohľadávky',
		'potrebovala by som spojiť na vymáhanie pohľadávok',
		'vymáhanie pohľadávky',
		'vymáhanie pohľadávok',
	],
	DLZNA_CIASTKA__POSTUP: [
		'Čo mám robiť, ked chcem splatiť dlhy',
		'Keď splatím dlhy, zastaví sa vymáhanie?',
		'Keď zaplatím svoj dlh, bude všetko v poriadku?',
		'Potrebujem pomoc s platenim dlhu',
		'Potrebujem vyriešiť dlh',
		'Po zaplatení všetkých svojich dlhov môžem ďalej využívať služby?',
		'Zaplatil som dlžnú čiastku, čo teraz ?',
	],
	DLZNA_CIASTKA__PRECO: [
		'Ako to že si musím platiť poplatky?',
		'Ako to že si odomňa vymáhate peniaze ?',
		'Prečo prišiel nedoplatok',
		'Prečo som vo vymáhaní',
		'prišiel nedoplatok ale ja mám že som to uhradila',
		'Zaplatil som faktúru včas tak prečo musím platiť nedoplatky',
	],
	BILLSHOCK__GENERAL: [
		'Chcel by som si ujasniť faktúru',
		'chcem vysvetliť fakturáciu',
		'chcem vysvetliť faktúru',
		'nespokojná s mesačnou platbou, žiadam vysvetlenie že čo je vo veci',
		'nespokojnosť s fakturáciou',
		'Nesúhlasím so sumou čo je na faktúre',
		'Nezrovnalosť faktúry',
		'nie som spokojná s fakturáciou',
		'nie som spokojná s platbou mesačnou, budete taká dobrá a vysvetlíte mi že čo je vo veci?',
		'nie som spokojná s vašou fakturáciou mesačnou',
		'nie som spokojná s vašou mesačnou fakturáciou',
		'potreboval by som informáciu o faktúre ktorú platím za mágio',
		'vysvetlite mi faktúru',
		'vysvetliť faktúru',
		'Zaujíma ma účet za telefón',
	],
	BILLSHOCK__INVOICE_COMPLAINT: [
		'Ako to ze mám platiť ak som nevolal?',
		'chybne vystavená faktúra',
		'ja som neprevolal to čo od nás chcete zaplatiť',
		'Ja som nevolal na spoplatnené čísla, čo to robia na mojej faktúre ?',
		'mám faktúru a podľa mňa nesprávne účtovanú, prosím aby ste mi to pozreli',
		'mám nesprávne účtovanú faktúru, prosím aby ste mi to pozreli',
		'Mám platiť za niečo a pritom ani  neviem, čo to je?',
		'nám účtujete niečo čo sme nevolali',
		'niečo mi naskakuje nejaké eurá a neviem za čo',
		'Odmietam zaplatit tieto vymyslene poplatky',
		'poslal som tam reklamáciu na faktúru',
		'poslal som vám reklamáciu faktúry, prosím aby ste mi to pozreli',
		'prečo mám platiť ak som neprevolal',
		'reklamácia faktúry',
		'reklamácia na faktúru',
		'reklamovať faktúru',
	],
	BILLSHOCK__NEROZUMIE_PLATBAM: [
		'chcem objasniť platbu',
		'Čo je doplnková časť mesačného poplatku?',
		'Čo je to za poplatok ?',
		'Čo sú to alikvótne poplatky?',
		'Čo sú to T-platby?',
		'Čo znamenajú informačné služby a prečo za ne platím?',
		'dostala som nezrozumiteľnú faktúru',
		'Mám vo faktúre úhradu za hry cez SMS, neviem o čo sa jedná',
		'nechápem, čo mám na faktúre',
		'nerozumiem faktúre',
		'nerozumiem faktúre, nedáva zmysel',
		'Nerozumiem niektorým položkám na faktúre.',
		'nerozumiem prečo je faktúra toľko eur',
		'nevyznám sa vo svojej faktúre',
		'objasniť platbu',
		'potreboval by som objasniť jednu platbu',
		'potreboval by som zistiť prečo mám nejaké platby z výpisu nad rámec vyčerpaných dát',
		'potreboval by som zistiť prečo mám vyúčtované nejaké služby nad rámec',
		'potrebujem zistiť prečo mám nejaké platby navyše, nad rámec vyčerpaných dát',
		'prosila by som objasniť platbu',
		'Viete sa mi pozriet na fakturu ? Nerozumiem jej',
	],
	BILLSHOCK__NEW_CHARGES: [
		'Alikvótne poplatky',
		'dodatočné poplatky',
		'mám na faktúre platene sms',
		'Mám na faktúre spoplatnené čísla',
		'Mám na účte spoplatnené čísla',
		'nové poplatky',
		'platené smsky',
		'Že tu mám neobmedzené MMS a SMS zadarmo a mám tu účtované 6 eur',
	],
	BILLSHOCK__OPRAV_SUMU: [
		'Chcem opraviť sumu a rozdeliť',
		'musíte mi opraviť faktúru',
		'poopravovať faktúry',
		'Potrebujem nápravu faktúru',
		'Potrebujem opraviť faktúru',
		'vedeli by ste mi opraviť faktúru',
	],
	BILLSHOCK__PLATIM_INU_SUMU: [
		'dali ste nám platiť na faktúre inú sumu',
		'na faktúre je iná suma ako doteraz',
		'na faktúre je teraz iná suma',
		'zakaždým nám posielate inú výšku faktúry',
		'zmenila sa mi splatná suma na faktúre',
		'zmenili ste mi sumu na faktúre',
	],
	BILLSHOCK__PLATIM_MENEJ: [
		'mám nejakú malú faktúru',
		'mám nejakú malú faktúru za tento mesiac',
		'nieje tá faktúra príliš nízka?',
		'suma na faktúre je podozrivo nízka',
		'zdá sa mi že mám nejakú malú faktúru',
	],
	BILLSHOCK__PLATIM_VIAC: [
		'Ako ste mi mohli naúčtovať väčšiu sumu tento mesiac. Nič som si neobjednal',
		'Ja by som sa chcela spýtať na platbu, lebo sa mi vidí, že ju mám vyššiu',
		'mám cenu faktúry nie ako som mala stále ale vyššiu o desať euro',
		'Mám neobmedzený paušál a prišla mi vyššia faktúra',
		'mám zvýšený poplatok na faktúre',
		'nahlásiť že ste mi zvýšili cenu faktúry',
		'navýšený poplatok',
		'Nechcem platiť viac',
		'no zvýšenie faktúry',
		'oproti minulému mesiacu platím o päť éčiek viac',
		'platba za faktúru bola navýšená',
		'platím viac o päť éčiek oproti minulému mesiacu',
		'posielate nám stále vyššie faktúry',
		'poslali ste mi nezvyčajnú vyššiu faktúru, nie ako som mala',
		'prečo mám nejaké platby naviac?',
		'provedali, že budem platiť rovnako ale prišla mi väčšia faktúra',
		'v porovnaní s minulým mesiacom platím viac',
		'zvýšený poplatok',
	],
	BILLSHOCK__PRECO_PLATIM_VIAC: [
		'Ako to že mám faktúru vyššiu o osem euro',
		'Ako to ze platim viac na fakture?',
		'Ako to že platím viac na faktúre?',
		'Ako to že platím viac na faktúre tento mesiac',
		'Ako to že zrazu platím viac',
		'mi napočítali naviac eurá',
		'mi napočítali už naviac štyri eurá',
		'mi napočítali viac o',
		'Mohli by ste mi povedat prečo zrazu platím viac?',
		'nechápem ako to že zrazu platím viac',
		'Nemal som platiť menej na faktúre?',
		'nerozumiem prečo mám takú vysokú faktúru',
		'nerozumiem prečo platím viac',
		'nerozumiem výške svojej faktúry',
		'nezdá sa mi výška faktúry',
		'Prečo mám odrazu toľko platit?',
		'prečo mám takú výšku faktúry akú mám',
		'Preco mám taku vysoku fakturu?',
		'Prečo mám zas platiť viac?',
		'prečo mám zvýšený poplatok na faktúre',
		'Preco odo mna chcete tento mesiac viac penazi ?',
		'Prečo platím o desať euro viac',
		'Prečo platím viac keď mi skončila viazanosť',
		'Prečo sa nám navýšila faktúra',
		'Prečo zas platím viac?',
		'Prečo zrazu platím viac?',
		'prišiel mi šek veľa zaplatiť a neviem prečo',
		'prišla mi nejaká vyššia faktúra chcela by som vedieť že kvôli čomu',
		'prišla mi veľká faktúra a neviem prečo',
	],
	BILLSHOCK__VYSKA_BOLA_INE_CISLO: [
		'mal som faktúru platiť 40 10 a teraz mi prišlo',
		'platila som faktúru za telefón iba 20 20 a teraz mám 30 40',
		'prečo platím 25 euro keď mám paušál len na 20',
		'prečo platím 25 euro mesačne keď mám paušál 20',
		'prečo platím 25 mesačne keď mám paušál 20',
		'výška faktúry bola iba 14 20 a teraz je 30 50',
	],
	BILLSHOCK__VYSKA_FAKTURY: [
		'A to za čo mám tak strašne veľa zaplatiť?',
		'chcem reklamovať výšku faktúry',
		'chcem reklamovať vysokú faktúru',
		'chcem sa spýtať na vysokú faktúru',
		'dostal som faktúru na vysokú sumu',
		'dostal som faktúru na zaplatenie a mám tam nehoráznu sumu',
		'mám vysokú faktúru',
		'mi prišlo veľa platiť a mám tam aj také dáta čo sme nevolali',
		'mi prišlo veľa platiť',
		'môj problém je vysoká faktúra a tu ju chcem reklamovať',
		'Neočákávane mám platiť strašne veľa?',
		'Neviem prečo je taký vysoký nedoplatok',
		'no chcela by som sa spýtať ohľadom telefónu nakoľko mi prišlo veľa platiť',
		'Odrazu mám vysokú fakturu',
		'potrebujem zistiť prečo mám väčšiu sumu platiť',
		'prišla mi vysoká faktúra',
		'prišlo mi veľa platiť',
		'vo faktúre mám hrozne veľa eur platiť',
		'vysoká faktúra',
		'vystavili ste mi príliš veľkú faktúru.',
	],
	PREPLATOK__DUPLICITNA_PLATBA: [
		'duplicitné platby',
		'duplicitná platba',
		'by sme chceli požiadať o vrátenie duplicitnej platby',
		'chcem reklamáciu úhrady faktúry, úhrada prebehla dvakrát',
		'chcem vrátiť faktúru zaplatenú duplicitne',
		'Faktúru som zaplatil dvojmo, viete mi to odpočítať z ďalšej faktúry',
		'mám zaplatený účet dvakrát',
		'Nechtiac som zaplatil za faktúru dvakrát, vrátite mi peniaze?',
		'potrebujem aby ste mi vrátili duplicitnú platbu',
		'potrebujeme refundovať jednu sumu ktorá bola duplicitne zaplatená',
		'potrebujeme refundovať sumu ktorá bola duplicitne zaplatená',
		'uhradil som faktúru dvakrát',
		'Zaplatil som fakturu duplicitne',
		'Zaplatil som faktúru omylom dvakrát',
		'Zaplatil som účet dvakrát',
	],
	PREPLATOK__GENERAL: [
		'ako dostanem preplatok späť?',
		'Ako môžem dostat preplatok',
		'Ako riešite preplatky ?',
		'Ako si mám zažiadať o vrátenie preplatku?',
		'chcem riešiť preplatok faktúry',
		'čo mám robiť, keď mám preplatok',
		'čo mám robiť, keď som zaplatila viac',
		'dobrý deň,  prišla mi faktúra s preplatkom',
		'Kedy mi vrátite preplatok',
		'Mám preplatok a neviem, ako ho získať späť.',
		'mám preplatok na faktúre a potrebujem zistiť čo s tým ďalej',
		'môźem zažiadať spätne o preplatok?',
		'Okamžite chcem naspäť peniaze ktoré som preplatil',
		'Potrebujem dostať naspäť preplatok',
		'Prečo ste si nechali môj preplatok',
		'Preplatil som faktúru. Môžte mi to odpočítať z ďalšej platby',
		'preplatok chcem riešiť',
		'preplatok',
		'potrebovala by som sa poradiť ohľadom preplatku',
	],
	PREPLATOK__ZAPLATIL_VIAC: [
		'Chcem naspäť extra peniaze ktoré som vám poslal',
		'Som vam poslala 30 eur a mala som zaplatit len 15',
		'Som zaplatila viac, nez je faktura',
		'Zaplatil som vám o 4 euro viac a chcem ich naspäť',
		'Zaplatil som viac peňazí ako som mal, vrátia sa mi ?',
	],
	INKASO__FAILED_INKASO: [
		'nebolo zrealizované inkaso overím čo mám povedať',
		'aké zmeny mám urobiť, keď inkaso nezbehlo?',
		'Inkaso nezbehlo, akú zmenu mám spraviť?',
		'Mal som vyššiu faktúru a nestiahli ste mi to z účtu',
		'Neodišli mi peniaze z účtu',
		'Nestiahli ste mi peniaze z účtu, ako to mám zaplatiť?',
		'potrebovala by som zistiť prečo mne neodišla platba v rámci inkasa ku vám do telekomu',
	],
	INKASO__GENERAL: [
		'ohľadne platieb potrebujem hovoriť inkasa',
		'ako si mám zriadiť inkaso?',
		'Ako si u vás spravím inkaso?',
		'ako si založiť inkaso?',
		'Ako zrušiť inkaso?',
		'Budem platiť inkasovými platbami',
		'chcela by som informácie o posledných inkaso platbách',
		'Chcem aby mi odchádzali platby z účtu',
		'Chcem robiť platby inkasom',
		'Chcem takú platbu inkasom',
		'hľadám inkasá',
		'Informácie o inkase',
		'inkasá',
		'inkaso',
		'Nedá sa mi nastaviť inkaso z banky, podľa všetkého máte uvedený zlý účet pre inkaso',
		'Potrebujem pomôcť s inkasom',
		'Potrebuje zmeniť formu úhrady miesto šeku aby mi sťahovalo z účtu',
		'Už viac nechcem inkaso.',
		'Vraj sa da platit inkasom',
	],
	INKASO__INKASNY_LIMIT: [
		'ako si zvýšiť inkasný limit',
		'ako si u vás zvýšim inkasný limit',
		'zvýšiť inkasný limit na faktúru na platenie na platbu',
	],
	INKASO__INKASNY_PRIKAZ: [
		'ako si spraviť inkasný príkaz',
		'ako si u vás spravím inkasný príkaz',
		'chcem si dať inkasný príkaz',
		'chcem si nastaviť inkasný príkaz',
		'inkasný príkaz',
		'zriadiť inkasný príkaz',
	],
	INKASO__OVERIT: [
		'by som chcela by som informáciu o inkase',
		'chcem preveriť inkaso',
		'chcem si overiť inkaso',
		'kontrola inkasa',
		'kontrola inkaso či je všetko v poriadku',
		'mohli by ste pozrieť či je moje inkaso v poriadku?',
		'overenie inkasa',
		'potrebujem overiť platbu inkasom',
		'rád by som preveril inkaso',
		'skontrolovať inkaso',
		'skontrolovať nastavenie môjho inkasa',
		'zaevidovali ste platbu za moje inkaso?',
		'Zaplatil som inkaso, evidujete to?',
	],
	SPOTREBA__CALLS: [
		'Aké mám prevolané minúty',
		'akú čiastku som pretelefonoval',
		'Akú čiastku som prevolal',
		'chcela by som vedieť koľko som pretelefonoval',
		'chcem vedieť koľko mám prevolané za celé obdobie',
		'chcem vedieť koľko som telefonovala',
		'ja by som chcela vedieť koľko mám tých voľných minút prevolaných',
		'Koľko hovorov som telefonovala',
		'koľko mám pretelefonované',
		'koľko mám prevolaných hovorov za tento mesiac',
		'Koľko minút mám prevolaných',
		'koľko minút som prevolal toto obdobie?',
		'Koľko môžem tento mesiac ešte volať ?',
		'Koľko som pretelefonovala od môjho posledného vyúčtovania?',
		'Koľko som pretelefonovala',
		'koľko som pretelefonoval',
		'Koľko som prevolal na paušál tento mesiac?',
		'koľko som prevolal za toto obdobie?',
		'koľko som telefonovala',
		'Koľko som už tento mesiac prevolal na paušále?',
		'koľko volaní som telefonovala',
		'kvôli prevolaným minútam',
		'ohľadom prevolaných minút',
		'potrebujem zistiť prevolané minúty',
		'Prevolané minúty',
		'prevolaným minútam',
		'skontrolovať prevolané minúty',
		'Ten prehlad tych minut prevolanych',
		'koľko som prevolal a do mobilných sietí z tohto čísla ktorého volám',
	],
	SPOTREBA__CALLS_LEFT: [
		'aký je môj zostatok voľných minút?',
		'aký mám zostatok voľných minút do mobilných sietí',
		'aký mám zostatok voľných minút',
		'chcem vedieť, či mám ešte dostatok voľných minút',
		'Koľko mám ešte voľných minút?',
		'Koľko mám voľných minút',
		'Koľko minút mi zostáva na paušály',
		'Koľko minút mi zostáva tento mesiac na paušáli?',
		'Koľko voľných minút a ešte mám?',
		'mám dostatok voľných minút do mobilných sietí',
		'mám ešte dostatok voľných minút?',
	],
	SPOTREBA__CREDIT: [
		'Ako si môžem ustrážiť spotrebu kreditu',
		'ako zistím koľko mam eště kreditu',
		'Ako zistím koľko som spotreboval kreditu',
		'aktuálna výška kreditu',
		'aký je stav môjho kreditu',
		'Chcem si overiť zostávajúcu výšku kreditu',
		'zostatok kreditu na easy karte',
		'Chcem vedieť zostatok môjho kreditu',
		'Koľko mám ešte kreditu',
		'pozri sa koľko mam kreditu',
		'stav kreditu',
		'výška kreditu',
		'zistiť aktuálny kredit',
	],
	SPOTREBA__DATA: [
		'Aká je moja aktuálna spotreba dát?',
		'Ako som používal internet na svojom paušály',
		'Koľko som minul internetu na paušáli tento mesiac?',
		'Nemam poruchu len mi vyplo data a chcem vediet ze ci som to prečerpal alebo ako to je',
		'Potreboval by som zistit ze kolko mam nad ramec dat',
		'Prenesene data chcem zistit',
	],
	SPOTREBA__GENERAL: [
		'aká je moja spotreba do mobilných sietí?',
		'lebo chcem vedieť spotrebu do mobilných sietí?',
		'aká je moja spotreba?',
		'aká je spoteba pre moj pausal?',
		'Ako sa dostanem k info o spotrebe?',
		'Ako si môžem manažovať spotrebu',
		'Ako viem koľko spotrebujem ?',
		'Ako viem že som prekročil spotrebu ?',
		'Ako zistím moju spotrebu?',
		'Aku mám spotrebu na mojom paušály?',
		'Aký je stav na mojom paušále ?',
		'Chcem byť informovaný o svojej spotrebe',
		'chcem viac informácií o svojej spotrebe.',
		'Jak zistím svoju spotrebu?',
		'Kde nájdem údaje k svojej spotrebe?',
		'Kde si môžem skontrolovať svoju aktuálnu spotrebu k paušálu?',
		'Koľko ešte môžem minút z paušálu ?',
		'Koľko som spotreboval tento mesiac na paušále?',
		'mám pausal ANO, aká je tam spotreba?',
		'Mozte mi povedat kde nájdem koľko som spotreboval tento mesiac?',
		'Neviem ako dôjsť k mojej spotreba.',
		'Potreboval by som vediet, kolko mam nad ramec',
		'Potrebujem pomoc s mojou spotrebou ?',
		'spotreba',
		'stav spotreby na tomto čísle',
		'stav spotreby',
		'Viem si skontrolovat spotrebu k pausalu ?',
	],
	SPOTREBA__PEVNA_LINKA: [
		'chcela by som zistiť koľko mám pretelefonované na pevnú linku a na mobil',
		'chcela by som zistiť koľko mám spotrebu na pevnej linke',
		'chcela by som zistiť koľko som pretelefonovala na pevnú linku',
		'chcela by som zistiť spotrebu za pevnú linku',
		'chcem poznať spotrebu na pevnej linke',
		'potrebujem vedieť informáciu o prevolaných minútach na pevnú linku',
		'Prevolané minúty na pevnej linke',
		'spotreba na pevnej linke',
		'spotreba pevná linka',
	],
	SPOTREBA__SMS: [
		'Aká je moja aktuálna spotreba SMS?',
		'aká je moja spotreba smsiek?',
		'Ako viem koľko smsiek som poslal ?',
		'Kolko smsiek som odoslala tento mesiac?',
		'koľko sms zdarma ešte mám na paušáli?',
		'koľko volných sms mi zostáva?',
		'mám ešte voľné smsky?',
		'Môžem ešte posielať smsky zadarmo na svojom paušáli ?',
		'Môžem ešte posielať smsky zadarmo na svojom paušáli?',
		'prehľad odoslaných smsiek',
	],
	SPOTREBA__VOLANIA_SPOTREBA: [
		'Aká je moja spotreba volaní',
		'ako mám spotrebu volaní',
		'Akú mám spotrebu volaní',
		'koľko je moja spotreba na hovoroch?',
		'spotreba volaní',
	],
	UPOMIENKA__AKO_ZAPLATIT: [
		'Ako mám zaplatiť upomienku',
		'Ako sa zbavím upomienky',
		'Chcem si odbaviť upomienku',
		'Chcem zaplatiť upomienku',
		'Čo mám robiť, keď mi prišla upomienka',
	],
	UPOMIENKA__GENERAL: [
		'chcem riešiť upomienku',
		'došlo nám faktúra že nemám zaplatenú faktúru za službu',
		'dostala som sms, źe nemám uhradenú faktúru',
		'mam upomienku že som nezaplatila',
		'Odoslali mi SMS, že nemám uhradenú faktúru',
		'prišla mi sms, že nemám zaplatenú faktúru',
		'riešiť upomienku',
		'upomienka',
		'upomienky by som chcela riešiť',
	],
	UPOMIENKA__INFO: [
		'došla mi od vás upomienková správa',
		'dostal som od vás upomienku',
		'môžem sa tu spýtať na upomienky?',
		'Poslali ste mi upomienku',
		'Prečo mi prišla upomienka?',
		'Prišla mi od vás upomienka',
	],
	UPOMIENKA__NASLEDKY: [
		'ako sa rieši nezaplatenie upomienky?',
		'ako sa trestá nezaplatenie upomienky?',
		'aký je trest, keď nezaplatím upomienku?',
		'čo mi hrozí, keď mi prišla upomienka s dlžnou sumou.',
		'Čo sa stane, keď nezaplatím upomienku?',
	],
	UPOMIENKA__REKLAMACIA: [
		'chcem reklamovať upomienky',
		'Mám problémy s upomienkami',
		'mám upomienku ale je už dávno uhradená faktúra',
		'mám zaplatený telefón a prišla mi upomienka tak neviem čo tým',
		'Prečo mi chodia upomienky, ja som všetko zaplatil.',
		'Prišla mi upomienka aj keď som už zaplatil.',
		'prišla mi upomienka a mám zaplatené',
		'reklamovať platbu za upomienku',
		'Reklamujem platbu za upomienku',
		'šek som si zaplatila a prišla mi upomienka o nezaplatení',
		'zaplatila som a prišla mi upomienka o nezaplatení',
	],
	VYPIS_FAKTURY__FAKTURY: [
		'Chcem výpis vyúčtovania',
		'Moj vypis faktury',
		'Potrebujem jednorázovy výpis faktúry.',
		'Potrebujem výpis faktúry.',
		'Prosim o zaslanie vypisu',
		'Zašlite mi výpis faktúry.',
	],
	VYPIS_FAKTURY__FAKTURY_CISLO_ADRESATA: [
		'chcem faktúru na číslo adresáta',
		'faktúra na číslo adresáta',
		'faktúru na číslo adresáta',
		'potreboval by som faktúru na číslo adresáta',
		'potrebujem faktúru na číslo adresáta',
		'treba mi faktúru na číslo adresáta',
	],
	VYPIS_FAKTURY__PLAIN: [
		'chcel by som sa dostať do podrobných výpisov',
		'chcem podrobný výpis',
		'chcem sa dostať do podrobných výpisov',
		'chcem výpis',
		'chcem výpisy',
		'Potrebujem jednorázovy výpis.',
		'Potrebujem výpis.',
		'treba mi výpis',
	],
	VYPIS_FAKTURY__VOLANIA: [
		'dajte mi výpis hovorov',
		'dajte mi výpis volaní',
		'pošlite mi výpis hovorov za rok',
		'pošlite mi výpis hovorov za tento mesiac',
		'pošlite mi výpis volaní',
		'potrebovala by som zistiť jeden hovor ktorý som nevolala',
		'potrebujem vedieť či mi bude chodiť výpis volaní',
		'potrebujem výpis volaní na moju linku',
		'potrebujem zistiť jeden hovor ktorý som nevolala že čo to je',
		'výpis hovorov',
		'výpis volaní',
	],
	NESPAROVANA_PLATBA__NESPRAVNY_SYMBOL: [
		'Dal som zlý variabilný symbol',
		'nedal som správny variabilný symbol',
		'Nesprávne som zadal variabilný symbol',
		'nezadal som správny variabilný symbol.',
		'nezadal som variabilný symbol.',
		'odoslal som platbu s nesprávným variabilným symbolom',
		'odoslal som platbu so zlým varibilným symbolom',
		'Pomýlil som sa pri variabilnom symbole.',
		'Potrebujem nahlásiť zlý variabilný symbol',
		'Potrebujem opraviť variabilný symbol',
		'uhradil som na zlý symbol',
		'zabudla som pri platbe dať variabilný symbol',
		'zabudla som zadať variabilný symbol pri platbe',
		'zadala som zlý variabilný symbol',
		'zadal som nesprávny variabilný symbol',
		'Zadal som zlý variabilný symbol.',
		'zle som zadal variabilný symbol',
		'Zle zadaný variabilný symbol',
		'zlý variabilný symbol',
	],
	SPLATKOVY_KALENDAR__LATKY: ['chcela by som sa informovať o látke', 'látka', 'látku', 'látky'],
	SPLATKOVY_KALENDAR__SPLATKY: [
		'by som sa chcela informovať o splátke',
		'by som sa chcela spýtať na splátku',
		'Ide tu o to ze dlhujem za faktury a chcem sa dohodnut na splatkovom kalendari',
		'Je možné nastaviť si platobný kalendár?',
		'Môžem si spraviť splátkový kalendár?',
		'Umožňujete nastavenie platobného kalendára?',
		'Viem zaplatiť faktúru na splátky?',
	],
	PIN_PUK__KOD_PLAIN: [
		'Aký je kód na odblokovanie mobilu?',
		'Aký mám kód do mobilu?',
		'kód na odblokovanie',
		'Pošlite mi kód',
		'potrebujem kód na odblokovanie',
		'zašlite mi kód na odblokovanie',
	],
	PIN_PUK__ODBLOKOVAT_KARTU: [
		'Chcem si odblokovať kartu',
		'chcem si zrušiť blokáciu karty',
		'odblokovať kartu',
		'potrebujem odblokovať kartu',
		'treba mi odblokovať kartu',
		'zrušiť blokáciu karty',
	],
	PIN_PUK__PIN: [
		'ako zistiť svoj pin kód',
		'Kde nájdem môj PIN?',
		'kde nájdem PIN kód',
		'nemám pin',
		'Neviem môj PIN kód',
		'neviem svoj pin',
		'pin',
		'už neviem svoje pin a stratil som aj puk a teraz mám zablokovaný mobil',
		'zabudol som aký je môj pin kód',
		'zabudol som svoje pinko',
	],
	PIN_PUK__PUK: [
		'ako zistiť kód PUK?',
		'Dal som zlý kód do telefónu a potrebujem puk kód',
		'Kde nájdem môj PUK?',
		'Nemám puk',
		'Neviem môj PUK kód',
		'Poprosim puk kod',
		'puk',
		'vedeli by ste mi poslať môj puk?',
		'Viete mi poslať PUK',
		'Zabudla som svoj PUK',
		'zabudol som svoj puk',
	],
	PIN_PUK__ZABLOKOVANA_SIM_KARTA: [
		'mám bloknutú kartu v mobile',
		'mám zablokovanú kartu v telefone',
		'Mám zablokovanú SIM kartu',
		'pomoc lebo sa mi zablokovala druhá sim karta',
		'sim karta zablokovaná',
		'sim kartu mám zablokovanú',
		'Zablokovala sa mi karta',
		'Zablokovalo mi sim kartu',
		'zablokovaná sim karta',
	],
	PIN_PUK__ZABLOKOVANY_MOBIL: [
		'odblokovať mobil',
		'odblokovať telefón',
		'zablokoval som si mobil',
		'Zablokoval som si telefon',
		'zablokovaný mobil',
		'zablokovaný telefón',
	],
	PIN_PUK__ZADAL_ZLY_KOD: [
		'dal som zlý kód',
		'nesprávny kód som naťukal',
		'pomýlil som si kód k telefónu a teraz je zablokovaný',
		'Zadala som zlý kód a zablokovala zariadenie',
		'zadal som nesprávny kód',
		'Zadal som zlý kód a zablokoval som si mobil',
		'zlý kód som dal',
	],
	ID_KOD__GENERAL: [
		'ako vyzerá môj id kód?',
		'aký je môj identifikačný kód?',
		'Aký je môj ID kód?',
		'chcem vedieť aký mám id kód',
		'dáte mi prosím môj id kód?',
		'kde je môj id kód?',
		'kde nájdem svoj id kód?',
		'kde sa nachádza môj id kód?',
		'nepamätám si svoj id kód',
		'nepoznám svoj id kód',
		'Neviem svoj id kód',
		'pošlete mi môj id kód?',
		'zabudol som svoj identifikačný kód',
	],
	DOBITIE_KREDITU_EASY__CHARGE_CREDIT: [
		'Ako dobijem easy kredit z paušálu ?',
		'Ako dobijem kredit na číslo?',
		'Ako dobijem kredit svojej dcére?',
		'Ako dobijem kredit svojmu synovi?',
		'Ako dobijem synovi kredit?',
		'Ako môžem dobiť kredit niekomu inému ?',
		'Ako si dobijem kredit ?',
		'chcela by som dať dobiť kredit',
		'chcel by som si dobiť kredit',
		'Chcem dobiť easy kredit',
		'chcem dobiť kredit',
		'chcem si dobiť kredit na tomto čísle',
		'Chcem si navýšiť kredit na easy',
		'Chcem synovi dobit 10 eur',
		'Chcem synovi dobiť kredit',
		'dobitie kreditu',
		'dobiť kredit',
		'Nemám kredit ale chcem dobit',
		'potreboval by som dobiť kredit',
		'potrebujem si cez môj paušál dobiť kredit',
		'potrebujem si dobiť kredit',
	],
	DOBITIE_KREDITU_EASY__DOBI_CISLO: [
		'Ako dobijem číslo?',
		'Ako sa dobíja číslo?',
		'chcem dobiť číslo?',
		'chcem si dobiť toto číslo?',
		'potrebujem dobiť kredit na čísle',
	],
	DOBITIE_KREDITU_EASY__DOBI_KARTU: [
		'Ako si dobijem EASY?',
		'Ako si môžem dobiť easy kartu?',
		'Chcem dobiť easy kartu z paušálky.',
		'Chcem pravidelne dobíjanie kreditu na easy kartu',
		'Chcem si jednorázovo dobiť kredit cez easy kartu',
		'dobiť easy kartu',
		'dobitie easy karty',
		'Neviem synovi dobiť kredit na easy karte',
		'Potrebujem kredit na easy karte',
		'Viem dobit kredit na easy z mojho pausalu?',
		'Viem si tu dobiť easy kartu?',
	],
	DOBITIE_KREDITU_EASY__DOBI_MOBIL: [
		'Ako si dobiť telefón?',
		'chcem jednorázovo dobiť telefón?',
		'dobite mi teraz telefón',
		'dobitie telefóna',
		'dobiť telefón',
	],
	DOBITIE_KREDITU_EASY__INFOLINKA: [
		'Aká je infolinka na dobíjanie EASY kartu?',
		'Aká je infolinka pre dobitie EASY kartu?',
		'Aká je linka na dobitie EASY karty?',
		'Aké je číslo na dobitie pre EASY kartu?',
		'kam zavolať aby ste mi dobili EASY kartu?',
	],
	STRATA_KRADEZ__LOSS: [
		'Ako mám postupovať pri strate?',
		'Chcem ohlásiť stratu',
		'Čo mám robiť, keď som stratila mobil?',
		'jedná sa o stratu mobilu',
		'na festival som stratil svoj mobil',
		'Neviem kde mám mobil',
		'niekde mi asi vypadol mobil',
		'niekde vo vlaku som stratil mobil',
		'o stratu telefónu sa jedná',
		'Prosím vás, neviem nájsť svoj mobil',
		'Stratila som mobil',
		'stratila som svoju simku',
		'Stratil',
		'Zabudol niekde svoj telefón a nedá sa mi naň dovolať',
		'Zmizol mi mobil',
	],
	STRATA_KRADEZ__THEFT: [
		'Ako mám postupovať pri krádeži ?',
		'Aký je postup pri strate, krádeži?',
		'Bol som na polícii, lebo mi ukradli telefón',
		'Chcem nahlásiť krádež',
		'čo mám robiť ak ma ukradli a nemám mobil ani simku.',
		'jedná sa o krádež mobilu',
		'jedná sa o krádež telefónu',
		'krádež',
		'Ma okradli o telefon',
		'Mi ukradli Samsung',
		'Okradli ma',
		'Potrebujem nahlasit ukradnutie mobilu',
		'Som v zahraničí a mi niekto ukradol iphone',
		'Ukradli mi huawei',
		'Ukradli mi iphone',
		'Ukradli mi mobil',
		'Ukradli mi samsung',
		'Ukradli mi SIM',
		'ukradli mi telefón',
	],
	STRATA_KRADEZ__ZABLOKUJ: [
		'chcela by som zablokovať telefónne číslo',
		'Chcem si zablokovať kartu SIM',
		'Chcem si zablokovať SIM',
		'chcem zablokovať telefónne číslo',
		'chcem zablokovať telefón',
		'treba mi chytro zablokovať kartu sim',
		'zablokovanie karty',
		'zablokovanie telefónneho čísla',
		'zablokovať kartu',
		'zablokovať telefónne číslo',
		'zablokujete mi mobil?',
		'zablokuj mi mobil',
	],
	HLASOVA_BIOMETRIA__ACTIVATE: [
		'Ako si mám aktivovať službu kde ma spoznáte po hlase?',
		'Ako si nastavím hlasovú biometriu?',
		'Ako si zapnem identifikáciu hlasovou biometriou?',
		'aktivácia nejakej hlasovej služby',
		'aktivácia voice identifikacie hlasom',
		'aktivovanie hlasovej služby',
		'aktivovať hlasovú službu',
		'aktivovať identifikaciu po hlase',
		'aktivovať nejakú hlasovú službu',
		'aktivujte mi takú tú hlasovú službu, čo ma poznáte po hlase',
		'Chcel by som si aktivovať novú voiceovu biometriu',
		'Chcem aktiváciu hlasovej biometrie',
		'Chcem aktivovať biometriu',
		'chcem aktivovať nejakú hlasovú službu na spoznávanie hlasu',
		'Chcem rozoznávanie môjho hlasu',
		'Chcem si aktivovať vašu hlasovú biometriu',
		'Chcem si nastaviť hlasovú biometriu',
		'Ja by som si chcela aktivovať tú vašu biometriu',
		'Kúpila som si hlasovú biometriu a chcem ju aktivovať',
		'Mala by som záujem o zapnutie hlasovej biometrie',
		'Môžem si nastaviť biometriu?',
		'No by som si chcela aktivovať tú biometriu',
		'Viete mi zapnúť hlasovú biometrickú identifikáciu?',
		'Volám kvôli aktivácii hlasovej identifikacie',
	],
	HLASOVA_BIOMETRIA__GENERAL: [
		'biometria',
		'Čo je to biometria?',
		'Čo je to hlasová biometria',
		'hlasová biometria, máte niečo?',
		'je hlasová biometria bezpečná?',
		'Mám u vás hlasovú biometriu a nechce ma overiť.',
		'Máte identifikáciu hovoriaceho po hlase?',
		'Máte nejaké info o vašej hlasovej biometrii?',
		'Máte vy vôbec niečo ako hlasovú biometriu',
		'Môžete ma overiť cez hlasovú biometriu?',
		'Rozpoznáte môj hlas správne?',
		'Som počula o hlasovej biometrii, čo to je?',
		'Viete ma identifikovať po hlase?',
	],
	HLASOVA_BIOMETRIA__HLASOVY_ODTLACOK: [
		'hlasový odtlačok',
		'mám tu od vás správu že si mám nahrať hlasový odtlačok',
		'nahranie hlasového odtlačku',
		'nahrǎt hlasový odtlačok',
		'tak nahrajte mi môj hlasový odtlačok',
	],
	PREBIEHAJUCA_PORTACIA__GENERAL_QUESTIONS: [
		'Ako dlho potrvá prenos čísla?',
		'Ako si môžem k Vám preniesť číslo',
		'chcem prenos čísla',
		'chcem prenos',
		'Čo je to dočasné číslo?',
		'Dostanem zľavu za prenos čísla?',
		'Kedy budem mať číslo u iného operátora?',
		'Kedy si môžem od vás preniesť číslo?',
		'Koľko budem musieť čakať na prenesenie čísla?',
		'môžem sa spýtať o prenose čísla',
		'Musí byť číslo u konkurencie vedené na moje meno?',
		'potrebujem informáciu o prenos čísla',
		'prenos teda',
		'Si mením operátora, ako dlho to bude trvať?',
		'Treba vám ohlásiť prenos čísla?',
		'potreboval by som prechodu orindžu na paušáli',
	],
	PREBIEHAJUCA_PORTACIA__IN_PROGRESS: [
		'Dal som si k vám preniesť číslo, viete mi povedať v akom je to stave.',
		'Dáte mi vedieť, keď budem mať číslo prenesené?',
		'Kedy mi bude fungovať prenesené číslo?',
		'Kedy sa mi prenesie číslo?',
		'Pred 3 dňami som si dal preniesť číslo a stále mi nefunguje',
		'Prenášate mi to číslo?',
		'Preniesli ste mi už to moje číslo?',
		'prenos čísla už mal byť aktívny do orange',
		'Prešiel som k vám, ale neviem či ste mi už preniesli číslo.',
		'pridlho trvá prenos čísla, kedy bude hotovo?',
		'Robil som ten prenos ale číslo mi nefunguje',
		'Viete vy vôbec, že mi máte prenášať číslo?',
		'Volal som vám minule, a dal som si k vám preniesť číslo, už to je prenesené?',
		'Za aký čas mi to číslo prenesiete?',
	],
	PREBIEHAJUCA_PORTACIA__PRENES_CISLO: [
		'Chcem prejsť od iného operátora k vám ku vám číslo',
		'Chcem preniesť ku vám číslo',
		'Chcem prestúpiť z orange do telekomu',
		'Chcem si ku vám preniesť číslo',
		'Chcem si k vám portovať číslo',
		'Chcem si k vám presunúť číslo',
		'Chcem si k vám previesť číslo',
		'Môžem si ponechať číslo ak prejdem k vám ?',
		'Poprosil by som si preniesť číslo do Tmobilu',
		'Potrebujem presunúť číslo',
		'Prenesiete mi číslo, keď som k vám prešiel?',
	],
	PREBIEHAJUCA_PORTACIA__ZAMIETNUTIE: [
		'aký je dôvod, že mi bol zamietnutý prenos čísla',
		'potrebujem vedieť prečo mi bol zamietnutý prechod čísla',
		'prechod čísla mi bol zamietnutý, prečo?',
		'rád by som vedel, prečo mi bol zamietnutý prechod čísla?',
		'z akého dôvodu mi bol zamietnutý prenos čísla',
		'zamietnutý prechod čísla',
		'zamietnutý prenos čísla',
		'zrušený prenos čísla',
		'prosím o o zrušenie prenosu čísla',
		'zrušiť prenos čísla',
	],
	TELEKOM_CENTRUM__CONTACT: [
		'ako sa dovolám na pobočku telekom centra?',
		'chcem zavolat na predajnu telekom centra',
		'kde najdem kontakty na telekom centrá',
		'potrebujem sa dovolať na telekom centrum',
		'potrebujem sa spojiť s pobočkou v',
		'potrebujem sa spojiť s predajňou v',
		'Potrebujem telefonne cislo na predajnu do noveho mesta nad vahom',
		'potrebujem telefonné číslo na telekom centrum',
		'potrebujem zavolat do telekom centra v Avionu v Bratislave',
	],
	TELEKOM_CENTRUM__INFO: [
		'ako ďaleko je najbližšie telekom centrum',
		'ako sa dostanem do najbližšieho telekom centra',
		'je ďaleko najbližšie telekom centrum?',
		'Kde je najbližšie Telekom centrum?',
		'kde nájdem najbližšie telekomácke centrum',
		'kde nájdem najbližšiu pobočku?',
		'kde sa nachádza najbližšie t centrum',
	],
	TELEKOM_CENTRUM__OPENING_HOURS: [
		'aké sú otváracie hodiny na pobočke v Bory Mall?',
		'Chcem vedieť či je otvorená pobočka v auparku',
		'informácie o otváracích hodinách prevádzky',
		'informácie o otvorení prevádzky',
		'je otvorená vaša pobočka na',
		'je otvorená vaša pobočka v',
		'kedy máte otvorené v telekom centre?',
		'má telekon centrum otvoreno?',
		'odkedy máte otvorené na pobočke?',
	],
	WIFI_HESLO__WHERE: [
		'Ake heslo ma moj router',
		'Ako nájdem heslo na wifinu',
		'ako zistím heslo od routera',
		'kde mám nájsť heslo od wifiny',
		'Kde najdem heslo od internetu',
		'Kde zistim heslo na Wifinu',
		'netuším jaké mám heslo na wifi',
		'Neviem moje heslo od WiFi',
		'Neviem zistit heslo od routera',
		'Zistiť heslo na wifi',
	],
	OSOBNE_UDAJE__GENERAL: [
		'Aké informácie si o mne ukladáte',
		'aké údaje máte o mňa uložené?',
		'aké všetky info si o mne ukladáte',
		'Ako dlho sa ukladajú údaje',
		'Ako pracujete s osobnými údajmi',
		'Ako spracovávate osobné údaje',
		'Chcem informácie o osobných údajoch',
		'čo všetko máte o mne uložené',
		'na ako dlho máte uložené osobné údaje',
		'načo sú vám všetky osobné údaje',
		'zaujíma ma ochrana mojich osobných údajov',
	],
	KONTAKT__GENERAL: [
		'došla mi smska',
		'Dostal som od vás e-mail',
		'dostávam od vás sms',
		'dostávam smsky ohľadom nejakých nadupaných kreditov o ktoré nemám záujem',
		'Kontaktovali ste ma',
		'mala som od vás neprijatý hovor',
		'mala som pred chvíľou telefonát na pevnú linku',
		'mal som pred chvíľou telefonát',
		'Mal som sa ozvať',
		'mám neprijatý hovor z telekomu',
		'Mám od vás neprijatý hovor',
		'Mám od vás SMS',
		'Mám sa ohlásiť',
		'Našiel som si od vás zmeškaný hovor',
		'Poslali ste mi nejakú esemesku',
		'pred chvíľou som mal telefonát',
		'prišla mi smska',
		'prišla mi sms',
		'Vraj vás mám kontaktovať',
		'zaslali ste mi sms',
		'zaslali ste mi smsku',
		'chcel by som riešiť prišla mi správa od vás že by som vám zavolal',
	],
	TELEFONNE_CISLO__ASK: [
		'Chcem zistiť telefónne číslo na doktorku',
		'Chcem zistiť telefónne číslo na lekáreň',
		'potreboval by som zistiť číslo',
		'potrebujem zistiť telefónne číslo',
		'zistenie čísla na',
		'zistenie čísla',
		'zistiť číslo',
	],
	VIAC_POZIADAVIEK__GENERAL: [
		'ja sa s vami nedohovorím lebo ja potrebujem viac',
		'lebo ja potrebujem viac no je jedno',
		'chcem toho riešiť viac',
		'mám viacero vecí čo chcem riešiť',
		'mám viac požiadaviek',
		'mám viac tém',
		'potrebujem riešiť viac požiadaviek',
		'viacero vecí potrebujem',
	],
	PODOZRIVE_HOVORY__BLOK: [
		'chcem zablokovať číslo ktoré ma otravuje celý deň nevyvoláva číslo',
		'ako blokovať prichádzajúce hovory zo zahraničia',
		'ako mám zablokovať hovory z divného čísla',
		'ako mám zablokovať hovory z neznámych čísel',
		'ako zabrániť tomu že mi niekto vyvoláva',
		'chcela by som zrušiť všetky volania zo zahraničia',
		'chcem si blokovať zahraničných volajúcich',
		'ja by som poprosila že keď mi volajú zo zahraničia či by sa nedalo zrušiť to',
		'nedalo by sa zrušiť keď mi volajú zo zahraničia',
		'nedá sa zrušiť keď mi volajú zo zahraničia?',
		'vyvoláva mi tu niekto z talianska a chcem si to bloknúť',
		'zablokovať keď mi volajú zo zahraničia',
		'zabrániť tomu že mi niekto vyvoláva a hneď zloží',
		'chcela by som sa informovať či sa dá na sim karte na paušálové zablokovať hovory od zahraničných telefónnych čísiel',
	],
	PODOZRIVE_HOVORY__GENERAL: [
		'divné číslo mi volá',
		'nejaké divné číslo mi volá',
		'neznáme číslo mi volá',
		'niekto mi vyvoláva a zloží',
		'volajú mi cudzie čísla',
		'volajú mi cudzí ľudia',
		'volajú mi dlhé čísla a neviem komu môžu patriť',
		'volajú mi neznáme čísla',
		'volajú mi niekoľko krát za deň cudzie čísla',
		'volajú mi podivné čísla',
		'volá mi nejaké divné číslo',
		'volá nám neznáme číslo',
		'volania od neznámych čísel',
		'vyvolávajú mi zvláštne čísla',
		'vyvoláva mi niekto a potom zloží',
		'problém nežiaducimi volaniami',
		'mám nežiaduce volania',
	],
	PODOZRIVE_HOVORY__MICROSOFT: [
		'ponúkajú mi niečo z microsoftu',
		'volali mi z microsoftu',
		'volalo mi číslo a predstavili sa ako microsoft',
	],
	PODOZRIVE_HOVORY__OBTAZUJU: [
		'kvôli obťažovaniu telefónom',
		'obťazovanie telefonátmi',
		'obťažovanie telefónom',
		'obťažujú ma nejakým cudzím číslom',
		'obťažujú ma neustálymi telefonátmi',
		'obťažujú ma',
		'obťažujú ma som obťažovaný cudzím číslom',
		'som obťažovaný cudzím číslom',
	],
	PODOZRIVE_HOVORY__PODOZRIVE_CISLA: [
		'chcela by som nahlásiť nejaký podozrivý telefonát na našu pevnú linku',
		'chcela by som nahlásiť podvodné telefónne čísla',
		'nahlásiť podozrivý telefonát',
		'podozrivé čísla mi volajú',
		'podozrivé čísla',
		'podozrivé číslo',
		'podozrivý telefonát na našu pevnú linku',
		'podozrivý telefonát',
		'volajú mi podozrivé čísla',
	],
	PODOZRIVE_HOVORY__ZAHRANICNE: [
		'mám neprijaté hovory z medzinárodného čísla',
		'mám neprijaté hovory zo zahraničných čísel',
		'mám neprijatý hovor z číny',
		'mám neprijatý hovor z fínska',
		'medzinárodné číslo mi volá',
		'nejaké medzinárodné čísla mi volajú',
		'neustále nám niekto volá po anglicky na našu pevnú linku',
		'neustále volá niekto anglickej reči aj dvakrát či trikrát denne na našu pevnú linku',
		'neustále volá niekto anglickej reči aj trikrát denne na našu pevnú linku',
		'neustále volá niekto anglickej reči na na našu pevnú linku',
		'stále máme telefonáty od niekoho s cudzou národnosťou',
		'stále nám vyzváňajú telefóny ale nevieme ich národnosť',
		'stále nám vyzváňa telefón, ale nevieme národnosť volajúceho',
		'stále nám vyzváňa telefón, volá nám cudzia národnosť',
		'volajú mi anglicky hovoriace čísla',
		'volajú mi a otravujú ma čísla zo zahraničia',
		'volalo mi na pevnú linku nejaké číslo medzinárodné a teda potrebujem zistiť či sa mi niekto nenabúral na linku',
		'volá mi na pevnú linku nejaké medzinárodné číslo',
	],
	NEVIE_STRUCNE__AKO_STRUCNEJSIE: [
		'Ako stručnejšie',
		'ako už stručnejšie inak sa to nedá',
		'jako vám to vysvetliť stručnejšie',
		'ja vám neviem inak stručnejšie vysvetliť no',
		'len niečo potrebujem a neviem to stručnejšie',
		'neviem stručnejšie',
		'stručnejšie sa nedá',
		'to už je stručne',
	],
	ZRUSENIE_E_MAILU__GENERAL: [
		'chcem sa informovať o zrušení emailovej pošty',
		'chcem vedieť prečo sa mi zrušil email',
		'chcem zistiť že či nebudú chodiť už ani faktúry do mailu',
		'dostal som mail o zrušení elektronickej pošty',
		'e-mailovú adresu nebudem môcť používať',
		'ja by som potreboval bližšie informácie k emailu, že bude zrušená elektronická pošta',
		'potrebujem viac info k emailu o zrušení elektronickej pošty',
		'Prišla mi SMS, že mi rušíte email',
		'rušenie mailovej služby',
		'ukončenie poskytovania sluźieb elektronickej pošty',
		'volám ohľadom zrušenia elektronickej pošty',
		'vraj bude zrušená elektronická pošta',
		'že bude zrušená tá poštová schránka',
		'zrušenie imailovej adresy',
		'zrušili ste mi mailovú adresu a chcem si nahlásiť novú',
	],
	RODICOVSKY_ZAMOK__GENERAL: [
		'Aké mam heslo na programy pre dospelých?',
		'aké mám heslo na rodičovský zámok',
		'ako sa nastavuje rodičovský zámok',
		'ako si nastavím rodičovský zámok',
		'Ako si zablokujem erotické programy ?',
		'Aký je kód keď si chcem požičať film',
		'aký mam kód na rodičovský zámok',
		'chcel by som nastaviť rodičovský zámok',
		'chcem si zablokovať nevhodné programy',
		'chcem si zablokovať programy pre dospelých',
		'Chcem zablokovať niektoré programy',
		'Čo je to rodičovský zámok',
		'deti keď nie sme doma pozerajú nevhodné programy chceme to zablokovať',
		'deti keď nie sme doma pozerajú nevhodné programy chceme to zrušiť',
		'mágio odo mňa žiada heslo. Ako to môžem zrušiť?',
		'načo je rodičovský zámok',
		'Nemáme deti a stále musíme zadávať kod na odblokovanie programov',
		'TV žiada odo mňa PIN kód. Ako to môžem zrušiť?',
		'Zablokoval som si mágio',
		'po osmej sa mi zatvoria jednotlivé kanály a asi zrejme kvôli detskému programu a potrebujem to odblokovať',
	],
	RODICOVSKY_ZAMOK__POZICOVNA_FILMOV: [
		'Aké mám heslo pre mágio požičovňu',
		'Aký mám kód pre videopožičovňu',
		'Chcem zablokovať požičiavanie filmov v mágiu, aký je tam kód?',
		'Videopožičovňa mi píše že zadávam nesprávny kód, ale ja som ho nemenil',
	],
	PORADIE_KANALOV__GENERAL: [
		'Ako si môžem cez mágio tv zmenit poradie kanálu',
		'Ako si nastavím iné poradie kanálov na mágio tv?',
		'Ako si zmenim program na inú pozíciu',
		'Chcel by som si dat markízu ako jednotku',
		'Chcem si nastaviť iné poradie kanálov na svojej televízií',
		'Chcem si zmenit poradie tv programov',
		'Je možné cez mágio go zmenit pozíciu tv kanálu',
		'Je možné si zmeniť to, ako idú po sebe televízne kanály',
		'ohľadom televízie by som potrebovala zmeniť jeden kanál či sa to dá',
	],
	DIALKOVY_OVLADAC__GENERAL: [
		'dali nám nový diaľkový ovládač a neviem ako funguje',
		'dostali sme nové diaľkové ovládanie, ale nevieme ako sa používa',
		'Je možné vymeniť starý ovládač za nový?',
		'Koľko stojí výmena dialkového ovládača ?',
		'máme diaľkový ovládač ale neviem ho sfunkčniť',
		'Nefunguje mi „prepinačka“ „ovladačka“.',
		'Nejde mi diaľkový ovládač',
		'Nejde mi diaľkový ovládač k telke',
		'Nejde mi ovladánie na Mágiu, asi mám pokazený ovládač.',
		'nevieme si spojazdniť diaľkové ovládanie k telke',
		'neviem používať môj nový ďiaľkový ovládač',
		'Pokazil sa mi dialkový ovládač na Mágio',
		'Pokazil sa mi dilakový ovládač na set top boxe.',
		'Potrebujem vymeniť dialkový ovládač na Mágio TV',
		'Potrebujem vymeniť ovládač',
		'Teraz som si dával od vás mágio, ale nefunguje mi ovládač',
	],
	DIALKOVY_OVLADAC__SPAROVANIE: [
		'Ako si spárujem ovládač pre Mágio TV ?',
		'Na predajni mi povedali , že si mám sparovať novy ovládač, ale neviem ako.',
		'Potrebujem návod pre spárovanie ovládača s Mágioboxom.',
		'Rád by som si spároval univerzálny ovladač PHILIPS, ktorý mám od vás.',
		'Teraz mám 2 ovládače, ale viem si spárovať Váš ovládač, aby som používal iba jeden?',
		'Viete mi poradit ako si spárovaˇť dialkový ovládač k mágio TV?',
	],
	I_CIR_00_01_F_OPERATOR__CHCE_CISLO_OPERATORA: [
		'chcel by som číslo na operátora',
		'chcem číslo na operátora',
		'dajte mi číslo na operátora',
		'na operátora mi dajte číslo',
		'potreboval by som číslo na operátora',
		'potrebujem číslo na operátora',
		'treba mi číslo na  operátora',
		'žiadam o číslo na operátora',
	],
	I_CIR_00_01_F_OPERATOR__CHCE_KOLEGU: [
		'daj mi tvojho kolegu',
		'kolegu',
		'prepojte ma na kolegu',
		'prosím si vášho kolegu',
		'vášho kolegu',
	],
	I_CIR_00_01_F_OPERATOR__NEPOMOZES_MI: [
		'asi mi nepomôžete, poprosím o prepojenie',
		'nechcem tvoju pomoc',
		'nepomôžete mi',
		'Nepomôžete mi',
		'Nepomôžete mi prepojte ma hneď',
		'Nepomôžete',
		'od teba pomoc nechcem',
		's vami sa nedohodnem',
		's vami sa nedohovorím',
		'ty mi nepomáhaj',
		'ty mi nepomôžes',
		'ty mi nepomôžes, prepoj ma',
		'ty mi nijak nepomôžeš',
		'Ty mi vobec nepomahaj moja, ty ma prepni',
	],
	I_CIR_00_01_F_OPERATOR__NOT_YOU: [
		'automatickeho asistenta nechcem',
		'nebudem sa baviť s počítačom',
		'nebudem sa baviť s virtuálnou',
		'nebudem se baviť s robotom',
		'nechcem počítač',
		'nechcem sa vykecávat s počítačom',
		'nechcem sa vykecávať s robotom',
		'nechcem žádnú virtuálnu',
		'nechcem žádnú virtual',
		'nemám čas rozprávat s počítačom',
		'nemám čas rozprávat s robotom',
		'nepotrebujem volať s tebou',
		'nepotrebujem volať s vami',
		'netreba mi volať s tebou',
		'netreba mi volať s vami',
		'nie nepotrebujem volať s vami',
		'od teba pomoct nechcem',
		'robota nechcem',
		's tebou sa baviť nechcem',
		's tebou sa rozprávať nebudem',
		's tebou som to už skúšal',
		's tebou to nechcem skúsiť',
		's tebou to riešiť nechcem',
		's vami som to už skúšal',
		'Ty nič, teba nechcem',
	],
	I_CIR_00_01_F_OPERATOR__PRACOVNIK_ASISTENT: [
		'asistenta',
		'chcela by som prepnúť na nejakého asistenta',
		'chcem pracovníka',
		'nič potrebujem asistenta',
		'no nič potrebujem asistenta',
		'potrebujem asistenta',
		'potrebujem prepnúť na nejakého pracovníka',
		'pracovníka',
		'prepnúť na nejakého pracovníka',
		'prepojiť na nejakého asistenta',
		'prepojiť na pracovníka',
		'prepojte ma na nejakého asistenta',
	],
	I_CIR_00_01_F_OPERATOR__PREPOJ_MAGENTA: ['prepojiť magenta', 'prepoj na magenta'],
	I_CIR_00_01_F_OPERATOR__REDIRECT: [
		'chcem byť prepojená na operátora',
		'nevyriešite tak to prepojte na operátora',
		'trvá to prepojené operátora',
		'Dobrý deň žiadam vás o spojenie s operátorom',
		'Ja by som sa chcela spojiť s operátorom',
		'mohli by ste ma prepojiť na operátora prosím vás',
		'Mozes ma prosim prepojit na operátora?',
		'no chcem aby ste ma už prepojili konečne na operátora',
		'Potreboval by som sa spojiť s operátorom',
		'Potrebujem sa spojiť s niekým od Telekomu',
		'potrebujem sa spojiť s operátorom nejakým',
		'Potrebujem spojiť s operátorom',
		'prepnite ma na operátora',
		'prepnite na operátora lebo zbytočne',
		'prepnite na operátora',
		'prepojenie na operátora',
		'prepojte ma na operátora',
		'Prepojte ma na operátora',
		'prepojte ma okamžite na niekoho normálneho',
		'pripojiť k operátorovi',
		'pripojiť na operátora',
		'pripojiť priamo na operátora',
		'spojenie priamo s operátorom',
		'spojenie s operátorom ešte raz',
		'spojíš ma s operátorom?',
		'Spojiť sa s operátorom',
		'spojiť s operátorom a niečo si ujasniť',
		'Spojiť s operátorom',
		'spoj ma s operátorom',
		'spojme sa s operátorom?',
		'spojte ma so servisom',
	],
	I_CIR_00_01_F_OPERATOR__REDIRECT_PLAIN: [
		'môžem vás poprosiť ma prepojiť?',
		'môžete ma prepojiť?',
		'potrebujem sa spojiť',
		'prepojiť?',
		'Prepojte ma',
		'prosím o prepojenie',
		'prosím prepojiť',
		'spojte ma',
		'spojte',
		'tak ma spojte',
		'Volal som pred chvíľou a prepojili ste ma, tak ma prepojte',
	],
	I_CIR_00_01_F_OPERATOR__WANT_AGENT_OPERATOR: [
		'prepojiť na agenta',
		'spoj mi agenta',
		'Chcem hovoriť len s operátorom',
		'Chcem hovoriť s operátorom',
		'chcem ísť na operátora',
		'chcem operátora',
		'Chcem sa rozprávať priamo s operátorom, nie takto',
		'chcem sa rozprávat s niekym zo servisu',
		'chcem sa rozprávať s operátorom',
		'chcem sa rozprávat so servisom',
		'chcem sa s niekým povyprávať',
		'daj mi operátora',
		'dajte mi na operátora',
		'dajte mi prosím servis',
		'dostaňte ma na operátora',
		'Hovno daj mi operátora',
		'hovoriť s operátorom',
		'iba operátora',
		'ja by som potrebovala rozprávať s vašim operátora',
		'ja by som prosila operátora',
		'ja súrne potrebujem operátora',
		'môžeme to riešiť ale potrebujem operátora',
		'môžem operátora',
		'nemôžete nič, potrebujem hovoriť s operátorom',
		'nič nemôžete ja potrebujem s operátorom hovoriť',
		'nič nemôžete, potrebujem s operátorom hovoriť',
		'operátora',
		'operátor',
		'poprosil by som operátorku',
		'poprosím vás o operátorku',
		'Potrebujem hovoriť s operátorom',
		'potrebujem hovoriť so servisom',
		'Potrebujem hovoriť so skutočným operátorom',
		'potrebujem operátora',
		'Potrebujem operátora',
		'Potrebujem sa dostať na operátora',
		'Potrebujem sa porozprávať s operátorom',
		'Potrebujem sa rozprávať s operátorom',
		'Potrebujem sa rozprávať s vašim operátorom',
		'potrebujem servis',
		'prosil aby som zvoliť operátora',
		'prosil by som operátorku',
		'prosím si operátorku',
		's operátorom',
		'treba niečo poriešiť to musím s operátorom hovoriť priamo',
		'už ma konečne dajte na toho operátora, nemám s vami nervy',
		'vy nič nemôžete, potrebujem hovoriť s operátorom',
	],
	I_CIR_00_01_F_OPERATOR__WANT_HUMAN: [
		'chcem aby ste ma prepojili so živým človekom',
		'chcem dostať človeka',
		'Chcem hovoriť priamo s človekom',
		'chcem hovoriť so živým človekom',
		'chcem ľudský rozhovor',
		'chcem niekoho osobne',
		'chcem sa rozprávať osobne',
		'chcem sa rozprávať osobne s človekom',
		'chcem sa rozprávať s človekom',
		'chcem živého človeka',
		'Dobry den, ja si prosim nejakeho človeka živého',
		'môžete mi prosím vás dať človeka',
		'potreboval by som niečo ohľadne technickej veci poriešiť s nejakým človekom',
		'potreboval by som technické veci riešiť s nejakým človekom',
		'potrebujem nejaké technické veci riešiť s človekom',
		'Potrebujem sa dostať k živému poradcovi',
		'prepojiť má so živým človekom',
		'prepojte ma na človeka',
		's človekom',
		'so živým človekom',
		'technické veci potrebujem riešiť s človekom',
		'živý operátor',
		'živý',
	],
	I_CIR_00_01_F_OPERATOR__WANT_SOMEONE_HELPFUL: [
		'by som potrebovala hovorit s niekým, kto mi poradí',
		'dajte mi niekoho kto mi pomôže, s kým sa dá dohodnúť',
		'dajte mi niekoho kto mi poradí',
		'dajte mi niekoho normálneho, s kým sa dá hovoriť',
		'dajte mi niekoho ochotného pomôcť',
		'spojte ma s niekým normálnym s kým sa dohovorím',
		'spojte ma s volakým normálnym či sa dohovorím',
		'spojte ma s volakým normálnym s kým sa dohovorím',
	],
	I_CIR_00_01_F_OPERATOR__ZAKAZNICKA_LINKA: [
		'chcem prepojiť na zákaznícku',
		'chcem zákaznícku linku',
		'prepojte ma na zákaznícku linku',
		'spojte so zákazníckou linkou',
		'zákaznícka linka',
		'zákaznícku linku',
	],
	I_CIR_00_01_G_INTRODUCTION__GREETING: [
		'Dobre som sa dovolal do telekomu',
		'Dobrý deň haló',
		'Dobrý deň',
		'Dobrý deň počujeme sa?',
		'Dobrý deň prajem',
		'Je tam niekto',
		'No môžem rozprávať, dobrý deň haló',
		'Pri telefóne',
		'Zdravím',
	],
	I_CIR_00_01_G_INTRODUCTION__POCUJETE: [
		'ale počujete ma?',
		'Halo halo',
		'haló haló',
		'Haló',
		'Haló počujeme sa',
		'Haló počujete ma?',
		'Halo počúvam',
		'no haló',
		'no počúvam, halo?',
		'Počujeme sa?',
	],
	I_CIR_00_01_H_INTERNET__PLAIN: [
		'chcem info o internete',
		'chcem sa spýtať na internet',
		'informácie ohľadne internetu',
		'Internet cez kábel',
		'Internet',
		'Internetové pripojenie',
		'Internetové pripojenie mi viete povedať?',
		'káblový Internet',
		'o internete',
		'pevný Internet',
		'potrebujem riešiť internet',
		'potrebujem sa opýtať na veci okolo internetu',
		'potrebujem s niekým hovoriť ohľadne internetu',
	],
	I_CIR_00_01_H_INTERNET__WANTS_TO_ASK: [
		'chcel by som sa informovať čo sa týka internetu',
		'chcel by som sa opýtať ohľadom internetu',
		'opýtať ohľadom internetu',
		'potrebujem pomoc ohľadom internetu',
		'potrebujem pomoc s internetom',
		'potrebujem pomôcť s internetom',
		'volám ohľadom internetu',
	],
	I_CIR_00_01_I_PODOBNE__GENERAL: ['a podobne'],
	I_CIR_00_01_J_KOD_ADRESATA__WHAT_IS_IT: [
		'číslo adresáta',
		'čo je kód adresáta',
		'čo je to kód adresáta',
		'kde nájdem kód adresáta?',
		'nechápem na aký kód sa ma pýtate',
		'nerozumiem aký kód po mne chcete',
		'netuším čo za kód chcete',
		'neviem kde nájdem kód adresáta',
	],
	I_CIR_00_01_K_TELEVIZIA__PLAIN: [
		'potrebujem riešiť balíček služieb na mágiou televíziu',
		'chcem riešiť televíziu',
		'chcem sa opýtať k televízii',
		'magio',
		'mágio',
		'mágio televízia',
		'mágio TV',
		'mám otázky ohľadom televízie',
		'televízia',
		'televízor',
		'telka',
		'tv',
		'viete potrebujem o televízii',
		'viete potrebujem s televíziou',
	],
	I_CIR_00_01_L_NIC__GENERAL: ['nič'],
	I_CIR_00_01_M_PEVNA_LINKA__GENERAL: [
		'chcel by som sa opýtať niečo o pevnej linke',
		'chcem sa spýtať niečo k pevnej linke',
		'mám otázky ohľadom pevnej linky',
		'mám pár otázok týkajúcich sa pevnej linke',
		'niečo nám treba zistiť ohľadne pevnej linky',
		'ohľadne pevnej linky',
		'o pevnej linke',
		'pevná linka',
		'potrebujem riešiť pevnú linku',
		'treba mi riešiť pevnú linka',
	],
	I_CIR_00_01_N_EASY__AKE_MAM_SLUZBY: [
		'Aké služby mám na easy karte?',
		'čo za služby mám na easy karte',
		'ktoré služby vlastne mám na easy karte',
		'služby na easy karte',
	],
	I_CIR_00_01_N_EASY__AKTIVNA: [
		'aktivovali ste mi easy kartu?',
		'Je aktívna moja easy karta?',
		'je easy karta aktívna',
		'Mám aktivovanú easy kartu?',
		'Mám easy kartu aktívnu?',
	],
	I_CIR_00_01_N_EASY__CO_DOSTANEM: [
		'aké služby dostanem k easy karte?',
		'aké služby mi dávate k easy karte?',
		'Chcel by som si pozrieť čo za služby dostanem k easy karte?',
		'Čo dávate k easy karte?',
		'Čo dostanem k easy karte?',
	],
	I_CIR_00_01_N_EASY__CO_STOJI: [
		'aká je cena easy karty',
		'Aké cenu sú za easy kartu?',
		'Koľko stojí easy karta',
		'Kolko stoji volanie cez easy kredit?',
		'Koľko stojí volanie z EASY karty?',
	],
	I_CIR_00_01_N_EASY__GENERAL: [
		'Ako funguje EASY karta?',
		'Chcem riešíť predplatenku',
		'Chcem sa informovat o easy karte',
		'chcem sa informovať o easykarte',
		'Čo je to easy karta',
		'easy karta',
		'easykarta',
		'info o easy karte',
		'informácie o easykarte',
		'Mam otázky k easy',
		'o easykarte',
		'predplatenku',
		'predplatenú kartu',
		'viete mi dať nejaké info o easy karte?',
	],
	I_CIR_00_01_N_EASY__KDE_KUPIM: [
		'Kde je predajné miesto na easy karty',
		'Kde predávate easy kartu',
		'Kde sa dá kúpiť easy karta',
		'Kde sa kupuje easy karta',
		'Kde sa predávajú easy karty',
	],
	I_CIR_00_01_N_EASY__PLATNOST: [
		'Ako dlho mi bude platiť karta?',
		'Dokedy je platná easy karta?',
		'Dokedy mám platnú easy kartu?',
		'Dokedy platí easy karta?',
		'na akú dlhú dobu mi platí easy karta?',
	],
	I_CIR_00_01_N_EASY__PRECHOD_PREDPLATENKA: [
		'dalo by sa prejsť z easy karty na predplatenku aj cez zákaznícku linku?',
		'dá sa prejsť z easykarty na predplatenku cez zákaznícku linku?',
		'je možné cez zákaznícku linku prejsť z easykarty na predplatenku?',
		'je možné prejsť z easykarty na predplateku pomocou zákazníckej linky?',
		'je možné prejsť z easykarty na predplatenku cez zákaznícku linku?',
		'môžem cez zákaznícku linku prejsť z easy na predplatenku?',
		'môžem prejsť z easykarty na predplatenku cez zákaznícku linku?',
	],
	I_CIR_00_02_I_INA_POZIADAVKA__OTHER: [
		'chcem riešiť niečo iné',
		'iné niečo',
		'inu',
		'inú',
		'inú požiadavku',
		'jednu požiadavku',
	],
	I_CIR_00_02_X_YES__NEED: ['Potrebujem'],
	I_CIR_00_157_A_VA__GENERAL: [
		'ak vyriešiš môj problém',
		'ale nech to je rýchlejšie ako naposledy',
		'no čo už s vami',
		'pokecajme si ešte',
	],
	I_CIR_00_157_A_VA__LETS_CONTINUE: [
		'áno skusme to vyriešiť',
		'dám vám šancu',
		'ešte mi skúste pomôcť',
		'ešte si skúsme pokecať',
		'ja to ešte skúsim',
		'môžeme pokračovať',
		'môžme znovu',
		'skúsim s Oliviou',
		'skúsme to ešte raz',
		'skúsme to spolu',
	],
	I_CIR_00_157_A_VA__MAM: ['mám'],
	I_CIR_00_157_A_VA__MAMI: ['mami'],
	I_CIR_00_157_A_VA__NEBOL: ['nebol', 'tie bol'],
	I_CIR_00_157_A_VA__WITH_VA: [
		'posledný krát to skúsme spolu',
		's asistentkou',
		's Oliviou',
		's Olíviou',
		's robotom',
		's tebou Olívia',
	],
	I_CIR_00_157_A_VA__WITH_YOU: [
		'chcem sa rozprávať s tebou',
		'hej s tebou',
		'hej s vami',
		'hmm s tebou',
		'jasné s tebou',
		'jasné s vami',
		'je mi to jedno, aj s tebou',
		'môžem komunikovať s vami',
		'môžem s vami',
		'sebou',
		's tebou',
		's vami ale nech to je rýchlejšie',
		's vami hovoriť',
		's vami mozem hovorit',
		's vami',
		'tebou',
	],
	I_CIR_00_157_A_VA__ZNAMY: ['známy'],
	I_CIR_00_157_A_VA__ZVANY: ['zvaný'],
	I_CIR_00_157_D_DOESNT_KNOW__DON_T_KNOW: [
		'ako to mám vedieť',
		'čo ja viem',
		'ja neviem',
		'ja sa v tom nevyznám',
		'ja to neviem',
		'netuším',
		'neviem',
		'neviem to zistiť',
		'nevyznám sa v tom',
	],
	I_CIR_00_157_D_DOESNT_KNOW__GIVE_UP: [
		'ja to kašlem',
		'ja to môžem celé',
		'môžem sa ja na to vykašlať',
		'na toto nemám nervy',
		'nebudem tu s vami zabíjať čas',
		'necháme to tak',
		'och bože',
		'vykašlem sa na to',
		'zvysoka vám na to kašlem',
	],
	I_CIR_00_157_D_DOESNT_KNOW__LATER: [
		'nemám to po ruke',
		'neviem, skúsme neskôr',
		'pozriem sa potom',
		'práve šoférujem',
		'šoférujem',
		'som v práci',
		'teraz šoférujem',
		'teraz to nemám',
		'to pri sebe nemám',
		'zavolám neskôr',
	],
	I_CIR_00_159_A_AKTIVACIA__AKO_PREJDEM_NA_EASY: [
		'Chcem ísť z paušálu na EASY kartu',
		'Chcem ísť z programu na EASY kartu',
		'Chcem prejsť z paušálu na EASY kredit',
		'prehoďte ma z paušálu na EASY',
		'zmeňte mi paušálku na EASY kartu',
	],
	I_CIR_00_159_D_PROBLEM_S_KREDITOM__GENERAL: [
		'cez aplikáciu som si skúšal dobiť kredit, kredit mi však nenavýšilo',
		'chcel som si dnes dobiť kredit ale nefunguje vám to',
		'Dobil som si kredit cez internet, stiahlo mi peniaze ale kredit mi nedošiel',
		'Máte pokazené dobíjanie kreditu',
		'nejde mi dobitie kreditu',
		'Nejde mi dobiť kredit',
		'Nemôžem si dobiť kredit',
		'neviem, či sa ešte dá dobiť kredit aj cez kupón',
		'skúšam si dobiť kredit viacerými spôsobmi, ale nejde mi to',
	],
	I_CIR_00_159_E_ADMINISTRACIA_EASY__AKTIVUJ_SLUZBU: ['Chcem si aktivovať službu na easy karte'],
	I_CIR_00_159_E_ADMINISTRACIA_EASY__DEAKTIVUJ_KARTU: ['Chcem si deaktivovať easy'],
	I_CIR_00_90_A_NO__NEPRISLA: ['nebola doručená', 'nedošla', 'neprišla', 'nič sme nedostali', 'vôbec nám to neprišlo'],
	I_CIR_01_01_A_YES__ANOTHER_ISSUE: [
		'áno, chcel by som ešte niečo ohľadne internetu',
		'ano, ešte sa mi okrem toho spomalil internet',
		'áno, mám ďalšie otázky týkajúce sa',
		'hej, ešte by som potreboval poradiť pri paušáloch',
		'jasné, chcel by som zmeniť adresu na odosielanie faktúr',
		'jednoznačne áno, bolo by mi treba zakúpiť rýchlejšie dáta',
		'jj, chcel by som sa ešte spýtať na neobmedzené dáta od vás',
		'môžete, rád by som zistil, koľko sms som odoslal',
		'no hej, ešte okrem toho mi poriadne nejde magio tv',
		'no hej vlastne, chcem sa spýtať ešte na tú biometriu',
		'no, mám pár ďalších otázok',
		'no, rád by som ešte vybral nejaký telefóm',
		'no vlastne môžete, syn stratil mobil',
		'samozrejme, pred pár dňami mi prišla faktúra a teda nerozumiem niektorým poplatkom',
		'určite áno, nejdú tiež mi mobilné dáta',
		'určite, cestujem do zahraničia a chcem roaming',
		'veru hej, jak je to s mojou viazanosťou?',
	],
	I_CIR_01_01_A_YES__MORE_PROBLEMS: [
		'áno, ešte chcem pomôcť?',
		'ano, mám ešte ďalší problém',
		'ano, mám viac problémov',
		'A vedeli by ste aj druhé vyriešiť?',
		'mám ešte ďalší riešiť',
		'mám ešte ďalšiu vec na vás',
		'mám toho viac',
		'nasledujúci problém je, že mám poruchu',
		'už keď som sa dovolal tak mi ešte vyriešte',
	],
	I_CIR_01_01_A_YES__NEXT_THNG: [
		'áno chcel by som ešte',
		'áno, Ďalej mi spravte',
		'áno ešte chcem',
		'áno ešte mi pomôžte',
		'áno ešte mi povedzte',
		'chcel by som ešte ďalší riešiť',
		'chcem ešte ďalšiu vec na vás',
		'potrebujem poradiť, keď mám nasledujúci problém je, že mám poruchu',
	],
	I_CIR_01_01_A_YES__PROBLEM_STILL_EXISTS: [
		'ano, môj problém s magiom ste veru nevyriešili',
		'áno, naďalej mám problém s volaniami',
		'áno, ten roaming mi stále nejde',
		'áno, urćite, ten internet proste stále nejde',
		'áno, veď moja pevná linka stále nefunguje',
		'hej, a snáď mi s tým už naozaj pomôžete',
		'hej, problém s boxom furt pretrváva',
		'jasné, internet v mobile je naďalej strašne pomalý',
		'jednoznačne, stále ste mi nevysvetlili tie nové poplatky',
		'samozrejme, veď s tým nastavením Magio boxu nepomohli',
		'určite, aj keď som spravil, čo ste mi radili, nepomohlo',
		'určite, lebo s problémom ste mi nepomohli',
		'určite, naďalej trvá môj problém s posielaním sms',
		'veruže áno, volania mi naďalej nefungujú',
	],
	I_CIR_01_01_A_YES__SIMPLE: [
		'áno',
		'ba',
		'hej',
		'jasné',
		'jo',
		'presne tak',
		'samozrejme',
		'súhlas',
		'tak',
		'určite',
		'veru tak',
	],
	I_CIR_01_01_A_YES__VARIOUS_SPECIFIC: [
		'ak viete s routerom pomôcť',
		'áno, ešte sa mi rozbil internet, je pomalý',
		'ano, mám problém s modemom',
		'áno, programy mi nejdú',
		'A vedeli by ste aj sms ked neposiela poradiť?',
		'ba, chcem sa spýtať na zľavy',
		'ešte ma poinformujte o akciách, dobre?',
		'mám ešte vybité dáta a treba ich dobiť',
		'mám toho viac, napríklad, že mi nejde paušál ani dobiť kredit',
		'už keď som sa dovolal tak mi ešte aktivujte roaming',
	],
	I_CIR_01_01_B_NO__ENOUGH: [
		'absolútne nie, zdá sa mi, že je to k ničomu',
		'jednoznačne nie, už nemám na to trpezlivosť',
		'nemôžete, stačilo už',
		'nemôžete, vaša pomoc je zjavne k ničomu',
		'nie, s tým čo potrebujem mi takto nepomôžete',
		'nie, už aj tak nevyriešime cez internet',
		'nie, už bolo dosť tých zbytočných kecov',
		'nope, aj tak to takto nepochopíte',
		'radšej nie, už teraz sme s problémom stratili kopec času',
		'rozhodne nie, už som unavený',
		'to nie, aj tak nič nechápete',
		'to teda určite nie, už fakt stačilo tejto akože pomoci',
		'už nepotrebujem',
		'veru nie, takto virtuálne to zjavne nevyriešime',
	],
	I_CIR_01_01_B_NO__IT_HELPED: [
		'neni potrebné, už mi všetko beží ako má',
		'netreba, je po probléme',
		'nie je potrebné, internet už funguje normálne',
		'nie je treba, už nemám ďalší problém',
		'nie je treba už, pomohli ste mi odstrániť môj problém',
		'nie nie, všetko už je ok',
		'nie, pomohlo, mám v tom už jasno',
		'nie, problém ste mi už pomohli vyriešiť',
		'nie, telka ide ako má',
		'nie, už všetko funguje',
		'rozhodne nie je viac nutné, už pomohlo',
		'určite nie, zbavili ste ma problému',
		'už nie, pomohli ste mi zodpovedať všetky moje otázky',
	],
	I_CIR_01_01_B_NO__SIMPLE: [
		'absolútne nie',
		'jednoznačne nie',
		'neexistuje',
		'ne',
		'nesúhlasím',
		'nesúhlas',
		'nie',
		'nope',
		'rozhodne nie',
		'tak to teda nie',
		'to nie',
		'to teda určite nie',
		'vôbec nie',
		'V žiadnom prípade',
	],
	I_CIR_72_01_A_YES__THIS_NUMBER: [
		'áno toto',
		'hej, toto číslo',
		'ide o toto číslo',
		'jasné, toto číslo',
		'Mám na mysli toto číslo',
		'myslím presne toto číslo',
		'na tomto čísle',
		'na tomto',
		'no jednoznačne toto',
		'o tomto čísle hovorím',
		'presne toto',
		'tak, toto',
		'tohto čísla',
		'toto číslo',
		'toto',
		'z ktorého volám',
	],
	I_CIR_72_01_B_NO__ANOTHER_NUMBER: [
		'chcem iné číslo',
		'druhé číslo',
		'hovorím o inom čísle',
		'iné číslo',
		'iného čísla',
		'na inom čísle',
		'na inom',
		'nie, iné číslo',
		'nie, nie, iné to je',
		'oj, nie, inakšie číslo',
		'to je zlé číslo',
		'volám z iného',
		'z druhého čísla',
		'z inačšieho čísla',
		'z iného',
	],
	I_CIR_72_01_B_NO__NOT_THIS_NUMBER: [
		'nechcel som toto číslo',
		'nehovorím o tomto čísle',
		'nejde o toto číslo',
		'nemám na mysli toto číslo',
		'nemyslel som toto číslo',
		'nie nie toto',
		'nie o toto',
		'nie to',
		'nie toto číslo',
		'nie toto',
		'nie z tohto, z ktorého volám',
		'toto číslo nie',
	],
};

export default intents;
