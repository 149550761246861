import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Grid, IconButton, Tooltip } from '@mui/material/';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { fetchRecording } from '../../redux/sip/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	card: {
		width: '300px',
		margin: '10px 20px 10px auto',
		borderRadius: '15px 0px 15px 15px ',
		fontSize: '14px',
		background: theme.palette.primary.main,
		color: '#fff',
		padding: '10px',
		textAlign: 'left',
		position: 'relative',
		overflow: 'inherit',
		float: 'right',
		'&::before': {
			content: '""',
			width: '0px',
			height: '0px',
			position: 'absolute',
			borderLeft: '10px solid ' + theme.palette.primary.main,
			borderRight: '10px solid transparent',
			borderTop: '10px solid ' + theme.palette.primary.main,
			borderBottom: '15px solid transparent',
			right: '-7px',
			top: '0px',
		},
	},
	left: {
		borderRadius: '0px 15px 15px 15px ',
		background: grey[600],
		margin: '10px auto 10px 20px',
		float: 'left',
		'&::before': {
			borderLeft: '10px solid transparent',
			borderRight: '10px solid ' + grey[600],
			borderTop: '10px solid ' + grey[600],
			left: '-7px',
		},
	},
	defaultFont: { fontSize: '13px', color: grey[900] },
	customPlayBtn: {
		padding: '8px',
		color: '#000',
		position: 'absolute',
		zIndex: 100,
		margin: '7px',
	},
	customPlayBtnIcon: {
		background: '#F1F3F4',
		borderRadius: '50%',
	},
}));

const PhoneCallRecordingModal = ({ conversationId, isOpenedFromExternal }) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const [audioUrl, setAudioUrl] = useState(null);
	const [isPhoneCallAudioPlaying, setIsPhoneCallAudioPlaying] = useState(false);
	const audioPlayer = useRef(null);

	useEffect(() => {
		const errorHandler = (e) => {
			console.error(e);
			Sentry.captureException(e, { extra: { conversationId } });
		};

		dispatch(fetchRecording(conversationId, true))
			.then((data) => {
				setAudioUrl('data:audio/wav;base64,' + data.payload.data.callRecordings[0].url);
			})
			.catch(errorHandler);
	}, [conversationId, audioUrl]);

	useEffect(() => {
		if (audioUrl && isPhoneCallAudioPlaying) {
			audioPlayer.current.play();
			audioPlayer.current.addEventListener('ended', () => setIsPhoneCallAudioPlaying(false));
		} else if (audioUrl) {
			audioPlayer.current.pause();
		}
	}, [audioUrl, isPhoneCallAudioPlaying]);

	return (
		<div>
			<SaveCloseModalTemplate
				previousUrlOnClose={isOpenedFromExternal}
				// For unknown reasons closing by escape key shows VALIDATE_MODEL_VERSION_FAIL error
				disableEscapeDialogClose={isOpenedFromExternal}
				isSaveBtnVisible={false}
				title={<FormattedMessage {...messages.callRecording} />}
			>
				{audioUrl ? (
					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={12}>
							<Tooltip
								title={
									<FormattedMessage
										{...messages[isPhoneCallAudioPlaying ? 'pausePhoneCallAudio' : 'playPhoneCallAudio']}
									/>
								}
								placement={'top'}
							>
								<IconButton
									className={classes.customPlayBtn}
									onClick={() => setIsPhoneCallAudioPlaying(!isPhoneCallAudioPlaying)}
								>
									{isPhoneCallAudioPlaying ? (
										<PauseCircleFilledIcon className={classes.customPlayBtnIcon} />
									) : (
										<PlayCircleFilledIcon className={classes.customPlayBtnIcon} />
									)}
								</IconButton>
							</Tooltip>
							<audio controls style={{ width: '100%' }} ref={audioPlayer} src={audioUrl} type="audio/mpeg" />
						</Grid>
					</Grid>
				) : (
					<FormattedMessage {...messages.loadingContent} />
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

PhoneCallRecordingModal.propTypes = {
	conversationId: PropTypes.string,
	isOpenedFromExternal: PropTypes.bool,
};

export default PhoneCallRecordingModal;
