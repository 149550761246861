import Autocomplete from '@mui/lab/Autocomplete';
import { CircularProgress, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { adjust, isEmpty } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PROPERTY_TYPES } from '../../core/configs';
import messages from '../../intl/messages';
import { palette } from '../../mui-theme-builder';
import { hideModal } from '../../redux/modal/actions';
import { fetchExtractorsData } from '../../redux/model-table/actions';
import { getExtractors } from '../../redux/model-table/selectors';
import { createUpdateSmartFunction, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import { validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const AddEditSmartFunctionModal = ({ nodeId, funcId, existingFunc, propertyType }) => {
	const extractors = useSelector(getExtractors);
	const [errorName, setErrorName] = useState(null);
	const [errorOutput, setErrorOutput] = useState(null);
	const [functionsFromApi, setFunctionsFromApi] = useState(extractors);
	const [funcName, setFuncName] = useState('');
	const [inputParams, setInputParams] = useState([]);
	const [output, setOutput] = useState('');

	const focusedTextField = useRef();
	const intl = useIntl();
	const dispatch = useDispatch();
	const loading = isEmpty(functionsFromApi);
	const isEntryConditions = propertyType === PROPERTY_TYPES.EXTRACT;
	const text = isEntryConditions ? (
		<FormattedMessage {...messages[existingFunc ? 'editEntryCondition' : 'addEntryCondition']} />
	) : (
		<FormattedMessage {...messages[existingFunc ? 'editSmartFunction' : 'addSmartFunction']} />
	);
	const existingFuncName = existingFunc && existingFunc.name ? existingFunc.name : null;
	const displayExistingFunc = existingFuncName === funcName;
	const funcMatch = functionsFromApi[funcName] || displayExistingFunc;
	const existingFuncInputs = displayExistingFunc && existingFunc.inputs ? existingFunc.inputs : null;
	const displayFuncFromApi = !loading && functionsFromApi[funcName] && !displayExistingFunc;

	const updateForm = () => {
		if (displayFuncFromApi) {
			setInputParams(functionsFromApi[funcName]);
		} else if (displayExistingFunc) {
			setOutput(existingFunc.output);

			if (existingFuncInputs) {
				setInputParams(
					Object.entries(existingFuncInputs).map(([key, value]) => ({
						name: key,
						default_value: typeof value === 'object' ? JSON.stringify(value) : value,
					}))
				);
			}
		} else {
			setInputParams([]);
			setOutput('');
		}
	};

	useEffect(() => updateForm(), [funcName]);

	useEffect(() => {
		if (loading) {
			dispatch(fetchExtractorsData());
		}
		if (existingFunc) {
			setFuncName(existingFuncName);
		}
	}, []);

	useEffect(() => {
		setFunctionsFromApi(extractors);
		if (focusedTextField.current) {
			focusedTextField.current.focus();
		}
	}, [extractors]);

	const handleFuncNameChange = (funcName) => {
		setFuncName(funcName || '');
		setErrorName(functionsFromApi[funcName] ? null : intl.formatMessage(messages.smartFunctionNameMismatch));
	};

	const handleAdd = () => {
		setErrorOutput(validateIsEmpty(output));
		if (validateIsEmpty(funcName) || !funcMatch || isEmpty(output)) {
			return;
		}

		const _inputs = {};
		for (const param of inputParams) {
			try {
				_inputs[param.name] = JSON.parse(param.default_value);
			} catch {
				if (!param.default_value || isEmpty(param.default_value)) {
					_inputs[param.name] = null;
				} else {
					_inputs[param.name] = param.default_value;
				}
			}
		}

		dispatch(
			registerStateBeforeDispatchingAction(
				createUpdateSmartFunction(nodeId, funcId, propertyType, {
					[output]: isEmpty(_inputs) ? funcName : [funcName, _inputs],
				})
			)
		);
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<SaveCloseModalTemplate title={text} saveBtnTitle={text} onSave={handleAdd}>
			<Grid container spacing={2} style={{ width: '100%' }}>
				<Grid item xs={12} align="left">
					<Autocomplete
						freeSolo
						options={Object.keys(functionsFromApi)}
						inputValue={funcName}
						renderInput={(params) => (
							<TextField
								{...params}
								autoFocus
								inputRef={focusedTextField}
								error={!!errorName}
								helperText={errorName}
								label={!loading && <FormattedMessage {...messages.selectSmartFunction} />}
								variant={'outlined'}
								fullWidth
								onKeyPress={handleKeyPress}
								required={!loading}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<span style={{ color: palette.primary.main, position: 'absolute', left: '15px' }}>
											{loading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</span>
									),
								}}
								onChange={(e) => handleFuncNameChange(e.target.value)}
							/>
						)}
						onChange={(e, v) => handleFuncNameChange(v)}
					/>
				</Grid>
				<Grid item xs={6} align="left">
					{inputParams &&
						inputParams.map((inputParam, id) => (
							<TextField
								style={{ marginTop: '20px' }}
								key={'inputParam' + id}
								value={inputParam.default_value || ''}
								label={inputParam.name || ''}
								fullWidth
								onChange={(e) =>
									setInputParams(adjust(id, () => ({ ...inputParam, default_value: e.target.value }), inputParams))
								}
								onKeyPress={handleKeyPress}
								variant="standard"
							/>
						))}
				</Grid>
				<Grid item xs={6} align="left">
					{funcMatch && (
						<TextField
							style={{ marginTop: '20px' }}
							value={output}
							disabled={loading}
							error={!!errorOutput}
							helperText={errorOutput}
							fullWidth
							onChange={(e) => {
								setOutput(e.target.value);
								setErrorOutput(validateIsEmpty(e.target.value));
							}}
							onKeyPress={handleKeyPress}
							variant="standard"
							label={<FormattedMessage {...messages.smartFunctionOutput} />}
						/>
					)}
				</Grid>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

AddEditSmartFunctionModal.propTypes = {
	nodeId: PropTypes.string,
	funcId: PropTypes.number,
	existingFunc: PropTypes.object,
	propertyType: PropTypes.string,
};

export default AddEditSmartFunctionModal;
