import { gql } from 'graphql-tag';

const SIP_ACCOUNT_FIELDS = gql`
	fragment SIP_ACCOUNT_FIELDS on SipAccount {
		accountUri
		domain
		registral
		username
		registration
		active
		protocol
		srtp
		organizationId
		voiceConnectorInstance
		accountStatus
	}
`;

export const getPhoneCallsQL = gql`
	query phoneCalls($modelId: ID!) {
		phoneCalls(modelId: $modelId) {
			callId
			callStart
			callEnd
			duration
			configHash
			language
			phoneNumberFrom
			phoneNumberTo
			direction
			recordCalls
			modelVersion
		}
	}
`;

export const getRecordingQl = gql`
	query callRecordings($id: ID!, $isSip: Boolean!) {
		callRecordings: callRecordings(callId: $id, isSip: $isSip) {
			url
		}
	}
`;

export const getSipAccountsQl = gql`
	query sipAccounts {
		sipAccounts {
			...SIP_ACCOUNT_FIELDS
		}
	}
	${SIP_ACCOUNT_FIELDS}
`;

export const getSipAccountsForModelQl = gql`
	query sipAccountsForModel($modelId: ID!) {
		sipAccountsForModel(modelId: $modelId) {
			...SIP_ACCOUNT_FIELDS
		}
	}
	${SIP_ACCOUNT_FIELDS}
`;

export const createSipAccountQl = gql`
	mutation createSipAccount(
		$domain: String!
		$registral: String
		$registration: Boolean
		$username: String
		$password: String
		$protocol: String
		$srtp: Int
		$organizationId: String
		$voiceConnectorInstance: String
	) {
		createSipAccount(
			domain: $domain
			registral: $registral
			registration: $registration
			username: $username
			password: $password
			protocol: $protocol
			srtp: $srtp
			organizationId: $organizationId
			voiceConnectorInstance: $voiceConnectorInstance
		) {
			...SIP_ACCOUNT_FIELDS
		}
	}
	${SIP_ACCOUNT_FIELDS}
`;

export const updateSipAccountQl = gql`
	mutation updateSipAccount(
		$accountUri: String!
		$domain: String
		$registral: String
		$registration: Boolean
		$username: String
		$protocol: String
		$srtp: Int
		$organizationId: String
		$voiceConnectorInstance: String
	) {
		updateSipAccount(
			accountUri: $accountUri
			domain: $domain
			registral: $registral
			registration: $registration
			username: $username
			protocol: $protocol
			srtp: $srtp
			organizationId: $organizationId
			voiceConnectorInstance: $voiceConnectorInstance
		) {
			...SIP_ACCOUNT_FIELDS
		}
	}
	${SIP_ACCOUNT_FIELDS}
`;

export const deleteSipAccountQl = gql`
	mutation deleteSipAccount($accountUri: String!) {
		deleteSipAccount(accountUri: $accountUri) {
			...SIP_ACCOUNT_FIELDS
		}
	}
	${SIP_ACCOUNT_FIELDS}
`;
