import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { deleteMetadata, fetchMetadatas, clearTableState } from '../../redux/data-management/actions';
import { getDataTable } from '../../redux/data-management/selectors';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const DeleteMetadataModal = ({ id, name }) => {
	const dispatch = useDispatch();
	const dataTable = useSelector(getDataTable);
	const handleDelete = async () => {
		await dispatch(deleteMetadata(id));
		await dispatch(fetchMetadatas());
		if (dataTable.tableName === name) {
			dispatch(clearTableState());
		}
		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.deleteMetadata} />}
				saveBtnTitle={<FormattedMessage {...messages.deleteMetadataButton} />}
				onSave={handleDelete}
			>
				<span>
					{<FormattedMessage {...messages.deleteMetadataContent} />} <strong>{name}</strong> ?
				</span>
			</SaveCloseModalTemplate>
		</div>
	);
};

DeleteMetadataModal.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default DeleteMetadataModal;
