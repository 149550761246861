const intents = {
	ADDRESS_PRODEJNA: [
		'adresa pobočky',
		'adresu na prodejnu',
		'adresu pobočky',
		'adresu prosím',
		'dá se zjistit přesná adresa pobočky',
		'jaká je adresa prodejny',
		'jaká je adresa',
		'kde je kamenný obchod',
		'kde je obchod',
		'kde je pobočka',
		'kde je prodejna',
		'kde máte pobočku',
		'potřebuju adresu na pobočku',
		'potřebuju adresu pobočky',
		'potřebuju adresu prodejny',
		'řekněte mi adresu',
	],
	ADDRESS_AUTOSALON: [
		'adresa autosalonu volvo',
		'adresa na showroom volva',
		'adresa prodejny vozů volvo',
		'adresu na salon volva',
		'dejte mi adresu na prodejnu volva',
		'jaká je přesná adresa prodejny volvo',
		'kde sídlí prodejna volvo',
		'adresu, kde sídlí prodejna volkswagenu',
		'na jaké adrese sídlí pobočka volskwagenu',
		'přesnou adresu autosalonu volkswagen',
		'sháním adresu na salon volkswagenu',
		'adresa autosalonu',
		'adresu autosalonu potřebuji',
		'chci adresu na salon',
		'adresa autosalonu dacia',
		'adresa dacie',
		'adresu na salon dacia',
		'na jaké adrese sídlí dacie',
		'adresa autosalonu kia',
		'adresa kii',
		'adresu na salon kia',
		'jaká je adresa prodejny aut značky kia',
		'adresa autosalonu renault',
		'adresu na salon renaultu',
		'adresu pobočky renaultu',
		'jakou má adresu prodejna vozů značky renault',
		'jaká je adresa showroomu renaultu',
		'na jaké adrese sídlí pobočka renaultu',
		'adresa sídla opelu',
		'adresu na salon opelu',
		'adresu opelu',
		'jaká je adresa na showroom opelu',
		'přesnou adresu prodejny vozů značky opel',
		'adresa pobočky automobilů',
		'chci vědět adresu pobočky audi',
		'kde sídlí audi',
	],
	ADDRESS_COMPANY: [
		'adresa sídla společnost',
		'adresa, kam to odeslat',
		'jaká je adresa sídla firma',
		'na jakou adresu se mám dostavit',
		'na jakou adresu to mám poslat',
		'na jaké adrese je vedení',
		'na jaké adrese máte sídlo firmy',
	],
	ADDRESS_SERVICE: [
		'adresa na servis',
		'adresa servisu pro vozy',
		'adresa servisu vozů',
		'adresu servisu',
		'dejte mi adresu na servis',
		'kde sídlí servis',
		'adresa servisu audi',
		'adresa servisu značky audi',
		'adresu na servis audi',
		'jaká je adresa servisu audi',
		'adresa na které je servis dacie',
		'adresa servisu automobilů dacia',
		'adresa servisu dacia',
		'adresa sídla servisu dacia',
		'adresu na servis dacia',
		'jaká je adresa servisu aut značky dacia',
		'na jaké adrese sídlí servis dacia',
		'adresa na které je servis kii',
		'adresu na servis kia',
		'jaká je adresa servisu aut značky kia',
		'na jaké adrese sídlí servis kia',
		'adresa servisu vozů opel',
		'adresa sídla servisu opelu',
		'adresu na servis opelu',
		'adresu na servis renaultu',
		'adresu servisu renaultu',
		'adresu na servis škodovky',
		'kde má sídlo servis škodovek',
		'kde přesně je servis pro vozy Škoda',
		'adresa na servis volva',
		'adresa servisu pro vozy volvo',
		'sháním adresu na servis volkswagenu',
	],
	ADDRESS_SKODA: [
		'adresa na showroom vozů značky škoda',
		'adresa prodejny škodovek',
		'adresa salonu škody',
		'adresu na pobočku škodovky',
		'jaká je adresa Škoda',
		'kde má sídlo prodejna škodovek',
		'kde přesně je prodejna pro vozy Škoda',
		'přesnou adresu na prodejnu škodovek',
	],
	ADDRESS_STATION: [
		'adresa benzínky',
		'adresa čerpací stanice',
		'adresu čerpačky',
		'jaká je adresa benzínky',
		'jaká je adresa čerpačky',
		'na jaké adrese je vaše benzínová stanice',
		'na jaké adrese máte pumpu',
		'na jaké adrese stojí pumpa',
	],
	ADDRESS_USED_CARS: [
		'adresa na ojeté vozy',
		'adresa na pobočku ojetých vozů',
		'adresa na prodejnu ojetých vozů',
		'adresa na salon použitých vozů',
		'adresa na showroom zánovních vozů',
		'adresu na bazar zánovních vozů',
		'chci adresu na použité vozy',
		'jaká je adresa ojetých vozů',
	],
	OMYL_CHCZNOVA: [
		'Přepsal jsem se',
		'Spletl jsem se',
		'Zadat znovu',
		'ještě jednou, prosím',
		'můžete to zopakovat',
		'můžu ještě jednou',
		'omyl, znova',
		'restart',
	],
	ZACIT_ZNOVU: ['začít chat', 'začít od začátku', 'začít znovu'],
	AKCE_SLEVY_CAMPAIGN: [
		'Jakou kampaň můžete doporučit?',
		'Kampaně',
		'Nabídka kampaní',
		'Slevové kampaně',
		'Speciální kampaně',
	],
	AKCE_SLEVY_GENERAL_SALE: [
		'Celá naše rodina má u vás tarif, je tu možnost nějaké slevy',
		'Chci slevu',
		'Co mi můžete nabídnout za slevy k mému tarifu?',
		'Jakou mám slevu',
		'Jaké mám slevy',
		'Jaké máte slevy',
		'Jelikož jsem už u vás dlouho, je tu možnost mi dát slevu na můj tarif nebo na',
		'Kamarád teď od vás dostal slevu na tarif, můžu dostat nějakou slevu i já?',
		'Kolik mám slevu na tarif',
		'Mám nárok na slevu',
		'Máte množstevní slevy',
		'Máte nějaké akční slevy',
		'Máte nějaké speciální akce pro mě?',
		'Máte nějaký tarif v akci',
		'Máte odměny pro stávající zákazníky?',
		'Máte pro mě nějaký speciální akční tarif?',
		'Máte tarif za akční cenu',
		'Můžu dostat nějakou slevu?',
		'Sleva',
		'Slevy',
		'chci slevu na můj tarif za věrnost',
		'chci slevu na tarif, jako má kamarád',
		'chci věrnostní slevu na tarif',
		'chci řešit odměny a akce pro stávající zákazníky',
		'jako věrný zákazník chci věrnostní slevu a platit míň',
		'jsem dlouho vaším zákazníkem, dostanu za to nějakou odměnu?',
		'jsem dlouho vaším zákazníkem, přesto platím za tarif vysokou částku, nemám nárokna nějakou slevu',
		'jsem u vás dlouho, chci slevu na můj tarif',
		'mám nárok na slevu na tarif, ale nepropsala se',
		'máte nějaké slevy na tarif pro stávající zákazníky',
		'nezapočítala se mi sleva na tarif, přestože na ni mám nárok',
		'nezapočítali jste mi slevu na tarif',
		'platím moc, chci slevu na tarif',
		'platím za tarif příliš mnoho, chci akční tarif',
		'rád bych dostal slevu na tarif',
		'slevy a akce na tarif pro stávající zákazníky',
		'zdá se mi že se nepropsala sleva na tarif',
	],
	AKCE_SLEVY_SALE_END: [
		'chci pokračovat v ukončené slevě',
		'chci prodloužit slevu, co teď mám',
		'končí mi sleva, chci ji prodloužit',
		'končí mi sleva, prodlužte mi ji',
		'prodlužte mi slevovou akci',
		'skončila mi sleva na tarif a rád bych dostal něco co bude za stejnou cenu',
		'skončila mi sleva, ale chci v ní pokračovat',
		'skončila mi sleva, ale chtěl bych tu samou slevu i nadále',
		'ukončili jste mi slevu',
	],
	AKCE_SLEVY_STUDENT: [
		'Chtěl bych uplatnit studentskou slevu na svůj tarif',
		'Mám slevy na ISIC kartu?',
		'Máte slevy pro studenty?',
		'Studentská akce',
		'chci od vás studentskou slevu, jsem na střední škole',
		'chodím na vysokou školu, mám nárok na slevu?',
		'jsem vysokoškolačka, dostanu slevu?',
		'slevy na isic',
	],
	AKCE_SLEVY_TIME_LIMITED: [
		'Akce',
		'Dočasné nabídky',
		'Limitovaná nabídka',
		'Limitované nabídky',
		'Slevová akce',
		'Slevová nabídka',
		'Současné akce',
		'Speciální nabídky',
		'letní nabídka',
		'zimní nabídka',
		'Časově omezená nabídka',
	],
	AKTUALNSTAV_PORUCHY_CALL_STATE: [
		'Jak dlouho to bude ještě trvat než mi zprovozníte volání',
		'Kdy bude znovu fungovat?',
		'Za jak dlouho mi zase půjde zasílat SMSky',
		'aktuální stav poruchy volání',
		'jak jste na tom s mojí poruchou volání',
		'kdy bude funkční volání',
		'kdy mi opravíte volání',
		'kdy opravíte poruchu volání',
		'kdy to bude funkční',
		'kdy to zase bude fungovat',
		'kdy zase půjde to volání',
		'kdy zprovozníte SMSky?',
		'opravíte mi volání do čtvrtka',
		'za jak dlouhou rozjedete SMSky?',
		'Čekám už týden, kdy to bude fungovat?',
	],
	AKTUALNSTAV_PORUCHY_DATE: [
		'Kdy má přijet někdo na tu opravu internetu?',
		'Kdy za mnou přijde někdo opravit?',
		'Termín opravy',
		'dobrý den chci se zeptat ulice a kdy asi pude internet',
		'jaký je termín opravy?',
		'kdy půjde zase internet?',
		'termín kdy dojde na opravu',
		'v jaký den bude ta oprava?',
		'v jaký den to opravíte?',
	],
	AKTUALNSTAV_PORUCHY_GENERAL: [
		'Aktuální stav poruchy modemu',
		'Chci vědět co je s poruchou signálu',
		'Hlásil jsem poruchu internetu',
		'Hlásil jsem vám včera poruchu na své televizi. Jak to vypadá?',
		'Hlášená porucha dat',
		'Stav servisního požadavku',
		'hlásil jsem včera poruchu své televizi jak vypadá',
		'jaký je stav poruchy, kterou jsem hlásil',
		'kdy bude spravená nahlášená porucha',
		'mam již nahlášenou poruchu',
		'mám hlášenou poruchu',
		'mám nahlášenou poruchu',
		'nahlásil jsem poruchu',
		'nahlásil sem poruchu',
		'před týdnem jsem řešil poruchu televize jak vypadá',
		'už před týdnem jsem vám hlásil poruchu televize. Jak to vypadá?',
		'řešíte moji poruchu',
	],
	AKTUALNSTAV_PORUCHY_INTERNET_STATE: [
		'Jak dlouho ještě bude trvat zprovoznění našeho internetu?',
		'Jak dlouho ještě budu čekat na zprovoznění internetu',
		'Jak dlouho mám ještě čekat na opravu internetu',
		'Kdy bude opravený ten internet',
		'Kdy mi konečně půjde internet',
		'Kdy mi ten internet zprovozníte',
		'Kdy mi zprovozníte internet',
		'Kdy už mi ten net opravíte konečně',
		'Za jak dlouho mi opravíte internet',
	],
	ALL_EVERYTHING: ['vše', 'všechno', 'všechno z toho', 'všechny možnosti', 'všecko', 'úplně vše'],
	ALREADY_DID_PLAIN: [
		'To už jsem dělala',
		'To už jsem udělal',
		'To už sem udělal',
		'dělal jsem to už a nepovedlo se to ani jednou',
		'no to už jsem dělal několikrát a ani jednou se to nepovedlo',
		'teď jsem to dělal',
		'to jsem už zkoušel a nic',
		'to už jsem dělal hotovo',
		'tohle už sem zkoušel',
		'už jsem to dělala',
	],
	ALREADY_DID_STILL_NOT_WORK: [
		'ani odpojení a znovu zapojení nepomáhá',
		'i po odpojení a zapojení stále nejde',
		'modem byl odpojen znova zapojen a stále nejde',
		'modem už byl odpojený a znova zapojený, ale nepomohlo to',
		'už jsme modem odpojili, zpátky zapojili a pořád nejde',
	],
	ANNUAL_CAR: [
		'chci roční auto značky dacia',
		'chci roční vůz',
		'chci si koupit roční vozy',
		'chci si u vás koupit roční vozidlo',
		'chtěla bych si pořídit roční vůz',
		'chtěla bych vědět, jaké máte v nabídce roční užitkové vozy volkswagen',
		'jaké máte v nabídce roční vozy',
		'maximálně roční vozy',
		'mohu si u vás zakoupit roční vůz',
		'mám zájem o roční vozy',
		'máte roční vozilo značky renault',
		'nabízíte i roční sutomobily značky volvo',
		'prodáváte také roční vozy',
		'roční vozy',
		'roční vůz kia',
		'volám kvůli ročnímu vozu opel',
		'zajímají mě roční auta volkswagen',
		'zajímají mě roční vozy',
	],
	ANO_HAVE_PASSWORD: ['ano mám heslo', 'heslo mám připravené', 'mám připravené heslo'],
	ANO_PLAIN_REPEATED: [
		'Ano, opravdu chci odejít',
		'ano, chci od vás odejít',
		'ano, končím s váma',
		'ano, opravdu ruším smlouvu',
		'fakt tu smlouvu chci zrušit',
		'jak jsem říkal, chci u vás skončit',
		'jo, fakt s vámi končím',
		'opravdu chci zrušit tu smlovu',
		'opravdu si přeju zrušit smlouvu',
		'rozumíte správně, odcházím',
		'skutečně s vámi chci zrušit smlouvu',
		'skutečně vypovídám smlouvu',
		'správně, chci vypovědět smlouvu',
		'zrušte moji smlouvu',
	],
	ANO_TRANSMISSION_CODE_REPEATED: [
		'Ano, skutečně chci, abyste mi ten čvop kód poslali.',
		'ano, pošlete mi přenosový kód',
		'jak jsem říkal, chci ten ČVOP kód',
		'opravdu ten kód chci',
		'potřebuju svůj ČVOP kód',
		'požaduju ten kód pro přenos',
		'rozuměla jste dobře, chci čvop',
		'skutečně chci ten ČVOP kód',
	],
	ANO_WANT: [
		'ano, přesně toto si přeji',
		'ano, takto mi to vyhovuje',
		'ano, už se těším',
		'chtěla bych to přesně takto',
		'hm, jo, to se mi líbí',
		'jasně, budu rád',
		'jasně, to by se mi líbilo',
		'jo, budu se těšit',
		'jo, takto si to přeju',
		'jo, to budu moc rád',
		'přesně to bych chtěl',
		'tak bych si to představoval',
		'tak bych si to přál',
	],
	ARE_YOU_THERE: [
		'haló',
		'haló haló',
		'haló, je tam někdo?',
		'haló, jsi tam?',
		'haló, jste tam ještě?',
		'haló, jste tam?',
	],
	ASISTENT_HUMAN: [
		'budu mluvit jenom s lidmi',
		'chci mluvit s lidmi',
		'chci mluvit s někým živým',
		'chci mluvit s člověkem',
		'chci přepojit na člověka',
		'chci reálného člověka',
		'chci se dostat na živého asistenta',
		'chci se nechat přepojit na živého člověka',
		'chci skutečného člověka',
		'chci spojit s opravdickým člověkem',
		'chci to s člověkem',
		'chci člověka',
		'chci živého asistenta',
		'chci živého kolegu',
		'chci živého operátora',
		'chci živého poradce',
		'chci živého člověka',
		'chci, aby jste mě přepojili na živého operátora',
		'dejte mi živého člověka',
		'mohla byste mě přesunout na hlasovou operátorku živou',
		'nemáte tam někoho živého',
		'nemáte tam opravdového člověka',
		'potřebuji přepojit na člověka',
		'přepoj mě na lidi',
		'přepoj mě na člověka',
		'přepojte mě na člověka',
		'přepojte mě na ženskou která člověk',
		'přála bych si přepojit na živého asistenta',
		'rád bych mluvil s člověkem',
		'to řešit s živým asistentem',
		'toto je na živého operátora',
		'živý operátor',
	],
	ASISTENT_NOT_YOU: [
		'já tě nechci',
		'nechci hovořit s vámi',
		'nechci mluvit s vámi',
		'nechci se s váma bavit',
		'nechci se s váma vybavovat',
		'nechci to řešit s vámi',
	],
	ASISTENT_NO_ROBOT: [
		'chci hovořit a vaším kolegou né s robotem',
		'chtěla bych mluvit s někym kdo neni virtuální děkuji',
		'já nechci žádnýho robota ale živou',
		'kontakt na zákaznickou linku nechci mluvit s robotickou asistentkou',
		'nebudu hovořit s robotem',
		'nebudu se bavit s automatem',
		'nebudu se bavit s plechovou hubou',
		'nechci automat',
		'nechci mluvit s automatem',
		'nechci mluvit s počítačem',
		'nechci mluvit s robotem',
		'nechci počítač',
		'nechci robota',
		'nechci si povídat s robotem',
		'nechci to řešit s automatem',
		'nechci to řešit s robotem',
		'nemám zájem se bavit s počítačem',
		'odmítám mluvit s robotem',
		'přepojte mě prosím na někoho nevirtuálního',
	],
	BILLSHOCK_MORE_SERVICES: [
		'Manželka má na faktuře něco navíc za volání na speciálně zpoplatněná čísla.',
		'Mám mít v tarifu sms zadarmo, ale na faktuře je mám napočítané',
		'Mám na faktuře napočítané MMSky, já ale žádné neposílal',
		'Mám na faktuře napočítané smsky, které mám ale podle tarifu zadarmo',
		'Mám na faktuře napočítaný roaming, já se ale vrátil z dovolené už před měsícem',
		'Mám na vysokou částku na faktuře kvůli roamingu',
		'Mám napočítané na faktuře navíc SMS, které mám mít ale zadarmo',
		'Na svém vyúčtování mám napočítanou nějakou upomínku, za co to je?',
		'a neprávem naúčtované mms',
		'na faktuře mam zábavní služby a nikam jsem nevolal',
		'platím za službu kterou jsem si neobjednala',
		'proč mám zaúčtovanou službu kterou jsem neobjednávala',
	],
	BLOKACE_SIM_BLOCK: [
		'Já bych chtěla zablokovat SIM kartu.',
		'Já bych potřeboval zablokovat paušál.',
		'a se zablokovaným a číslama',
		'blok telefon',
		'blokace',
		'blokaci čísla',
		'bloknout SIM',
		'bloknout číslo prosím',
		'blokovací asistence',
		'chci blokaci mého čísla',
		'chci blokovat SIM',
		'dobrý den potřebovala bych zablokovat jedno telefonní číslo, bylo by to možné?',
		'mám zablokované číslo, pomůžete mi s tím?',
		'nastavit blokaci na sim',
		'pomoc s blokací SIM karty',
		'pomoc s bloknutím simky',
		'potřebovala bych udělat blokaci na toto číslo',
		'potřebuji zablokovat SIM kartu',
		'potřebuji zablokovat simkartu',
		'potřebuju poradit se zablokovanýma číslama',
		'potřebuju zablokovat číslo',
		'prosím o bloknutí  SIM',
		'prosím o zabloknutí SIM',
		'prosím o zablokování SIM',
		'volám abyste mi zablokovali můj mobil',
		'zablokovaná karta',
		'zablokované číslo',
		'zablokovat kartu SIM',
		'zablokovat simku',
		'zablokovat simku s číslem',
		'zablokovat telefon',
		'zablokovat toto číslo',
		'zablokování telefonu',
		'zablokování čísla',
		'zablokujte mi SIM kartu',
		'zablokujte mi moji simku',
		'zalokovat jedno telefonní číslo',
		'zjistit zda mám zablokovanou simku',
	],
	BLOKACE_SIM_LOST: [
		'chci nahlásit ztrátu SIM karty',
		'chci nahlásit ztrátu telefonu',
		'chci zablokovat mobil, asi jsem jej ztratil',
		'nejspíše jsem ztratil mobil, nemůžu ho nikde najít, co mám dělat?',
		'nemůžu najít mobil, mužete jej zablokovat',
		'nemůžu najít telefon, můžu si ho zablokovat?',
		'netuším, kde mám mobil, můžete jej zablokovat',
		'nevím, kde mám mobil',
		'někde jsem zapomněl mobil, zablokujete',
		'někde jsem zapomněl telefon, blokněte mi sim',
		'přišel jsem o mobil, zablokujete mi simku',
		'zablokujte mi mobil, nevím kde je',
		'ztracená sim karta',
		'ztratil jsem  tašku s mobilem',
		'ztratil jsem mobil',
		'ztratil jsem mobil, je potřeba to asi zablokovat',
		'ztratil jsem telefon',
		'ztratil se mi mobil, blokněte mi simku',
		'ztratil se mi telefon, je třeba zablokovat sim?',
		'ztratila jsem kabelku a byl v ní mobil',
		'ztratila se mi sim karta, blokněte ji',
		'ztratila se mi simka, zablokujte ji',
		'ztráta mobilu simkartou',
		'ztráta telefonu zablokování fajn',
	],
	BLOKACE_SIM_STEAL: [
		'Včera mi ukradli telefon tak jestli byste mi tam mohli zablokovat SIM kartu.',
		'byl jsem okraden',
		'byl jsem okraden o telefon',
		'chci nahládit krádež mobilu',
		'chci zablokovat sim kartu, byl jsme okraden',
		'krádež mobilu, chci zablokovat číslo',
		'někdo mi sebral mobil, zablokovat prosím',
		'okradli mě a nemám telefon',
		'okradli mě, zablokujte mi telefon',
		'sebrali mi telefon, zablokujete moje číslo',
		'ukradli mi kabelku a byl v ní telefon',
		'ukradli mi mobil',
		'ukradli mi můj telefon, potřebuji zablokovat simku',
		'ukradli mi telefon',
		'vykradli mi auto a potřebuji zablokovat všecko',
		'vykradli mi byt a sebrali mobil, zablokujte mi sim',
		'vzali mi mobil chci zablokovat číslo',
		'vzali mi telefon, chci zablokovat simkartu',
	],
	BLOKACE_SIM_UNBLOCK: [
		'Chci odblokovat SIM, jak na to?',
		'Já mam zablokovanou SIM kartu, odblokovat',
		'Před nějakou dobu se mi zablokovala karta, jak ji odblokuji',
		'Zablokovala se mi SIM karta, jak ji odblokuji',
		'chci odblokout simku',
		'chci odblokovat telefonní číslo',
		'chci vědět, jak se odblokuje sim',
		'jak na odblokování sim karty?',
		'jak si můžu odblokovat sim kartu?',
		'jak si můžu odblokovat simku?',
		'jak si odblokuju sim kartu?',
		'mobil mi hlásí zablokovanou SIM kartu',
		'odblokace simky',
		'odblokovat',
		'odblokovat sim kartu',
		'odblokování sim karty',
		'odblokování simkarty',
		'odblokování simky',
		'odblokujte mi sim kartu',
		'omylem si zablokoval SIM kartu',
		'popletl jsem PUK a zablokoval si simku',
		'poradíte mi, jak se odblokuje sim karta?',
		'potřebuji odblokovat simku',
		'potřebuju odblokovat SIM kartu',
		'potřebuju odblokovat kartu v mobilu',
		'potřebuju odblokovat kartu v telefonu',
		'potřebuju odblokovat moje číslo',
		'potřebuju uvolnit SIM kartu',
		'prosím o odblokování sim karty',
		'zablokovaná SIM, jak odblokovat',
		'zrušit blokování simky',
	],
	BOTH_BOTH_NAMED: [
		'kabelem i wifinou',
		'káblem i přes wifi',
		'mamka je na wifi, ale já jedu přes kabel',
		'nějaká zařízení máme připojená přes kabel, ale některá i přes wifi',
		'někdy kabelem a někdy wifi',
		'používáme kabel i wifi',
		'v jedné místnosti kabelem a v ostatních částech bytu wifinou',
		'využíváme jak kabel, tak i wifinu',
	],
	BOTH_GENERAL: [
		'Jak kdy',
		'Obojí',
		'Oběma',
		'někdy jedno, někdy druhý',
		'někdy tak a někdy tak',
		'podle nálady',
		'podle potřeby to střídáme',
		'používám obojí, ale záleží na okolnostech',
		'používám obě varianty',
		'používáme oboje',
		'připojuju se oběma způsoby',
		'střídám to',
	],
	BUY_AUDI: [
		'chci si koupit audi',
		'chci si pořídit audi',
		'chtěla jsem u vás koupit automobil značky audi',
		'dá se u vás sehnat vozy značky audi',
		'je u vás možné pořídit audi',
		'můžu si pořídit auto audi',
		'potřebuju koupit audi',
		'pořídím u vás audi',
		'prodáte mi audi',
	],
	BUY_DACIA: [
		'chci si koupit dacii',
		'chci si pořídit dacii',
		'můžu si pořídit dacii',
		'potřebuju koupit dacii',
		'pořídit dacii',
		'pořídím u vás dacii',
		'prodáte mi dacii',
		'zajímá mě koupě dacie',
	],
	BUY_GENERAL: [
		'chci auto',
		'chci si koupit auto',
		'chci si koupit vozidlo',
		'chci si pořídit auto',
		'chci řešit koupi auta',
		'koupit automobil u vás',
		'mohla bych si u vás koupit auto',
		'mohla bych si u vás koupit automobil',
		'potřebovala bych automobil',
		'potřebovala bych si koupit auto',
		'potřebuju koupit vozidlo',
		'prosím prodáte mi vozidlo',
		'zajímala by mě koupě auta',
	],
	BUY_KIA: [
		'chci si koupit kiu',
		'chci si pořídit kiu',
		'můžu si pořídit kiu',
		'potřebuju koupit kiu',
		'pořídit kiu',
		'pořídím u vás kiu',
		'prodáte mi kiu',
		'zajímá mě koupě kii',
	],
	BUY_NEW: [
		'chci nový vůz',
		'chci s vámi řešit nové auto',
		'chci si koupit novou kiu',
		'chci si koupit nový vůz',
		'chci si pořídit novou škodu',
		'chci si u vás koupit nový auťák',
		'chci si vybrat nové auto',
		'chtěla bych si pořídit nové auto',
		'chtěla bych si pořídit nový automobil',
		'chtěla jsem u vás koupit nový automobil značky volvo',
		'můžu si u vás koupit nový opel',
		'nové auto prosím',
		'nové užitkové vozy volkswagenu prosím',
		'nový automobil prosím',
		'potřeboval bych nový auťák',
		'potřebuju nový auto',
		'potřebuju nový vůz',
		'prodáte mi nový renaut',
		'prodáváte nová auta značky dacia',
		'prodáváte nové volkswagny',
		'volám kvůli novému vozu',
		'volám kvůli tomu novému automobilu',
	],
	BUY_OPEL: [
		'chci si koupit opel',
		'chci si pořídit opel',
		'chtěla jsem u vás koupit automobil značky opel',
		'můžu si pořídit opel',
		'potřebuju koupit opel',
		'pořídím u vás opel',
		'prodáte mi opel',
		'prodáváte auta značky opel',
		'prodáváte opely',
	],
	BUY_RENAULT: [
		'chci si koupit renault',
		'chci si pořídit renault',
		'můžu si pořídit renault',
		'potřebuju koupit renault',
		'pořídit renault',
		'pořídím u vás renault',
		'prodáte mi renault',
		'zajímá mě koupě renaultu',
	],
	BUY_SKODA: [
		'chci si koupit škodu',
		'chci si pořídit škodovku',
		'chtěl bych škodovku',
		'chtěla bych si koupit škodu',
		'chtěla jsem u vás koupit automobil značky škoda',
		'můžu si pořídit auto škoda',
		'potřebuju koupit škodu',
		'pořídím u vás škodovku',
		'prodáte mi škodovku',
	],
	BUY_USED: [
		'chci ojetinu',
		'chci ojeté auto',
		'chci ojetý vůz',
		'chci olítanou dacii',
		'chci si u vás koupit ojetý automobil',
		'chci si u vás koupit ojetý vůz',
		'chci si u vás koupit olítaný vůz',
		'chtěla bych nějakou ojetinu',
		'chtěla bych renault z druhé ruky',
		'koupit ojetinu',
		'koupit ojeté volvo',
		'lze u vás pořídit zánovní vůz kia',
		'máte u vás v autosalonu výběr vozů z druhé ruky',
		'olítaného opela',
		'potřebovala bych sehnat ojetinu volkswagenu',
		'potřebovala bych sehnat ojetý vůz',
		'potřebovala bych sehnat olétaný vůz',
		'pořídit ojetinu',
		'pořídit olítanou škodu',
		'prodáváte i auta z druhé ruky',
		'sháním zánovní automobil',
	],
	BUY_VOLVO: [
		'chci si koupit volvo',
		'chci si pořídit volvo',
		'chtěla jsem u vás koupit automobil značky volvo',
		'koupím u vás volvo',
		'můžu si pořídit volvo',
		'potřebuju koupit volvo',
		'pořídím u vás volvo',
		'prodáte mi volvo',
		'prodáváte volva',
	],
	BUY_VW: [
		'chci si koupit volkswagen',
		'chci si pořídit volkswagen',
		'chtěla jsem u vás koupit automobil značky volkswagen',
		'dá se u vás sehnat užitkové vozy značky volkswagen',
		'je u vás možné pořídit volkswagen',
		'můžu si pořídit auto volkswagen',
		'potřebuju koupit volkswagen',
		'pořídím u vás volkswagen',
		'prodáte mi volkswagen',
	],
	CANNOT_FIND_CALL_LATER: [
		'budu potřebovat čas najít to, zkuste to za hodinu',
		'můžeme později',
		'můžete zavolat zítra?',
		'nějaký čas to potrvá než budu moci',
		'to musím pohledat doma, zavoláte jindy?',
		'to si budu muset najít, zavolejte později',
		'zkuste to později, zeptám se ženy',
	],
	CANNOT_FIND_CANT_FIND: [
		'Nemůžu ho najít',
		'Nevím kde ho najdu',
		'Nevím kde je',
		'kde ho najdu',
		'kde to je?',
		'nevím',
		'nevím kde ho mám',
		'nikde to nemůžu najít, co mám dělat?',
		'to mám někde v dokumentech, ale nevím, kde to najít',
	],
	CANNOT_FIND_DONT_HAVE: [
		'Nemám ho',
		'asi jste mi takové číslo nikdy nedali',
		'já nic takového nemám',
		'nebylo mi žádné přidělené',
		'nic takového jsem nedostal',
		'toto nemám',
	],
	CANNOT_FIND_OTHER_WAY: [
		'a šlo by to i bez toho?',
		'jde to udělat i bez DSL čísla?',
		'nejde to nějak udělat i bez čísla?',
		'nezvládneme to i bez toho?',
		'nešlo by to zvládnout bez toho',
		'šlo by to udělat jinak?',
	],
	CANNOT_FIND_WHAT_IS_IT: [
		'Nevím, jaké je to číslo',
		'co je to DSL číslo?',
		'co po mě chcete přesně?',
		'co to je?',
		'jak ho zjistím',
		'jak to číslo vypadá?',
		'jak zjistím, jaké mám DSL číslo',
		'mám tady smlouvu, kde to číslo najdu?',
		'nevidím ho',
	],
	CARPARTS: [
		'chci koupit náhradní díl',
		'dá se u vás pořídit náhradní díl',
		'díly na opela',
		'díly na škodovku',
		'díly pro značku opel',
		'díly pro značku renault',
		'jsou k dispozici originální díly na volkswagen',
		'koupit originál díly',
		'máte k dispozici náhradní díly',
		'máte na skaldě náhradní díly',
		'nabízíte náhradní díly',
		'nahradní díly na renaulta',
		'náhradní díly ke škodovce',
		'náhradní díly kia',
		'náhradní díly na opel',
		'náhradní díly opel',
		'náhradní díly renaulta',
		'náhradní síly značky kia',
		'originál díly na dacii',
		'originální díly',
		'originální díly dacia',
		'originální díly do aut opel',
		'originální díly kia',
		'originální díly renault',
		'originální díly značky kia',
		'origiální díly na kiu',
		'popotřebuji originální díly do škodovky',
		'potřebuji náhradní díly',
		'potřebuji originální díly na audi',
		'potřebuji originální díly pro Škodu',
		'potřebuju originální díly na volvo',
		'prodáváte náhradní díly',
		'sehnat náhradní díl',
		'sháním náhradní díly',
		'sháním originální díly',
	],
	CERTIFICATE: [
		'auto s certifikovaným programem',
		'auto, co spadá do certifikovaného programu',
		'automobil s certifikátem',
		'certifikovaná ojetina',
		'certifikované ojeté automobily',
		'certifikované vozidlo',
		'certifikovaný ojetý vůz',
		'certifikovaný program na automobil',
		'certifikovaný vůz',
		'chci certifikovanou ojetinu',
		'chci certifikované auto',
		'máte certifikované vozy',
		'máte u aut certifikát',
		'vozy co mají certifikát',
		'zajímají mě auta s certifikátem',
		'zajímá mě vůz v certifikovaném programu',
	],
	CIZFAMILY: [
		'Informace k rodinné smlouvě',
		'chci informace k manželově smlouvě',
		'chci informace k rodinné smlouvě',
		'chci infromace ke smlouvě mojí dcery',
		'dceřina smlouva',
		'jak je to s rodinnou smlouvou?',
		'jak je to se smlouvou otce',
		'manželova smlouva',
		'matčina smlouva',
		'na manžela',
		'otcova smlouva',
		'potřebuji informace ke smlouvě mého syna',
		'synova smlouva',
		'zajímá mě rodinná smlouva',
		'zajímá mě sestřina smlouva',
		'zajímá mě smlouva mojí matky',
	],
	CIZOTHER_PERSON: [
		'Informace k cizí smlouvě',
		'Informace ke smlouvě jiné osoby',
		'K cizí smlouvě',
		'Smlouva která je napsaná na něco jiného',
		'Zajímá mě cizí smlouva',
		'cizí',
		'smlouva na někoho jiného',
	],
	COMMON_CHANGE_NET_ADDRESS: [
		'chci změnit adresu pro internet',
		'potřebuji změnit adresu internetu',
		'změna adresy internetu',
		'změnit internetovou adresu',
	],
	COMMON_IP_ADDRESS: [
		'chci veřejnou ip adresu',
		'potřebuji zřídit veřejnou ip adresu',
		'prosím o veřejnou ip adresu',
		'veřejná ip adresa',
	],
	COMMON_WIFCALL: [
		'chtěla bych volání přes wifi',
		'potřebuji vyřešit a volání přes wifi',
		'volat přes wifi',
		'volání přes wifi',
	],
	COMMON_ZYXEL: ['nový zyxel', 'potřebuju nový zyxel', 'potřebuju zyxel', 'zyxel prosím'],
	CONTACT_ACCOUNTING: [
		'chtěl bych hovořit s člověkem z účtárny',
		'kontakt na na fakturační oddělení',
		'kontakt na někoho z účtárny',
		'kontakt na účetní oddělení',
		'kontakt na účtárnu',
		'kontakt na účtárnu prosím',
		'kontaktovat účtárnu',
		'mluvit s někým z účtárny',
		'potřebuju mluvit s někým u vás na účtárně',
		'potřebuju mluvit s někým z účtárny',
		'potřebuju mluvit s panem účetním',
		'potřebuju mluvit s paní účetní',
		'potřebuju něco řešit s účtárnou',
		'přepojit na účetní',
		'přepojit na účetní oddělení',
		'přepojte mě do účtárny',
		'přepojíte mě na účetní',
		'spojit s někým na účtárně',
		'spojit s někým z účtárny',
		'telefon do účtárny',
	],
	CONTACT_AUDI: [
		'dovolat se do showroomu vozů Audi',
		'jaké je číslo na prodejnu Audi',
		'když chci audi, na jaké číslo mám zavolat?',
		'můžu poprosit o číslo na showroom vozů Audi',
		'potřebuju telefon na salon pro značku Audi',
		'prosím o přepojení na prodejnu Audi',
		'prosím číslo na showroom Audi',
		'přepojil byste mě na salon pro značku Audi',
		'přepojit na salon Audi',
		'přepojte mě na prodejnu pro značku Audi',
		'telefonní číslo do autosalonu pro značku Audi',
		'vozy Audi, jaký je telefon?',
	],
	CONTACT_DACIA: [
		'chci kontakt na salon Dacie',
		'dovolat se do salonu Dacie',
		'jaké je číslo na salon Dacie',
		'jaký je telefon na showroom Dacia?',
		'kontaktovat prodejnu pro značky Dacia',
		'musím hovořit s prodejnou Dacie',
		'prosím přepojte mě do prodejny dacie',
		'showroom Dacia, jaké má číslo?',
		'spojit s projednou vozů Dacia',
		'telefonní číslo na salon Dacie',
	],
	CONTACT_HR: [
		'chci ejč árko',
		'dovolat se na personální',
		'ejč árko potřebuju',
		'hovořit s někým z personálního',
		'kontakt na HR',
		'kontakt na ejč ár',
		'kontakt na hejčár',
		'kontakt na oddělení lidských zdrojů',
		'kontakt na personalistku',
		'kontakt na personalistu',
		'kontakt na personální oddělení',
		'mluvit s někým z personálního',
		'mluvit s panem z HR',
		'mluvit s paní z HR',
		'mobil na pana personalistu',
		'mobil na paní personalistku',
		'mohl bych mluvit s paní personalistkou',
		'nemůžu najít kontakt na pana personálního',
		'potřebuju spojit s personálním',
		'přepojit na personalistu',
		'sháním personální oddělení',
		'telefon na personalistku',
		'telefon na personální oddělení',
		'číslo na personální oddělení',
	],
	CONTACT_KIA: [
		'dejte mi číslo do showroomu značky Kia',
		'dovolat se na pobočku pro vozy Kia',
		'dovolat se na salon vozů Kia',
		'dovolat se na salon značky Kia',
		'dáte mi telefon na prodejnu vozů Kia',
		'jaké je číslo na showroom Kia',
		'když chci prodejnu vozů Kia, kam mám zavolat?',
		'kontakt na showroom Kia',
		'mobil na prodejnu vozů Kia',
		'telefon na prodejnu Kia',
	],
	CONTACT_MANAGEMENT: [
		'chtěl bych mluvit s někým z vedení',
		'dovolat se na vedení',
		'hovořit s někým z vedení',
		'kdo je vedoucím pobočky',
		'kdo vede pobočku',
		'kontakt na management',
		'kontakt na vedení společnosti',
		'kontakt na vedoucího pobočky',
		'mluvit s managementem',
		'mobil na vedoucí',
		'můžu mluvit s vedoucím pobočky',
		'odkažte mě na vedoucího pobočky',
		'přepojte mě na kanceléř vedení',
		'přepojte mě na management',
		'přepojte mě na vedoucího',
		'spojit s někým z vedení firmy',
		'spojte mě s někým z managementu',
		'telefon na vedení',
		'telefon na vedoucího',
		'vedení pobočky',
		'číslo na vedení',
	],
	CONTACT_MARKETING: [
		'chci spojit s oddělením marketingu',
		'kdo má na starosti marketing',
		'kdo má na starosti reklamu',
		'kontakt na markeťačku',
		'kontakt na markeťáka',
		'kontakt na reklamní oddělení',
		'kontakt na vedoucí marketingu',
		'kontaktovat marketingové oddělení',
		'kontaktovat šéfku marketingu',
		'mluvit s markeťákem',
		'mluvit s někým z marketingu',
		'mobil na někoho přes reklamu',
		'někoho, kdo řeší reklamu',
		'potřebuji kontakt na marketingové oddělení',
		'potřebuju se dovolat na marketingové oddělení',
		'propojit s někým z marketingu',
		'přepojit na markeťačku',
		'přepojit na markeťáka',
		'telefon na reklamní oddělení',
		'zavolat na marketingové oddění',
		'zavolat si s markeťačkou',
		'zavolat si s markeťákem',
		'číslo na marketingové oddělení',
		'číslo na něhoho přes marketing',
	],
	CONTACT_OPEL: [
		'dejte mi prosím číslo na showroom opelu',
		'dovolat se na do showroomu Opel',
		'dáte mi prosím číslo na showroom Opel',
		'jaké je číslo na salon vozů Opel',
		'jaký má prodejna Opel telefon',
		'kontaktovat showroom pro Opel',
		'potřebuju kontakt na pobočky pro vozy Opel',
		'prosím přepojit na prodejnu Opel',
		'přepojte mě na pobočku Opelu',
		'telefon na prodejnu Opel',
		'číslo na prodejnu, kde mají auto Opel',
	],
	CONTACT_OWNER: [
		'chci mluvit s majitelem',
		'dejte mi majitele',
		'dáte mi k telefonu pana majitele prosím?',
		'jaké číslo má pan majitel',
		'jaký telefon má pan majitel',
		'kontakt na majitele',
		'kontakt na majitele fimy',
		'kontakt na pana majitele',
		'potřebuji se spojit s majitelem',
		'potřebuju mluvit s panem majitel',
		'přepojte mě prosím na pana majitele',
		'telefon na majitele firmy',
	],
	CONTACT_PR: [
		'PR oddělení',
		'dovolat se člověku přes public relations',
		'hovořit s tiskovou mluvčí',
		'kdo má na starosti PR',
		'kontakt na tiskovou mluvčí',
		'kontakt na tiskového mluvčího',
		'potřebuju mobil na tiskové oddělení',
		'propojit s někým, kdo u vás řeší PR',
		'public relations oddělení',
		'přepojení na piar oddělení',
		'přepojit na PR',
		'přepojte mě na piár',
		'spojit se s vaším piár',
		'telefon na někoho z PR',
	],
	CONTACT_PRINCIPAL: [
		'chci mluvit se šéfem',
		'chci telefonní číslo na šéfa',
		'dal byste mi k telefonu pana ředitele',
		'dejte mi k telefonu šéfa firmy',
		'dovolat se na ředitelství',
		'dovolat se panu řediteli',
		'dáte mi mobil na vašeho šéfa',
		'dáte mi telefon na šéfa firmy',
		'hovořit v panem ředitelem',
		'jak můžu kontaktovat nejvyššího šéfa',
		'jak se můžu spojit s ředitelem',
		'kontakt na ředitele',
		'kontakt na ředitele pobočky',
		'kontakt na ředitelství',
		'kontakt na šéfa',
		'mobil na ředitel',
		'mobil na šéfa společnosti',
		'musím mluvit s panem ředitelem',
		'potřebuju šéfa firmy',
		'prosím číslo na šéfa pobočky',
		'přepojit byste mě na ředitelství',
		'přepojit na pana ředitele',
		'přepojte mě na ředitelství',
		'přepojte mě na šéfa',
		'přepojíte mě na šéfa prodejny',
		'telefon do kanceláře ředitele',
		'telefon na ředitele',
		'zjistit kontakt na ředitele pobočky',
		'číslo na pana ředitele',
	],
	CONTACT_RENAULT: [
		'Když chci renault, na jaké číslo mám volat?',
		'jaký je telefon na renault',
		'jaký je telefon na showroom pro značku Renault',
		'kontakt na showroom Renaultu',
		'kontakt odhledně značky renault',
		'potřebuju se dovolat na prodejnu vozů Renault',
		'prosím přepojte mě na salon Renault',
		'přepojil byste mě na salon vozů Renault',
		'přepojíte mě na prodejnu pro Renault',
		'telefon na pobočku Renault',
		'telefonní číslo na salon pro zančku Renault',
	],
	CONTACT_SERVICE: [
		'chtěl bych mluvit s technikem',
		'dejte mi prosím číslo na technika',
		'dáte mi prosím kontakt na technika',
		'hovořit s technikem',
		'kontakt na servis',
		'kontkat na servis dacie',
		'mobil na technika',
		'mám dotaz na technika',
		'potřebuji mluvit s technikem',
		'potřebuji mluvit se servisákem',
		'potřebuju se o něčem poradit s technikem',
		'přepojte mě na servis',
		'přepojte mě na servis kii',
		'volám kvůli servisu',
		'volám kvůli servisu renaultu',
		'volám na servis',
		'volám na servis volva',
		'čislo na servisáka škody',
		'číslo na servis',
		'číslo na technika',
		'číslo na technika volkswagenu',
	],
	CONTACT_SERVICE_AUDI: [
		'dovolat se do servisu vozů Audi',
		'jaké je číslo na servis Audi',
		'když chci servis audi, na jaké číslo mám zavolat?',
		'můžu poprosit o číslo na servis vozů Audi',
		'potřebuju telefon na servis pro značku Audi',
		'prosím o přepojení na servis Audi',
		'prosím číslo na servis Audi',
		'přepojil byste mě na servis pro značku Audi',
		'přepojit na servis Audi',
		'přepojte mě na servis pro značku Audi',
		'servis vozů Audi, jaký je telefon?',
		'telefonní číslo do servisu pro značku Audi',
	],
	CONTACT_SERVICE_DACIA: [
		'chci kontakt na servis Dacie',
		'dovolat se do servisu Dacie',
		'jaké je číslo na servis Dacie',
		'jaký je telefon na servis Dacia?',
		'kontaktovat servis pro značky Dacia',
		'musím hovořit se servisem Dacie',
		'prosím přepojte mě do servisu dacie',
		'servis Dacia, jaké má číslo?',
		'spojit se servisem vozů Dacia',
		'telefonní číslo na servis Dacie',
	],
	CONTACT_SERVICE_KIA: [
		'dejte mi číslo do servis značky Kia',
		'dovolat se na servis pro vozy Kia',
		'dovolat se na servis vozů Kia',
		'dovolat se na servis značky Kia',
		'dáte mi telefon na servis vozů Kia',
		'jaké je číslo na servis Kia',
		'když chci servis vozů Kia, kam mám zavolat?',
		'kontakt na servis Kia',
		'mobil na servis vozů Kia',
		'telefon na servis Kia',
	],
	CONTACT_SERVICE_OPEL: [
		'dejte mi prosím číslo na servis opelu',
		'dovolat se na do servis Opel',
		'dáte mi prosím číslo na servis opelu',
		'jaké je číslo na servis vozů Opel',
		'jaký má servis opelu telefon',
		'kontaktovat servis pro Opel',
		'potřebuju kontakt na servis pro vozy Opel',
		'prosím přepojit na servis Opel',
		'přepojte mě na servis Opelu',
		'telefon na servis Opel',
		'číslo na servis, kde mají auto Opel',
	],
	CONTACT_SERVICE_RENAULT: [
		'Když chci servis renaultu, na jaké číslo mám volat?',
		'jaký je telefon na servis pro značku Renault',
		'jaký je telefon na servis renault',
		'kontakt na servis Renaultu',
		'kontakt na servis odhledně značky renault',
		'potřebuju se dovolat na servis vozů Renault',
		'prosím přepojte mě na servis Renault',
		'přepojil byste mě na servis vozů Renault',
		'přepojíte mě na servis pro Renault',
		'telefon na servis Renault',
		'telefonní číslo na servis pro zančku Renault',
	],
	CONTACT_SERVICE_SKODA: [
		'chci kontakt na servis pro škodovku',
		'jaké je číslo na servis se škodovkami',
		'kontakt na servis vozů Škoda',
		'potřebuju se dovolat do servisu Škoda',
		'přepojil byste mě na servis, co prodává škodovky',
		'přepojit na servis Škoda',
		'přepojte mě na servis škodovek',
		'telefon na servis vozů značky Škoda',
		'telefonní číslo na servis vozů Škoda',
	],
	CONTACT_SERVICE_VOLVO: [
		'dovolat se do servisu volva',
		'jaký má kontakt servis, co má na starosti volvo',
		'kontakt na servis volva',
		'nějaký telefon do servisu volva',
		'potřebuji kontakt na servis volva',
		'prosím přepojte mě na servis volvo',
		'spojit s někým ze servisu volvo',
		'telefon na servis pro značku volvo',
		'číslo na servis pro volvo',
	],
	CONTACT_SERVICE_VW: [
		'chci se dovolat do servisu volkswagenu',
		'dovolat se na servis vozů značky Volskwagen',
		'jaké je číslo na servis Volskwagenu',
		'jaký je telefon do servisu Volkswagenu',
		'když chci servis volskwagen, na jaké číslo mám volat',
		'kontakt na servis Volskwagenu',
		'kontakt na servis s volkswagenem',
		'přepojíte mě na servis volkswagenu',
		'telefon na servis Volkswagenu',
		'číslo na servis Volkswagen',
	],
	CONTACT_SKODA: [
		'chci kontakt na prodejnu pro škodovku',
		'jaké je číslo na showroom se škodovkami',
		'kontakt na salon vozů Škoda',
		'potřebuju se dovolat do salonu Škoda',
		'přepojil byste mě na pobočku, co prodává škodovky',
		'přepojit na salon Škoda',
		'přepojte mě na prodejnu škodovek',
		'snažím se dovolat do autosalonu škody',
		'telefon na prodejnu vozů značky Škoda',
		'telefonní číslo na showroom vozů Škoda',
		'volám do prodejny škodovek',
		'volám na obchod škody',
		'volám na prodejnu škody',
		'číslo na obchod se škodovkami',
	],
	CONTACT_VOLVO: [
		'dovolat se do showroomu volva',
		'jaký má kontakt pobočka, co má na starosti volvo',
		'kontakt na prodejnu volva',
		'nějaký telefon do showroomu volva',
		'potřebuji kontakt na showroom volva',
		'prosím přepojte mě na salon volvo',
		'spojit s někým z prodejny volvo',
		'telefon na pobočku pro značku volvo',
		'číslo na autosalon pro volvo',
	],
	CONTACT_VW: [
		'dovolat se na prodejnu vozů značky Volkswagen',
		'jaké je číslo na salon Volkswagenu',
		'jaký je telefon na Volkswagen',
		'když chci volkswagen, na jaké číslo mám volat',
		'kontakt na autosalon s volkswagenem',
		'kontakt na prodejnu Volkswagenu',
		'přepojíte mě na prodejnů volkswagenu',
		'telefon na salon Volkswagenu',
		'číslo na showroom Volkswagen',
	],
	CRASH_SERVICE: [
		'jsem po bouračce',
		'mám bourané auto',
		'mám nabourané auto',
		'mám po nehodě odřené auto',
		'mám zmačkané auto',
		'měl jsem bouračku',
		'měl jsem nehodu',
		'měla jsem autonehodu',
		'nabourala jsem auto',
		'někdo mi naboural auto',
		'promáčklé auto po nehodě',
		'při autonehodi mi zmačkali auto',
		'při nehodě jsem si promáčknul auto',
		'vůz po autonehodě',
	],
	DEFECT_SERVICE: [
		'auto je pokažené',
		'auto nejede',
		'auto nějak blbne',
		'auto se rozbilo',
		'chci vyřešit závadu',
		'mám poruchu auto',
		'mám rozbité auto',
		'něco se mi rozbilo v autě',
		'pokazilo se mi auto',
		'potřebuji opravit závadu',
		'potřebuju opravit poruchu na autě',
		'řeším porouchané auto',
		'řeším závadu na autě',
	],
	DOBIJENSTAV_KREDITU_ADD_CREDIT: [
		'Chci kredit',
		'Dobití z vyúčtování',
		'Dobít kredit',
		'Došel mi kredit a potřebuju ho dobít',
		'Kredit navíc',
		'Nabít kredit',
		'Potřebuju dobít kredit.',
		'Přišla mi SMS, že už nemám kredit. Můžu si ho nabít takhle přes vás?',
		'chtěl bych si dobít kredit',
		'chtěla bych dobít číslo',
		'dobití jiného telefonu',
		'dobití kreditu',
		'dobíjení kreditu z vyúčtování paušálu',
		'dobít kredit na jiné číslo',
		'dobít kredit přebytkem z vyúčtování',
		'dobít kredit přeplatkem z výčtování',
		'dobít mobil',
		'dobít telefon',
		'dobít z vyúčtování',
		'já bych si potřeboval todleto druhý číslo dobít',
		'mohu dobít kredit svému synovi, i když je u jiného operátora?',
		'potřebuji obnovit kredit',
		'potřebuju dobít moje číslo',
		'přišla mi sms zpráva že mohu mít bonusový kredit při dobití',
	],
	DOBIJENSTAV_KREDITU_ERROR: [
		'Nepřišel mi kredit, který jsem si dobil',
		'Nevím za co se mi odečetl kredit',
		'Odečetl se mi kredit ale já nikam nevolal',
		'Proč mi telefon hlásí, že nemám kredit?',
		'Proč se mi ztratil kredit, když jsem nikam nevolal?',
		'Strhnul se mi kredit a nevím za co',
		'mi nejde dobít',
		'nejde mi dobít telefonní číslo z vyúčtování',
		'odečetli ste synovi z kreditu za sluzbu kterou nechceme',
		'proč nemám kredit',
		'včera jsem synovi dobíjel kredit a ještě mu nepřisel',
	],
	KREDIT_STAV: [
		'chci dobíjecí kredit',
		'Chci poradit s kreditem',
		'Chtěl bych poradit s kreditem',
		'Chtěl bych se zeptat kolik mám kreditu',
		'dobíjecí kredit',
		'Jak zjistím kredit',
		'Jak zjistím stav svého kreditu',
		'jak zjistím, jestli mám ještě dobíjecí kredit',
		'Jak zjistím, jestli mám ještě kredit',
		'Jaký je stav kreditu na mém účtu',
		'Jaký je zůstatek mého kreditu',
		'Jaký mám kredit',
		'Jaký mám zůstatek na kartě',
		'Jaký mám zůstatek',
		'Kolik korun mám ještě',
		'kolik korun mám zůstatek?',
		'Kolik kreditu mám na mém účtu',
		'Kolik mi zbývá korun',
		'Kolik mi zbývá kreditu',
		'Kolik mi zbývá peněz',
		'kolik mi zůstává na účtě?',
		'Kolik my zbývá na kartě',
		'kolik mám ještě kreditu?',
		'Kolik mám kredit',
		'Kolik mám kreditu',
		'Kolik mám na kartě',
		'kolik mám peněz na účtu',
		'Kolik mám peněz',
		'mám dobíjecí kredit',
		'Mám ještě kredit?',
		'Mám ještě na účtu kredit',
		'Můžu se zeptat na stav svého kreditu?',
		'potřebuju vědět, jakej mám teď kredit',
		'potřebuju vědět, kolik mám ještě na kontě kreditu',
		'potřebuju zjistit jestli mám předplacený kredit na zlatý kartě',
		'rád bych se zeptal na stav mého kreditu',
		'ráda bych se zeptala na kredit',
		'Stav kreditu',
		'Výše kreditu',
		'Zajímá mě stav kreditu',
		'zůstatek kreditu na mém účtu',
		'Zůstatek kreditu',
		'Řekněte mi stav kreditu prosím',
	],
	DOES_NOT_HAVE_DID_NOT_ARRIVE: ['ještě mi nepřišel', 'nebyl mi doručen', 'nedorazil', 'nedostal jsem ho'],
	DOES_NOT_HAVE_DONT_DO_IT: [
		'a to neudělám',
		'ne, nebudu diktovat',
		'nebudu diktovat žádné číslo',
		'nic diktovat nebudu',
		'to dělat nebudu',
		'to dělat nechci',
	],
	DOES_NOT_HAVE_DONT_HAVE_CONTRACT: [
		'nemám po ruce smlouvu',
		'nemám smlouvu u sebe',
		'nemůžu, protože po ruce smlouvu nemám',
		'neznám číslo nemám žádnou smlouvu s těmi po ruce',
		'no to teď nemám a kdes to s citovým telefonní číslo nebo',
		'teď u sebe nemám žádnou smlouvu, takže číslo nemám',
		'číslo nevím, protože teď u sebe nemám smlouvu',
	],
	DONT_HAVE: ['ne, nemám', 'nemám', 'nemám žádný', 'nemáte'],
	DONT_KNOW: [
		'Jak mám vědět, co mám vybrat?',
		'Jak to mám vědět',
		'ještě nevím',
		'jo to já vůbec nevím',
		'Já fakt nevím',
		'já tomu nerozumím, takže nevím, kterou variantu vybrat',
		'já tomu vůbec nerozumím',
		'Nedokážu říct',
		'Nejsem si jist',
		'Nejsem si jistý, co.',
		'nemám tušení s čím chci pomoct',
		'nemám tušení',
		'nemám zdání jak na to',
		'netuším jak na to',
		'Netuším',
		'Netuším, co bych k tomu měl dodat.',
		'Netuším, co mám zvolit',
		'netuším, s čím potřebuju pomoct',
		'Nevím jak na to',
		'Nevím s čím chci pomoct, nerozumím tomu',
		'Nevím si rady',
		'Nevím vůbec nic',
		'Nevím',
		'Nevím, co dodat.',
		'Nevím, co to je',
		'nevím, co z toho bych měla vybrat',
		'Nevím, jak to asi mám vědět.',
		'Nevím, jestli to stačí.',
		'nevím, já to neznám',
		'tak to vám fakt neřeknu, protože nevím',
		'To nedokážu posoudit',
		'To neumím posoudit',
		'To neumím',
		'to vám nepovím, já tomu nerozumím',
		'vůbec nevím',
		'vůbec nevím, co vybrat',
	],
	DOES_NOT_KNOW_NOTHING: [
		'Nic z toho',
		'ani jedna možnost',
		'ani jedno',
		'ani jedno z toho',
		'ani jedním',
		's ničím',
		'vůbec nic z toho',
		'vůbec s ničím',
		'Žádná z možností',
		'Žádná z variant',
		'žádné z toho',
	],
	DOES_NOT_KNOW_NO_CAPACITY_TO_UNDERSTAND: [
		'Nerozumím těmto věcem',
		'kdepak, tomu já nerozumím',
		'o tomhle nemám ani páru',
		'o tomhle nemám ani tucha',
		'tomuhle fakt nerozumím',
		'této problematice já nerozumím',
	],
	DOHODNUTY_TERMIN_AGREED_DATE: [
		'chci zjistit domluvený termín',
		'dohodnutý',
		'již domluvený',
		'kdy jsme s technikem domluveni?',
		'zajímá mě dohodnutý termín',
		'zajímám se o termín, na kterém jsme byli domluveni',
	],
	DOHODNUTY_TERMIN_AGREED_TIME: [
		'chci vědět v kolik přijde',
		'dohodnutý termín',
		'dohodnutý termín návštěvy technika',
		'dohodnutý čas',
		'jaký je dohodnutý termín technika',
		'již dohodnutý termín',
		'kdy dorazí technik',
		'na kolikátou má přijít technik',
		'v kolik je návštěva technika',
	],
	DONE_PLAIN: [
		'hotovo',
		'hotovou',
		'je to hotové',
		'mám to hotovo',
		'mám to vypnuté',
		'no hotovo',
		'už to je',
		'už to mám',
		'vypnuto',
	],
	DONT_CARE: [
		'je mi jedno jaké to bude auto',
		'je mi to jedno',
		'na tom nezáleží',
		'na značce nezáleží',
		'nechám to na vás',
		'nezáleží mi na značce',
		'spoléhám na vás',
		'to pro mě je nedůležité',
		'to pro mě není důležité',
		'značka je mi jedno',
		'značka není důležitá',
	],
	DID_NOT_DECIDE: [
		'ještě jsem se nerozhodla',
		'nemáme vybranou',
		'nemůžu se rozhodnout',
		'nerozhodla jsem se',
		'nevybral jsem ještě',
		'nevím jakou bych si měl vybrat',
		'nevím jaké si mám vybrat',
		'nevím, ještě jsem se nerozhodl',
		'tu nemám vybranou',
		'zatím jsem si nevybral',
	],
	DVBT_GENERAL: [
		'Chci se zeptat ohledně televize na přepojení na dvb t dva. Chci se zeptat jestli to potřebuju.',
		'Já bych se chtěla zeptat jak je teď v reklamách to dvb t dva. To bude fungovat?',
		'Já jsem slyšel že zítra končí to dvb t dva. Já mám od vás Set top box. Půjde mi zítra televize?',
		'Já se chci jenom zeptat kvůli tomu dvb t dva. Mně nefunguje televize. Tu televizi mám od vás?',
		'Já volám kvůli toho dvb t dva. Teď jsem zaslechla, že se to týká i některých internetových televizí. Týká se mě to?',
		'Potřebovala bych nějaký informace ohledně dvb t dva.',
		'Pořád s tím straší že to vypnou to dvbt2.',
		'Vypnutí TV',
		'asi jenom zeptat kvůli tomu dvb t dva ně funguje televize tu televizi mám od vás',
		'chci se zeptat ohledně televize na přepojení na dvb t dva chci se zeptat jestli to potřebuju',
		'já bych chtěla zeptat jak je teď v reklamách to dvb t dva a to bude fungovat',
		'já volam kvůli toho dvb t dva teď sem zas nešla že se to týká i některých internetových televizí týká se mě to',
		'konec pozemního signálu',
		'potřeboval bych nějaký informace ohledně dvb t dva',
		'přechod na dvb t dva na kolik by mě přišlo kdybych od vás nechal udělat televizi',
		'ty se zeptat ohledně dvb t dva budeme fungovat',
		'vypnutí tv signálu',
		'Řešim přechod na dvb t dva. Na kolik by mě přišlo, kdybych si od vás nechal udělat televizi.',
		'řeším přechod na dvb t dva na kolik by mi přišlo kdybych od vás nechal udělat televizi',
	],
	ELECTRIC_CAR: [
		'auto na elektrický pohon',
		'auto na elektrický proud',
		'auto na elektriku',
		'auto s pohonem na elektriku',
		'auto, které jezdí na elektriku',
		'chci auto na elektriku',
		'chci elektromobil',
		'chci vůz s elektrickým pohonem',
		'chtěl bych si koupit elektrické auto',
		'elektrické auto potřebuji',
		'elektrický vůz si chci koupit',
		'prodáte mi vůz na elektriku',
		'rád bych si koupil elektromobil',
		'vozidlo, co pohání elektrický proud',
		'zajímá mě elektromobil',
	],
	ELECTRIC_NEW_CAR: [
		'chci nové auto na elektriku',
		'chci nový elektromobil',
		'chci nový vůz s elektrickým pohonem',
		'chtěl bych si koupit nové elektrické auto',
		'nové auto na elektrický pohon',
		'nové auto na elektrický proud',
		'nové auto na elektriku',
		'nové auto s pohonem na elektriku',
		'nové auto, které jezdí na elektriku',
		'nové elektrické auto potřebuji',
		'nové vozidlo, co pohání elektrický proud',
		'nový elektrický vůz si chci koupit',
		'prodáte mi nový vůz na elektriku',
		'rád bych si koupil nový elektromobil',
		'zajímá mě nový elektromobil',
	],
	ELECTRIC_USED_CAR: [
		'auto na elektriku z druhé ruky',
		'chci ojeté auto na elektriku',
		'chci ojetý elektromobil',
		'chci použitý vůz s elektrickým pohonem',
		'chtěl bych si koupit zánovní elektrické auto',
		'ojeté auto na elektrický pohon',
		'ojeté auto s pohonem na elektriku',
		'ojetý elektrický vůz si chci koupit',
		'olétané elektrické auto potřebuji',
		'použité auto na elektrický proud',
		'prodáte mi ojetý vůz na elektriku',
		'rád bych si koupil zánovní elektromobil',
		'vozidlo z druhé ruky, co pohání elektrický proud',
		'zajímá mě použitý elektromobil',
		'zánovní auto, které jezdí na elektriku',
	],
	EMAIL_HR: [
		'chci e-mail na personální oddělení',
		'chci mail na HR',
		'chci mail na oddělení lidských zdrojů',
		'dáte mi e-mail na ejč ár',
		'e-mail na HR',
		'e-mail na personální',
		'jaký je e-mail na personalistku',
		'jaký je mail na oddělení lidských zdrojů',
		'mail na personální oddělení',
		'maila na pesrsonální',
	],
	EMAIL_MANAGEMENT: [
		'chci mail na management společnosti',
		'dáte mi  mail na management',
		'dáte mi kontaktní e-mail na managementa',
		'e-mail na management',
		'e-mail na vedení společnosti',
		'e-mail na vedení',
		'e-mail na vedení vedení firmy',
		'jaký je mail na vedení',
	],
	EMAIL_MARKETING: [
		'chci e-mail na reklamní oddělení',
		'dáte mi mail na marketingové oddělení',
		'e-mail na marketing',
		'e-mail na marketingové oddělení',
		'jaká je mail na oddělení marketingu',
		'mail na markeťáka',
		'potřebuju e-mail na vašeho markeťáka',
		'potřebuju mail na oddělení reklamy',
	],
	EMAIL_OWNER: [
		'chci e-mail na majitele firmy',
		'dáte mi e-mail na majitele',
		'e-mail na pana majitele',
		'jaká má mail majitel firmy',
		'jaký má e-mail majitel firmy',
		'kontaktní mail na majitele',
		'mail majitele',
		'potřebuju kontaktní e-mail na majitele firmy',
	],
	EMAIL_PRINCIPAL: [
		'dáte mi e-mail na pana ředitele',
		'e-mailovou adresu na pana ředitele',
		'jaký e-mail má pan ředitel',
		'jaký má mail ředitel',
		'kontaktní mail na ředitele',
		'mail na ředitele',
		'potřebuju e-mail na pana ředitele',
		'potřebuju zjistit e-mail na ředitelství',
	],
	EXISTUJICEXISTING: [
		'chci mluvit o pojištění, co u vás mám',
		'chtěla bych mluvit o stávajícím pojištění',
		'chtěla bych řešit již uzavřené pojištění',
		'existující pojištění',
		'potřebuju vyřešit existující pojištění',
		'uzavřené pojištění',
	],
	EXPERT: [
		'Kdo u vás má na starost prodej automobilů',
		'chtěla bych se poradit s odborníkem',
		'experta na kiu',
		'experta přes prodej automobilů',
		'k telefonu experta na koupi auta',
		'mluvil s někým, kdo u vás prodává auta',
		'mluvit s někým kompetentním ohledně koupě auta',
		'mluvit s někým, kdo mi pomůže vybrat vůz',
		'mluvit s odborníkem na prodej aut',
		'nějaký expert, co by mi poradil',
		'někdo poradí ohledně výběru vozu',
		'někoho, kdo mi poradí s renautem',
		'někoho, kdo mi vybere automobil',
		'někoho, kdo rozumí škodovce',
		'odborníka na automobil značky volvo',
		'odborníka na prodej aut',
		'odborníka na značku dacia',
		'odborníka přes prodej aut',
		'pomoc při výběru automobilu',
		'popovídat si s expertem',
		'poradit s výběrem vozu',
		'poradit se s expertem',
		'potřebuji poradit od experta',
		'potřebuji radu odborníka',
		'pověřenou osobu, co mi pomůže koupit auto',
		'promluvit s expertem',
		'prosím o přepojení na experta',
		'předejte mě na někoho, kdo se zabývá užitkovými vozy volkswagenu prosím',
		'přepojil byste mě na experta',
		'přepojit na experta',
		'přepojit na odborného prodejce',
		'přepojit na prodejce aut',
		'přepojíte mě na experta na opel',
		'přepojíte mě na odborníka',
		'radu ohledně vozidla',
		'spojit se s expertem',
		'člověka, co zná volkswagny',
	],
	EXPERT_NEW_CAR: [
		'chci mluvit s někým kompetentním ohledně koupě nového auta',
		'experta na novou kiu',
		'experta přes prodej nových automobilů',
		'k telefonu experta na koupi nového auta',
		'mluvil s někým, kdo u vás prodává nové auta',
		'mluvit s někým, kdo mi pomůže vybrat nový vůz',
		'mluvit s odborníkem na prodej nových aut',
		'někoho, kdo mi poradí s novým renautem',
		'někoho, kdo mi vybere nový automobil',
		'někoho, kdo rozumí nové škodovce',
		'odborníka na nové auto značky dacia',
		'odborníka na nový automobil značky volvo',
		'odborníka na prodej nových aut',
		'odborníka přes prodej nových aut',
		'pomoc při výběru nového automobilu',
		'poradit s výběrem nového vozu',
		'pověřenou osobu, co mi pomůže koupit nové auto',
		'předejte mě na někoho, kdo se zabývá novými užitkovými vozy volkswagenu prosím',
		'přepojit na prodejce nových aut',
		'přepojíte mě na experta na nový opel',
		'radu ohledně nového vozidla',
		'člověka, co zná nové volkswagny',
	],
	EXPERT_USED_CAR: [
		'experta na použitou kiu',
		'experta přes prodej použitých automobilů',
		'k telefonu experta na koupi ojetého auta',
		'mluvil s někým, kdo u vás prodává ojetá auta',
		'mluvit s někým kompetentním ohledně koupě použitého auta',
		'mluvit s někým, kdo mi pomůže vybrat ojetý vůz',
		'mluvit s odborníkem na prodej ojetých aut',
		'někdo poradí ohledně výběru ojetího vozu',
		'někoho, kdo mi poradí s ojetým renautem',
		'někoho, kdo rozumí ojeté škodovce',
		'odborníka na ojeté auto značky dacia',
		'odborníka na ojetý automobil značky volvo',
		'odborníka na prodej ojetých aut',
		'odborníka přes prodej použitých aut',
		'pomoc při výběru použitého automobilu',
		'poradit s výběrem ojetého vozu',
		'předejte mě na někoho, kdo se zabývá ojetými užitkovými vozy volkswagenu prosím',
		'přepojit na prodejce použitých aut',
		'přepojíte mě na experta na použitý opel',
		'radu ohledně použitého vozidla vozidla',
		'člověka, co zná použité volkswagny',
	],
	FAKTURACE_ACOUNT_STATEMENT: [
		'chtěl bych výpis z účtu',
		'podrobný výpis služeb',
		'podrobný výpis z účtu',
		'poskytnete mi výpis z účtu',
		'potřebuji výpis z účtu',
		'výpis služeb',
	],
	FAKTURACE_AMOUNT: [
		'chci splatit fakturu k telefonu',
		'chci zjistit poslední zaplacenou částku podle minulého vyúčtování',
		'chci znát útratu',
		'chtěla by vědět kolik budu platit za září',
		'chtěla bych znát kolik budu platit jako za více volání tento měsíc',
		'jaká je výše mé faktury',
		'jaká je částka na vyúčtování',
		'kolik mám eště zaplatit',
		'kolik mám poslední zaplacenou částku',
		'kolik mám zaplatit poslední se zaplacenou částku',
		'kolik mám zaplatit za poslední fakturu',
		'kolik platím za telefon',
		'potřeboval bych vědět už to vyúčtování',
		'potřebuju vědět kolik platím tendle měsíc za služby',
		'potřebuju vědět útratu',
		'potřebuju zjistit výši vyúčtování',
		'přiznat hodnotu vyúčtování',
		'splácení faktury na ceně zaplacení telefonu potřebuji',
		'splácení faktury prosím',
		'telefon kolik platím',
		'vyúčtování do teď jak jaká je částka',
		'výše vyúčtování',
		'zjistit částku na minulém vyúčtování',
		'zjistit částku na posledním vyúčtování',
	],
	FAKTURACE_BILLING_INFO: [
		'Chci se informovat ohledně fakturace',
		'bych se zeptala ohledně vyúčtování služeb',
		'chci se informovat na vyúčtování',
		'chci se zeptat na fakturu',
		'chci se zeptat na poslední vyúčtování',
		'chci vyúčtování mého telefonního čísla',
		'chci vyřešit něco s fakturou',
		'chtěl bych vysvětlit fakturační údaje',
		'chtěl bych vědět, jaké je moje vyúčtování',
		'chtěl bych znát vyúčtování',
		'chtěla bych znát můj stav vyučování',
		'dobrý den potřeboval bych vyúčtování k smlouvám které už mam zrušené',
		'informace o vyúčtovaných',
		'informace o vyúčtování',
		'informace o vyúčtováních',
		'jak vypadá moje faktura',
		'jaká je moje faktura,  zúčtování z minula',
		'je souhlasí nám vyúčtování tarif',
		'já bych chtěla vědět to vyúčtování a nad našeho telefonního čísla',
		'já bych potřebovala vědět proč mám vyúčtování na 461 korun tenhle měsíc',
		'mam zaplacené vyúčtování',
		'mám ten vyúčtování',
		'můžu se zeptat na vyúčtování',
		'no takže chtěl by zeptat na vyúčtování a internetu na doma',
		'potřeboval zjistit vyučování',
		'potřebovala bych informaci k faktuře, která mi přišla',
		'potřebuje pomoc i ohledně vyúčtování',
		'potřebuji se zeptat na vyúčtování',
		'potřebuju nějaké informace k vyúčtování',
		'potřebuju s vámi pořešit fakturu',
		'potřebuju se domluvit na vyúčtování telefonního čísla',
		'potřebuju se zeptat na něco kvůli vyúčtování kvůli telefonnímu číslu',
		'potřebuju zjistit vaše zúčtovací období od kdy do kdy',
		'potřebuju zjistit vyúčtování',
		'potřebuju znát mé vyúčtování',
		'prosím o přehled vyúčtování',
		'proč jsem překročila vyúčtování',
		'proč mám a jiné vyúčtování než doposud',
		'proč platím tolik peněz',
		'před chvílí jsem volala kvůli nezaplacenému telefonu chtěla jsem se zeptat jak to vypadá',
		'přehled vyúčtování',
		'přehled vyúčtování červen',
		'přepojit mně prosím na vyúčtování telefonních hovorů',
		'sdělíte mi stav mého vyúčtování',
		'se zeptat na fakturu která mi přišlo',
		'souhlasí nám vyúčtování tafiru',
		'uděláte mi přehled vyúčtovaných služeb',
		'vyúčtování chci znát',
		'vyúčtování potřebuji zjistit',
		'vyúčtování za 13. 6. až 19. 6.',
	],
	FAKTURACE_CANNOT_FIND: [
		'jak mám najít online vyúčtování',
		'kde najdu v samoobsluze vyúčtování',
		'na internetu jsem nenašel moje vyúčtování',
		'nejde mi najít vyúčtování v aplikaci',
		'nemůžu najít své online vyúčtování',
		'nemůžu v samoobsluze najít vyúčtování',
		'nezobrazuje se mi po přihlášení do elektronické evidence aktuálně dlužná čáska',
	],
	FAKTURACE_CHANGE: [
		'chci změnit fakturační skupinu',
		'prosím o změnu fakturační skupiny',
		'změnu fakturační skupiny',
		'změníte mi fakturační skupinu',
	],
	FAKTURACE_DUE_DATE: [
		'chci svoji splatnost odložit',
		'můžu odložit svoji splatnost',
		'odložení splatnosti',
		'odložit zaplacení paušálu',
		'potřebuju odložit splatnost paušálu',
		'potřebuju odpložit splatnost',
		'prosím o odložení splatnosti',
	],
	FAKTURACE_ERROR: [
		'Mám problém s fakturou',
		'Mám problém s mojí poslední fakturou',
		'chci změnit vyúčtování',
		'dobrý den mám problém s vyúčtováním potřeboval bych nějaké informace k vyúčtování',
		'mám problém s vyúčtováním',
		'problém s fakturou',
		'problém s vyúčtováním',
		'problémy s vyúčtováním',
	],
	FAKTURACE: [
		'a pod sebou vyúčtování',
		'a potřeboval bych zúčtování',
		'chci s někým mluvit ohledně fakturace',
		'chci se zeptat na vyúčtování',
		'chci účtování',
		'chci řešit fakturu',
		'Chci řešit vyúčtování',
		'co vyúčtováním',
		'daňový doklad na fakturu',
		'detaily k vyúčtování',
		'faktura na toto číslo',
		'Faktura',
		'Fakturace',
		'fakturu potřebuju zjistit',
		'já volám kvůli vyúčtování mobilu telefon',
		'kdo dělá fakturovače',
		'mám dotazy k vyúčtování',
		'mám tu něco s fakturou',
		'mám vyúčtování',
		'pomoci potvrdím vyúčtováním',
		'potřeboval bych střelit vyúčtování',
		'potřebuji se zeptat na fakturu',
		'potřebuji řešit fakturu',
		'potřebuju odpověď kvůli faktuře',
		'potřebuju vyúčtování',
		'potřebuju vyřešit účet',
		'požádat o vystavení faktury',
		'tady to zúčtování z minulého měsíce',
		'takže účtování no',
		'ty mluvit o vyúčtování',
		'volám kvůli fakturování',
		'volám kvůli vyúčtování',
		'volám ohledně fakturace',
		'vystavit fakturu',
		'vyúčtování do',
		'vyúčtování faktury',
		'vyúčtování má uhrazenou platbou',
		'vyúčtování na paušál',
		'vyúčtování paušálu',
		'vyúčtování pevného internetu vzduchem',
		'vyúčtování služby',
		'vyúčtování tarifu',
		'vyúčtování telefonické',
		'vyúčtování vyúčtování vyúčtování',
		'vyúčtování za červen na toto telefonní číslo',
		'Vyúčtování',
		'zajímalo by mě vyúčtování',
		'zúčtování z minulého měsíce',
		'Účtenka',
	],
	FAKTURACE_HELP: [
		'chtěla bych pomoc u vyúčtování',
		'no potřebujeme pomoc se zúčtováním',
		'pomoc s vyúčtováním',
		'potřeboval bych pomoct s vyúčtováním',
		'potřebuju ohledně vyúčtování pomoci',
		'potřebuju pomoci ohledně vyúčtování',
	],
	FAKTURACE_PENALTY: [
		'chci zrušit tu pokutu protože sme se v sobotu domlouvali',
		'chtěl bych tu pokutu a stornovat',
		'chtěla bych smazat tu pokutu',
		'potřebuju smazat pokutu',
		'prosím o zrušení pokuty',
		'zrušit pokutu',
	],
	FAKTURACE_PERIOD: [
		'chci znát konec účetního období',
		'kdy končí mé účetní období',
		'kdy končí účetní období',
		'potřebuju vědět konec účetního období',
	],
	FAKTURACE_PHONE: [
		'dobrý den u telefonu ... a mně dneska přišla vyúčtování telefonu',
		'vyúčtování hovorů v paušálu',
		'vyúčtování hovorů v rámci tarifu',
		'vyúčtování paušálu za hovory',
		'vyúčtování telefonních hovorů',
		'vyúčtování telefonního čísla',
		'vyúčtování telefonu',
		'účet za hovory',
		'účet za mobil',
		'účet za paušál',
		'účet za tarif',
		'účet za telefon',
		'účet za telefonování',
	],
	FAKTURACE_TWO_BILL: [
		'chci nastavit druhou fakturu',
		'potřeboval bych nastavit dvě faktury',
		'potřebuju dvě faktury',
		'prosím o nastavení dvou faktur',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANCANNOT_ENTER: [
		'chci přijímat faktury mailem, ale nejde mi zadat',
		'nejde mi zadat mail pro přijímání faktur',
		'nemůžu zadat mail, abych přijímala faktury',
		'prosím nejde zadat email při přijímání faktur',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANCHANGE_ADDRESS: [
		'Chci jinou adresu na faktuře',
		'Chci zasílat faktury na jinou adresu',
		'Chci změnit fakturační adresu',
		'Potřebovala bych změnit adresu na vyúčtování',
		'Už tři roky mám špatnou adresu v kontaktech údajích',
		'chci aby mi chodily faktury na jiné místo',
		'chci doručovat faktury na jiné místo',
		'chci jinou adresu na vyúčtování',
		'chci nahlásit jinou fakturační adresu',
		'chci nastavit jinou fakturační adresu',
		'chci uvést jinou faktura',
		'chci změnit adresu vyúčtování',
		'chci změnit adresu, kam chodí faktura',
		'chodí mi faktury na blbou adresu',
		'chtěl bych změnit adresu, kam chodí faktury',
		'dočasné bydliště',
		'fakturační adresa se změnila',
		'jiná fakturační adresa',
		'jiné bydliště',
		'nová adresa, kam chodí faktura',
		'nová adresa, kam má chodit faktura',
		'nová fakturační adresa',
		'nové bydliště',
		'nové trvalé bydliště',
		'opravit adresu bydliště',
		'posílat faktury do nového bydliště',
		'potřebuji nastavit jinou adresu na faktuře',
		'potřebuji změnit adresu pro zasílání faktur',
		'potřebuju změnit adresu kam mi budete posílat faktury',
		'potřebuju změnit číslo přechodného pobytu',
		'přechodné bydliště',
		'změna bydliště',
		'změna fakturační adresy',
		'změnil jsem bydliště, posílejte mi faktury tam',
		'změnit bydliště',
		'změnit trvalé bydliště na faktuře',
		'číslo přechodného pobytu',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANCHANGE_FORMAT: [
		'Chci aby faktury chodily poštou',
		'Chci aby mi chodilo elektronické vyúčtování',
		'Chci aby mi faktury chodily na email.',
		'Chci aby mi faktury chodily v pdfku',
		'Chci nastavit elektronické vyúčtování',
		'Chci zasílat tištěné vyúčtování',
		'Chci zasílat vyúčtování v PDF',
		'Chci zrušit elektronické vyúčtování',
		'Nechci abyste mi poílali tištěné vyúčtování',
		'Nechci elektronické vyúčtování',
		'Nechci faktury posílat poštou',
		'Nechci faktury poštou',
		'Zaslat emailem fakturu',
		'Zasílejte mi faktury v pdfku',
		'Zašlete mi elektronické vyúčtování',
		'Zašlete mi faktury poštou',
		'Zašlete mi tištěné vyúčtování',
		'aby mi chodilo vyúčtování poštou',
		'chci aby mi vyúčtování přišlo elektronicky',
		'chci dostávat faktury elektronicky',
		'chci dostávat vyúčtování elektornicky',
		'chci faktury emailem',
		'chci vyúčtování jen elektronicky',
		'chci zasílat faktury elektronicky',
		'chci zasílat vyútování elektronicky',
		'chci, aby mi faktury chodily elektronicky',
		'chtěla bych poprosit o elektronické vyúčtování',
		'chtěla bych posílat vyúčtování poštou',
		'dobrý den chtěla bych prosím zrušit papírové vyúčtování a chtěla bych ho a posílat na mail',
		'doručit vyúčtování poštou',
		'fakturu mi pošlete pouze elektronicky',
		'faktury emailem',
		'jiný způsob doručování vyúčtování',
		'mohli byste mi místo faktury na papíře posílat fakturu do mailu',
		'nový způsob doručování vyúčtování',
		'papírové vyúčtování už nechci, už jen na e-mail',
		'poslat vyúčtování poštou',
		'posílejte mi vyúčtování elektronicky',
		'posílejte mi vyúčtování jen elektronickou formou',
		'posílejte mi vyúčtování jen na mail, papírovou formu zrušte',
		'pošlete mi vyúčtování poštou',
		'stačí mi vyúčtování v elektronické podobě',
		'stačí mne vyúčtování posílat elektronickou formu',
		'vyúčtování posílat elektronickou formou',
		'zaslat vyúčtování poštou',
		'zasílejte mi prosím vyúčtování pouze v elektronické formě',
		'změnit způsob doručování vyúčtování',
		'zrušit fakturu a zrušit písemnou fakturu',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANCHANGE_NUMBER: [
		'chci aby chodilo vyúčtování na jiné telefonní číslo',
		'chci změnit číslo, kam mi má chodit vyúčtování',
		'chtěla bych se zeptat jestli by mohlo chodit vyúčtování na moje telefonní číslo no protože chodí k manželovi',
		'mohli byste mi posílat vyúčtování na jiné telefonní číslo',
		'mohli byste posílat vyúčtování na moje telefonní číslo a ne synovo',
		'mohlo by moje vyúčtování chodit na moje číslo',
		'potřebuju změnit číslo na doručování vyúčtování',
		'potřebuju změnit číslo, kam má chodit vyúčtování',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANGENERAL: [
		'hlavička na faktuře bude jiná',
		'změna fakturačních údajů',
		'změna hlavičky na faktuře',
		'změna hlavičky ve faktuře',
		'změnit údaje na faktuře',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANNO_CHANGE_FORMAT: [
		'nic se nemění, posílejte dál faktury elektronicky',
		'prosím stále mi posílejte vyúčtování elektronicky',
		'prosím zasílejte mi faktury jako dotěď elektronicky',
		'prosím zasílejte vyúčtování písemnou formou jako dosud děkuji piperová',
		'vyúčtování posílejte jako doteď písemně',
	],
	FAKTURACNADRESA_A_ZASILANVYUCTOVANRESEND: [
		'Potřeboval bych poslat znovu fakturu',
		'Potřebuju znovu zaslat fakturu',
		'Ztratil jsem fakturu, pošlete ji znovu?',
		'můžete mi poslat fakturu ještě jednou?',
		'pes mi sežral fakturu, potřeboval bych ji zaslat znovu',
		'poslat ještě jednou složenku za poslední měsíc',
		'poslat znovu poslední složenku',
		'potřebuju poslat znova a složenku na zaplacení červnovou po telefonu',
		'potřebuju znovu poslat složenku',
		'prosím vás, nemůžu najít fakturu, pošlete mi ji znova?',
		'zaslat znova složenku',
	],
	INTERNET_VYPADAVA: [
		'asi nám tady vypadl internet',
		'chci nahlásit výpadek internetu',
		'chci se zeptat jestli je u nás odstavený internet? protože vůbec nefunguje',
		'co pět minut, tak to vypadne',
		'co to vypadává',
		'furt vypadává',
		'hlásím výpadek internetu',
		'internet chvíli jde a pak zase ne',
		'internet chvíli jde, chvíli nejde',
		'internet co mi pořád vypadává',
		'internet má asi výpadek',
		'internet vypadává a nefunguje',
		'Internet často vypadává',
		'je na naší adrese výpadek internetu?',
		'každých x minut to vypadává',
		'mám internet a nemůžu se teď připojit a aktivovat si to',
		'mám nestabilní připojení',
		'mám časté výpadky internetu',
		'máte odstávku internetu? protože mi vůbec nefunguje',
		'máte výpadek internetu?',
		'na tomhle telefonním čísle mám internet a nemůžu se teď připojit',
		'nefunguje internet, je nějaká odstávka?',
		'nefunguje internet, je nějaký výpadek?',
		'nefunguje internet, máte odstávku?',
		'nefunguje nám net, máte odstávku?',
		'nejde mi internet, máte výpadek?',
		'nejede mi internet, máte odstávku?',
		'Nestabilní internet',
		'neustále mi to vypadává',
		'neustále vypadává',
		'občas mi to z ničeho nic spadne',
		'odstavili jste u nás internet? totiž vůbec nejede',
		'padá mi net',
		'pevný internet pořád vypadává.',
		'porad vypadává',
		'stabilita internetu je fakt špatná',
		'Vypadl mi doma internet a mohli byste mi poslat někoho',
		'Vypadl nám internet',
		'vypadl u nás internet',
		'Vypadává mi internet',
		'vypadává, chvíli jde a chvíli nejde',
		'výpadek pevného internetu',
		'výpadek připojení',
		'často vypadává aspoň to chvíli de chvíli nejde',
	],
	AMOUNT: [
		'Mobilní internet 1 gigabajt',
		'Mobilní internet 1,5 giga',
		'Mobilní internet 10 GB',
		'Mobilní internet 150 MB',
		'Mobilní internet 2 gigabajt',
		'Mobilní internet 3 megabajt',
		'Mobilní internet 30 gigabajt',
		'Mobilní internet 4 mega',
		'Mobilní internet 400 MB',
		'internet na měsíc 1 gigabajt',
		'internet na měsíc 10 gigabajt',
		'internet na měsíc 2 gigabajt',
		'internet na měsíc 4 gigabajt',
		'internet na měsíc 400 megabajt',
		'internet na měsíc 5 gigabajt',
		'internet na týden 150 megabajt',
		'dokoupit 250 megabajt',
		'přikoupit jeden gigabajt',
	],
	BUY_DATA: [
		'Chci Balíček internetovej',
		'Chci další internetová data',
		'Dobít data',
		'Mobilní data koupit',
		'Mohli byste mi zapnout nějaký balíček s daty',
		'chci si koupit více data',
		'dobiji si data',
		'dokoupit data',
		'potřebuju data',
		'tak na chvilku bych potřebovala ještě doplnit data?',
		'zapnout data zlobivému puberťákovi',
		'zapnout nějaký balíček s daty, protože už jsem si ty svoje vypotřebovala',
		'Šlo by si dobít data?',
	],
	COMPLAINT: [
		'Chtěl bych se zeptat proč mám vyčerpané data na mobilním čísle když by to mělo být v danou dobu neomezené',
		'chodí mi zprávy, že jsem vyčerpal data',
		'chtěl bych se zeptat jak to že dostávám zprávy o vyčerpání dat',
		'i když mám data stále, chodí mi zprávy, že mi došly',
		'jakto že mi chodí zprávy o vyčerpání dat když mám neomezené',
		'jakto že mám vyčerpaná data, když bych je měla mít neomezené',
		'mám hodně spotřebovaných dat a nepasuje mi to',
		'mám hodně spotřebovaných dat, i když jsem je skoro nepoužila',
		'mám moc spotřebovaných dat a to je divné',
		'mám víc spotřebovaných dat, než bych měl mít',
		'neodpovídá mi hodnota spotřebovaných dat',
		'spotřebovaná data neodpovídají realitě',
	],
	DATA_LIMIT: [
		'chci se zeptat kolik mi zbývá dat internet mobil',
		'chci řešit fup',
		'dá se navýšit můj datový limit?',
		'fup',
		'jak změním svůj datový limit?',
		'jak zvýším svůj datový limit?',
		'jaký je můj datový limit?',
		'jaký je můj limit dat?',
		'jaký mám fup?',
		'jaký mám limit pro data v mobilu',
		'kolik mi ještě zbývá internetu v mobilu',
		'kolik mi zbývá internetových dat v mobilu',
		'kolik mi zbývá mobilních dat',
		'kolik mám dat',
		'kolik mám internetu v mobilu',
		'kolik mám ještě dat',
		'kolik mám ještě dat v mobilu',
		'kolik můžu čerpat dat, je nějaký limit?',
		'ukaž mi můj datový limit',
		'zbývají mi ještě nějaká internetová data v mobilu',
		'zbývá mi ještě internet v mobilu',
	],
	DATA_PRICE: [
		'Jak je u vás drahých 5 GB dat',
		'Kolik u vás stojí data na den',
		'Kolik u vás stojí jeden GB datového přenosu?',
		'Kolik u vás stojí neomezené data',
		'chci se informovat o ceně dat v mobilu',
		'chci se zeptat na cenu internetu v mobilu',
		'chtěl bych znát cenu mobilního internetu',
		'chtěli bychom vědět za kolik máte internet do mobilu',
		'jak draho vyjdou mobilní data?',
		'jak drahých je u vás 5 GB mobilních dat?',
		'jak jsou drahá data',
		'jakou máte cenu dat',
		'jakou máte cenu za internet v mobilu',
		'kolik stojí 3 GB data?',
		'kolik stojí mobilní data',
		'kolik stojí tarif HD?',
		'kolik stojí váš tarif HD',
		'kolik u vás dám za data denně',
		'mohu se zeptat, jaká je u vás cena internetu v mobilu',
		'můžete mi říct cenu internetu v mobilu',
		'na kolik korun vyjdou mobilní data?',
		'na kolik vyjde 100 MB dat?',
		'na kolik vyjde tarif SD?',
		'potřebuju vědět cenu internetu v mobilu',
		'za kolik je u vás tarif HD',
		'za kolik prodáváte datový tarif s 5 giga?',
		'za kolik si můžu pořídit neomezený datový tarif?',
		'řeknete mi prosím, jakou máte cenu pro internet v mobilu?',
	],
	GENERAL: [
		'a přišla mi sms a že mohu mít o prázdninách neomezená data tak se na to chci zeptat',
		'chci info, kolik mám dat',
		'chci něco vědět o datech',
		'chci se informovat o datech',
		'chci se zeptat na data',
		'chci se zeptat na neomezená data',
		'dobrý den já bych potřebovala pomoc s daty',
		'info o neomezených datech',
		'informace o datech',
		'kolik mám dat?',
		'potřeboval bych  neomezená data',
		'potřebuji vědět podrobnosti k neomezeným datům',
		'potřebuji zjistit, kolik mám dat',
		'potřebuju info k datům',
		'potřebuju vědět něco o datech',
		'volám ohledně neomezených dat',
		'zeptat se, kolik mám dat',
	],
	MORE_DATA: [
		'Chci vyšší data, nestačí mi.',
		'Navýšit data',
		'Potřeboval bych navýšit data pro dceru.',
		'Pořád mi ke konci měsíce nestačí data',
		'Už mi nestačí moje data. Můžete mi nabídnout jiný tarif?',
		'ano, chci navýšit datový limit',
		'chci navýšit datový limit',
		'chci navýšit limit na čísle, ze kterého volám',
		'chci víc dat do mobilu',
		'chci více dat',
		'dejte mi více dat',
		'jak dostanu více dat?',
		'jak seženu více dat?',
		'jednorázové navýšení dat',
		'jenom jednorázově navýšit data',
		'jo, navýšit jednorázově',
		'mam pod tarifem u vás telefonní číslo a potřebuju více dat v balíku',
		'navýšení datového limitu k číslu, ze kterého volám',
		'navýšení internetu',
		'navýšení internetu no super',
		'navýšit data',
		'navýšit datový limit',
		'potřebovala bych navýšit baliček dat',
		'potřebovala bych přidat data',
		'potřebuji navýšit data',
		'potřebuji více dat',
		'potřebuju navýšit svoje data',
		'připlatit mobilní internet',
		'správně, navýšit limit',
		'větší balík dat',
		'zvýšit limit pro data',
	],
	NO_DATA: [
		'Chci obnovit data',
		'Chci obnovit internet',
		'Přišla mi sms že mám vyčerpaný data co mám dělat',
		'Zase mi došly data, můžete mi je navýšit?',
		'chci data k mojí smlouvě',
		'chtěla bych obnovit internet',
		'dočerpal jsem data',
		'došla mi smska, že jsem vyčerpala data',
		'došlo mi data',
		'došly mi data',
		'došly mi data v mobilu',
		'mám nedostatek dat',
		'mám omezená data',
		'mám už málo dat',
		'nastavíte mi data u tohoto telefonního čísla',
		'nemám nějaká data, která bych mohl využít, abych se dostal na internet',
		'potřebovala bych obnovit internet',
		'potřebuji nastavit data u telefonního čísla pod mojí smlouvu',
		'potřebuju se dostat na internet ale nemám už data',
		'přisla mi sms, že mi došly data',
		'přišel mi email že mám vyčerpány data na svém mobilu a',
		'už mám vyčerpaná data',
		'vypnutá data',
		'vypnutí dat',
		'vyčerpal jsem svoje data',
	],
	SPEED: [
		'chtěl bych rychlejší přenost dat',
		'dá se zrychlit připojení',
		'jaká je nyní moje datová rychlost',
		'potřebovali bysme rychlejší přenos dát ohledně internetu',
		'prosím o rychlejší přenos dat',
		'přenos dat je moc pomalý',
		'vyšší datová rychlost',
		'zařídit rychlejší přenos dat',
		'zaříďte mi rychlejší přenos dat',
		'zrychlit data',
	],
	WHENACTIVATED: [
		'chci se zeptat, kdy mi opět zapnete data',
		'chtěla bych vědět, kdy budu mít zase zapnutá data',
		'chtěla bych vědět, kdy mi obnovíte moje data',
		'kdy budu mít znovu zapnutý datový balíček',
		'mohu se zeptat, kdy budu mít opět aktivní datové připojení',
		'potřebovala bych zjisti, kdy mi zapnete data v mobilu',
		'potřebuji vědět kdy budou zase zapnutá data tarif',
		'zajímalo by mě, kdy budu mít zase aktivovaná data',
	],
	GREETING: [
		'ahoj',
		'dobré odpoledne',
		'dobré poledne',
		'dobré ráno',
		'dobrý den',
		'dobrý večer',
		'nazdar',
		'nazdárek',
		'zdarec',
		'zdravím',
		'zdravíčko',
		'čau',
		'čus',
		'čusík',
	],
	HARDWARE_DEVICE: [
		'chci mluvit o splátkách hardwaru',
		'chci mluvit o zařízení na splátky',
		'chci se bavit o splátkách zařízení',
		'chci řešit splácení hardwaru',
		'hardware na splátky',
		'potřebuju vyřešit splácení zařízení',
		'potřebuju řešit hardware na splátky',
		'potřebuju řešit splátky zařízení',
		'zařízení na splátky',
	],
	HLASOVY_AUTOMAT_AUTOMAT: [
		'ajvíárko',
		'automat prosím',
		'automat',
		'automatický systém',
		'chci hlasový automat',
		'chci ivr',
		'chci přepojit na automat',
		'chtěla bych na ajvíárko',
		'chtěla bych přepojit na automatický systém',
		'hlasový automat',
		'IVR',
		'potřebuju ajvíárko',
		'potřebuju ivr',
		'prosím o přepojení do automatu',
		'prosím o přepojení na ivr',
		'přepojit do automatu',
		'přepojit do hlasového automatu',
		'přepojit na ajvíárko',
		'přepojit na automat',
		'přepojit na hlasový automat',
		'přepojte mě do starého hlasového automatu',
		'přepojte mě na hlasový automat',
		'tak jo hlasový automat',
		'tak mě přepojte na hlasový automat',
	],
	HLASOVY_AUTOMAT_ROBOT: [
		'chci plechovou hubu',
		'chtěla bych hlasového robota',
		'hlasový robot',
		'můžete mě přepojit na plechovou bábu',
		'potřebuju hlasového robota',
		'prosím o přepojení na plechovou bábu',
		'přepojit na plechovou hubu',
		'přepojte mě na hlasového robota',
	],
	HW_RETURN_BOUGHT: [
		'chci vrátit zařízení, které jsem si koupil',
		'chtěl bych vrátit zakoupené zařízení',
		'koupil jsem chytré auto a chci vám ho vrátit',
		'koupil jsem hadsfree a chci vám ho vrátit',
		'koupil jsem mobil a chci vám ho vrátit',
		'koupil jsem modem a chci vám ho vrátit',
		'koupil jsem set-top-box  a chci vám ho vrátit',
		'koupil jsem si od vás televizi a chci ji vrátit',
		'koupil jsem tablet a chci vám ho vrátit',
		'koupil jsem televizi a chci vám ji vrátit',
		'koupil jsem zařízení a chci vám ho vrátit',
		'koupil jsem zařízení a chci vám ho vrátit zpátky',
		'nevyhovuje mi zařízení, které jsem koupil, chci ho vrátit',
		'pořídil jsem si u vá mobil a chci ho vrátit',
		'pořídil jsem si u vás handsfree a chci ho vrátit',
		'pořídil jsem si u vás notebook a chci ho vrátit',
		'pořídil jsem si u vás sim kartu a chci ji vrátit',
		'pořídil jsem si u vás simku a chci ji vrátit',
		'pořídil jsem si u vás tablet a chci ho vrátit',
		'pořídil jsem si u vás televizi a chci ji vrátit',
		'pořídil jsem si u vás zařízení a chci ho vrátit',
		'pořízené zařízení mi nevyhovuje, chvi ho vrátit',
		'vracení zakoupeného zařízení',
		'vrácení koupené televize',
		'vrácení zakoupené televize',
		'vrátit koupenná modem',
		'vrátit koupenou televizi',
		'vrátit koupené',
		'vrátit koupené chytré auto',
		'vrátit koupené chytré hodinky',
		'vrátit koupené handsfree',
		'vrátit koupené smart watch',
		'vrátit koupené zařízení',
		'vrátit koupený chytrý náramek',
		'vrátit koupený laptop',
		'vrátit koupený mobil',
		'vrátit koupený set-top-box',
		'vrátit koupený tablet',
		'vrátit pořízené',
		'vrátit zakoupenou telku',
		'vrátit zakoupené',
		'vrátit zakoupené chytré auto',
		'vrátit zakoupené chyté hodinky',
		'vrátit zakoupené handsfree',
		'vrátit zakoupené smart watch',
		'vrátit zakoupené zařízení',
		'vrátit zakoupené zboží',
		'vrátit zakoupený chytrý náramek',
		'vrátit zakoupený laptop',
		'vrátit zakoupený mobil',
		'vrátit zakoupený modem',
		'vrátit zakoupený set-top-box',
		'vrátit zakoupený tablet',
		'vrátit, co jsem koupil',
		'vrátit, co jsem zakoupil',
	],
	HW_RETURN_COMPLAINT: [
		'chci reklamovat telefon',
		'chci reklamovat televizi',
		'chci vyreklamovat chytré auto',
		'no ta reklamace telefonu',
		'potřebuji reklamovat telefon',
		'potřebuju reklamovat chytrý náramek',
		'potřebuju vyreklamovat laptop',
		'prosím o vyreklamování set-to-boxu',
		'reklamace',
		'reklamace mobilu',
		'reklamace telefonu',
		'reklamace zařízení',
		'reklamace zboží',
		'reklamovat handsfree',
		'vyreklamovali byste mi tablet',
		'vyreklamovat notebook',
		'vyreklamujete mi modem',
	],
	HW_RETURN_GUARANTY_EXPIRED: [
		'dá se vrátit televize, které skončila záruka',
		'můžu vrátit zařízení po konci záruky?',
		'můžu vrátit zařízení po skončení záruky?',
		'můžu vrátit zařízení po záruce?',
		'můžu vrátit zařízení, ačkoliv mu skončila záruka?',
		'můžu vrátit zařízení, i když mu skončila záruka?',
		'můžu vrátit zařízení, když mu uplynula záruka',
		'můžu vrátit zařízení, když skončila platnost záruky',
		'můžu vrátit zařízení, kterému skončila záruka?',
		'můžu vrátit zařízení, přestože mu skončila záruka?',
		'prošla záruka, můžu i tak zařízení vrátit vrátit?',
		'vrátit  handsfree po konci záruky',
		'vrátit  handsfree po uplynutí záruky',
		'vrátit chytré hodinky  po uplynutí záruky',
		'vrátit chytré hodinky po konci záruky',
		'vrátit chytré hodinky po uplynutí záruky',
		'vrátit chytré hodinky po záruce',
		'vrátit chytré hodinky po záruční době',
		'vrátit chytrý náramek po konci záruky',
		'vrátit chytrý náramek po uplynutí záruky',
		'vrátit chytrý náramek po záruce',
		'vrátit chytrý náramek po záruční době',
		'vrátit handsfree po uplynutí záruky',
		'vrátit handsfree po záruce',
		'vrátit handsfree po záruční době',
		'vrátit laptop po konci záruky',
		'vrátit laptop po uplynutí záruky',
		'vrátit laptop po záruce',
		'vrátit laptop po záruční době',
		'vrátit mobil po konci záruky',
		'vrátit mobil po uplynutí záruky',
		'vrátit mobil po záruce',
		'vrátit mobil po záruční době',
		'vrátit modem po konci záruky',
		'vrátit modem po uplynutí záruky',
		'vrátit modem po záruce',
		'vrátit modem po záruční době',
		'vrátit notebook po konci záruky',
		'vrátit notebook po uplynutí záruky',
		'vrátit notebook po záruce',
		'vrátit notebook po záruční době',
		'vrátit set-top-box po konci záruky',
		'vrátit set-top-box po uplynutí záruky',
		'vrátit set-top-box po záruce',
		'vrátit set-top-box po záruční době',
		'vrátit smart watch po konci záruky',
		'vrátit smart watch po uplynutí záruky',
		'vrátit smart watch po záruce',
		'vrátit smart watch po záruční době',
		'vrátit tablet po konci záruky',
		'vrátit tablet po uplynutí záruky',
		'vrátit tablet po záruce',
		'vrátit tablet po záruční době',
		'vrátit televizi po konci záruky',
		'vrátit televizi po uplynutí záruky',
		'vrátit televizi po záruce',
		'vrátit televizi po záruční době',
		'vrátit zařízení po konci záruční doby',
		'vrátit zařízení po skončení záručení doby',
		'vrátit zařízení po uplynutí záruky',
		'vrátit zařízení po záruční době',
		'vrátit zařízení s prošlou zárukou',
		'vrátit zařízení s uplynulou zárukou',
		'vrátit zařízení, kterému uplynula záruka',
		'vrátit zařízení, kterému vypršela záruka',
		'záruka už není platná, můžu zařízení vrátit',
	],
	HW_RETURN_HOW_TO_RETURN: [
		'co mám dělat, když chci vrátit chytré hodinky',
		'co mám dělat, když chci vrátit chytrý náramek',
		'co mám dělat, když chci vrátit chyté auto',
		'co mám dělat, když chci vrátit handsfree',
		'co mám dělat, když chci vrátit modem',
		'co mám dělat, když chci vrátit set-top-box',
		'co mám dělat, když chci vrátit sim kartu',
		'co mám dělat, když chci vrátit simku',
		'co mám dělat, když chci vrátit smart watch',
		'co mám dělat, když chci vrátit telefon',
		'co mám dělat, když chci vrátit zařízení',
		'co mám udělat, když chci vrátit mobil',
		'co mám udělat, když chci vrátit tablet',
		'co mám udělat, když chci vrátit televizi',
		'co mám udělat, když chci vrátit zařízení',
		'jak funguje vrácení',
		'jak je to s vrácením  zboží',
		'jak mám postupovat, když chci vrátit zařízení',
		'jak mám vrátit zařízení',
		'jak můžu vrátit zařízení',
		'jak se dá vrátit zařízení',
		'jak se dá vrátit zboží',
		'jak to chodí při vrácení zařízení',
		'jak to chodí, když chci vrátit zařízení',
		'jak to funguje s vrácením',
		'jak to funguje s vrácením zařízení',
		'jak to mám udělat, když chci vrátit zboží',
		'jak vrátit zařízení',
		'jak vám  můžu vrátit chytré hodinky',
		'jak vám mám vrátit zařízení',
		'jak vám můžu vrátit chytré auto',
		'jak vám můžu vrátit chytrý náramek',
		'jak vám můžu vrátit hadsfree',
		'jak vám můžu vrátit mobil',
		'jak vám můžu vrátit modem',
		'jak vám můžu vrátit notebook',
		'jak vám můžu vrátit set-top-box',
		'jak vám můžu vrátit sim kartu',
		'jak vám můžu vrátit simku',
		'jak vám můžu vrátit smart watch',
		'jak vám můžu vrátit tablet',
		'jak vám můžu vrátit telefon',
		'jak vám můžu vrátit televizi',
	],
	HW_RETURN_RENTED: [
		'chci vám vrátit pronajaté zařízení',
		'chci vám vrátit zařízení, které jsem si od vás pronajal',
		'chci vám vrátit zařízení, které mi pronajímáte',
		'chci vám vrátit zařízení, které mám v pronájmu',
		'krabičku od televize mám jen pronajatou, musím ji vrátit',
		'modem od telky mám jenom pronajatý musím ho vrátit',
		'mám od vám pronajaté zařízení, chci ho vrátit',
		'pronajal jsem si u vás zařízení, chci ho vrátit',
		'set-top-box mám jenom pronajatý, musím ho vrátit',
		'vratit pronajatou satelitní televizi',
		'vrátit pronajatou satelitní telku',
		'vrátit pronajaté zařízení',
		'vrátit zařízení v pronájmu',
	],
	HW_RETURN_RENTED_NET: [
		'chci vrátit krabičku od internetu, přijedete si pro ni?',
		'chci vám vráti zařízení k internetu',
		'chci vám vrátit koncové zařízení od internetu',
		'chci vám vrátit krabičku od internetu, přijeďte si pro ni',
		'musím vám vrátit krabičku od internetu?',
		'mám od vás internet a chci vám vrátit koncové zařízení',
		'měl jsem u vás internet, musím vám vracet krabičku',
		'ruším u vás internet musím vám vrátit modem',
		'ruším u vás internet, musím vracet modem?',
	],
	HW_RETURN_UNDER_GUARANTY: [
		'chci vrátit zařízení v záruce',
		'chci vrátit zařízení, ještě mu běží záruka',
		'chci vám vrátit zařízení, ještě je v záruce',
		'chci vám vrátit zboží, pořád je v záruce',
		'mohl bych vrátit zařízení v záruce',
		'můžu vrátit zařízení v záruce',
		'můžu vrátit zařízení, dokud je v záruce?',
		'můžu vrátit zařízení, kdy mu stále platí záruční doba?',
		'můžu vrátit zařízení, když je v záruce?',
		'můžu vrátit zařízení, když mu stále běží záruční doba?',
		'můžu vrátit zařízení, když má platnou záruční dobu?',
		'můžu vrátit zboží v záruce',
		'vrátit chytré auto v záruce',
		'vrátit chytré hodinky v záruce',
		'vrátit chytrý náramek  v záruce',
		'vrátit handsfree v záruce',
		'vrátit iphone v záruce',
		'vrátit laptop v záruce',
		'vrátit mobil v záruce',
		'vrátit modem v záruce',
		'vrátit notebook v záruce',
		'vrátit set top box v záruce',
		'vrátit smart watch v záruce',
		'vrátit tablet v záruce',
		'vrátit telefon v záruce',
		'vrátit televizi v záruce',
		'vrátit telku v záruce',
		'vrátit zařízení s platnou zárukou',
		'vrátit zařízení v záruce',
	],
	HW_RETURN_WARRANTY_CARD: [
		'chci mluvit o záručním listu',
		'chci se informovat ohledně záručního listu',
		'potřeboval bych záruční list',
		'potřebuji poradit se záručním listem',
		'volám kvůli záručnímu listu',
		'záruční list',
	],
	INKASO_CANCEL: [
		'Potřebuju zrušit inkaso',
		'dobrý den, potřeboval bych zrušit inkaso',
		'už nechci inkaso',
		'už nepotřebuji sipo můžete ho zrušit',
		'zrušit inkaso',
		'zrušíte mi prosím sipo, už ho nepotřebuji',
	],
	INKASO_CHANGE_ACCOUNT_NUMBER: [
		'chci nahlásit inkaso z jiného účtu',
		'chci oznámit nové číslo účtu ke svému inkasu',
		'chci přenastavit účet u inkasa',
		'chci zmenit účet pro převod inkasa',
		'chci změnit číslo účtu k inkasu',
		'Chtěl bych přenastavit účet u inkasa',
		'chtěl bych u svého inkasa zadat nové číslo banky',
		'Chtěl bych změnit číslo účtu pro inkaso',
		'dobrý den chci nahlásit číslo bankovního účtu pro inkaso',
		'Je možné platit inkaso z jiného účtu?',
		'musím nahlásit jiný účet na inkaso',
		'mám inkaso a chci platit faktury z jiného účtu',
		'nastavení účtu u inkasa',
		'nastavení čísla účtu u inkasa',
		'oznamuju, že budu odvádět inkaso z jiného účtu',
		'potřeboval bych změnit číslo účtu u inkasa',
		'Potřebuji změnit účet, ze kterého platím inkaso',
		'přenastavení inkasního účtu',
		'změna inkasního účtu',
		'změna účtu u inkasa',
		'Změnil jsem banku, potřebuju upravit inkaso',
	],
	INKASO_GENERAL: [
		'aktivujete mi inkasni platbu',
		'chci provést inkasní platbu',
		'chci se zeptat, jestli mi můžete aktivovat inkasní platbu',
		'inkasní platba',
		'jde mi aktivovat inkaso',
		'můžu mít inkaso',
		'můžu si aktivovat inkaso',
		'no potřebuju nahlásit svolení k inkasu',
		'čau kde se zeptat jestli mně aktivujete a inkasní platbu',
	],
	INKASO_LIMIT_INKASO: [
		'asi jste přesáhli limit inkasa, co s tím?',
		'chcete více peněz než mám limit na inkasu',
		'chci navýšit inkaso',
		'chci zvýšit inkaso limit',
		'chci zvýšit limit sipo',
		'chci řešit limit u inkasa',
		'chtěl bych navýšit limit u sipa',
		'Dostala jsem SMS o překročeném inkasním limitu - mám zaplatit celou částku?',
		'Inkasní limit',
		'limit na inkasu nestačil, co teď?',
		'maximum inkasa přesažena',
		'maximální povolená částka u inkasa překročena',
		'musím si změnit limit u sipa',
		'nastavení limitu u sipa',
		'Nastavit inkaso',
		'nastavit limit inkasa',
		'navýšit limit inkasa',
		'nevešel jsem se do limitu inkasa',
		'nový limit inkasa',
		'potřebuju navýšit inkaso',
		'potřebuju vyšší limit na sipu',
		'potřebuju vyšší limit u inkasa',
		'potřebuju změnit limit u inkasa',
		'proč jste si platbu nestáhli, když jsem zvednul inkasní limit?',
		'překročen limit u inkasa',
		'překročena maximální částka u inkasa',
		'překročil jsem povolenou částku u inkasa',
		'přenastavení limitu u inkasa',
		'přenastavit limit inkasa',
		'přesáhl jsem limit inkasa',
		'přetáhl jsem inkasní limit',
		'v bance jsem si nastavil limit pro inkaso a rád bych to samé nastavil u Vás',
		'zvýšení limitu inkasa',
		'zvýšení limitu sipa',
	],
	INKASO_MORE: [
		'jde mi z účtu víc peněz než na kolik mám vyúčtování',
		'jde mi z účtu větší částka než mám inkaso',
		'strhává se mi z účtu víc peněz než mám inkaso',
		'z účtu odchází víc peněz než je vyúčtování',
	],
	INKASO_NEW: [
		'Chci si nastavit nové inkaso',
		'Moje inkaso',
		'Potřebuju nastavit inkaso',
		'nové inkaso, prosím',
		'potřebuji nové inkaso',
		'založíte mi nové inkaso',
	],
	INKASO_ORDER: [
		'nastavit trvalý příkaz',
		'potřebuju trvalý příkaz k placení',
		'trvalý příkaz k placení paušálu',
		'zřízení trvalého příkazu pro placení paušálu',
	],
	INKASO_REFUSE_INKASO: [
		'banka neakceptovala inkaso',
		'banka nevzala inkaso, co s tím mám dělat?',
		'banka si nestrhla peníze za inkaso',
		'banka zamítla inkaso',
		'Byl jsem na bance a tam mi řekli, že mám všechno správně nastavený včetně SIPO. Jak to že jste si nestrhli peníze.',
		'dostal jsem upomínku, přestože mám inkaso',
		'došla mi upomínka na placení, ale nic jste si nestrhli',
		'jakto že se tento měsíc nestrhlo inkaso',
		'jaktože neprošlo sipo',
		'jaký je problém se SIPEM?',
		'je nějaký problém s platbou přes sipo',
		'ještě jste mi nestrhli paušál od dva a dvacátého',
		'Mám nastavené sipo, ale neprošlo. Kde je problém?',
		'Nastavoval jsem si u vás inkaso a ještě se mi peníze neodečetly.',
		'nemam stržený paušál tak ti který je nastavený na dva a dvacátého',
		'nemám stržený paušál',
		'nemám stržený paušál, který je nastavený',
		'neprošlo mi inkaso za tenhle měsíc, můžete se mi na to kouknout?',
		'nepřišlel požadavek na inkaso',
		'nestrhli jste si inkaso',
		'nezažádali jste si o inkaso',
		'Problém s inkasem',
		'proč jste mi nestrhli paušál',
		'proč nefunguje sipo',
		'Přišla mi SMS o neproběhlém inkasu.',
		'přišla mi SMS o neprošlém inkasu',
		'SIPO nefunguje',
		'transakce přes inkaso neproběhla',
		'Už milionkrát jsem u vás reklamoval nefunkčnost inkasa a zase to nefunguje.',
		'už po několikáté mi neprošlo inkaso, můžu vědět proč?',
		'volám kvůli zamítnutému inkasu',
		'zamítli mi inkaso, proč jste zvýšili částku?',
		'zamítlé inkaso',
	],
	INKASO_REMINDER: [
		'mám inkaso tak proč mi přišla upomínka?',
		'mám nastavené inkaso, přesto mi přišla upomínka',
		'prošlo mi inkaso, ale dostal jsem upomínku',
		'přišla mi upomínka, i když mám inkaso',
	],
	INTERNET_PROBLEM_S_INTERNETEM_CHECK: [
		'ověření zda je číslo připojené na internet',
		'ověřte připojení čísla na net',
		'potřebovala bych zjistit, zda je číslo připojené na internet',
		'prosím o ověření, zda je číslo připojené na internet',
	],
	INTERNET_PROBLEM_S_INTERNETEM_DOWNLOADING: [
		'Mám problém se stahováním',
		'Nejde mi stahování',
		'Nejde stahovat',
		'Nemůžu nic stahovat',
		'Nemůžu stahovat',
		'Pomalé stahování',
		'Problém s rychlostí stahování',
		'Snížila se mi rychlost stahování',
		'Všechno se stahuje hrozně pomalu',
		'Čekám na stahování hodinu',
	],
	INTERNET_PROBLEM_S_INTERNETEM_HOW_CONNECT: [
		'internet tady připojit internet a potřebuji poradit jak to mám udělat',
		'jak na připojení internetu',
		'jak připojím internet',
		'jak to mám udělat, abych připojila internet',
		'potřebuju poradit, jak mám připojit internet',
	],
	INTERNET_PROBLEM_S_INTERNETEM_INTERNET_SPEED: [
		'Domácí internet je pomalý',
		'Internet je pomalý',
		'Mám mizernou rychlost internetu',
		'Mám pomalý internet',
		'Mám pomalý net',
		'Mám zasekaný internet',
		'Mám šnečí internet',
		'Mě jde hrozně pomalu internet.',
		'Pomalý internet',
		'Rychlost internetu je nízká',
		'Rychlost internetu neodpovídá',
		'Snížená rychlost internetu',
		'Zpomalil se mi internet',
		'Zpomaluje se mi domácí internet',
		'je strašně pomalej internet',
		'mám od vás internet vzduchem a zdá se mi že to mám teď pomalý',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_DATA: [
		'Data netečou',
		'Dotaz ohledně datového tarifu nejdeme to zapnout co s tím',
		'data mi nefungují',
		'data mi nejedou',
		'datová karta mi nejde',
		'internetová datová karta nefunguje',
		'jsou zablokovaný data, nevím proč.',
		'mně nefungují data v telefonu',
		'mám přerušený tok dat',
		'mám zakázaná data nevím z jakého důvodu to je můžete mi na to odpovědět',
		'nefakčí mi internetová datová karta',
		'nefunguje datová karta',
		'nefungují mi data',
		'nefungují mi data v telefonu',
		'nejde mi internet datová karta',
		'nejdou mi zapnout data',
		'nejdou mě datový provozy',
		'netečou data do telefonu',
		'nějak mi nejedou data',
		'potřeba potřebovala bych vědět o proč mi nejdou data u studentského tarifu',
		'proč mám zakázaná data',
		'z jakého důvodu mám zakázaná data',
		'zjistila jsem, že mám zakázaná data',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_FACEBOOK: [
		'Nefunguje mi facebook',
		'facebook nespolupracuje',
		'facebook se furt seká',
		'nechce se rozjet fejsbúk',
		'nejde se mi přihlásit na facebook',
		'nejede facebook',
		'nemůžu se přihlásit na facebook',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_GOOGLE: [
		'Gmail se mnou nespolupracuje',
		'Nefunguje mi Google Drive',
		'Nefunguje mi google',
		'google je rozbitý',
		'google se mnou odmítá spolupracovat',
		'gůgl nejde otevřít',
		'nejde mi otevřít google',
		'nejede google',
		'strýček google nespolupracuje',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_LOADING: [
		'Dlouho se mi načítají filmy',
		'Dlouho se mi načítají stránky',
		'Dlouho se mi načítá HBOGO',
		'Dlouho se načítá Netflix',
		'Načítá se mi dlouho YouTube',
		'Nechce se mi načíst žádná stránka',
		'Nejde mi Tinder',
		'Nejde načíst stránky',
		'Nemůžu koukat na videa',
		'Nemůžu načíst Instagram',
		'Nemůžu zobrazit Booking.com',
		'Nemůžu zobrazit Messenger',
		'Nenačtu Airbnb',
		'Nenačítají se mi žádné aplikace',
		'Nenačítá se Uber',
		'Nenačítá se Viber',
		'Nenačítá se mi Dropbox',
		'Nenačítá se mi Spotify',
		'Nenačítá se mi WhatsApp',
		'Nezobrazuje se mi Amazon',
		'Pomalu se to načítá',
		'Stránky se nenačítají',
		'Trvá mi načíst stránky',
		'nefunguje web',
		'nejde mi web',
		'nejdou stránky',
		'nenačtu web',
		'nenačítají se mi stránky',
		'nenačítá se Duolingo',
		'nenačítá se Skype',
		'nenačítá se mi web',
		'příliš dlouho se načítá Slack',
		'web se načítá strašně dlouho',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_ROUTER: [
		'Router se nemůže připojit k internetu',
		'asi mi nefunguje router, nemůžu se připojit k netu',
		'blbne router',
		'chci demontovat router',
		'chtěla bych odblokovat router',
		'mám nefunkční router',
		'nedaří se mi zprovoznit router',
		'nefunguje internet na routeru',
		'nefunguje mi router',
		'nefunguje router',
		'nejede router',
		'potřeboval bych pomoct s odblokováním routeru',
		'potřebuju odblokovat router',
		'připojení routeru k internetu selhalo',
		'router nemá internet',
		'router se mi nepřipojuje k internetu',
		'router se nechce připojit k internetu',
		'rozbitý router',
	],
	INTERNET_PROBLEM_S_INTERNETEM_SETTING: [
		'chci nastavit internet',
		'nastavené internetu',
		'poslali byste mi k nastavení netu konfigurační zprávu',
		'poslat konfigurační zprávu nastavení internetu',
		'potřebovala bych konfigurační zprávu, abych nastavila internet',
	],
	INTERNET_PROBLEM_S_INTERNETEM_WIFI: [
		'Nejde mi wifi',
		'Přestala nám fungovat wifina',
		'Seká se mi wifina',
		'Vypadla nám wifina',
		'Wifi se hodně seká',
		'Zpomalila se mi wifina',
		'byla u nás bouřka a od té doby mi nefunguje Wi-Fi',
		'nefunguje mi něco na wifi',
		'nefunguje nám wifi',
		'nejde se mi připojit na vifi',
		'neustále vypadává připojení skrze wifi',
		'někteří přátelé se nemohou připojit u nás doma na wifi ale ostatním to jde',
		'od včerejška nám nefunguje wifina',
		'pomalý wifi net',
		'pořád vypadává wifina',
		'wifi je pomalá',
		'wifi jede pomalu',
		'wifi nám přestala fungovat',
	],
	INTERNET_BROWSER: [
		'chrom mi nic nedělá',
		'edge nejede',
		'explorer nic neukazuje',
		'firefox háže chybu',
		'lištička nic nenačítá',
		'mozilla nic neukazuje',
		'nenačítá se mi browser',
		'nenačítá se mi chrome',
		'nenačítá se mi prohlížeč',
		'opera se mi rozbila, nic se nenačítá',
		'thunderbird nefunguje',
	],
	INTERNET_DSL: ['nefunguje nám DSL', 'nefunguje nám DSLko', 'padá dsl', 'vypadl nám DSL internet', 'zase nejede DSL'],
	INTERNET: [
		'aktivovat internet',
		'chci pomoct s internetem',
		'chci poradit s internetem',
		'Chci vyřešit internet',
		'chci řešit internet',
		'chci řešit svůj internet',
		'chtěl bych pomoct s internetem',
		'chtěl bych prosím vyřešit můj problém s internetem',
		'dobrý den potřebovala bych poradit jak nastavit propojení internetu',
		'dobrý den potřebuji vyřešit problém s internetovým úderem zahraničí',
		'hm ztratil jsem číslo na internet',
		'internet a mamka má',
		'internet chci vyřešit',
		'internet je můj problém',
		'internet prosím',
		'internet',
		'Internetové připojení',
		'jde už potřeboval internet v no',
		'Měl bych prosbu ohledně internetu',
		'no internet',
		'no pevný internet do zásuvky co to je',
		'no potřebuju vědět esi existuje nějaká externí wifi',
		'Něco s internetem',
		'ohledně internetu',
		'Pevný internet do zásuvky',
		'pomůžete mi s internetem',
		'poradíte mi prosím vás s internetem',
		'poradíte mi s internetem',
		'potřeboval bych nutně vyřešit internet',
		'potřeboval bych pomoct s internetem',
		'potřeboval bych vyřešit něco s internetem',
		'potřebuji informaci k východ k vysokorychlostnímu internetu',
		'potřebuji se zeptat na internet',
		'potřebuji vyřešit problém s internetem',
		'potřebuji zjistit internet u tohoto čísla',
		'potřebuju pomoct s internetem',
		'potřebuju pořešit internet',
		'potřebuju vyřešit svůj internet',
		'potřebuju řešit internetové připojení',
		'problém je u internetu',
		'pryč s internetem',
		'připojení internetu',
		's internetem',
		's připojením internetu',
		'stav internetu potřebuju řešit',
	],
	INTERNET_INTERNET_ZMENA_CISLA: [
		'Mám u vás internet, který platím cez SIPO a budu mít jiné číslo',
		'mám u vás internet a teď si měním číslo.',
		'sestra má u vás internet, měla SIPO a teď bude mít jiné číslo.',
	],
	INTERNET_LANDLINE: [
		'internet přes pevné připojení nefunguje',
		'mám internet dotažený kabelem a nejede mi internet',
		'mám problém s hraním her přes pevnou linku, mám vysoký ping',
		'mám problém s připojením přes kabel',
		'mám rozbité připojení a mám internet přes kabel',
		'nemůžu se přes kabel připojit',
		'problém s pevným připojením',
		'přes kabel mi nechce jet internet',
	],
	INTERNET_MOBILNY: [
		'chci internet v mobilu',
		'chtěl bych mobilní internet',
		'data',
		'internet mobil doma',
		'internet přes mobil',
		'internet přes telefon',
		'mobilní internet prosím',
		'mám net v telefonu',
		'potřebuju internet do mobilu',
		's mobilním internetem',
		's mobilním',
		's přenosným',
	],
	INTERNET_MOBIL_INTERNET_DISCONNECT: [
		'Vypnout mobil z internetu',
		'mobil odpojit od internetu',
		'vypnout internet v mobilu',
	],
	INTERNET_OPTIC_INTERNET: [
		'Prej máme optiku internet',
		'Technik říkal, že máme i optiku',
		'máme od vás internet doma a když přjel technik říka prej máme i optiku',
		'optický internet',
		'optika internet prosím',
		'potřebuju optické připojení na internet',
	],
	INTERNET_SEARCH_ENGINE: [
		'na seznamu mi nejde vyhledávání',
		'nefunguje bing',
		'nefunguje mi google',
		'nefunguje mi vyhledávání',
		'nefunguje seznam',
		'nejde mi bing',
		'nejde mi seznam',
		'nejede strýček google',
		'nemůžu vyhledávat v googlu',
		'nezobrazuje se mi bing',
	],
	INTERNET_SLOW: [
		'hrozně pomalej internet',
		'internet je strašně pomalý',
		'malá rychlost netu',
		'mám pomalý net',
		'pomalá rychlost internetu',
		'pomalý internet',
	],
	INTERNET_TECHNIK_INTERNET: [
		'Potřebuji se domluvit na termínu pro technika na internet.',
		'bohužel jsme se nedostali do místa, kam technik potřeboval aby zpravil internet. Potřebuji se domluvit na novém termínu.',
		'dá se tu dohodnout nový termín pro příjezd technika?',
		'internet technik',
		'kdy dojde od vás ten technik na internet?',
		'technik k internetu',
		'víte mi říct termín na technika pro internet?',
	],
	JINE_BUY: [
		'koupit televizi',
		'koupě televize',
		'mám zájem o pořízení televize',
		'nákup televize',
		'objednat televizi',
		'pořídit televizi',
		'zakoupení televize',
		'řeším objednání nové televize',
	],
	NECO_JINE: [
		'ani jedno z nabízených',
		'Ani jedno z vyjmenovaných, nastavit něco jiného',
		'Chci nastavit jinou věc',
		'chci nastavit jiné zařízení',
		'chci nastavit něco jiného',
		'chci něco jiného',
		'chci řešit další věci',
		'Chci řešit jiné téma',
		'chci řešit něco jiného',
		'chci řešit ostatní věci',
		'další možnosti',
		'další nabídka',
		'další věc',
		'další',
		'Jinou věc',
		'jiná věc mě zajímá',
		'jiné možnosti',
		'Jiné téma',
		'Jiné zařízení',
		'jiné',
		'jiného',
		'jsem tu kvůli jiné záležitosti',
		'mluvím o něčem jiném',
		'myslela jsem na jiný problém',
		'mám jiný dotaz',
		'mám jiný problém',
		'mě zajímá jiné téma',
		'nastavit jiný produkt',
		'ne, kvůli úplně jiné věci',
		'nechci nastavit nic z toho',
		'Nechci nic z uvedených témat',
		'Netrefila jste se, chci nastavit jiné zařízení',
		'netrefili jste se, něco jiného',
		'nevybral jsem si z nabídky',
		'nezajímám se',
		'nic z toho',
		'nic z uvedeného nechci řešit',
		'něco dalšího',
		'něco jiné',
		'něco jiného mě zajímá',
		'něco jiného prosím',
		'něco jiného',
		'něco jinýho',
		'ostatní témata',
		'pomoct s něčím jiným',
		'potřebuji něco dalšího',
		'potřebuju jinou věc',
		'Potřebuju nastavit něco jiného',
		'potřebuju něco jiného',
		'potřebuju poradit s něčím jiným',
		'potřebuju radu ohledně něčeho jiného',
		'Potřebuju vyřešit něco jiného',
		'potřebuju vědět něco jiného',
		'potřebuju řešit něco ještě trošku jiného',
		'ptám se na něco jiného',
		'píšu kvůli úplně jiné věci',
		'to nesedí, chci řešit něco jiného',
		'Tohle ne, něco jiného',
		'volám kvůli jinému problému',
		'volám kvůli něčemu jinému',
		'volám kvůli něčemu úplně jinému',
		'vyberu si jiné téma',
		'zajímám se o jinou věc',
		'zajímám se o něco jiného',
		'řeším něco jiného',
		'žádné z uvedených',
	],
	KONTAKT_BOOK_APPOINTMENT: [
		'chci si zarezervovat schůzku',
		'chtěla bych se objednat na pobočku, abych nemusela čekat frontu',
		'jde se objednat na pobočku',
		'mohu se objednat na pobočku',
		'můžu si domluvit schůzku na pobočce',
	],
	KONTAKT_CALL_BACK: [
		'já bych potřeboval jestli by se mi nikdo neozval prosím na toto číslo až bude čas',
		'ozvěte se mi na toto číslo',
		'prosím zavolejte mi na na toto číslo až budete mít čas',
		'prosím zavolejte na číslo 6 3 4 4 8 9 2 1 děkuji',
		'zavolal by mi někdo na toto číslo',
	],
	KONTAKT_CONTACT_OPERATOR: [
		'chci mluvit s paní jako minule',
		'chci mluvit s pánem jako minule',
		'chci mluvit se slečnou',
		'chci přepojit na asistenta paní',
		'chci přepojit na operátora pana',
		'chci přepojit na technickou podporu',
		'chci telefonní číslo na pana',
		'chci vás poprosit o kontakt na asistenta',
		'chci vás poprosit o kontakt na kolegu',
		'chci vás poprosit o kontakt na operátora',
		'chci vás požádat o kontakt na kolegyni',
		'chtěla bych paní, co se mnou zaobírala minule',
		'dobrý den já bych potřebovala přepojit na slečnu která mi volala na toto číslo před asi sedma minutama děkuji',
		'dáte mi telefonní číslo na',
		'já chci pana',
		'já chci paní',
		'já chci paní svobodovou se se mnou zaobírala',
		'kontakt na asistenta',
		'kontakt na asistentku',
		'minula jsem mluvil s',
		'minule jsem hovořila s vaším kolegou',
		'minule jsem mluvila s vašim kolegou',
		'minule jsem se bavil s vaším kolegou',
		'naposled mi radil váš kolega',
		'posledně jsem konzultoval s vaším kolegou',
		'potřebovala bych se domluvit s panem šampalíkem',
		'potřebovali bysme spojit s operátorkou která teďka s náma řešilo internet',
		'potřebuju hovořit s',
		'potřebuju kontakt na',
		'potřebuju mluvit s',
		'prosím o kontakt na pana',
		'prosím o kontakt na paní',
		'prosím o spojení na pana vladimíra pěničku',
		'pán co se mnou zaobíral',
		'ty kontakt s asistentem',
	],
	KONTAKT_DEPARTMENT: [
		'chtěla bych telefonní číslo na oddelení stížností',
		'dejte mi kontakt na finanční oddělení',
		'hledám telefon na reklamní oddělení',
		'kde najdu kontakt na reklamační oddělení',
		'kde seženu kontakt na oddělení stížností',
		'potřebuju email na tiskové oddělení',
		'potřebuju kontakt na marketingové oddělení',
		'potřebuju kontakt na obchodní oddělení',
		'potřebuju kontakt na oddělení styku s veřejností',
		'potřebuju kontakt na technologické oddělení',
		'potřebuju telefon na retailové oddělení',
		'potřebuju číslo na oddělení služeb',
		'sháním kontakt na oddělení péče o zákazníky',
	],
	KONTAKT_EXISTING_PLACE: [
		'Benešov',
		'Beroun',
		'Blansko',
		'Boskovice',
		'Brandýs nad Labem',
		'Brno',
		'Brno OC Campus',
		'Bruntál',
		'Břeclav',
		'Cheb',
		'Chomutov',
		'Chrudim',
		'Domažlice',
		'Dvůr Králové nad Labem',
		'Děčín',
		'Frýdek-Místek',
		'Havl.Brod',
		'Havířov',
		'Hodonín',
		'Hradec Králové',
		'Hranice',
		'Jablonec nad Nisou',
		'Jeseník',
		'Jihlava',
		'Jindřichův Hradec',
		'Jičín',
		'Kadaň',
		'Karlovy Vary',
		'Karviná',
		'Kladno',
		'Klatovy',
		'Kolín',
		'Kopřivnice',
		'Kralupy nad Vltavou',
		'Krnov',
		'Kroměříž',
		'Kutná Hora',
		'Kyjov',
		'Lanškroun',
		'Liberec',
		'Litomyšl',
		'Litoměřice',
		'Louny',
		'Mariánské Lázně',
		'Mladá Boleslav',
		'Moravská Třebová',
		'Most',
		'Mělník',
		'Nový Jičín',
		'Nymburk',
		'Náchod',
		'Olomouc',
		'Opava',
		'Orlová',
		'Ostrava',
		'Pardubice',
		'Pelhřimov',
		'Plzeň',
		'Poděbrady',
		'Polička',
		'Prachatice',
		'Praha',
		'Prostějov',
		'Písek',
		'Přerov',
		'Příbram',
		'Rakovník',
		'Rokycany',
		'Rychnov nad Kněžnou',
		'Sokolov',
		'Strakonice',
		'Svitavy',
		'Tachov',
		'Teplice',
		'Trutnov',
		'Turnov',
		'Tábor',
		'Třebíč',
		'Třinec',
		'Uherské Hradiště',
		'Uherský Brod',
		'Uničov',
		'Valašské Meziříčí',
		'Velké Meziříčí',
		'Vrchlabí',
		'Vsetín',
		'Vysoké Mýto',
		'Vyškov',
		'Zlín',
		'Znojmo',
		'Zábřeh',
		'Ústí nad Labem',
		'Ústí nad Orlicí',
		'Česká Lípa',
		'České Budějovice',
		'Český Těšín',
		'Čáslav',
		'Říčany',
		'Šumperk',
		'Žamberk',
		'Žatec',
		'Žďár nad Sázavou',
	],
	KONTAKT_LANGUAGE: [
		'domluvím se v obchodě i jiným jazykem než čeština?',
		'kde najdu německou jazykovou mutaci webu?',
		'máte i anglickou verzi webových stránek?',
		'neumím moc dobře česky, domluvím se na prodejně i rusky?',
		'přítel neumí česky, domluvíme se i anglicky?',
	],
	KONTAKT_NOT_CALLED_BACK: [
		'Byl jsem domluvený, že mi zavoláte, ale čekám marně',
		'Váš kolega slíbil, že se mi ozve zpátky, ale už mi nezavolal',
		'co mi třeba zavolat zpátky, když to slíbíte?',
		'když slíbíte, že zavoláte zpět, tak zavolejte',
		'měli jste mi volat zpět, ale nevolali',
		'měli jste mi volat zpět, ale nikdo se neozval',
		'měli jste mi zavolat zpátky s informacemi, ale nikdo se mi neozval',
		'příště byste se mi mohli ozvat zpátky, když to slíbíte',
		'slíbili jste, že se ozvete zpátky a nic',
		'řekl jste, že mi hned zavoláte zpátky, ale neučinil jste tak',
	],
	KONTAKT_ROZTYLY: [
		'chci telefon na Roztyly',
		'chci zavolat do Roztyl',
		'jak se dostanu na pobočku v Roztylech',
		'jaká je adresa pobočky na Roztylech',
		'potřebuju kontakt na Roztyly',
		'potřebuju kontakt na pobočku v Roztylech',
		'potřebuju číslo na Roztyly',
	],
	KONTAKT_STORE: [
		'chci se spojit s pobočkou mobilem',
		'chci se spojit skrz mobil s pobočkou',
		'dovolat se na pobočku',
		'e-mail na pobočku',
		'kontakt na pobočku',
		'kontaktovat pobočku',
		'mluvit s někým na pobočce',
		'můžete mě přepojit na prodejnu sokolov',
		'potřebuju domluvit setkání na pobočce',
		'potřebuju kontakt na prodejnu',
		'potřebuju přepojit apollo na pobočku v české lípě',
		'potřebuju se spojit s prodejnou',
		'potřebuju se spojit skrz mobil',
		'potřebuju telefon na prodejnu šopinpark',
		'potřebuju zavolat na prodejnu',
		'potřebuju číslo na prodejnu',
		'protřebuju mobil na prodejnu',
		'přepojit na pobočku',
		'přepojit na prodejnu',
		'přesměrovat na konkrétní pobočku',
		'spojit se s pobočkou',
		'tak funguje vaše pobočka na národní třídě',
		'telefon na pobočku',
		'číslo na pobočku',
	],
	KONTAKT_STORE_OPEN: [
		'chtěla bych se zeptat zda máte otevřeno třeba ve valašském meziříčí',
		'do kolika mají otevřeno na pobočce v ústí',
		'dobrý den chtěla jsem se zeptat je otevřená prodejna moravské třebové',
		'dobrý den máte na pobočce na chodově zítra otevřeno',
		'dobrý den prosím tě prodejnu sokolov je otevřeno',
		'jakou mají otevírací dobu na pobočce',
		'je na pobočce otevřeno',
		'je otevřeno',
		'je otevřená pobočka v hostivaři',
		'je pobočka otevřená',
		'je vaše pobočka otevřená',
		'kdy mají otevřeno',
		'mají otevřeno',
		'má pobočka otevřeno',
		'no já se jenom zeptat a kdy v jablonci maj otevřeno já potřebuju zaplatit vyucovani',
		'otvírací doba na pobočka',
		'potřebuji znát otevírací dobu kamenné prodejny v havířově',
		'prosím do kolika mají otevříno v pobočce týmu v ústí nad labem děkuji',
		' pobočka je otevřená nebo zavřená',
	],
	KONTAKT_TMISSEDCALL: [
		'SMS o zmeškaném hovoru',
		'a měl sem mu vod vás nepřijatý hovor tak se chcu zeptat vo co de',
		'a proč jste mi volali',
		'dostala jsem smsku o zmeškaném hovoru',
		'dostala jsem smsku o zmeškaném hovoru od vás',
		'mám nepřijatý hovor od vaší firmy',
		'mám od vás nepřijatý hovor',
		'mám od vás zmeškaný hovor',
		'mám zameškaný hovor od operátora',
		'mám zmeškaný hovor',
		'mám zmeškaný hovor od vaší firmy',
		'měla jsem zmeškaný hovor',
		'nechci nic, to vy jste volali',
		'nestihl jsem hovor od vás z vaší firmy',
		'nestihl jsem hovor, volám vám zpátky',
		'nestihl jsem vzít telefon, tak vám volám zpět',
		'nic nechci ale vy jste mi volali',
		'no já mám vod vás dva zmeškaný telefon je tak nevim co se děje',
		'někdo od vás mi volal',
		'volal mi někdo z vaší firmy',
		'vy jste mi volali',
		'vy jste mi volali nevím co chcete',
		'vy jste mně volali a já nevím co chcete',
	],
	KONTROLA_ZAPLACENA_PLATEB_NA_CESTE_DLUH: [
		'chci vědět jestli dlužím',
		'chci zkontrolovat, jestli něco nedlužím',
		'dlužím vám něco',
		'evidujete u mě nějaký dluh?',
		'mám tam nějaký nedoplatek?',
		'mám u vás něco nezaplaceného?',
		'mám u vás nějaký dluh?',
		'potřebuji zjistit, jestli tam mám nějaký nedoplatek',
	],
	KONTROLA_ZAPLACENA_PLATEB_NA_CESTE_GENERAL: [
		'Kontrola plateb',
		'Peníze na cestě',
		'jsou už moje peníze na cestě?',
		'kontrola placení',
		'mám uhrazené vyúčtování?',
		'nemůžu najít svoji platbu',
		'platil jsem, ale svoji platbu nevidím',
		'potřebuji zkontrolovat své platby',
		'zkontrolovat placení',
	],
	KONTROLA_ZAPLACENA_PLATEB_NA_CESTE_KONTROLA: [
		'chci vědět jestli mám zaplaceno',
		'dorazila vám moje platba?',
		'dorazily moje peníze',
		'došla k vám platba, co jsem dělal?',
		'došly vám peníze, co jsem posílal?',
		'evidujete mojí platbu?',
		'evidujete peníze co jsem vám poslal?',
		'mám už zaplaceno, došly už peníze?',
		'přišla moje platba?',
	],
	CHCE_PLATIT_KARTOU_ONLINE: [
		'chci platit kartou',
		'chci platit kartou přes internet',
		'dá se platit master card?',
		'dá se za tarify platit kartou?',
		'lze platit na webu',
		'lze platit přes platební bránu?',
		'můžu platit visa kartou?',
		'můžu u vás platit kartou?',
		'placení kartou na webu',
	],
	KOUPIT_INTERNET_GENERAL: [
		'Chceme si sjednat internet',
		'Chci internet domu',
		'Chci u vás objednat internet',
		'Chtěl bych k nám domu sjednat internet',
		'Chtěl bych od vás pevné připojení internetu',
		'Chtěli bychom si pořídit internet',
		'Chtěli bychom si u vás zařídit internet domu',
		'Koupit internet',
		'Líbila se nám vaše nabídka internetu a chtěli bychom si ho sjednat',
		'Máme zájem o váš internet domu',
		'Měl bych zájem si u vás pořídit internet',
		'Nakoupit internet',
		'Objednat internet',
		'Pořídit internet',
		'nabídku internetových služeb',
		'rád bych od vás pevné připojení',
	],
	KOUPIT_INTERNET_RECOVERY: [
		'potřebuju obnovit smlouvu na internet',
		'potřebuju prodloužit smlouvu na internet, co mi skončila',
		'prosím o prodloužení smlouvy na internet',
		'skončila mi smlouva na internet',
		'skončila mi smlouva na internet, chci ji prodloužit',
		'skončila mi smlouva na internet, prosím obnovit',
	],
	KOUPIT_ZARIZENBUY_NEW: [
		'chci si koupit nové zařízení',
		'jedná se o koupi mobilního telefonu',
		'jedná se o koupi telefonu',
		'jedná se o koupi zařízení',
		'koupi toho zařízení',
		'koupit zařízení',
		'mám zájem o nové zařízení',
		'o koupi zařízení',
		'pořídit nové zařízení',
		'rád bych zakoupil nové zařízení',
		'zajímám se o koupi zařízení',
	],
	FIRST_CHOICE: [
		'chci to první',
		'možnost 1',
		'možnost jedna',
		'možnost číslo jedna',
		'první možnost',
		'první variant',
		'první varianta',
		'první zmíněná možnost',
		'to 1.',
		'to první zmíněné',
		'to první',
		'to první, jak jste říkala',
		'tu první volbu',
		'tu první věc',
		'varianta číslo jedna',
		'zaprvé',
		'řeším to první',
	],
	LAN_GENERAL: ['LAN kabel', 'kabelem', 'káblíkem', 'pomocí kabelu', 'přes ethernet kabel', 'přes kabel', 'přes šnůru'],
	LAST_CHOICE: [
		'chci poslední variantu',
		'co jste uvedl na konci',
		'možnost na konci',
		'možnost, co jste říkal naposled',
		'no to poslední',
		'poslední možnost na konci',
		'poslední možnost',
		'poslední varianta',
		'poslední volbu',
		'ta možnost na konci',
		'ta poslední možnost',
		'ta poslední varianta',
		'to co bylo řečeno na konci',
		'to co jste říkal naposled',
		'to poslední',
		'to poslední, jak jste říkala',
		'to úplně poslední',
		'varianta co byla poslední',
	],
	LEASING_AMERICAN: [
		'a co americký leasing',
		'americký typ leasingu',
		'jak to máte s americkým leasingem',
		'je možná americká varianta leasing',
		'můžu si u vás sjednat americký leasing',
		'nabízíte i americký leasing',
		'ohledně amerického leasingu',
		'poskytujete americký leasing',
		'převést na americký leasing',
		'umožňujete taky americký leasing',
		'vybral by si americký leasing',
	],
	LEASING_COST: [
		'cena leasingu',
		'cenová nabídka leasingu',
		'ceník leasingu',
		'chci se zeptat na cenu leasingu',
		'jak máte vysoký leasing',
		'kolik měsíčně stojí leasing',
		'kolik stojí leasing',
		'máte drahý leasing',
		'máte levný leasing',
		'měsíční částka za leasing',
		'najít cenu za operativní leasing',
		'ta kolik máte leasing',
		'výše leasingu',
		'zajímá mě cena leasingu',
	],
	LEASING_GENERAL: [
		'auto na leasing',
		'chci auto na operák',
		'chci se zeptat na leasing',
		'chci řešit leasing',
		'chtěl bych vyřídit operativní leasing na opel',
		'chtěl bych znát detaily o nabízeném leasingu',
		'dáte mi operativní leasing na škodovku',
		'informace k operáku',
		'jaký leasing nabízíte',
		'leasing na volkswagen',
		'leasingová smlouva',
		'mám zájem o operativní leasing',
		'můžu si u vás vyřídit leasing',
		'nabídka leasingu',
		'opearativní leasing na dacii',
		'operativní leasing na kiu',
		'operativní leasing na volvo',
		'operativním leasingem na audi',
		'podrobnosti ohledně leasingu',
		'poskytujete leasing',
		'potřebovala bych pořešit operativní leasing',
		'prosím o operák',
		'vyřídit si operativní leasing',
		'vůz na leasing',
		'zajímají mě informace o leasingu',
		'zajímám se o operativní leasing',
	],
	MEZINARODNSLUZBY_CALL: [
		'Chtěl bych začít volat na zahraniční čísla',
		'Chtěla bych si nastavit volání do zahraničí',
		'Informace o mezinárodních volání',
		'Informace o mezinárodním volání',
		'Kolik platím hovor a SMS na Slovensko?',
		'Mezinárodní hovory do Řecka',
		'Mezinárodní volaní',
		'Můj manžel bude v zahraničí a chtěla bych si aktivovat volání do zemí EU',
		'Potřebovala bych povolit volání na číslo v zahraničí',
		'Teď jsem přijel na dovolenou a nejdou mi na mém čísle hovory. Kouknete se mi na to?',
		'Volání do EU',
		'Volání do Německa',
		'Volání do zahraničí',
		'Volání na Slovensko',
		'Volání na zahraniční číslo',
		'Volání v EU',
		'Volání v zahraničí',
		'Volání ze zahraničí',
		'chci informace o volání do zahraničí',
		'dobrý den prosím vás volání z chorvatska do české republiky u mojeho telefonního čísla platí nebo neplatí',
		'jak je to s voláním do Polska',
		'jedu do zahraničí a chci se ujistit jestli budu schopný volat z ciziny',
		'jsem v turecku a nemůžu se nikam dovolat',
		'kolik stojí mezinárodní hovor?',
		'můžu volat do Rakouska?',
		'nefunguje mi volání do zahraničí, mám to aktivní?',
		'nemůžu se dovolat dceři na Slovensko',
		'potřebuji volat na německé telefonní číslo, jaká je cena hovoru?',
		'potřebuju nastavit volání ze švýcarska',
		'rád bych věděl kolik mě bude stát hovor na slovensko.',
		'šlo by mi odblokovat telefonní číslo na slovensku abych se mohl dovolat na slovenský telefonní číslo',
	],
	MEZINARODNSLUZBY_CALL_COST: [
		'Roamingové ceny',
		'cena hovoru v cizině',
		'cena hovoru v jiném státě',
		'cena hovoru v roamingu',
		'cena hovoru v zahraničí',
		'cena hovoru za hranicemi',
		'cena mezinárodního hovoru',
		'cena mezinárodního volání',
		'cena volání z cizina',
		'cena volání ze zahraničí',
		'jsem na slovensku a rád bych věděl kolik mě stojí hovor.',
		'kolik budu plati za hovory ze zahraničí domů',
		'kolik budu platit za hovory z egypta',
		'kolik mě bude stát hovor z německa',
		'kolik mě bude stát hovor ze zahraničí domů',
		'kolik mě bude stát volání z ciziny domů',
		'kolik stojí roamingový hovor na slovensku?',
		'kolik stojí volání v zemích EU',
		'kolik stojí volání z ciziny',
		'kolik stojí volání za hranicemi',
		'kolik stojí volání ze zahraničí',
		'kolik zaplatím za volání v zemích EU',
		'kolik zaplatím za volání z ciziny',
		'kolik zaplatím za volání za hranicemi',
		'kolik zaplatím za volání ze zahraničí',
		'syn je v zahraničí, kolik ho stojí hovor?',
		'za kolik Kč za minutu budu volat do Thajska?',
		'za kolik volám ze zahraničí domů',
	],
	MEZINARODNSLUZBY_GENERAL: [
		'Data v roamingu',
		'Jsem v cizině a nefungují mi data, co mám dělat',
		'Jsem v zahraničí a potřebuji si zapnout roaming',
		'Pojedu do roamingové zóny',
		'Potřebuji pro svého syna aktivovat roaming na 2 týdny',
		'Roaming',
		'Volání v roamingu',
		'budu v zahraničí, budou mi tam fungovat služby',
		'chci deaktivovat roaming na tomto čísle',
		'chci vypnout roaming',
		'datový roaming',
		'jak poznám že mám aktivní roaming?',
		'jak to máme s roamingem do zahraničí',
		'jedu na dovolenou, mám na čísle vše nastavené abych tam fungoval?',
		'mám můj syn aktivní roaming?',
		'plánuju cestu do zahraničí',
		'pojedu na dovolenou do ciziny, bude mi tam fungovat mobil',
		'přišla mi v Německu smska, že budu platit 10 centů za každý MB.',
		'služby do zahraničí',
		'tarify v cizině',
		'tarify zahraničí',
	],
	MEZINARODNSLUZBY_ROAMING_INTERNET: [
		'Bude mi fungovat internet v zahraničí',
		'Internet v EU',
		'Internet v zahraničí',
		'Jak si můžu nastavit, aby mi fungoval internet na dovolené?',
		'Jsem na dovolené a nepřipojuje se mi telefon k internetu. Koukne se mi na to?',
		'dobrý den můžu používat neomezený data v chorvatsku',
		'dostanu se na internet na dovolené',
		'dostanu se na internet v cizině',
		'dostanu se na internet v cizí zemi',
		'dostanu se na internet v cizím státě',
		'dostanu se na internet v zahraničí',
		'dostanu se na internet v zemích EU',
		'dostanu se na internet za hranicemi',
		'internet na dovolené',
		'internet v cizině',
		'internet v telefonu na dovolené',
		'jak s neomezenými daty v cizině',
		'jaká je cena mobilních dat v Chorvatsku?',
		'mobilní data v cizině',
		'mobilní data v cizím státě',
		'mobilní data v zahraničí',
		'mobilní data v zemích EU',
		'mohu v zahraničí využívat neomezená data',
		'můžete mi nastavit internet do telefonu na dovolenou v zahraničí',
		'nechce se mi na dovolené připojit telefon k internetu',
	],
	MEZINARODNSLUZBY_SMS: [
		'Chci aktivovat zasílání SMS a volání do zahraničí',
		'MMS do ciziny',
		'MMS v roamingu',
		'MMS z Německa',
		'MMS ze zahraničí',
		'Mezinárodní SMS',
		'Odesílání MMS do zahraničí',
		'SMS do EU',
		'SMS do ciziny',
		'SMS do zahraničí',
		'SMS na zahraniční číslo',
		'SMS v EU',
		'SMS v cizině',
		'SMS v roamingu',
		'SMS v zahraničí',
		'SMS z Chorvatska',
		'SMS z Řecka',
		'chci psát sms na zahraniční čísla',
		'chci psát ze zahraničí domů',
		'chci psát zprávy ze zahraničí',
		'jsme v egyptě, kolik mě stojí smska domů?',
		'kolik stojí smska domů z ciziny',
		'mmsky do zahraničí',
		'mohu posílat zprávy ze zahraničí?',
		'mám povolené zprávy ze zahraničí',
		'posílat sms z dovolené',
		'potřebuju aby mi fungovaly smsky na čísla v zahraničí',
		'potřebuju, aby mi fungovaly zprávy ze zahraničí',
		'psát sms z ciziny',
		'zahraniční MMSky',
	],
	MOBILNINTERNET_MOBILE_INTERNET_NOT_WORKING: [
		'blbne mi internet v mobilu',
		'jakto že nejde internet v mobilu',
		'jsem odpojený od mobilního internetu',
		'jsem v telefonu něco zmáčkla a nemůžu se připojit na mobilní data',
		'mobilní internet mi každou chvíli nejede',
		'mobilní internet vůbec nefunguje',
		'moje připojení i internetu v mobilu vypadává',
		'Mám nestabilní mobilní data',
		'mám potíže s přenosným netem',
		'mám problém s připojením telefonu k síti',
		'mám výpadky mobilního internetu',
		'můj mobilní internet je nestabilní',
		'můj mobilní internet často vypadává',
		'Nedaří se mi připojit k mobilnímu internetu',
		'nedokážu se dostat na internet v mobilu',
		'nefunguje mi mobilní internet',
		'nefunguje net v mobilu',
		'Nefungují mi data a nevím z jakého důvodu.',
		'Nefungují mi data na mobilu',
		'Nefungují mi data',
		'nejde internet v mobilu',
		'nejde mi internet do mobilu',
		'nejde mi mimo domov bezdrátový internet',
		'Nejde mi na na telefonu internet. Pomůžete mi s tím?',
		'nejde mi tohle telefonní číslo připojit k síti',
		'nejede mi net v mobilu',
		'nejedou mi data',
		'nemůžu jít na net z mobilu',
		'nemůžu se dostat na internet přes mobil',
		'Nemůžu se dostat na mobilní data',
		'nemůžu se mimo domov připojit mobilem na bezdrátový internet',
		'nemůžu se připojit k internetu v mobilu',
		'net v mobilu nefachá',
		'pokazil se mobilní internet',
		'potíže s internetem v mobilu',
		'potřebuju odstranit problém s mobilním internetem',
		'potřebuju opravit svůj mobilní internet',
		'problém s internetem v mobilu',
		'proč mi nejde mimo domov bezdrátová',
		'proč mi nejde mobilní internet',
		'proč se nechce telefon připojit k síti',
		'přestal fungovat mobilní internet',
		'přestal jít internet v mobilu',
		'přestaly mi jít internet data v mobilu',
		'vypadává mi internet v mobilu',
	],
	MODEM_CHANGE: [
		'Chtěl bych vyměnit modem za novější',
		'Potřebuju vyměnit modem',
		'chci nový router',
		'chci novější modem',
		'chci vyměnit modem',
		'chci výměnu modemu',
		'potřebuji zařídit výměnu modemu',
		'potřebuju vyměnit router',
		'chci dostat nový modem',
		'chtěl bych nový modem',
		'co musím udělat abych dostal nový modem?',
		'mohl bych dostat novější modem?',
		'potřeboval bych nový modem',
		'vyměnili byste mi modem',
		'šlo by získat nový modem',
		'vyměnit router',
	],
	MODEM_CONNECT_MODEM: [
		'Nastavit Modem',
		'Připojit modem',
		'S tím zapojením',
		'Zapojit modem',
		'chci pomoci se zapojením modemu',
		'jak si zapojím modem',
		'no asi s připojením modemu',
		'pomoct se zapojením',
		's tím zapojením modemu prosím',
		'se zapojením modemu',
		'to zapojení',
		'zapojení modemu',
	],
	MODEM_MODEM_BOX: [
		'do které díry mám zapojit kabel od krabičky',
		'do kterého konektoru patří kabely od kabičky',
		'jak se zapojuje ta krabička od modemu',
		'jak správně zapojit kabely do krabičky',
		'mám tu krabičku a nevím, kam to strčit',
		'neumím zapojit krabičku od modemu',
		'nevím, jak zapojit kabely do krabičky',
		'potřebuji pomoc se zapojením krabičky',
		'přišla mi poštou krabička a nevím, jak ji zapojit',
		'strkám kabely do krabičky',
	],
	MODEM_SETTING: [
		'Chci nastavit modem',
		'Chci pomoct s nastavením modemu',
		'Chtěl bych resetovat nastavení modemu',
		'Dneska mi kurýr dovezl modem k pevnému internetu, jak ho mám nastavit?',
		'Dneska mi od vás přišel modem a potřebuju pomoct ho nastavit',
		'Jak se nastavuje ten modem od vás k pevnému internetu?',
		'Můžete mi pomoct s nastavením modemu?',
		'Nastavit modem',
		'Nemůžu nastavit nový modem, který jsem obdržel',
		'Neumím sám nastavit ten váš modem, můžete mi s tím pomoci?',
		'Potřebuju nakonfigurovat modem',
		'Potřebuju pomoct s konfigurací modemu, který jste mi poslali',
		'Přišel mi modem k pevnému internetu a neumím ho nastavit.',
		'chci nastavit černý puk',
		'mám nový modem',
		'neumím nastavit modem',
		'nevím heslo do nastavení modemu',
		'nevím jak zapojit modem',
		'nevím jak zprovoznit modem',
		'potřebuju nastavit modem',
	],
	MODEM_WIFI: [
		'chtěla bych zprovoznit wifi, poradíte mi?',
		'jak mám nastavit wifi?',
		'nastavit wifi',
		'nefunguje mi wifi',
		'pomůžete mi spustit wifi?',
		'poradíte mi s nastavením wifiny?',
		'potřebuju poradit s nastavením wifi',
		'wifina mi nejde nastavit',
	],
	MUJ_HLASOVY_TARIF_ANOTHER_OPERATOR: [
		'Kolik mě stojí volání do jiné sítě',
		'cena sms do cizí sítě s mým tarifem',
		'cena sms do jiné sítě s mým tarifem',
		'cena volání do cizí sítě s mým tarifem',
		'cena volání do jiné sítě s mým tarifem',
		'jak je drahé volání k jinému operátorovi s mým tarifem?',
		'jak jsou drahé sms do jiné sítě s mým tarifem?',
		'kolik mě stojí sms a volání do jiných sítí',
		'na kolik vyjde volání s mým tarifem do jiné sítě?',
		'na kolik vyjdou sms s mým tarifem?',
		'za kolik mám smesky do jiné sítě',
		'za kolik volám do cizí sítě',
	],
	MUJ_HLASOVY_TARIF_FREE_CALLING: [
		'Kam můžu volat v rámci svého tarifu zadarmo?',
		'Mám minuty zdarma',
		'kde najdu volné minuty',
		'kolik má můj tarif volných SMS?',
		'kolik má můj tarif volných minut?',
		'kolik mám měsíčně zadarmo SMS ve svém tarifu?',
		'kolik mám volných minut za měsíc?',
		'mám volné minuty a SMSky ve svém tarifu?',
		'nefungují mi volné minuty',
	],
	MUJ_HLASOVY_TARIF_GENERAL: [
		'Můj hlasový tarif',
		'Můj tarif na sms',
		'informace o mém tarifu',
		'můj paušál',
		'potřebuji informace o tarifu',
	],
	MUJ_HLASOVY_TARIF_INFO: [
		'Kolik můžu volat v rámci tarifu',
		'a zajímalo by mě aktuální útraty',
		'jak zjistím kolik jsem prodatoval měsíčně',
		'kolik dat mi zbývá v tarifu?',
		'kolik mi zbývá provolat z paušálu',
		'kolik paušálu mám provolané',
		'kolik už jsem provolal z paušálu',
		'oznámili mi, že mi odebrali třicet volných minut, kolik jich teď mám',
		'počet poslaných sms do ostatních sítí',
		'výše měsíčního paušálu',
	],
	MUJ_HLASOVY_TARIF_ORDINARY: [
		'chci obyčejný tarif',
		'chci se zeptat na můj obyčejný tarif',
		'chci řešit obyčejný tarif',
		'můj obyčejný tarif',
		'obyčejný tarif',
	],
	MUJ_HLASOVY_TARIF_STATUS: [
		'Byla můj tarif převedena z M na S?',
		'Chci upravit svůj tarif na volání',
		'Jak dlouho už mám váš hlasový tarif',
		'Jak mám nastavený svůj tarif',
		'Jak vypadá můj tarif volací',
		'Jaká je platnost mého tarifu volacího',
		'Jaké služby volání mám aktivované na svém účtu?',
		'Jaký mám hlasový paušál',
		'Jaký mám tarif na volání?',
		'Já jsem si na lince domluvila tarif za 475 Kč ale v aplikaci vidím za 525 Kč, jak to',
		'Zajímali by mě informace k mému volacímu tarifu',
		'a podívejte se mě na tarif',
		'až teď mi oznámili, že mi zrušili volné minuty',
		'chci se zeptat na svůj tarif',
		'chtěl bych se zeptat jaký mam aktuálně nastaveny tarif',
		'co mám nastavený za tarif',
		'do kdy mi platí tarif?',
		'do kdy mám platný tarif?',
		'do kdy mám tarif?',
		'informace o tarifu na čísle 739 354 895',
		'jaké jsou parametry mého tarifu na volání a sms?',
		'jaký mám nastavený tarif?',
		'já bych se chtěla zeptat na svůj tarif',
		'já nemám ty výhody co bych měla mít tím tarifem',
		'kolik mě stoji mmsky?',
		'kolik mě stoji sms',
		'měnil jsem si tarif a vypnuli mi internet',
		'nerozumím svému tarifu, co všechno tam mám?',
		'potřebovala bych tarif',
		'potřebuju informace ohledně paušálu',
		'potřebuju se zeptat na tarif',
		'prodloužit studentské výhody pro číslo 7 3 3 7 2 7 3 7 5',
		'synovi byl změněn tarif, můžu vědět co obsahuje?',
	],
	MUJ_BLOCK: [
		'mám zablokovanou aplikaci',
		'mám zablokovaný účet',
		'zablokoval se mi účet, nevím co s tím',
		'zablokovaná samoobsluha',
		'zablokovaný účet',
	],
	CHANGE_ADDRESS: [
		'Chtěl bych aktualizovat informace ve smlouvě změnila se mi adresa',
		'chci doručit smlouvu na jiné místo',
		'chci nahlásit novou adresu do smlouvy',
		'chci nahlásit změnu adresy, přestěhovali jsme se',
		'chci nahlásit změnu adresy',
		'chci změnit adresu místa, kam mi doručíte smlouvu',
		'chci změnit adresu pro doručení smlouvy',
		'chci změnit doručovací adresu pro smlouvu',
		'chci změnit místo doručení smlouvy',
		'nastavit novou adresu v samoobsluze',
		'potřebuju změnit adresu ve smlouvě',
		'potřebuju změnit adresu ve smlouvě, protože jsme se přestehovali',
		'přestěhovali jsme se do jiného města, kam to mám nahlásit?',
		'přestěhovali jsme se, jak si změním v samoobsluze adresu?',
		'přestěhovali jsme se, potřebuju nahlásit novou adresu',
		'stěhovali jsme se, chci si změnit adresu',
	],
	CHANGE_MAIL: [
		'chci změnit mail',
		'dobrý den potřeboval bysem změnit e mail',
		'potřebuju zrušit e mail',
		'změnit email',
	],
	MAIL: [
		'dobrý den ohledně mailů',
		'mám problém s mailem u přihlášení do samoobsluhy',
		'nefunguje mi email v aplikaci',
		'nejde mi zadat mail do aplikace',
		'nemůžu se přishlásit s mým mailem do samoobsluhy',
		'zapomněla jsem přihlašovací email',
	],
	M_PLATBA_ACTIVATE: [
		'Chci dovolit m-platby',
		'Chci povolit mplatbu',
		'Chci zapnout mplatbu',
		'aktivovat platby třetím stranám',
		'chci mít zapnuté platby třetím stranám',
		'chci odblokovat m-platby',
		'chtěla bych dovolit m-platby',
		'chtěla bych odblokovat platby třetím stranám',
		'odblokovat platby třetím stranám',
		'potřebuji aktivovat mobilní platby',
		'potřebuji povolit m-platby',
		'potřebuju aktivovat m-platby',
		'povolte mi m-platby',
		'povolíte mi mobilní platby',
		'povolíte mi platby třetím stranám',
		'zypnout platby třetím stranám',
		'šly by povolit m-platby',
	],
	M_PLATBA_APP: [
		'chci kupovat aplikace',
		'potřebuju koupit aplikaci',
		'potřebuju zapnout možnost koupení aplikací',
		'zapnout nákupy aplikací',
	],
	M_PLATBA_DEACTIVATE: [
		'Chci pro své dítě zrušit platby přes mobil',
		'Chci vypnout mplatbu',
		'Chci zablokovat platby přes mobil',
		'Chtěl bych zrušit placení přes mobil',
		'Zablokujte na tomto čísle platby přes mobil',
		'Zrušte mi okamžitě platby přes mobil',
		'ano chci zablokovat hovory od třetích stran',
		'blokace třetím stranám',
		'chtěl bych zrušit všechny mikroplatby na čísle 6 3 7 5 8 500',
		'potřebovala bych zablokovat m platby',
		'potřebovala bych zakázat m platby',
		'prosila bych o zakázání m platby',
		'prosila bych zablokovat m-platby',
		'prosím o zablokování m platby',
		'prosím o zrušení mikroplateb',
		'prosím zablokovali byste mi m platby',
		'zablokovat hovory třetích stran',
		'zablokujte mi mplatbu',
	],
	M_PLATBA_GAMES: [
		'Nákupy mobilních her',
		'Platba za hry do mobilu',
		'chci nakoupit hry mobilní platbou',
		'chci si koupit hry mobilní platbou',
		'chci si pomocí mobilní platby nakoupit hry',
		'hraní her mobilní platby',
		'jak platit za hry v mobilu',
		'mohu použít mplatbu na nákup her',
		'mohu si koupit přes mobilní platbu hry',
		'mplatba na nákup her',
		'nákup her pomocí mplatby',
	],
	M_PLATBA_GENERAL: [
		'Mobilní platba',
		'Platba mobilem',
		'Platba přes SMS',
		'Platba přes mobil',
		'Platit přes SMS',
		'chci m-platby ale nemám je povolené',
		'chci nakupovat po SMS',
		'chci platit mobilem',
		'chci používat m-platby v telefonu',
		'chci rešit m platbu',
		'chci se podívat na m platbu',
		'chci si platit mobilem',
		'chci vědět, proč nemám povolené m platby',
		'jakto že nemám zapnuté m platby',
		'm platby',
		'mohli bychom se podívat na m platbu',
		'mohu v telefonu používat m platby',
		'mám povolené m platby',
		'můžu se podívat na m platbu',
		'nakupování po SMS',
		'nemám povolené m platby',
		'nemám zapnuté m-platby',
		'nákupy po SMS',
		'platba přes m platby',
		'potřebuju se podívat na m platbu',
		'potřebuju v telefonu mplatby',
		'proč nemám povolené m platby',
		'v mém mobilu nejsou povolené m platby',
		'zaplatit přes SMS',
	],
	M_PLATBA_LIMIT: [
		'byl byste tak hodný a upravil mi limit pro m-platby',
		'chci nastavit limit mplatby',
		'chci upravit limit m-platby',
		'chci změnit limit m plateb',
		'chci změnit limit plateb přes webovou bránu',
		'chci změnit maximální limit m-platby',
		'chtěla bych prosím nastavit limit pro m-platbu',
		'chtěla bych upravit limit m-platby',
		'jak nastavím limit pro m platby?',
		'jak upravím limit pro m-platby',
		'mohli byste mi změnit limit m-platby',
		'poradíte mi, jak nastavit limit m-platbám',
		'potřeboval bych upravit limit m-platby',
		'potřebuju nastavit limit pro m-platby',
		'potřebuju změnit limit m-plateb',
		'upravili byste mi limit pro m-platby',
		'změnit limit pro m-platbu',
	],
	NABIDKA_A_ZMENA_TARIFU_ACTIVATE: [
		'aktivace tarifu',
		'aktivovat navé číslo',
		'aktivovat tarif',
		'aktivovat číslo',
		'chtěla bych aktivovat neomezená data na jinačím telefonním čísle',
		'potřebuju aktivovat neomezená data',
		'prosím o aktivaci čísla',
		'zaktivovat číslo',
		'zaktivujete mi telefonní číslo',
	],
	NABIDKA_A_ZMENA_TARIFU_CALL_COST: [
		'chci vědět cenu hovorů',
		'chci znát cenu za telefonování',
		'jaká bude moje cena hovorů',
		'jaká je cena hovorů',
		'jaká je cena volání',
		'já bych chtěl vědět kolik budu platit za hovory',
		'kolik budu platit za hovory',
		'kolik zaplatím za hovory',
		'za kolik budu mít hovory',
		'za kolik budu volat',
		'řeknete mi kolik mě bude stát hovor',
	],
	NABIDKA_A_ZMENA_TARIFU_CHANGE_OPERATOR: [
		'Chci se stát vaším zákazníkem',
		'Chtěl bych k vám přejít',
		'Chtěl bych přejít se svým číslem od jiného operátora k vám na paušál. Pomůžete mi?',
		'Máte lepší nabídku tarifů než jiní operátoři?',
		'Vodafone mi nabídl lepší podmínky',
		'chci k vám přejít',
		'chci lepší podmínky, jdu ke konkurenci',
		'chci odejít od Vodafonu, co mi nabídnete vy?',
		'chci změnit stávajícího operátora a přejít k vám, jaké tarify máte?',
		'co mi nabídnete, kdybych k vám chtěl přejít?',
		'hledám nového operátora, co nabízíte?',
		'hledám vhodného operátora pro syna, můžete mi nabídnout nějaký tarif?',
		'jdu jinam, za lepších podmínek',
		'jinde mají lepší podmínky',
		'jiní operátoři mají lepší podmínky',
		'libí se mi nabídka od konkurence, co mate vy, že bych přešel k vám?',
		'mám lepší nabídku od Vodafonu',
		'mám nabídku od Vodafonu a za lepších podmínek',
		'mám zájem o přechod k vám',
		'měním stávajícího operátora, jaké tarify nabízíte?',
		'přemýšlím, že se stanu vašim zákazníkem, co nabídnete?',
		'převod internetu a televize',
		'uvažuji o tom, že bych přešel k vám',
	],
	NABIDKA_A_ZMENA_TARIFU_CHECK_AVAILABILITY: [
		'chci se zeptat zda máte internet i na mojí adrese',
		'chtěl bych zkontrolovat, jestli můžu mít doma pevný internet',
		'jak zjistím, že je připojení dostupné i u mě?',
		'je dostupný internet i ve starém městě?',
		'rád bych ověřil, zda máte internetové kabely na adrese',
		'rád bych překontroloval jestli je na mé adrese dostupný pevný internet',
		'slyšel jsem o vašem novém připojení, je dostupné i na mojí adrese?',
		'vede vám internetová přípojka i na sídliště',
	],
	NABIDKA_A_ZMENA_TARIFU_DATA: [
		'Datová SIM',
		'Datová simka',
		'Datové tarify',
		'Datový tarif',
		'Jaké máte datové tarify',
		'MŮJ SVOBODNÝ TARIF 4 GB',
		'MŮJ SVOBODNÝ TARIF 7 GB',
		'MŮJ SVOBODNÝ TARIF NEOMEZENĚ HD',
		'SIM na data',
		'chci více dat do tarifu',
		'data pro nové číslo',
		'data pro nový tarif',
		'jaké nabízíte datové balíčky',
		'jaké nabízíte datové tarify',
		'mám zájem o nějaký balíček dat',
		'máte i nějaký tarif na data?',
		'nabídnete mi nějaký datový tarif?',
		'zajímala by mě letní nabídka neomezených dat',
		'zajímaly by mě vaše datové tarify',
	],
	NABIDKA_A_ZMENA_TARIFU_HELP: [
		'pomoc s nastavením paušálu',
		'pomoc s nastavením tarifu',
		'pomoc s paušálem',
		'pomoc s tarifem',
		'pomožte mi, mám problém s tarifem',
		'pomůžete mi s tarifem?',
		'potřebuji pomoc s maminčiným tarifem',
		'potřebuji pomoct s tarifem',
	],
	NABIDKA_A_ZMENA_TARIFU_HLAS: [
		'Můj svobodný tarif M a 1,5 GB',
		'Můj svobodný tarif M a 12 GB',
		'Můj svobodný tarif M a 3 GB',
		'Můj svobodný tarif M a neomezeně HD',
		'Můj svobodný tarif M a neomezeně MAX',
		'Můj svobodný tarif M a neomezeně SD',
	],
	NABIDKA_INTERNET: [
		'chci připojit pevný internet',
		'chci si pořídit internetovou sim',
		'chci si zřídit pevný internet',
		'chtěla bych od vás internet do zásuvky',
		'dobrý den volám ohledně zřízení internetu jak jsem se domlouvala s kolegyní',
		'dobrý den já bych potřebovala zařídit připojení pevného internetu',
		'Internetová SIM',
		'jak dostat za co nejméně peněz do telefonu internet',
		'jakou máte nabídku internetu',
		'jaké máte tarify pro přenosný internet?',
		'Můžu si u vás zařídit internetový tarif',
		'Nabídka internetu',
		'nabídka na mobilní internet na měsíc',
		'nabídnete mi přenosný internet?',
		'potřeboval bych podrobnou informaci pevnému internetu tedy ste mi nabídli',
		'potřebovali bysme internet na chatu',
		'potřebuju internet, můžete mi něco nabídnout?',
		'Potřebuju nějaký internet na cestování, jaké jsou nabídky?',
		'potřebuju pevný internet',
		'potřebuju SIM s internetem',
		'Přenosný internet',
		'připojíte mi pevný internet',
		'viděl jsem reklamu na váš nový internet, můžu dostat nějaké bližší informace?',
		'volám ohledně zřízení internetu',
		'zajímá mě nabídka internetu',
		'zajímá mě vaše nabídka internetu',
		'zajímám se o nabídku internetu',
		'zřídit si internet',
	],
	NABIDKA_A_ZMENA_TARIFU_INTERNET_HOME: [
		'Chci pevný internet do domácnosti',
		'chci internet domů',
		'chci se zeptat na internet k nám domů',
		'chci si zřídit internet k nám domu',
		'chtěl bych pevný internet k nám domů',
		'chtěl bych ten internet do domu',
		'chtěla bych od vás pevný internet domů',
		'dá se ke mně domů napojit váš internet',
		'jakou máte dostupnost vašeho internetu do domácnosti',
		'jaké jsou možnosti internetu na doma',
		'mám zájem o váš internet do naší domácnosti',
		'můžu si k sobě domu objednat váš internet k nám domů',
		'na doma',
		'potřebuju zřídit internet na doma',
		'vede k nám domů váš internet',
		'zajímal by mě ten váš pevný internet k nám domů',
		'zřídit internet na doma',
	],
	NABIDKA_A_ZMENA_TARIFU_MODEM: [
		'Datový tarif pro SIM do modemu',
		'Datový tarif pro modem do počítače',
		'datová SIM pro modem',
		'datová simka do modemu',
		'datové tarify do modemu',
		'datový tarif pro počítačový modem',
		'jaké nabízíte datové simky',
		'jaký mi nabídnete datový tarif do počítače',
	],
	NABIDKA_A_ZMENA_TARIFU_NEW: [
		'Chci informace k novým tarifům, co jsem zahlédl',
		'Chtěl bych si převést tohle dobíjecí číslo na paušál',
		'Dejte mi nový tarif',
		'Jak zřídit paušál?',
		'Mám tři děti a pro každé bych chtěl nový tarif, jaké máte?',
		'Mám zájem o váš nový tarif',
		'Nové tarify',
		'Portfolio nových tarifů',
		'Slyšel jsem, že máte nové tarify',
		'Zalíbily se mi vaše nové tarify',
		'Zaujaly mě vaše nové tarify',
		'budu chtít nový paušál',
		'budu potřebovat nový paušál',
		'budu potřebovat paušál',
		'chci platit pomocí paušálu',
		'chci platit přes paušál',
		'chci u vá nové zařízení telefonu',
		'chtěl bych zařídit tarif pro moji babičku',
		'chtěla bych jiný paušál',
		'chtěla bych začít používat paušál',
		'co udělat pro to, abych mohl používat paušál?',
		'já bych chtěla jakoby nové zařízení telefonu',
		'končí mi tarif tak se chci domluvit na nějakym novém tarifu',
		'koukal jsem že máte teď novou nabídku, můžu dostat bližší informace',
		'mohl bych si vzít paušál pod tohle číslo?',
		'mohla bych přes paušál',
		'mohli byste mě informovat o nových tarifech',
		'na vašem webu jsem našel novou nabídku tarifů, chci ho ale smlouva mi končí příští rok',
		'nabídka nových tarifů',
		'potřebovala bych paušál',
		'potřebuju přejít na nový tarif a získat nový telefon',
		'potřebuju zřídit paušál',
		'prosím o informace k tarifům',
		'v reklamě máte novou nabídku, rád bych si něco vybral.',
		'viděl jsem reklamu na nové tarify',
		'zajímá mě nabídka tarifu',
		'zajímám se o nabídku tarifů',
	],
	NABIDKA_A_ZMENA_TARIFU_PAY_LESS: [
		'Chci si výhodnější tarif',
		'Platím strašně moc, můžete mi to nějak zlevnit?',
		'chci levnější tarif',
		'chci levnější tarif, abych ušetřil',
		'chci platit míň co s tím uděláte',
		'chci platit míň, co mám udělat?',
		'chtěl bych platit méně za tarif',
		'chtěl bych slevu a platit méně',
		'chtěl bych zlevnit paušál a platit míň',
		'chtěla bych výhodnější tarif',
		'já bych se chtěla zeptat jestli bych nemohla dostat ňákej výhodnější tarif více minutama',
		'levné paušály',
		'levné tarify',
		'mohli byste mi dát nějaký výhodnější tarif',
		'nechci platit tolik',
		'potřebuji platit za internet míň než nyní',
		'proč platím tolik za paušál',
		'přijde mi, že platím hodně, a chtěl bych platit méně',
		'ráda bych měla výhodnější paušál',
		'výhodnější tarif s více minutami',
	],
	NABIDKA_A_ZMENA_TARIFU_PHONE_TO_TARIFF: [
		'chtěla bych k tarifu telefon za korunu',
		'dobrý den já už telefon na splátky k tarifu',
		'já bych potřebovala jestli by bylo možné k tarifu si vzít telefon',
		'já bych se chtěl zeptat mohl bych mit telefon za korunu když mám u vás paušál',
		'kam bych se na telefon za jednu korunu',
		'nový tarif s telefonem za korunu',
		'telefon k tarifu',
		'telefon za korunu',
	],
	NABIDKA_A_ZMENA_TARIFU_WRONG_TARIF: [
		'mám špatný tarif',
		'můj tarif je špatný',
		'nemám správný tarif',
		'nesprávný tarif',
	],
	NABIDKA_INTERNETU_NAVYSIT_RYCHLOST_INTERNET: [
		'chci zvýšit výkon internetu',
		'mám pevný internet a chcela bysom zvýšit jeho výkon',
		'prosím navýšit a rychlost internetu',
		'rád bych si zvýšil rychlost na svém internetu co u vás mám',
	],
	NASTAVENINKASA_SETTING: [
		'Chci si nastavit inkaso',
		'Nastavení inkasa',
		'Změnit nastavení inkasních plateb',
		'chci to nastavení inkasa',
		'chci změnit nastavení plateb přes inkaso',
		'chci řešit nastavení inkasa',
		'nastavit inkaso',
		'potřebuju nastavit inakso',
		'změna nastavení inkasa',
	],
	NASTAVENMMS_ACTIVATE: [
		'Aktivace MMS',
		'Aktivovat MMS',
		'Aktivujte mi MMS',
		'Chci posílat MMS',
		'Chci zapnout možnost zasílání a příjem MMS',
		'Chtěl bych aktivovat MMS',
		'Chtěl bych vás požádat o aktivaci MMS na mém čísle',
		'Kde si můžu zapnout MMS služby',
		'Kde si zapnu MMS',
		'Mám aktivní MMS?',
		'Mám zapnuté MMS?',
		'Můžu si u vás aktivovat zasílání MMSek',
		'Nemůžu nikde najít, jak se zapínají MMS služby',
		'Neumím si zapnout MMS služby',
		'Nevím jak zapnout MMS služby',
		'Nevím kde si aktivovat zasílaní MMS',
		'Zapnout MMS',
		'Zapněte mi MMS ať je můžu posílat i vidět',
		'mohla byste mi poslat nastavení na mms na tomto telefonním čísle',
	],
	NASTAVENMMS_DEACTIVATE: [
		'Chtěl bych zrušit příjem MMS',
		'Nechci dostávat MMS',
		'Vypnout MMS',
		'nepotřebuji dostávat MMS',
		'ruším MMS',
		'ráda bych zrušila MMS',
		'už nechci dostávat MMS',
		'zrušit MMS',
	],
	NASTAVENMMS_GENERAL: ['mms zprávy', 'mmsky', 'zprávy mms'],
	NASTAVENMMS_SETTING: [
		'Chtěl bych nakonfigurovat MMSky',
		'Jak se nastavují MMS zprávy',
		'Konfigurace MMS',
		'Konfigurační zprava MMS',
		'MMSky konfigurovat',
		'Mám nastavené MMS?',
		'Poradíte mi jak si nastavit MMS zprávy',
		'Potřebuju nastavit MMS',
		'Potřebuju poradit jak nastavit MMS',
		'nastavení MMS',
		'rad bych si nechal nastavit MMS',
	],
	NASTAVENTELEVIZE_SETTINGS: [
		'asi to nastavení',
		'chci naladit programy',
		'chci nastavit připojení',
		'chci změnit jas',
		'chtěla bych naladit programy',
		'nastavení',
		'nastavení obrazovky',
		'potřebuji upravit',
		'potřebuju nastavit programy',
		'přenastavit jas',
		'změnit jas obrazovky',
	],
	NASTAVENTELEVIZE_SET_UP: [
		'nastavení programů',
		'nastavení televize',
		'nastavit kanály',
		'nastavit programy',
		'nastavit televizi',
		'nevidím program',
		'změnit kanály',
	],
	NASTAVENZARIZENSET_UP: [
		'chci si nastavit zařízení',
		'chci řešit nastavení zařízení',
		'chtěl bych si nastavit zařízení',
		'nastavit zařízení',
		'potřebuju nastavit zařízení',
		'to nastavení',
		'zajímám se o nastavení zařízení',
	],
	MODEM: [
		'chci pomoct s modemem',
		'kvůli modemu',
		'Modem',
		'možnost s modemem',
		'nejspíše s modemem',
		'no s modemem',
		'Připojení modemu',
		's modemem',
		'týká se to modemu',
	],
	MODEM_PROBLEM: [
		'Asi mám zavirovaný modem , umíte mi pomoci?',
		'Asi mám zavirovaný modem',
		'Chci pomoct se zapojením modemu',
		'mám nový modem a nevím, jak ho rozjet',
		'mám problém s modemem, nesvítí mi',
		'nevím jak zapojit krabičky aby mi fungoval internet, pošlete mi někoho',
		'nevím jak zapojit modem co mám dělat?',
		'nevím si rady se zapojením modemu',
		'pomůžete mi zapojit modem',
		'Potřebuju pomoct s zapojením modemu',
		'potřebuju, abyste mi zapojili internet do zásuvky',
		'připojíte mi internet do zásuvky',
		'resetoval jsem modem a nejde mi',
		'Shořel mi nejspíš modem',
	],
	NASTAVIT_TARIFNZVYHODNENACTIVATE: [
		'Nahodit balíček',
		'Nastavit tarifní zvýhodněni',
		'Zapnout balíček zvýhodnění',
		'chci aktivovat ten výhodný balíček',
		'chci tarifní zvýhodnění',
		'chci ten balíček',
		'můžete mi zapnout balíček s tarifním zvýhodněním?',
	],
	NASTAVIT_TARIFNZVYHODNENCALL: [
		'Balíček volání do všech sítí zdarma',
		'Chci balíček volání do všech sítí zdarma',
		'Chci si dočasně zapnout balíček volání do všech sítí',
		'Chtěla bych na jeden den volání do všech sítí',
		'Hlasové balíčky zvýhodnění',
		'Potřebuji nastavit volání do všech sítí zdarma',
	],
	NASTAVIT_TARIFNZVYHODNENCANCEL: [
		'Chci ihned zrušit balíček, který mi žere peníze',
		'Chci zrušit balíček, který vůbec nevyužívám',
		'Nemám zájem o balíček který jste mi vnutili',
		'Potřebuju deaktivovat balíček, který mi strhává kredit',
		'Zrušit balíček',
		'chci zrušit balíček, který jsem si neobjednala',
		'chci zrušit neomezený náhradní internet na 48 hodin',
		'chci zrušit zvýhodněné služby',
		'platím za balíček, který jsem si neobjednala',
		'prosím zrušte mi zvýhodněné služby',
		'zjistila se na mém tarifu nejenom že platíme za balíček které jsem si neobjednala',
		'zrušení vnuceného balíčku',
		'zrušení zvýhodněných služeb',
	],
	NASTAVIT_TARIFNZVYHODNENCHANGE: [
		'Chci si změnit balíček, který je mi k ničemu',
		'Chci změnit svoje balíčky',
		'chtěla bych vyměnit balíček za jiný typ',
		'potřebuju změnit svoje balíčky',
		'potřebuju změnit zvýhodněné balíčky',
		'vyměnit balíčky',
		'výměna balíčku za jiný',
		'změna balíčků',
		'změnit nastavení mých balíčků',
		'změňte mi obsah balíčku',
	],
	NASTAVIT_TARIFNZVYHODNENDATA: [
		'Chci základní datový balíček',
		'Datový balíček 1 500 MB',
		'Datový balíček 10 000 MB',
		'Datový balíček 1000 MB',
		'Datový balíček 2000 MB',
		'Datový balíček 3 000 MB',
		'Datový balíček 400 MB',
		'Datový balíček 4000 MB',
		'Datový balíček 400MB',
		'Datový balíček 50 MB',
		'Internet na 1 den 25 MB',
		'Neomezený datový balíček na den',
		'Zvýhodněné Balíčky dat',
		'Zvýhodněné Datové balíčky',
		'chtěl bych zvýhodněná data',
		'můžu si nastavit datový balíček?',
		'potřeboval by sem si aktivovat ta data',
	],
	NASTAVIT_TARIFNZVYHODNENGENERAL: [
		'balíček',
		'balíček benefitů',
		'benefit',
		'benefitní tarif',
		'informace o balíčku benefitů',
		'tarifní zvýhodnění',
		'zjistit informace ohledně benefit ku',
		'zvýhodnění',
		'zvýhodněný balíček',
	],
	NASTAVIT_TARIFNZVYHODNENPROLONGATION: [
		'Chci prodloužit platnost balíčku',
		'aby balíček fungoval dýl',
		'aby balíček platil déle',
		'aby platnost balíčku trvala déle',
		'chci nastavit delší platnost balíčku',
		'prodloužení balíčků služeb',
		'prodloužení datového balíčku',
		'prodloužení hlasového balíčku',
		'prodloužení internetového balíčku',
		'prodloužit balíček dat',
		'prodloužit minuty',
		'prodloužit stávající balíček, bude mi brzo končit',
		'prodloužit volné minuty',
		'prodloužit zvýhodněný balíček',
	],
	NASTAVIT_ZABAVNSLUZBY_AUDIOTEXT: [
		'Audiotextové služby',
		'audio textové služby',
		'audio texty',
		'audiotexty',
		'chci audiotextovky',
		'chtěla bych vědět, jak fungují audiotextové služby',
		'jak aktivuji audiotextovky?',
		'jak fungují audiotextové služby?',
		'jdou mi audio textové služby?',
		'mohu si aktivovat audiotextovky?',
		'potřebuji aktivovat audiotext',
		'zajímalo by mě, jak takové audiotextové služby fungují.',
	],
	NASTAVIT_ZABAVNSLUZBY_END_PREMIUM_SMS: [
		'chci vypnout prémiové sms',
		'chci zrušit odběr sms',
		'chci zrušit placené sms',
		'chci zrušit prémiové sms',
		'deaktivace prémiových zpráv',
		'deaktivovat prémiové sms',
		'deaktivujte mi prémiové zprávy',
		'nechci prémiové sms zprávy',
		'vypnout odběr prémiových zpráv',
		'zrušení placených prémiových smsek',
		'zrušení prémiových sms',
		'zrušit odběr prémiových sms zpráv',
		'zrušit odběr sms zpráv',
		'zrušit placené prémiové sms',
		'zrušit placené zprávy',
		'zrušit posílání placených sms',
	],
	NASTAVIT_ZABAVNSLUZBY_GENERAL: [
		'Zábavní služby',
		'aktivovat zábavní služby',
		'chci mít přehled o zábavních službách',
		'info o zábavních službách',
		'jak zapnout zábavní služby?',
		'jaké nabízíte zábavní služby',
		'povolit zábavní služby',
		'zajímají mě zábavní služby',
	],
	NASTAVIT_ZABAVNSLUZBY_NINEZEROZERO: [
		'Předčíslí 900',
		'SMS na devět set',
		'SMS na speciálně zpoplatněná čísla 900',
		'Volání na devítistovky',
		'chci odblokovat placenou linku',
		'chci, aby mi šly posílat sms na čísla 900',
		'dobrý den potřebuji vědět kde najít a placené telefonní linky pod začínající devítkou',
		'kde bych mohla najít placené linky které začínají devítkou',
		'kde najdu placenou linku začínající devítkou',
		'můžete mi prosím aktivovat placenou linku',
		'můžete mi zprovoznit smsky na devítkové číslo',
		'odblokovat placenou linku',
		'potřebovala bych aktivovat odesílání sms na devítkový číslo',
		'potřebuju odblokovat o placenou linku na číslo devět set šest',
		'ráda bych abyste mi odblokovali placené linky',
		'volání na 9 0 0',
		'volání na 900',
		'zajímaly by mě informace o placených linkách',
	],
	NASTAVIT_ZABAVNSLUZBY_ORACLE: [
		'Chci povolit SMS na horoskopy',
		'Volání na věštce',
		'aktivovat horoskopy',
		'chci aktivovat horoskopy',
		'chci povolit číslo na věštění z karet',
		'chci zkusit volání věštci',
	],
	NASTAVIT_ZABAVNSLUZBY_PREMIUM_SMS: [
		'Chci povolit SMS na hry',
		'Chtěl bych pravidelný odběr hádanek',
		'Premium SMS',
		'SMS jízdenky',
		'SMS na speciálně zpoplatněná čísla',
		'Volání na speciálně zpoplatněná čísla',
		'běží mi služba premium?',
		'hlasovací SMS',
		'jak poslat hlasovací sms do soutěže',
		'jak poslat sms na zaplacení steamu',
		'jak poslat sms na zaplacení tinderu',
		'jestli mám aktivovanou službu premium',
		'můžete mi zjistit zda mám aktivní službu premium?',
		'nemůžu si koupit jízdenky',
		'sms autobus',
		'sms tramvaj',
		'soutěžní SMS',
		'volání s vyšší sazbou',
	],
	NECO_JINEHO_ANOTHER_ERROR: ['jedná se o jinou poruchu', 'jiná porucha', 'mám jinou poruchu'],
	NEDORUCENA_SMS_VYUCTOVANDELETE: [
		'dítě mi vymazalo smsku s fakturou, pošlete mi novou?',
		'omylem jsem si smazala smsku s vyúčtováním, můžete mi ji poslat znovu?',
		'přišel jsem o SMS s fakturami',
		'smazal jsem si SMS s fakturou, pošlete mi novou?',
		'smazal jsem si SMS s vyúčtováním, potřebuji ji poslat znovu',
		'smazal jsem si smsku s fakturou',
		'ztratil jsem SMS s vyúčtováním',
	],
	NEDORUCENA_SMS_VYUCTOVANNOT_RECEIVED: [
		'Je už dvacátého a ještě mi nepřišla informace kolik mám platit.',
		'Nechodí mi od vás SMSky o faktuře.',
		'Nechodí mi od vás zprávy s fakturou',
		'Neposlali jste mi zprávu s vyúčtováním',
		'Nepřišla mi SMS o vyúčtování.',
		'Nepřišla mi SMSka s fakturou',
		'Nepřišla mi zpráva o vyúčtování.',
		'Nepřišla mi žádná zpráva o tom, kolik mám zaplatit.',
		'Nepřišla mi žádná zpráva o tom, že mám zaplatit vyúčtování',
		'Vůbec mi od vás nechodí zprávy, že kolik mám zaplatit své vyúčtování',
		'nechodí mi teď smsky s fakturami',
		'nedošla mi SMS s vyúčtováním',
		'nepřišla mi smska o faktuře',
		'přestaly mi chodit sms vyúčtování',
		'upozornění na vystavenou fakturu',
	],
	NEDORUCENA_SMS_VYUCTOVANSEND: [
		'Kdy mi pošlete SMSkou fakturu?',
		'Máte tam chybu, neposíláte mi SMS zprávy o vyúčtování.',
		'kdy mi dojde smska s fakturou?',
		'můžete mi poslat sms s fakturou',
		'pošlete mi SMS s fakturou',
		'pošlete mi SMS s vyúčtováním',
		'pošlete mi SMS s částkou, co mám zaplatit',
		'pošlete mi částku smskou?',
	],
	NEW_OFFER: [
		'cenovou nabídku nového vozu',
		'cenovou nabídku nových automobilů',
		'cenová nabídka na nový vůz',
		'cenová nabídka nového vozidla',
		'cenová nabídka nových aut',
		'chci nabídku nových vozidel',
		'nabídka nových aut',
		'nabídka nových vozů',
		'nabídku nového automobilu',
		'nabídněte mi nový automobil',
		'nebídka na nové auto',
	],
	NE_ANOTHER_NUMBER: [
		'na druhým čísle',
		'na jiném čísle',
		'ne z druhého',
		'ne z druhého čísla co u vás mám',
		'né z druhého a co co tam je na jo čísla',
		'z druhého čísla co tam je',
		'z jiného',
	],
	NE_DONT_HAVE_PASSWORD: ['heslo připravené nemám', 'nemám připravené heslo'],
	NE_NOT_INTERESTED: [
		'ne jen to chci opravit',
		'ne nechci šlo mi o něco jiného',
		'ne, nechci',
		'ne, tohle si řešit nepřeji',
		'ne,tohle teď nechci řešit',
		'nechci aby to',
		'nechci děkuji',
		'nechci toto',
		'nechci řešit',
		'nechci',
		'nemám zájem to řešit',
		'nemám zájem',
		'nepotřebuju díky',
		'nepotřebuju řešit připojení',
		'nepotřebuju',
		'nepřeji si to řešit',
		'nezajímá mě',
		'nic takového mě nezajímá',
		'o tohle nemám zájem',
		'to mě nezajímá',
		'to teda nechci',
		'tohle nemám zájem řešit',
		'tohle opravdu řešit nechci',
		'Tohle řešit nechci',
		'tohle řešit nepotřebuji',
		'tyhle věci mě nezajímají',
	],
	NE_NO_CALL: [
		'ne s voláním',
		'ne s voláním ne ale s vyúčtováním',
		'ne s voláním s vyúčtováním',
		'potíže s voláním řešit nechci',
		's voláním ne s fakturou',
	],
	NE_THATSALL: [
		'díky,  ale s tím už si poradím sama',
		'děkuji, nic víc už nepotřebuju',
		'myslím, že už mi to stačí',
		'ne, to už dokážu sám',
		'nemusíte pokračovat, to bude všechno',
		'není potřeba, to dovedu už sama',
		'nepokračujte, to je vše, děkuji',
		'o ničem dalším už nechci mluvit',
		'stačilo, děkuji',
		'stačí, to dovedeme už sami',
		'teď už to zvládnu sám',
		'to nechci řešit',
		'to už je z mé strany všechno',
		'to už si najdu sám',
		'už mi to stačilo',
		'v pohodě, to už si udělám sám',
	],
	SILNY_NE: [
		'ani náhodou',
		'ani omylem',
		'ani za nic',
		'ani za zlatý prase',
		'diki, ale ne',
		'děkuji, více nechci',
		'fakt ne',
		'já né děkuji pěkně',
		'ne, děkuji',
		'ne, není třeba',
		'není potřeba',
		'nepotřebuji',
		'nic takového nebude',
		'no to teda fakt ne',
		'né, diki',
		'opravdu ne',
		'rozhodně ne',
		'tak to teda ani náhodou',
		'tak to teda v žádném případě',
		'to sotva',
		'to teda ani náhodou',
		'to teda ani omylem',
		'určitě ne',
		'v žádném případě',
		'vůbec ne',
		'žádné takové',
	],
	NE: [
		'ale ne',
		'to ne',
		'asi ne',
		'myslím že ne',
		'ne',
		'ne díky',
		'ne děkuju',
		'ne nechci',
		'ne nevolám',
		'ne ne',
		'není to tak',
		'není třeba',
		'nikoliv',
		'pravděpodobně ne',
		'spíš ne',
		'tak to není',
		'to ani ne',
		'už ani ne',
	],
	NOBODY_ANSWERED: [
		'ještě jste mi nevolali',
		'ještě jste mi nezavolali',
		'nedostala jsem odpověď',
		'nemám od vás odpověď',
		'neodpověděli jste mi',
		'neozval se mi nikdo',
		'neozvali jste se',
		'neozvali jste se mi',
		'nezavolali jste mi',
		'nikdo mi nedal vědět',
		'nikdo mi nezavolal zpět',
		'nikdo mi od vás nevolal',
		'nikdo mi od vás nezavolal',
		'nikdo se mi neozval',
		'odpověď jsem nedostala',
		'odpověď od vás nemám',
	],
	NOT_WORKING_GENERAL: [
		'nefunguje si',
		'nejde připojení',
		'nelze se připojit',
		'nepřipojím se',
		'nic nefunguje',
		'právě nefunguje',
		'přestal fungovat net',
		'teď nefunguje',
		'vůbec nefunguje už několik hodin modem už jsem restartoval',
		'vůbec nefunguje',
		'vůbec nejde',
		'úplně přestal fungovat',
	],
	WEB_NOT_LOADING: [
		'Nenačtou se mi stránky',
		'nechce se mi nic načítat',
		'nechce se nám načíst ani jedna stránka',
		'nejde načíst žádná stránka',
		'nenačte se mi úvodní stránka',
		'nenačítá se mi žádná stránka',
		'nenačítá se vůbec nic',
		'nic nejde načíst',
		'nic se nenačítá',
		'žádná stránka se mi nenačte',
	],
	NOT_WORKING_NOT_START: [
		'internet nenabíhá',
		'internet nenaběhne',
		'nechce naběhnout',
		'prostě nenabíhá',
		'vůbec nenaběhne',
	],
	NOT_WORKING_NO_CONNECTION: [
		'není k dispozici připojení',
		'není k dispozici připojení internetu',
		'píše mi to, že připojení není k dispozici',
		'připojení internetu není k dispozici',
		'připojení není k dispozici',
	],
	NOT_WORKING_NO_INTERNET: [
		'není nalezen server',
		'píše, že server nenalezen',
		'server nenalezen a a síť marion je bez internetu mi to píše',
		'síť je bez internetusíť nemám internet',
		'říká, že síť nemá internet',
	],
	NOT_WORKING_WIFI: ['neběží mi wifina', 'nefakčí wifi', 'nefunguje wifi', 'nejde mi wifina', 'wifina nefunguje'],
	NOVE_NEW_DEVICE: [
		'chci informace k novému zařízení',
		'další zařízení',
		'nové zařízení',
		'splátky dalšího zařízení',
		'zajímají mě splátky nového zařízení',
		'zajímá mě další zařízení',
		'zajímá mě nové zařízení',
	],
	NOVY_TERMIN_CHANGE_TIME: [
		'chci změnu termínu',
		'můžete změnit čas návštěvy technika',
		'odložit návštěvu',
		'posunout návštěvu',
		'potřebuju posunout termín technika',
		'upravit čas',
		'změnit termín',
		'změnit termín instalaci dsl internetu',
	],
	NOVY_TERMIN_NEW_DATE: [
		'chci dostat nový termín technika',
		'dáte mi nový termín',
		'mám zájem o nový termín',
		'nový termín',
		'o nový termín',
		'potřebuju jiný termín',
		'v novém termínu',
		'zajímám se o nový termín příchodu technika',
	],
	NOVY_TERMIN_REJECT_TIME: [
		'chci odvolat termín',
		'chci zrušit návštěvu technika',
		'chci zrušit technika',
		'dohodnutý čas mi nevyhovuje',
		'nehodí se mi termín',
		'nemám čas',
		'nemůžu v ten čas',
		'nestíhám být doma včas, aby mohl přijet technik',
		'nestíhám technika',
		'potřebuju jiný termín, tento se mi nehodí',
		'technika dnes nestihnu',
		'ten čas nemohu',
		'termín pro technika se mi nehodí',
		'zrušení termínu',
		'zrušit schůzku',
		'zrušit schůzku technika',
	],
	NO_ROBOT_OPERATOR: [
		'chci mluvit s operátorem',
		'dovolat se na operátora',
		'dáte mi k telefonu operátorku',
		'dáte mi k uchu operátora',
		'musím mluvit s operátorkou',
		'můžu mluvit s operátorem',
		'můžu mluvit s opetátorkou',
		'operátora prosím',
		'potřebuju k telefonu operátora',
		'prosím chci hovořit s operátorkou',
		'přepojil byste mě na operátorku',
		'přepojte mě na operátora',
		'přepojíte mě na operátora',
	],
	NO_CONFUSED: [
		'Netuším do jaké zdířky se kabel dává',
		'nemůžu najít kam to strčit',
		'nevím kam kabel strčit',
		'nevím kam kabel zasunout',
		'nevím, kam se kabel dává',
	],
	NO_DIFFERENT_ADDRESS: [
		'chci řešit jinačí adresu',
		'chci řešit jinou adresu',
		'chtěla bych pořešit jinou adresu',
		'jinačí adresa',
		'jinačí adresu prosím',
		'jinou adresu',
		'jiná adresa',
		'kdepak, jde o jinou adresu',
		'ne, chci jinou adresu',
		'ne, mám to na jiné adrese',
		'ne, potřebuju vyřešit jinou adresu',
	],
	NO_DONT_HAVE_CABLE: [
		'Nemám kabel',
		'já žádný kabel nemám',
		'nedostala jsem žádný kabel',
		'nemám ethernetový kabel',
		'nemáme doma žádný kabel',
		'nic takového nemám',
		'u toho byl nějaký kabel?',
	],
	NO_DONT_WANT_COOPERATE: [
		'Nechci vám pomoct',
		'na to nemám čas',
		'nechci, nic nového',
		'nechci, to už jsem dělal',
		'nemám jak to udělat',
	],
	NO_DONT_WANT_RESTART: [
		'Nechci to restartovat',
		'Už nechci pokračovat',
		'nechci to řešit restartem, už jsem to zkoušel',
		'nepřeju si to restartovat',
	],
	NO_ITS_BETTER: [
		'je to o dost lepší, díky',
		'je to už dobrý',
		'už je to lepší',
		'už se to zlepšilo',
		'už to běží díky',
	],
	NO_AM_FINE: [
		'už nemám nic na srdci',
		'z mé strany už nic nepotřebuji',
		'z mé strany už to bude všechno',
		'za mě dobrý',
		'za mě je to všechno',
		'za mě vyřešeno',
	],
	NO_NOT_ON_PLACE: [
		'jsem jinde',
		'ne sem jinde',
		'ne, ještě tam cestuji',
		'ne, jsem ještě na cestě',
		'ne, jsem na jiném místě',
		'ne, nejsem doma',
		'ne, nejsem na místě',
		'ne, nejsem tam',
		'ne, nejsem, v práci',
		'nejsem',
		'nejsem ještě',
		'nejsem na místě',
	],
	NO_NOT_WILLING_ON_PLACE: [
		'ne, nebudu tam jezdit',
		'ne, nechci tam jet',
		'nebudu tam chodit',
		'nechci tam jít',
		'nechci vám pomoci',
		'to je váš problém, nebudu vám pomáhat',
	],
	NO_NOT_WORKING_LIGHT: [
		'Kontrolka nesvítí',
		'Nesvítí',
		'Nesvítí to',
		'Nic tam nevidím',
		'ani jedna',
		'na modemu nesvítí nic',
		'nejde nic vidět',
		'nemyslím si, že nějaká kontrolka svítí',
		'nesvítí ani jedna',
		'nevidím nic, nesvití',
		'nevidím žádnou kontrolku, která svítí',
		'nevypadá to, že by něco svítilo',
		'nevypadá to, že svítí',
		'nevšimla jsem si, že by svítila',
		'neřekla bych, že svítí',
		'nic nesvítí',
		'řekla bych, že nesvítí',
		'žádný kontrolka nesvítí',
	],
	NO_NO_CONNECTION: [
		'kabel není zapojený',
		'ne, není zapojený',
		'není zapojený',
		'není zapojený do modemu',
		'není zapojený do zdířky',
	],
	NO_NO_THAT_S_ALL: [
		'Myslím, že je to všechno.',
		'Ne, to je celé',
		'Ne, to je vše.',
		'Nechci, je to všechno',
		'Už je to asi všechno.',
		'Už nechci doplnit nic.',
		'chci, aby to fungovalo, ale jinak je to všechno',
		'ne akorát bych to potřeboval opravit',
		'ne, ale ať už je to opravené',
		'nechci, ale ať to už jde',
		'nechci, ale ať už je to hotové',
	],
	NO_UNCHANGED: [
		'Je to stejné',
		'Je to stejný',
		'Nic se nestalo',
		'Pořád',
		'Pořád je to pomalý',
		'Pořád stejné',
		'Pořád stejný',
		'Pořád žádná změna',
		'Všechno je pořád stejné, nic se nezměnilo.',
		'beze změny',
		'je to pořád špatné',
		'nic se nezměnilo',
		'Žádná změna',
		'Žádný efekt',
	],
	NO_WRONG_ADDRESS: [
		'adresa je už stará',
		'adresa je špatně',
		'adresa má v sobě chybu',
		'adresa není dobře',
		'chybná adresa',
		'máte starou adresu',
		'máte v adrese chybu',
		'máte špatnou adresu',
		'ne, to není dobrá adresa',
		'tahle adresa je blbě',
		'toto je stará adresa',
		'toto je špatná adresa',
		'v adrese je chyba',
	],
	OBECNE_TELEVIZE_BUY: [
		'chci koupit parabolu na televizi',
		'chci koupit televizi',
		'chci satelit na telku',
		'chci si koupit televizní anténu, prodáváte ji i s parabolou?',
		'dá se u vás objednat televize?',
		'můžu si koupit televizi od vás?',
		'můžu si objednat televizi',
		'prodáváte talíře na chytání televizního signálu?',
		'prodáváte televize?',
	],
	OBECNE_TELEVIZE_CHANNELS: [
		'chci HBO do televize, ale nevím jak',
		'chci naladit čt tři',
		'chtěl bych netflix do televize, jde to?',
		'chtěla bych se zeptat ohledně že mi nefunguje čt tři můžu to na svém televizoru naladit',
		'dobrý den moji rodiče mají tv mini a nemají čt tři',
		'jak naladit nový programy do telky',
		'jak naladím HBO, nejde to',
		'jak naladím programy v televizi',
		'mám problém s HBO',
		'mám problém s novým kanálem',
		'měl bych mít nový kanál v televizi, ale nejde naladit',
		'můžeme si objednat víc programů do televize?',
		'můžu mít v televizi netflix?',
		'můžu naladit čt tři',
		'můžu si dát do televize netflix?',
		'nefunguje mi čt tři',
		'poradíte mi, jak v telce koukat na kabelovku?',
		'potřeboval bych vyřešit problém s programem v televizi',
		'potřeboval bych víc programů v telce',
		'potřebuji nové televizní programy',
		'potřebuji pomoci naladit kanály v telce',
		'potřebuju na své televizi naladit čt tři a ono to nejde',
		'potřebuju naladit nové programy',
		'potřebuju v tv mini naladit čt tři',
		'proč v tv mini není čt tři',
		'sice máme tv mini, ale čt tři nejde',
		'v televizi mám málo programů, můžu objednat víc?',
	],
	OBECNE_TELEVIZE_CHANNELS_END: [
		'chci odstranit hbo z televize',
		'chci zrušit hbo',
		'chci zrušit program',
		'dobrý den potřebovala bych vyřešit zdarma hbo na mym telefonu protože já to chci zrušit',
		'odstranit balíček hbo',
		'odstranění programu',
		'odstraňte mi netflix z telefonu',
		'odstraňtě mi netflix',
		'potřebuju zrušit program',
		'zrušení hbo go',
		'zrušení programu',
		'zrušení služby na televizi',
		'zrušit hbo go',
		'zrušte mi netflix',
	],
	OBECNE_TELEVIZE_CONTROL: [
		'dobrý den já bych chtěla náhradní ovládání vaší televize',
		'k vaší televizi potřebuju náhradní ovládání',
		'nové ovládání k televizi bych potřebovala',
		'náhradní ovládání k televizi',
		'potřebuju ovládání k tv',
	],
	TV_OBRAZ_PROBLEM: [
		'mám rozostřený obraz v televizi je to u vás?',
		'mám rozostřený obraz v televizi',
		'Mám zhoršený obraz u televize. Můžete se mi na to kouknout?',
		'Pořád mi kostičkuje obraz u televize, co se děje?',
		'seká se mi obraz na telce',
		'Televize nic neukazuje',
		'vypadává mi obraz na televizi',
		'šumí mi obraz na televizi',
	],
	SATELIT_TELEVIZE: [
		'zprovoznit satelitní televizi',
		'zprovoznění satelitní televize',
		'aktivace satelitní televize',
		'aktivovat satelitní televizi',
		'aktivování satelitní televize',
		'satelitní televize',
	],
	TELEVIZE: [
		'a kolik televizi můžu napojit na jeden ten vysílač',
		'chci informace k televiznímu přijímači',
		'chci něco vědět o mé televizi',
		'chci poradit s televizí',
		'chci se zeptat na televizní připojení',
		'chci si popovídat o televizi',
		'chci řešit televize',
		'nevím si rady s telkou',
		'poradíte mi s televizí',
		'poskytnete mi radu k televiznímu přijímači',
		'potřebuji poradit s telkou',
		'potřebuju poradit ohledně a připojení a televizního přijímače',
		'potřebuju poradit ohledně televize',
		'přijímač se taky platí, když mám vaši televizi',
		's televizí',
		'televize s programy',
		'televize',
		'volám ohledně mé televize',
		'zajímala by mě vaše televize',
		'řešení televize',
	],
	OBECNE_TELEVIZE_NET_TV: [
		'internet televize',
		'internetová telka',
		'já potřebuju pomoc internetu televizi',
		'mám problém se přihlásit do internet televize',
		'nejde se přihlášit do internetové tv',
		'nemůžu se přihlásit do internetové telky',
		'pomůžete mi s internet televizí',
		'potřebuji informace ohledně internetové televize',
		'potřebuju pomoct s internetovou televizí',
		'tv s internetem',
	],
	OBECNE_TELEVIZE_ON_INSTALLMENT: [
		'chci televizi na splátky',
		'dáte mi telku na splátky',
		'potřebuji na splátky televizi',
		'telku na splátky bych chtěla',
		'tv na splátky',
		'řekni že chceš televizi na splátky',
	],
	OBECNE_TELEVIZE_RESET_PASSWORD: [
		'chci obnovit heslo mobilní televize',
		'obnova hesla k mobilní tv',
		'potřebuji a obnovit heslo k mobilní tv',
		'potřebuju obnovu hesla k mobilní telce',
	],
	OBECNE_TELEVIZE_RETURN_TV: [
		'chtěla bych vrátit televizi novou verzi za starou',
		'jak vrátím novou verzi televize na starou',
		'můžu vrátit novou televizi zpět',
		'potřeboval bych vrátit novou verzi televize na starou',
	],
	OBECNE_TELEVIZE_SEND: [
		'chci poslat zařízení k televizi',
		'dobrý den vrátil zase zařízení k televizi tak jestli bylo možný to poslal znova',
		'mohli byste mi znova poslat zařízení k televizi',
		'po vrácení a znova na mne je televize',
		'potřeboval bych poslat znova zařízení k televizi',
		'pošlete mi zařízení k televizi',
	],
	OBECNE_TELEVIZE_SETTING: [
		'chci měnit nastavení televize',
		'jak změním nastavení telky',
		'potřebovala bych měnit to nastavení televize',
		'potřebovala bych změnit nastavení tv',
		'prosím o změnu nastavení televize',
	],
	OBECNE_TELEVIZE_SUBINTENT_HOW_TO_TUNE: [
		'Chci nastavit novou televizi',
		'Chci nastavit telku',
		'Chci přenastavit televizi',
		'Jak naladit kanály u televize',
		'Mám špatně nastavenou televizi',
		'Nejde mi nastavit televize',
		'Nemůžu nikde najít manuál na svojí televizi',
		'Potřeboval bych nakonfigurovat televizi',
		'Potřebuju manuál k televizi',
		'Potřebuju návod na naši chytrou televizi',
		'Potřebuju český návod na televizi',
		'jak mám zresetovat televizi?',
		'jak na reset set-up boxu?',
		'jak udelat reset setup boxu?',
		'reset televize?',
	],
	OBECNE_TELEVIZE_USER: [
		'dobrý den potřebovala bych uživatelské jméno TV co',
		'jak zjistím moje uživatelské jméno na telce',
		'potřebovala bych zjistit uživatelské jméno tv go',
		'potřebovala bych znát uživatelské jméno na naší televizi',
	],
	OBJEDNAVKA_CANCEL_ORDER: [
		'chci informace o zrušené objednávce',
		'chci odvolat svoji objednávku',
		'chci stornovat svoji objednávku',
		'chci zrušit svoji objednávku',
		'je ta objednávka zrušená',
		'moc se omlouvám ale prosím o zrušení mé objednávky už se to vyřešilo',
		'má mi přijít telefon a já ho nechci',
		'měli byste mi poslat objednávku, ale já ji potřebuju zrušit',
		'prosím o zrušení mé objednávky',
		'přišla mi objednávka, ale já ji nechci',
		'storno objednávky',
		'stornovat objednávku',
		'ten telefon, co mi posíláte, už nechci',
		'už nechci objednávku',
		'už nechci tu objednávka',
		'už to nepotřebuju, můžete tu objednávku zrušit?',
		'volám kvůli zrušené objednávce',
		'zjistil jsem, že to nepotřebuji, můžete zrušit tu moji objednávku? děkuji',
		'zrušil kvůli zrušené objednávce',
		'zrušit objednání',
		'zrušte moji objednávku',
		'zrušte prosím tu moji objednávku',
	],
	OBJEDNAVKA_CHANGE_ADRESS: [
		'chtěla bych nahlásit kurýrovi novou adresu',
		'jinou adresu pro kurýra',
		'můžu ještě kurýrovi změnit adresu',
		'potřebuju kurýrovi změnit adresu pro doručení',
		'změnit adresu kurýra',
	],
	OBJEDNAVKA_COMPLAINT: [
		'Přišla mi špatná objednávka',
		'chtel bych reklamovat svoji objednávku',
		'chtěla bych reklamovat rozbitou objednávku',
		'dorazil mi rozbitý balík',
		'ještě jste mi nic nedoručili',
		'moje objednávka má poškrábaný povrch',
		'nedorazil balík',
		'nedoručená zásilka',
		'nepřišla mi zásilka',
		'objednávka dorazila poškozená',
		'objednávka zaplacená ale zboží nikde',
		'povrch zásilky je poničený',
		'přišla mi objednavka, kterou jsem si neobjednala',
		'přišla rozbitá objednávka',
		'stále jsem neobdržela již zaplacenou objednávku',
		'stále jste mi nedoručili moji objednávku',
		'už jsem objednávku platila, ale zboží stále nepřišlo',
		'už mám objednávku delší dobu zaplacenou, ale ještě jsem ji neobdržela',
		'zaplatila jsem objednávku dávno a nic',
		'zásilka stále nikde',
	],
	OBJEDNAVKA_ERROR: [
		'mám potíže s objednávkou',
		'máme problémy s objednávkou',
		'potíže s objednávkou',
		'problém s naší objednávkou',
	],
	OBJEDNAVKA_GENERAL: [
		'chci se ujistit, že v objednávce je vše správně',
		'chci urgovat objednávku služby',
		'chtěla bych popohnat objednávku',
		'k té objednávce',
		'moje objednávka',
		'můžu urgovat objednávku',
		'objednaný telefon',
		'objednávce, prosím, dejte prioritu',
		'objednávka z internetu',
		'objednávám si internet a bohužel to někde vázne',
		's objednaným telefonem',
		's objednávkou',
		'volám kvůli mé objednávce',
		'volám kvůli té objednávce',
		'volám ohledně mojí objednávky',
	],
	OBJEDNAVKA_NEW_ORDER: [
		'chci si nově objednat ...',
		'chci si něco objednat',
		'chci udělat novou objednávku',
		'chci vyřídit další objednávku',
		'chci vyřídit novou objednávku',
		'mám zájem o objednávku',
		'nová objednávka',
		'stojím o novou objednávku',
		'založit novou objednávku',
	],
	OBJEDNAVKA_SOMEBODY: [
		'Dneska byl kurýr u nás na televizi i s internetem a bylo nám řečeno, že nám ještě něco přijde poštou.',
		'Dneska mi byli zapojovat internet a já mám u vás objednaný modem a na televizi. Nevíte jestli už to bylo poslané?',
		'Nevíte, jestli jste odeslali můj balík?',
		'Objednali jsme si modem. Přišla mi zpráva, že je to v přepravě. Tak jsem se chtěl zeptat kdy to přijde.',
		'Volám kvůli dodávce jestli nevíte kdy asi dorazí.',
		'Vzali jsem si modem přes internet. Přijel člověk, který to montuje, ale nepřivezl ji.',
		'Zítra by balík měl kurýr doručit. Nevíte jaký je tam čas?',
		'kdy budete doručovat můj balík?',
		'kdy dojede kurýr s objednávkou',
		'kdy mi dorazí modem, nevíte, jestli jste ho už odeslali?',
		'kdy mi přijde modem',
		'kdy přijede kurýr',
		'nevíte kdy přijede kurýr?',
		'nevíte, kdy nám dorazí poštou, to co má přijít?',
		'proč pořád nejede kurýr',
	],
	OBOJE_BOTH: ['chci řešit oboje', 'o obojím', 'o obou', 'oboje', 'obojí', 'potřebuji vyřešit obojí', 's obojím'],
	OFFER: [
		'cenovou nabídku automobilů',
		'cenovou nabídku vozu',
		'cenová nabídka aut',
		'cenová nabídka na auto',
		'cenová nabídka vozidla',
		'chci nabídku vozidel',
		'nabídka aut',
		'nabídka na vůz',
		'nabídka vozů',
		'nabídku automobilů',
		'optávka na auto',
		'optávám automobil',
		'poptávka na vozidlo',
	],
	OFFER_AUDI: [
		'cenová nabídka auta audi',
		'cenová nabídka na audi',
		'cenové nabídky audi',
		'cenové nabídky na audi',
		'nabídka na audi',
		'nabídnete mi audi',
		'nabídněte mi audi',
		'nabízíte u vás audi',
		'optávka na audi',
		'optávání audi',
		'poptávám audi',
	],
	OFFER_DACIA: [
		'cenová nabídka dacie',
		'cenová nabídka na dacii',
		'cenové nabídky dacie',
		'cenové nabídky na dacii',
		'nabídka na dacii',
		'nabídnete mi dacii',
		'nabídněte mi dacii',
		'nabízíte u vás dacii',
		'optávka na dacii',
		'optávání dacie',
		'poptávám dacii',
	],
	OFFER_KIA: [
		'cenová nabídka kii',
		'cenová nabídka na kiu',
		'cenové nabídky kia',
		'cenové nabídky na kiu',
		'nabídka na kiu',
		'nabídnete mi kiu',
		'nabídněte mi kiu',
		'nabízíte u vás kiu',
		'optávka na kiu',
		'optávání kii',
		'poptávám kiu',
	],
	OFFER_OPEL: [
		'cenová nabídka na opel',
		'cenová nabídka opel',
		'cenové nabídky na opel',
		'cenové nabídky opelu',
		'nabídka na opel',
		'nabídnete mi opel',
		'nabídněte mi opel',
		'nabízíte u vás opel',
		'optávka na opel',
		'optávání opelu',
		'poptávám opel',
	],
	OFFER_RENAULT: [
		'cenová nabídka na renault',
		'cenová nabídka renaultu',
		'cenové nabídky na renault',
		'cenové nabídky renaultu',
		'nabídka na renault',
		'nabídnete mi renault',
		'nabídněte mi renault',
		'nabízíte u vás renault',
		'optávka na renault',
		'optávání renaultu',
		'poptávám renault',
	],
	OFFER_SKODA: [
		'cenová nabídka auta škoda',
		'cenová nabídka na škodovku',
		'cenová nabídka na škodu',
		'cenové nabídky na škody',
		'cenové nabídky škodovek',
		'nabídka na škodovku',
		'nabídnete mi škodu',
		'nabídněte mi škodovku',
		'nabízíte u vás škodu',
		'optávka na škodovku',
		'optávání škody',
		'poptávka na škodovku',
		'poptávám škodu',
	],
	OFFER_VOLVO: [
		'cenová nabídka na volvo',
		'cenová nabídka volva',
		'cenové nabídky na volvo',
		'cenové nabídky volva',
		'nabídka na volvo',
		'nabídnete mi volvo',
		'nabídněte mi volvo',
		'nabízíte u vás volva',
		'optávka na volvo',
		'optávání volva',
		'poptávám volvo',
	],
	OFFER_VW: [
		'cenová nabídka auta volkswagen',
		'cenová nabídka na volkswagen',
		'cenové nabídky na volkswageny',
		'cenové nabídky volkswagen',
		'nabídka na volkswagen',
		'nabídnete mi volkswagen',
		'nabídněte mi volkswageny',
		'nabízíte u vás volkswageny',
		'optávka na volkswagen',
		'optávání volkswagenu',
		'poptávám volkswagen',
	],
	OLD_CAR: [
		'5 let využívaný opel',
		'auto staré maximálně rok',
		'auto značky škoda, které jezdilo tak 6 let',
		'chci auto, maximálně 2 roky staré',
		'chci starší auto',
		'chci užitkový vůz volkswagen tak do 5 let stáří',
		'chtěla bych jen tak 3 roky používaný vůz',
		'chtěla bych vědět, jestli u vás je dacie, která je maximálně rok stará',
		'koupit starší auto',
		'maximálně 10 let starý vůz',
		'máte ke koupi i starší vozidla',
		'máte nějakou 3 roky starou kiu',
		'máte tak 3 roky využívané auto značky volvo',
		'najít vozidlo, co by bylo využívané maximálně 2 roky',
		'nechci moc staré auto, jen tak 3 roky',
		'potřebuji auto, které nebude starší než 4 roky',
		'potřebuji maximálně 5 let starého renaulta',
		'pořídit starší auto',
		'pět let staré auto',
		'starší auto než 5 let nechci',
		'starší automobil',
		'zajímal by mě volkswagen, jen tak pár let staré',
		'zajímá mě automobil, co bude jen 2 roky používané',
		'zajímá mě starší vůz',
	],
	ONE_GENERAL: [
		'1',
		'1 bych chtěl řešit',
		'1. adresa',
		'adresu jedna',
		'jedna',
		'jedničku',
		'na adrese jedna',
		'první',
		'prvou',
	],
	OPENING_HOURS_AUDI: [
		'do kdy má otevřeno prodejna Audi',
		'do kolika hodin má otevřeno prodejna Audi',
		'jakou má otevírací dobu prodej vozů Audi',
		'jakou má prodejní dobu Audi',
		'kdy mají otevřeno v salonu Audi',
		'kdy má otevřeno prodejna Audi',
		'kdy má otevřeno prodejna vozů Audi',
		'otevírací doba v prodejně Audi',
		'provozní doba v prodejně Audi',
	],
	OPENING_HOURS_CLOSING: [
		'kdy mají prodejny zavřeno',
		'kdy zavírá prodejna',
		'máte v prodejnách zavřeno',
		'máte v showroomu zavřeno',
		'v kolik hodin je zavíračka',
		'v kolik hodin zavírají prodejny',
		'v kolik hodin zavírají showroomy',
		'v kolik hodin zavíráte',
		'zavírací doba',
		'zavíračka v prodejnách',
	],
	OPENING_HOURS_DACIA: [
		'do kdy má otevřeno prodejna Dacia',
		'do kolika hodin má otevřeno prodejna Dacia',
		'jakou má otevírací dobu prodej vozů Dacia',
		'jakou má provozní dobu Dacia',
		'kdy mají otevřeno v salonu Dacia',
		'kdy má otevřeno prodejna Dacia',
		'kdy má otevřeno prodejna vozů Dacia',
		'otevírací doba dacia',
		'otevírací doba v prodejně Dacia',
		'provozní doba v prodejně Dacia',
	],
	OPENING_HOURS_GENERAL: [
		'a do kdy máte pracovní dobu',
		'jakou máte pracovní dobu',
		'jakto že už není pracovní doba',
		'jaké je pracovní doba',
		'kdy vám končí pracovní doba',
		'kdy vám začíná pracovní doba',
		'počkám na pracovní dobu',
		'tak to já se radši ozvu v pracovní době',
		'to počká do zítřka na pracovní dobu',
		'v kolik hodin vám končí pracovní doba',
		'vyřeším to v pracovní době',
		'zavolám raději zítra v pracovní době',
		'zavolám znovu, až budete mít pracovní dobu',
		'zkusím to v pracovní době',
	],
	OPENING_HOURS_KIA: [
		'do kdy má otevřeno prodejna Kia',
		'do kolika hodin má otevřeno prodejna Kia',
		'jakou má otevírací dobu prodej vozů kia',
		'jakou má provozní dobu kia',
		'kdy mají otevřeno v salonu kia',
		'kdy má otevřeno prodejna kia',
		'kdy má otevřeno prodejna vozů Kia',
		'otevírací doba Kia',
		'otevírací doba v prodejně Kia',
		'provozní doba v prodejně Kia',
	],
	OPENING_HOURS_OPEL: [
		'do kdy má otevřeno prodejna Opel',
		'do kolika hodin má otevřeno prodejna Opel',
		'jakou má otevírací dobu prodej vozů Opel',
		'jakou má provozní dobu Opel',
		'kdy mají otevřeno v salonu Opel',
		'kdy má otevřeno prodejna Opel',
		'kdy má otevřeno prodejna vozů Opel',
		'otevírací doba Opel',
		'otevírací doba v prodejně Opel',
		'provozní doba v prodejně Opel',
	],
	OPENING_HOURS_OPENING: [
		'chtěl bych znát přesné otevírací hodiny',
		'do kdy máte otevírací hodiny',
		'do kolika hodin máte otevřeno',
		'jakou máte otevírací dobu',
		'jaká je otevírací doba v Hostivaři',
		'jaká je otevírací doba v Malešicích',
		'jaká je otevírací doba v Modřanech',
		'jaká je otevírací doba ve Strašnicích',
		'jaká je otevírací doba ve Štěrboholech',
		'jaké jsou otevírací hodiny',
		'jaké máte otevírací hodiny',
		'kdy máte otevírací dobu',
		'od kdy máte otevřeno',
		'od kolika hodin máte otevřeno',
		'odkdy máte otevřeno',
		'v kolik hodin otevíráte',
		'zjistit, kdy máte otevřeno',
	],
	OPENING_HOURS_RENAULT: [
		'do kdy má otevřeno prodejna Renault',
		'do kolika hodin má otevřeno prodejna Renault',
		'jakou má otevírací dobu prodej vozů Renault',
		'jakou má provozní dobu Renault',
		'kdy mají otevřeno v salonu Renault',
		'kdy má otevřeno prodejna Renault',
		'kdy má otevřeno prodejna vozů Renault',
		'otevírací doba Renault',
		'otevírací doba v prodejně Renault',
		'provozní doba v prodejně Renault',
	],
	OPENING_HOURS_SKODA: [
		'do kdy má otevřeno prodejna Škodovek',
		'do kolika hodin má otevřeno prodejna Škoda',
		'jakou má otevírací dobu prodej vozů Škoda',
		'jakou má prodejní dobu Škodovka',
		'kdy mají otevřeno v salonu Škoda',
		'kdy má otevřeno prodejna vozů Škoda',
		'kdy má otevřeno prodejna Škodovek',
		'otevírací doba Škoda',
		'otevírací doba v prodejně Škodovek',
		'provozní doba v prodejně Škodovky',
	],
	OPENING_HOURS_VOLVO: [
		'do kdy má otevřeno prodejna Volvo',
		'do kolika hodin má otevřeno prodejna Volvo',
		'jakou má otevírací dobu prodej vozů Volvo',
		'jakou má provozní dobu Volvo',
		'kdy mají otevřeno v salonu Volvo',
		'kdy má otevřeno prodejna Volvo',
		'kdy má otevřeno prodejna vozů Volvo',
		'otevírací doba Volvo',
		'otevírací doba v prodejně Volvo',
		'provozní doba v prodejně Volvo',
	],
	OPENING_HOURS_VW: [
		'do kdy má otevřeno prodejna Volkswagen',
		'do kolika hodin má otevřeno prodejna Volkswagen',
		'jakou má otevírací dobu prodej vozů Volkswagen',
		'jakou má provozní dobu Volkswagen',
		'kdy mají otevřeno v salonu Volkswagen',
		'kdy má otevřeno prodejna Volkswagen',
		'kdy má otevřeno prodejna vozů Volkswagen',
		'otevírací doba Volkswagen',
		'otevírací doba v prodejně Volkswagen',
		'provozní doba v prodejně Volkswagen',
	],
	OPERATOR_NO_TOGETHER: ['ne to nevyřešíme', 'ne, nezkusíme', 'nevyřešíme', 'nezkusíme', 'to spolu nevyřešíme'],
	OPERATOR: [
		'chci mluvit s operátorem kvůli poruše',
		'chci operátora',
		'chci požádat o přepojení na normálního operátora',
		'chci přepojit na operátora',
		'chci přepojit na pracovníka',
		'chci si promluvit s operátorem',
		'chci si zavolat s operátorem',
		'chci vás požádat, aby jste mě přepojila na operátora',
		'chci zavolat operátorovi',
		'chtěl bych zavolat operátorovi',
		'dejte mi operátora',
		'je to něco spíše na operátora',
		'm přepojíte mě na operátora',
		'ne chtěl bych mluvit s operátorem',
		'né já chci řešit operátora už',
		'né prosím vás já bych potřebovala operátora děkuji',
		'Operátor',
		'operátorku',
		'potřebuju přepojit na operátora',
		'potřebuju to řešit s operátorem',
		'potřebuju řešit operátora',
		'prosil bych pana operátorka nebo paní operátorku',
		'prosím operátora',
		'přepojit k operátorovi přepoj mě',
		'přepojit na operátora',
		'přepojtě mě na operátora',
		'Si zavolal operátorovi',
		'spoj mě v operátorem',
		'spojením na operátorku',
		'spojit s operátorem',
		'spojte mě s operátorem',
		'toto je něco na operátora',
	],
	AGENT: ['Agent', 'dejte mě na agenta', 'chci mluvit s agentem', 'na agenta', 'přepojte mě na agenta'],
	PREPOJIT: [
		'ano chci přepojit',
		'jo přepojit chci',
		'můžu se někam přepojit',
		'přepoj mě',
		'přepojit mě nahoru',
		'přepojte mě už',
		'přepojte mě',
		'raději mě přepojte',
		'tak mě konečně přepojte',
		'víte co? radši mě přepojte',
		'okamžitě mě přepojte',
	],
	ASISTENT: [
		'Asistent',
		'chci přepojit na asistenta',
		'chtěl bych spojit s asistentem',
		'chtěla bych mluvit s paní asistentkou',
		'chtěla bych spojit s asistentem',
		'dát mi asistenta k telefonu',
		'dáte mi k telefonu asistenta',
		'dáte mi k telefonu asistentku',
		'dáte mi k telefonu paní asistentku',
		'děkuji přepojte asistenta',
		'já bych potřeboval už se přepojit na vašeho asistenta',
		'mohla bych být spojena s vaším asistentem',
		'potřebovala bych asistenta a něco s ním domluvit',
		'potřebuju mluvit s asistentem ne robotem',
		'potřebuju mluvit s asistentem',
		'potřebuju se domluvit s asistentem',
		'potřebuju se dovolat asistentovi',
		'potřebuju se dovolat na asistenta',
		'prosím o přepojení na asistenta',
		'prosím vás mohla bych být spojena s ňákym vaším asistentem',
		'přepojil byste mě na asistenta',
		'přepojit na asistenta prosím',
		'přepojit na asistentku na pobočce',
		'přepojit na asistentku',
		'přepojte mě na asistenta',
		'přála bych si aby sem byla spojená s asistentem',
		'přála bych si spojit s asistentem',
		'spojte mě s asistentem',
		'volám asistentovi',
		'zapne asistenta k telefonu',
	],
	KONZULTANT: [
		'chtěl bych se obrátit na vašeho konzultanta',
		'je prosím k dispozici váš konzultant',
		'mohl by mi pomoci váš konzultant',
		'mohl by mi poradit váš konzultant',
		'mohl byste mě přepojit na vašeho konzultanta',
		'potřebuju mluvit s konzultantem',
		'přepojil byste mě na vašeho konzultanta',
		'přepojíte mě prosím na vašeho konzultanta',
		'připojit se na konzultanta',
		'ráda bych mluvila s konzultantem',
		'spojte mě s konzultantem',
	],
	KOMPETENTNA_OSOBA: [
		'ano chci přepojit na kompetentní osobu',
		'chci kompetentní osobu',
		'kompetentní osobu prosím',
		'mohli byste mi dát kompetentní osobu',
	],
	PREPOJIT_NA_LINKU: [
		'přepojit na linku',
		'přepojit na zákaznickou linku',
		'přepojíte mě na vaší zákaznickou linku',
		'připojit na zákaznickou linku',
	],
	ORDER: [
		'chci se ujistit o stavu mé objednávky',
		'chci zkontrovat stav své objednávky',
		'jak je na tom moje doručení objednávky?',
		'jak je na tom moje objednávka',
		'Jak jste na tom s vyřízením mojí objednávky?',
		'jaký je stav mé objednávky',
		'kdy bude doručena moje objednávka',
		'kdy někdo přijede s tou objednávkou?',
		'kdy někdo zavolá kvůli té objednávce?',
		'kdy přijde moje objednávka',
		'mám dotaz na objednávku',
		'není nějaká změna v mé objednávce',
		'potřebuji informace k nové objednávce',
		'stav objednávky',
		'v jakém stavu je moje objednávka?',
	],
	OTHER_MODEM: [
		'Je to jiný modem',
		'Je to jiný typ modemu',
		'Jiný modem',
		'Mám jinej',
		'Mám jinou značku',
		'Mám jiný',
		'je to inakší',
	],
	GOT_IT_FROM_ELSEWHERE: [
		'Mám ho odjinud',
		'Mám ho z Alzy',
		'Mám ho z bazaru',
		'Sám jsem si pořídil',
		'je z heureky',
		'jinde',
		'koupil jsem ho v softcomu',
		'koupila jsem ho jinde',
		'koupila jsem ho někde jinde',
		'koupili jsem si ho jinde',
		'koupili jsme si někde jinde',
		'ne, odjinud',
		'od jinde',
		'od jinud',
		'od jiného prodejce',
		'od někoho jiného',
		'od známýho',
		'sehnal jsem si ho jinde',
		'sehnala jsem ho jinde',
	],
	HAVE_HIS_OWN: [
		'mám můj',
		'mám můj modem',
		'mám náš',
		'mám svůj',
		'mám svůj modem',
		'mám vlastní modem',
		'máme náš vlastní modem',
		'máme vlastní',
		'můj',
		'náš',
		'svůj',
		'vlastní',
	],
	PEVNY_INTERNET: [
		'internet přes drát',
		'internet přes kabel',
		'pevná linka',
		'pevné internetové připojení',
		'pevné připojení',
		'porucha pevného připojení',
		's kabelovým připojením',
		's pevným internetem',
		's pevným',
	],
	PEVNY_INTERNET_HOME: [
		'blbne internet v domě',
		'celý barák je bez internetu',
		'doma',
		'domácí internet',
		'domácí připojení',
		'internet na doma',
		'internet u mě doma',
		'internet u nás doma',
		'internet v domácnosti',
		'internet, co máme doma',
		'nefunguje připojení v celém baráku',
		'nejede připojení v celém domě',
		'net v baráku',
		'nikomu v domě nefunguje internet',
		'potíže s připojením internetu u nás doma',
		'problém s internetem na doma',
		'přestal fungovat internet v celém baráku',
		'připojení doma',
		'připojení v baráku',
		'připojení v domě',
		'u mě doma',
	],
	PLATBA_BLOCK: [
		'blokace prostředků',
		'potřebuju zablokovat prostředky',
		'prosím o blokaci platby',
		'zablokovat platbu',
		'zablokovat prostředky',
	],
	PLATBA_CHANGE_PAYMENT_METHOD: [
		'chci fakturu zaplatit jinak',
		'chci nastavit jiný způsob placení mojí faktury',
		'chci změnit metodu úhrady faktury',
		'chci změnit způsob placení mojí faktury',
		'mohla bych faktury platit jinak',
		'můžu fakturu zaplatit jinak',
		'můžu poprosit o jiný způsob placení',
		'potřebuju změnit způsob úhrady',
		'změna způsoba úhrady',
		'šlo by zaplatit fakturu jiným způsobem',
	],
	PLATBA_CHANGE_POSTAL_PAYMENT_METHOD: [
		'Chtěla bych změnit způsob platby účtu ze složenky na pevný příkaz',
		'chci nahradit platbu složenkou',
		'chci začít platit přes složenku',
		'chtěl bych se zbavit složenek a začít používat trvalý příkaz',
		'místo složenek chci platit z účtu',
		'nechci platit faktury složenkou',
		'nechci používat složenky',
	],
	PLATBA_CHECK: [
		'Před týdnem jsem platil vyúčtování a ještě mi nepřišla sms s potvrzením, že jste to dostali',
		'Přišla vám platba za fakturu?',
		'Vyúčtování se stále ukazuje jako neuhrazené nic tam není',
		'Zaplatil jsem vyúčtování, vidíte to v systému?',
		'chtěla bych děti jezdí mám zaplacené vyúčtování paušálu',
		'dobrý den potřebuju zjistit a na tomto čísle jestli je zaplacená složenka na březen',
		'faktura je zaplacená?',
		'hradila jsem složenku',
		'je faktura zaplacená?',
		'jestli mám uhrazené veškeré faktury co se mě účtovali',
		'jestli už mám zaplacený mobil',
		'jestli už mám zaplacený telefon',
		'mam zaplacený paušálu',
		'mám nesrovnalost s vyúčtováním v platbách',
		'mám nesrovnalosti v platbě za mobil',
		'mám zaplacenou fakturu',
		'mám zaplacenou složenku?',
		'nepřišlo mi ještě potvrzení o tom, že je faktura zaplacená.',
		'platila jsem složenku?',
		'potřeboval bych zkontrolovat fakturu',
		'potřebuju vědět, zdali už mám zaplacenou fakturu',
		'potřebuju zjistit, jestli mám zaplacenou složenku',
		've všechno v pořádku, faktura zaplacená',
		'zjistíme mi, jestli je už zaplacená složenka za minulý měsíc',
		'zkontrolovat provedenou transakci',
	],
	PLATBA_CHECK_GENERAL: [
		'Chci se zeptat, včera jsem posílal peníze, dorazilo to?',
		'Chci vyšetřit, co se stalo s mojí příchozí platbou',
		'Dohledejte prosím platbu, co jsem vám posílal',
		'Dorazila k vám moje platba',
		'Dorazila moje platba?',
		'Dorazily moje peníze?',
		'Evidujete moji platbu?',
		'Nevidím platbu v samoobsluze',
		'Nevidím platbu, můžete to zkontrolovat?',
		'Obávám se, že jsem špatně zadal platební údaje, můžete to vyšetřit?',
		'Podíváte se prosím, jestli vám dorazily peníze?',
		'Poslal jsem peníze, došly?',
		'Už vám dorazily moje peníze od banky?',
		'chci si jen potvrdit, že jste obdržely moje peníze z banky',
		'chci zkontrolovat limit plateb',
		'dohledáte, jestli vám už dorazily peníze z banky?',
		'dostali jste moje platbu?',
		'dávál jsem včera příkaz k platbě, už vám přišly peníze?',
		'evidujte mojí platbu?',
		'je zaplaceno',
		'mám v pořádku zaplaceno',
		'mám zaplaceno',
		'mám zaplaceno vše',
		'mám zaplaceno za únor?',
		'potvrzení platby',
		'potřebovala bych si ověřit, jestli vám už dorazily peníze z mojí banky',
		'potřebovala bych zjistit jednu platbu takže nemám uhrazené sype na muj telefon nebo neni',
		'potřebuju zjistit jednu platbu',
		'přišly vám peníze',
		'vše v pořádku zaplaceno',
		'za březen mám zaplaceno',
		'zaplaceno za minulý měsíc',
		'zaplaceno za únor',
		'řeknete mi jestli už vám přišly moje peníze?',
	],
	PLATBA_DELAYED_PAYMENT: [
		'chci řešit vyúčtování, protože si mi zpozdila platba',
		'potřebuju vyřešit vyúčtování zpoždění platby',
		'zpozdila se mi platba',
		'zpožděná platba za vyúčtování',
	],
	PLATBA_GENERAL: [
		'a mám problém platbou a',
		'a potřebovala bych poradit s platbou',
		'chci domluvit platbu',
		'chci radu ohledně platby',
		'chci se domluvit na platbě',
		'chtěla sem vyřešit platbu s vámi přes telefon',
		'dobrý den nemám zaplacené vyúčtování',
		'domluvení platby',
		'jednou přišla a sms je zaplaceno',
		'mohu platbu řešit přes telefon',
		'mám dotaz k platbě',
		'mám problém se zaplacením',
		'mám problém u platby',
		'měla bych zájem a poptat se na placení a t mobile',
		'měla bych zájem o platbu u vás',
		'můžeme se prosím nějak domluvit na placení',
		'no já bych potřebovala fakturu za telefon platit si',
		'placení přes kartu',
		'platba',
		'platba a',
		'platba za tento měsíc',
		'pomůžete mi s platbou přes telefon',
		'pomůžete mi s problémem s placením',
		'poradíte mi prosím u platby',
		'potřeboval bych pomoct s platbou',
		'potřebovala bych pomoct s problémem u placení',
		'potřebuji se domluvit o platbě',
		'potřebuju něco ohledně svojeho paušálu jakože abych si to zaplatila',
		'potřebuju vyřešit problémy s platbou',
		'provedenou transakci',
		'provedená transakce',
		'provedení transakce',
		'provést transakci',
		'vyúčtování plateb',
		'vyřešit problém s placením',
	],
	PLATBA_HOW_TO_PAY: [
		'Jak mám zaplatit fakturu',
		'Jak platit fakturu',
		'Jak zaplatit fakturu',
		'Jakým způsobem můžu zaplatit faktury?',
		'Přišla mi faktura a nevím jak jí uhradím',
		'chci se zeptat na způsob placení faktury',
		'dobrý den potřebuju vědět jak mám zaplatit mobil',
		'jak mám zaplatit za mobil',
		'jak mám zaplatit za všecko?',
		'jak nejrychleji zaplatím fakturu',
		'jak se dají platit faktury',
		'jak zaplatit kartou na webu',
		'jak zaplatím mobil',
		'jak zaplatím vyúčtování, když je zavřená pošta',
		'je zavřená pošta, jak mám zaplatit',
		'jsou uzavřené pošty a potřebuju zaplatit fakturu',
		'mám jenom složenku, nemám jak jinak zaplatit fakturu',
		'nemůžu zaplatit fakturu, protože jsou zavřené pošty',
		'nevím, jak se vám dovolat na info ohledně platby',
		'né já potřebuju vědět jak budu platit do si vyúčtování když je zavřená pošta',
		'platba mobilního telefonu',
		'pomoc s provedením transakce',
		'přehled způsobů placení faktur',
		'tak dobře mám jenom složenku a internet nemám tak jak mám zaplatit fakturu',
		'zaplacení telefonu',
		'způsob placení faktury',
		'způsob, jak platit',
		'způsoby placení',
	],
	PLATBA_LOST: [
		'Bylo mi zrušené číslo, přitom jsem platil na pobočce',
		'Poslal jsem platbu a nejspíš nedorazila',
		'Poslal jsem platbu, nedorazila',
		'Přišla mi SMS o nezaplaceném vyúčtování, přitom jsem platil',
		'Přišla mi SMS, že nemám zaplacenou fakturu',
		'Přišla mi upomínka, i když jsem již zaplatila',
		'Přišlo mi že nemám zaplacený účet, přitom jsem platila',
		'Ztracená platba, co mám dělat?',
		'byly mi zablokovány služby, ale vše jsem hradil',
		'dostal jsem informaci, že vám platba nedorazila, přitom jsem platil',
		'hradil jsem na prodejně, ale faktura je stále nezaplacená',
		'mně teď přišla nějaká zpráva, že mám zaplatit, ale já už jsem přitom fakturu zaplatila',
		'no přišla nám jako nezaplacená a my již zaplatili v pátek minulý',
		'platila jsem včera a dostala jsem info o nezaplacení faktury',
		'přišla mi sms o nezaplacení, ale platila jsem',
		'přišla mi sms, že nemám zaplacené vyúčtování, já už ale fakturu platila',
		'přišla mi upomínka a nevím o tom, že bych fakturu nezaplatil',
		'už jsem zaplatila a stále mám zablokované volání',
		'zaplatila jsem, ale přišly mi sms, že neevidujete dvě platby',
		'zapomněl jsem, jestli jsem poslal platbu za fakturu, co s tím?',
	],
	PLATBA_NOT_RECEIVED: [
		'Neobdržela jsem dosud složenku abych mohla zaplatit telefon.',
		'Nepřišla mi složenka na zaplacení',
		'chci zaplatit telefon, ale stále nemám údaje',
		'chci zaplatit za telefon, ale ještě mi nepřišly platební údaje',
		'chci zaplatit, ale ještě mi nedošla faktura',
		'chtěla bych fakturu na zaplacení telefonu',
		'ještě jsem nedostala fakturu s podklady k platbě za telefon',
		'nemůžu zaplatit, ještě mi nepřišla složenka',
		'no já bych potřebovala fakturu za telefon platit si ten',
		'no nepřišel mi a složenkami nepřišla na zaplacení telefonu',
		'potřebovala bych zaplatit, ale nedošla mi složenka',
		'potřebuju fakturu, abych mohla zaplatit telefon',
		'potřebuju podklady, abych mohla zaplatit telefon',
		'potřebuju údaje k platbě, ještě mi nic nepřišlo',
		'stále ještě nemám složenku, abych mohla zaplatit',
	],
	PLATEBNUDAJE: [
		'Chci vědět platební údaje',
		'chtěl bych platební údaje',
		'jaké jsou údaje pro zaplacení',
		'kdy mi pošlete platební údaje',
		'Můžete mi poslat údaje, kam mám poslat fakturu',
		'Můžete mi říct moje údaje k placení?',
		'nemám od vás platební údaje',
		'platební údaje prosím',
		'platební údaje',
		'potřebuji zaslat platební údaje, abych mohl provést platbu',
		'potřebuji údaje pro zaplacení faktury',
		'pošlete mi platební údaje, chci zaplatit fakturu',
		'prosím o zaslání platebních údají',
		'rád bych zaplatil, ale nemám údaje',
		'údaje pro úhradu faktury',
		'údaje z faktury pro její zaplacení',
	],
	PLATBA_PAY_AMOUNT: [
		'jak je vysoký můj paušál',
		'jako moc peněz mám poslat',
		'jakou částku budu platit za telefon můžete mi říct za měsíc červen',
		'jakou částku platí měsíčně',
		'kolik korun mám platit',
		'kolik korun to dělá',
		'kolik mám poslat peněz',
		'kolik mám tenhle měsíc platit',
		'kolik mám zaplatit',
		'kolik peněz mám platit',
		'kolik peněz to dělá',
		'kolik vám mám zaplatit za paušál',
		'netuším, kolik mám zaplatit',
		'nevím, kolik mám poslat korun',
		'no kolik mám za tento měsíc zaplatit',
		'potřebuji vědět, kolik mám platit paušál',
		'za kolik korun to mám',
		'za kolik mám platit paušál',
		'údaje pro zaplacení',
		'částka pro zaplacení',
	],
	PLATBA_VS: [
		'Chci svůj variabilní symbol',
		'Potřeboval bych zjistit svůj variabilní symbol',
		'Variabilní symbol',
		'asi jsem ztratil variabilní symbol, můžete mi ho poslat znovu?',
		'chybný variabilní symbol',
		'co mám dát jako variabilní symbol?',
		'dal jsem tam blbý variabilní symbol',
		'jaký mám přiřazený variabilní symbol',
		'jaký mám uvést variabilní symbol',
		'jaký mám zadat variabilní symbol',
		'můžete mi poslat variabilní symbol',
		'neuvedl jsem při platbě variabilní symbol, co teď?',
		'pošlete mi ještě jednou variablní symbol',
		'při platbě byl zadán špatně a variabilní symbol',
		'uvedla jsem špatný variabilní symbol',
		'zapomněl jsem zadat variabilní symbol',
		'špatně jsem zadala variabilní symbol',
	],
	PLATBA_WANT_ACCOUNT: [
		'potřebovala bych číslo účtu abych mohla zaplatit za telefonování',
		'potřebuju zaplatit fakturu, ale neznám číslo účtu',
		'potřebuju znát číslo účtu k zaplacení',
		'pošlete mi číslo účtu abych mohla zaplatit',
		'prosím o číslo účtu k zaplacení',
	],
	PLATBA_WANT_TO_PAY: [
		'ale rádi bychom zaplatili vyúčtování',
		'chceme zaplatit vyúčtování',
		'chci uhradit mobil',
		'chci uhradit paušál',
		'chci uhradit telefon',
		'chci zaplatit mobil',
		'chci zaplatit paušál',
		'chci zaplatit paušál za mobil',
		'chci zaplatit služby za mobil',
		'chci zaplatit za paušál',
		'chtěla bych platbu odložit na příští měsíc',
		'chtěli bychom platit paušál',
		'dobrý den potřebuju zaplatit vyúčtování své manželky',
		'můžu zaplatit telefon',
		'placení potřebuju vyřešit',
		'placení prosím',
		'pomozte mi s placením',
		'potřeboval bych poslat sms s údaji, vyúčtování za toto období nemám zaplacené',
		'potřeboval bych uhradit paušál',
		'potřebovala bych fakturu za telefon zaplatit si',
		'potřebovala bych prodloužit platbu na příští měsíc',
		'potřebovala bych zaplatit paušál až příští měsíc',
		'potřebujeme zaplatit vyúčtování',
		'potřebuji platit',
		'potřebuji zaplatit za',
		'potřebuju doplatit věci který tam u vás mám',
		'potřebuju zaplatit',
		'potřebuju zaplatit fakturu za telefon',
		'potřebuju zaplatit paušál za telefon',
		'potřebuju zaplatit za telefon',
		'ráda bych uhradila vyúčtování',
	],
	PLATBA_WHEN_TO_PAY: [
		'chtěla bych vědět, kdy platím televizi',
		'dobrý den chtěl bych si zjistit kdy mám platit televizi i internet',
		'kdy mám zaplatit za telefon',
		'potřebuju vědět kdy platim za televizi',
		'řekněte mi, kdy mám zaplatit za telefon',
	],
	PLATBA_WRONG_ACCOUNT: [
		'Nemůžu zaplatit, píše mi to neexistující účet',
		'Peníze jsem poslal na nesprávný účet',
		'Peníze odešly na špatný účet.',
		'Platba mi neprošla, účet neexistuje.',
		'Platbu jsem poslala na blbý účet',
		'Poslal jsem omylem peníze na nesprávný účet',
		'Poslal jsem peníze na neexistující účet',
		'Poslal jsem peníze na špatný účet',
		'Píše mi to neexistující účet.',
		'Zaplatila jsem na špatné číslo účtu',
		'platba šla asi na špatný účet, protože se mi poslané peníze vrátily nazpátek',
		'poslal jsem peníze na účet, ale peníze se mi vrátily zpět',
		'zaplatil jsem telefon a peníze se mi vrátily zpátky',
		'účet na který chci posílám platbu neexistuje',
	],
	PLATEBNUDAJE_ACCOUNT_NUMBER: [
		'Na jaký účet mám poslat peníze',
		'jaký byl ten účet kam poslat prachy?',
		'nemám číslo účtu pro úhradu',
		'nevím, na jakej účet poslat peníze',
		'pošlete mi číslo účtu pro platbu',
		'vymazala jsem si zprávu s číslem účtu pro úhradu',
		'účet pro platbu',
		'na jaký účet mám zaplatit za fakturu',
	],
	PNEU_SERVICE: [
		'chci si nechat přezout auto',
		'chci si nechat vyměnit pneumatiky',
		'chci si zarezovovat termín na přezutí auta',
		'dá se u vás objednat na výměnu pneumatik',
		'musím přezout auto',
		'můžete mi vyměnit gumy',
		'můžu u vás nechat přezout auto',
		'nechat vyměnit kola',
		'potřebuje výměnu pneu z letních na zimní',
		'potřebuju gumy na přezutí',
		'potřebuju přezout auto',
		'potřebuju přijet přehodit gumy',
		'přehodit gumy na letní',
		'přehodit gumy na zimní',
		'přezout auto na letní pneu',
		'přezout auto na zimní pneu',
		'přezout gumy na autě',
		'přezout kola',
		'přezout pneu',
		'přezutí auta na letní pneumatiky',
		'přezutí na zimní pneumatiky',
		'přezutí pneu',
		'přezutí pneumatik',
		'vyměníte mi kola za zimní',
		'vyměníte mi pneu za zimní',
		'výměna kol',
		'šlo vy u vás nechat vyměnit kola',
	],
	POJISTENAMOUNT: [
		'chci znát výši pojištění',
		'jaká je cena za pojištění',
		'jaká je moje výše pojištění',
		'jaká je výše pojištění',
		'kolik mám platit za pojištění',
		'kolik se u vás platí za pojištění',
		'kolik stojí pojistění',
		'kolik stojí pojištění na televizi',
		'kolik stojí pojištění notebooku',
		'na kolik mi vychází pojištění',
		'výše platby za pojištění',
		'za kolik pojišťujete telefon',
		'zajímá mě výše mého pojištění',
		'řekněte mi částku za pojištění',
	],
	POJISTENCANCEL: [
		'chci ukončit pojistku na chytré auto',
		'chci zrušit pojištění',
		'informace o zrušení pojištění',
		'můžete mi zrušit pojištění',
		'potřebuju ukončit pojištění na notebook',
		'už nechci platit pojištění na chytré hodinky',
		'už nechci pojistku na modem',
		'zajímají mě informace o zrušení pojištění',
		'zajímá mě zrušení pojištění',
		'zrušení pojištění',
		'zrušili byste mi pojištění na telefon',
		'zrušit pojištění televize',
	],
	POJISTENCHANGE: [
		'chci zvýšit pojištění',
		'lze změnit částku pojištění',
		'mohu si změnit pojištění',
		'můžu si snížit pojištění',
		'můžu si změnit výši pojištění',
		'potřebovala bych změnit částku pojištění',
		'zajímá mě změna výše pojištění',
		'změna výše pojištění',
	],
	POJISTENCHECK: [
		'chci zjistit, jestli mám pojištěný telefon',
		'co obsahuje moje pojištění',
		'jak mám pojištěné chytré auto',
		'jakou mám pojistku na chytré hodinky',
		'jaké mám pojištění na laptop',
		'jaké u vás mám pojištění',
		'jaký je obsah mého pojištění',
		'mám pojistku na modem',
		'mám pojištěnou televizi',
		'pojistil jsem u vás telefon',
		'pojistila jsem si notebook?',
		'pojišťoval jsem set-to-box',
		'potřebuju zjistit jaké mám pojištění',
	],
	POJISTENGENERAL: [
		'chci pojištění',
		'děláte i pojištění',
		'děláte pojištění proti poškození jinou osobou',
		'jaké pojištění nabízíte',
		'mohu se pojistit, kdybych nemohla splácet',
		'mohu si u vás sjednat pojištění',
		'mám dotaz ohledně pojištění',
		'máte pojištění, které zahrnuje poškození dětmi',
		'máte více druhů pojištění',
		'můžu si pojištění zařídit po telefonu',
		'můžu si zřídit pojištění proti krádeži',
		'pojistka mobil',
		'pojištění',
		'pojištění proti poškození',
		'pojištění schopnosti splacení',
		'pojišťujete proti poškození třetí osobou',
		'pojišťujete proti rozbití',
		'poskytujete pojištění',
		'poskytujete pojištění na splácení',
		'potřebuji pojištění',
	],
	POJISTENINSURANCE: [
		'chci mluvit o placení pojištění',
		'chci se bavit o platbách pojištění',
		'chtěla bych se zeptat na platby pojištění',
		'o platbách pojištění',
		'placení pojištění',
		'potřebovala bych pořešit placení pojištění',
	],
	POJISTENNEW_INSURANCE: [
		'chci pojistit chytré auto',
		'chci pojistit proti krádeži',
		'chci pojištění na splácení, kdybych přišel o zaměstnání',
		'chci pojištění schopnosti splácet',
		'chci si zařídit pojištění',
		'chci zařídit pojištění proti poškození dětmi',
		'chci zaříditpojištění Pro jistotu splacení',
		'chtěl bych pojistit u vás koupený telefon',
		'chtěl bych pojištění',
		'chtěl bych pojištění Pro jistotu',
		'chtěl bych pojištění, kdyby mi ukradli telefon a zneužili sim',
		'chtěl bych si zřídit pojištění na splácení',
		'lze pojistit chytré hodinky',
		'můžete mi pojistit set-to-box',
		'nové pojištění',
		'pojistit laptop',
		'pojistíte mi modem',
		'potřeboval bych pojištění na mobil',
		'potřeboval bych pojištění proti poškození třetí osobou',
		'potřebuju nové pojištění',
		'potřebuju pojištění proti poškození',
		'potřebuju pojištění proti rozbití',
		'potřebuju řešit nové pojištění',
		'prosím o pojištění televize',
		'ráda bych měla pojištěný notebook',
		'zajímá mě nové pojištění',
	],
	POJISTNA_UDALOST_INSURED_EVENT: [
		'chci nahlásit pojistnou událost',
		'jde o pojistnou událost',
		'nahlášení pojistné události',
		'pojistná událost',
		'potřebuju nahlásit pojistnou událost',
	],
	PORUCHA_INTERNETU_INTERNET_MODEM_PRIPOJENI: [
		'Mám doma pevný internet a ten router nefunguje.',
		'Mám doma ten router a nefunguje a nesvítí.',
		'Potřeboval bych pomoct s připojením modemu',
		'Potřeboval bych pomoct s připojením router',
		'Potřebuju připojit modemu',
		'chci nahlásit poruchu modemu',
		'nefunguje mi zyxel',
		'technická pomoc s routrom',
	],
	MOBILE_INTERNET_BAD_SIGNAL: [
		'dostal jsem  připojení k internetu a nejede nám signál',
		'dostal jsem mobilní internet a nejede nám signál',
		'mám potíže s přenosným netem, já to zprovozňuji. Nejede nám signál.',
		'nefunguje net v mobilu, nemám signál',
		'nejdou dáta v mobilu pro slabý signál',
		'nejede mi net v mobilu, není signál',
		'potíže s internetem v mobilu, nekvalitní signál, vypadáva',
		'problém s internetem v mobilu, bez signálu',
	],
	NEFUNGUJE_INTERNET: [
		'hlásím poruchu internetu',
		'internet funguje úplně špatně',
		'internet mi nejede',
		'internet mě funguje úplně špatně',
		'Internet nefunguje',
		'Internet nefungujeme',
		'internet nejede',
		'internet přestal fungovat',
		'internet vůbec nefunguje',
		'internet vůbec nejede',
		'jsem odpojený od internetu',
		'já bych chtěl nahlásit poruchu na internetu dsl',
		'moc tomu nerozumím, ale nefunguje internet',
		'mám nefunkční internet',
		'Mám nestabilní internet',
		'mám něco s internetem, nejde',
		'mám od vás pevný internet do zásuvky, ale nefunguje.',
		'na potřebuji technickou podporu pro nefungující internet',
		'Neběží mi internet, potřebuji nahlásit poruchu.',
		'nefachá mi net',
		'nefunguje internet',
		'nefunguje mi internet nevím proč',
		'nefunguje mi internet pevný',
		'nefunguje mi internet',
		'Nefunguje nám internet',
		'Nefunguje nám už delší dobu internet',
		'nefunguje náš pevný internet',
		'nefungujeme Internet',
		'nefunkční internet',
		'nefunkční pevný internet',
		'nejde internet',
		'nejde mi internet',
		'nejede mi internet, je chybu u vás?',
		'nejede mi net',
		'nemůžeme nastartovat internet',
		'Nemůžu se dostat na internet',
		'net je rozbitý',
		'no, nefunguje internet',
		'od rána nám nefunguje internet',
		'podle něj špatně funguje internet',
		'porouchaný internet',
		'Porucha internetu',
		'Porucha netu',
		'potřebuju odstranit problém s internetem',
		'potřebuju opravit internet',
		'potřebuju zprovoznit internet',
		'proč mi nejde internet',
		'proč přestal fungovat internet',
		'přestal mi fungovat internet',
		'přestal mi jít internet',
		'Přestal nám fungovat internet',
		'Přestává mi fungovat internet vzduchem',
		'ten internet co jsem si od vás koupit už zase nefunguje.',
		'ten špatný internet',
		'už několik hodin jsem bez internetu',
		'velmi špatně funguje net',
		'včera jsme se přestěhovali a nefrčí nám internet',
		'vůbec mi nefunguje internet',
		'vůbec mi nejede net',
		'Řeším poruchu internetu',
		'špatný internet',
		'špatný nefuknční internet',
		'špatný nefungující internet',
		'špatně funguje internet',
		'Špatně fungující internet',
		'špatně mi funguje internet',
	],
	INTERNET_PROBLEM_S_INTERNETEM_NEFUNGUJE_CONNECTION: [
		'chci se připojit k internetu, ale stále to selhává',
		'internetové připojení nefunguje',
		'mam potíže s připojením',
		'mám nějak zamrzlý připojení k internetu',
		'mám problém s připojením',
		'Nedaří se mi připojit k internetu',
		'nefunguje mi připojení, nemůžu se podívat na své oblíbené stránky',
		'nefunguje připojení k internetu',
		'nejde mi připojení internetu',
		'nejde připojení k internetu',
		'Nejde se mi připojit k internetu',
		'nejde se mi připojit k internetu, co s tím?',
		'Nemůžu se připojit k internetu',
		'nemůžu se připojit na internet',
		'Nemůžu se připojit',
		'nějak mi blbne připojení',
		'problém s připojením k internetu',
		'píše mi to: Nelze se připojit k internetu',
		'připojení internetu nefunguje',
		'připojení k internetu nefunguje',
		'připojení k internetu není k dispozici',
		'připojení selhává',
		'špatné připojení k internetu',
		'špatně se připojuje internet',
	],
	PORUCHA_INTERNETU_NEFUNGUJE_INTERNET_ROAMING: [
		'na čísle nefunguje internet přes roaming',
		'nefunguje internet na Roamingu',
		'nefunguje internet v zahraničí jak to?',
		'nefunguje roaming internet',
		'nejede mi internet roaming v zahraničí',
	],
	PORUCHA_INTERNETU_NEFUNGUJE_INTERNET_ZAKAZNIK_ZMETENY: [
		'dneska mi přišel balíček s plno kabelama k pevnýmu internetu a já nevím čím vším a já si s tím nevím rady.',
		'mám pevný internet, poslali jste mi balík s plno kabelama a ja nevím co s tím',
		'měla jsem tu v pondělí technika, který mi zavedl kabel, a furt to nejede',
	],
	PORUCHA_SMS_DELAY: [
		'Chodí mi opožděně SMS',
		'Chodí mi se zpožděním SMS',
		'SMS mi chodí s velkým zpožděním',
		'dlouhý interval mezi odesláním a doručením sms',
		'opožďují se mi sms',
		'sms mi chodí až za dlouho po odeslání',
		'sms mi přichází o dost později než byly poslány',
		'smsky mi chodí o hodinu později',
		'smsky mi chodí opožděně',
		'zpožďují se mi sms',
	],
	PORUCHA_SMS_GENERAL_SMS: ['chci řešit sms', 'mé sms', 'pro smsky', 'sms', 'textové zprávy sms', 'zprávy'],
	PORUCHA_SMS_NOT_OPEN: [
		'Nemůžu načíst SMS',
		'Nenačítají se mi SMS',
		'Nezobrazují se mi SMS',
		'když kliknu na novou sms, tak se mi neukáže',
		'nedaří se mi otevřít smsky',
		'nefunguje mi otevírání smsek',
		'nefunguje mi zobrazování smsek',
		'nefunguje zobrazování smsek',
		'nejde mi otevřít textovku',
		'nejde mi otevřít textovou zprávu',
		'nejdou mi otevřít smsky',
		'nelze mi zobrazit žádnou smsku',
		'nemůžu otevřít smsku',
		'nemůžu otevřít smsky',
		'nemůžu otevřít textovku',
		'nemůžu otevřít zprávy',
		'neotevírají se mi smsky',
		'neotvírají se mi smsky',
		'neukazuje se mi text nové sms zprávy',
		'otevírání smsek nefunguje',
		'přestaly se mi zobrazovat textovky',
		'zprávu nelze zobrazit. Co to je?',
	],
	NEFUNGUJU_SMS: [
		'blbnou mi smsky',
		'když chci poslat zprávu, tak neodejde',
		'Když zasílám SMS odeslání se pokaždé přeruší',
		'Mobil mi říká, že SMS nejde poslat ačkoliv mám signál',
		'Mám asi zablokované SMS, nejdou posílat',
		'mám nefunkční sms',
		'Na mým iPhonu nemůžu posílat SMS na ostatní čísla',
		'Nechodí mi SMS od mých kamarádů',
		'Nechodí mi SMS',
		'Nechodí SMS',
		'nechtějí mi chodit smsky',
		'nechtějí mi fungovat smsky',
		'nedaří se mi odeslat sms',
		'nefunguje odesílání smsek',
		'nefungují mi sms od kamarádů',
		'nefungují mi sms',
		'nefungují mi zprávy',
		'Nefungují SMS',
		'Nejdou mi odeslat SMS z androidu',
		'nejdou mi posílat textovky',
		'nejdou mi smsky',
		'nelze odeslat sms',
		'Nemůžu poslat SMS',
		'Nemůžu posílat SMS zprávy',
		'Neposílají se SMS',
		'odesílání sms se nezdařilo',
		'odesílání smsky pořád selhává',
		'porucha sms',
		'posílání sms',
		'potíže s sms',
		'potíže s textovými zprávami',
		'potřebuji, abych mohla odesílat zprávy',
		'potřebuji, abych mohla posílat sms zprávy',
		'Problém s SMS',
		'problém s textovkami',
		'Problém s textovými zprávami',
		'problém se zprávami',
		'prosím stále mi nechodí sms',
		'Psala jsem SMS ale nedorazila, asi se neodeslala',
		'Přestali mi chodit SMS',
		'rozbily se mi smsky',
		'rozbité sms',
		'SMS zprávy nefungují',
		'SMSky nefungují',
	],
	PORUCHA_TV_BROKEN: [
		'Nikdo se neozval, televize blbe pořád dál.',
		'blbne televize',
		'hlásil jsem poruchu na televizi a nic se neděje',
		'je to krám mizernej, blbá televize',
		'mám rozbitou televizi',
		'rozbila se televize',
		'rozbitá televize',
	],
	PORUCHA_TV_BROKEN_OVLADAC: [
		'blbne zas ovladač',
		'ovladač je pokažen',
		'ovladač je porouchanej',
		'ovladač nefunguje',
		'ovladač nejde',
		'poruchu ovladač',
		'rozbil se ovladač',
	],
	PORUCHA_TV_INTERNET_TV_NOT_WORKING: [
		'Udělal jsem si u vás internetovou televizi a teď jsem to zkoušel přes mobil a nefunguje',
		'internet televize chce po mně uživatelské jméno a to vůbec netuším, nedá se to u vás nějak zjistit?',
		'nevím jaké mám jméno a heslo do mobilné internet televize',
	],
	PORUCHA_TV_NEFUNGUJE_SATELIT: [
		'Chci oznámit, že mi přestal fungovat satelit',
		'chtěla bych nahlásit poruchu satelitní televize',
		'hlásím poruchu satelitu',
		'je to nefunkční, televize cez satelit',
		'nefunguje satelit',
		'přes noc přestal jít satelit',
		'satelit nejede',
		'satelitná televize nefuguje',
	],
	PORUCHA_TV_NEFUNGUJOU_PROGRAMY: [
		'Chci oznámit, že mi přestali fungovat programy',
		'Nám nejdou základní programy',
		'na televizi nám nejedou žádné programy',
		'nefunguje program na televizi',
	],
	TV_NOT_WORKING: [
		'blbne zas televize',
		'co se děje s televizí, nějak to nejede',
		'mám pokaženou telku',
		'nefachá televize',
		'nefunguje televize',
		'nefunguje TV',
		'nefunkční televize',
		'nejde telka',
		'nejede televize',
		'něco je s telkou, vůbec nefunguje',
		'pokazila se televize',
		'pokažená televize',
		'porouchala se televize',
		'porouchaná televize',
		'porucha televize',
		'přestala fungovat telka',
		'přestala jet televize',
		'televize je pokažená',
		'televize je porouchaná',
		'televize nefunguje',
		'televize nejde',
		'televize vůbec nefunguje, zapnu to a nic',
		'Včera televize přestala fungovat úplně.',
	],
	PORUCHA_VOLANCANT_CALL: [
		'Když se mi snaží dovolat, tak to nejde',
		'Nedaří se mi dovolat na jiné číslo',
		'Nemůžu se dovolat',
		'Nemůžu se dovolat manželce.',
		'Nemůžu se dovolat na číslo',
		'Nemůžu se spojit s druhou stranu po telefonu',
		'Nemůžu se ze svýho iPhonu nikomu dovolat',
		'Z mého telefonu se nemůžu nikam dovolat',
		'co znamená, že volaný účastník není dostupný',
		'manželka říkala, že se ji určitá čísla nemohou dovolat.',
		'mám kartu a nechce mi to volat',
		'mám problém se někam dovolat',
		'mí přátelé se mi nemohou dovolat',
		'najednou se nemůžu nikam dovolat',
		'nechce mi to volat',
		'nedovolám se kam, pořád to hlásí nedostupný',
		'nedovolám se příteli, on mě ano',
		'nedá se mi dovolat',
		'nejde mi volat, prej nemám krediť, ale ja mám paušál',
		'nemůžu se dovolat příteli',
		'nemůžu se nikomu dovolat, prej nemám krediť, ale mám paušál',
		'není možné se ke mě dovolat',
		'nikam se nedovolám',
		'nikdo, komu volám, není dostupný',
		'některé čísla se mi nemohou dovolat',
		'němůžu se nikam dovolat, pořád mi to hlásí nedostupnost',
		'potřebuju zjistit problém s jedním číslem proč nejde volat',
	],
	PORUCHA_VOLANCANT_DIAL: [
		'Nemůžu vytočit číslo',
		'Nemůžu vytočit žádné číslo',
		'nedaří se mi vytočit žádné číslo',
		'nefunguje vytáčení čísel',
		'nejde mi vytáčení čísel',
		'nejde mi vytáčet žádné číslo',
		'rozbité vytáčení čísel',
	],
	PORUCHA_VOLANCANT_HEAR: [
		'Mám hluchý telefon',
		'Nemůžu s nikým telefonovat, protože mám hluchý telefon',
		'Neslyším druhou stranu, když s někým volám',
		'Při hovoru neslyším druhou stranu',
		'v telefonu nic neslyším',
		'Špatně slyším druhou stranu',
	],
	PORUCHA_VOLANDISCONNECTION: [
		'Když někomu volám hovor se ukončí automaticky',
		'Když s někým mluvím na telefonu hovor se mi ukončí sám od sebe',
		'Když telefonuju přeruší se mi hovor',
		'Když telefonuju sekají se mi hovory',
		'Vypadávají mi hovory během telefonování',
		'chci si normálně zavolat, ale hrozně mi to vypadává',
		'přerušuje eště mi telefon',
		'sám se mi přerušuje telefon při volání',
		'telefon přerušuje hovory',
	],
	PORUCHA_VOLANEMERGENCY_CALL: [
		'fungují mi na telefonu jen tísňové volání',
		'mobil mi ukazuje, že jenom tísňová volání',
		'můžu se dovolat jen na tísňová volání',
		'nemůžu nic vytočit, kromě tísňového volání',
		'nemůžu se dovolat na žádnou linku kromě tísňové linky',
		'nemůžu se nikam dovolat, kromě tísňové linky',
		's nikým se nemůžu spojit kromě tísňové linky',
	],
	PORUCHA_VOLANFOURTH_CHOICE: [
		'chci to čtvrté, co jste říkala',
		'možnost 4',
		'ta čtvrtá varianta',
		'to čtvrté',
		'tu čtvrtou volbu',
		'varinta čtyři',
		'čtvrtá možnost',
	],
	NEDOVOLA_SE_MI: [
		'Mně se nemůžou dovolat',
		'mám problém s telefonováním',
		'nedovolá se mi vůbec nikdo',
		'nefunguje mi příjímaní hovoru',
		'Nefunguje volání',
		'nejde mi příjímaní hovoru',
		'nejde mi volat na pevné číslo',
		'nejde mi volat na číslo na pevné lince',
		'nejde se dovolat na moje číslo',
		'nejde se mi dovolat',
		'Nejde telefonovat',
		'nejde volat na můj telefon',
		'Nejde volat',
		'nemůže se mi nikdo dovolat',
		'nemůžou se mi dovolat žádní lidi',
		'Nemůžu nikomu volat',
		'Nemůžu přijímat hovory',
		'nemůžu volat',
		'nikdo se mi nedovolá',
		'nikdo se mi nemůže dovolat',
		'opravíte mi prosím volání',
		'Porucha hovorů',
		'Porucha volání',
		'problém s voláním',
		'proč se mi vnuk a dcera nemůžou dovolat',
		'telefon má signál, přesto se mi nedá dovolat',
		'telefonování nefunguje',
		'telefonování nejde',
	],
	PORUCHA_VOLANGENERAL_VOLAT: [
		'hovor',
		'hovory',
		's voláním',
		'telefonovat',
		'volat',
		'volat na pevné číslo',
		'volání',
	],
	PORUCHA_VOLANNETWORK: [
		'dobrý den potřeboval bych pomoc nastavení mobilní sítě',
		'mobilní sítě',
		'poprosím u jineho číslo které vám nadiktuju pomoci vyřešit problém s připojením k síti',
		'potřebovala bych vyřešit připojení k síti',
	],
	PORUCHA_VOLANOTHER_OPERATOR: [
		'Nefunguje mi volání na čísla jiných operátorů',
		'nedovolám se na čísla jiných operátorů',
		'nelze se dovolat na telefonní čísla jiných operátorů',
		'nemůžu se dovolat na čísla u jiných mobilních operátorů',
		'nemůžu se dovolat na čísla vodafonu',
	],
	PORUCHA_VOLANSIGNAL: [
		'Mám doma nekvalitní signál, vypadávají mi hovory, co s tím',
		'Nejede nám signál.',
		'Přestěhoval jsem se a od té doby se mi nikdo nedovolá. Jak to tady máte se signálem?',
		'dobrý den nejede nám signál na připojených mobilech',
		'mobilní signál nejede',
		'na čísle popisném 195 špatný signál',
		'nechytám mobilní signál',
		'nefunguje nám signal na mobilu',
		'nefunční signál',
		'nejede nám signál',
		'nemám signál',
		'nó telefon nemá signál',
		'občas nemám na volání signál a pak zase funguje',
		'porouchal se mi signál',
		'porucha signálu',
		'potřebuji signál',
		'proč mám najednou slabý signál',
		'proč nejde signál?',
		'při volání mi vypadává signál, co mám dělat',
		'vypadává mi signál při volání',
	],
	PORUCHY_MMS_DELAY: [
		'MMS chodí o pár hodin později',
		'MMS zprávy mi chodí s velkým zpožděním',
		'chodí mi opožnědě mms',
		'dlouho trvá, než mi přijde MMs',
		'doručování mmsek se opožďuje',
		'mms mi chodí příliš dlouho po odeslání',
		'mmsky mi chodí hrozně pozdě',
		'mmsky mi chodí o dost později, než byly poslány',
		'mmsky mi chodí s prodlevou',
		'mmsky mi nechodí hned ale se zpožděním',
		'opožděné doručování MMS',
		'prodleva při doručování MMS',
	],
	PORUCHY_MMS_GENERAL: [
		'MMS mi nedorazila',
		'MMS nefrčí',
		'MMS se zasekla',
		'Mám nový telefon a nefungují mi na něm MMS',
		'Nefungují MMS',
		'Nefungují mi MMS služby',
		'Nějaký technický problém s MMSky',
		'Poruchy MMS',
		'Potřebuju opravit MMS služby',
		'Problém s MMS',
		'mám asi porouchaní mms',
		'nefrčí mi MMSky',
		'rozbité mms',
	],
	PORUCHY_MMS_NOT_DOWNLOAD: [
		'chci si stáhnout mms a nejde to',
		'jak mám stáhnout mms do mobilu? nejde mi to',
		'je to pokažené, nelze stáhnout MMS',
		'nefunguje stahování mms',
		'nefunguje stáhnutí mms do telefonu',
		'nejde mi v telefonu stáhnout žádná mmska',
		'nejdou mi stahovat mms',
		'nemůžu si stáhnout mms',
		'nestáhnu si do telefonu mms',
	],
	PORUCHY_MMS_NOT_OPEN: [
		'Nejde mi otevřít MMS',
		'Nemůžu na svém mobilu přijmout MMS',
		'Nemůžu si zobrazit MMS',
		'Nevidím obsah MMS',
		'Nezobrazují se mi MMS',
		'Posílali mi MMS ane nevidím obsah',
		'když mi přijde MMS, nemůžu ji otevřít',
		'klikám, ale mmska se mi neotevírá',
		'mám problém s otevíráním mmsek',
		'neotvírá se mi MMS',
		'potíže s otevíráním mms',
	],
	PORUCHY_MMS_NOT_RECEIVED: [
		'Nechodí mi MMS',
		'když mi někdo pošle MMS, tak mi nepřijde',
		'mms měly přijít z komerční banky a nepřišly',
		'mobil mi neukáže příchozí MMS',
		'mám dostat mms z komerční banky a nepřišly',
		'můj mobil nepřijímá MMS',
		'nedoručují se mi mms',
		'nefuguje doručování MMS na můj telefon',
		'nefuknční příjem MMS',
		'nepřišla mi žádná mms',
		'porouchané přijímání mmsek',
		'přestaly mi chodit MMS zprávy',
		'špatné přijímání mmsek',
	],
	PORUCHY_MMS_NOT_SEND: [
		'Adresátům nechodí ode mě MMS',
		'MMS nejde odeslat',
		'MMS zpráva se vrací s chybovou hláškou',
		'Moje MMS zprávy se lidem neukazují',
		'Mám problémy s posíláním MMS zpráv',
		'Můj android mi hlásí že nemůžu odesílat MMS',
		'Můj mobil neumí odesílat MMS',
		'Nedokážu odeslat MMS',
		'Nefunguje mi odesílání MMS zpráv',
		'Nejde mi odeslat MMS',
		'Nemůžu poslat MMS',
		'Posílal jsem MMS a nedorazila',
		'Snažím se odeslat MMS ale nejde to',
		'Z mého mobilu nejdou odesílat MMS',
		'nemůžu odeslat obrázek',
	],
	PORUCHY_NEFUNGUJE_MGENERAL: [
		'aktualizace mobilních služeb selhala',
		'chci nahlásit poruchu',
		'chci nahlásit výpadek',
		'Chci oznámit, že mi přestalo fungovat',
		'chci řešit kvalitu služeb',
		'chci řešit poruchu',
		'chtěla bych nahlásit poruchu',
		'hlásím poruchu',
		'hlásím výpadek',
		'je to krám mizernej, nefunguje',
		'je to porouchaný!',
		'je to rozbité',
		'je to rozbitý!',
		'mobilní služby se nezaktualizovaly',
		'Mám poruchu',
		'mám požadavek s neodcházením a nepřijímáním sms zpráv',
		'mám problém s kvalitou a služeb',
		'Nahlášení poruchy',
		'nefachá',
		'Nefunguje mi to',
		'nefunguje mi',
		'nefunguje to',
		'nefunguje',
		'nefunkční',
		'nejde mi to spustit',
		'nejede to',
		'nejede',
		'něco mi nefunguje tak, jak má, kam to mám nahlásit?',
		'porouchaná',
		'porucha',
		'poruchou',
		'rozbil se mi mobil',
		'rozbitá',
		's poruchou',
		'technická pomoc',
		'vyřešit že nemám přístupná data ačkoliv jsou zaplacena',
		'Výpadek',
		'zas mi to nefunguje',
	],
	PORUCHY_NEFUNGUJE_MNEFUNGUJE_TELEFON: [
		'A mě se začal ten telefon brutálně sekat.',
		'Já bych chtěla reklamovat telefón od vás.',
		'Já mám od vás dva telefony, tam nebyl žádný záruční list, byly na smoluvu.',
		'hlásím poruchu mobilu',
		'přestal mi jít telefon',
	],
	PORUCHY_NEFUNGUJE_MNELZE_DOBIT_KREDIT: [
		'Já jsem chtěl přes internet dobít telefon a napsalo to, že provést  platbu kartou nelze.',
		'Vy máte nějaký výpadek nebo to nelze dobít kartou kredit',
		'je možné dobíjet kredit kartou cez internet?',
		'lze dobít kredit kartou cez internet?',
	],
	PORUCHY_NEFUNGUJE_MODBLOKOVAT_MMS: [
		'Jak si odblokuji MMS?',
		'Když se snažím odblokovat MMS, tak to nejde',
		'Na tomhle čísla jsem si už odblokovával MMSky a ono to stále nejde.',
		'nedá se mi odblokovat MMS',
		'nefunguji MMS',
		'nejde mi poslat MMS, i když jsem je odblokoval',
		'odblokuj mi MMS',
	],
	PORUCHY_NEFUNGUJE_MTECHNIK_NEUSPEL: [
		'došel vás technik a stále to nefunguje',
		'jak odešel technik tak už to zase nejede',
		'my jsme tady měli technika včera a pořád nám to nefunguje',
		'technik nám nepomohl, nefunguje to',
		'váš technik nám nepomohol',
	],
	GIVEUP: [
		'konec',
		'konec hovoru',
		'končím',
		'na shledanou',
		'nashlednou',
		'tady to nemá smysl',
		'tak tady končím',
		'vzdávám to, nashle',
	],
	PREFERUJPRODEJNU_PLACE: [
		'ach jo, zajdu na prodejnu',
		'dejte mi kontakt na prodejnu',
		'já bych radši na prodejně',
		'kdy se můžu stavit na prodejnu?',
		'kontakt na prodejnu',
		'preferuji kamennou pobočku',
		'preferuji prodejnu',
		'prodejna',
		'prodejnu prosím',
		'raději tu prodejnu',
		'tak já se k vám stavím na pobočku',
		'vyřešit na prodejně',
		'vyřeším to na prodejně',
		'zajdu na pobočku',
	],
	PRENOS_CISLA_A_UKONCENSMLOUVY_DISCUSSION_CONTRACT_END: [
		'chci s někým probrat vypovědění smlouvy',
		'potřeboval bych mluvit s někým ohledně vypovědění smlouvy',
		'potřebuju mluvit s někým ohledně výpovědi smlouvy',
		'přišel mi email ohledně výpovědi smlouvy potřebuju s někým probrat',
	],
	PRENOS_CISLA_A_UKONCENSMLOUVY_FORM: [
		'chci poradit jak na formulář s výpovědí',
		'co napsat do formuláře s výpovědí',
		'potřebovala bych vypsat formulář výpovědí a potřebuju vědět co vypsat na formuláři',
		'potřebuju pomoct s formulářem výpovědi',
	],
	KONEC_SMLOUVY: [
		'Budu rušit číslo, jak dlouho trvá výpovědní doba?',
		'chci od vás odejít',
		'chci skončit všechny smlouvy, které mám s vámi',
		'Chci ukončit smlouvu a nevím jak. Poradíte mi?',
		'chci ukončit smlouvu na čísle 724 483 394',
		'chci ukončit smlouvu',
		'chci ukončit smlouvy a přejít k O2',
		'chci vypovědět smlouvu do konce měsíce',
		'chci výpověď svého čísla',
		'chtěl bych přepojit na výpovědní oddělení',
		'chtěla jsem se zeptat na ukončení smlouvy',
		'Co mám napsat do výpovědi',
		'co potřebuju k ukončení smlouvy',
		'do kdy mě platí ta smlouva.',
		'dobrý den potřebuju se domluvit na ukončení smlouvy',
		'dokdy je má smlouva platná?',
		'Jak můžu ukončit smlouvu?',
		'jde o smlouvy kterou jsem jí zrušil',
		'jdu ke konkurenci a končím smlouvu',
		'Já volám protože mě končí smlouva',
		'kde se mi končí platnost smlouvy?',
		'končím u vás, konec smlouvy',
		'mohu se zeptat na ukončení smlouvy',
		'mě končí smlouva a má výpovědní lhůtu',
		'Mě končí smlouva v březnu, zvažuji,jak to budu řešit. Chtěl bych nějaký mobil.',
		'O2 mi dalo lepší cenu, chci skončit smlouvu',
		'platím moc, už mě to nebaví a chci odejit',
		'poradíte mi prosím s ukončením smlouvy',
		'Posílal jsem k vám výpověď a ještě mi nepřišla žádná odpověď.',
		'potřebuju poradit, jak ukončit smlouvu',
		'retenční oddělení',
		'ukončení smlouvy',
		'Ukončit smlouvu',
		'Ukončit závazek',
		'ukončuji smlouvu',
		'ukončuji vaše služby',
		'Už u vás nechci být, konec smlouvy',
		'volám, abych zrušil smlouvu, co mám u vás',
		'vypovědění služeb',
		'Vypovědět smlouvu',
		'vypovědět závazky',
		'výpověd ke svému číslu',
		'zrušit smlouvu',
	],
	PRENOS_CISLA_A_UKONCENSMLOUVY_IHAVEANOTHEROPERATOR: [
		'Chci přejít k jinému operátorovi',
		'Chci přejít s číslem k jinému operátorovi',
		'Chci změnit operátora, ale nechat si své číslo',
		'Dostal jsem lepší nabídku u konkurence',
		'budu převádět svoje číslo',
		'chci k jinému operátorovi i se svým číslem',
		'chtěla bych ukončit smlouvu, jdu k jinému operátorovi',
		'co na přechod k jinýmu operátorovi',
		'dobrý den chtěl bych přejít k vodafonu',
		'jdu k jinému operátorovi',
		'jdu se svým číslem k jinému operátorovi',
		'jiný operátor',
		'konkurence mi nabídla lepší nabídku, chci skončit',
		'mením operátora, své číslo si nechávám',
		'no převod čísla',
		'ostatní operátoři jsou lepší, končím s vámi',
		'potřebuju přenést číslo k jinému operátorovi',
		'potřebuju převod čísla',
		'přecházím k jinému operátorovi, chci u vás skončit',
		'přecházím ke konkurenci',
		'přecházím ke konkurenčnímu operátorovi',
		'přecházím pod firemní smlouvy jiného operátora',
		'přenos telefonního čísla jinam',
		'přenos telefonního čísla k jinému operátorovi',
		'přenos telefonního čísla ke konkurenci',
		'převod čísla jinam',
		'převádím číslo k jinému operátorovi',
		'převést telefonní číslo na jiného operátora',
		'samozřejmě že k jinému',
		'u konkurence mám lepší nabídku, končím',
	],
	PRENOS_CISLA_A_UKONCENSMLOUVY_TRANSMISSION_CODE: [
		'Chci ČVOP kód',
		'Přenést číslo',
		'budu od vás odcházet, řekněte mi přenosový kód',
		'chci OKU kód',
		'chci kód pro odchodu',
		'chci přenést číslo k jinému operátorovi',
		'chtěl bych si ověřit oku kód',
		'chtěla by poslat kód na přesměrování svého telefonního čísla k inemu operátorovi',
		'chtěla bych abyste mi poslali přenosový kód',
		'chtěla bych abyste mi poslali žádost mluvila s ňou přenosový kód',
		'dete mi číslo pro přestup',
		'dobrý den chci zjistit svůj ověřovací kód účastníka',
		'dobrý den poprosil bych o oku číslo',
		'dobrý den potřebovala bych vlastně heslo ke změně operátora',
		'dáte mi můj ověřovací kód účastníka',
		'já bych potřebovala znát svůj převodový kód když potřebuju odejít k jinému operátorovi',
		'k přestupu potřebuju číslo, ten oku kód',
		'kó na přesměrování k inému operátorovi',
		'kód na přesměrování čísla',
		'kód pro odchod',
		'mám čvop, kde ho mám nahlásit?',
		'nejde mi dostat ověřovací k účastníka',
		'nemůžu dostat ověřovací kód účastníka',
		'nemůžu se dostat do ověřovací kod účastníka',
		'pac kód',
		'potřeboval bych vygenerovat kód pro přenos',
		'potřebuji ověřovací kod účastníka',
		'potřebuji přenosové číslo',
		'potřebuji zaslat čvop kód telefonního čísla',
		'potřebuji čvop již jsem o to žádala potřebuji vědět jestli už jste mi to poslali',
		'potřebuju odchodový kód',
		'potřebuju ověřit oku kód',
		'potřebuju ověřovací kod účastníka',
		'potřebuju předat kpu kód',
		'potřebuju zaslat kpu kód',
		'potřebuju čvop kód',
		'prosím o heslo kvůli změně operátora',
		'prosím o oku kód',
		'prosím o číslo přestupu tarifu',
		'přenos čísla pošlete mi kód',
		'přenosový kód',
		'takže ještě jednou žádám o zaslání přechodového čísla na tento mobil na číslo tohoto mobilu',
		'znovu žádám o zaslání přechodového čísla',
		'čvop',
	],
	PRENOS_CISLA_A_UKONCENSMLOUVY_WITHDRAW: [
		'chtěl bych odstoupit od smlouvy',
		'odstupuji ze smlouvy co jsem uzavřel před týdnem',
		'potřeboval bych odstoupit od smlouvy uzavřené na internetu',
		'potřebuji informace ohledně odstoupení od smlouvy do čtrnácti dnů ohledně telefonu',
		'uzavřel jsem smlouvu po internetu a chci odstoupit jak mám ze zákona nárok',
	],
	PREPLATEK_CHECK: [
		'chtěla bych přehled vyúčtovaných služeb nesouhlasí me faktura, mám přeplatek',
		'mám moc zaúčtovaných služeb',
		'mám přeplatek, zaúčtované služby neodpovídají',
		'mám vysoký přeplatek',
		'mám zaúčtované služby navíc',
		'neodpovídají mi zaúčtované služby',
		'potřebuju znát přehled zaúčtovaných služeb, jelikož mám přeplatek',
		'proč mám přeplatek',
	],
	PREPLATEK_MORE: [
		'Co se stane s penězi, co jsem vám poslal navíc?',
		'Mám u vás přeplatek, co s ním bude.',
		'Omylem jsem vám poslal víc peněz, máte je tam?',
		'Přišla mi SMS o vyúčtování a je tam částka na nula Kč, jak to? Měl jsem přeplatek?',
		'chci info o přeplatku',
		'chci vědět, za co platím tolik peněž',
		'mám asi nějaký přeplatek',
		'můžete mi říct, za co platím takovou částku',
		'omylem jsem zaplatil víc',
		'poslal jsem vám víc',
		'potřebuji vědět za co platím šest set korun',
		'potřebuju zjistit, za co platím tolik korun',
		'proč platím tolik',
		'přeplatek',
		'přeplatek na účtu',
		'převedl jsem víc peněz',
		'přišlo vám ode mě víc',
		'volám kvůli přeplatku',
		'za co platím tolik',
		'zaplatil jsem víc',
		'zaplatil sem víc než sem měl',
		'řekněte mi, za co platím 500 korun',
	],
	PREPLATEK_MULTPAYMENT: [
		'Zaplatil jsem fakturu dvakrát',
		'a mam zaplacený paušál dvakrát omylem',
		'dvakrát zaplacený paušál',
		'omylem jsem poslal peníze dvakrát',
		'omylem jsem zaplatit vícekrát',
		'poslal jsem peníze víckrát',
		'poslal jsem platbu za paušál nechtěně dvakrát',
		'zaplatil jsem dvakrát',
		'zaplatil jsem omylem dvakrát',
	],
	PREPLATEK_RETURN: [
		'Kdy mi vrátíte peníze, které jsem vám navíc poslal.',
		'Poslal jsem vám víc, než bylo potřeba. Chci to vrátit.',
		'chci vrátit peníze, co jsem vám poslal navíc',
		'chci zpět peníze, co jsem poslal navíc',
		'kdy mi vrátíte to, co jsem vám poslal navíc',
		'mám přeplatek a za telefonní hovory a nevrátili ste mi ho',
		'nevrátili jste mi přeplatek za telefonní hovory',
		'vrátíte mi přeplatek',
		'zaplatil jsem fakturu dvakrát, pošlete mi peníze zpět?',
	],
	PRICE: [
		'cena automobilu',
		'cena vozu škoda',
		'chci od vás slevu',
		'chci znát cenu volkswagenu',
		'chci znát vaší cenovou nabídku',
		'chtěl bych porovnat ceny škodovek',
		'chtěla bych porovnat nabídku od vás s konkurencí',
		'chtěla bych znát výši ceny za opel',
		'dostala jsem nabídku na 447 000 korun',
		'dáte mi slevu na auto',
		'info o ceně vozu kia',
		'jakou dostanu slevu',
		'jaká cena je za auta značky dacia',
		'jaká je cena škodovky',
		'jaké jsou vaše ceny aut',
		'je možné získat nějakou slevu',
		'jinde mi vozidlo nabídli za 759 000',
		'jinde mi za auto nabídli výhodnější cenu',
		'kolik mi můžete dát slevu',
		'kolik u vás stojí auto',
		'kolik u vás stojí volvo',
		'konkurence má lepší cenu auta',
		'mám dotaz na cenu vozidla',
		'u konkurence jsem dostal výhodnější cenu',
		'za kolik máte škodu',
		'za kolik nabízíte vozidla',
		'za kolik prodáváte kiu',
		'za kolik u vás seženu užitkové vozy volkswagen',
		'zajímá mě cena vašich automobilů',
		'zajímá mě cena vozu renault',
		'zeptat se na cenu auto',
		'řekněte mi přibližnou cenu renaultu',
	],
	PRICE_NEW_CAR: [
		'chci od vás slevu u nového auta',
		'chci znát cenu nového volkswagenu',
		'chci znát vaší novou cenovou nabídku',
		'chtěla bych porovnat nabídku nového auta od vás s konkurencí',
		'chtěla bych znát výši ceny za nový opel',
		'dáte mi slevu na nové auto',
		'jaká cena je za nová auta značky dacia',
		'jaké jsou vaše ceny nových aut',
		'je možné získat nějakou slevu za nové auto',
		'jinde mi nové vozidlo nabídli za 759 000',
		'jinde mi za nové auto nabídli výhodnější cenu',
		'kolik mi můžete dát slevu na nové auto',
		'kolik u vás stojí nové auto',
		'kolik u vás stojí nové volvo',
		'konkurence má lepší cenu nového auta',
		'mám dotaz na cenu nového vozidla',
		'za kolik máte novou škodu',
		'za kolik nabízíte nová vozidla',
		'za kolik prodáváte novou kiu',
		'za kolik u vás seženu nové užitkové vozy volkswagen',
		'zajímá mě cena vašich nových automobilů',
		'řekněte mi přibližnou cenu nového renaultu',
	],
	PRICE_USED_CAR: [
		'chci od vás slevu za ojetý vůz',
		'chci znát cenu použitého volkswagenu',
		'chci znát vaší cenovou nabídku ojetých vozů',
		'chtěla bych znát výši ceny za ojetý opel',
		'dostala jsem nabídku ojetého auta na 447 000 korun',
		'dáte mi slevu na použité auto',
		'jak se pohybuje cena zánovních aut',
		'je možné získat nějakou slevu za použitý vůz',
		'jinde mi ojeté vozidlo nabídli za 75 000',
		'kolik mi můžete dát slevu na ojeté auto',
		'kolik u vás stojí ojeté volvo',
		'kolik u vás stojí použité auto',
		'kolik u vás stojí zánovní automobil',
		'konkurence má lepší cenu ojetých aut',
		'mám dotaz na cenu ojetého vozidla',
		'za kolik máte ojetou škodu',
		'za kolik nabízíte ojetá vozidla',
		'za kolik prodáváte použitou kiu',
		'za kolik u vás seženu ojeté užitkové vozy volkswagen',
		'zajímá mě cena vašich ojetých automobilů',
		'řekněte mi přibližnou cenu použitého renaultu',
	],
	PRICHOD_NEWCOMER: [
		'chci nahlásit kód pro přenos',
		'chci nahlásit přenosový kód',
		'chci sdělit OKU kód',
		'chci sdělit ČVOP kód',
		'chci zadat ČVOP',
		'potřebuju zadat kód ČVOP',
		'přecházím k vám od jiného operátora',
		'přicházím k vám',
	],
	PROBLEM_PRIHLASENERROR: [
		'mám problém se přihlásit',
		'nejde se mi přihlásit',
		'problém s přihlášením',
		'přihlášení',
		'příhlášení nefunguje',
	],
	PRODLOUZENA_INFORMACE_ANOTHER_CONTRACT: [
		'chci řešit další smlouvu',
		'chci řešit jinou smlouvu',
		'další smlouva',
		'informace o jiné smlouvě',
		'potřebuju informace o jiné smlouvě',
		'potřebuju vyřešit jinou smlouvu',
		'potřebuju řešit další smlouvu',
	],
	PUK_PHONE: [
		'Můj telefon se mi zablokoval a chce to po mě nějaké heslo.',
		'jak odblokovat mobil',
		'jak odblokovat telefon',
		'kód pro odblokování telefonu',
		'mám zablokovaný telefon, jak ho odblokuji',
		'náhradní heslo k SIM, abych odblokoval telefon',
		'potřeboval bych odblokovat telefon prosím',
		'potřeboval bych ty čísla pro odblokování telefolu',
		'potřeboval bych údaje k odblokování telefonu',
		'potřebuji kód pro odblokování mobilu',
		'potřebuju zpátky odblokovat telefon',
		'prosím o odblokování telefonu',
		'prosím o odblokávání mobilu',
		'takový ten kód na odblokování mobilu',
		'zablokoval jsem si mobil, jak ho odblokuji',
		'zablokoval jsem si telefon, potřebuju to opravit',
	],
	PUK_PIN: [
		'Zadal jsem špatně PIN a zablokoval se mi telefon',
		'a já tady mam pin k telefonu ano nebo ne',
		'kde najdu pin k odblokování simky?',
		'kde najdu svůj pin?',
		'mám pin k telefonu?',
		'mám zablokovaný PIN',
		'nemůžu si vzpomenou na svůj PIN',
		'neznám svůj PIN',
		'popletl jsem PIN',
		'potřebuji zjistit PIN',
		'potřebuju do telefonu naťukat pin, ale zapomněl jsem ho',
		'potřebuju odemknout telefon pin kódem',
		'potřebuju odemknout telefon pinem',
		'potřebuju zadat pin, ale neznám ho',
		'syn si zablokoval mobilní telefon pinem, jde to nějak odblokovat?',
		'zadal jsem špatný pin',
		'zadal jsem špatně pin a bloknul si kartu',
		'zapomenul jsem svůj PIN',
	],
	PUK_PUKKEYWORD: [
		'Jaký je můj puk?',
		'PUK',
		'Potřeboval bych zjistit heslo ke svému telefonu. Zadal jsem 3X špatně a teď mi telefon hlásí, že mám zadat nějaký kód PUK.',
		'Přes noc mi proběhla aktualizace na mém telefon a teď to po mě chce PUK. Já si ho ž ale nepamatuju. Můžete mi ho říct?',
		'Svůj puk, prosím',
		'chci svůj puk',
		'jak zjistím svůj PUK?',
		'kde najdu PUK',
		'najdi můj PUK',
		'nepamatuju si puk',
		'nevím, kde najít PUK',
		'potřeboval bych sdělit puk',
		'potřebuji vědět PUK',
		'potřebuju svůj puk',
		'rád bych vás požádal o svůj PUK',
		'telefon hlásí že potřebuje PUK kód',
		'ukaž můj PUK',
		'ukážete mi můj puk?',
		'zadat PUK kód',
		'řeknete mi můj PUK?',
		'špatný PIN, potřebuji PUK kód',
	],
	RECEPTION: [
		'chtěl bych k telefonu recepci volkswagen',
		'chtěl bych recepci škodovky',
		'chtěla bych hovořit s někým z recepce',
		'chtěla bych přepojit na recepci pobočky dacia',
		'dejte mi číslo na recepci',
		'dovolat se na paní recepční',
		'dovolat se na recepci volva',
		'dáte mi k telefonu paní recepční',
		'dáte mi paní na recepci',
		'dáte mi recepční',
		'kontakt na recepci prosím',
		'můžu mluvit s někým na recepci',
		'potřebuju se dovolat na recepci Kia',
		'potřebuju zatelefonovat na recepci',
		'prosím o telefon na recepci',
		'prosím recepci opel',
		'přepojil byste mě na recepci pobočky',
		'přepojit na recepci',
		'přepojte mě na recepci',
		'přepojíte mě na recepci',
		'spojil byste mě s recepční',
	],
	RECOGNIZED_NUMBER_NUMBER: [
		'DSL 1 2 3 4 5 6 7',
		'd s l je 7 8 7 12 3 4',
		'hmm 7 8 dva tři devet osm jeden',
		'něco jako 1 2 4 9 00 8',
		'číslo je 1 8 7 0 2 0 0',
	],
	REKLAMACE_COMPLAINT: [
		'chci reklamovat',
		'chci reklamovat mobil',
		'potřebuju reklamovat',
		'potřebuju reklamovat set-to-box',
		'reklamace modemu',
		'reklamaci',
		'reklamaci handsfree',
		'reklamaci telefonu',
		'reklamaci zařízení',
		'řeším reklamaci',
		'řeším reklamaci televize',
	],
	REPEAT: [
		'je možné to zopakovat',
		'můžete mi to zopakovat',
		'nerozumím, zopakujete mi to ještě',
		'prosím o opakování',
		'prosím o zopakování',
		'zopakovat byste mi to',
		'zopakovat otázku',
		'zopakovat podruhé',
		'zopakovat pokyny',
		'zopakovat poslední větu',
		'zopakuješ mi to',
		'zopakujte mi to prosím',
		'zopákni mi to',
		'špatně jsem rozuměl, zopakujete mi to',
		'špatně jsem slyšet, zopakujte mi to',
	],
	REPEAT_ONCE_AGAIN: [
		'celé to prosím zopakujte',
		'ještě jednou co po mně chcete',
		'ještě jednou jak to bylo',
		'ještě jednou',
		'moc jsem vám nerozuměla, můžete to celé zopakovat?',
		'možnosti zopakovat',
		'musíte mi to zopakovat',
		'můžete mi to říct ještě jednou',
		'můžete to zopakovat prosím',
		'Můžete to zopakovat',
		'můžete to říct, prosím',
		'Nerozuměl jsem',
		'Pardon, nerozuměl jsem, jak že jste to říkal?',
		'pověz mi to znovu',
		'povězte mi to ještě jednou',
		'prosím znova',
		'znova mi to povězte',
		'znovu prosím',
		'zopakovat možnosti',
		'Zopakuj mi je',
		'zopakujte mi to ještě',
		'zopakujte to',
		'řekneme mi to prosím znovu',
		'řeknete mi to ještě jednou?',
		'řekni mi to znovu',
		'řekněte mi to znova',
		'řekněte to znovu',
	],
	REPEAT_FIRST: [
		'Jaká byla první',
		'co byla první varianta?',
		'co byla ta první adresa',
		'jaká byla první možnost',
		'jaká že byla ta první adresa',
		'první možnost znovu',
		'zopakujte mi tu první možnost',
	],
	REPEAT_CANT_HEAR: [
		'blbě vás slyším',
		'je tady rušno, není vás slyšet',
		'je tu velký hluk',
		'mám tady rámus',
		'není vás slyšet',
		'neslyšel jsem',
		'neslyšel jsem vás',
		'promiňte, neslyšel jsem vás',
		'vůbec vás není slyšet',
		'špatně jsem vás teď slyšel',
	],
	REPEAT_NOT_UNDERSTAND: [
		'co jste říkala',
		'co prosím',
		'cože',
		'jak to bylo',
		'nerozumím',
		'nerozuměl jsem',
		'promiňte, co jste říkala',
		'teď jsem blbě slyšel',
		'vůbec jsem vám nerozuměl',
	],
	REPEAT_SECOND: [
		'Jaká byla druhá',
		'Jaká byla druhá možnost',
		'co byla druhá varianta',
		'co byla ta druhá adresa',
		'druhá možnost znovu',
		'jak že byla ta druhá adresa',
		'jak že byla ta druhá možnost?',
	],
	RESERVATION_SERVICE: [
		'chci si nechat opravit auto',
		'chci si nechat zkontrolovat auto',
		'domluvit se na termínu návštěvy servisu',
		'dá se přijet na servis i bez termínu',
		'dá se u vás zavmluvit návštěva servisu',
		'je nutné se objednávat do servisu',
		'jsou nějaké volné termíny v servisu',
		'kdy můžu přijet do servisu',
		'musím se na servis objednávat',
		'musím si nechat opravit auto',
		'musím si nechat prohlédnout auto',
		'musím si servis rezervovat předem',
		'máte v servisu volno',
		'můžu přijet do servisu bez objednání',
		'objednat auto na opravu',
		'objednat se na kontrolu vozu',
		'objednat se na servis',
		'objednávka do servisu',
		'oservisovat auto',
		'potřebuju si zamluvit termín na servis',
		'potřebuju zkontrolovat auto',
		'přihlásit se na opravu auta',
		'rezervace na oservisování auta',
		'rezervovat opravu vozu',
		'rezervovat termín na servis',
		'vadí, když se na servis neobjednám předem',
		'zajímají mě volné termíny na servis',
		'zamluvit si prohlídku auta',
	],
	RETURN_GENERAL: [
		'chci se vrátit',
		'na předchozí otázku',
		'o krok zpět',
		'předchozí volba',
		'vrátit se',
		'zpátky',
		'zpět',
	],
	SAFETY: [
		'Chráníte osobní data?',
		'Co děláte pro zabezpečení bezpečnosti svých zákazníků?',
		'Jak je tento kanál zabezpečený?',
		'Je tato komunikace bezpečná?',
		'Je toto oficiální kanál?',
		'Je zajištěna bezpečnost osobních údajů?',
		'Jste zabezpečení proti podvodům?',
		'Můžu tady sdělovat osobní informace?',
		'Nedostane se někdo k mým osobním údajům?',
		'a co moje osobní údaje',
		'dodržujete gdpr',
		'jak nakládáte s osobními údaji',
		'jak to je s osobními údaji',
		'je hovor bezpečný',
		'je to bezpečné',
		'je to podle gdprq',
		'nejsem si jistá bezpečností',
		'není to nebezpečené',
		'není to nebezpečné',
		'to mi nepřijde moc bezpečné',
	],
	SAW_CAR_OUT: [
		'chci auto, co máte odstavené na silnici',
		'chci vozidlo, co stojí na ulici',
		'máte na ulici zaparkované pěkné auto',
		'máte to auto, co stojí kousek od vás',
		'měla bych zájem o odstavené auto',
		'na ulici jsem viděla auto',
		'odstavené auto na parkovišti',
		'to zaparkované vozidlo venku',
		'venku máte zaparkované auto',
		'viděl jsem u vás zaparkované auto, co se mi líbí',
		'viděla jsem stát u vás na silnici auto',
		'viděla jsem u vás stát na silnici auto',
		'zajímá mě auto od vás z parkoviště',
		'zajímá měl auto, co je zaparkované venku',
		'zajímá měl vozidlo, co máte',
	],
	SECOND_CHOICE: [
		'2. možnost',
		'chci tu druhou',
		'chci vyřešit to druhé',
		'chci řešit to prostřední',
		'druhou variantu',
		'druhá možnost',
		'druhá varianta',
		'dvojka',
		'možnost dva',
		'možnost dvě',
		'potřebuju vyřešit prostřední možnost',
		'prostřední varianta',
		's tou druhou možností',
		's tou druhou variantou',
		's tím druhým',
		'ta možnost uprostřed',
		'ta prostřední možnost',
		'to druhé',
		'to druhý',
		'to druhý, co jste říkala',
		'to prostřední',
		'tu druhou možnost',
		'tu druhou volbu',
		'varianta 2',
		'varianta dva',
		'varianta číslo dvě',
		'zadruhé',
	],
	SELECTION: [
		'jak na prodej vozu bezkontaktně',
		'jak si je u vás možné si momentálně vybrat auto',
		'jak si mohu koupit auto, když je všechno zavřené',
		'jak si mám vybrat auto, když se nemohu přijít podívat',
		'jak si mám vybrat bezkontaktně auto',
		'jak si teď u vás mohu koupit auto',
		'jak si u vás mohu zakoupit automobil',
		'jak si vyberu auto',
		'jak to u vás funguje',
		'jak to u vás momentálně probíhá',
		'jak u vás probíhá prodej auta',
		'mohu si bezkontaktně koupit auto',
	],
	SERVICE_AUDI: [
		'jsou nějaké volné termíny v servisu Audi',
		'kdy mi končí stk na audi',
		'kdy můžu přijet do servisu Audi',
		'musím si nechat opravit Audi',
		'máte v servisu Audi volno',
		'objednat Audi na opravu',
		'objednat se na STK Audi',
		'objednat se na kontrolu Audi',
		'objednat se na servis Audi',
		'objednat se na technickou Audi',
		'objednat se na technickou kontrolu Audi',
		'oservisovat Audi',
		'potřebuju si zamluvit termín na servis Audi',
		'potřebuju zkontrolovat Audi',
		'servisní kontrola Audi',
		'technická prohlídka Audi',
	],
	SERVICE_DACIA: [
		'jsou nějaké volné termíny v servisu Dacie',
		'kdy mi končí stk na dacii',
		'kdy můžu přijet do servisu Dacie',
		'musím si nechat opravit Dacii',
		'mám dacii a končí mi stk',
		'máte v servisu Dacie volno',
		'objednat Dacii na opravu',
		'objednat se na STK Dacie',
		'objednat se na kontrolu Dacie',
		'objednat se na servis Dacii',
		'objednat se na technickou Dacie',
		'objednat se na technickou kontrolu Dacie',
		'oservisovat Dacii',
		'potřebuju si zamluvit termín na servis Dacie',
		'potřebuju zkontrolovat Dacie',
		'servisní kontrola Dacie',
		'technická prohlídka Dacie',
	],
	SERVICE_KIA: [
		'jsou nějaké volné termíny v servisu Kia',
		'kdy můžu přijet do servisu Kia',
		'musím si nechat opravit Kiu',
		'máte v servisu Kia volno',
		'objednat Kiu na opravu',
		'objednat se na STK Kii',
		'objednat se na kontrolu Kii',
		'objednat se na servis Kia',
		'objednat se na technickou Kii',
		'objednat se na technickou kontrolu Kii',
		'oservisovat Kiu',
		'potřebuju si zamluvit termín na servis Kia',
		'potřebuju zkontrolovat Kiu',
		'servisní kontrola Kii',
		'technická prohlídka Kii',
	],
	SERVICE_OPEL: [
		'jsou nějaké volné termíny v servisu opelu',
		'kdy mi končí stk na opelu',
		'kdy můžu přijet do servisu opelu',
		'musím si nechat opravit Opel',
		'máte v servisu opelu volno',
		'objednat Opel na opravu',
		'objednat se na STK opelu',
		'objednat se na kontrolu opelu',
		'objednat se na servis Opel',
		'objednat se na technickou kontrolu opelu',
		'objednat se na technickou opelu',
		'oservisovat Opel',
		'potřebuju si zamluvit termín na servis opelu',
		'potřebuju zkontrolovat Opel',
		'servisní kontrola Opel',
		'technická prohlídka opelu',
	],
	SERVICE_RENAULT: [
		'jsou nějaké volné termíny v servisu Renaultu',
		'kdy mi končí stk na renaulta',
		'kdy můžu přijet do servisu Renaultu',
		'musím si nechat opravit Renault',
		'máte v servisu Renault volno',
		'objednat Renault na opravu',
		'objednat se na STK Renaultu',
		'objednat se na kontrolu Renaultu',
		'objednat se na servis Renaultu',
		'objednat se na technickou Renaultu',
		'objednat se na technickou kontrolu Renaultu',
		'oservisovat Renault',
		'potřebuju objednat na stk s renaultem',
		'potřebuju si zamluvit termín na servis Renaultu',
		'potřebuju zkontrolovat Renault',
		'servisní kontrola Renaultu',
		'technická prohlídka Renaultu',
	],
	SERVICE_SKODA: [
		'jsou nějaké volné termíny v servisu Škoda',
		'kdy mi končí stk na škodovku',
		'kdy můžu přijet do servisu Škoda',
		'musím si nechat opravit škodovku',
		'máte v servisu Škody volno',
		'objednat se na STK Škody',
		'objednat se na kontrolu škodovky',
		'objednat se na servis Škoda',
		'objednat se na technickou kontrolu Škody',
		'objednat se na technickou Škoda',
		'objednat Škodu na opravu',
		'oservisovat Škodu',
		'potřebuju si zamluvit termín na servis Škody',
		'potřebuju zkontrolovat škodovku',
		'servisní kontrola škodovky',
		'technická prohlídka Škody',
	],
	SERVICE_VOLVO: [
		'jsou nějaké volné termíny v servisu Volva',
		'kdy mi končí stk na volvo',
		'kdy můžu přijet do servisu Volva',
		'musím si nechat opravit Volvo',
		'mám volvo a končí mi stk',
		'máte v servisu Volva volno',
		'objednat Volvo na opravu',
		'objednat se na STK Volva',
		'objednat se na kontrolu Volva',
		'objednat se na servis Volvo',
		'objednat se na technickou Volva',
		'oservisovat Volvo',
		'potřebuju si zamluvit termín na servis Volva',
		'potřebuju zkontrolovat Volvo',
		'servisní kontrola Volva',
		'technická prohlídka Volva',
		'zarezerovat si termín na technickou kontrolu Volva',
	],
	SERVICE_VW: [
		'jsou nějaké volné termíny v servisu Volkswagenu',
		'kdy mi končí stk volkswagenu',
		'kdy můžu přijet do servisu Volkswagenu',
		'musím si nechat opravit Volkswagen',
		'máte v servisu Volkswagenu volno',
		'objednat Volkswagen na opravu',
		'objednat se na STK Volkswagenu',
		'objednat se na kontrolu Volkswagen',
		'objednat se na servis Volkswagenu',
		'objednat se na technickou Volkswagenu',
		'objednat se na technickou kontrolu Volkswagenu',
		'oservisovat Volkswagen',
		'potřebuju objednat na stk volskwagen',
		'potřebuju si zamluvit termín na servis Volkswagenu',
		'potřebuju zkontrolovat Volkswagen',
		'servisní kontrola Volkswagenu',
		'technická prohlídka Volkswagenu',
	],
	SERVIS_HOURS_CLOSING: [
		'chtěl jsem do servisu, ale měli zavřeno',
		'jaktože má servis zavřeno',
		'kdy zavírá servis',
		'máte v servisu zavřeno',
		'stihnu to do servisu před zavíračkou',
		'už máte v servisu zavřeno',
		'v kolik hodin zavírá servis',
		'v kolik je zavíračka servisu',
		'v kolik končí servis',
		'v kolik zavírají v servisu',
		'zavírá servis už odpoledne',
		'zavírá servis večer',
	],
	SERVIS_HOURS_MECHANICIAN: [
		'do kdy tam jsou technici',
		'do kolika hodin mají technici',
		'do kolika pracuje mechanik',
		'kdy tam jsou technici',
		'otevírací doba u mechaniků',
		'otevírací doba u techniků',
		'pracovní doba mechaniků',
		'pracovní doba techniků',
		'v kolik hodin tam jsou mechanici',
		'v kolik hodin tam jsou servisáci',
	],
	SERVIS_HOURS_OPENING: [
		'do kolika hodin má otevřeno servis',
		'do kolika jsou v servisu',
		'do kolika mají v servisu',
		'dokdy jsou v servisu',
		'jakou má servis otevírací dobu',
		'jaké je otevírací doba v servisu',
		'je servis otevřený ráno',
		'jsou v servisu i večer',
		'kdy má servis otevřeno',
		'mají v servisu otevřeno od rána',
		'mají v servisu otevřeno ráno',
		'mají v servisu otevřeno večer',
		'má servis otevřeno',
		'od kolika má otevřeno servis',
		'odkdy má servis otevřeno',
		'otevírací doba servisu',
		'potřebuju ověřit jak má otevřeno servis',
		'potřebuju vědět, jak má servis otevřeno',
		'pracovní doba v servisu',
		'v kolik hodin otevírá servis',
	],
	SERVIS_HOURS_OPENING_AUDI: [
		'do kdy má otevřeno servis Audi',
		'do kolika hodin má otevřeno servis Audi',
		'funguje servis Audi o víkendu',
		'informaci o otevírací době servisu Audi',
		'jak má otevřeno servis Audi',
		'jaké je provozní doba v servisu Audi',
		'má servis Audi otevřeno o víkendu',
		'má servis Audi otevřeno v sobotu',
		'má teď otevřeno servis Audi',
		'máte servis Audi otevřeno v neděli',
		'od kolika hodin má otevřeno servis Audi',
		'otevírací doba v servisu Audi',
		'pracovní doba v servisu Audi',
		'v kolik zavírá servis Audi',
	],
	SERVIS_HOURS_OPENING_DACIA: [
		'do kdy má otevřeno servis Dacie',
		'do kolika hodin má otevřeno servis Dacia',
		'funguje servis Dacia o víkendu',
		'info o otevírací době servisu Dacie',
		'jak má otevřeno servis Dacie',
		'jaké je provozní doba v servisu Dacia',
		'má servis Dacia otevřeno v sobotu',
		'má servis dacie otevřeno o víkendu',
		'má teď otevřeno servis Dacia',
		'máte servis Dacia otevřeno v neděli',
		'od kolika hodin má otevřeno servis Dacia',
		'otevírací doba v servisu Dacia',
		'pracovní doba v servisu Dacie',
		'v kolik zavírá servis Dacia',
	],
	SERVIS_HOURS_OPENING_KIA: [
		'do kdy má otevřeno servis Kia',
		'do kolika hodin má otevřeno servis Kia',
		'funguje servis Kia o víkendu',
		'informace o otevírací době servisu Kia',
		'jak má otevřeno servis Kia',
		'jaké je provozní doba v servisu Kia',
		'má servis Kia otevřeno o víkendu',
		'má servis Kia otevřeno v sobotu',
		'má teď otevřeno servis Kia',
		'máte servis Kia otevřeno v neděli',
		'od kolika hodin má otevřeno servis Kia',
		'otevírací doba v servisu Kia',
		'pracovní doba v servisu Kia',
		'v kolik zavírá servis Kia',
	],
	SERVIS_HOURS_OPENING_MALESICE: [
		'do kdy má otevřeno servis škoda malešice',
		'jak má otevřeno servis vozů škola malešice',
		'jak má otevřeno servis škoda malešice',
		'má servis malešice otevřeno o víkendu',
		'má servis v Malešicích otevřeno v neděli',
		'má servis škoda malešice otevřeno v sobotu',
		'od kolika hodin má otevřeno servis škoda malešice',
		'otevírací doba servis škoda malešice',
		'otevíračka servisu vozů škoda malešice',
	],
	SERVIS_HOURS_OPENING_OPEL: [
		'do kdy má otevřeno servis Opel',
		'do kolika hodin má otevřeno servis Opel',
		'funguje servis Opel o víkendu',
		'jak má otevřeno servis Opel',
		'jaké je provozní doba v servisu Opel',
		'má servis Opel otevřeno v sobotu',
		'má servis Opelu otevřeno o víkendu',
		'má teď otevřeno servis Opel',
		'máte servis Opel otevřeno v neděli',
		'od kolika hodin má otevřeno servis Opel',
		'otevírací doba servisu Opel',
		'otevírací doba v servisu Opel',
		'potřebovala bych informaci o otevírací době servisu Opel',
		'pracovní doba v servisu Opel',
		'v kolik zavírá servis Opel',
	],
	SERVIS_HOURS_OPENING_RENAULT: [
		'do kdy má otevřeno servis Renault',
		'do kolika hodin má otevřeno servis Renault',
		'funguje servis Renault o víkendu',
		'jak má otevřeno servis Renault',
		'jaké je provozní doba v servisu Renault',
		'má servis Renault otevřeno o víkendu',
		'má servis Renault otevřeno v sobotu',
		'má teď otevřeno servis Renault',
		'máte servis Renault otevřeno v neděli',
		'od kolika hodin má otevřeno servis Renault',
		'otevírací doba v servisu Renault',
		'pracovní doba v servisu Renault',
		'v kolik zavírá servis Renault',
	],
	SERVIS_HOURS_OPENING_SKODA: [
		'do kdy má otevřeno servis Škoda',
		'do kolika hodin má otevřeno servis značky škoda',
		'funguje servis Škoda o víkendu',
		'jak má otevřeno servis Škoda',
		'jaké je provozní doba v servisu Škoda',
		'má servis Škoda otevřeno o víkendu',
		'má servis škodovky otevřeno v sobotu',
		'má teď otevřeno servis škodovek',
		'máte servis Škoda otevřeno v neděli',
		'od kolika hodin má otevřeno servis škodovky',
		'otevírací doba v servisu Škoda',
		'pracovní doba v servisu Škoda',
		'v kolik zavírá servis škodovky',
	],
	SERVIS_HOURS_OPENING_VOLVO: [
		'do kdy má otevřeno servis Volvo',
		'do kolika hodin má otevřeno servis Volvo',
		'funguje servis Volvo o víkendu',
		'informaci o otevírací době servisu Volvo',
		'jak má otevřeno servis Volvo',
		'jaké je provozní doba v servisu Volvo',
		'má servis Volvo otevřeno o víkendu',
		'má servis Volvo otevřeno v sobotu',
		'má teď otevřeno servis Volvo',
		'máte servis Volvo otevřeno v neděli',
		'od kolika hodin má otevřeno servis Volvo',
		'otevírací doba servisu Volvo',
		'otevírací doba v servisu Volvo',
		'pracovní doba v servisu Volvo',
		'v kolik zavírá servis Volvo',
	],
	SERVIS_HOURS_OPENING_VW: [
		'do kdy má otevřeno servis Volkswagen',
		'do kolika hodin má otevřeno servis Volkswagen',
		'funguje servis Volkswagen o víkendu',
		'informaci o otevírací době servisu Volskswagenu',
		'jak má otevřeno servis Volkswagen',
		'jaké je provozní doba v servisu Volkswagen',
		'má servis Volkswagen otevřeno o víkendu',
		'má servis Volkswagen otevřeno v sobotu',
		'má teď otevřeno servis Volkswagen',
		'máte servis Volkswagen otevřeno v neděli',
		'od kolika hodin má otevřeno servis Volkswagen',
		'otevírací doba v servisu Volkswagen',
		'pracovní doba v servisu Volkswagen',
		'v kolik zavírá servis Volkswagen',
	],
	SERVIS_HOURS_WEEKEND: [
		'do kolik hodin mají v servisu o víkendu',
		'do kolika jsou v servisu o víkendu',
		'funguje servis o víkendu',
		'jak dlouho jsou o víkendu v servisu',
		'jaká je víkendová otevírací doba v servisu',
		'jaká je víkendová pracovní doba v servisu',
		'má servis otevřeno o víkendu',
		'má servis otevřeno v neděli',
		'má servis v neděli zavřeno',
		'má servis v něděli otevřeno',
		'má servis v sobotu zavřeno',
		'má sevis o víkendu zavřeno',
		'máte servis otevřeno i v sobotu',
		'otevírá servis i v sobotu',
		'otevírá servis v neděli',
		'pracuje se v servisu i v sobotu',
		'v kolik hodin o víkendu končí servis',
		'v kolik hodin otevírá servis v sobotu',
	],
	SERVIS_HOURS_WORKDAYS: [
		'jakou má servis provozní dobu přes týden',
		'jaká se servis otevřeno ve čtvrtek',
		'je servis v provozu od pondělí do pátku',
		'má sevis otevřeno v pátek',
		'provozní doba servisu',
		'provozní doba servisu v úterý',
		'středeční provozní doba servisu',
	],
	SHOWROOM: [
		'do jakého showroomu teď mohu přijít',
		'jde se k vám přijít podívat na auta',
		'mohu se přijít podívat na auta',
		'máte auta skladem',
		'máte automobily i skladem',
		'máte na pobočce showroom',
		'máte na prodejně vystavená auta',
		'máte showroom',
		'máte vozy na showroomu skladem',
		'v jakém autosalonu se mohu přijít podívat na automobily',
		'zajímá mě, jestli auta na prodejně jsou skladem',
	],
	SHOWROOM_NEW_CAR: [
		'do jakého showroomu teď mohu přijít na nová auta',
		'jde se k vám přijít podívat na nová auta',
		'mohu se přijít podívat na nová auta',
		'máte na pobočce showroom s novými vozidly',
		'máte na prodejně vystavená nová auta',
		'máte nová auta skladem',
		'máte nové automobily i skladem',
		'máte nové vozy na showroomu skladem',
		'máte v showroomu nové vozy',
		'v jakém autosalonu se mohu přijít podívat na nové automobily',
		'zajímá mě, jestli jsou nová auta na prodejně skladem',
	],
	SHOWROOM_USED_CAR: [
		'do jakého showroomu teď mohu přijít podívat se na ojetá auta',
		'jde se k vám přijít podívat na použitá auta',
		'mohu se přijít podívat na ojetá auta',
		'máte na pobočce showroom s ojetými auty',
		'máte na prodejně vystavená ojetá auta',
		'máte ojetá auta skladem',
		'máte použité automobily i skladem',
		'máte použité vozy na showroomu skladem',
		'máte showroom s použitými vozidly',
		'v jakém autosalonu se mohu přijít podívat na vaše ojeté automobily',
		'zajímá mě, jestli použitá auta na prodejně jsou skladem',
	],
	SIM_ACTIVATE: [
		'aktivace SIM',
		'aktivace paušální sim karty',
		'aktivaci paušální simky',
		'aktivaci sim karty',
		'aktivovat simku',
		'chci aktivovat svoji novou SIM',
		'chci řešit aktivaci sim',
		'dostal jsem novou SIM kartu, potřebuju ji aktivovat',
		'jak dlouho trvá aktivace simkarty',
		'jak mám aktivovat simku',
		'je zaktivování SIM na dlouho',
		'mám novou SIMku, potřebuju ji aktivovat',
		'vysvětlíte mi aktivaci sim karty',
		'za jak dlouho bude SIM karta aktivní',
	],
	SIM_CHANGE_SIM: [
		'chci vyměnit simku',
		'chci vyměnit starou sim za novou',
		'chci zachovat telefonní číslo, ale potřebuju microsim kartu',
		'chci řešit výměnu sim',
		'do nového telefonu potřebuju menší kartu, microsim kartu',
		'menší simka',
		'místo stávají simky prosím novou',
		'potřebuji vyměnit SIM kartu za menší',
		'potřebuju microsim kartu',
		'potřebuju vyřešit výměnu sim karty',
		'simkarta výměna',
		'vyměnit sim kartu za novou',
		'výměna simky',
		'výměna stávající SIM',
		'výměnu sim',
	],
	SIM_ERROR: [
		'Simka je rozbitá',
		'chci nahlásit poruchu SIM karty',
		'chci nahlásit závadu SIM karty',
		'chci řešit poruchu sim',
		'hlásím poruchu sim karty',
		'moje SIMkarta je vadná',
		'mám potíže se SIMkou, nejde mi',
		'mám poškozenou simkartu',
		'mám problém se SIM kartou, nefunguje',
		'nefunguje mi SIM karta',
		'nefunguje mi karta',
		'nefunkční SIMka',
		'nefunkční sim karta',
		'nejde mi SIM karta',
		'pokazila se mi SIM karta',
		'porouchala se mi SIM karta',
		'porucha SIM',
		'poruchu sim karty',
		'poškozená sim',
		'rozbila se mi SIM karta',
	],
	SIM_EXPIRED: [
		'expirace platnosti sim karty',
		'expirace sim karty',
		'expirovala mi SIM karta',
		'expirovala mi platnost SIM karty',
		'expirovala mi simka',
		'expirovaná platnost sim karty',
		'hlásí mi to, že skončila platnost sim karty',
		'konec platnosti sim karty',
		'moje SIM karta není platná',
		'moje SIMkarta už neplatí',
		'nemám platnou SIMku',
		'platnost SIM karty',
		'skončila mi platnost SIMky',
		'uběhla platnost sim karty',
		'vypršela mi SIM karta',
		'vypršela mi platnost SIM karty',
	],
	SIM_E_SIM: [
		'aktivace eSIM',
		'bude fungovat na mém telefonu eSIM',
		'chtěla bych esim kartu',
		'co je esim',
		'eSIM',
		'eSIM karta',
		'jak funguje eSIM',
		'jak mám objednat esim',
		'jak nastavím eSim',
		'kde koupím eSIM',
		'kde můžu koupit eSIM',
		'mohu používat v mém mobilu eSIM kartu',
		'potřebovala bych změnit e sim',
		'potřebuju informace k eSIM',
	],
	SIM_GENERAL: ['micro SIM', 'nano SIM', 'se simkartou', 'sim', 'simka', 'simkarta'],
	SIM_LOST: [
		'já jsem přišla o telefonní číslo',
		'přišla jsem o telefonní číslo',
		'taky ale ztratila telefonní číslo',
		'ztratila jsem telefonní číslo',
	],
	SIM_NEW_SIM: [
		'chci pořídit novou sim',
		'druhou sim',
		'druhá simka',
		'nová SIM',
		'nová SIM karta',
		'nová micro SIM',
		'nová nano SIM',
		'nová simka',
		'potřeboval bych sim kartu',
		'pořídit novou sim kartu',
	],
	SIM_SETTING: [
		'chci radu ohledně nastavení sim',
		'chci řešit nastavení sim',
		'nastavení simky',
		'potřebuju poradit jak nastavit simkartu',
		'potřebuju poradit s nastavením sim karty',
	],
	SIM_WARNINGOFEXPIREIGNORED: [
		'nestihl jsem lhůtu pro dobití kreditu a přestala mi fungovat sim',
		'nestihl jsem si dobít kredit, vypršela mi lhůta',
		'nestihl jsem si dobít ve lhůtě a nemůžu volat a posílat sms',
		'nestihl jsem si ve lhůtě dobít kredit a nefunguje mi simka',
		'prošla mi lhůta pro dobití a nemůžu volat ani posílat zprávy',
		'prošvihl jsem dobití kreditu ve lhůžě a nefungue mi sim',
		'simka je neplatná, mám prošlou lhůtu pro dobití',
		'simka je neplatná, protože jsem si zapomněl dobít kredit',
		'skončila mi lhůta pro dobití kreditu a nefunguje mi SIM',
		'skončila mi lhůta pro dobití kreditu a nejde mi si SIM',
		'skončila mi lhůta pro dobití kreditu a přestala mi platit',
		'už mi neplatí simka, protože jsem si nestihl dobít kredit',
		'vypršela mi lhůta pro dobití, co mám dělat',
		'zapomněl jsem si včas dobít kredit a vypršela mi platnost simky',
	],
	SLUZBY_GENERAL: [
		'Chtěla bych upravit nastavení služeb',
		'Chtěla bych zrušit jednu službu, co mám u vás',
		'Mobilní služby',
		'Nabídka služeb',
		'Potřeboval bych si aktivovat u vás novou službu',
		'Potřebovala bych si zapnout službu',
		'Přidat',
		'chci si změnit nastavení služeb',
		'co nabízíte',
		'dodatečné služby',
		'jakou máte nabídku služeb?',
		'jaké máte služby',
		'jaké nabízíte služby',
		'nabídky služeb',
		'nabízíte i služby jako m-platba, roaming a podobné?',
		'nabízíte služby všem',
		'nová služba',
		'přidat službu',
		'služby',
		'vaše služby',
		'zajímají mě vaše služby',
		'zajímají mě vámi nabízené služby',
	],
	SMOG_CHECK_SERVICE: [
		'chci si zamluvit termín na změření emisí',
		'chtěla bych si nechat změřit emise',
		'emisní kontrola',
		'kdy máte volno na emisích',
		'kontrola emisí',
		'měření emisí',
		'měření výfukových plynů',
		'můžu přijet na měření emisí',
		'můžu si u vás nechat poměřit emise',
		'nechat si změřit emise',
		'objednat se na emise',
		'potřebuju na emise',
		'potřebuju zkontrolovat emise',
		'servisní kontrola emisí',
		'zkontrolovat výfukové plyny',
		'změřit výfukové plyny',
	],
	SPLATKOVY_KALENDAR_AMOUNT: [
		'chci se informovat o výši splátky mého dluhu u vás',
		'chci znát výši splátky za zařízení, které od vás mám',
		'chtěl bych vědět částku, kterou mám splácet za notebook',
		'chtěla bych vědět, jakou částku vám mám splácet',
		'chtěla bych znát výši splátky za smart watch',
		'chtěla bych znát výši splátky za telefon',
		'jakou mám nastavenou váši splátky',
		'jakou částku platím měsíčně za zakoupený modem',
		'jaká je moje splátka za telefon',
		'jaká je moje výše splátky za telku',
		'jaká je měsíční splátka chytrých hodinek',
		'jaká je výše splátek za tablet',
		'jaká je částka splátky k vyrovnání dluhu',
		'kolik mám  splácet za chytrý náramek',
		'kolik mám platit měsíčně za handsfree',
		'kolik mám splácet za mobil',
		'kolik platím za laptop',
		'kolik splácím za televizi',
		'kolik vám splácím',
		'mám u vás dluh, jakou částku mám splácet',
		'splátka za set-top-box',
		'výše splátky chytrého auta',
		'výše splátky dluhu u vás',
		'řekli byste mi, jaká je výše mé splátky za notes',
	],
	SPLATKOVY_KALENDAR_CHANGE_AMOUNT: [
		'chci měsíční splátku zvýšit',
		'chci platit měsíčně jinou částku za chytré hodinky',
		'chci snížit splátku',
		'chci změnit částku ve splátkovém kalendáři',
		'chtěla bych změnit výši splátky',
		'dlužím vám a potřebuju upravit splátkový kalendář',
		'mohla bych si navýšit částku ke splácení dluhu',
		'mohla bych si snížit částku splátky za mobil',
		'můžu si změnit výši splátky mojí dlužné částky',
		'potřebuju snížit splátku mého dluhu',
		'potřebuju snížit splátku za televizi',
		'potřebuju změnit splátkový kalendář mého dluhu u vás',
		'potřebuju změnit splátku na telefon',
		'potřebuju změnu ve splátkovém kalendáři',
		'přenastavit výši splátky za chytré auto',
		'tenhle měsíc bych potřebovala změnit částku splátky za laptop',
		'změnit částku, ráda bych splácela méně',
		'zvýšit splátku za modem',
	],
	SPLATKOVY_KALENDAR_CHANGE_DATE: [
		'chci změnit datum splatnosti',
		'chci změnit den splácení',
		'chtěla bych změnit datum splátky',
		'dobrý den já bych potřeboval prosím vás posunout splátku telefonu o týden jestli by to šlo',
		'jde odložit splatnost?',
		'jde odložit splátku?',
		'můžu si odložit splatnost?',
		'potřeboval bych odložit splatnost paušálu',
		'potřebuju přesunout splátku',
		'potřebuju splácet jiný den v měsíci',
		'potřebuju změnit datum, kdy proběhne splátka',
		'prosím o změnu data splatnosti',
		'přesunout splátku na jiný den',
	],
	SPLATKOVY_KALENDAR_DATE: [
		'datum splatnosti',
		'jaké je datum splatnosti mé splátky',
		'nestihla jsem datum splatnosti',
		'potřebovala bych znát datum splatnosti',
		'zaplatila jsem po splatnosti',
	],
	SPLATKOVY_KALENDAR_GENERAL: [
		'Je možné vytvořit splátkový kalendář?',
		'chci se informovat na možnost splátkového kalendáře',
		'jaký je můj splátkový kalendář',
		'můžete mě informovat o mém splátkovém kalendáři',
		'můžu vám to splácet?',
		'požaduju splátkový kalendář',
		'splátkový kalendář',
		'zaplatit dále to na splátky',
	],
	SPLATKOVY_KALENDAR_HOW_MANY: [
		'kolik mi ještě zbývá splátek',
		'kolik mi ještězbývá zaplatit splátek',
		'kolik mám ještě platit splátek',
		'mám od vás zařízení na zpátky a kolik eště splátek musím zaplatit',
		'splácím zařízení, kolik ještě bude splátek',
	],
	SPOLU_TOGETHER: ['dobře společně', 'dobře, tak spolu', 'no tak spolu', 'společně', 'spolu', 'tak společně'],
	STATION: [
		'chci někoho z benzínky',
		'chci se dovolat na benzínku',
		'chci volat na čerpačku',
		'dovolat se na čerpací stanici',
		'dovolat se na čerpačku',
		'dáte mi kontakt na čerpačku',
		'dáte mi číslo na benzinku',
		'dáte mi číslo na čerpačku',
		'kdo má na starosti pumpu',
		'kontakt na obsluhu pumpy',
		'kontakt na pumpu',
		'kontakt na čerpací stanici',
		'kontakt na čerpačku',
		'někoho z čerpačky',
		'potřebuji kontakt na čerpačku',
		'potřebuji se dovolat na čerpačku',
		'potřebuju kontakt na benzinku',
		'přepojit na někoho na čerpací stanici',
		'přepojte mě na benzinku',
		'přepojte mě na někoho z pumpy',
		'přepojte mě na čerpací stanici',
		'spojit s někým z čerpací stanice',
		'číslo na čerpačku',
	],
	STATION_BROKEN_AUTOMAT: [
		'automat na čerpačce je zaseknutý',
		'automat nechce tankovat',
		'automat nespustí tankování',
		'automat vůbec nereaguje',
		'automat vůbec netankuje',
		'máte rozbitý čerpací automat',
		'na pumpě nefunguje automat',
		'nefunguje tankovací automat',
		'nefunguje vám tankomat',
		'nevím, jak mám natakovat',
		'něco je špatně s automatem na tankování',
		'porucha tankovacího automatu',
		'rozbil se vám čerpací automat',
		'tankovací automat je mimo provoz',
		'tankovací automat je zaseklý',
		'čerpací automat vůbec nejede',
	],
	STATION_FILLING: [
		'dá se u vás tankovat',
		'jde u vás natankovat diesel',
		'je u pumpy v nabídce nafta',
		'lze u vás tankovat',
		'mohu u vás natankovat i já',
		'mohu u vás normálně tankovat',
		'máte na pumpě taky diesel',
		'můžu u vás natankovat',
		'nabízíte na pumpě benzín',
		'pumpujete i naftu',
		'tankujete benzín',
	],
	STATION_FILLING_NOT_WORK: [
		'je rozbité tankování',
		'nefunguje tankování',
		'nejde mi tankovat',
		'nejde natankovat',
		'nejsem schopný natankovat',
		'nevím, jak natankovat',
		'nevím, jak to funguje s tankováním',
		'pokažené tankování',
		'takování nejde',
		'tankování vůbec nejede',
	],
	STATION_FUEL_COST: [
		'aktuální ceník pohonných hmot',
		'cena benzínu',
		'cena dieselu',
		'cena nafty',
		'cena za litr naturalu',
		'ceny na pumpě',
		'jaká je momentálně cena za litr nafty',
		'jaké jsou aktuální ceny na pumpě',
		'jaké jsou ceny tankování',
		'jaké máte ceny na čepačce',
		'kolik korun stojí u vás litr benzínu',
		'kolik peneěz u vás stojí natural',
		'kolik peněz stojí benzín',
		'kolik se zrovna cena za litr benzínu',
		'kolik stojí litr benzínu',
		'kolik stojí litr dieselu',
		'kolik stojí litr nafty',
		'kolik stojí litr naturalu',
		'kolik stojí zemní plyn',
		'kolik u vás stojí benzín',
		'kolik u vás stojí plyn',
		'za kolik korun je nafta',
		'za kolik máte cng',
		'za kolik máte natural 95',
	],
	STATION_PAYMENT: [
		'automat mi odmítnul platbu',
		'blokované placení kartou za palivo',
		'je rozbité placení u tankomatu',
		'máte zablokované placení kartou',
		'na pumpě je zablokované placení',
		'na čerpačce nefunguje placení',
		'naúčtovalo mi to vyšší platbu za tankování',
		'nedaří se mi zaplatit kartou',
		'nefunguje placení na pumpě',
		'nefunguje placení na čerpačce',
		'nefunguje placení za benzín',
		'nefunguje platba kartou za tankování',
		'nejde platit na benzín kartou',
		'nejde platit za naftu kartou',
		'nejde zaplatit za tankování',
		'proč blokujete placení kartou',
		'strhlo mi to platbu za tankování dvakrát',
		'strhlo mi to víc peněz, než jsem natankoval',
	],
	STAVAJICEXISTING_DEVICE: [
		'chci informace ke splátkám stávajícího zařízení',
		'inforMace k zařízení, které už u vás mám',
		'informace ke stávajícímu zařízení',
		'stávající',
		'stávající zařízení',
		'zajímají mě splátky stávajícího zařízení',
		'zajímá mě stávající zařízení',
	],
	STAV_OBJEDNAVKY_WHEN: [
		'dokdy mi doručíte moji objednávku',
		'dokdy vyřídíte moji objednávku',
		'kdy dorazí kurýr s tou objednávkou',
		'kdy dorazí moje objednávka',
		'kdy mi doručíte moji objednávku',
		'kdy mi přijde balík',
		'kdy má přijít moje objednávka',
		'kdy přijede někdo s tou objednávkou',
		'mají mi doručit balík, nevíte přesný čas?',
		'v kolik mi má přijít balík',
		'v kolik přijde ta objednávka',
		'za jak dlouho mi dovezou balík',
		'za jak dlouho mi přijde objednávka',
		'za jak dlouho mi to přivezou',
		'za jak dlouho vyřídíte moji objednávku',
	],
	STAV_REKLAMACE_COMPLAINT_STATUS: [
		'chci znát stav reklamace',
		'chci řešit stav reklamace',
		'chci řešit stav reklamace televize',
		'jak je na tom reklamace mobilu',
		'jak je na tom reklamace mého modemu',
		'jaký je stav reklamace handsfree',
		'jaký je stav reklamace mého telefonu',
		'potřebuju vědět stav reklace set-to-boxu',
		'potřebuju vědět stav reklamace',
		'reším stav reklamace',
		'stav reklamace',
	],
	STK_ENDING_SERVICE: [
		'asi mi propadlo stk',
		'asi ni propadla technická kontrola',
		'blíží se konec stk',
		'chci se zeptat, kdy mi končí technická',
		'kdy mi končí stk',
		'konec platnosti technické kontroly',
		'končí mi planost stk',
		'končí mi technická',
		'prošlá technická kontrola',
		'prošlé stk',
	],
	STK_SERVICE: [
		'může přijet na stk',
		'objednat se na STK',
		'objednat se na stanici technické kontroly',
		'objednat se na technickou',
		'objednat se na technickou kontrolu',
		'pravidelná technická kontrola',
		'rezervovat den na technickou prohlídku',
		'servisní kontrola',
		'servisní prohlídka',
		'stanice technické kontroly',
		'technická prohlídka',
		'zarezerovat si termín na technickou kontrolu',
	],
	STOCK_DACIA: [
		'chci kontakt na sklad Dacie',
		'dovolat se do skladu Dacie',
		'jaké je číslo na sklad Dacie',
		'jaký je telefon na sklad Dacia?',
		'kontaktovat sklad pro značky Dacia',
		'musím hovořit se skladem Dacie',
		'prosím přepojte mě do skladu dacie',
		'sklad Dacia, jaké má číslo?',
		'spojit se skladem vozů Dacia',
		'telefonní číslo na sklad Dacie',
	],
	STOCK_KIA: [
		'dejte mi číslo do skladu značky Kia',
		'dovolat se na sklad pro vozy Kia',
		'dovolat se na sklad vozů Kia',
		'dovolat se na sklad značky Kia',
		'dáte mi telefon na sklad vozů Kia',
		'jaké je číslo na sklad Kia',
		'když chci sklad vozů Kia, kam mám zavolat?',
		'kontakt na sklad Kia',
		'mobil na sklad vozů Kia',
		'telefon na sklad Kia',
	],
	STOCK_OPEL: [
		'dejte mi prosím číslo na sklad opelu',
		'dovolat se na do skladu opelu',
		'dáte mi prosím číslo na sklad opelu',
		'jaké je číslo na sklad vozů Opel',
		'jaký má sklad opelu telefon',
		'kontaktovat sklad pro Opel',
		'potřebuju kontakt na sklad pro vozy Opel',
		'prosím přepojit na sklad Opel',
		'přepojte mě na sklad Opelu',
		'telefon na sklad opelu',
		'číslo na sklad, kde mají auto Opel',
	],
	STOCK_RENAULT: [
		'Když chci sklad renaultu, na jaké číslo mám volat?',
		'jaký je telefon na sklad pro značku Renault',
		'jaký je telefon na sklad renaultu',
		'kontakt na sklad Renaultu',
		'kontakt na sklad odhledně značky renault',
		'potřebuju se dovolat na sklad vozů Renault',
		'prosím přepojte mě na sklad Renaultu',
		'přepojil byste mě na sklad vozů Renault',
		'přepojíte mě na sklad pro Renault',
		'telefon na sklad Renaultu',
		'telefonní číslo na sklad pro značku Renault',
	],
	STOCK_SKODA: [
		'chci kontakt na sklad pro škodovku',
		'jaké je číslo na sklad se škodovkami',
		'kontakt na sklad vozů Škoda',
		'potřebuju se dovolat do skladu Škoda',
		'přepojil byste mě na sklad, co prodává škodovky',
		'přepojit na sklad Škoda',
		'přepojte mě na sklad škodovek',
		'telefon na sklad vozů značky Škoda',
		'telefonní číslo na sklad vozů Škoda',
	],
	STOCK_VOLVO: [
		'dovolat se do skladu volva',
		'jaký má kontakt sklad, co má na starosti volvo',
		'kontakt na sklad volva',
		'nějaký telefon do skladu volva',
		'potřebuji kontakt na sklad volva',
		'prosím přepojte mě na sklad volvo',
		'spojit s někým ze skladu volvo',
		'telefon na sklad pro značku volvo',
		'číslo na sklad pro volvo',
	],
	STOCK_VW: [
		'chci se dovolat do skladu volkswagenu',
		'dovolat se na sklad vozů značky Volskwagen',
		'jaké je číslo na sklad Volskwagenu',
		'jaký je telefon do skladu Volkswagenu',
		'když chci sklad volskwagen, na jaké číslo mám volat',
		'kontakt na sklad Volskwagenu',
		'kontakt na sklad s volkswagenem',
		'přepojíte mě na sklad volkswagenu',
		'telefon na sklad Volkswagenu',
		'číslo na sklad Volkswagen',
	],
	SVOJE_GENERAL: [
		'Chci informace k mojí smlouvě',
		'Informace k moji smlouvě',
		'Informace o mojí smlouvě',
		'Ke svojí smlouvě',
		'Moje smlouva',
		'Potřebují informace ke svojí smlouvě',
		'Zajímá mě smlouva, která je napsaná na mě',
		'moje',
		'moji',
		'smlouvu co je psaná na mě',
		'smlouvu na moje jméno',
		'smlouvu na mě',
		'svoji',
	],
	TARIFY_A_SMLOUVY_DOTACE_NA_MOBIL: [
		'Mám u vás paušál a měla by tam být nějaká dotace na mobil',
		'Mám u vás paušál a měla by tam být nějaká sleva na mobil',
		'dotace na mobil',
		'sleva na mobil',
		'ta dotace na nějaký ten mobil co máte teďka v nabídce nebo si můžeme vybrat jiný?',
		'ta sleva na nějaký ten mobil co máte teďka v nabídce nebo si můžeme vybrat jiný?',
	],
	TARIFY_A_SMLOUVY_KOLIK_STOJMUJ_TARIF: [
		'Chci řešit kolik stojí ten tarif co mám?',
		'kolik stojí Můj tarif',
		'kolik stojí ten tarif co tady mám?',
	],
	TARIFY_A_SMLOUVY_KONCCISLO: [
		'Přišel mi e-mail, že mi končí moje číslo.',
		'končí mi moje číslo',
		'prišla mi správa, že mi končí číslo.',
		'psali jste mi, že mi končí číslo.',
		'správa o ukončení čísla',
	],
	TARIFY_A_SMLOUVY_NABIDKA_TARIFU: [
		'Já bych se chtěl informovat ohledně nabízených tarifů.',
		'Měla bych zájem od vás o nějaké paušální karty.',
		'chci vidět vaši nabídku tarifú',
		'nabídka paušálních tarifu',
		'nabídka paušálu',
	],
	TARIFY_A_SMLOUVY_NABIDKA_TELEFONU: [
		'Bral jsem u vás mobil na paušální tarif a chtěl bych vzít ještě jeden mobil',
		'Já bych se chtěl informovat ohledně nabízených telefonů.',
		'chci vidět vaši nabídku telefonů',
		'chci zistiť jestli je možné vzít více telefonů na paušál',
		'mám od vás na paušál mobil a chtěl bych ještě jeden telefon',
		'nabídka mobilů',
		'nabídka telefonů',
	],
	TARIFY_A_SMLOUVY_OKU_KOD: ['Já bych, prosím, potřeboval vytvořit OKU kod.', 'OKU', 'OKU kod', 'chci OKU kod'],
	TARIFY_A_SMLOUVY_PRECHOD_CISLA: [
		'Chci přenést své telefonní číslo',
		'Jsem u operátora O2 a chtěl bych od vás paušál s telefonem.',
		'Mám dvě SIM karty. Jednu od vás a jednu od Blackmobilu a chtěl bych přesunout číslo od Blackmobilu k vám.',
		'Mě kontaktoval váš obchodní zástupce a my jsme se domlouvali na nějakých věcech',
		'Nejsem vaším klientem. Dala jsem výpověď u stávajícího operátora a chtěla jsem vědět, jestli je nutná nějaká výpovění lhůta. Protože oni mi řekli, že měsíc.',
		'ja přenáším číslo k vám ale Vodafone mi píše',
		'lze to nějak přenést své telefonní číslo',
		'na tomto čísle už má zažádáno o převod',
		'přenos čísla k vám',
		'přestup k vám',
		'váš obchodní zástupce mi měl zaslat návrh jakoby možnosti řešení k vám.',
	],
	TARIFY_A_SMLOUVY_PREVOD_TELEFONU: [
		'Já dělám převod čísla a chci vědet jestli mi treba vyžádan souhlas',
		'Já dělám převod čísla za jednoho pána',
		'převedli jsme nám tefon',
		'převod tefonu na jinou osobu',
		'udělali jsme převod tefonu na manželku.',
		'udělali jsme to, že jsme převedli tefon můj druhý na manželku.',
	],
	TARIFY_A_SMLOUVY_SLUZBY_NOVE_JINE_ZMENA: [
		'Já mám několik proseb na změnu mé objednávky u vás služeb.',
		'chtěla bych přejít na jiný služby',
		'mám u vás balík služeb a chci si to změnit',
		'u tohoto čísla chcu změnu mé objednávky služeb.',
		'změna služeb, který jsem si objednala',
	],
	TARIFY_A_SMLOUVY_SMLOUVA_NOVE_JINE_ZMENA: [
		'Chci tu smlouvu, kterou jsem měla předtím nebo lepší.',
		'Já mám několik proseb na změnu mé smlouvy na vaše služby.',
		'mám u vás smlouvu a chci si ji změnit',
	],
	TARIFY_A_SMLOUVY_SMLOUVY_ZASKRTNUTO_AKTIVOVAT: [
		'Chtěla bych ověřit zda jsem ve formuláři zaškrtla aktivovat v zmluve.',
		'Chtěla bych ověřit, když jsem převáděla telefonní číslo pod naši rámcovou smlouvu, ve formuláři jsem nezaškrtla aktivovat.',
		'převáděla jsem telefonní číslo pod naši rámcovou smlouvu, mám to aktivováno?',
	],
	TARIFY_A_SMLOUVY_ZRUSENCISLA: [
		'Já tam mám ještě dvě telefonní čísla. A ty bych chtěl zrušit.',
		'chceme to zrušit druhé číslo, které nepoužívám, paušál,',
		'chci zrušit telefonní čísla a přišla nám zpráva, že se to zruší nejdřív',
		'jste řekli, že pošlete dedečkovi kód, aby u vás mohl zrušit telefon',
		'kód na zrušení telefonů',
		'mám druhou SIM kartu, kterou nepoužívám a platím za něj taky paušál,chceme to zrušit',
		'přišla smska, že končí tarif. Když řeknu rodné číslo, zjistíte mi co to je za smsku?',
		'zruš paušál',
		'zruš telefón',
		'zruš číslo',
		'zrušení tarifu',
		'zrušení čísla',
		'zrušit telefonní paušál',
		'zrušit telefonní tarif',
		'zrušit telefonní čísla',
		'zrušit telefonní číslo',
	],
	TARIFY_A_SMLOUVY_ZRUSENSMLOUVY: [
		'Já tam mám ještě smlouvu. A tu chci zrušit.',
		'chci jen zrušit smlouvu',
		'potřebuju zrušit smlouvu',
		'zrušení smlouvy',
		'zrušit smlouvy, kterou mám nyní',
		'zrušit telefonní smlouvu',
	],
	PROLONGACE_TARIF: [
		'chci jen prodloužit tarif',
		'chci mít tento tarif i nadále',
		'chtěla bych prodloužit tarif',
		'je možné si u vás tarif prodloužit?',
		'Končí mi tarif a nechtěla bych nic měnit, chtěla bych to nechat tak, jak to mám.',
		'můžu si prodloužit tarif',
		'potřebuju prodloužení tarifu',
		'potřebuju prodloužit tarif',
		'prodloužení paušálu',
		'prodloužení paušálu, který mám nyní',
		'prodloužení stávajícího tarifu',
		'prodloužení tarifu',
		'prodloužit tarif',
		'prolongace paušálu',
		'prolongace tarifu',
		'proudloužení paušálu, který mám nyní',
	],
	ZMENA_TARIF: [
		'a potřebovala bych informace ohledně změny mého tarifu',
		'byl mi měněn tarif',
		'chci si změniť paušál na lepší',
		'chci si změniť tarif na jiný',
		'chci změnit paušál',
		'chci změnit svůj tarif',
		'chci změnit tarif',
		'chci změnit tarif, abych ušetřil',
		'chci změnit tarif, chci ho mít výhodnější',
		'chci změnu tarifu telefonu',
		'Chtěla bych změnit tarif, co máte za nabídku',
		'dobrý den byl mi měněn tarif měla sem s plus',
		'ja bych potřebovala našemu dědovi změnit tarif telefonu',
		'jak si můžu změnit tarif na svém čísle ?',
		'Jak si můžu změnit tarif?',
		'Včera jsem nechtíc odklikl, že chci měnit tarif',
		'změna mého tarifu',
		'změna tarifu',
		'změnily ste mi tarif a já nesouhlasím',
		'změnit tarif',
		'změniť paušál na neomezené volaní do všech sití',
		'potřebuju ověřit změnu tarifu',
		'potřebuju poradit se změnou tarifu',
		'potřebuju provézt změnu tarifu',
		'nemůžu měnit tarif, chci všechno po starém.',
		'potřeboval bych změnu tarifu',
		'u tohoto čísla si chtěla změnit tarif',
		'tarif změnit',
		'upravit tarif',
		'úprava tarifu',
	],
	LEVNY_TARIF: ['jde dát nějaký levnější tarif?', 'mám studentské číslo a chci jestli jde dát nějaký levnější tarif?'],
	DRAZSTARIF: [
		'Přišla mi SMS o tom, že tarif může být dražší o 150Kč.',
		'skončila mi smlouva, mám nyní dražší tarif než jsem měl',
		'Chci ten dražší tarif.',
		'chtěl bych dražší tarif.',
	],
	JINY_TARIF: [
		'jiný tarif',
		'chtěla bych přejít na jiný tarif',
		'chtěl bych jiný tarif',
		'chci jiný paušál',
		'nebídněte mi prosím jiný lepší tarif',
		'synova nabídka na jiné číslo',
		'Tarify jiné',
		'tak jak mám nastavený tarif, mi nevyhovuje',
		'Tarify lepší',
		'Tarify nové',
		'chtěla bych nový tarif',
	],
	TARIF: [
		'chci od vás ten tarif z reklamy',
		'Chci poradit ohledně tarifu',
		'chci přejít na měsíční paušál',
		'Chci přejít na paušál',
		'chci stejný tarif jako má kamarád',
		'chci váš tarif od naší firmy',
		'Chci řešit tarify',
		'Chtěl bych probrat tarif',
		'chtěla bych další číslo na paušál',
		'Díval jsem se na reklamu v televizi a chtěl jsem si pořídit ten nový super tarif od vás.',
		'Já mám u vás paušál, chtěla bych tam přidat další číslo',
		'Já mám u vás paušál, mám tam dvě čísla',
		'mám dotaz ohledně tarifů',
		'mám problém s paušálem můžete mi pomoct',
		'Mám u vás paušál',
		'Měsíční paušál',
		'No potřebuju spojit na někoho ohledně tarifů',
		'paušál pro více osob',
		'paušál',
		'potřeboval bych vyřešit problém s tarifem',
		'potřebovali bychom probrat tarify',
		'potřebuju informace ohledně tarifů',
		'potřebuju poradit s tarifem',
		'Potřebuju řešit paušál',
		'potřebuju řešit tarif',
		'Přechod na měsíční tarif',
		'Přechod na paušál',
		'Převod na paušál',
		'tarif se závazkem',
		'tarify prosím',
		'Tarify volání',
		'Tarify',
		'to se má tarifem',
		'volám ohledně tarifů',
		'váš tarif',
	],
	NEOMEZENY_TARIF: [
		'a potřeboval bych vyřídit neomezený tarif',
		'chci neomezený tarif',
		'Já bych potřeboval zapnout balíček volání do všech sítí',
		'kamarád dostal vše neomezeně, já to chci taky',
		'kamarád od vás dostal nový neomezený tarif a já bych ho taky rád dostal',
		'prosím neomezený tarif',
		'vyřídíte mi neomezený tarif',
	],
	TECHNIK_GENERAL: [
		'co je s tím technikem',
		'dnes má přijet technik',
		'dneska má technik připojit internet',
		'dobrý den já mám už na dneska domluvenýho technika který má připojit internet',
		'kdy dorazí ten technik',
		'kdy má přijet ten technik',
		'kdy můžeme čekat toho technika',
		'montér pořád nedorazil',
		'mám na dneska technika, který má připojit internet',
		'měl k nám přijet technik ale zatím nic',
		'může ten technik přijet jindy',
		'můžete mi dát termín, kdy mi technik nainstaluje internet',
		'na dnešek mám objednaného technika na internet',
		'opravář ještě nedorazil',
		'pořád čekáme na technika',
		'prosím řekněte mi termín instalace internetu',
		'servisák tu ještě není',
		'technik co měl přijet tu pořád není',
		'technik nedorazil',
		'technik pořád nepřijel',
		'technik už tu byl',
		'termín instalace internet',
		'zrušit objednaného technika',
	],
	TECHNIK_HELP: [
		'chci namontovat satelit',
		'chci vyřešit instalaci satelitu',
		'chci řešit instalací sat tv x',
		'chtěla bych pomoct s instalací',
		'jak namontuju internet',
		'pomůžete mi nainstalovat modem',
		'potřebuji pomoct s instalací internetu',
		'potřebuju pomoct s instalací modemu',
		'potřebuju radu ohledně namontování modemu',
		'potřebuju vyřešit instalaci internetu',
		'potřebuju vyřešit instalaci satelitu',
	],
	TECHNIK_NEED_SERVICE: [
		'chci domluvis servis',
		'chtěla bych zařídit servis',
		'potřebuju servis',
		'servis potřebujeme',
		'zařiďte mi servis prosím',
	],
	TECHNIK_NEED_TECHNIK: [
		'asi je potřeba, aby přijel technik',
		'chci domů technika',
		'no dobrý den potřebovala bych poslat domů technika',
		'potřebovala bych technika',
		'potřebuju domů objednat technika',
		'potřebuju, aby se na to přijel mrknout technik',
		'potřebuju, aby to přišel opravit technik',
		'pošlete mi prosím technika',
	],
	TELEFON_TABLET_MOBILE: [
		'Chci konfigurovat svůj mobil',
		'Nastavení mobilu',
		'Nemám návod na svůj telefon',
		'Potřebuju nastavit mobil',
		'Potřebuju stáhnout manuál na svůj mobil',
		'Potřebuju česká manuál na svůj mobil',
		'Spadnul mi do vody telefon a nefunguje',
		'Telefon nastavit',
		'Zprovoznit mobil',
		'Zprovoznit smartphone',
		'můj telefon',
		'nenabíjí se mi telefon, co s tím',
		'telefon',
	],
	TEST_RIDE: [
		'chci si domluvit testovací jízdu',
		'chci testovací jízdu',
		'chci zkušební jízdu',
		'chtěla bych jet na testovací jízdu',
		'chtěla bych testovací jízdu',
		'je možné si u vás objednat zkušební jízdu',
		'je možné si zamluvit testovací jízdu',
		'je v nabídce testovací projížďka',
		'jízda na zkoušku',
		'může na testovací projížďku',
		'můžu se objednat na testovací jízdu',
		'nabízíte testovací jízdu',
		'projet se na zkoušku',
		'projet si auto na zkoušku',
		'projet testovací jízdu',
		'projížďka na zkoušku',
		'rád bych zkušební jízdu',
		'zajímá mě testovací jízda',
		'zajímám se o testovací projížďku',
		'zamluvit si zkušební jízdu',
		'zarezervovat si zkušební jízdu',
		'zkušební projížďku prosím',
	],
	THATSALL: [
		'díky, to je všechno',
		'děkuji, za mě stačí',
		'myslím se, že je to vše',
		'ne děkuji to je vše',
		'nejspíš je to všechno',
		'nevím, uvidí se',
		'stačí, děkuji',
		'takhle je to všechno',
		'to bude vše',
		'to je vše',
		'to je všechno',
		'to je všechno, co jsem chtěl',
		'to je všecko',
		'To mi stačilo',
		'uvidíme, zatím',
		'už je to všechno',
		'už mám všecko',
		'už vím všechno',
	],
	THIRD_CHOICE: [
		'3. varianta',
		'chci třetí možnost',
		'možnost 3',
		'možnost tři',
		's tím třetím zmíněným',
		'to 3',
		'to třetí',
		'trojka',
		'tu třetí volbu',
		'třetí možnost',
		'třetí varianta',
		'třetí variantu',
		'třetí',
		'varianta 3',
		'varianta číslo tři',
		'za třetí',
		'zatřetí',
	],
	TMCZ_FROM_YOU: ['Od Vás', 'Váš', 'ano je váš', 'je Vás', 'myslím, že je váš', 'myslím, že od vás'],
	TV_HOW_TO_TUNE: [
		'jak mám naladit televizi?',
		'jak naladit televizi',
		'nemůžu na televizi naladit stanice',
		'nemůžu naladit televizní stanice',
		'nemůžu nic naladit na telce',
		'nemůžu nic naladit na televizi',
		'nevím, jak naladit televizní stanice',
		'pomůžete mi naladit telku',
		'poradíte mi, jak v televizi naladím stanice',
		'potřebuju pomoct s naladěním televizních stanic',
		'proč mi nejde naladit žádnou stanici na televizi?',
	],
	TV_PIN: [
		'Zapojili jsme si televizi a chce to po me PIN nákupu',
		'jak zjistím svůj pin k televizi',
		'jaký je PIN k televizi?',
		'kde najdu pin k televizi',
		'koupila jsem u vás televizi a chce to po mně zadat PIN',
		'můžete mi sdělit pin na televizi',
		'nemám pin k televizi',
		'potřebuju zjistit PIN k televizi',
	],
	TWO_GENERAL: [
		'2 bych chtěl řešit',
		'2. adresa',
		'Dvě',
		'adresu dvě',
		'druhou',
		'druhů',
		'dva',
		'dvojku',
		'na adrese dva',
		'tu druhou',
	],
	USED_OFFER: [
		'cenovou nabídku ojetého vozu',
		'cenovou nabídku zánovních automobilů',
		'cenová nabídka auta z druhé ruky',
		'cenová nabídka na ojeté auto',
		'cenová nabídka ojetin',
		'cenová nabídka ojetého vozidla',
		'cenová nabídka vozů z druhé ruky',
		'chci nabídku ojetin',
		'nabídka ojetých aut',
		'nabídka ojetých vozů',
		'nabídku zánovního automobilu',
		'nabídky ojetin',
		'nadídka na ojetinu',
		'zajímá mě vaše nabídka zánovních vozů',
	],
	UVITACTONY_ACTIVATE: [
		'Chtěl jsem si aktivovat uvítací melodii a píše mi to, že mám zablokovanou službu.',
		'aktivovat hudbu při vyzvánění',
		'aktivovat uvítací tón',
		'aktivovat vyzváněcí melodii',
		'aktivovat vyzváněcí tón',
		'chci, aby mi při vyzvánění hrála písnička',
		'chtěla bych tu melodii',
		'když volám, chci, aby tam hrála písnička',
		'zapnout hudbu při vyzvánění',
		'zapnout písničku při vyzvánění',
		'zapnout uvítací melodii',
		'zapnout vyzváněcí melodii',
		'zapnout vyzváněcí tón',
	],
	UVITACTONY_COST: [
		'Je uvítací hudba zdarma?',
		'Je uvítací melodie zadarmo?',
		'Je zpoplatněná hudba při vyznánění?',
		'Je zpoplatněná služba uvítací melodie?',
		'Je zpoplatněná služba uvítací tóny?',
		'Jsou písničky při vyzvánění zadarmo?',
		'Jsou uvítací tóny zdarma?',
		'Jsou zpoplatněné uvítací písničky?',
		'Kolik korun stojí hudba při vyzvánění?',
		'Kolik stojí písnička při vyzvánění?',
		'Kolik stojí služba uvítací tóny?',
		'Kolik stojí uvítací melodie?',
		'Kolik zaplatím, když chci uvítací tón do mobilu?',
		'Musí se platit za uvítací melodii?',
		'Musím platit za uvítací tony?',
		'Máte ještě na první měsíc uvítací melodii zdarma?',
		'Platí se za vyzváněcí hudbu?',
		'Uvítací melodie co mám v telefonu jsou zpoplatněný.',
		'Za písničku při vyzvánění se platí?',
		'kolik korun stojí vyzváněcí hudba?',
	],
	UVITACTONY_DEACTIVATE: [
		'Bohužel jsem si dal uvítací tón a nevím jak ho deaktivovat.',
		'Jak to máte s těma uvítacíma tónama, včera mně skončil a už ho nechci.',
		'Mám u vás službu uvítací tóny nebo něco takého a chtěl bych to zrušit.',
		'Potřeboval bych vyřešit malý problémek na telefonu, a to zrušit uvítací tón.',
		'Potřebovala bych zrušit ten uvítací tón.',
		'a zrušit vyzvánění u telefonního čísla',
		'chci zrušit uvítací tón',
		'deaktivovat hudbu při vyzvánění',
		'deaktivovat písničku při vyzvánění',
		'deaktivovat uvítací melodii',
		'deaktivovat uvítací tón',
		'deaktivovat vyzváněcí hudbu',
		'deaktivovat vyzváněcí melodii',
		'deaktivovat vyzváněcí tón',
		'nechci hudbu',
		'nechci už tu písničku',
		'odstranit vyzvánění u mého telefonního čísla.',
		'už nechci mít uvítací melodii',
		'už nechci používat vyzváněcí tón',
		'už nechci uvítací tón',
		'už nechci, aby mi při vyzvánění hrála písnička',
		'vypnout  vyzváněcí tón',
		'vypnout písničku při vyzvánění',
		'vypnout uvítací melodii',
		'vypnout uvítací tón',
		'vypnout vyzváněcí melodii',
		'vypněte mi ten uvítací tón',
		'vypněte mi tu hudbu',
		'vypněte mi tu hudbu, která hraje při volání',
		'vypněte mi tu melodii',
		'vypněte mi tu písničku',
		'zrušit hudbu při vyzvánění',
		'zrušit písničku při vyzvánění',
		'zrušit uvítací melodii',
		'zrušit uvítací písničku',
		'zrušit vyzváněcí hudbu',
		'zrušit vyzváněcí melodii',
		'zrušit vyzváněcí tón',
	],
	UVITACTONY_GENERAL: [
		'hraje mi tam písnička',
		'hudba při vyzvánění',
		'pořád mi tam něco hraje',
		'písnička při vyzvánění',
		'uvítací hudba',
		'uvítací melodie',
		'uvítací písnička',
		'uvítací tón',
		'vyzváněcí hudba',
	],
	UVITACTONY_SETTINGS: [
		'Na tomto čísle mám uvítací tóny Tandra? a chtěl bych tam jiný',
		'chci nastavit jinou písničku při vyzvánění',
		'chci nastavit jinou uvítací melodii',
		'chci nastavit jinou vyzváněcí hudbu',
		'chci si dát jinou uvítací hudbu',
		'chci si dát jinou vyzváněcí písničku',
		'chci si dát jiný uvítací tón',
		'chci změnit písničku při vyzvánění',
		'chci změnit uvítací melodii',
		'chci změnit uvítací tón',
		'nastavit hudbu při vyzvánění',
		'nastavit uvítací tón',
		'nastavit vyzváněcí melodii',
		'nastavit vyzváněcí tón',
		'nastavíte mi písničku?',
		'nastavíte mi uvítací tón?',
		'nastavíte mi vyzváněcí hudbu?',
		'nastavíte mi zváněcí melodii?',
		'potřebuju změnit nastavení uvítacího tónu',
		'změnit nastavení písniček při vyzvánění',
		'změnit nastavení uvítací melodie',
		'změnit nastavení vyzváněcí hudby',
		'změnit nastavení vyzváněcí melodie',
		'zvolit jinou hudbu při vyzvánění',
		'zvolit jinou uvítací melodii',
		'zvolit jinou vyznávěcí melodii',
		'zvolit jiný uvítací tón',
	],
	VOLANA_CISLA_CALLED: [
		'Chtěl bych zjistit, kam jsem volal minulý měsíc',
		'Kam jsem volal?',
		'Volaná čísla',
		'chci vědět kam jsem volal',
		'chci zjistit komu jsem naposledy volal',
		'komu jsem volal',
		'můžete mi poslat kam všude jsem volal?',
		'nedávno volaná čísla',
	],
	VOLANA_CISLA_CALL_PRICE: [
		'a zjistit kolik stál kolik stálo volání zadané číslo',
		'kolik jsem platila za hovor na uvedené číslo',
		'kolik stál hovor na číslo 738 948 943',
		'za kolik byl hovor na číslo 7 7 4 9 4 9 0 4 0',
	],
	VOLANA_CISLA_LAST_CALL_PRICE: [
		'jaká byla cena za poslední hovor',
		'kolik stál poslední hovor',
		'potřebuju vědět cenu posledního hovoru',
		'zjistíte, kolik stál můj poslední hovor',
		'řeknete mi prosím kolik mě stál poslední hovor',
	],
	VOLANA_CISLA_LIST: [
		'Chci seznam posledních čísel',
		'Chtěl bych vás požádat o výpis hovorů za uplynulý tři týdny',
		'Seznam volaných čísel',
		'Výpis volaných čísel',
		'Výpis čísel',
		'Ztratil jsem telefon, potřebuju seznam čísel na které jsem volal',
		'a potřebuju najít číslo na které jsem volala kolik a kolikrát',
		'potřebuji ke svému číslu výpis příchozích hovorů',
		'potřebuji poslat seznam volaných čísel',
		'potřebuji přehled kolikrát jsem kam volala',
		'potřebuji s zjitit, kam jsem volala a kolikrát',
		'potřebuji výpis kolikrát jsem volala na jaké číslo',
		'potřebuju najít čísla na které jsem volala a kolikrát',
		'pošlete mi výpis volaných čísel',
		'výpis odchozích hovorů',
		'výpis příchozích hovorů',
	],
	VRACENRETURN: [
		'chci vrátit zařízení',
		'chci řešit vrácení',
		'potřebuju vrátit zařízení',
		'vrácení',
		'vrácení zařízení',
		'vrátit zařízení',
	],
	VYMAHANCHECK: [
		'chci ověřit, jestli už mám všechno zaplacené',
		'chci vědět, jestli mám zaplaceno',
		'chci vědět, zda mám zaplaceno',
		'dlužnou částku za březen mám zaplaceno',
		'evidujete zaplacení dluhu?',
		'je březen penále za březen zaplacený?',
		'mám dluh za minulý měsíc zaplaceno',
		'mám zaplacen dluh',
		'mám zaplaceno dlužné za minulý měsíc?',
		'můžete, prosím, zkontrolovat, jestli mám zaplaceno?',
		'splatnost telefonu u jiného číslo',
		'ukazuje se vám, že mám zaplacený dluh',
		'už mám dluh zaplacený?',
		'za březen mám dlužnou částku zaplacenou',
		'zaplacená dlužná částka za březen',
		'zaplacený dluh za únor',
		'zkontrolujte prosím, jestli mám uhrazené dlužné',
	],
	VYMAHANCOMPLAINT: [
		'mám jinou částku pokuty',
		'mám vyměřenou neodpovídající pokutu',
		'neodpovídá výše pokuty',
		'pokutu jsem již zaplatil, a přesto ji stále vymáháte',
		'pokutu mám zaplacenou, ale stejně ji vymáháte dál',
		'stále po mě chcete pokutu, kterou jsem již platil',
		'vyměřená pokuta neodpovídá',
		'vyměřili jste mi moc vysokou pokutu',
		'vyměřili jste mi neoprávněně vysokou pokutu',
		'zablokovali jste mi služby, ale vše jsem hradil, proč?',
		'zaplatil jsem pokutu v plné výši, ale chcete ji znova',
	],
	VYMAHANGENERAL: [
		'Chci info o svém dluhu?',
		'Chci informace o mém dluhu?',
		'Chci se informovat o svém dluhu?',
		'Chci se zeptat jestli něco dlužím?',
		'Chci se zeptat jestli vám dlužím?',
		'asi vám dlužím, můžeme se na to podívat?',
		'chci se zeptat na útratu',
		'chctěl bych se zeptat na útratu',
		'dlužím vám něco?',
		'dlužím vám, můžeme se na to podívat?',
		'dlužím vám?',
		'dobrý den a nedoplatek',
		'dopis o vymáhání dluhu',
		'došla mi upomínka na dlužnou částku',
		'informace o dluhu',
		'jakou mám útratu',
		'jaká je moje útrata',
		'když bych něco dlužil',
		'mohu mít otázku na útratu',
		'mám na svém účtě dluh',
		'mám na účtě nějaký dluh',
		'mám u vás dluh',
		'mám u vás útratu',
		'nedoplatek',
		'nemáme zaplacenou fakturu přišlo mi že mě vypnete',
		'nezaplacené vyúčtování',
		'né zaplacené vyúčtování',
		'ověříte mi mojí útratu',
		'přišla mi upomínka a nevím o tom, že bych něco nezaplatil',
		'přišla mi včera od vás upomínka',
		'vymáhání dluhu',
		'vymáhání dlužné částky',
		'vymáhání nezaplacené faktury',
		'zajímalo by mě, jestli vám něco dlužím',
		'řešit nedoplatek',
	],
	VYMAHANHOW_MUCH: [
		'Chci vědět kolik dlužím',
		'Chtěl bych se zeptat, jaká je výše dlužné částky',
		'Jaká je dlužná částka',
		'Jaký mám dluh',
		'Kolik peněz dlužím',
		'Kolik peněz mám uhradit',
		'jakou dlužnou částku mám uhradit?',
		'jakou mám u vás dlužnou částku?',
		'jakou mám částku na faktuře?',
		'jakou částku mám uhradit?',
		'jaká je moje dlužná částka',
		'jaký je můj dluh u vás?',
		'kolik dlužím na faktuře?',
		'kolik dlužím?',
		'kolik mám dloužnou částku na faktuře?',
		'kolik mám dluh na faktuře?',
		'kolik mám uhradit',
		'kolik vám dlužím',
		'potřebu a zjistite a neuhlazené vyúčtování a',
	],
	VYMAHANHOW_SOLVE: [
		'Jak mám uhradit dluh?',
		'Jak u vás zaplatím všechny své dluhy',
		'Jak zaplatit dluh',
		'Jak zaplatit neuhrazenou fakturu',
		'Jak zaplatím svoje neuhrazené faktury',
		'Jak zaplatím všechny své pohledávky',
		'Mám dva dny po splatnosti. Když to teď zaplatím, vyhnu se ještě vymáhání?',
		'Mám u vás dluh a chci se vyhnout vymáhání',
		'Potřebuji informace, jak řešit dluh',
		'Přišla mi SMS, že dlužím nějakou částku, můžete mi s tím poradit?',
		'Přišla mi SMSka, že už mám po splatnosti',
		'Přišla mi od vás zprava, ze to mam uhradit, abych neplatil ten poplatek',
		'budu muset zaplatit nějaký vymáhací poplatek, když jsem fakturu zaplatila dva dny po splatnosti?',
		'došla mi sms že u vás mám dluh, co s tím můžu dělat?',
		'jak mám zaplatit svoje dluhy?',
		'jak mám řešit svůj dluh',
		'ještě jsem nezaplatila a už je po splatnosti, můžu to nějak napravit?',
		'minulý měsíc jsem zapomněla zaplatit fakturu, co s tím?',
		'mám doma neuhrazenou fakturu po splatnosti, co s tím?',
		'mám nedopatřením dvě neuhrazené faktury',
		'nechci platit poplatek za vymáhání, jak mám splatit svůj dluh?',
		'nezaplatil jsem poslední vyúčtování a nyní přišla nová faktura, můžu to zaplatit dohromady?',
		'potřebuju vyřešit svůj dluh u vás, jak na to?',
		'přišla mi sms, že vám něco dlužím, jak to mám vyřešit?',
		'volala mi vymáhací agentura a chci to nějak řešit',
	],
	VYMAHANPENALTY: [
		'dostanu pokutu za pozdější zaplacení dluhu?',
		'hrozí mi pokuta za dluh, už jste to zasílali',
		'jak je na tom moje pokuta za prodlení splátky?',
		'jak vysoká je ta pokuta za můj dluh',
		'jaká je pokuta za moji dlužnou částku?',
		'jaká je prosím pokuta za mojí dlužnou částku?',
		'kdy mi přijde pokuta za prodlení?',
		'kdy mi přijde pokuta, o který jste mi psali',
		'kdy můžu očekávat pokutu?',
		'měl bych dostat pokutu za pozdní zaplacení dluhu',
		'měl bych v nejbližší době dostat pokutu za prodlení splátek, v jakém je to stavu',
	],
	VYMAHANREMINDER: [
		'budu platit v termínu, nemusíte mi to připomínat',
		'budu platit včas nemusí mě urgovat stačí',
		'budu platit včas, nepřipomínejte mi to',
		'nemusíte mě upozorňovat, budu platit včas',
		'nemusíte mě urgovat, budu platit v termínu',
	],
	VYMAHANRESOLVE: [
		'chci uhradit dluh z upomínky',
		'chci vyřídit upomínku',
		'chci zaplatit, mám už upomínku',
		'chtěla bych vyřídit dluh',
		'mohla bych uhradit dluh',
		'neuplacenou upomínkou',
		'nezaplacená upomínka',
		'potřebuju vyřešit upomínku',
		'potřebuju vyřídit upomínku',
		'potřebuju zaplatit upomínku',
	],
	VYUCTOVANBILL: [
		'aktuální faktura za internet',
		'chci řešit účty za internetu',
		'faktura k internetu',
		'faktura za internet',
		'faktura za internetové přípojení',
		'fakturace k internetu',
		'internetové vyúčování',
		'měsíční vyúčtování za připojení k netu',
		'platby za internet',
		'platby za internetové připojení',
		'poslední faktura za internetu',
		'potřebuju fakturu k internetu',
		'vyúčtování internetu',
		'vyúčtování za internet',
		'zajímá mě vyfakturování intertentu',
		'účet za internet',
		'účet za připojení k internetu',
	],
	VYUCTOVANBILLING: [
		'chci s vámi mluvit o splácení vyúčtování, které vám dlužím',
		'chci se bavit o splátkách dlužných vyúčtování',
		'o splátkách dlužných vyúčtování',
		'potřebuju poradit se splacením dlužného vyúčtování',
		'potřebuju poradit se splácením dlužného vyúčtování',
		'potřebuju poradit se splácením vyúčtování, co vám dlužím',
		'splácení dlužného vyúčtování',
	],
	VYUCTOVANDLUH: [
		'co mi to přišlo za sms, že mám dluh?',
		'dluh je moc vysoký, bylo to míň',
		'jaké mám ještě neuhrazené částky, protože mám zablokovaný telefon',
		'kolik zbýva ješte uhradiť?',
		'mám u vás dluh?',
		'máme u vás neuhrazené částky?',
		'neuhrazené částky',
		'prišla mi správa prej máte v správě muj dluh, ale dyť já nemám žádný dluh',
	],
	VYUCTOVANEXEKUCE_SPLATKY: [
		'chtěla bych si dohodnout splátky, protože mám u vás exekuci',
		'exekuce, ale já bych radši splátky',
		'mám od vás exekuci, nebyl by možný splátkový kalendár',
		'mám u vás nedoplatky, a nechci skončit v exekuci, není možné si dohodnout splátky,',
	],
	VYUCTOVANMORE_THAN_USUAL: [
		'a zajímá mě to nový vyúčtování proč tam mám zvýšený',
		'chci řešit příliš vysokou částku na faktuře',
		'chci řešit vysokou částku ve vyučtování',
		'chci řešit částku na faktuře',
		'chci řešit částku na vyúčtování',
		'co mi to přišlo za vysokou částku',
		'co mám navíc ve faktuře',
		'co mám navíc ve vyúčtování',
		'Domluvili jsme si částku 500Kč a já dostal vyšší.',
		'faktura je moc vysoká',
		'jakto, že mám vyšší platby',
		'já bych chtěla proč se mi zvýšily platby',
		'mam na vyúčtovaní nějak moc',
		'mám na faktuře moc vysokou částku',
		'Mám vysokou fakturu a nevím proč',
		'mám vysokou fakturu, platil jsem vždycky méně než nyní',
		'Mám vysoké vyúčtování',
		'mám vysoký účet',
		'Mám větší fakturu než obvykle, čím to?',
		'Na čísle pod moji smlouvou je syn a ten má fakturu na 5000 Kč. Jak to?',
		'Nerozumím tomu, jak to že mám tak vysokou fakturu.',
		'nevím, proč platím tak hodně',
		'Nevíte prosím, čím to bude, že ta faktura je tak vysoká',
		'platím víc než každý ostatní měsíc',
		'Poslední faktura je obrovská.',
		'proč je tak vysoký účet',
		'proč jste mi zvýšili platbu',
		'proč mám na novém vyúčtování tak zvýšenou částku',
		'proč mám teď platit víc, když v srpnu jsem platila o stovku míň',
		'proč mám vyšší částku k zaplacení',
		'Proč platím víc',
		'přišla mi domů faktura s částkou, kterou jsem neočekával.',
		'Přišla mi faktura vyšší než minulý měsíc',
		'přišla mi hrozne vysoka faktura, chci si to dat do splátek',
		'přišla mi k úhradě nějaká velká částka',
		'vysoká faktura',
		'vysoká platba',
		'vyúčtování je moc vysoké',
		'zvýšená částka na vyúčtování',
		'účtujete mi víc než obvykle',
	],
	VYUCTOVANSTEZNOST: [
		'chci reklamovat vyúčtování',
		'chci vysvětlit vyúčtování',
		'dobrý den normálně platím tisíce 100 korun za paušál a teďkon mě přišla 1008 kč',
		'moc mi nesedí vyúčtování za tarif',
		'mohli bychom znát jednotlivé položky vyúčtování, faktura neodpovídá',
		'nechci platit svoje poslední vyúčtování, s částkou nesouhlasím.',
		'Nelíbí se mi částka na poslední faktuře.',
		'nesedí mi ceny v posledním vyúčtovaní',
		'nesouhlasí mi vyúčtování',
		'Nesouhlasím s tou částkou kterou jste mi poslali',
		'nesouhlasím s vyúčtováním tarifu',
		'no já mám fakturu 475 a teď mě vyšla na 557 můžete mi to vysvětlit',
		'potřeboval bych vyreklamovat zúčtování',
		'potřebuju pomoc s vyúčtováním je špatne',
		'potřebuju pomoc se špatným vyúčtováním',
		'potřebuju vysvětlit vyúčtování telefonního čísla',
		'potřebuju řešit částky účtované na čísle má dcery',
		'prosím potřebuji si ověřit fakturu za telefon a internet nesouhlasím ičo ta',
		'přišla mi faktura a nelíbí se mi její částka',
		'reklamace vyúčtování',
		'reklamace zúčtování',
		'reklamuji fakturu, neodpovídá',
		'Si děláte srandu s tou minulou fakturou. Co je to za částku?',
		'vyúčtování reklamace',
		'zase těch 75 korun',
		'špatné vyúčtování faktury',
		'špatně to vyúčtovali už 2. měsíc',
	],
	VYUCTOVANM_PLATBY_KONTROLA: ['Kontrola M-Plateb.', 'Kontrola aktivních M-Plateb.', 'aktivní M-Platba'],
	VYUCTOVANNOT_RECEIVED: [
		'a ne obdržené vyúčtování',
		'Chodí mi faktura na správný email?',
		'dobrý den nechodí mně vyúčtování',
		'faktura mi nebyla doručena',
		'Jak to, že ještě nemám novou fakturu za paušál?',
		'Je nějaký problém s fakturou? Ještě mi nepřišla?',
		'nechodí mi do emailu vyúčtování, vlastně nikam',
		'Nechodí mi vyúčtování',
		'nechodí mě do aplikace do emailu ani nikam vyúčtování',
		'nedorazila mi poslední faktura',
		'Nedorazilo mi od vás ještě vyúčtování za minulý měsíc',
		'nedoručená faktura',
		'Nedoručené vyúčtování',
		'nedoručili jste mi fakturu',
		'Nedostal jsem od vás ještě fakturu. Je nějaký problém?',
		'Nedostal jsem od vás ještě vyúčtování za mobil a nechci platit upomínku. Můžete mi ho teda poslat?',
		'nedošla faktura. Podíváte se kdy dojde?',
		'nedošlo mi vyúčtování',
		'nedošlo mi vyúčtování, faktura',
		'nedošlo vyúčtování paušálu',
		'Nemám vyúčtování',
		'neobdržela jsem fakturu',
		'neobdržela jsem stále složenku',
		'neobdržené vyúčtování',
		'Nepřišla mi faktura',
		'Nepřišlo mi vyúčtování na email',
		'Nepřišlo mi vyúčtování, žádnou formou',
		'Nepřišlo vyúčtování',
		'nevidím fakturu v emailu',
		'Nevím kolik mám za tenhle měsíc platit, ještě mi nepřišlo vyúčtování.',
		'nikde od vás nemám vyúčtování',
		'Pořád mi ještě nepřišla faktura za paušál. Můžete mi říct co se děje?',
		'pořád mi nedošla faktura',
		'Pošťačka mi ještě nepřinesla fakturu od vás za tenhle měsíc. Můžete mi říct, co se děje?',
		'Proč mi ještě od vás nepřišla faktura?',
		'rád bych zaplatil, ale nedorazila mi od vás faktura',
		'stále mi nepřišel výpis',
		'Tenhle měsíc mi ještě nepřišlo vyúčtování, můžete mi ho poslat?',
		'Zpozdilo se mi od vás vyúčtování, můžu o něj poprosit',
	],
	VYUCTOVANPOHLEDAVKY: [
		'Já bych měl dotaz ohledně pohledávek?',
		'Já bych měl dotaz, můžu sa obrátit na vás ohledně pohledávek?',
		'jestli se můžu obrátit na vás ohledně pohledávek?',
		'pohledávka',
	],
	VYUCTOVANPOSUN_PLATBY: [
		'Potřebovala bych posunout platbu',
		'chtěla bych si odložit platbu',
		'odsunout platbu',
		'posunout platbu',
		'přenést platbu',
	],
	VYUCTOVANSPATNE_ODOSLANE_PENIZE: [
		'Zřejmě v telefonu jsem na něco špatně kliknul a odeslal peníze, co sme nechtěl.',
		'kliknul jsem na něco a odešli mi z telefonu peníze, víte mi s tím něco udelat',
		'pomožte mi, nechtíc jsem odeslal peníze z telefonu',
		'z jednoho telefonu mi odešly peníze, který jsem určitě posílat nechtěl.',
		'špatně jsem odeslal peníze z telefonu, to jsem nechtěl',
	],
	VYUCTOVANUNBLOCK_AFTER_PAY: [
		'chci znova zapnout služby, když už jsem zaplatil',
		'já už jsem volal před hodinou a půl já už to mám zaplacený a paní posílala e-mail už aby mě odblokovali telefon.',
		'já už jsem vám posílal platbu a chci odblokovat telefon.',
		'já už mám zaplacený a chci už odblokovat telefon.',
		'kdy mi odblokujete služby? Já to už zaplatil',
		'nedoplatky už jsem zaplatil, tak mi to už odblokujte',
		'odblokujte mi ten telefon, já to už zaplatil',
		'vy jste mě vypli, ale já to už zaplatil, tak to chci odblokovat',
		'zapněte mě ty služby, ja už zaplatil',
	],
	VYUCTOVANZAKAZNIK_UZ_ZAPLATIL: [
		'Dneska jsem platil ten mobil, ale nevím jestli vám to došlo. Stále mi to hlásí nezaplaceno.',
		'dnes jsem platil ale svíti mi to jako nezaplaceno',
		'já to zaplatil a vy píšete, že to ještě nedošlo',
		'já už poslal platbu ale pořád to je jako nezaplacený',
		'píšete, že vám nepříšla platba, ale já jsem vám ji posílal.',
	],
	VYUCTOVANZRUSENE_CISLO: [
		'Mě přišlo vyúčtování na telefonní číslo, který už jsem v září zrušil.',
		'Na číslo který jsem už zrušil mám fakturu. Jak to?',
		'jak to, že mi posíláte faktury na číslo, který jsem už zrušil',
		'mám vyúčtování na telefonní číslo, který bylo zrušeno',
		'no já mám fakturu na zrušený číslo, můžete mi to vysvětlit?',
	],
	WAIT_GENERAL: [
		'bude mi to chvíli trvat',
		'chvilinku počkejte, než to ověřím',
		'dejte mi chviličku',
		'Dejte mi minutku',
		'Dejte mi minutku, jdu se podívat.',
		'dělám na tom moment',
		'hledám, vydržte',
		'Hned jsem zpátky',
		'Hned to bude',
		'Jdu se podívat, budu hned zpět',
		'jdu tam',
		'jdu to zkontrolovat, hned to bude',
		'ještě chviličku, musím se podívat',
		'ještě ne',
		'ještě potřebuji chvilku',
		'ještě se načítá',
		'ještě to není',
		'kouknu na to',
		'minutku',
		'moment',
		'moment, kouknu na to',
		'musíte chvíli počkat',
		'musíte počkat, jdu to zkontrolovat',
		'Nespěchejte na mě tak',
		'no a počkej',
		'potřebuji více času',
		'počkejte chvilku',
		'počkejte chvíli',
		'Počkejte',
		'Počkejte, nesmíte tak spěchat',
		'pracuji na tom, počkejte',
		'strpení prosím',
		'strpení',
		'stále nabíhá',
		'vydržte, musím se podívat',
		'zatim nabíhá',
	],
	WAIT_STILL_WAITING: ['dále čekáme', 'eště čekám', 'ještě budu chvíli čekat', 'ještě stále čekám', 'ještě čekám'],
	WARRANTY_SERVICE: [
		'chci garančku',
		'chci garanční prohlídku',
		'garančku potřebuji',
		'garanční prohlídka',
		'mohu k vám na garančku',
		'mohu přijet na garanční prohlídku',
		'objednat garančku',
		'objednat na garančku',
		'objednat se na garanční prohlídku',
		'potřeba garanční prohlídky',
		'potřebuju garančku',
		'přijet na garanční prohlídku',
	],
	WHEN: [
		'jak dlouho bude trvat, než si budu moct odvézt opel',
		'jak dlouho budu čekat na škodu',
		'jak dlouho ještě bude trvat dodání užitkového vozu volkswagen',
		'jak dlouho ještě bude trvat předání vozu',
		'jak dlouho se čeká na vozidlo',
		'jak dlouho trvá dodání auta',
		'jak je dlouhé dodání aut značky volvo',
		'kdy mi dodáte kiu',
		'kdy mohu dostat renaulta',
		'kdy můžete auto dodat',
		'kdy pro mě bude auto připravené',
		'kdy si budu moct přijet pro auto',
		'kdy si budu moct vyzvednou volkswagen',
		'kdy si mohu vyzvednout zakoupený automobil',
		'kolik měsíců trvá dodání automobilu',
		'za jak dlouho mi dodáte vozidlo',
		'za jak dlouho mi můžete dodat dacii',
	],
	WHEN_NEW_CAR: [
		'jak dlouho bude trvat, než si budu moct odvézt nový opel',
		'jak dlouho budu čekat na novou škodu',
		'jak dlouho ještě bude trvat dodání nového užitkového vozu volkswagen',
		'jak dlouho ještě bude trvat předání nového vozu',
		'jak dlouho se čeká na nové vozidlo',
		'jak dlouho trvá dodání nového auta',
		'jak je dlouhé dodání nových aut značky volvo',
		'kdy mi dodáte novou kiu',
		'kdy mohu dostat nového renaulta',
		'kdy můžete nové auto dodat',
		'kdy pro mě bude nové auto připravené',
		'kdy si budu moct přijet pro nové auto',
		'kdy si budu moct vyzvednou nový volkswagen',
		'kdy si mohu vyzvednout nový zakoupený automobil',
		'kolik měsíců trvá nové dodání automobilu',
		'za jak dlouho mi dodáte nové vozidlo',
		'za jak dlouho mi můžete dodat novou dacii',
	],
	WHEN_USED_CAR: [
		'Kdy si mohu vyzvednout zakoupený použitý automobil',
		'Za jak dlouho mi dodáte ojeté vozidlo',
		'jak dlouho bude trvat, než si budu moct odvézt opela z druhé ruky',
		'jak dlouho budu čekat na použitou škodu',
		'jak dlouho ještě bude trvat dodání užitkového vozu volkswagen z druhé ruky',
		'jak dlouho ještě bude trvat předání ojetého vozu',
		'jak dlouho se čeká na použité vozidlo',
		'jak dlouho trvá dodání ojetého auta',
		'jak je dlouhé dodání ojetých aut značky volvo',
		'kdy mi dodáte ojetou kiu',
		'kdy mohu dostat zánovního renaulta',
		'kdy můžete auto dodat ojeté auto',
		'kdy pro mě bude ojeté auto připravené',
		'kdy si budu moct přijet pro použité auto',
		'kdy si budu moct vyzvednou zánovní volkswagen',
		'kolik měsíců trvá dodání zánovního automobilu',
		'za jak dlouho mi můžete dodat použitou dacii',
	],
	WIFSETTING_WIFI: [
		'Nakonfigurovat wifi',
		'Nastavit wifi',
		'Rozběhnout nastavení wifi',
		'chci nastavit wifi',
		'chtěl bych nakonfigurovat wifi',
		'chtěl bych nastavit vifinu',
		'chtěla bych pomoct s nastavením wifiny',
		'dostat se přes konfiguraci wifi',
		'heslo k nastavení wifi',
		'potřeboval bych radu ohledně nastavení vifiny',
		'potřebovali bychom poradit, jak nastavit wifinu',
		'potřebuji nastavit wifi',
		'potřebuju nějak nastavit wifi',
		'potřebuju pomoct s nastavením vifiny',
	],
	WIFI: [
		'bezdrátový internet přes wifi',
		'bezdrátový internet',
		'blne wifina',
		'internet přes wifi',
		'internet z wifiny',
		'nefunguje wifina',
		'nejede wifina',
		'pokažená wifi',
		'Používám bezdrátovou síť',
		'Používám jen wifi',
		'problém s wifi bezdrátovým',
		'problémová wifina',
		'přestala jet wifina',
		'připojení přes wifi',
		's bezdrátovou sítí',
		's wifi sítí',
		's wifi',
		's wifinou',
		'wifi pro firmu',
		'wifi vůbec nefunguje, nedostanu se na net',
		'Wifi',
	],
	YES: [
		'ano díky',
		'ano děkuji',
		'ano děkuju',
		'ano je to tak',
		'ano je',
		'ano mám',
		'ano máte',
		'ano no no no',
		'ano prosím',
		'ano sedí',
		'ano',
		'ano, je',
		'ano, může být',
		'ano, na tom se můžeme domluvit',
		'Ano, rozhodně',
		'ano, to mě zajímá',
		'dobrá',
		'dobře',
		'děkuji ano',
		'děkuju ano',
		'Jasnačka',
		'jasný',
		'jasně že ano',
		'jasně že jo',
		'jasně',
		'jasně, že chci',
		'jasně, že mám',
		'jasňačka',
		'jj, toto by mě zajímalo',
		'jo díky',
		'jo děkuji',
		'jo jo',
		'jo jo, tak je to správně',
		'jo mám',
		'jo',
		'jo, potvrzuji',
		'Jo, přesně tak',
		'jo, rozhodně je to tak',
		'jo, to by mohlo být',
		'ok jasný',
		'ok',
		'oukej',
		'potvrzuji',
		'potvrzuji, že je to tak',
		'prosím',
		'přesně tak',
		'přesně',
		'rozhodně ano',
		'rozhodně s vámi souhlasím',
		'rozhodně souhlas',
		'Rozhodně',
		's tím souhlasím',
		'samozřejmě že ano',
		'samozřejmě že jo',
		'samozřejmě',
		'samozřejmě, že chceme',
		'sedí',
		'souhlas',
		'souhlasí',
		'souhlasím',
		'správně',
		'super',
		'tak jo',
		'tak tak, ano',
		'to je ono',
		'to je pravda',
		'to je správně',
		'to je v pořádku',
		'to si piš',
		'určitě ano',
		'určitě',
	],
	YES_ALSO: ['ano také', 'taktéž', 'taky', 'také'],
	YES_CONFIRM: [
		'a no já potřebuju ji nahlásit poruchu internetu',
		'no mám problém s internetem',
		'no, já mám poruchu připojení',
		'potřebuju nahlásit poruchu',
		'potřebuju řešit poruchu připojení',
		'potřebuju řešit připojení',
	],
	YES_CONFIRM_ADDRESS: [
		'adresa je dobře',
		'adresa je správná',
		'adresa je správně',
		'adresa je v pořádku',
		'adresa odpovídá',
		'adresa sedí',
		'adresa souhlasí',
		'ano adresa souhlasí',
		'ano, adresa sedí',
		'ano, máte správnou adresu',
		'ano, na této adrese',
		'hm, jo, tuto adresu chci řešit',
		'je to správná adresa',
		'máte správnou adresu',
		'správná adresa',
	],
	CONTINUE: [
		'Můžeme jít dál',
		'Můžeme pokračovat dále.',
		'Pojďme dál.',
		'Pojďme k dalšímu kroku.',
		'Pokračujme',
		'jedeme dál',
		'můžeme',
		'můžeme se pohnout dále',
		'pokračujte',
	],
	YES_IM_HERE: [
		'Ano jsem',
		'Ano jsem tam',
		'Ano tam',
		'Ano, jsem tady',
		'Ano, tady',
		'Ano, tu',
		'Jsem tady',
		'ano, jsem doma',
		'ano, jsem v kanceláři',
		'ano, právě jsem dorazil domů',
		'jasně, doma',
		'jo, dorazil jsem na místo',
		'jsem',
		'jsem na místě',
		'jsem tu',
		'já jsem na místě',
		'já sem na místě',
		'sem',
		'su tu',
	],
	YES_INTERNET: [
		'ano, internet pořád nefunguje',
		'ano, internet pořád svítí červeně',
		'ano, net pořád nejede',
		'ano, potřebuji internet',
		'ano, potřebuji opravit připojení k internetu',
		'ano, potřebuji pomoc s tím internetem',
		'ano, potřebuji připravit internetové připojení',
		'ano, potřebuji vyřešit okamžitě internet',
		'ano, potřebuji vyřešit problém s internetem',
		'ano, pořád nám internet nejde',
		'ano, pořád nám nejde',
		'ano, pořád nám nejde připojení',
		'ano, pořád nám to nejde',
		'ano, s tím internetem',
		'ano, s tím že nám nejde internet',
		'ano, s tím, že nám to pořád nejde',
		'ano, to připojení',
	],
	YES_INTERNET_OK: [
		'internet je v pořádku',
		'internet se rozjel díky',
		'internet se už rozjel',
		'internet už funguje',
		'internet už jede',
	],
	YES_INTERNET_STARTED_WORKING: [
		'ano funguje',
		'funguje',
		'internet se rozjel',
		'internet vypadá v pořádku',
		'jo internet je funkční',
		'už internet funguje',
		'vypadá to že internet běží',
	],
	YES_MAYBE: [
		'asi ano',
		'Asi jo',
		'možná ano',
		'Myslím si, že jo',
		'myslím že ano',
		'myslím že jo',
		'nedokážu posoudit, ale asi jo',
		'nejsem si úplně jistý, ale asi teda...',
		'Nejspíš jo',
		'pravděpodobně ano',
		'pravděpodobně jo',
		'Snad jo',
		'Snad',
		'spíš ano',
		'spíš jo',
		'Řekl bych, že asi ano',
	],
	YES_NEED: [
		'je potřeba aby ste mi pomohli',
		'potřeboval bych aby ste to řesili',
		'potřebuji pomoci co nejrychleji',
		'potřebuji to vyřešit co nejdříve',
		'potřebuju ještě aby jste si pohli',
		'potřebuju mluvit se servisním střediskem poruch',
		'potřebuju nutně internet pracovně nemůžu si dovolit ještě tři dny čekat',
		'potřebuju to okamžitě vyřešit',
		'potřebuju to rychle spravit',
	],
	YES_RESTART_DONE: [
		'Už jsem restartoval',
		'hotovo co dál?',
		'hotovo restartoval jsem',
		'hotovo už jsem do vyplá nahodila a zase zapla',
		'hotovo, už jsem to zrestartoval',
		'mám restartováno',
		'povedlo se mi to restartovat co dál?',
		'restart je cajk',
		'restart je hotový',
		'restartováno',
		'teď jsem restartoval',
		'už je to hotové',
		'už je to po restartu',
		'už se to restartovalo',
		'už to po restartu znovu naběhlo',
	],
	YES_SPEED_OK: [
		'ano, běží to svižně',
		'ano, je vidět zrychlení',
		'běží už plynule',
		'je tam zrychlení',
		'jede to rychle',
		'rychlost je v pořádku',
		'už je to svižné',
		'už to běží rychle',
		'už to fičí',
		'už to jde svižně',
		'už to naběhlo',
		'už to zrychlilo',
		'velké zrychlení',
		'vidím zrychlení',
	],
	YES_WANT: [
		'ano chci to řešit',
		'ano chci',
		'ano přeju',
		'ano tohle chci řešit',
		'ano, chci přesně tohle',
		'ano, chci',
		'ano, přeji si to',
		'ano, přeji si',
		'ano, přeju si doplnit další věci',
		'ano, přeju',
		'ano, to si přeju samozřejmě',
		'asi by to bylo fajn, tak tedy chci',
		'chci to tak',
		'chci',
		'jasně, že chcu',
		'jo, chci něco doplnit',
		'jo, chtěl bych',
		'přeji si',
		'přesně tohle chci řešit',
		'samozřejmě, že chci doplnit další informace',
		'samozřejmě, že chci',
	],
	ZAKAZNICKA_SMLOUVA_ADD: [
		'chtěla bych přidat k paušálu další telefonní číslo',
		'potřebuju přidat k mému paušálu nové číslo',
		'přidat další číslo',
		'přidat telefonní číslo ke svému paušálu',
		'přidáte mi k paušálu telefonní číslo',
	],
	ZAKAZNICKA_SMLOUVA_ADD_MEMBER: [
		'chci přidat dalšího člena smlouvy do programu škoda auto',
		'potřebuju dodat dalšího člena smlouvy do programu škoda auto',
		'potřebuju k programu škoda auto připojit dalšího člena smlouvy',
		'připojit účastníka smlouvy do projektu škoda auto',
		'ráda bych přidala dalšího účastníka smlouvy do projektu nebo do programu škoda auto',
	],
	SMLOUVA_PROLONGACE: [
		'Dnes mi přišla SMS, že mi skončil závazek na mém čísle, co to znamená?',
		'Jak dlouho jsem ještě u vás vázaný',
		'Jak si můžu prodloužit smlouvu na svém čísle?',
		'Kdy mi bude končit smlouva?',
		'Kdy mi končí smlouva',
		'Končí mi v dubnu smlouva, ale jinou nechci, co můžu udělat dál?',
		'Vypršela mi platnost mého tarifu, co se s tím dá teď dělat?',
		'bude mi končit tarif, co mám dělat?',
		'chci obnovit smlouvu',
		'chci prodloužit smlouvu',
		'chci prodloužit číslo se stejný tarifem',
		'chci si prodloužit smlouvu',
		'do kdy platí smlouva?',
		'jak dlouho mi ještě platí smlouva?',
		'kdy mi skončí závazek?',
		'končí mi smlouva',
		'končí mi smlouva a chci stejné podmínky',
		'končí mi smlouva, můžete mi ji obnovit',
		'mám smlouvu na dobu neurčitou?',
		'mám smlouvu na dobu určitou?',
		'mám u vás nějaký závazek ve smlouvě?',
		'můžu si prodloužit smouvu po telefonu',
		'potřebuju obnovit smlouvu',
		'Zařizoval jsem si přes vás prodloužení smlouvy a prej to mám potvrdit.',
		'chci jen prodloužit smlouvu',
		'potvrzení prodloužení smlouvy',
		'potřebuju prodloužit smlouvu',
		'prodloužení smlouvy',
		'prodloužení smlouvy, kterou mám nyní',
		'prodloužení stávající smlouvy',
		'prodloužit smlouvu',
		'prolongace smlouvy',
		'přišla mi SMS, že mám někde potvrdit prodloužení smlouvy.',
		'vy jste mi prodloužili smlouvu?',
		'prodloužení smlouvy na paušál',
		'skončila mě smlouva a chci obnovit',
		'synovi skončila smlouva, rád bychom ji prodloužit',
		'v aplikaci vidím že mi končí smlouva, jakou nabídku mi můžete nabídnout?',
		'zítra mi končí smlouva, můžete mi dát stejnou',
	],
	ZAKAZNICKA_SMLOUVA: [
		'chci se zeptat na zákaznickou smlouvu',
		'jak to máte se zákaznickou smlouvou?',
		'zajímaly by mě vaše zákaznické smlouvy',
		'Zákaznická smlouva na číslo',
		'Zákaznická smlouva',
	],
	SMLOUVA: [
		'a já potřebuju číslo smlouvy',
		'bývalou smlouvu',
		'Chci něco ohledně smlouvy',
		'Chci řešit smlouvu',
		'Chci řešit svojí smlouvu',
		'Chtěla bych se zeptat na smlouvu',
		'Chtěla bych se zeptat na svojí smlouvu',
		'jsem vázan smlouvou?',
		'mám otázku na smlouvu',
		'mám u vás smlouvu?',
		'Otázka ohledně smluvního závazku',
		'potřeboval bych řešit smlouvu.',
		'potřebovala bych pro vyřešit problém se smlouvou',
		'Potřebuju poradit se smlouvou',
		'Potřebuju poradit se svojí smlouvou',
		'smlouva',
		'smlouvy',
		'Smluvní vztah',
		'Tam je to vázané nějakou smlouvou viďte?',
		'Volám ohledně smlouvy',
	],
	ZAKAZNICKA_SMLOUVA_INFO: [
		'Informace k mé smlouvě',
		'Mám zadanou objednávku na přenos od O2. Jak to vypadá',
		'Stav smlouvy',
		'Uzavíral jsem s vámi novou smlouvu a zatím mi nepřišlo potvrzení. Co se děje?',
		'Uzavíral jsem si novou smlouvu a stále nepřišlo potvrzení o objednávce. co SE DĚJE?',
		'Získat informace o aktivní smlouvě',
		'a chtěl bych informace o zrušení anebo znovu aktivaci paušálu',
		'chci vědět, jaké tarify mám v aktuální smlouvě',
		'chtěla bych se informovat o aktuální změně smlouvy',
		'chtěla bych se zeptat kdy mi vyprší paušál',
		'co mám vůbec ve smlouvě?',
		'jak vypadá moje smlouva',
		'jaké je moje číslo smlouvy',
		'jaké jsou mé smluvní podmínky u vás',
		'jaké mám číslo smlouvy',
		'jaký je obsah mé smlouvy?',
		'já jsem se chtěla zeptat když budu psát telefon na firmu musím uzavírat smlouvu místě bydliště',
		'kde najdu svoji smlouvu',
		'kde najdu znění své smlouvy?',
		'kdy mi končí paušál',
		'kdy mi končí tarif?',
		'můžete mi poslat moji smlouvu?',
		'potřebuji znát číslo smlouvy mého telefonního tarifu',
		'potřebuju zjistit jestli mám výpověď',
		'potřebuju zjistit číslo mé smlouvy',
		'prosím o číslo smlouvy',
		'prosím zjisti číslo smlouvy',
		'přehled smlouvy',
		'rád bych zjistil, zdali už mám u vás aktivní paušál',
		'smlouvu čísla 7 7 7 8 3 4 9',
		'vědět jaká je smlouva',
		'účastnická smlouva 17 36 0 4 0 3',
	],
	ZAKAZNICKA_SMLOUVA_NEW_CONTRACT: [
		'Potřeboval bych novou smlouvu pro syna. Můžete mi poradit jak to mám udělat a co k tomu potřebuju?',
		'chci uzavřít smlouvu',
		'm ne není to tak potřebovala bych hovořit s někym ohledně smlouvy uzavření smlouvy',
		'můžu si uzavřít novou smlouvu',
		'nová smlouva',
		'potřebovala bych novou smlouvu na můj paušál',
		'potřebuju pomoct s uzavřením smlouvy',
		'ráda bych novou smlouvu',
	],
	ZAKAZNICKA_SMLOUVA_PENALTY: [
		'Jaké jsou sankce za ukončení smlouvy před jejím vypršením',
		'chci vyřešit finační vypořádání',
		'jakou částku zaplatím, pokud u vás skončím',
		'jaká je pokuta za vypovědění smlouvy?',
		'jaké bude finanční vypořádání, když od vás odejdu',
		'jaké jsou smluvní pokuty při porušení mé smlouvy?',
		'jsou nějaké sankce za vypovězení smlouvy',
		'jsou nějaké sankce, když změním operátora?',
		'když bych od vás odešel, jsou za to nějaké sankce?',
		'když bych změnila operátora, dostanu od vás pokutu?',
		'když od vás odejdu, dostanu smluvní pokutu?',
		'kolik budu platit, pokud od vás odejdu',
		'potřebuju se informovat ohledně finančního vypořádání',
		'přišel dopis finanční vypořádání',
		'zajímalo by mě, jestli dostanu pokutu, když změním operátora',
	],
	ZAKAZNICKA_SMLOUVA_SEND: [
		'dobrý den potřebovala bych poslat smlouvu na telefon',
		'mohli byste mi smlouvu zaslat do telefonu',
		'poslali byste mi smlouvu na telefon',
		'potřbovala bych smlouvu poslat do telefonu',
	],
	ZAKAZNICKA_SMLOUVA_SIGN: [
		'chci podepsat smlouvu',
		'podepsat smlouvu',
		'potřebuju podpis smlouvy',
		'potřebuju s vámi vyřešit podpis smlouvy',
	],
	ZARIZENAUTO: [
		'chci si objednat chytré auto',
		'chci řešit chytré auto',
		'chytré auto',
		'co dělá chytré auto',
		'co je to chytré auto',
		'informace o chytrém autu',
		'kolik stojí chytrý auto',
		'mám zájem o chytré auto',
		'pořídit chytré auto',
	],
	ZARIZENBATTERY: [
		'Telefon se mi nenabíjí, co s tím',
		'baterie se přestala nabíjet',
		'je na baterku do mobilu záruka',
		'moje mobilní baterie je rychle vybitá',
		'mám problém s baterkou v mobilu',
		'mám problém s nabíjením',
		'mám problém s nabíjením baterky',
		'máte záruku na baterie v mobilech?',
		'rychle se mi vybíjí baterie mobilu',
		'snížila se výdrž mojí baterky v mobilu',
		'telefon se nenabíjí',
	],
	ZARIZENBLUETOOTH: [
		'Jak se zapíná bluetooth',
		'Jak spáruju zařízení přes bluetooth',
		'jak nastavit bluetooth',
		'jak se na mobilu zapíná bluetooth',
		'jak spáruju počítač a mobil přes bluotooth',
		'k čemu je bluetooth',
		'můžete mi poradit s Bluetooth?',
		'potřebuji pomoct s bluetooth',
	],
	ZARIZENBUY: [
		'Chci koupit si iPhone',
		'Chci koupit telefon',
		'Chci telefon Samsung…',
		'Chci vědět, jaké nové zařízení si můžu u vás pořídit',
		'Chci zvýhodněný telefon',
		'Chtěl bych se zeptat na nabídku telefonů. Jaké nabízíte?',
		'Chtěl bych si pořídit Xiaomi od vás ke svému tarifu',
		'Chtěl bych si u vás pořídit Huawei',
		'Chtěl bych si u vás pořídit nový mobil',
		'Chtěl bych si u vás pořídit nový telefon',
		'Mám zájem si pořídit nové zařízení',
		'Máte telefon skladem?',
		'Měl bych zájem nový Galaxy',
		'Na jaké nové zařízení mám nárok',
		'Na které nové zařízení mám nárok',
		'Potřeboval bych nový telefon. Jsem zedník a potřebuju nějaký odolný, co mi vydrží.',
		'Zajímalo by mě, jaká nová zařízení teď máte v nabídce',
		'chci telefon za zvýhodněnou cenu',
		'dobrý potřeboval bych vyřešit nákup telefonu',
		'dá se koupit nabíječka',
		'dá se u vás koupit nové jablíčko?',
		'mám zájem o mobil',
		'mám zájem o telefon',
		'měl bych zájem o mobil',
		'měl bych zájem o telefon',
		'můžu někde koupit nabíječku na můj telefon, ztratil jsem ji',
		'můžu si někde koupit nová sluchátka',
		'nemůžu najít sluchátka od telefonu, potřebuju koupit nové',
		'novou nabíječku prosím',
		'potřebovala bych nový mobil od vás',
		'potřebovala bych nový telefon od vás',
		'rád bych si od vás koupil notebook, prodáváte nějaké?',
		'ztratil jsem k telefonu sluchátka, můžu si je někde koupit?',
		'ztratil jsem nabíječku na telefon, dá se koupit?',
		'ztratil jsem nabíječku, potřebuju novou',
		'ztratil jsem sluchátka od telefonu, dají se dokoupit?',
	],
	ZARIZENCHANGE_STB: [
		'chtěla bych vyměnit set top box',
		'potřebuji vyměnit settopbox na prodejně',
		'prosím o výměnu set top boxu na prodejně',
		'výměna settopboxu',
	],
	ZARIZENHANDSFREE: [
		'Kde si zapnu handsfree',
		'chci používat handsfree',
		'co je to handsfree',
		'jak na handsfree',
		'jak nastavím handsfree',
		'jak zapnout handsfree',
		'kde se zapíná handsfree',
		'má to handsfree',
	],
	ZARIZENMOBILE_DONT_WORK: [
		'mám pomalejší mobil',
		'můj telefon Huawei nefunguje, na koho se můžu obrátit?',
		'nefunguje mi telefon',
		'nejde mi telefon',
		'zablokoval jsem si mobilní telefon',
	],
	ZARIZENMOBILE_SETTING: [
		'Koupil jsem mobil, pomůžete mi s nastavením?',
		'Mám Huawei a pořád mi ukazuje, že mám hloubkově vyčistit paměť',
		'Mám nový telefon a neumím ho nastavit. Můžete mi pomoct?',
		'Nastavení telefonu',
		'Nejdou mi data v telefonu můžete mi poslat konfigurační zprávu',
		'chci si přenastavit telefon',
		'dá se někde získat návod k mobilu?',
		'jak dám telefon do továrního nastavení?',
		'jak přenastavím telefon',
		'jak si nastavím tmavý mód v telefonu',
		'jak si zapnout data v telefonu',
		'mobil',
		'mohla byste mi pomoc při novém telefonu s odesláním zpráv',
		'můžete mi poskytnout návod k telefonu',
		'můžu si někde stáhnout návod?',
		'nastavení zařízení',
		'pomůžete i s nastavením zařízení',
		'pomůžete mi nastavit nový telefon',
		'potřeboval bych telefon dát do továrního nastavení',
		'potřebuji konfigurační sms',
		'potřebuji konfigurační zprávu',
		'potřebuji poradit s telefonem',
		'potřebuji poslat sms kvůli konfiguraci',
		'potřebuji zprávu kvůli konfiguraci',
		'potřebuju hloubkově vyčistit telefon',
		'potřebuju nastavit moje zařízení',
		'potřebuju nastavit nový telefon',
		'potřebuju návod',
		'potřebuju přenastavit telefon',
		'pošlete mi konfigurační sms',
		'píše mi to hloubkové čištění paměti',
		'telefonem',
		'ztratil jsem návod k telefonu, ale chtěl bych ho do továrního nastavení',
		'ztratil jsem návod od telefonu mohu ho někde najít?',
	],
	ZARIZENMODEM: [
		'chci koupit modem',
		'kolik bude stát měsíční modem',
		'kolik stojí modem',
		'mám rozbitý modem',
		'můžu si koupit modem',
		'nefunguje mi modem',
		'potřebuju koupit nový modem',
		'prodáváte modemy',
		'prosím modem',
		'rozbil se mi modem',
	],
	ZARIZENNOTEBOOK: [
		'Chci novy notebook',
		'Mám zavirovaný počítač',
		'cena nového notebooku',
		'chtěl bych si vybrat notebook',
		'jaké máte notebooky',
		'jiný notebook',
		'mám zavirovaný notebook',
		'mám zájem o notebook',
		'výměna notbooku',
		'zajímají mě notebooky',
		'zajímám se o notebooky',
		'záruka na notebook',
	],
	ZARIZENOFFER: [
		'Chtěl bych nějakou nabídku telefonů od vás do 7 000 kč. Nabízíte nějaké?',
		'Jaká nová zařízení mi můžete nabídnout',
		'Jaké máte telefony Galaxy',
		'Máte telefon?',
		'Máte telefony se studentskou slevou',
		'Máte tlačítkové telefony',
		'Nabídka telefonu Huawei',
		'chtěla bych se zeptat jestli je v nabídce iphone s e v červené barvě',
		'dostal jsem nabídku nového mobilního telefonu',
		'nabízíte nové telefony?',
	],
	ZARIZENON_INSTALLMENT: [
		'chci telefon na splátky',
		'mně nabízí už mam v úvaze výhodný telefon',
		'na splátky telefon',
		'to víš aby na telefon na splátky',
	],
	ZARIZENORDER: [
		'chci objednat set to box',
		'objednat zařízení',
		'objednejte mi televizi',
		'objednání se to boxu',
		'potřebovala bych objednat telefon',
	],
	ZARIZENPICK_UP: [
		'bude už k vyzvednutí telefon na prodejně?',
		'chci se zeptat kdyz ti budu moct vyzvednout telefon na prodejně otcem frida',
		'dáte mi prosím vědět, kdy bude k vyzvednutí televize',
		'je už k vyzvednutí modem',
		'kdy bude na prodejně k vyzvednutí  televize',
		'kdy bude na prodejně přichystaný telefon k vyzvednutí',
	],
	ZARIZENPRICE: [
		'Jaké telefony máte do 5000 korun',
		'Kolik stojí telefon…',
		'Kolik u vás stojí Huawei',
		'Kolik u vás stojí Samsung',
		'Kolik u vás stojí Xiaomi',
		'Kolik u vás stojí android',
		'Kolik u vás stojí iPhone',
		'Za kolik máte u vás iPhony',
		'potřebuji se zeptat jestli máte v nabídce iPhone',
	],
	ZARIZENRESET: [
		'Potřebuji reset zařízení které je připojené k televizi',
		'chci zresetovat zařízení',
		'potřebuji zresetovat zařízení připojené k televizi',
		'prosím o reset zařízení připojené k mobilu',
		'prosím o zresetování zařízení',
		'reset zařízení',
		'reset zařízení u televize',
		'zresetujte mi zařízení od televize',
	],
	ZARIZENSMART_WATCH: [
		'Chci informace k smart Watch',
		'Chtěl bych k telefonu i smart watch',
		'Mám zájem o chytré hodinky, které nabízíte',
		'chci si pořídit hodinky',
		'chtěl bych applewatch',
		'chytrý náramek',
		'kolik stojí chytré hodinky',
		'máte nějaké chytré hodinky?',
		'nabídka hodinek',
		'nabízíte fitbit',
		'prodáváte chytré hodinky',
		'prodáváte chytré hodinky?',
	],
	ZARIZENSTB: [
		'chtěla bych zprovoznit set top box',
		'informaci o settopboxu aris 43 0 2',
		'jak zjistím puk k settopboxu',
		'jaké máte set-top boxy',
		'já bych potřebovala přenastavit settopbox děkuji',
		'koupil jsem si nový set top box a nevím jak ho nastavit',
		'koupit nový set-top box',
		'mám nový set-top-box, jak ho připojím?',
		'nastavení set top boxu',
		'nastavit set-top box',
		'potřebovala bysem pomalu pomoc s nastavením settopboxu děkuji',
		'potřebuji zjistit puk k set top boxu',
		'potřebuju kód k set top boxu',
		'potřebuju pomoct s nastavením set top boxu',
		'pořídit od vás set-top box',
		'puk settopboxu',
		'set-top-box chce po mně chce nějaký kód',
		'set-top-box chce zadat puk',
	],
	ZARIZENTABLET: [
		'Chci koupit tablet',
		'Mám nový tablet, nemůžu ho nastavit',
		'Nastavit tablet',
		'Nefunguje mi tablet',
		'Potřebuji nastavit tablet',
		'mám rozbitý tablet',
		'nejde mi tablet',
		'potřeboval bych tablet pro syna do školy',
		'tablet špatně ukazuje věci',
		'v tabletu se mi to špatně zobrazuje',
		'Nemůžu najít návod na svůj tablet',
		'Nevím jak nastavit do češtiny svůj tablet',
		'jak zprovoznit tablet',
		'můj tablet',
		'nastavení tabletu',
		'potřebuji navést, jak nastavit tablet',
		'tablet',
	],
	ZAZNAMOVE_SLUZBY_MISSEDCALL: [
		'Registr zmeškaných hovorů',
		'jak se dostanu k výpisů zmeškaných hovorům',
		'jak se dostanu ke zmeškaným hovorům',
		'je někde dostupný seznam zmeškaných hovorů?',
		'kde je dostupný registr zmeškaných hovorů?',
		'potřebuju se dostat do registru zmeškaných hovorů',
		'seznam zmeškaných hovorů',
		'zajímá mě registr zmeškaných hovorů',
	],
	ZAZNAMOVE_SLUZBY_TERMS: [
		'jak aktivovat zavolání zpátky?',
		'jak funguje služba zavolej zpět',
		'prosím sos',
		'sos',
		'zavolej zpět',
		'zavolání zpět',
	],
	ZAZNAMOVE_SLUZBY_VOICEMAIL: [
		'Chci hlasovku',
		'Chci se deaktivovat hlasovou schránku',
		'Hlasovka záznamník',
		'Hlasová schránka',
		'Jak se dostanu do své hlasové schránky',
		'Jak si mohu aktivovat hlasovou schránku',
		'Kde se dá změnit nastavení hlasové schránky',
		'Kde si můžu přehrát hovory ve své hlasové schránce',
		'MMS hlasovka',
		'MMS hlasový kurýr',
		'Můžete mi zrušit hlasovou schránku na mém čísle?',
		'Potřebuju deaktivovat svoji hlasovou schránku',
		'nepotřebuju hlasovku, zrušte mi ji',
	],
	ZKUSIM_NA_INFOLINCE_INFOLINK: [
		'dobrá, tak zákaznické centrum',
		'dobře, zákaznické centrum',
		'dáme to po telefonu',
		'infolinka',
		'přepojte mě na zákaznické centrum',
		'risknu tu infolinku',
		'tak to zákaznické centrum',
		'vyřešit v zákaznickém centru',
		'zkusím infolinku',
		'zkusím zákaznické centrum',
		'zvládneme to po lince',
		'zvládneme to po telefonu',
		'zákaznické centrum',
	],
	ZKUSIM_NA_INFOLINCE_TRY: [
		'risknu to',
		'tak já to zkusím',
		'tak to zkusíme společně',
		'tak to zkusíme spolu tady',
		'zkusím to',
		'zkusím to s vámi',
	],
	ZMENA_KONTAKTNICH_UDAJU_CHANGE: [
		'chci změnit moje kontaktní údaje',
		'kontaktních údajů',
		'potřebuju udělat změnu v kontaktních údajích',
		'potřebuju změnit kontaktní údaje',
		'změny kontaktních údajů',
	],
};

export default intents;
