import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, removeIntent } from '../../redux/model/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveIntentModal = (props) => {
	const dispatch = useDispatch();
	const { intentId } = props;

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.deleteIntentTitle} />}
			saveBtnTitle={<FormattedMessage {...messages.deleteIntentTitle} />}
			onSave={() => {
				dispatch(registerStateBeforeDispatchingAction(removeIntent(intentId)));
				dispatch(hideModal());
			}}
			isSaveBtnDisabled={false}
		>
			<FormattedMessage
				{...messages.deleteIntentContent}
				values={{ name: intentId, strong: (msg) => <strong>{msg}</strong> }}
			/>
		</SaveCloseModalTemplate>
	);
};

RemoveIntentModal.propTypes = {
	intentId: PropTypes.string,
};

export default RemoveIntentModal;
