import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { addIntent, registerStateBeforeDispatchingAction, updateIntent } from '../../redux/model/actions';
import { getIntentIds } from '../../redux/model/selectors';
import { normalizeNodeId as normalizeIntent } from '../../utils/normalization';
import { validateIsEmpty, validateIsYesNo } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const AddEditIntentModal = ({ id }) => {
	const [intentId, setIntentId] = useState(id);
	const [cursorPosition, setCursorPosition] = useState(0);
	const intentIds = useSelector(getIntentIds);
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	let errorMsg = validateIsEmpty(intentId) ?? validateIsYesNo(intentId) ?? '';
	if (!errorMsg && intentIds.filter((i) => i !== id).includes(intentId)) {
		errorMsg = <FormattedMessage {...messages.utteranceAlreadyExists} />;
	}

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.selectionStart = cursorPosition;
			inputRef.current.selectionEnd = cursorPosition;
		}
	}, [intentId]);

	const handleAdd = async () => {
		if (errorMsg) {
			return;
		}
		dispatch(registerStateBeforeDispatchingAction(id ? updateIntent(id, intentId) : addIntent(intentId)));
		dispatch(hideModal());
	};

	const text = id ? 'updateIntent' : 'addIntent';

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages[text]} />}
			saveBtnTitle={<FormattedMessage {...messages[text]} />}
			onSave={handleAdd}
		>
			<div>
				<TextField
					variant="standard"
					autoFocus
					value={intentId || ''}
					error={Boolean(errorMsg)}
					helperText={errorMsg}
					fullWidth
					inputRef={inputRef}
					onChange={(e) => {
						setCursorPosition(e.target.selectionStart);
						setIntentId(normalizeIntent(e.target.value));
					}}
					onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
					label={<FormattedMessage {...messages.intentName} />}
				/>
			</div>
		</SaveCloseModalTemplate>
	);
};

AddEditIntentModal.propTypes = {
	id: PropTypes.string,
};

export default AddEditIntentModal;
