import { Grid, Tab, Tabs } from '@mui/material';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { clone, pick, uniq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { showNotification } from '../../redux/notification/actions';
import {
	CALL_LIST_STATUS,
	CALL_STATUS,
	createCampaign,
	fetchCallStatuses,
	fetchCampaign,
	updateCampaign,
} from '../../redux/outbound/actions';
import { getCampaign, getDefaultCallStatuses } from '../../redux/outbound/selectors';
import {
	isPhoneNumberInvalid,
	isTrunkAccountInvalid,
	isTwilioFlowIdValid,
	validateIsEmpty,
} from '../../utils/validation';
import CampaignForm from '../forms/CampaignForm';
import CampaignMessagesForm from '../forms/CampaignMessagesForm';
import StatelessCheckbox from '../inputs/StatelessCheckbox';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

export const FIELD = {
	MODEL_ID: 'modelId',
	TWILIO_NUMBER: 'phoneNumberTwilio',
	TRUNK_ACCOUNT: 'trunkAccount',
	TWILIO_FLOW_ID: 'twilioFlowId',
	NAME: 'name',
	PAR_CALLS: 'maxParallelCalls',
	REP_CALL_DELAY: 'repeatCallDelayInMinutes',
	STATUS_NOTIF: 'callStatusNotifier',
	TRIALS: 'maxCallTrials',
	CALLS: 'phoneCalls',
	CALL_DATES: 'repeatCallsAt',
	CALL_STATUSES_TO_REPEAT: 'callStatusesToRepeat',
	MESSAGE_LIST: 'messageList',
	CAMPAIGN_TYPE: 'campaignType',
};

export const MESSAGE_FIELD = {
	SERVICE_PROVIDER: 'serviceProvider',
	REQUEST_KEY: 'requestKey',
	DEFAULT_MESSAGE: 'defaultMessage',
	STATUSES_TO_MESSAGE: 'statusesToMessage',
	CUSTOM_MESSAGES: 'customMessages',
};

export const validateCampaignField = (value, field) => {
	let error = null;

	switch (field) {
		case FIELD.MODEL_ID:
		case FIELD.PAR_CALLS:
		case FIELD.REP_CALL_DELAY:
		case FIELD.TRIALS:
			error = validateIsEmpty(value);
			break;
		case FIELD.TWILIO_NUMBER:
			error = validateIsEmpty(value) || isPhoneNumberInvalid(value);
			break;
		case FIELD.TRUNK_ACCOUNT:
			error = validateIsEmpty(value) || isTrunkAccountInvalid(value);
			break;
		case FIELD.TWILIO_FLOW_ID:
			error = validateIsEmpty(value) || isTwilioFlowIdValid(value);
			break;
		case FIELD.CAMPAIGN_TYPE:
			error = validateIsEmpty(value);
			break;
	}

	return error;
};

export const validateMessageListField = (value, field) => {
	let error = null;

	switch (field) {
		case MESSAGE_FIELD.SERVICE_PROVIDER:
			error = validateIsEmpty(value);
			break;
	}

	return error;
};

const CreateEditCopyCampaignModal = ({ campId, callback, operation = 'create', modelId: defaultModelId }) => {
	const dispatch = useDispatch();

	const intl = useIntl();

	const defaultCallStatuses = useSelector(getDefaultCallStatuses);
	const campaign = useSelector(getCampaign(campId));

	const [campaignState, setCampaignState] = useState({
		[FIELD.MODEL_ID]: typeof campaign === 'undefined' ? defaultModelId : campaign[FIELD.MODEL_ID],
		[FIELD.TWILIO_NUMBER]: typeof campaign === 'undefined' ? '' : campaign[FIELD.TWILIO_NUMBER],
		[FIELD.TRUNK_ACCOUNT]: typeof campaign === 'undefined' ? '' : campaign[FIELD.TRUNK_ACCOUNT],
		[FIELD.TWILIO_FLOW_ID]: typeof campaign === 'undefined' ? '' : campaign[FIELD.TWILIO_FLOW_ID],
		[FIELD.NAME]: typeof campaign === 'undefined' ? '' : campaign[FIELD.NAME],
		[FIELD.PAR_CALLS]: typeof campaign === 'undefined' ? 1 : campaign[FIELD.PAR_CALLS],
		[FIELD.REP_CALL_DELAY]: typeof campaign === 'undefined' ? 60 : campaign[FIELD.REP_CALL_DELAY],
		[FIELD.STATUS_NOTIF]: typeof campaign === 'undefined' ? null : campaign[FIELD.STATUS_NOTIF],
		[FIELD.TRIALS]: typeof campaign === 'undefined' ? 1 : campaign[FIELD.TRIALS],
		[FIELD.CALL_STATUSES_TO_REPEAT]: [CALL_STATUS.BUSY, CALL_STATUS.NO_ANSWER, CALL_STATUS.PAUSED, CALL_STATUS.FAILED],
		[FIELD.CALLS]: typeof campaign === 'undefined' ? [] : campaign[FIELD.CALLS],
		[FIELD.CALL_DATES]: typeof campaign === 'undefined' ? [] : campaign[FIELD.CALL_DATES],
		[FIELD.CAMPAIGN_TYPE]: typeof campaign === 'undefined' ? 'BATCH' : campaign[FIELD.CAMPAIGN_TYPE],
	});

	const [messageListState, setMessageListState] = useState({
		[MESSAGE_FIELD.SERVICE_PROVIDER]: '',
		[MESSAGE_FIELD.REQUEST_KEY]: '',
		[MESSAGE_FIELD.DEFAULT_MESSAGE]: '',
		[MESSAGE_FIELD.STATUSES_TO_MESSAGE]: [],
		[MESSAGE_FIELD.CUSTOM_MESSAGES]: {},
	});

	const [isLoading, setIsLoading] = useState(operation !== 'create');
	const [useMessages, setUseMessages] = useState(false);
	const [copyPhoneCalls, setCopyPhoneCalls] = useState(operation === 'copy');

	const [currentTab, setCurrentTab] = useState('calls');

	const isSaveBtnDisabled = operation === 'update' && campaign?.status !== CALL_LIST_STATUS.WAITING;

	const modalHeading = (() => {
		let text = 'createCampaign';
		if (isSaveBtnDisabled) {
			text = 'campROnly';
		} else if (operation === 'update') {
			text = 'updateCampaign';
		} else if (operation === 'copy') {
			text = 'copyCampaign';
		}

		return <FormattedMessage {...messages[text]} />;
	})();

	const callStatuses = uniq([...campaignState[FIELD.CALL_STATUSES_TO_REPEAT], ...defaultCallStatuses]);

	useEffect(() => {
		dispatch(fetchCallStatuses());

		if (operation !== 'create' && campId) {
			dispatch(fetchCampaign(campId))
				.then((data) => {
					const campaignOriginal = data.payload.data.callLists;
					setIsLoading(false);
					setUseMessages(campaignOriginal[FIELD.MESSAGE_LIST]);

					if (operation === 'copy') {
						campaignOriginal[FIELD.NAME] = intl.formatMessage(messages.copyOf) + ' ' + campaignOriginal[FIELD.NAME];
					}

					campaignOriginal[FIELD.CALLS] = campaignOriginal[FIELD.CALLS].map((call) =>
						pick(['metaData', 'phoneNumber'], call)
					);

					setCampaignState({
						...campaignState,
						...pick(Object.values(FIELD), campaignOriginal),
					});

					const messageList = {
						...messageListState,
						...pick(Object.values(MESSAGE_FIELD), campaignOriginal[FIELD.MESSAGE_LIST] || {}),
					};

					// DB casts {} as null;
					if (!messageList[MESSAGE_FIELD.CUSTOM_MESSAGES]) {
						messageList[MESSAGE_FIELD.CUSTOM_MESSAGES] = {};
					}

					setMessageListState(messageList);
				})
				.catch((err) => {
					console.error(err);
					Sentry.captureException(err);
					dispatch(showNotification(err, 'error'));
				});
		}
	}, [operation, campId]);

	const handleAdd = async () => {
		const stateClone = clone(campaignState);
		// Validation in the next step would require both Twilio and SIP to not be empty...
		if (stateClone[FIELD.TRUNK_ACCOUNT]) {
			Reflect.deleteProperty(stateClone, [FIELD.TWILIO_FLOW_ID]);
			Reflect.deleteProperty(stateClone, [FIELD.TWILIO_NUMBER]);
		} else {
			Reflect.deleteProperty(stateClone, [FIELD.TRUNK_ACCOUNT]);
		}

		for (const [field, value] of Object.entries(stateClone)) {
			const error = validateCampaignField(value, field);
			if (error) {
				return;
			}
		}

		if (operation === 'copy' && !copyPhoneCalls) {
			stateClone[FIELD.CALLS] = [];
		}

		if (useMessages) {
			const messageListStateClone = clone(messageListState);
			for (const [field, value] of Object.entries(messageListStateClone)) {
				const error = validateMessageListField(value, field);
				if (error) {
					return;
				}
			}

			// Clear any potential messages for unselected statuses
			messageListStateClone[MESSAGE_FIELD.CUSTOM_MESSAGES] = pick(
				messageListStateClone[MESSAGE_FIELD.STATUSES_TO_MESSAGE],
				messageListStateClone[MESSAGE_FIELD.CUSTOM_MESSAGES]
			);

			stateClone[FIELD.MESSAGE_LIST] = messageListStateClone;
		} else {
			stateClone[FIELD.MESSAGE_LIST] = null;
		}

		const res = await dispatch(
			operation === 'update' ? updateCampaign({ ...stateClone, id: campId, intl }) : createCampaign(stateClone)
		);

		if (callback) {
			callback(res.payload.data?.callLists?.id);
		}

		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			isSaveBtnDisabled={isSaveBtnDisabled}
			title={modalHeading}
			saveBtnTitle={modalHeading}
			onSave={handleAdd}
			disableBackdropClickDialogClose
			disableEscapeDialogClose
			maxWidth={'md'}
		>
			{!isLoading ? (
				<Grid container spacing={2} style={{ width: '100%' }}>
					<Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
						<Tab value="calls" label={<FormattedMessage {...messages.calls} />} />
						<Tab value="messages" disabled={!useMessages} label={<FormattedMessage {...messages.messages} />} />
					</Tabs>
					{currentTab === 'calls' ? (
						<>
							<Grid item xs={12}>
								<StatelessCheckbox
									value={useMessages}
									onChange={() => setUseMessages(!useMessages)}
									label={<FormattedMessage {...messages.useMessages} />}
								/>
							</Grid>
							{operation === 'copy' && (
								<Grid item xs={12}>
									<StatelessCheckbox
										value={copyPhoneCalls}
										onChange={() => setCopyPhoneCalls(!copyPhoneCalls)}
										label={<FormattedMessage {...messages.copyPhoneCalls} />}
									/>
								</Grid>
							)}
							<CampaignForm state={campaignState} setState={setCampaignState} handleAdd={handleAdd} />
						</>
					) : null}
					{currentTab === 'messages' ? (
						<CampaignMessagesForm state={messageListState} setState={setMessageListState} callStatuses={callStatuses} />
					) : null}
				</Grid>
			) : (
				<>
					<FormattedMessage {...messages.loadingContent} />
				</>
			)}
		</SaveCloseModalTemplate>
	);
};

CreateEditCopyCampaignModal.propTypes = {
	campId: PropTypes.string,
	modelId: PropTypes.string,
	callback: PropTypes.func,
	operation: PropTypes.oneOf(['create', 'update', 'copy']),
};

export default CreateEditCopyCampaignModal;
