import { Checkbox, FormControlLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const StatelessCheckbox = ({ value, onChange, label }) => {
	return (
		<FormControlLabel
			className="floatLeft mt15 approve-checkbox"
			control={<Checkbox checked={value} onChange={onChange} />}
			label={label}
		/>
	);
};

StatelessCheckbox.propTypes = {
	label: PropTypes.string,
	value: PropTypes.boolean,
	onChange: PropTypes.func,
};

export default StatelessCheckbox;
