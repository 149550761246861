import InfoIcon from '@mui/icons-material/Info';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';
import { intlHtml } from '../../utils/intlHtml';

const useStyles = makeStyles((theme) => ({
	wrap: {
		listStyleType: 'none',
		display: 'inline-block',
	},
	popper: {
		'& div': {
			padding: '15px',
			'& h1': {
				paddingTop: 0,
				marginTop: 0,
			},
			'& a': {
				background: theme.palette.primary.main,
				padding: '0 5px',
				borderRadius: '3px',
				color: 'white',
				textDecoration: 'none',
			},
		},
	},
	iconBtn: {
		padding: 4,
	},
	icon: {
		width: 18,
		height: 18,
		margin: 0,
		color: grey[600],
	},
}));

const InfoButton = ({ title, placement, ...rest }) => {
	const classes = useStyles();

	const [open, setOpen] = React.useState(false);

	const handleTooltipClose = () => setOpen(false);
	const handleTooltipOpen = () => setOpen(true);

	return (
		<div className={classes.wrap}>
			<ClickAwayListener onClickAway={handleTooltipClose}>
				<div>
					<Tooltip
						classes={{
							popper: classes.popper,
						}}
						arrow
						interactive
						onClose={handleTooltipClose}
						open={open}
						disableFocusListener
						disableHoverListener
						disableTouchListener
						placement={placement || 'top'}
						title={<FormattedMessage {...messages[title]} values={intlHtml} />}
					>
						<IconButton onClick={handleTooltipOpen} aria-label={title} className={classes.iconBtn} {...rest}>
							<InfoIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
				</div>
			</ClickAwayListener>
		</div>
	);
};

InfoButton.propTypes = {
	placement: PropTypes.string,
	title: PropTypes.string,
};

export default InfoButton;
