import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { createNodeGroup, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import NodeIdTextField from '../NodeIdTextField/NodeIdTextField';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const CreateNodeGroupModal = () => {
	const [inputConf, setInputConf] = useState({});
	const dispatch = useDispatch();

	const handleAdd = () => {
		if (inputConf.validation || isEmpty(inputConf)) {
			return;
		}

		dispatch(registerStateBeforeDispatchingAction(createNodeGroup(inputConf.nodeId)));
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.createNodeGroup} />}
				saveBtnTitle={<FormattedMessage {...messages.createNodeGroup} />}
				onSave={handleAdd}
			>
				<NodeIdTextField
					autoFocus
					nodeId=""
					onKeyPress={handleKeyPress}
					label={<FormattedMessage {...messages.groupName} />}
					handleChange={setInputConf}
				/>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default CreateNodeGroupModal;
