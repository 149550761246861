import { omit } from 'ramda';

// returns object cleansed of number key while keeping number order without gaps
const delObjNumKeyKeepSequence = (obj, key) => {
	key = Number(key);
	obj = omit([key], obj);

	while (obj[key + 1] !== undefined) {
		obj[key] = obj[key + 1];
		obj = omit([key + 1], obj);
		key++;
	}
	return obj;
};

export default delObjNumKeyKeepSequence;
