import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../../constants';
import { clearNotification } from '../../redux/notification/actions';
import { getNotification } from '../../redux/notification/selectors';
import SnackbarContentWrapper from './SnackbarContentWrapper';

const HIDE_TIMEOUT_MS = 5000;

const anchorOrigin = {
	horizontal: 'center',
	vertical: 'top',
};

const useStyles = makeStyles(() => ({
	scrollable: {
		zIndex: 1400,
		display: 'initial',
		overflowY: 'auto',
		maxHeight: '90vh',
		position: 'fixed',
	},
}));

const UINotification = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const { type, message, count } = useSelector(getNotification);

	const handleClose = () => dispatch(clearNotification());

	return (
		<Snackbar
			key={message}
			classes={{ root: styles.scrollable }}
			open={!!message}
			autoHideDuration={type !== notificationTypes.ERROR ? HIDE_TIMEOUT_MS : null}
			onClose={handleClose}
			anchorOrigin={anchorOrigin}
		>
			<SnackbarContentWrapper
				type={type}
				onClose={handleClose}
				message={count > 1 ? `${count}x: ${message}` : message}
			/>
		</Snackbar>
	);
};

UINotification.propTypes = {
	clearNotification: PropTypes.func,
	message: PropTypes.string,
	type: PropTypes.string,
};

export default UINotification;
