import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Tooltip } from '@mui/material';
import TextField from '@mui/material/TextField';
import JSZip from 'jszip';
import { omit } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { palette } from '../../mui-theme-builder';
import { hideModal } from '../../redux/modal/actions';
import { createModel, upsertModelAsset, uploadAudioFile } from '../../redux/model-table/actions';
import { importModelFromFile } from '../../redux/model/actions';
import { validateIsEmpty } from '../../utils/validation';
import Preloader from '../Preloader/Preloader';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const CreateNewModelModal = () => {
	const [errorTitle, setErrorTitle] = useState(null);
	const [errorDesc, setErrorDesc] = useState(null);
	const [modelName, setModelName] = useState('');
	const [modelDescription, setModelDescription] = useState('');
	const [isUploading, setIsUploading] = useState(false);
	const dispatch = useDispatch();
	// i'm not removing the validation mechanism for the case the desc will be required in the future
	const doNotValidateDesc = true;

	const handleAdd = async () => {
		setErrorTitle(validateIsEmpty(modelName));
		setErrorDesc(!doNotValidateDesc && validateIsEmpty(modelDescription));

		if (validateIsEmpty(modelName) || (!doNotValidateDesc && validateIsEmpty(modelDescription))) {
			return;
		}

		await dispatch(createModel(modelName, modelDescription));
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	const extractFileName = (nameWithFolder) => {
		return nameWithFolder.substring(nameWithFolder.lastIndexOf('/') + 1, nameWithFolder.length);
	};

	const extractFolderName = (nameWithFolder) => {
		return nameWithFolder.substring(0, nameWithFolder.lastIndexOf('/') + 1);
	};

	const handleProjectUpload = async (e) => {
		setIsUploading(true);
		setErrorTitle(validateIsEmpty(modelName));
		setErrorDesc(!doNotValidateDesc && validateIsEmpty(modelDescription));

		if (validateIsEmpty(modelName) || (!doNotValidateDesc && validateIsEmpty(modelDescription))) {
			return;
		}
		const newProject = await dispatch(createModel(modelName, modelDescription));
		let configFile = '';
		const configs = {};
		const assets = {};
		const assetPaths = {};
		// We now have the metadata of compressed files, not their contents
		const loadedZip = await JSZip.loadAsync(e.target.files[0]);

		for (const [filename, file] of Object.entries(loadedZip.files)) {
			// Just directories and subdirectories are considered files by JSZip
			if (!file.dir) {
				// Ugly but branching has to happen

				// This directory contains yaml config in both json and yaml form. We will need to replace ids inside before uploading it
				if (filename.startsWith('configs/') && filename.endsWith('.json')) {
					configFile = await loadedZip.file(filename).async('text');
				} else if (filename.endsWith('.json')) {
					// We found a config file of some asset. Map it to folder so that we don't rely on order of iteration
					configs[extractFolderName(filename)] = await loadedZip.file(filename).async('text');
				} else if (filename.endsWith('.wav')) {
					// Currently, only .wav files are supported while uploading assets
					// We have to manually convert it from compressed version
					const newFile = new File(
						[new Blob([file._data.compressedContent], { type: 'audio/wav' })],
						// Getting name of file without folder
						extractFileName(filename)
					);
					// Map the returned file config to folder name too for later id mapping
					assets[extractFolderName(filename)] = {
						type: 'FILE_AUDIO',
						name: extractFileName(filename),
						data: {
							fileName: extractFileName(filename),
							fileData: newFile,
						},
					};
				}
			}
		}
		const oldIds = [];
		const promises = [];
		Object.entries(configs).forEach(([key, config]) => {
			if (assets[key]) {
				const parsedValue = JSON.parse(config);
				oldIds.push(parsedValue.id);
				assetPaths[parsedValue.id] = parsedValue.data.path;
				promises.push(dispatch(uploadAudioFile(assets[key])));
			} else {
				const parsedValue = JSON.parse(config);

				switch (parsedValue.type) {
					case 'WIDGET_ANNOUNCEMENT':
						oldIds.push(parsedValue.id);
						// Announcements are just their config files
						promises.push(dispatch(upsertModelAsset(newProject.payload.result, omit(['id'], parsedValue))));
					default:
						break;
				}
			}
		});

		// Promise.all preserves the order of its elements despite their async nature
		const replacements = await Promise.all(promises);
		for (const [index, newValue] of replacements.entries()) {
			if (newValue.path) {
				configFile.replace(assetPaths[oldIds[index]], newValue.payload.data.path);
				configFile.replace(oldIds[index], newValue.payload.data.fileId);
			} else {
				configFile.replace(oldIds[index], newValue.payload.result);
			}
		}

		dispatch(importModelFromFile(configFile, 'json'));
		setIsUploading(false);
		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.newProject} />}
				saveBtnTitle={<FormattedMessage {...messages.newProject} />}
				onSave={handleAdd}
			>
				<div>
					<TextField
						autoFocus
						value={modelName}
						error={!!errorTitle}
						helperText={errorTitle}
						fullWidth
						onChange={(e) => {
							setModelName(e.target.value);
							setErrorTitle(validateIsEmpty(e.target.value));
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.projectName} />}
						data-testid="projectNameInput"
						variant="standard"
					/>
					<p />
					<TextField
						value={modelDescription}
						error={!!errorDesc}
						helperText={errorDesc}
						fullWidth
						onChange={(e) => {
							setModelDescription(e.target.value);
							setErrorDesc(!doNotValidateDesc && validateIsEmpty(e.target.value));
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.projectDescription} />}
						data-testid="projectDescInput"
						variant="standard"
					/>
					<p />
					<input
						accept=".zip"
						id="contained-button-file"
						type="file"
						style={{ display: 'none' }}
						onChange={(e) => {
							handleProjectUpload(e);
						}}
						disabled={!modelName}
					/>
					<label htmlFor="contained-button-file">
						<Tooltip title={<FormattedMessage {...messages.uploadProject} />}>
							<span>
								<Button
									variant="contained"
									style={{ background: palette.primary, height: '35px' }}
									component="span"
									disabled={!modelName}
								>
									{!isUploading && <CloudUploadIcon />}

									{isUploading && (
										<Preloader
											loaderWrapStyle={{
												background: palette.primary,
											}}
											loaderStyle={{
												height: '15px',
												width: '15px',
												border: '3px solid transparent',
												borderTop: '3px solid #fff',
												borderBottom: '3px solid #fff',
											}}
										/>
									)}
								</Button>
							</span>
						</Tooltip>
					</label>
					<p />
					<FormattedMessage {...messages.uploadProjectWarning} />
				</div>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default CreateNewModelModal;
