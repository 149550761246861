import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../intl';
import { isPhoneNumberInvalid } from '../utils/validation';
import EnhancedTextField from './EnhancedTextField';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 10,
		width: '90%',
	},
	table: {
		marginTop: '1rem',
		cursor: 'auto',
	},
}));

const Blacklist = ({ blacklist, onChange }) => {
	const classes = useStyles();
	const [phoneNumber, setPhoneNumber] = useState({});
	const areFieldsValid = phoneNumber?.isValid;

	const addPhoneNumberToBlacklist = (e) => {
		onChange(e, [...blacklist.filter((number) => number !== phoneNumber.value), phoneNumber.value]);
	};

	const deletePhoneNumberFromBlacklist = (e, phoneNum) => {
		onChange(
			e,
			blacklist.filter((number) => number !== phoneNum)
		);
	};

	return (
		<div className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<EnhancedTextField
						onValidate={setPhoneNumber}
						errorMsg={phoneNumber.errorMsg}
						validatePred={isPhoneNumberInvalid}
						label={<FormattedMessage {...messages.phoneNumber} />}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent={'flex-end'} spacing={1}>
				<Grid item>
					<Button
						onClick={addPhoneNumberToBlacklist}
						color={'primary'}
						disabled={!areFieldsValid}
						className="action-button"
					>
						<FormattedMessage {...messages.addToBlacklist} />
					</Button>
				</Grid>
			</Grid>
			{!!blacklist.length && (
				<Grid item xs={12} className={classes.table}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage {...messages.phoneNumber} />
								</TableCell>
								<TableCell align={'right'}>
									<FormattedMessage {...messages.delete} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{blacklist.map((number, index) => (
								<TableRow key={index}>
									<TableCell>{number}</TableCell>
									<TableCell align={'right'}>
										<Tooltip title={<FormattedMessage {...messages.delete} />}>
											<IconButton
												size={'small'}
												aria-label="delete"
												onClick={(e) => deletePhoneNumberFromBlacklist(e, number)}
											>
												<DeleteIcon fontSize={'small'} />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			)}
		</div>
	);
};

Blacklist.propTypes = {
	blacklist: PropTypes.array,
	onChange: PropTypes.func,
};

export default Blacklist;
