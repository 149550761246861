import { Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../intl';
import { fetchPhoneCallsForActiveModel } from '../redux/sip/actions';
import { getPhoneCalls } from '../redux/sip/selectors';
import { isEmptyValidation, isPhoneNumberInvalid } from '../utils/validation';
import EnhancedTextField from './EnhancedTextField';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 10,
	},
	table: {
		marginTop: '1rem',
		cursor: 'auto',
	},
}));

const Whitelist = ({ configHash, whitelist, onChange }) => {
	const classes = useStyles();
	const phoneCalls = useSelector(getPhoneCalls);
	const dispatch = useDispatch();
	const [phoneNumber, setPhoneNumber] = useState({});
	const [allowedCallCount, setAllowedCallCount] = useState({});
	const areFieldsValid = phoneNumber?.isValid && allowedCallCount?.isValid;

	useEffect(() => {
		dispatch(fetchPhoneCallsForActiveModel());
	}, []);

	const addPhoneNumberToWhitelist = (e) => {
		onChange(e, [
			...whitelist.filter((number) => number.phoneNumber !== phoneNumber.value),
			{
				phoneNumber: phoneNumber.value,
				allowedCallCount: Number(allowedCallCount.value),
			},
		]);
	};

	const deletePhoneNumberFromWhitelist = (e, phoneNum) => {
		onChange(
			e,
			whitelist.filter((number) => number.phoneNumber !== phoneNum.phoneNumber)
		);
	};

	return (
		<div className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<EnhancedTextField
						onValidate={setPhoneNumber}
						errorMsg={phoneNumber.errorMsg}
						validatePred={isPhoneNumberInvalid}
						label={<FormattedMessage {...messages.phoneNumber} />}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<EnhancedTextField
						type="number"
						onValidate={setAllowedCallCount}
						errorMsg={allowedCallCount.errorMsg}
						validatePred={isEmptyValidation}
						label={<FormattedMessage {...messages.allowedCallCount} />}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent={'flex-end'} spacing={1}>
				<Grid item>
					<Button
						onClick={addPhoneNumberToWhitelist}
						color={'primary'}
						disabled={!areFieldsValid}
						className="action-button"
					>
						<FormattedMessage {...messages.addToWhitelist} />
					</Button>
				</Grid>
			</Grid>
			{!!whitelist.length && (
				<Grid item xs={12} className={classes.table}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage {...messages.phoneNumber} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.allowedCallCount} />
								</TableCell>
								<TableCell>
									<FormattedMessage {...messages.madeCallsCount} />
								</TableCell>
								<TableCell align={'right'}>
									<FormattedMessage {...messages.delete} />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{whitelist.map((number, index) => (
								<TableRow key={index} align={'center'}>
									<TableCell>{number.phoneNumber}</TableCell>
									<TableCell align={'center'} style={{ paddingRight: '14%' }}>
										{number.allowedCallCount}
									</TableCell>
									<TableCell align={'center'} style={{ paddingRight: '12%' }}>
										{
											phoneCalls.filter((call) => {
												return (
													(call.phoneNumberFrom.toLowerCase() === number.phoneNumber.toLowerCase() ||
														call.phoneNumberTo.toLowerCase() === number.phoneNumber.toLowerCase()) &&
													call.configHash === configHash
												);
											}).length
										}
									</TableCell>
									<TableCell align={'right'}>
										<Tooltip title={<FormattedMessage {...messages.delete} />}>
											<IconButton
												size={'small'}
												aria-label="delete"
												onClick={(e) => deletePhoneNumberFromWhitelist(e, number)}
											>
												<DeleteIcon fontSize={'small'} />
											</IconButton>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			)}
		</div>
	);
};

Whitelist.propTypes = {
	configHash: PropTypes.string,
	whitelist: PropTypes.array,
	onChange: PropTypes.func,
};

export default Whitelist;
