import { produce } from 'immer';
import { combineReducers } from 'redux';
import { actionTypes as authActionTypes } from '../auth/actions';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = {
	users: [],
	isFetchingAssets: false,
	filteredValue: '',
	metadatas: [],
	dataTable: {
		tableName: '',
		columns: [],
		rows: [],
		dataChanges: {},
		columnsType: {},
	},
};

const dataManagement = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.UPDATE_METADATA_FILTERED_VALUE: {
			return {
				...state,
				filteredValue: payload.value,
			};
		}
		case actionTypes.FETCH_METADATAS_SUCCESS:
			state.metadatas = payload?.data?.metadatas ?? DEFAULT_STATE.metadatas;
			break;
		case actionTypes.FETCH_METADATA_SUCCESS:
			const fetchedMetadata = payload?.data?.metadata;
			state.metadatas.forEach((metadata) => {
				if (metadata.id === fetchedMetadata.id) {
					Object.keys(metadata).forEach((key) => {
						metadata[key] = fetchedMetadata[key];
					});
				}
			});
			break;
		case actionTypes.EDIT_METADATA_SUCCESS:
			const updatedMetadata = payload?.data?.updateMetadata;
			state.metadatas.forEach((metadata) => {
				if (metadata.id === updatedMetadata.id) {
					Object.keys(metadata).forEach((key) => {
						metadata[key] = updatedMetadata[key];
					});
				}
			});
			break;
		case actionTypes.FETCH_DATA_TABLE_COLUMNS_SUCCESS:
			state.dataTable.tableName = payload?.data?.getDataTableColumns?.tableName ?? DEFAULT_STATE.dataTable.tableName;
			state.dataTable.columns = payload?.data?.getDataTableColumns?.columnNames ?? DEFAULT_STATE.dataTable.columns;
			break;
		case actionTypes.ADD_TABLE_COLUMN_SUCCESS:
			if (payload?.data?.getDataTableColumns?.columnNames) {
				state.dataTable.columns = payload?.data?.addDataTableColumn?.columnNames;
			}
			break;
		case actionTypes.FETCH_DATA_TABLE_ROWS_SUCCESS:
			state.dataTable.rows = payload?.data?.getDataTableRows ?? DEFAULT_STATE.dataTable.rows;
			break;
		case actionTypes.UPDATE_TABLE_ROWS_SUCCESS:
			if (payload?.data?.insertUpdateRow) {
				if (payload.data.insertUpdateRow instanceof Array) {
					state.dataTable.rows = payload.data.insertUpdateRow;
				}
				state.dataTable.rows = payload?.data?.insertUpdateRow;
			}
			break;
		case actionTypes.DELETE_TABLE_ROW_SUCCESS:
			if (payload?.data?.deleteRow) {
				state.dataTable.rows = payload?.data?.deleteRow;
			}
			break;
		case actionTypes.UPDATE_DATA_CHANGES:
			if (payload.row instanceof Array) {
				if (!(payload.table in state.dataTable.dataChanges)) {
					state.dataTable.dataChanges[payload.table] = {};
				}
				payload.row.forEach((row) => {
					state.dataTable.dataChanges[payload.table][row.id] = row;
				});
			} else if (payload.row) {
				state.dataTable.dataChanges[payload.table][payload.row.id] = payload.row;
			} else {
				state.dataTable.dataChanges[payload.table] = {};
			}
			break;
		case actionTypes.IMPORT_ROWS_SUCCESS:
			if (payload.data.importRows.rows instanceof Array) {
				state.dataTable.rows = payload.data.importRows.rows;
			}
			break;
		case actionTypes.FETCH_DATA_TABLE_COLUMNS_TYPE_SUCCESS:
			state.dataTable.columnsType = payload?.data?.getDataTableColumnsType || {};
			break;
		case actionTypes.CLEAR_TABLE_STATE:
			state.dataTable = {
				tableName: '',
				columns: [],
				rows: [],
				dataChanges: {},
				columnsType: {},
			};
		default:
			return state;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: dataManagement,
	fetchingMetadatas: fetching([
		actionTypes.FETCH_METADATAS_PENDING,
		actionTypes.FETCH_METADATAS_SUCCESS,
		actionTypes.FETCH_METADATAS_FAIL,
	]),
	deleteMetadata: fetching([
		actionTypes.DELETE_METADATA_PENDING,
		actionTypes.DELETE_METADATA_SUCCESS,
		actionTypes.DELETE_METADATA_FAIL,
	]),
	editMetadata: fetching([
		actionTypes.EDIT_METADATA_PENDING,
		actionTypes.EDIT_METADATA_SUCCESS,
		actionTypes.EDIT_METADATA_FAIL,
	]),
	getDataTableColumns: fetching([
		actionTypes.FETCH_DATA_TABLE_COLUMNS_PENDING,
		actionTypes.FETCH_DATA_TABLE_COLUMNS_SUCCESS,
		actionTypes.FETCH_DATA_TABLE_COLUMNS_FAIL,
	]),
	getDataTableRows: fetching([
		actionTypes.FETCH_DATA_TABLE_ROWS_PENDING,
		actionTypes.FETCH_DATA_TABLE_ROWS_SUCCESS,
		actionTypes.FETCH_DATA_TABLE_ROWS_FAIL,
	]),
});
