import Autocomplete from '@mui/lab/Autocomplete';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { flatten, includes, uniq } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { getAllSmartFunctionsOutput, getAllVariables } from '../../redux/model/selectors';
import { isEmptyValidation } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const InsertVariableModal = ({ handleInsertVariable }) => {
	const [errAutocompleteOption, setErrAutocompleteOption] = useState('');
	const [autocompleteOption, setAutocompleteOption] = useState('');
	const existingVariables = useSelector(getAllVariables).map((variable) => variable[0]);
	const smartFunctionsOutput = useSelector(getAllSmartFunctionsOutput);
	const autocompleteOptions = uniq(flatten([existingVariables, smartFunctionsOutput]));
	const dispatch = useDispatch();

	const handleChange = (e, v) => {
		const value = v || e.target.value || '';
		setErrAutocompleteOption(
			!includes(value, autocompleteOptions) ? <FormattedMessage {...messages.variableDoesntExist} /> : ''
		);
		setAutocompleteOption(value);
	};

	const handleAdd = () => {
		const isOptionEmpty = isEmptyValidation(autocompleteOption);
		setErrAutocompleteOption(errAutocompleteOption || isOptionEmpty);
		if (errAutocompleteOption || isOptionEmpty) {
			return;
		}

		handleInsertVariable(autocompleteOption);
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.insertVariable} />}
			saveBtnTitle={<FormattedMessage {...messages.insertVariable} />}
			onSave={handleAdd}
		>
			<Grid item xs={12} align="left">
				<FormattedMessage {...messages.selectExistVariable} />
				<br />
				<br />
				<Autocomplete
					freeSolo
					options={autocompleteOptions}
					inputValue={autocompleteOption}
					renderInput={(params) => (
						<TextField
							{...params}
							autoFocus
							error={!!errAutocompleteOption}
							helperText={errAutocompleteOption}
							label={<FormattedMessage {...messages.selectVariable} />}
							variant={'outlined'}
							fullWidth
							onKeyPress={handleKeyPress}
							onChange={handleChange}
						/>
					)}
					onChange={handleChange}
				/>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

InsertVariableModal.propTypes = { handleInsertVariable: PropTypes.func };

export default InsertVariableModal;
