import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { messages } from '../../intl';
import { login } from '../../redux/auth/actions';
import { showUserSettingsModal } from '../../redux/modal/actions';
import ROUTES, { ROUTE_NAMES } from '../MaterialDashboard/routes';
import Preloader from '../Preloader/Preloader';

const useStyles = makeStyles((theme) => ({
	loginPage: {
		display: 'flex',
		justifyContent: 'center',
	},
	container: {
		marginTop: 50,
		padding: 20,
		minWidth: 300,
		background: '#fff',
		borderRadius: 5,
		boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
		textAlign: 'center',
	},
	save: {
		minWidth: '100% !important',
		marginTop: '20px !important',
	},
	textField: {
		display: 'block !important',
		width: '100% !important',
		margin: '20px 0 !important',
	},
	error: {
		fontSize: 14,
		fontWeight: 500,
		display: 'block',
	},
}));

const Login = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isFetching, setIsFetching] = useState(false);
	const [errors, setErrors] = useState([]);
	const location = useLocation();
	const navigate = useNavigate();
	const { from } =
		!isEmpty(location.state) && !isNil(location.state) ? location.state : { from: ROUTES[ROUTE_NAMES.DASHBOARD].path };

	const handleSubmit = async (e) => {
		setIsFetching(true);
		setErrors([]);

		e.preventDefault();

		const data = await dispatch(login(email, password));

		if (data.payload?.errors?.length > 0) {
			setErrors(data.payload.errors.map(({ message }) => message));
			setIsFetching(false);
		} else if (location.state?.isFromExternal) {
			navigate(-1);
		} else {
			navigate(from, { replace: true });
			if (data.payload?.passwordExpired) {
				dispatch(showUserSettingsModal());
			}
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSubmit(e);
		}
	};

	return (
		<div className={classes.loginPage}>
			<div className={classes.container}>
				<TextField
					name={`login-email-${window.location.hostname}`}
					label={<FormattedMessage {...messages.email} />}
					type="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					onKeyPress={handleKeyPress}
					className={classes.textField}
					fullWidth
					variant="standard"
				/>
				<TextField
					name={`login-password-${window.location.hostname}`}
					label={<FormattedMessage {...messages.password} />}
					type="password"
					autoComplete="current-password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyPress={handleKeyPress}
					className={classes.textField}
					fullWidth
					variant="standard"
				/>
				{errors.map((message) => (
					<Typography color={'error'} key={message} className={classes.error}>
						{message}
					</Typography>
				))}
				<Button
					onClick={handleSubmit}
					className={classes.save}
					disabled={isFetching}
					variant="contained"
					color="primary"
					data-testid="submit"
				>
					<FormattedMessage {...messages.login} />
					{isFetching && (
						<Preloader
							loaderWrapStyle={{
								background: '#7CBA2D',
							}}
							loaderStyle={{
								height: '15px',
								width: '15px',
								border: '3px solid transparent',
								borderTop: '3px solid #fff',
								borderBottom: '3px solid #fff',
							}}
						/>
					)}
				</Button>
			</div>
		</div>
	);
};

export default Login;
