import { produce } from 'immer';
import { actionTypes as authActionTypes } from '../auth/actions';
import { actionTypes } from './actions';

const DEFAULT_STATE = {
	entities: {
		voiceNames: [],
		phoneNumbers: {},
		availablePhoneNumbers: [],
	},
};

export default produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.FETCH_VOICE_NAMES_SUCCESS:
			state.entities.voiceNames = payload?.data?.voiceNames ?? [];
			break;
		case actionTypes.FETCH_PHONE_NUMBERS_SUCCESS:
		case actionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_SUCCESS:
		case actionTypes.ASSIGN_PHONE_NUMBERS_TO_CONFIG_SUCCESS:
		case actionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_SUCCESS:
		case actionTypes.UNASSIGN_PHONE_NUMBER_FROM_CONFIG_SUCCESS:
			state.entities.phoneNumbers = payload.entities.phoneNumbers ?? {};
			break;
		case actionTypes.FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS:
			state.entities.availablePhoneNumbers = payload.entities.phoneNumbers ?? {};
			break;
	}
}, DEFAULT_STATE);
