import {
	Button,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	List,
	ListItem,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../intl';
import { palette } from '../../mui-theme-builder';
import { deleteModelAsset, uploadAudioFile, upsertModelAsset } from '../../redux/model-table/actions';
import { getIsFetchingAssets, getSpecificAssetsType, selectedModelId } from '../../redux/model-table/selectors';
import { getConfiguration } from '../../redux/model/selectors';
import { AssetAudioFileCreation, AssetAudioFileListItem } from '../model-assets/AssetAudioFile';
import { AssetUnknownListItem } from '../model-assets/AssetUnknown';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	formField: {
		width: '90%',
		marginBottom: '20px',
	},
	tabContainer: {
		display: 'flex',
	},
	formContainer: {
		padding: '10px',
		position: 'relative',
	},
	deleteButton: {
		position: 'absolute',
		right: '0px',
		bottom: '0px',
	},
	tabs: {
		overflow: 'unset',
	},
	indicator: {
		background: theme.palette.primary.main,
	},
	tab: {
		minWidth: '140px',
		marginRight: '4px',
	},
}));

const ASSET_TYPES = Object.freeze({
	FILE_AUDIO: {
		label: 'wav audio file',
	},
});

const ModelAssetsModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const modelId = useSelector(selectedModelId);
	const configuration = useSelector(getConfiguration);

	const assets = useSelector((state) => getSpecificAssetsType(modelId, 'WIDGET_ANNOUNCEMENT', false, state));
	const isFetchingAssets = useSelector(getIsFetchingAssets());

	const [currentAsset, setCurrentAsset] = useState({ name: '', type: Object.keys(ASSET_TYPES)[0], data: {} });
	const [isAddingAsset, setIsAddingAsset] = useState(false);

	const handleAssetDataChange = (data) => {
		if (currentAsset.type === 'FILE_AUDIO' && !currentAsset.name) {
			setCurrentAsset({ ...currentAsset, data, name: data.fileName });
		} else {
			setCurrentAsset({ ...currentAsset, data });
		}
	};

	const handleAssetCreate = () => {
		switch (currentAsset.type) {
			case 'FILE_AUDIO':
				dispatch(uploadAudioFile(currentAsset));
				break;
			default:
				dispatch(upsertModelAsset(modelId, currentAsset));
				break;
		}

		setIsAddingAsset(false);
	};

	const handleDelete = (id) => {
		dispatch(deleteModelAsset(id));
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={configuration.project}
				isSaveBtnVisible={isAddingAsset}
				isSaveBtnDisabled={!currentAsset.name || isEmpty(currentAsset.data)}
				saveBtnTitle={<FormattedMessage {...messages.saveAsset} />}
				onSave={handleAssetCreate}
				headerButton={
					<Button
						onClick={() => {
							setCurrentAsset({ name: '', type: currentAsset.type, data: {} });
							setIsAddingAsset(!isAddingAsset);
						}}
						style={{ background: !isAddingAsset ? palette.primary.main : grey[600], float: 'right' }}
						disabled={!modelId}
						variant="contained"
					>
						<FormattedMessage {...messages[!isAddingAsset ? 'newAsset' : 'backToList']} />
					</Button>
				}
			>
				{!isAddingAsset ? (
					<List>
						{assets.map((asset) => {
							switch (asset.type) {
								case 'FILE_AUDIO':
									return <AssetAudioFileListItem key={asset.id} asset={asset} onDelete={handleDelete} />;
								default:
									return <AssetUnknownListItem key={asset.id} asset={asset} onDelete={handleDelete} />;
							}
						})}
						{isFetchingAssets && (
							<ListItem>
								<CircularProgress style={{ margin: 'auto' }} />
							</ListItem>
						)}
					</List>
				) : (
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<TextField
								className={classes.formField}
								variant="outlined"
								label={<FormattedMessage {...messages.assetsFriendlyName} />}
								value={currentAsset.name}
								onChange={(e) => setCurrentAsset({ ...currentAsset, name: e.target.value })}
								autoFocus
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl variant="outlined" className={classes.formField}>
								<InputLabel>
									<FormattedMessage {...messages.assetsFileType} />
								</InputLabel>
								<Select
									fullWidth
									value={currentAsset.type}
									onChange={(e) => setCurrentAsset({ ...currentAsset, type: e.target.value })}
									label={<FormattedMessage {...messages.assetsFileType} />}
								>
									{Object.entries(ASSET_TYPES).map(([type, { label }]) => (
										<MenuItem value={type} key={type}>
											{label}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<AssetDataCreation assetType={currentAsset.type} onDataChange={handleAssetDataChange} />
						</Grid>
					</Grid>
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

export default ModelAssetsModal;

function AssetDataCreation({ assetType, onDataChange }) {
	switch (assetType) {
		case 'FILE_AUDIO':
			return <AssetAudioFileCreation onDataChange={onDataChange} />;
		default:
			throw new Error(`Can't create unknown type of asset "${assetType}".`);
	}
}

AssetDataCreation.propTypes = {
	assetType: PropTypes.string.isRequired,
	onDataChange: PropTypes.func.isRequired,
};
