import Autocomplete from '@mui/lab/Autocomplete';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateHistory, addNodesIntoNodeGroup } from '../../redux/model/actions';
import { getActiveNodeGroupId, getNodesAddableToNodeGroup } from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

// regex search
const debouncedRegExSearch = debounce(
	(regEx, setNodesToBeAdded, allNodeIds) => {
		if (regEx === '') {
			setNodesToBeAdded([]);
			return;
		}
		regEx = new RegExp(`${regEx}`);
		const matches = allNodeIds.filter((value) => regEx.test(value));
		setNodesToBeAdded(matches);
	},
	500,
	{ leading: false, trailing: true }
);

// prepare node ids list for alphabetical sorting
const prepareToSort = (nodesIds) =>
	nodesIds.map((nodeId) => {
		const firstLetter = nodeId[0];
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
			nodeId,
		};
	});

// hide node ids already selected to be added
const hideSelected = (allNodeIds, nodesToBeAdded) => allNodeIds.filter((nodeId) => !nodesToBeAdded.includes(nodeId));

const AddRemoveGroupNodeModal = ({ selectedNodes }) => {
	const areNodesSelected = selectedNodes && selectedNodes.length > 0;

	const [nodesToBeAdded, setNodesToBeAdded] = useState([]);
	const dispatch = useDispatch();
	const activeNodeGroupId = useSelector(getActiveNodeGroupId);
	const allNodeIds = useSelector(getNodesAddableToNodeGroup(activeNodeGroupId));

	const handleOnSave = () => {
		dispatch(registerStateHistory());

		dispatch(addNodesIntoNodeGroup(activeNodeGroupId, nodesToBeAdded));

		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.addNode} />}
			saveBtnTitle={<FormattedMessage {...messages.addNode} />}
			onSave={handleOnSave}
			isSaveBtnDisabled={isNil(nodesToBeAdded) && !areNodesSelected}
		>
			<FormattedMessage {...messages.addNodeIntoGroup} />
			<br />
			<br />
			<TextField
				label={<FormattedMessage {...messages.regExToAddNodes} />}
				variant={'outlined'}
				onChange={(e) => debouncedRegExSearch(e.target.value, setNodesToBeAdded, allNodeIds)}
			/>
			<br />
			<br />
			<Autocomplete
				value={prepareToSort(nodesToBeAdded)}
				multiple
				groupBy={(item) => item.firstLetter}
				options={prepareToSort(hideSelected(allNodeIds, nodesToBeAdded)).sort(
					(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
				)}
				getOptionLabel={(item) => item.nodeId}
				disableCloseOnSelect
				filterSelectedOptions
				renderInput={(params) => (
					<TextField
						{...params}
						autoFocus
						label={<FormattedMessage {...messages.typeNodeId} />}
						variant={'outlined'}
						fullWidth
					/>
				)}
				onChange={(e, v) => setNodesToBeAdded(v.map((item) => item.nodeId))}
			/>
		</SaveCloseModalTemplate>
	);
};

AddRemoveGroupNodeModal.propTypes = {
	selectedNodes: PropTypes.array,
};

export default AddRemoveGroupNodeModal;
