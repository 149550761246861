import { FiberManualRecord as ColorIcon } from '@mui/icons-material';
import { Grid, TextField, Checkbox, FormControlLabel } from '@mui/material';
import { red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { setColorCaptions, setShowColorCaptions } from '../../redux/model/actions';
import { getColorCaptions, getShowColorCaptions } from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles(() => ({
	input: {
		marginTop: '5px',
	},
	colorIcon: {
		padding: '8px',
	},
}));

const ColorCaptionsModal = () => {
	const classes = useStyles();
	const captions = useSelector(getColorCaptions);
	const showColorCaptions = useSelector(getShowColorCaptions);

	const [inputs, setInputs] = useState(captions);
	const [mutableShowColorCaptions, setMutableShowColorCaptions] = useState(showColorCaptions);
	const dispatch = useDispatch();

	const handleSave = () => {
		dispatch(setColorCaptions(inputs));
		dispatch(setShowColorCaptions(mutableShowColorCaptions));
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.colorCaptions} />}
			onSave={handleSave}
			saveBtnTitle={<FormattedMessage {...messages.save} />}
			isSaveBtnVisible={!isEmpty(inputs)}
		>
			<Grid container>
				{!isEmpty(inputs) ? (
					<Grid container>
						{Object.entries(inputs).map(([color, caption], index) => (
							<Grid container item xs={10} key={index} spacing={3}>
								<Grid item xs={1}>
									<ColorIcon classes={{ root: classes.colorIcon }} style={{ fill: color }} />
								</Grid>
								<Grid item xs={6}>
									<TextField
										value={caption}
										classes={{ root: classes.input }}
										onChange={({ target }) => setInputs((state) => ({ ...state, [color]: target.value }))}
										variant="standard"
									/>
								</Grid>
							</Grid>
						))}
						<Grid item xs={12}>
							<FormControlLabel
								label={<FormattedMessage {...messages.showColorCaptions} />}
								control={
									<Checkbox
										checked={mutableShowColorCaptions}
										onChange={(e, v) => setMutableShowColorCaptions(v)}
										color="primary"
									/>
								}
							/>
						</Grid>
					</Grid>
				) : (
					<p style={{ color: red[500] }}>
						<FormattedMessage {...messages.setColorsFirst} />
					</p>
				)}
			</Grid>
		</SaveCloseModalTemplate>
	);
};

export default ColorCaptionsModal;
