/* eslint-disable no-use-before-define */
import jsyaml from 'js-yaml';
import isInteger from 'lodash/isInteger';
import size from 'lodash/size';
import {
	compose,
	curry,
	descend,
	difference,
	filter,
	flatten,
	includes,
	isEmpty,
	keys,
	length,
	map,
	mapObjIndexed,
	merge,
	mergeAll,
	o,
	omit,
	path,
	pick,
	pickBy,
	pluck,
	prop,
	sort,
	toPairs,
	uniq,
	values,
} from 'ramda';
import { createSelector } from 'reselect';
import {
	CONFIGURATION,
	FLOW,
	FLOW_EDITOR_UI,
	GOTO_TRANSITION_TYPES,
	INTENTS,
	LEGACY_INTENTS_NAME_IN_YAML,
	MODEL_CONFIG_TYPE,
	NODE_GROUPS,
	PROPERTY_TYPES,
	RESPONSE_TYPES,
	SEMANTICALLY_SIMILAR_INTENTS,
	STOPWORDS,
	TRANSITION_TYPES,
	unifyCondition,
	unifySmartFunction,
	VOCABULARY,
	YAML_EDITOR_UI,
} from '../../core/configs';
import { PROPERTY_TYPES_RESET, STT_BOOST } from '../../core/configs/consts';
import { CUSTOM_NODE_COLORS } from '../../core/diagram/constants';
import isNilOrEmpty from '../../utils/isNilOrEmpty';
import { getNearbyDiagramPosition, placeCommentsIntoRawYaml } from './utils';

export const ID = 'model';
/** @type {String[]} */
export const MODEL_DATA_FIELDS = Object.freeze([
	CONFIGURATION,
	FLOW,
	INTENTS,
	SEMANTICALLY_SIMILAR_INTENTS,
	NODE_GROUPS,
	MODEL_CONFIG_TYPE,
	FLOW_EDITOR_UI,
	YAML_EDITOR_UI,
	VOCABULARY,
	STOPWORDS,
]);

// The max number of nodes in a group for which to display referenceNodes
const MAX_REFERENCE_NODE_COUNT = 70;
const DEFAULT_CANVAS_ZOOM = 50;

export const getModelData = prop(ID);
export const getActiveNodeGroupId = createSelector(getModelData, path([NODE_GROUPS, 'activeNodeGroupId']));

export const UI_PROPERTIES = Object.freeze(['diagramPosition', 'canvasOffset', 'canvasZoom']);
export const getEditorUi = createSelector(getModelData, prop(FLOW_EDITOR_UI));
export const getDiagramData = createSelector(getEditorUi, prop('diagrams'));
export const getZoomLevel = createSelector(getEditorUi, prop('zoomLevel'));
export const getIsModelLocked = createSelector(getEditorUi, prop('isModelLocked'));
export const getSelectedNodes = createSelector(getEditorUi, prop('selectedNodes'));
export const getShowColorCaptions = createSelector(getEditorUi, prop('showColorCaptions'));
// list captions that were set for colors used in flow
export const getColorCaptions = createSelector(getEditorUi, prop('colorCaptions'));
export const getDisplayClosestNodesOnly = createSelector(getEditorUi, prop('displayClosestNodesOnly'));
export const getClosestNodesTarget = createSelector(getEditorUi, prop('closestNodesTarget'));
export const getCanvasHeaderHeight = createSelector(getEditorUi, prop('canvasHeaderHeight'));
// node's visibility
export const getHiddenNodes = createSelector(getEditorUi, prop('hiddenNodes'));
export const getIsNodeVisible = (nodeId) =>
	createSelector(getHiddenNodes, (hiddenNodes) => !hiddenNodes.includes(nodeId));
export const getCanvasZoom = createSelector(
	getActiveNodeGroupId,
	getDiagramData,
	getEditorUi,
	(activeNodeGroupId, diagramData, editorUi) => {
		if (activeNodeGroupId) {
			return diagramData.canvasZoom[activeNodeGroupId] || DEFAULT_CANVAS_ZOOM;
		} else {
			return editorUi.canvasZoom;
		}
	}
);
export const getCanvasOffset = createSelector(
	getActiveNodeGroupId,
	getDiagramData,
	getEditorUi,
	(activeNodeGroupId, diagramData, editorUi) => {
		if (activeNodeGroupId) {
			return diagramData.canvasOffset[activeNodeGroupId] || { x: 0, y: 0 };
		} else {
			return editorUi.canvasOffset;
		}
	}
);

export const getProjectData = o(pick(MODEL_DATA_FIELDS), getModelData);
export const getRawYaml = path([ID, 'rawYaml']);
export const getYamlErrors = path([ID, 'rawYamlErrors']);
export const getYamlParserErrors = (state) => getYamlErrors(state).filter((error) => !error.isApiError);
export const getYamlWarnings = path([ID, 'rawYamlWarnings']);
export const getYamlErrorsAndWarnings = (state) => {
	const errors = state[ID]?.rawYamlErrors || [];
	const warnings = state[ID]?.rawYamlWarnings || [];

	const addType = (array, type) => array.map((element) => ({ ...element, type }));

	return [...addType(errors, 'error'), ...addType(warnings, 'warning')];
};
export const getYamlComments = createSelector(getModelData, path([YAML_EDITOR_UI, 'comments']));
export const getManualChangeVersion = path([ID, 'manualChangeVersion']);
export const getPastStateVersions = path([ID, 'pastStateVersions']);
export const getFutureStateVersions = path([ID, 'futureStateVersions']);
export const getValidationErrors = path([ID, 'validationErrors']);
export const getActiveModelConfigType = path([ID, MODEL_CONFIG_TYPE]);
export const getConfiguration = (state) => state[ID]?.[CONFIGURATION] ?? {};
export const getVocabulary = (state) => state[ID]?.[VOCABULARY] ?? {};
export const getStopwords = (state) => state[ID]?.[STOPWORDS] ?? [];
export const getGlobalStartNodeId = o(path(['start_node']), getConfiguration);
export const getNodeGroupIdContainingGlobalStartNode = (state) => {
	const globalStartNodeId = getGlobalStartNodeId(state);
	const node = getNode(globalStartNodeId, state);
	return node?.group;
};
export const getActiveNodeId = path([ID, 'activeNodeId']);
export const getNodes = path([ID, 'flow']);
export const getNodesPositions = path([ID, 'ui', 'diagrams', 'positions']);
export const getNodeGroups = path([ID, NODE_GROUPS, 'groups']);
// Clean the flow from properties that should not trigger rerender when changed
export const getNodesForDiagram = createSelector(getNodes, (nodes) =>
	mapObjIndexed((node) => omit(UI_PROPERTIES, node), nodes)
);

export const getNodeGroupsForDiagram = createSelector(getNodeGroups, (nodeGroups) =>
	nodeGroups.map((nodeGroup) => omit(UI_PROPERTIES, nodeGroup))
);
export const getNodeGroup = curry((groupId, state) => getNodeGroups(state).find((group) => group.name === groupId));
export const getActiveNodeGroup = (state) =>
	getNodeGroups(state).find((group) => group.name === getActiveNodeGroupId(state));
const getActiveNodeGroupWithoutUiProperties = createSelector(getActiveNodeGroup, (activeNodeGroup) =>
	omit(UI_PROPERTIES, activeNodeGroup)
);
export const getAllNodesFromDiagramGroups = (state) => flatten([getNodeGroups(state).map((group) => group.nodes)]);
export const getAllDiagramGroupsNames = (state) => getNodeGroups(state).map((group) => group.name);
export const getProjectName = path([ID, CONFIGURATION, 'project']);
export const getNode = curry((nodeId, state) => getNodes(state)?.[nodeId]);
export const getNodePosition = curry((nodeId, state) => getNodesPositions(state)?.[nodeId]);
export const getNodeIds = o(keys, getNodes);

export const getNodesDisplayedInDiagram = createSelector(
	getActiveNodeGroupWithoutUiProperties,
	getNodeGroupsForDiagram,
	getNodesForDiagram,
	(activeNodeGroup, nodeGroups, nodes) => {
		let displayedNodes = {};

		if (isEmpty(activeNodeGroup)) {
			for (const nodeGroup of nodeGroups) {
				displayedNodes[nodeGroup.name] = { groupId: nodeGroup.name, isGroup: true, color: nodeGroup.color };
			}
		} else {
			displayedNodes = pick(activeNodeGroup.nodes, nodes);
		}

		return displayedNodes;
	}
);

export const getAllGroupStartNodes = (state) => getNodeGroups(state).map((group) => group.startNode);
export const getNodeIdsInGroup = curry((groupId, state) => getNodeGroup(groupId, state)?.nodes);
export const getNodesAddableToNodeGroup = curry((nodeGroupId, state) => {
	const allNodes = getNodes(state);
	const groupStartNodes = getAllGroupStartNodes(state);
	const nodeGroup = getNodeGroup(nodeGroupId, state);
	return Object.keys(allNodes).filter(
		(nodeId) => !nodeGroup.nodes.includes(nodeId) && !groupStartNodes.includes(nodeId)
	);
});
export const getIndependentNodes = (state) =>
	Object.entries(getNodes(state))
		.filter(([, node]) => !node.group)
		.map(([nodeId]) => nodeId);
export const getAvailableTransitionNodes = curry((nodeId, state) => {
	const independentNodes = getIndependentNodes(state);
	Object.entries(getNodes(state))
		.filter(([, node]) => !node.group)
		.map(([nodeId]) => nodeId);
	const groupStartNodes = getAllGroupStartNodes(state);
	const nodesFromSameGroup = getActiveNodeGroup(state)?.nodes || [];

	return uniq([...independentNodes, ...groupStartNodes, ...nodesFromSameGroup]);
});
// List all colors that were manually specified for any of the nodes
export const getColorsFromFlow = (state) => {
	const nodes = getNodesForDiagram(state);
	const colors = Object.values(nodes)
		.filter((node) => node.color && node.color !== CUSTOM_NODE_COLORS.DEFAULT)
		.map((node) => node.color);
	return uniq(colors);
};

export const getNodeResponses = curry((nodeId, state) =>
	// Selects all values which have a number as their key
	pickBy((value, key) => !isNaN(key), getNode(nodeId)(state) || {})
);

export const getNodeResponsesByType = curry((nodeId, responseType, state) => {
	const responses = getNodeResponses(nodeId, state);
	const result = {};

	for (const [key, response] of Object.entries(responses)) {
		if (response[responseType] !== undefined) {
			result[key] = { [responseType]: response[responseType] };
		}
	}

	return result;
});

export const getNodeOutputtedMarkdownOptions = curry((nodeId, state) => {
	const responses = getNodeResponses(nodeId, state);
	const markdownOptions = { [RESPONSE_TYPES.MARKDOWN_OPTIONS]: [], indexInNodeResponses: null };

	for (const [key, response] of Object.entries(responses)) {
		if (response[RESPONSE_TYPES.MARKDOWN_OPTIONS] !== undefined) {
			markdownOptions.indexInNodeResponses = key;
			markdownOptions[RESPONSE_TYPES.MARKDOWN_OPTIONS] = response[RESPONSE_TYPES.MARKDOWN_OPTIONS];
		}
	}

	return markdownOptions;
});
export const getTransitions = (nodeId, transitionType) => o(path([transitionType]), getNode(nodeId));
export const getGotoTransition = curry((nodeId, state) => {
	const pairs = toPairs(pick(GOTO_TRANSITION_TYPES, getNode(nodeId, state)));
	if (isEmpty(pairs)) {
		return { type: '', targetNodeId: '', reuseUtteranceVariable: '' };
	}

	const [type, targetNodeId] = pairs[0];

	if (type === TRANSITION_TYPES.GOTO_REUSE_UTTERANCE) {
		return { type, targetNodeId: targetNodeId[0], reuseUtteranceVariable: targetNodeId[1] };
	}

	return { type, targetNodeId, reuseUtteranceVariable: '' };
});

/**
 * Iterates through transitions of all nodes in a nodeGroup and returns any nodes from different groups that are linked, i.e. have a transition between them
 */
export const getReferenceNodes = curry((state) => {
	const activeNodeGroup = getActiveNodeGroup(state);
	const referenceNodes = {};
	const { ACTIONS, ACTIONS_SIGNALS, CONDITIONS, GOTO, GOTO_REUSE_UTTERANCE, GOTO_WAIT, GOTO_BACK } = TRANSITION_TYPES;

	if (!activeNodeGroup || activeNodeGroup.nodes.length > MAX_REFERENCE_NODE_COUNT) {
		return {};
	}

	const addNodeToReferenceNodesIfExternal = (targetNodeId, sourceNodeId) => {
		if (!activeNodeGroup.nodes.includes(targetNodeId) && !referenceNodes[targetNodeId]) {
			const targetNode = getNode(targetNodeId, state);
			// Only nodes that are groupStartNodes can be referenceNodes
			// It is enough to check if the node has a group, in case there are multiple targetNodes, they will be rendered on top of each other because they get the same diagramPosition
			if (targetNode && targetNode.group) {
				// NodeId and not groupId is used for reference so that ReactDiagrams can create links between the referenceNode and the sourceNode
				referenceNodes[targetNodeId] = {
					...targetNode,
					isReferenceNode: true,
					diagramPosition:
						// Search for a manually set position for a reference node of the said group first
						activeNodeGroup.referenceNodeDiagramPositions[targetNode.group] ||
						getNearbyDiagramPosition(sourceNodeId, state),
				};
			}
		}
	};

	for (const sourceNodeId of activeNodeGroup.nodes) {
		const sourceNode = getNode(sourceNodeId, state);
		// It cannot be ruled out that there is a node without any actual representation
		if (!sourceNode) {
			continue;
		}

		for (const TYPE of [GOTO, GOTO_REUSE_UTTERANCE, GOTO_WAIT, GOTO_BACK]) {
			if (sourceNode[TYPE]) {
				addNodeToReferenceNodesIfExternal(sourceNode[TYPE], sourceNodeId);
			}
		}

		if (sourceNode[ACTIONS]) {
			for (const targetNodeId of Object.keys(sourceNode[ACTIONS])) {
				addNodeToReferenceNodesIfExternal(targetNodeId, sourceNodeId);
			}
		}

		if (sourceNode[ACTIONS_SIGNALS]) {
			for (const targetNodeId of Object.values(sourceNode[ACTIONS_SIGNALS])) {
				addNodeToReferenceNodesIfExternal(targetNodeId, sourceNodeId);
			}
		}

		if (sourceNode[CONDITIONS]) {
			for (const [, targetNodeId] of sourceNode[CONDITIONS].map(unifyCondition)) {
				addNodeToReferenceNodesIfExternal(targetNodeId, sourceNodeId);
			}
		}
	}

	return referenceNodes;
});

export const getTransitionsTargettingNode = curry((nodeId, state) => {
	const flow = getNodesForDiagram(state);
	const transitionsTargettingNode = [];
	const { ACTIONS, ACTIONS_SIGNALS, CONDITIONS, GOTO, GOTO_REUSE_UTTERANCE, GOTO_WAIT, GOTO_BACK } = TRANSITION_TYPES;

	// Iterate over all nodes and search for a reference to the nodeId in their transitions
	for (const [prevNodeId, prevNode] of Object.entries(flow)) {
		// All of these transitions can be set only once, their value is the nodeId itself
		for (const TYPE of [GOTO, GOTO_REUSE_UTTERANCE, GOTO_WAIT, GOTO_BACK]) {
			if (prevNode[TYPE] === nodeId) {
				const tableEntry = {
					type: TYPE,
					sourceNode: prevNodeId,
				};
				transitionsTargettingNode.push(tableEntry);
			}
		}

		// Each node can have multiple actions but they must target different nodes
		// Its value is an object e. g. {nodeId: intentName, anotherNodeId: intentName2}
		if (prevNode[ACTIONS] && prevNode[ACTIONS][nodeId]) {
			const tableEntry = { value: prevNode[ACTIONS][nodeId], type: ACTIONS, sourceNode: prevNodeId };
			transitionsTargettingNode.push(tableEntry);
		}

		// There are subtypes of signals, its value is an object specifying the subtypes
		// e.g. {no_input: nodeId, no_match: anotherNodeId}
		if (prevNode[ACTIONS_SIGNALS]) {
			const signalsTargettingNode = pickBy(
				(targetNodeForSignal) => targetNodeForSignal === nodeId,
				prevNode[ACTIONS_SIGNALS]
			);

			for (const signalName of Object.keys(signalsTargettingNode)) {
				const tableEntry = { value: signalName, type: ACTIONS_SIGNALS, sourceNode: prevNodeId };
				transitionsTargettingNode.push(tableEntry);
			}
		}

		// Multiple conditions are valid, even targetting the same node
		// The value of conditions is a list of ['condition', 'targetNode'] lists
		if (prevNode[CONDITIONS]) {
			const conditionsTarggetingNode = prevNode[CONDITIONS].filter(
				([, targetNodeForCondition]) => targetNodeForCondition === nodeId
			);

			for (const [condition] of conditionsTarggetingNode) {
				const tableEntry = { value: condition, type: CONDITIONS, sourceNode: prevNodeId };
				transitionsTargettingNode.push(tableEntry);
			}
		}
	}

	return transitionsTargettingNode;
});

export const getIntents = path([ID, 'intents']);
export const getIntent = curry((intentId, state) => getIntents(state)?.[intentId]);
export const getSsiGroups = path([ID, SEMANTICALLY_SIMILAR_INTENTS]);
export const getIntentIds = o(keys, getIntents);

export const getIntentsWithReferencesToNodes = (state) => {
	const bareIntents = getIntents(state);
	const intents = [];

	for (const [intentId, utterances] of Object.entries(bareIntents)) {
		intents.push({
			intentId,
			utterances,
			originNodeIds: getIntentOriginNodeIds(intentId, state) || [],
			targetNodeIds: getIntentTargetNodeIds([intentId], state),
		});
	}

	return intents;
};

/**
 * Get nodeIds of nodes which use intentId in their actions.
 * --->INTENT_ORIGIN_NODE<---:
 * 	ACTIONS:
 * 		INTENT_NODE: *INTENT_ID
 */
export const getIntentOriginNodeIds = curry((intentId, state) =>
	compose(
		keys,
		filter((node) => o(includes(intentId), values)(node[TRANSITION_TYPES.ACTIONS])),
		getNodesForDiagram
	)(state)
);

/**
 * Get nodeIds which are coupled with the requested intentId in ACTIONS,
 * INTENT_ORIGIN_NODE:
 * 	ACTIONS:
 * 		->>>INTENT_NODE<---: *INTENT_ID
 * 			NOT_INTENT_NODE: *DIFFERENT_INTENT_ID
 */
export const getIntentNodeIds = curry((requestedIntentId, state) => {
	const originIntentNodesActions = compose(
		pluck([TRANSITION_TYPES.ACTIONS]),
		values,
		filter((node) => o(includes(requestedIntentId), values)(node[TRANSITION_TYPES.ACTIONS])),
		getNodesForDiagram
	)(state);

	const intentNodeIds = [];
	for (const originIntentNodeAction of originIntentNodesActions) {
		for (const [intentNodeId, intentId] of Object.entries(originIntentNodeAction)) {
			if (intentId === requestedIntentId) {
				intentNodeIds.push(intentNodeId);
			}
		}
	}

	return uniq(intentNodeIds);
});

/**
 * Get nodeIds which are the target of a GOTO transition in intentNodes
 * INTENT_NODE:
 * 	GOTO: ->>>INTENT_TARGET_NODE<---
 */
export const getIntentTargetNodeIds = curry((intentIds, state) => {
	const intentNodeIds = compose(
		uniq,
		flatten,
		map((intentId) => getIntentNodeIds(intentId, state))
	)(intentIds);

	return compose(
		// Remove empty strings or other false values
		filter((nodeId) => nodeId),
		uniq,
		pluck('targetNodeId'),
		map((intentNodeId) => getGotoTransition(intentNodeId, state))
	)(intentNodeIds);
});

// TODO: Appropriate for memoization or refactor with getIntentOriginNodeIds (e.g. array type param).
export const getNodeIdsWithIntentCombination = (intentIds) =>
	compose(
		keys,
		filter((node) => compose(isEmpty, difference(intentIds), values)(node[TRANSITION_TYPES.ACTIONS])),
		getNodesForDiagram
	);

export const getIntentIdsFromOriginNodes = curry((originNodeIds, state) =>
	compose(
		uniq,
		values,
		mergeAll,
		map((originNodeId) => getTransitions(originNodeId, TRANSITION_TYPES.ACTIONS)(state))
	)(originNodeIds)
);

export const getIntentIdsFromSameOriginNodeToSameTargetNode = (targetNodeId, originNodeIds) => (state) => {
	// Get intents from all (origin)Nodes where the selected intent is used
	const intentsWithSameOriginNode = getIntentIdsFromOriginNodes(originNodeIds, state);

	// The intents also need to have the same targetNode, there has to be a link: originNode (ACTIONS) -> intentNode (GOTO) -> targetNode
	return intentsWithSameOriginNode.filter((intentId) =>
		getIntentTargetNodeIds([intentId], state).includes(targetNodeId)
	);
};

export const getYamlEditorUiConfig = path([ID, YAML_EDITOR_UI]);
export const getDiagramPositions = createSelector(getDiagramData, prop('positions'));

// YAML
/**
 * Dumps data into YAML format deterministically - the same data are always serialized into
 * the same string. That is possible because we sort object keys so the result is predictable.
 */

// Simple YAML - no formatting
const yamlDump = (data, sortKeys = false) => {
	try {
		return jsyaml.dump(data, { sortKeys, lineWidth: 300 });
	} catch (e) {
		throw new Error(`Data could not be serialized to YAML. Got "${e}" for: ${JSON.stringify(data)}`);
	}
};

const cleanNodeForYaml = omit(['levelX', 'levelY', 'color', 'group', 'diagramPosition']);

/**
 * Sanitizes anchors that should be referenced as `*anchor_name` in YAML.
 * But ignores generic intents that are written as `.generic_intent_name`.
 */
const sanitizeActionAnchor = (a) => (typeof a === 'string' && !a.startsWith('.') ? `*${a}` : a);

/**
 * This function serves for the backward compatibility purpose of the outdated CALL and EXTRACT records
 *
 * @param {Object} node
 * @param {String} type
 * @returns {Object} node with uniformed extractor
 */
const transformExtractor = (node, type) => {
	const functions = node[type];
	if (functions) {
		return {
			...node,
			[type]: functions.map(unifySmartFunction),
		};
	}

	return node;
};

export const getYamlFromModel = (state) => {
	const flow = mapObjIndexed((node) => {
		if (node.ACTIONS) {
			node = {
				...node,
				ACTIONS: mapObjIndexed(sanitizeActionAnchor, node.ACTIONS),
			};
		}

		node = transformExtractor(node, PROPERTY_TYPES.CALL);
		node = transformExtractor(node, PROPERTY_TYPES.EXTRACT);

		return cleanNodeForYaml(node);
	}, getNodesForDiagram(state));
	const intents = getIntents(state);
	const vocabulary = getVocabulary(state);
	const stopwords = getStopwords(state);

	if (isEmpty(flow) && isEmpty(intents) && isEmpty(vocabulary) && isEmpty(stopwords)) {
		return '';
	}

	let flowString = yamlDump({ [FLOW]: flow });
	let anchorString = yamlDump({ [LEGACY_INTENTS_NAME_IN_YAML]: intents });

	// add anchor/alias syntax
	flowString = flowString.replace(/: '\*(\w+)'/g, ': *$1');
	anchorString = anchorString.replace(/ {2}('?)(\w+)('?):/g, '  $2: &$2');

	const config = getConfiguration(state);
	const configString = yamlDump({ [CONFIGURATION]: config });

	let yaml = configString + '\n' + anchorString + '\n' + flowString;

	const ssiGroups = getSsiGroups(state);
	if (size(ssiGroups) > 0) {
		const ssi = yamlDump({ [SEMANTICALLY_SIMILAR_INTENTS]: ssiGroups });
		yaml = yaml + '\n' + ssi;
	}

	const yamlComments = getYamlComments(state);
	yaml = placeCommentsIntoRawYaml(yamlComments, yaml);

	if (vocabulary && !isEmpty(vocabulary)) {
		const vocabularyString = yamlDump({ [VOCABULARY]: vocabulary });
		yaml += '\n' + vocabularyString;
	}
	if (stopwords && !isEmpty(stopwords)) {
		const stopwordsString = yamlDump({ [STOPWORDS]: stopwords });
		yaml += '\n' + stopwordsString;
	}

	return yaml;
};

const nodesInputsSentences = (nodeId, flow) => {
	const responses = [];
	Object.entries(flow).map(([_nodeId, node]) => {
		// if node is input
		if (nodeId !== _nodeId && JSON.stringify(node).includes('"' + nodeId + '"') && node[1]) {
			// add response
			responses.push(Object.values(node[1])[0]);
		}
	});

	return responses;
};

export const getYamlFromModelFormatted = (state) => {
	let nodesActionsComments = {};
	const _flow = getNodesForDiagram(state);
	const intents = getIntents(state);
	const vocabulary = getVocabulary(state);
	const stopwords = getStopwords(state);

	if (isEmpty(_flow) && isEmpty(intents) && isEmpty(vocabulary) && isEmpty(stopwords)) {
		return '';
	}

	// CUSTOM PARSING : JavaScript -> YAML
	const flow = mapObjIndexed((node, nodeId) => {
		// ACTIONS
		const nodesActions = node[TRANSITION_TYPES.ACTIONS];
		if (nodesActions) {
			const sentences = nodesInputsSentences(nodeId, _flow);

			if (!isEmpty(sentences)) {
				nodesActionsComments = merge({ [nodeId]: sentences }, nodesActionsComments);
			}
			node = {
				...node,
				[TRANSITION_TYPES.ACTIONS]: mapObjIndexed(sanitizeActionAnchor, nodesActions),
			};
		}

		// CALL
		node = transformExtractor(node, PROPERTY_TYPES.CALL);

		// SET
		const variables = node[PROPERTY_TYPES.SET];
		if (variables) {
			node = {
				...node,
				[PROPERTY_TYPES.SET]: variables.map((s) => (Array.isArray(s) ? { [s[0]]: s[1] } : s)),
			};
		}

		// EXTRACT
		node = transformExtractor(node, PROPERTY_TYPES.EXTRACT);

		return cleanNodeForYaml(node);
	}, _flow);

	/**
	 * SORT NODE'S KEYS - START
	 * using regex (JS can't sort object keys natively)
	 */
	const makeKeyForOrder = (attrName, i) => `_in_node_order_${String(i).padStart(3, '0')}_${attrName}`;
	const nodesKeyOrder = [
		PROPERTY_TYPES.EXTRACT,
		PROPERTY_TYPES.CALL,
		PROPERTY_TYPES.RESET,
		PROPERTY_TYPES.SET,
		'OUTPUT',
		TRANSITION_TYPES.ACTIONS,
		TRANSITION_TYPES.ACTIONS_SIGNALS,
		TRANSITION_TYPES.CONDITIONS,
		TRANSITION_TYPES.GOTO,
		TRANSITION_TYPES.GOTO_REUSE_UTTERANCE,
		TRANSITION_TYPES.GOTO_WAIT,
		TRANSITION_TYPES.GOTO_BACK,
	];

	const sortNodesKeys = (flow) => {
		const sortedFlow = {};
		for (const [nodeName, node] of Object.entries(flow)) {
			const sortedNode = {};
			sortedFlow[nodeName] = sortedNode;

			for (const [index, attrName] of nodesKeyOrder.entries()) {
				if (attrName === 'OUTPUT') {
					Object.entries(node)
						.filter(([k]) => isInteger(Number(k)))
						.forEach(([k, data]) => (sortedNode[makeKeyForOrder(k, index)] = data));
				} else {
					const data = node[attrName];
					if (data != null) {
						sortedNode[makeKeyForOrder(attrName, index)] = node[attrName];
					}
				}
			}
		}

		return (
			yamlDump({ [FLOW]: sortedFlow }, true)
				.replace(/_in_node_order_\d+_/g, '')
				// remove empty lines
				.replace(/\n\n/g, '\n')
		);
	};
	/* SORT NODE'S KEYS - END */

	let flowString = sortNodesKeys(flow);

	let anchorString = yamlDump({ [LEGACY_INTENTS_NAME_IN_YAML]: intents }, true);

	// add anchor/alias syntax
	flowString = flowString.replace(/: '\*(\w+)'/g, ': *$1');
	anchorString = anchorString.replace(/ {2}(\w+):/g, '  $1: &$1');

	// labels as comments: we need to sort them from the longest to match those with shared prefix first
	const intentsToReplace = sort(descend(length), Object.keys(intents));
	flowString = flowString.replace(new RegExp(': \\*(' + intentsToReplace.join('|') + ')', 'g'), (nodeId, match) => {
		const test = intents[match];
		return nodeId + (!isEmpty(test) ? ' # ' + JSON.stringify(test) : '');
	});

	// ACTIONS comments
	if (!isEmpty(nodesActionsComments)) {
		const regex = new RegExp(
			'\n[ ]{2}((?:' + Object.keys(nodesActionsComments).join('|') + `):.+?${TRANSITION_TYPES.ACTIONS}):`,
			'gs'
		);

		flowString = flowString.replace(regex, (group, match) => {
			const nodeId = match.substr(0, match.indexOf(':'));
			return group + ' # ' + JSON.stringify(uniq(nodesActionsComments[nodeId]));
		});
	}

	const config = getConfiguration(state);
	const configString = yamlDump({ [CONFIGURATION]: config }, true);

	let yaml = configString + '\n' + anchorString + '\n' + flowString;

	// state node groups
	const ssiGroups = getSsiGroups(state) || {};
	Object.entries(ssiGroups).map(([nodeId, groupItems]) => {
		// removing empty groups
		if (isEmpty(groupItems)) {
			Reflect.deleteProperty(ssiGroups, nodeId);
		}
	});

	if (!isEmpty(ssiGroups)) {
		const ssi = yamlDump({ [SEMANTICALLY_SIMILAR_INTENTS]: ssiGroups }, true);
		yaml = yaml + '\n' + ssi;
	}

	// blank lines between anchors, nodes and state nodes groups
	yaml = yaml.replace(/\n[ ]{2}[A-Z0-9]/g, (group) => `\n${group}`);

	// (General) comments
	const comments = getYamlComments(state);
	yaml = placeCommentsIntoRawYaml(comments, yaml);

	if (vocabulary && !isEmpty(vocabulary)) {
		const vocabularyString = yamlDump({ [VOCABULARY]: vocabulary });
		yaml += '\n' + vocabularyString;
	}
	if (stopwords && !isEmpty(stopwords)) {
		const stopwordsString = yamlDump({ [STOPWORDS]: stopwords });
		yaml += '\n' + stopwordsString;
	}

	return yaml;
};
// YAML END

/**
 * We differentiate in between 2 variable types in regard to backwards compatibility
 * The valid one { varName: 'var value' }, or the old one ['varName', 'var value'].
 * The older format is still used for its convenience inside the store
 */
const unifyVariableFormat = (variable) => (Array.isArray(variable) ? variable : Object.entries(variable)[0]);

export const getAllVariables = (state) =>
	uniq([
		...Object.values(getNodesForDiagram(state)).flatMap(getNodeVariables),
		...getAllSmartFunctionsOutput(state).map((smartFunctionVariable) => [smartFunctionVariable, '']),
	]);

export const getNodeVariables = curry((nodeId, state) => {
	const node = getNode(nodeId, state) || {};
	return (node[PROPERTY_TYPES.SET] || []).map(unifyVariableFormat);
});

export const getNodeVariablesToReset = curry((nodeId, state) => {
	const rawVariables = getNode(nodeId, state)[PROPERTY_TYPES.RESET] ?? [];
	const keywords = Object.values(PROPERTY_TYPES_RESET);

	return {
		type: rawVariables.find((i) => keywords.includes(i)) ?? PROPERTY_TYPES_RESET.INCLUDING,
		list: rawVariables.filter((i) => !keywords.includes(i)),
	};
});

export const getNodeSttBoost = curry((nodeId, state) => {
	const variables = getNodeVariables(nodeId, state) || [];
	const sttBoostIndex = variables.findIndex((variable) => variable[0] === STT_BOOST);
	if (sttBoostIndex > -1) {
		return JSON.parse(variables[sttBoostIndex][1]);
	}

	return [];
});

export const getNodesConditions = curry((nodeId, state) => {
	const conditions = state[ID].flow[nodeId]?.[TRANSITION_TYPES.CONDITIONS];
	if (!conditions) {
		return [];
	} else {
		return conditions.map(unifyCondition);
	}
});

export const getAllConditions = (state) => {
	const flow = getNodesForDiagram(state);
	const conditions = [];

	for (const [, node] of Object.entries(flow)) {
		const _conditions = node[TRANSITION_TYPES.CONDITIONS];

		if (isNilOrEmpty(_conditions)) {
			continue;
		}

		for (const conditionData of _conditions) {
			const [condition] = unifyCondition(conditionData);
			conditions.push(condition);
		}
	}

	return uniq(conditions);
};

export const getNodesProperty = (nodeId, propertyType) => path([ID, FLOW, nodeId, propertyType]);

export const getAllSmartFunctions = (state) => {
	const flow = getNodesForDiagram(state);
	const smartFunctions = [];

	for (const node of Object.values(flow)) {
		const _smartFunctions = node[PROPERTY_TYPES.CALL];

		if (!_smartFunctions) {
			continue;
		}

		for (const smartFunction of _smartFunctions) {
			smartFunctions.push(smartFunction);
		}
	}

	return uniq(smartFunctions);
};

export const getAllSmartFunctionsOutput = (state) =>
	getAllSmartFunctions(state).map((smartFunc) => Object.keys(unifySmartFunction(smartFunc))[0]);

export const getUtterances = (state) => {
	const intents = getIntents(state);
	return flatten(Object.values(intents));
};
