import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { createUpdateVariable, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import { validateIsEmpty } from '../../utils/validation';
import { PythonEditor } from '../PythonEditor';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const AddEditVariableModal = (payload) => {
	const [errorName, setErrorName] = useState('');
	const [errorValue, setErrorValue] = useState('');
	const [variableName, setVariableName] = useState(payload.varName || '');
	const [variableValue, setVariableValue] = useState(payload.varValue || '');
	const dispatch = useDispatch();
	const intl = useIntl();

	const handleAdd = () => {
		setErrorName(validateIsEmpty(variableName));
		setErrorValue(validateIsEmpty(variableValue));

		if (validateIsEmpty(variableName) || validateIsEmpty(variableValue)) {
			return;
		}

		dispatch(
			registerStateBeforeDispatchingAction(
				createUpdateVariable(payload.nodeId, payload.varId, variableName, variableValue)
			)
		);
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	const text = <FormattedMessage {...messages[payload.varName ? 'editVariable' : 'newVariable']} />;

	return (
		<SaveCloseModalTemplate title={text} saveBtnTitle={text} onSave={handleAdd}>
			<Grid container direction="column" spacing={2} justify="center" alignItems="stretch">
				<Grid item xs={12}>
					<TextField
						autoFocus
						value={variableName}
						error={!!errorName}
						helperText={errorName || errorValue}
						fullWidth
						onChange={(e) => {
							setVariableName(e.target.value);
							setErrorName(validateIsEmpty(e.target.value));
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.newVariableName} />}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<PythonEditor
						value={variableValue}
						placeholder={intl.formatMessage(messages.newVariableValue)}
						onChange={(value) => {
							setVariableValue(value);
							setErrorValue(validateIsEmpty(value));
						}}
					/>
				</Grid>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

export default AddEditVariableModal;
