import Autocomplete from '@mui/lab/Autocomplete';
import { Grid, TextField } from '@mui/material';
import { uniq } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, renameVariable } from '../../redux/model/actions';
import { getAllVariables } from '../../redux/model/selectors';
import { isEmptyValidation } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RenameVariableModal = () => {
	const [prevVar, setPrevVar] = useState(null);
	const [errPrevVar, setErrPrevVar] = useState(null);
	const [newVar, setNewVar] = useState(null);
	const [errNewVar, setErrNewVar] = useState(null);
	const variables = uniq(useSelector(getAllVariables).map((variable) => variable[0]));
	const dispatch = useDispatch();

	const handleRename = () => {
		if (!prevVar) {
			setErrPrevVar(isEmptyValidation(''));
			return;
		}
		if (!newVar) {
			setErrNewVar(isEmptyValidation(''));
			return;
		}
		if (!errNewVar && !errPrevVar) {
			dispatch(registerStateBeforeDispatchingAction(renameVariable(prevVar, newVar)));
			dispatch(hideModal());
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleRename();
		}
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.renameVariable} />}
			saveBtnTitle={<FormattedMessage {...messages.renameVariable} />}
			onSave={handleRename}
		>
			<Grid item xs={12} align="left">
				<FormattedMessage {...messages.selectExistVariable} />
				<br />
				<br />
				<Autocomplete
					options={variables}
					getOptionLabel={(option) => option}
					blurOnSelect
					renderInput={(params) => (
						<TextField
							{...params}
							label={<FormattedMessage {...messages.selectVariable} />}
							variant="outlined"
							error={!!errPrevVar}
							helperText={errPrevVar}
						/>
					)}
					onChange={(e, v) => {
						setErrPrevVar(null);
						setPrevVar(v);
					}}
				/>
				<br />
				<br />
				<TextField
					autoFocus
					error={!!errNewVar}
					helperText={errNewVar}
					label={<FormattedMessage {...messages.replaceWith} />}
					variant={'outlined'}
					fullWidth
					onKeyPress={handleKeyPress}
					onChange={(e) => {
						setNewVar(e.target.value);
						setErrNewVar(isEmptyValidation(e.target.value));
					}}
				/>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

export default RenameVariableModal;
