import { LOCALE } from '../core/configs/consts';
import cs from './cs-CZ';
import messages from './messages';
import pl from './pl-PL';
import sk from './sk-SK';

const prepareEnUsFromDefault = (messages) => {
	const en = {};
	for (const { id, defaultMessage } of Object.values(messages)) {
		en[id] = defaultMessage;
	}

	return en;
};

export default {
	[LOCALE.EN]: prepareEnUsFromDefault(messages),
	[LOCALE.PL]: pl,
	[LOCALE.CS]: cs,
	[LOCALE.SK]: sk,
};
