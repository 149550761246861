import { TextField, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { mergeLeft } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { updateCampaign } from '../../redux/outbound/actions';
import { getCampaign } from '../../redux/outbound/selectors';
import { convertDateToString, convertStringToDate } from '../../utils/date-time';
import { isPhoneNumberInvalid, validateIsEmpty } from '../../utils/validation';
import FreeSoloAutocomplete from '../inputs/FreeSoloAutocomplete';
import { useStylesCampaignDetail } from '../MaterialDashboard/Outbound/CampaignDetail';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const FORM_FIELD = {
	PHONE_NUMBER: 'phoneNumber',
	FIRST_CALL_TIME: 'firstCallTime',
	METADATA: 'metaData',
	REP_CALL_DELAY: 'repeatCallDelayInMinutes',
	TRIALS: 'maxCallTrials',
	CALL_STATUSES_TO_REPEAT: 'callStatusesToRepeat',
};

const AddNewNumberModal = ({ id, metaDataPattern }) => {
	const classes = useStylesCampaignDetail();
	const dispatch = useDispatch();
	const text = <FormattedMessage {...messages.addNumber} />;
	const campaign = useSelector(getCampaign(id));
	const [formFieldsState, setFormFieldsState] = useState({
		[FORM_FIELD.PHONE_NUMBER]: '',
		[FORM_FIELD.FIRST_CALL_TIME]: new Date(new Date().getTime() + 2 * 60000).toISOString(),
		[FORM_FIELD.METADATA]: metaDataPattern,
		[FORM_FIELD.REP_CALL_DELAY]: campaign.repeatCallDelayInMinutes,
		[FORM_FIELD.TRIALS]: campaign.maxCallTrials,
		[FORM_FIELD.CALL_STATUSES_TO_REPEAT]: campaign.callStatusesToRepeat,
	});

	const [formFieldErrors, setFormFieldErrors] = useState({
		[FORM_FIELD.PHONE_NUMBER]: null,
		[FORM_FIELD.FIRST_CALL_TIME]: null,
		[FORM_FIELD.METADATA]: null,
		[FORM_FIELD.REP_CALL_DELAY]: null,
		[FORM_FIELD.TRIALS]: null,
		[FORM_FIELD.CALL_STATUSES_TO_REPEAT]: null,
	});

	const handleAddNumber = (e) => {
		const number = e.target.value;
		if (e.key === 'Enter' || number) {
			if (isPhoneNumberInvalid(number)) {
				setFormFieldErrors({ ...formFieldErrors, [FORM_FIELD.PHONE_NUMBER]: isPhoneNumberInvalid(number) });
				return;
			}
		}
		setFormFieldErrors({ ...formFieldErrors, [FORM_FIELD.PHONE_NUMBER]: null });
		setFormFieldsState({ ...formFieldsState, [FORM_FIELD.PHONE_NUMBER]: number });
	};

	const isValidDate = (date) => {
		let invalid = false;
		let warning = false;
		let errorMessage = '';
		const newDateObject = new Date(date);
		if (date === '') {
			invalid = true;
			warning = true;
			errorMessage = <FormattedMessage {...messages.invalidDate} />;
		} else if (newDateObject < new Date()) {
			invalid = true;
			warning = true;
			errorMessage = <FormattedMessage {...messages.invalidDate} />;
		} else if (
			newDateObject.getHours() < 8 ||
			newDateObject.getHours() > 18 ||
			(newDateObject.getHours() > 17 && newDateObject.getMinutes() > 30)
		) {
			// non-working hrs logic
			invalid = false;
			warning = true;
			errorMessage = <FormattedMessage {...messages.nonWorkingHrs} />;
		} else if (newDateObject.getDay() === 0 || newDateObject.getDay() === 6) {
			invalid = false;
			warning = true;
			errorMessage = <FormattedMessage {...messages.weekend} />;
		}
		return { invalid, warning, errorMessage };
	};

	const updateFormStates = (value, field) => {
		switch (field) {
			case FORM_FIELD.FIRST_CALL_TIME:
				setFormFieldsState({ ...formFieldsState, [FORM_FIELD.FIRST_CALL_TIME]: value.toISOString() });
				break;
			case FORM_FIELD.REP_CALL_DELAY:
				setFormFieldsState({ ...formFieldsState, [FORM_FIELD.REP_CALL_DELAY]: value });
				break;
			case FORM_FIELD.TRIALS:
				setFormFieldsState({ ...formFieldsState, [FORM_FIELD.TRIALS]: value });
				break;
			case FORM_FIELD.CALL_STATUSES_TO_REPEAT:
				setFormFieldsState({ ...formFieldsState, [FORM_FIELD.CALL_STATUSES_TO_REPEAT]: value });
				break;
		}
	};

	const validateAddNumberForm = (value, field) => {
		let error;
		switch (field) {
			case FORM_FIELD.PHONE_NUMBER:
				error = validateIsEmpty(value) || isPhoneNumberInvalid(value);
				setFormFieldErrors({ ...formFieldErrors, [FORM_FIELD.PHONE_NUMBER]: error });
				break;
			case FORM_FIELD.FIRST_CALL_TIME:
				error = isValidDate(value);
				setFormFieldErrors({
					...formFieldErrors,
					[FORM_FIELD.FIRST_CALL_TIME]: {
						error: error.invalid,
						warning: error.warning,
						errorMessage: error.errorMessage,
					},
				});
				break;
			case FORM_FIELD.REP_CALL_DELAY:
				error = validateIsEmpty(value);
				setFormFieldErrors({ ...formFieldErrors, [FORM_FIELD.REP_CALL_DELAY]: error });
				break;
			case FORM_FIELD.TRIALS:
				error = validateIsEmpty(value);
				setFormFieldErrors({ ...formFieldErrors, [FORM_FIELD.TRIALS]: error });
				break;
		}
		return error;
	};

	const handleUpdateCampaign = () => {
		for (const [field, value] of Object.entries(formFieldsState)) {
			const error = validateAddNumberForm(value, field);
			if (field === FORM_FIELD.FIRST_CALL_TIME && error.invalid) {
				return false;
			} else if (field !== FORM_FIELD.FIRST_CALL_TIME && error) {
				return false;
			}
		}

		const phoneCalls = campaign.phoneCalls;
		let phoneCallKey = formFieldsState[FORM_FIELD.PHONE_NUMBER];
		// Check if phone no. already exists in the campaign
		if (formFieldsState[FORM_FIELD.PHONE_NUMBER] in phoneCalls) {
			let count = 0;
			Object.keys(phoneCalls).forEach((key) => {
				const phoneNumber = key.split(' ')[0];
				if (phoneNumber === formFieldsState[FORM_FIELD.PHONE_NUMBER]) {
					count++;
				}
			});
			phoneCallKey = `${formFieldsState[FORM_FIELD.PHONE_NUMBER]} (${count})`;
		}
		const newPhoneCallsObj = mergeLeft(
			{
				[phoneCallKey]: {
					[FORM_FIELD.PHONE_NUMBER]: formFieldsState[FORM_FIELD.PHONE_NUMBER],
					[FORM_FIELD.FIRST_CALL_TIME]: formFieldsState[FORM_FIELD.FIRST_CALL_TIME],
					[FORM_FIELD.METADATA]: formFieldsState[FORM_FIELD.METADATA],
					callList: id,
					status: 'waiting',
				},
			},
			phoneCalls
		);
		dispatch(
			updateCampaign({
				...campaign,
				id,
				phoneCalls: newPhoneCallsObj,
				[FORM_FIELD.REP_CALL_DELAY]: formFieldsState[FORM_FIELD.REP_CALL_DELAY],
				[FORM_FIELD.CALL_STATUSES_TO_REPEAT]: formFieldsState[FORM_FIELD.CALL_STATUSES_TO_REPEAT],
				[FORM_FIELD.TRIALS]: formFieldsState[FORM_FIELD.TRIALS],
			})
		);
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={text}
			saveBtnTitle={text}
			onSave={async () => {
				await handleUpdateCampaign();
			}}
		>
			<Grid item xs={12}>
				<TextField
					label={<FormattedMessage {...messages.addNumber} />}
					variant={'outlined'}
					onKeyUp={(e) => handleAddNumber(e)}
					onBlur={(e) => handleAddNumber(e)}
					helperText={formFieldErrors[FORM_FIELD.PHONE_NUMBER]}
					error={!!formFieldErrors[FORM_FIELD.PHONE_NUMBER]}
				/>
			</Grid>
			<br />
			<Grid item xs={12}>
				<TextField
					label={<FormattedMessage {...messages.dateTimeLabel} />}
					variant="standard"
					type="datetime-local"
					value={
						convertDateToString(new Date(formFieldsState[FORM_FIELD.FIRST_CALL_TIME])) ||
						convertDateToString(new Date())
					}
					onChange={(e, v) => {
						validateAddNumberForm(convertStringToDate(e.target.value), FORM_FIELD.FIRST_CALL_TIME);
						updateFormStates(convertStringToDate(e.target.value), FORM_FIELD.FIRST_CALL_TIME);
					}}
					helperText={formFieldErrors[FORM_FIELD.FIRST_CALL_TIME]?.errorMessage}
					error={!!formFieldErrors[FORM_FIELD.FIRST_CALL_TIME]?.warning}
				/>
			</Grid>
			<br />
			{Object.keys(formFieldsState[FORM_FIELD.METADATA]).length > 0 && (
				<span className={classes.spanLabel}>{<FormattedMessage {...messages.metaDataLabel} />}</span>
			)}
			{Object.entries(formFieldsState[FORM_FIELD.METADATA] ?? {}).map(([param, value]) => (
				<Grid spacing={2} container key={param} direction="row" alignItems="center">
					<Grid item xs={12}>
						<TextField
							className={classes.defaultFont}
							label={param}
							InputLabelProps={{
								shrink: true,
							}}
							defaultValue={value}
							onChange={(e) => {
								setFormFieldsState({
									...formFieldsState,
									[FORM_FIELD.METADATA]: {
										...formFieldsState[FORM_FIELD.METADATA],
										[param]: e.target.value,
									},
								});
							}}
							variant="standard"
						/>
					</Grid>
				</Grid>
			))}
			<br />
			<Grid item xs={12}>
				<TextField
					value={formFieldsState[FORM_FIELD.REP_CALL_DELAY]}
					error={Boolean(formFieldErrors[FORM_FIELD.REP_CALL_DELAY])}
					helperText={formFieldErrors[FORM_FIELD.REP_CALL_DELAY]}
					fullWidth
					type="number"
					onChange={(e) =>
						Number(e.target.value) > 0 || !e.target.value
							? updateFormStates(Number(e.target.value), FORM_FIELD.REP_CALL_DELAY)
							: null
					}
					label={<FormattedMessage {...messages.repeatCallDelayInMinutes} />}
					variant="standard"
				/>
			</Grid>
			<br />
			<Grid item xs={12}>
				<TextField
					value={formFieldsState[FORM_FIELD.TRIALS] || ''}
					error={Boolean(formFieldErrors[FORM_FIELD.TRIALS])}
					helperText={formFieldErrors[FORM_FIELD.TRIALS]}
					fullWidth
					type="number"
					onChange={(e) =>
						Number(e.target.value) > 0 || !e.target.value
							? updateFormStates(Number(e.target.value), FORM_FIELD.TRIALS)
							: null
					}
					label={<FormattedMessage {...messages.maxCallTrials} />}
					variant="standard"
					disabled
				/>
			</Grid>
			<br />
			<Grid item xs={12}>
				<FreeSoloAutocomplete
					values={formFieldsState[FORM_FIELD.CALL_STATUSES_TO_REPEAT]}
					setValues={(values) => updateFormStates(values, FORM_FIELD.CALL_STATUSES_TO_REPEAT)}
					label="setCallStatuses"
					disabled
				/>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

AddNewNumberModal.propTypes = {
	id: PropTypes.string.isRequired,
	metaDataPattern: PropTypes.object,
};

export default AddNewNumberModal;
