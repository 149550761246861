import { Tooltip } from '@mui/material';
import { cyan, orange } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Condition, Intent, SmartFunction, GlobalStartNode, GroupStartNode, Variable } from '../IconsWithTooltip';

const useStyles = makeStyles((theme) => ({
	nodeHeader: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		maxWidth: 210,
		'& .condition': {
			fill: theme.palette.error.main,
		},
		'& .intent': {
			fill: orange[500],
		},
		'& .smartFunction': {
			fill: 'black',
		},
		'& .variable': {
			fill: cyan[500],
		},
		'& .start': {
			fill: theme.palette.primary.main,
		},
		'& .blackIconColor': {
			fill: orange[500],
		},
		'& svg': {
			margin: 0,
			width: 18,
			height: 18,
		},
	},
	nodeTitle: {
		color: '#4c4c4c',
		flex: (props) => '1 0 ' + props.titleWidth,
		maxWidth: (props) => props.titleWidth,
		wordBreak: 'break-word',
		overflow: 'hidden',
		fontSize: 14,
		fontWeight: (props) => (props.isGroup ? '900' : '500'),
	},
	nodeIconsWrap: {
		textAlign: 'right',
		flex: (props) => '1 0 ' + props.iconsWidth,
		maxWidth: (props) => props.iconsWidth,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		overflow: 'hidden',
	},
	content: {
		flex: '1 0 100%',
		margin: '5px -5px -4px -5px',
		color: '#929292',
		fontStyle: 'italic',
		padding: 5,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		height: 30,
		lineHeight: '16px',
		fontSize: 12,
	},
}));

const NodeHeader = ({ content, icons, isGroup, nodeTitle }) => {
	const _icons = [];
	if (!isGroup) {
		if (icons.hasCondition) {
			_icons.push(<Condition key={1} className="condition" />);
		}
		if (icons.hasIntent) {
			_icons.push(<Intent key={2} className="intent" />);
		}
		if (icons.hasSmartFunction) {
			_icons.push(<SmartFunction key={3} className="smartFunction" />);
		}
		if (icons.hasVariable) {
			_icons.push(<Variable key={4} className="variable" />);
		}
		if (icons.isGlobalStartNode) {
			_icons.push(<GlobalStartNode key={5} className="start" />);
		}
		if (icons.isGroupStartNode) {
			_icons.push(<GroupStartNode key={6} className="start" />);
		}
	} else if (icons.isGlobalStartNode) {
		_icons.push(<span key={4}>&nbsp;&nbsp;</span>);
		_icons.push(<GlobalStartNode key={5} className="start" />);
	}

	const classes = useStyles({
		titleWidth: (_icons.length * 10 - 100) * -1 + '%',
		iconsWidth: _icons.length * 10 + '%',
		isGroup,
	});

	return (
		<div className={classes.nodeHeader}>
			<Tooltip title={nodeTitle} placement={'top'} enterDelay={500} enterNextDelay={500}>
				<span className={classes.nodeTitle}>{nodeTitle}</span>
			</Tooltip>
			<span className={classes.nodeIconsWrap}>{_icons}</span>
			{content && <div className={classes.content}>{content}</div>}
		</div>
	);
};

NodeHeader.propTypes = {
	content: PropTypes.string,
	icons: PropTypes.object,
	isGroup: PropTypes.bool,
	nodeTitle: PropTypes.string,
};

export default NodeHeader;
