/* eslint-disable indent */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Grid, IconButton, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../../intl';
import FormattedDate from '../../../intl/FormattedDate';
import {
	CALL_LIST_STATUS,
	updateCallerNumber,
	updateCampaignName,
	updateTrunkAccount,
} from '../../../redux/outbound/actions';
import { getCallerNumber, getCampaign, getTrunkAccount } from '../../../redux/outbound/selectors';
import { isPhoneNumberInvalid, isTrunkAccountInvalid } from '../../../utils/validation';
import { useStylesCampaignDetail } from './CampaignDetail';
import { setInProgressRounds } from './Campaigns';

const handleUpdateCallerNumber = (id, e, setCallerNumberErr, dispatch) => {
	const number = e.target.value;
	if (isPhoneNumberInvalid(number)) {
		setCallerNumberErr(isPhoneNumberInvalid(number));
		return;
	}
	setCallerNumberErr(null);
	dispatch(updateCallerNumber(id, e.target.value));
};

const handleUpdateTrunkAccount = (id, e, setTrunkAccountErr, dispatch) => {
	const trunkAccount = e.target.value;
	if (isTrunkAccountInvalid(trunkAccount)) {
		setTrunkAccountErr(isTrunkAccountInvalid(trunkAccount));
		return;
	}
	setTrunkAccountErr(null);
	dispatch(updateTrunkAccount(id, trunkAccount));
};

export const countNextRoundTime = (lastRoundFinishedAt, repeatCallDelayInMinutes) => (
	<FormattedDate value={new Date(lastRoundFinishedAt).getTime() + repeatCallDelayInMinutes * 60 * 1000} />
);

const CampaignInfo = ({ callerNumberErr, disableUI, id, setCallerNumberErr, trunkAccountErr, setTrunkAccountErr }) => {
	const classes = useStylesCampaignDetail();
	const [fold, setFold] = useState(false);
	const intl = useIntl();

	const campaign = useSelector(getCampaign(id));
	const callerNumber = useSelector(getCallerNumber(id));
	const trunkAccount = useSelector(getTrunkAccount(id));
	const dispatch = useDispatch();

	const repeatCallsAt = campaign.repeatCallsAt || [];
	const showNextRoundTime =
		campaign.status !== CALL_LIST_STATUS.FINISHED && campaign.lastRoundFinishedAt && isEmpty(repeatCallsAt);

	return (
		<div className={classes.section} style={{ minHeight: 'auto' }}>
			<div
				className={`${classes.sectionHeader} ${classes.cursorPointer}`}
				style={{ marginBottom: fold ? 0 : '15px' }}
				onClick={() => setFold(!fold)}
			>
				<FormattedMessage {...messages.campaign} />
				<IconButton className={classes.foldBtn}>{!fold ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}</IconButton>
			</div>
			{!fold && (
				<Grid spacing={2} container>
					{/* camp name */}
					<Grid item xs={12}>
						<TextField
							key={callerNumber + 'campaignName'}
							label={<FormattedMessage {...messages.campaignName} />}
							value={campaign.name}
							onChange={(e) => dispatch(updateCampaignName(id, e.target.value))}
							disabled={disableUI}
							variant="standard"
						/>
					</Grid>
					{/* twilio / sip */}
					{trunkAccount ? (
						<Grid item xs={12}>
							<TextField
								label={<FormattedMessage {...messages.trunkAccount} />}
								defaultValue={trunkAccount}
								onKeyUp={(e) => handleUpdateTrunkAccount(id, e, setTrunkAccountErr, dispatch)}
								disabled={disableUI && !trunkAccountErr}
								helperText={trunkAccountErr}
								error={!!trunkAccountErr}
								variant="standard"
							/>
						</Grid>
					) : (
						<Grid item xs={6}>
							<TextField
								label={<FormattedMessage {...messages.twilioNumber} />}
								defaultValue={callerNumber}
								onKeyUp={(e) => handleUpdateCallerNumber(id, e, setCallerNumberErr, dispatch)}
								disabled={disableUI && !callerNumberErr}
								helperText={callerNumberErr}
								error={!!callerNumberErr}
								variant="standard"
							/>
						</Grid>
					)}
					{/* status */}
					<Grid item xs={6} className={classes.defaultFont}>
						<FormattedMessage {...messages.status} />
						<br />
						<strong>{setInProgressRounds([campaign], intl)[0].status}</strong>
					</Grid>
					<Grid item xs={6} className={classes.defaultFont}>
						<FormattedMessage {...messages.maxCallTrials} />
						<br />
						<strong>{campaign.maxCallTrials}</strong>
					</Grid>
					<Grid item xs={6} className={classes.defaultFont}>
						<FormattedMessage {...messages[isEmpty(repeatCallsAt) ? 'repeatCallDelayInMinutes' : 'repeatCallsAt']} />
						<br />
						<strong>
							{isEmpty(repeatCallsAt)
								? showNextRoundTime
									? countNextRoundTime(campaign.lastRoundFinishedAt, campaign.repeatCallDelayInMinutes)
									: campaign.repeatCallDelayInMinutes
								: repeatCallsAt.map((repeatCallAt, i) => (
										<div key={'repeatCallAt' + i}>
											<FormattedDate value={repeatCallAt} />
										</div>
								  ))}
						</strong>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

CampaignInfo.propTypes = {
	callerNumberErr: PropTypes.object,
	disableUI: PropTypes.bool,
	id: PropTypes.string,
	setCallerNumberErr: PropTypes.func,
	trunkAccountErr: PropTypes.object,
	setTrunkAccountErr: PropTypes.func,
};

export default CampaignInfo;
