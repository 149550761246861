import { produce } from 'immer';
import { path } from 'ramda';
import { LOCALE } from '../../core/configs/consts';
import { actionTypes, ID } from './actions';

const getDefaultLangCode = () => {
	for (const langCode of Object.values(LOCALE)) {
		if (langCode.includes(navigator.language)) {
			return langCode;
		}
	}

	return LOCALE.EN;
};

const DEFAULT_STATE = {
	locale: getDefaultLangCode(),
};

const intl = produce((state, { type, payload }) => {
	switch (type) {
		case actionTypes.SET_LOCALE:
			state.locale = payload.locale;
			break;
	}
}, DEFAULT_STATE);

export const getLocale = path([ID, 'locale']);

export default intl;
