import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Builder from './components/MaterialDashboard/Builder';
import { ModalRoot } from './components/Modals';
import useAuth from './hooks/auth/useAuth';
import useUpdateEffect from './hooks/useUpdateEffect';
import { translation } from './intl';
import theme from './mui-theme-builder';
import { getLocale } from './redux/intl/reducers';
import { restoreSelectedModelVersion } from './redux/model-table/actions';
import { selectedModelVersionId as selectActiveModelVersionId } from './redux/model-table/selectors';
import { showNotification } from './redux/notification/actions';
import './styles/base.scss';
import './styles/diagram.scss';
import { isSupportedBrowser } from './utils/browser';
import LSM from './utils/LocalStorageManager';

const App = () => {
	const LOCALE = useSelector(getLocale);
	const activeModelVersionId = useSelector(selectActiveModelVersionId);
	const { userId, userEmail, role, isUserLoggedIn } = useAuth();
	const dispatch = useDispatch();

	useEffect(() => {
		if (process.env.DIAGNOSTIC_LOGGING_ENABLED && isUserLoggedIn) {
			Sentry.configureScope((scope) => scope.setUser({ id: userId, email: userEmail }));
			LogRocket.identify(userId, {
				email: userEmail,
				// add any custom user variables below
				role,
			});
		}
	}, [isUserLoggedIn, userId]);

	// Browser support check
	if (!isSupportedBrowser()) {
		dispatch(showNotification(`Your internet browser is not supported! Please open our application in Google Chrome.`));
	}

	// Loads data for active version model ID stored in local storage when the user refreshes the page.
	useEffect(() => {
		const selectedModelVersionId = LSM.getSelectedModelVersionId();
		if (isUserLoggedIn && selectedModelVersionId) {
			dispatch(restoreSelectedModelVersion(selectedModelVersionId));
		}
	}, [isUserLoggedIn]);

	// Synchronizes active model version ID in Redux with the local storage.
	// Ignores first mount because the ID in Redux is always `null` and is filled from local storage.
	useUpdateEffect(() => {
		if (activeModelVersionId) {
			LSM.setSelectedModelVersionId(activeModelVersionId);
		} else {
			LSM.removeSelectedModelVersionId();
		}
	}, [activeModelVersionId]);

	return (
		<ThemeProvider theme={theme}>
			<IntlProvider locale={LOCALE} messages={translation[LOCALE]}>
				<BrowserRouter>
					<ModalRoot />
					<Builder />
				</BrowserRouter>
			</IntlProvider>
		</ThemeProvider>
	);
};

export default App;
