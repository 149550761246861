import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { showPhoneCallRecordingModal, showPhoneCallTranscriptionModal } from '../redux/modal/actions';

const useStyles = makeStyles({
	container: {
		position: 'fixed',
		height: '100vh',
		width: '100vw',
		backgroundColor: '#E4E8EC',
		left: 0,
		top: 0,
		zIndex: '1200',
	},
});

const ExternalPage = () => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const { id, type } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			if (type === 'transcription') {
				dispatch(showPhoneCallTranscriptionModal(id, true));
			} else if (type === 'recording') {
				dispatch(showPhoneCallRecordingModal(id, true));
			}
		} else {
			navigate(-1);
		}
	}, [id, type]);

	return <div className={styles.container} />;
};

export default ExternalPage;
