import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import keycloak from './auth/keycloak';
import { USE_KEYCLOAK } from './core/configs/consts';
import configureStore from './redux/configureStore';
import { getManualChangeVersion, getRawYaml } from './redux/model/selectors';
import LSM from './utils/LocalStorageManager';
import { isBlank } from './utils/validation';

// Sentry & LogRocket initialization
if (process.env.DIAGNOSTIC_LOGGING_ENABLED) {
	Sentry.init({
		dsn: 'https://2d34c0e1b9614496b9ae7e9bc03ee374@o319218.ingest.sentry.io/5305549',
		release: process.env.SENTRY_RELEASE,
		environment: process.env.SENTRY_ENVIRONMENT,
		normalizeDepth: 10,
		maxValueLength: 1000,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
	LogRocket.init('ma3g4l/flow-editor', {
		release: process.env.SENTRY_RELEASE,
		console: { shouldAggregateConsoleErrors: true },
	});
	setupLogRocketReact(LogRocket);
	LogRocket.getSessionURL((sessionUrl) => {
		Sentry.configureScope((scope) => scope.setExtra('sessionUrl', sessionUrl));
	});
}

// Redux store initialization
let lastYamlVersion = 0;
const store = configureStore();
store.subscribe(() => {
	const state = store.getState();
	const rawYaml = getRawYaml(state);
	const manualChangeVersion = getManualChangeVersion(state);

	// Auto-saves model into local storage every time it is updated manually and is not empty.
	if (lastYamlVersion !== manualChangeVersion && !isBlank(rawYaml)) {
		LSM.setAutosaveData(rawYaml);
		lastYamlVersion = manualChangeVersion;
	}
});

// React DOM initialization
const Application = process.env.DIAGNOSTIC_LOGGING_ENABLED ? Sentry.withProfiler(App) : App;
ReactDOM.render(
	USE_KEYCLOAK ? (
		<ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }}>
			<Provider store={store}>
				<Application />
			</Provider>
		</ReactKeycloakProvider>
	) : (
		<Provider store={store}>
			<Application />
		</Provider>
	),
	document.getElementById('root')
);
