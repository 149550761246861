import { produce } from 'immer';
import { append, mergeDeepRight, o, omit, prop, uniq } from 'ramda';
import { combineReducers } from 'redux';
import { ASYNC_ACTION_STATES } from '../../utils/action-utils';
import { actionTypes as authActionTypes } from '../auth/actions';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = {
	entities: {
		models: {},
		versions: {},
		configs: {},
		assets: {},
	},
	isFetchingAssets: false,
	filteredValue: '',
	selectedModelId: '',
	selectedModelVersionId: null,
	extractors: {
		state: ASYNC_ACTION_STATES.READY,
		data: {},
		error: undefined,
	},
	users: [],
	healths: {},
};

const trainingDefaultState = {
	isFetching: {},
	initiallyFetched: [],
};

const optimizeExtractorsData = (extractorsData) => {
	const optimized = {};
	for (const func of extractorsData) {
		optimized[func.name] = func.parameters;
	}
	return optimized;
};

const modelTable = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.UPDATE_DASHBOARD_FILTERED_VALUE: {
			return {
				...state,
				filteredValue: payload.value,
			};
		}
		case actionTypes.EDIT_MODEL_USER_ROLE_SUCCESS: {
			return mergeDeepRight(state, payload);
		}
		case actionTypes.FETCH_MODEL_SUCCESS: {
			const newState = mergeDeepRight(state, payload);
			newState.entities.assets = payload.entities.assets ?? {};
			return newState;
		}
		case actionTypes.SELECT_MODEL:
			return {
				...state,
				selectedModelId: payload.id,
				selectedModelVersionId: null,
			};
		case actionTypes.FETCH_MODEL_VERSION_SUCCESS: {
			const fetchedModelVersion = payload.entities.versions?.[payload.result];
			return mergeDeepRight(state, {
				entities: payload.entities,
				selectedModelId: fetchedModelVersion.model,
			});
		}
		case actionTypes.SELECT_MODEL_VERSION: {
			return {
				...state,
				selectedModelVersionId: payload.id,
			};
		}
		case actionTypes.CREATE_MODEL_SUCCESS: {
			const createdModelId = payload.result;
			return {
				...state,
				entities: mergeDeepRight(state.entities, payload.entities),
				selectedModelId: createdModelId,
				selectedModelVersionId: null,
			};
		}
		case actionTypes.UPDATE_MODEL_SUCCESS: {
			return {
				...state,
				entities: mergeDeepRight(state.entities, payload.entities),
			};
		}
		case actionTypes.DELETE_MODEL_SUCCESS:
			const deletedModelId = payload.result;

			Reflect.deleteProperty(state.entities.models, deletedModelId);
			if (state.selectedModelId === deletedModelId) {
				state.selectedModelId = null;
				state.selectedModelVersionId = null;
			}
			break;
		case actionTypes.DELETE_MODEL_VERSION_SUCCESS: {
			// delete selection
			const deletedVersionId = payload.result;
			if (state.selectedModelVersionId === deletedVersionId) {
				state.selectedModelId = null;
				state.selectedModelVersionId = null;
			}

			// delete actual entity
			const entities = state.entities;
			Reflect.deleteProperty(entities.versions, deletedVersionId);

			// delete entity ID from the parent model
			const modelId = payload.entities.versions[deletedVersionId].model;
			const model = entities.models[modelId];
			if (model) {
				model.versions = model.versions.filter((v) => v !== deletedVersionId);
			}
			break;
		}
		// TODO: Reducers would be much simpler if we do MODELS_INFO_SUCCESS (refetch entities after crud operation)
		case actionTypes.UPDATE_MODEL_VERSION_SUCCESS:
		case actionTypes.CREATE_MODEL_VERSION_SUCCESS:
			const modelVersion = payload.entities.versions[payload.result];

			const modelId = modelVersion.model;
			const prevModelData = state.entities.models?.[modelId];

			return {
				...state,
				entities: {
					...mergeDeepRight(state.entities, payload.entities),
					models: {
						...state.entities.models,
						[modelId]: {
							...prevModelData,
							versions: o(uniq, append(payload.result))(prevModelData?.versions),
						},
					},
				},
				selectedModelVersionId: payload.result,
			};
		case actionTypes.MODELS_INFO_SUCCESS: {
			return {
				...state,
				entities: mergeDeepRight(state.entities, payload.entities),
			};
		}
		case actionTypes.VERSIONS_TRAINED_STATUS_SUCCESS: {
			return {
				...state,
				entities: mergeDeepRight(state.entities, payload.entities),
			};
		}
		case actionTypes.CREATE_CONFIG_SUCCESS:
		case actionTypes.TRAIN_CONFIG_SUCCESS:
			const config = payload.entities.configs[payload.result];
			const modelVersionId = config.modelVersion;
			const prevModelVersion = state.entities.versions?.[modelVersionId];

			return {
				...state,
				entities: {
					...mergeDeepRight(state.entities, payload.entities),
					versions: {
						...state.entities.versions,
						[modelVersionId]: {
							...prevModelVersion,
							configs: uniq([...prevModelVersion?.configs, payload.result]),
						},
					},
					configs: {
						...state.entities.configs,
						[payload.result]: {
							...state.entities.configs[payload.result],
							...config,
							trained: type === actionTypes.TRAIN_CONFIG_SUCCESS,
						},
					},
				},
			};
		case actionTypes.DELETE_CONFIG_SUCCESS:
			const res = payload.data.deleteConfig;
			state.entities.configs = omit([res.id], state.entities.configs) || {};
			state.entities.versions[res.modelVersion.id].configs = [];
			break;
		case actionTypes.FETCH_EXTRACTORS_DATA_PENDING:
			return {
				...state,
				extractors: {
					...state.extractors,
					state: ASYNC_ACTION_STATES.PENDING,
					error: undefined,
				},
			};
		case actionTypes.FETCH_EXTRACTORS_DATA_SUCCESS:
			return {
				...state,
				extractors: {
					state: ASYNC_ACTION_STATES.SUCCESS,
					data: optimizeExtractorsData(payload.data.extractors),
					error: undefined,
				},
			};
		case actionTypes.FETCH_EXTRACTORS_DATA_FAIL:
			return {
				...state,
				extractors: {
					state: ASYNC_ACTION_STATES.FAIL,
					error: payload.errors.map(prop('message')).join('; '),
					data: [],
				},
			};
		case actionTypes.DELETE_IMAGE_SUCCESS:
			const deletedImageId = payload.result;

			const models = state.entities.models;
			for (const model of Object.values(models)) {
				if (model.images?.includes(deletedImageId)) {
					model.images = model.images.filter((i) => i !== deletedImageId);
				}
			}
			Reflect.deleteProperty(state.entities.images, deletedImageId);
			break;
		case actionTypes.DELETE_MODEL_ASSET_SUCCESS:
			const deletedAssetId = payload.result;
			Reflect.deleteProperty(state.entities.assets, deletedAssetId);
			break;
		case actionTypes.SET_IS_FETCHING_ASSETS:
			state.isFetchingAssets = payload.value;
			break;
		case actionTypes.UPLOAD_MODEL_ASSET_SUCCESS:
			return {
				...state,
				isFetchingAssets: false,
				entities: {
					...state.entities,
					assets: mergeDeepRight(state.entities.assets, { [payload.id]: payload }),
				},
			};
		case actionTypes.CREATE_MODEL_ASSET_SUCCESS:
		case actionTypes.UPDATE_MODEL_ASSET_SUCCESS:
			const newState = mergeDeepRight(state, payload);
			newState.isFetchingAssets = false;
			return newState;
		case actionTypes.FETCH_USERS_SUCCESS:
			return {
				...state,
				users: payload?.data?.users ?? [],
			};
		case actionTypes.FETCH_APP_HEALTHS_SUCCESS:
			state.healths = payload?.data?.healths ?? DEFAULT_STATE.healths;
			break;
		default:
			return state;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: modelTable,
	fetchingUsers: fetching([
		actionTypes.FETCH_USERS_PENDING,
		actionTypes.FETCH_USERS_SUCCESS,
		actionTypes.FETCH_USERS_FAIL,
	]),
	fetchingCreateModel: fetching([
		actionTypes.CREATE_MODEL_PENDING,
		actionTypes.CREATE_MODEL_SUCCESS,
		actionTypes.CREATE_MODEL_FAIL,
	]),
	fetchingUpdateModel: fetching([
		actionTypes.UPDATE_MODEL_PENDING,
		actionTypes.UPDATE_MODEL_SUCCESS,
		actionTypes.UPDATE_MODEL_FAIL,
	]),
	fetchingModel: fetching([
		actionTypes.FETCH_MODEL_PENDING,
		actionTypes.FETCH_MODEL_SUCCESS,
		actionTypes.FETCH_MODEL_FAIL,
	]),
	fetchingModelsInfo: fetching([
		actionTypes.MODELS_INFO_PENDING,
		actionTypes.MODELS_INFO_SUCCESS,
		actionTypes.MODELS_INFO_FAIL,
	]),
	fetchingVersionsTrainedStatus: fetching([
		actionTypes.VERSIONS_TRAINED_STATUS_PENDING,
		actionTypes.VERSIONS_TRAINED_STATUS_SUCCESS,
		actionTypes.VERSIONS_TRAINED_STATUS_FAIL,
	]),
	fetchingCreateModelVersion: fetching([
		actionTypes.CREATE_MODEL_VERSION_PENDING,
		actionTypes.CREATE_MODEL_VERSION_SUCCESS,
		actionTypes.CREATE_MODEL_VERSION_FAIL,
	]),
	fetchingModelVersion: fetching([
		actionTypes.FETCH_MODEL_VERSION_PENDING,
		actionTypes.FETCH_MODEL_VERSION_SUCCESS,
		actionTypes.FETCH_MODEL_VERSION_FAIL,
	]),
	fetchingDeleteModel: fetching([
		actionTypes.DELETE_MODEL_PENDING,
		actionTypes.DELETE_MODEL_SUCCESS,
		actionTypes.DELETE_MODEL_FAIL,
	]),
	fetchingDeleteModelVersion: fetching([
		actionTypes.DELETE_MODEL_VERSION_PENDING,
		actionTypes.DELETE_MODEL_VERSION_SUCCESS,
		actionTypes.DELETE_MODEL_VERSION_FAIL,
	]),
	fetchingValidateModelVersion: fetching([
		actionTypes.VALIDATE_MODEL_VERSION_PENDING,
		actionTypes.VALIDATE_MODEL_VERSION_SUCCESS,
		actionTypes.VALIDATE_MODEL_VERSION_FAIL,
	]),
	fetchingCreateConfig: fetching([
		actionTypes.CREATE_CONFIG_PENDING,
		actionTypes.CREATE_CONFIG_SUCCESS,
		actionTypes.CREATE_CONFIG_FAIL,
	]),
	fetchingTrainConfig: (state = trainingDefaultState, action) => {
		const { type, meta } = action;
		const { requestId, versionId } = meta ?? {};

		switch (type) {
			case actionTypes.TRAIN_CONFIG_PENDING:
				return {
					...state,
					isFetching: {
						...state.isFetching,
						[requestId]: versionId,
					},
				};
			case actionTypes.TRAIN_CONFIG_SUCCESS:
				return {
					...state,
					isFetching: omit([requestId], state.isFetching),
					initiallyFetched: uniq([...state.initiallyFetched, versionId]),
				};
			case actionTypes.TRAIN_CONFIG_FAIL:
				return {
					...state,
					isFetching: omit([requestId], state.isFetching),
				};
			default:
				return state;
		}
	},
	fetchingModelRolesChange: fetching([
		actionTypes.EDIT_MODEL_USER_ROLE_PENDING,
		actionTypes.EDIT_MODEL_USER_ROLE_SUCCESS,
		actionTypes.EDIT_MODEL_USER_ROLE_FAIL,
	]),
});
