import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { deleteModel, deleteModelVersion } from '../../redux/model-table/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveModelVersionsModal = ({ callback, modelVersions, removeModelId }) => {
	const dispatch = useDispatch();

	const handleDelete = async () => {
		if (removeModelId) {
			await dispatch(deleteModel(removeModelId));
		} else {
			for (const id of modelVersions) {
				await dispatch(deleteModelVersion(id));
			}
		}
		callback([]);
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.deleteModelVersionsTitle} />}
			saveBtnTitle={<FormattedMessage {...messages.deleteModelVersionsTitle} />}
			onSave={handleDelete}
			saveOnEnterKey
		>
			<Box>
				<FormattedMessage
					{...messages[removeModelId ? 'deleteAllModelVersionsQuestion' : 'deleteModelVersionsQuestion']}
				/>
			</Box>
		</SaveCloseModalTemplate>
	);
};

RemoveModelVersionsModal.propTypes = {
	callback: PropTypes.func,
	modelVersions: PropTypes.array.isRequired,
	removeModelId: PropTypes.string,
};

export default RemoveModelVersionsModal;
