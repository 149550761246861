import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { deleteCampaigns } from '../../redux/outbound/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveCampaignsModal = ({ list }) => {
	const dispatch = useDispatch();
	const text = <FormattedMessage {...messages[list.length < 2 ? 'deleteCampaign' : 'deleteCampaigns']} />;

	return (
		<SaveCloseModalTemplate
			title={text}
			saveBtnTitle={text}
			onSave={async () => {
				const deleteResp = await dispatch(deleteCampaigns(list));
				if (deleteResp) {
					dispatch(hideModal());
				}
			}}
		>
			<FormattedMessage {...messages.deleteCampaignsQuestions} />
		</SaveCloseModalTemplate>
	);
};

RemoveCampaignsModal.propTypes = {
	list: PropTypes.array.isRequired,
};

export default RemoveCampaignsModal;
