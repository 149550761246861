import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import modals from '../../constants/modals';
import useAuth from '../../hooks/auth';
import { getModalProps, getModalType } from '../../redux/modal/reducers';

const ModalRoot = () => {
	const modalType = useSelector(getModalType);
	const modalProps = useSelector(getModalProps, shallowEqual);
	const { isUserLoggedIn } = useAuth();
	const Modal = modals[modalType] || null;
	// Without this check, Modals can remain active after logout
	if (isUserLoggedIn) {
		return Modal ? <Modal {...modalProps} /> : null;
	} else {
		return null;
	}
};

export default ModalRoot;
