import { gql } from 'graphql-tag';
import { PHONE_NUMBER_FIELDS } from '../voice';

const CAMPAIGN_FIELDS = gql`
	fragment CAMPAIGN_FIELDS on CallList {
		createdAt
		lastRoundFinishedAt
		id
		maxParallelCalls
		name
		modelId
		status
		phoneNumberTwilio
		twilioFlowId
		trunkAccount
		maxCallTrials
		callStatusNotifier
		repeatCallDelayInMinutes
		callStatusesToRepeat
		repeatCallsAt
		finishedRounds
		messageList {
			serviceProvider
			defaultMessage
			requestKey
			statusesToMessage
			customMessages
		}
		campaignType
	}
`;

const PHONE_CALL_FIELDS = gql`
	fragment PHONE_CALL_FIELDS on PhoneCall {
		id
		callId
		calledAt
		finishedAt
		error
		metaData
		phoneNumber
		status
		trials
		messageSent
		callList
		firstCallTime
		nextCallTime
	}
`;

export const createCampaign = gql`
	mutation createCallList(
		$name: String!
		$modelId: ID
		$twilioFlowId: String
		$phoneNumberTwilio: String
		$trunkAccount: String
		$maxParallelCalls: Int
		$maxCallTrials: Int
		$repeatCallDelayInMinutes: Int
		$callStatusNotifier: CallStatusNotifierEnum
		$phoneCalls: [PhoneCallInput!]
		$callStatusesToRepeat: [String]
		$repeatCallsAt: [DateTime]
		$messageList: MessageListInput
		$campaignType: CallListTypeEnum!
	) {
		callLists: createCallList(
			name: $name
			modelId: $modelId
			twilioFlowId: $twilioFlowId
			phoneNumberTwilio: $phoneNumberTwilio
			trunkAccount: $trunkAccount
			maxParallelCalls: $maxParallelCalls
			maxCallTrials: $maxCallTrials
			repeatCallDelayInMinutes: $repeatCallDelayInMinutes
			callStatusNotifier: $callStatusNotifier
			phoneCalls: $phoneCalls
			callStatusesToRepeat: $callStatusesToRepeat
			repeatCallsAt: $repeatCallsAt
			messageList: $messageList
			campaignType: $campaignType
		) {
			...CAMPAIGN_FIELDS
			phoneCalls {
				phoneNumber
				metaData
				status
				callList
			}
		}
	}
	${CAMPAIGN_FIELDS}
`;

export const updateCampaign = gql`
	mutation updateCallList(
		$id: ID!
		$name: String!
		$modelId: ID!
		$createdAt: DateTime
		$lastRoundFinishedAt: DateTime
		$twilioFlowId: String
		$phoneNumberTwilio: String
		$trunkAccount: String
		$maxParallelCalls: Int
		$maxCallTrials: Int
		$repeatCallDelayInMinutes: Int
		$callStatusNotifier: CallStatusNotifierEnum
		$phoneCalls: [PhoneCallInput!]
		$status: CallListStatusEnum
		$callStatusesToRepeat: [String]
		$repeatCallsAt: [DateTime]
		$messageList: MessageListInput
	) {
		callLists: updateCallList(
			id: $id
			name: $name
			modelId: $modelId
			createdAt: $createdAt
			lastRoundFinishedAt: $lastRoundFinishedAt
			twilioFlowId: $twilioFlowId
			phoneNumberTwilio: $phoneNumberTwilio
			trunkAccount: $trunkAccount
			maxParallelCalls: $maxParallelCalls
			maxCallTrials: $maxCallTrials
			repeatCallDelayInMinutes: $repeatCallDelayInMinutes
			callStatusNotifier: $callStatusNotifier
			phoneCalls: $phoneCalls
			status: $status
			callStatusesToRepeat: $callStatusesToRepeat
			repeatCallsAt: $repeatCallsAt
			messageList: $messageList
		) {
			...CAMPAIGN_FIELDS
			# Can't use fragment because the mutation works with PhoneCallInput and not PhoneCall
			phoneCalls {
				id
				callId
				calledAt
				finishedAt
				error
				metaData
				phoneNumber
				status
				trials
				messageSent
				callList
			}
		}
	}
	${CAMPAIGN_FIELDS}
`;

export const updateCampaignStatusQL = gql`
	mutation updateCallList($id: ID!, $status: CallListStatusEnum) {
		callLists: updateCallList(id: $id, status: $status) {
			id
			status
		}
	}
`;

export const deleteCampaigns = gql`
	mutation deleteCallLists($ids: [ID!]!) {
		callLists: deleteCallLists(ids: $ids) {
			ids
		}
	}
`;

export const getCampaigns = gql`
	query callLists($modelId: ID!) {
		callLists(modelId: $modelId) {
			...CAMPAIGN_FIELDS
		}
	}
	${CAMPAIGN_FIELDS}
`;

export const getCampaign = gql`
	query callList($id: ID!) {
		callLists: callList(id: $id) {
			...CAMPAIGN_FIELDS
			phoneCalls {
			...PHONE_CALL_FIELDS
			}
		}
	}
	${CAMPAIGN_FIELDS}, ${PHONE_CALL_FIELDS}
`;

export const getConversation = gql`
	query conversation($id: ID!) {
		conversation(conversationId: $id) {
			id
			transcriptions {
				author
				text
			}
		}
	}
`;

export const getAvailablePhoneNumbers = gql`
	query availablePhoneNumbers($modelId: ID!) {
		availablePhoneNumbers(modelId: $modelId) {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;

export const getCallStatusesQl = gql`
	query {
		__type(name: "CallStatuses") {
			enumValues {
				name
			}
		}
	}
`;
