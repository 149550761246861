import * as requests from '../../core/requests';
import { buildConfusionMatrixQuery, abortConfusionMatrixQuery } from '../../graphql/confusion-matrix';
import { createActionsMap } from '../../utils/action-utils';
import { selectedModelVersionId } from '../model-table/selectors';
import { getRawYaml } from '../model/selectors';
import { psf } from '../utils';
import { ID } from './selectors';

export const actionTypes = createActionsMap(ID, [...psf('MATRIX'), ...psf('CACHED_MATRIX'), ...psf('ABORT_MATRIX')]);

export const generateConfusionMatrixForSelectedModel = (tryCacheOnly = false, ignoreGenerics = false) => (
	dispatch,
	getState
) => {
	const state = getState();
	if (!selectedModelVersionId(state)) {
		return;
	}

	return dispatch(
		requests.qlAuthRequest(
			[
				tryCacheOnly ? actionTypes.CACHED_MATRIX_PENDING : actionTypes.MATRIX_PENDING,
				{
					type: tryCacheOnly ? actionTypes.CACHED_MATRIX_SUCCESS : actionTypes.MATRIX_SUCCESS,
					payload: async (action, state, response) => {
						const json = await response.json();
						return json.data.buildConfusionMatrix;
					},
				},
				tryCacheOnly ? actionTypes.CACHED_MATRIX_FAIL : actionTypes.MATRIX_FAIL,
			],
			{
				query: buildConfusionMatrixQuery,
				variables: { rawYaml: getRawYaml(state), tryCacheOnly: tryCacheOnly, ignoreGenerics: ignoreGenerics },
			}
		)
	);
};

export const abortConfusionMatrixForSelectedModel = () => (dispatch, getState) => {
	const state = getState();
	if (!selectedModelVersionId(state)) {
		return;
	}

	return dispatch(
		requests.qlAuthRequest(
			[actionTypes.ABORT_MATRIX_PENDING, actionTypes.ABORT_MATRIX_SUCCESS, actionTypes.ABORT_MATRIX_FAIL],
			{
				query: abortConfusionMatrixQuery,
				variables: { rawYaml: getRawYaml(state), tryCacheOnly: false, ignoreGenerics: false },
			}
		)
	);
};
