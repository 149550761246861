import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { amber } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const iconType = {
	INFO: InfoIcon,
	WARNING: WarningIcon,
	ERROR: ErrorIcon,
};

const useStyles = makeStyles((theme) => ({
	INFO: {
		backgroundColor: theme.palette.primary.main + ' !important',
	},
	WARNING: {
		backgroundColor: amber[700] + ' !important',
	},
	ERROR: {
		backgroundColor: theme.palette.error.dark + ' !important',
	},
	wrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		flexWrap: 'nowrap',
	},
	icon: {
		fontSize: 20,
		margin: 0,
	},
	iconType: {
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
	message: {
		display: 'flex',
		alignItems: 'flex-start',
	},
	close: {
		padding: 7,
	},
	messageItem: {
		marginBottom: '1em',
	},
}));

const formatMessage = (message, classes) => {
	const messages = message.split('\n');
	if (messages.length === 1) {
		return message;
	}

	return (
		<ol>
			{messages.map((m) => (
				<li key={m} className={classes.messageItem}>
					{m}
				</li>
			))}
		</ol>
	);
};

const SnackbarContentWrapper = React.forwardRef((props, ref) => {
	const classes = useStyles();
	const { className, message, onClose, type, ...other } = props;
	const Icon = iconType[type];

	return (
		<SnackbarContent
			ref={ref}
			className={clsx(classes[type], className, classes.wrapper)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconType)} /> {formatMessage(message, classes)}
				</span>
			}
			action={[
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose} className={classes.close}>
					<CloseIcon className={classes.icon} />
				</IconButton>,
			]}
			{...other}
		/>
	);
});

SnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	type: PropTypes.string,
};

export default SnackbarContentWrapper;
