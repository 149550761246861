import { Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { fetchOrganizations } from '../../redux/auth/actions';
import { getOrganizations } from '../../redux/auth/selectors';
import { hideModal } from '../../redux/modal/actions';
import { createSipAccount, updateSipAccount } from '../../redux/sip/actions';
import { validateContainsString, validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const PROTOCOLS = ['TLS', 'UDP', 'TCP'];

const CreateSipAccountModal = ({ edit, sipAccount }) => {
	const organizations = useSelector(getOrganizations);

	const [domain, setDomain] = useState('');
	const [domainError, setDomainError] = useState(null);

	const [registration, setRegistration] = useState(true);

	const [registral, setRegistral] = useState('sip:');
	const [registralError, setRegistralError] = useState(null);

	const [protocol, setProtocol] = useState(PROTOCOLS[0]);

	const [username, setUsername] = useState('');
	const [usernameError, setUsernameError] = useState(null);

	const [password, setPassword] = useState('');
	const [passwordError, setPasswordError] = useState(null);

	const [srtp, setSrtp] = useState(2);
	const [organizationId, setOrganizationId] = useState(organizations[0]?.id);
	// This is the default value in voice-connector and one that it filters by
	const [voiceConnectorInstance, setVoiceConnectorInstance] = useState('not in use');

	const dispatch = useDispatch();

	useEffect(() => {
		if (edit) {
			const {
				domain,
				registration,
				registral,
				protocol,
				username,
				password,
				srtp,
				organizationId,
				voiceConnectorInstance,
			} = sipAccount;

			setDomain(domain);
			setRegistration(registration);
			setRegistral(registral);
			setProtocol(protocol);
			setUsername(username);
			setPassword(password);
			setSrtp(srtp);
			setOrganizationId(organizationId);
			setVoiceConnectorInstance(voiceConnectorInstance);
		}
	}, [edit, sipAccount]);

	useEffect(() => {
		dispatch(fetchOrganizations());
	}, []);

	const handleSubmit = async () => {
		const domainError = validateIsEmpty(domain);
		if (registration) {
			const registralError =
				validateIsEmpty(registral) || (registration ? validateContainsString(registral, 'sip:') : false);
			const usernameError = validateIsEmpty(username);
			const passwordError = validateIsEmpty(password);
			if (registralError || usernameError || passwordError) {
				setRegistralError(registralError);
				setUsernameError(usernameError);
				setPasswordError(passwordError);
				return;
			}
		}
		if (domainError) {
			setDomainError(domainError);
			return;
		}

		const account = {
			domain,
			registration,
			registral,
			protocol,
			username,
			srtp,
			password,
			voiceConnectorInstance,
			organizationId,
		};

		if (edit) {
			dispatch(updateSipAccount({ ...account, accountUri: sipAccount.accountUri }));
		} else {
			dispatch(createSipAccount(account));
		}

		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={
					edit ? (
						<FormattedMessage {...messages.updateSipAccount} />
					) : (
						<FormattedMessage {...messages.createSipAccount} />
					)
				}
				saveBtnTitle={
					edit ? (
						<FormattedMessage {...messages.updateSipAccount} />
					) : (
						<FormattedMessage {...messages.createSipAccount} />
					)
				}
				onSave={handleSubmit}
			>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={12}>
						<TextField
							autoFocus
							value={domain}
							error={!!domainError}
							helperText={domainError}
							fullWidth
							onChange={(e) => {
								setDomain(e.target.value);
								setDomainError(validateIsEmpty(e.target.value));
							}}
							label={<FormattedMessage {...messages.domain} />}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							autoFocus
							value={registral}
							error={!!registralError}
							helperText={<FormattedMessage {...messages.registralInfo} /> || registralError}
							fullWidth
							onChange={(e) => {
								setRegistral(e.target.value);
								if (registration) setRegistralError(validateIsEmpty(e.target.value));
							}}
							label={<FormattedMessage {...messages.registral} />}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={4}>
						<InputLabel>
							<FormattedMessage {...messages.protocol} />
						</InputLabel>
						<Select
							value={protocol}
							onChange={(e) => {
								setProtocol(e.target.value);
							}}
						>
							{PROTOCOLS.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid item xs={4}>
						<TextField
							autoFocus
							value={srtp}
							fullWidth
							type="number"
							inputProps={{ min: 0 }}
							onChange={(e) => {
								setSrtp(e.target.value);
							}}
							label={<FormattedMessage {...messages.srtp} />}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={4}>
						<FormControlLabel
							control={<Checkbox checked={registration} onChange={(e) => setRegistration(event.target.checked)} />}
							label={<FormattedMessage {...messages.registration} />}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							autoFocus
							error={!!usernameError}
							helperText={usernameError}
							value={username}
							fullWidth
							onChange={(e) => {
								setUsername(e.target.value);
								if (registration) setUsernameError(validateIsEmpty(e.target.value));
							}}
							label={<FormattedMessage {...messages.username} />}
							variant="standard"
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							autoFocus
							type="password"
							error={!!passwordError}
							helperText={passwordError}
							value={password}
							fullWidth
							onChange={(e) => {
								setPassword(e.target.value);
								if (registration) setPasswordError(validateIsEmpty(e.target.value));
							}}
							label={<FormattedMessage {...messages.password} />}
							variant="standard"
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							autoFocus
							value={voiceConnectorInstance}
							fullWidth
							onChange={(e) => {
								setVoiceConnectorInstance(e.target.value);
							}}
							label={<FormattedMessage {...messages.voiceConnectorInstance} />}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12}>
						<InputLabel>
							<FormattedMessage {...messages.organizationId} />
						</InputLabel>
						<Select
							value={organizationId}
							onChange={(e) => {
								setOrganizationId(e.target.value);
							}}
						>
							{organizations.map(({ name, id }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};
CreateSipAccountModal.propTypes = {
	edit: PropTypes.boolean,
	sipAccount: PropTypes.object,
};
export default CreateSipAccountModal;
