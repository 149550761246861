export const sttProviders = ['GOOGLE', 'AZURE'];
export const ttsProviders = ['GOOGLE', 'AZURE'];

export const voiceNameOptions = {
	cs: [
		{
			label: 'Vlasta',
			value: 'cs-CZ-VlastaNeural',
			gender: 'female',
			localeCode: 'cs-CZ',
		},
		{
			label: 'Antonin',
			value: 'cs-CZ-AntoninNeural',
			gender: 'male',
			localeCode: 'cs-CZ',
		},
	],
	en: [
		{
			label: 'Natasha (Australia)',
			value: 'en-AU-NatashaNeural',
			gender: 'female',
			localeCode: 'en-AU',
		},
		{
			label: 'William (Australia)',
			value: 'en-AU-WilliamNeural',
			gender: 'male',
			localeCode: 'en-AU',
		},
		{
			label: 'Clara (Canada)',
			value: 'en-CA-ClaraNeural',
			gender: 'female',
			localeCode: 'en-CA',
		},
		{
			label: 'Liam (Canada)',
			value: 'en-CA-LiamNeural',
			gender: 'male',
			localeCode: 'en-CA',
		},
		{
			label: 'Neerja (India)',
			value: 'en-IN-NeerjaNeural',
			gender: 'female',
			localeCode: 'en-IN',
		},
		{
			label: 'Prabhat (India)',
			value: 'en-IN-PrabhatNeural',
			gender: 'male',
			localeCode: 'en-IN',
		},
		{
			label: 'Emily (Ireland)',
			value: 'en-IE-EmilyNeural',
			gender: 'female',
			localeCode: 'en-IE',
		},
		{
			label: 'Connor (Ireland)',
			value: 'en-IE-ConnorNeural',
			gender: 'male',
			localeCode: 'en-IE',
		},
		{
			label: 'Rosa (Filipines)',
			value: 'en-PH-RosaNeural',
			gender: 'female',
			localeCode: 'en-PH',
		},
		{
			label: 'James (Filipines)',
			value: 'en-PH-JamesNeural',
			gender: 'male',
			localeCode: 'en-PH',
		},
		{
			label: 'Libby (UK)',
			value: 'en-GB-LibbyNeural',
			gender: 'female',
			localeCode: 'en-GB',
		},
		{
			label: 'Mia (UK)',
			value: 'en-GB-MiaNeural',
			gender: 'female',
			localeCode: 'en-GB',
		},
		{
			label: 'Ryan (UK)',
			value: 'en-GB-RyanNeural',
			gender: 'male',
			localeCode: 'en-GB',
		},
		{
			label: 'Aria (US)',
			value: 'en-US-AriaNeural',
			gender: 'female',
			localeCode: 'en-US',
		},
		{
			label: 'Jenny (US)',
			value: 'en-US-JennyNeural',
			gender: 'female',
			localeCode: 'en-US',
		},
		{
			label: 'Guy (US)',
			value: 'en-US-GuyNeural',
			gender: 'male',
			localeCode: 'en-US',
		},
	],
	pl: [
		{
			label: 'Agnieszka',
			value: 'pl-PL-AgnieszkaNeural',
			gender: 'female',
			localeCode: 'pl-PL',
		},
		{
			label: 'Zofia',
			value: 'pl-PL-ZofiaNeural',
			gender: 'female',
			localeCode: 'pl-PL',
		},
		{
			label: 'Marek',
			value: 'pl-PL-MarekNeural',
			gender: 'male',
			localeCode: 'pl-PL',
		},
	],
	sk: [
		{
			label: 'Viktoria',
			value: 'sk-SK-ViktoriaNeural',
			gender: 'female',
			localeCode: 'sk-SK',
		},
		{
			label: 'Lukas',
			value: 'sk-SK-LukasNeural',
			gender: 'male',
			localeCode: 'sk-SK',
		},
	],
	de: [
		{
			label: 'Ingrid (Austria)',
			value: 'de-AT-IngridNeural',
			gender: 'female',
			localeCode: 'de-AT',
		},
		{
			label: 'Jonas (Austria)',
			value: 'de-AT-JonasNeural',
			gender: 'male',
			localeCode: 'de-AT',
		},
		{
			label: 'Katja (Germany)',
			value: 'de-DE-KatjaNeural',
			gender: 'female',
			localeCode: 'de-DE',
		},
		{
			label: 'Conrad (Germany)',
			value: 'de-DE-ConradNeural',
			gender: 'male',
			localeCode: 'de-DE',
		},
		{
			label: 'Leni (Switzerland)',
			value: 'de-CH-LeniNeural',
			gender: 'female',
			localeCode: 'de-CH',
		},
		{
			label: 'Jan (Switzerland)',
			value: 'de-CH-JanNeural',
			gender: 'male',
			localeCode: 'de-CH',
		},
	],
	ru: [
		{
			label: 'Dariya',
			value: 'ru-RU-DariyaNeural',
			gender: 'female',
			localeCode: 'ru-RU',
		},
		{
			label: 'Svetlana',
			value: 'ru-RU-SvetlanaNeural',
			gender: 'female',
			localeCode: 'ru-RU',
		},
		{
			label: 'Dmitry',
			value: 'ru-RU-DmitryNeural',
			gender: 'male',
			localeCode: 'ru-RU',
		},
	],
};
