import { path } from 'ramda';
import { ID } from './actions';

export const isFetchingOrganizations = path([ID, 'fetchOrganizations', 'isFetching']);
export const initiallyFetchedOrganizations = path([ID, 'fetchOrganizations', 'initiallyFetched']);

export const isAssigningPhoneNumber = path([ID, 'assignPhoneNumberToOrganization', 'isFetching']);
export const initiallyAssignedPhoneNumber = path([ID, 'assignPhoneNumberToOrganization', 'initiallyFetched']);

export const isUnassigningPhoneNumber = path([ID, 'unassignPhoneNumberFromOrganization', 'isFetching']);
export const initiallyUnassignedPhoneNumber = path([ID, 'unassignPhoneNumberFromOrganization', 'initiallyFetched']);

export const isCreatingUser = path([ID, 'createUser', 'isFetching']);
export const initiallyCreatedUser = path([ID, 'createUser', 'initiallyFetched']);

export const isUpdatingUser = path([ID, 'updateUser', 'isFetching']);
export const initiallyUpdatedUser = path([ID, 'updateUser', 'initiallyFetched']);
