import { Card } from '@mui/material/';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { groupWith, prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { fetchConversation } from '../../redux/outbound/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	card: {
		width: '300px',
		margin: '10px 20px 10px auto',
		borderRadius: '15px 0px 15px 15px ',
		fontSize: '14px',
		background: theme.palette.primary.main,
		color: '#fff',
		padding: '10px',
		textAlign: 'left',
		position: 'relative',
		overflow: 'inherit',
		float: 'right',
		'&::before': {
			content: '""',
			width: '0px',
			height: '0px',
			position: 'absolute',
			borderLeft: '10px solid ' + theme.palette.primary.main,
			borderRight: '10px solid transparent',
			borderTop: '10px solid ' + theme.palette.primary.main,
			borderBottom: '15px solid transparent',
			right: '-7px',
			top: '0px',
		},
	},
	left: {
		borderRadius: '0px 15px 15px 15px ',
		background: grey[600],
		margin: '10px auto 10px 20px',
		float: 'left',
		'&::before': {
			borderLeft: '10px solid transparent',
			borderRight: '10px solid ' + grey[600],
			borderTop: '10px solid ' + grey[600],
			left: '-7px',
		},
	},
	defaultFont: { fontSize: '13px', color: grey[900] },
}));

const groupTranscriptionsByAuthor = (transcriptions) => {
	if (isEmpty(transcriptions)) {
		return [];
	}

	const areAuthorsSame = (t1, t2) => t1.author === t2.author;
	return groupWith(areAuthorsSame, transcriptions).map((ts) => ({
		author: ts[0].author,
		text: ts.map(prop('text')).join('<br />'),
	}));
};

const PhoneCallTranscriptionModal = ({ conversationId, isOpenedFromExternal }) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const [conversation, setConversation] = useState(null);

	useEffect(() => {
		const errorHandler = (e) => {
			console.error(e);
			Sentry.captureException(e, { extra: { conversationId } });
		};

		dispatch(fetchConversation(conversationId))
			.then((conversation) => setConversation(groupTranscriptionsByAuthor(conversation.transcriptions)))
			.catch(errorHandler);
	}, [conversationId]);

	return (
		<div>
			<SaveCloseModalTemplate
				previousUrlOnClose={isOpenedFromExternal}
				// For unknown reasons closing by escape key shows VALIDATE_MODEL_VERSION_FAIL error
				disableEscapeDialogClose={isOpenedFromExternal}
				isSaveBtnVisible={false}
				title={<FormattedMessage {...messages.callTranscription} />}
			>
				{conversation ? (
					<>
						{conversation.map((msg, i) => (
							<Card
								key={'phoneCallModalMsg' + i}
								className={`${classes.card} ${msg.author === 'BOT' && classes.left}`}
								dangerouslySetInnerHTML={{ __html: msg.text }}
							/>
						))}
					</>
				) : (
					<FormattedMessage {...messages.loadingContent} />
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

PhoneCallTranscriptionModal.propTypes = {
	conversationId: PropTypes.string,
	isOpenedFromExternal: PropTypes.bool,
};

export default PhoneCallTranscriptionModal;
