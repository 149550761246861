import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../../constants';
import { messages, INTL } from '../../intl';
import { trainConfig } from '../../redux/model-table/actions';
import {
	initiallyFetchedTrainConfig,
	isSelectedModelVersionTraining,
	selectedModelVersionId,
} from '../../redux/model-table/selectors';
import { getRawYaml, getYamlParserErrors, getYamlErrors } from '../../redux/model/selectors';
import { showNotification } from '../../redux/notification/actions';
import { isBlank } from '../../utils/validation';
import { uiSquareButtonStyles as styles } from '../ButtonsWithTooltip';

const TrainButton = ({ placement, showIconOnly }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const modelVersionId = useSelector(selectedModelVersionId);
	const isModelVersionTraining = useSelector(isSelectedModelVersionTraining);
	const isModelVersionTrained = useSelector(initiallyFetchedTrainConfig(modelVersionId));
	const yamlData = useSelector(getRawYaml);
	// Only errors regarding the structure of YAML should disable training, saving, import, export, etc
	const yamlErrors = useSelector(getYamlParserErrors);
	const trainingErrors = useSelector(getYamlErrors);

	useEffect(() => {
		if (isModelVersionTraining) {
			dispatch(showNotification(intl.formatMessage(messages.modelTrainingInProgressEx), notificationTypes.INFO));
		} else if (isModelVersionTrained) {
			dispatch(showNotification(intl.formatMessage(messages.modelTrained), notificationTypes.INFO));
		}
		if (trainingErrors.length > 0) {
			dispatch(showNotification(INTL.formatMessage(messages.yamlUnknownError)));
		}
	}, [isModelVersionTraining, isModelVersionTrained]);

	return (
		<Button
			variant="contained"
			disabled={isModelVersionTraining || isBlank(yamlData) || yamlErrors.length > 0}
			onClick={() => dispatch(trainConfig())}
			color="secondary"
			style={showIconOnly ? styles : null}
		>
			{showIconOnly ? (
				<Tooltip
					title={
						<FormattedMessage
							{...messages[isModelVersionTraining ? 'modelTrainingInProgress' : 'startTrainingModel']}
						/>
					}
					placement={placement || 'top'}
				>
					<FitnessCenterIcon />
				</Tooltip>
			) : (
				<>
					<FitnessCenterIcon style={{ marginRight: 5 }} />
					<FormattedMessage {...messages[isModelVersionTraining ? 'modelTrainingInProgress' : 'startTrainingModel']} />
				</>
			)}
		</Button>
	);
};

TrainButton.propTypes = {
	placement: PropTypes.string,
	showIconOnly: PropTypes.bool,
};

export default TrainButton;
