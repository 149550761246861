import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { difference, isNil } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import {
	getAllDiagramGroupsNames,
	getActiveNodeGroupId,
	getNodeIds,
	getActiveNodeGroup,
} from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const ShowActiveNodeModal = (props) => {
	const [selected, setSelected] = useState(null);
	const { callback } = props;
	const dispatch = useDispatch();
	const activeNodeGroupId = useSelector(getActiveNodeGroupId);
	let nodeIds = useSelector(getNodeIds);

	nodeIds = difference(nodeIds, useSelector(getAllDiagramGroupsNames));
	if (activeNodeGroupId) {
		nodeIds = useSelector(getActiveNodeGroup).nodes;
	}

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.showActiveNode} />}
			saveBtnTitle={<FormattedMessage {...messages.showActiveNode} />}
			onSave={() => {
				dispatch(hideModal());
				callback(selected);
			}}
			isSaveBtnDisabled={isNil(selected)}
		>
			<Autocomplete
				options={nodeIds}
				getOptionLabel={(nodeId) => nodeId}
				renderInput={(params) => (
					<TextField
						{...params}
						autoFocus
						label={<FormattedMessage {...messages.showActiveNode} />}
						variant={'outlined'}
						fullWidth
					/>
				)}
				onChange={(e, v) => setSelected(v)}
			/>
		</SaveCloseModalTemplate>
	);
};

ShowActiveNodeModal.propTypes = {
	callback: PropTypes.func,
};

export default ShowActiveNodeModal;
