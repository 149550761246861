import { produce } from 'immer';
import { combineReducers } from 'redux';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = {
	dashboards: [],
};

const kibana = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.FETCH_KIBANA_DASHBOARDS_SUCCESS:
			state.dashboards = payload?.data?.dashboards ?? DEFAULT_STATE.dashboards;
			break;
		default:
			return state;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: kibana,
	fetchingKibanaDashboards: fetching([
		actionTypes.FETCH_KIBANA_DASHBOARDS_PENDING,
		actionTypes.FETCH_KIBANA_DASHBOARDS_SUCCESS,
		actionTypes.FETCH_KIBANA_DASHBOARDS_FAIL,
	]),
});
