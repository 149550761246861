import { produce } from 'immer';
import { actionTypes as authActionTypes } from '../auth/actions';

const DEFAULT_STATE = {
	isFetching: false,
	initiallyFetched: false,
};

const fetching = (types) =>
	produce((state, action) => {
		const [requestType, successType, failureType] = types;

		switch (action.type) {
			case authActionTypes.LOGOUT_SUCCESS:
				return DEFAULT_STATE;
			case requestType:
				state.isFetching = true;
				break;
			case successType:
				state.isFetching = false;
				state.initiallyFetched = true;
				break;
			case failureType:
				state.isFetching = false;
				break;
		}
	}, DEFAULT_STATE);

export default fetching;
