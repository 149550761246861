import * as requests from '../../core/requests';
import {
	qlcreateMetadata,
	metadatas as metadatasQL,
	metadata as metadataQL,
	deleteMetadata as deleteMetadataQL,
	updateMetadata as updateMetadataQL,
	getDataTableColumns as getDataTableColumnsQL,
	getDataTableRows as getDataTableRowsQL,
	addDataTableColumn as addDataTableColumnQL,
	deleteDataTableColumn as deleteDataTableColumnQL,
	insertUpdateTableRow as insertUpdateTableRowQL,
	importRows as importRowsQL,
	getDataTableColumnsType as getDataTableColumnsTypeQL,
	deleteTableRow as deleteTableRowQL,
} from '../../graphql/metadata';
import { createActionsMap, makeActionCreator } from '../../utils/action-utils';
import { psf } from '../utils';

export const ID = 'dataManagement';

export const actionTypes = createActionsMap(ID, [
	'UPDATE_DATA_CHANGES',
	'UPDATE_METADATA_FILTERED_VALUE',
	'CLEAR_TABLE_STATE',
	...psf('CREATE_METADATA'),
	...psf('FETCH_METADATAS'),
	...psf('FETCH_METADATA'),
	...psf('DELETE_METADATA'),
	...psf('EDIT_METADATA'),
	...psf('FETCH_DATA_TABLE_COLUMNS'),
	...psf('FETCH_DATA_TABLE_COLUMNS_TYPE'),
	...psf('FETCH_DATA_TABLE_ROWS'),
	...psf('ADD_TABLE_COLUMN'),
	...psf('DELETE_TABLE_COLUMN'),
	...psf('UPDATE_TABLE_ROWS'),
	...psf('DELETE_TABLE_ROW'),
	...psf('IMPORT_ROWS'),
]);

export const updateDataManagementFilteredValue = makeActionCreator(actionTypes.UPDATE_METADATA_FILTERED_VALUE, 'value');
export const updateDataChangesInStore = makeActionCreator(actionTypes.UPDATE_DATA_CHANGES, 'table', 'row');
export const clearTableState = makeActionCreator(actionTypes.CLEAR_TABLE_STATE);

export const createMetadata = (name, description) =>
	requests.qlAuthRequest(
		[actionTypes.CREATE_METADATA_PENDING, actionTypes.CREATE_METADATA_SUCCESS, actionTypes.CREATE_METADATA_FAIL],
		{
			query: qlcreateMetadata,
			variables: { name, description },
		}
	);

export const fetchMetadatas = (sort, filter) =>
	requests.qlAuthRequest(
		[actionTypes.FETCH_METADATAS_PENDING, actionTypes.FETCH_METADATAS_SUCCESS, actionTypes.FETCH_METADATAS_FAIL],
		{ query: metadatasQL, variables: { sort, filter } }
	);

export const fetchMetadata = (id) =>
	requests.qlAuthRequest(
		[actionTypes.FETCH_METADATA_PENDING, actionTypes.FETCH_METADATA_SUCCESS, actionTypes.FETCH_METADATA_FAIL],
		{ query: metadataQL, variables: { id } }
	);

export const deleteMetadata = (id) =>
	requests.qlAuthRequest(
		[actionTypes.DELETE_METADATA_PENDING, actionTypes.DELETE_METADATA_SUCCESS, actionTypes.DELETE_METADATA_FAIL],
		{ query: deleteMetadataQL, variables: { id } }
	);

export const editMetadata = (args) =>
	requests.qlAuthRequest(
		[actionTypes.EDIT_METADATA_PENDING, actionTypes.EDIT_METADATA_SUCCESS, actionTypes.EDIT_METADATA_FAIL],
		{ query: updateMetadataQL, variables: { ...args } }
	);

export const fetchDataTableColumns = (tableName) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_DATA_TABLE_COLUMNS_PENDING,
			actionTypes.FETCH_DATA_TABLE_COLUMNS_SUCCESS,
			actionTypes.FETCH_DATA_TABLE_COLUMNS_FAIL,
		],
		{ query: getDataTableColumnsQL, variables: { tableName } }
	);

export const fetchDataTableColumnsType = (tableName) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_DATA_TABLE_COLUMNS_TYPE_PENDING,
			actionTypes.FETCH_DATA_TABLE_COLUMNS_TYPE_SUCCESS,
			actionTypes.FETCH_DATA_TABLE_COLUMNS_TYPE_FAIL,
		],
		{ query: getDataTableColumnsTypeQL, variables: { tableName } }
	);

export const fetchDataTableRows = (tableName) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_DATA_TABLE_ROWS_PENDING,
			actionTypes.FETCH_DATA_TABLE_ROWS_SUCCESS,
			actionTypes.FETCH_DATA_TABLE_ROWS_FAIL,
		],
		{ query: getDataTableRowsQL, variables: { tableName } }
	);

export const addDataTableColumn = (tableName, columnName, columnType) =>
	requests.qlAuthRequest(
		[actionTypes.ADD_TABLE_COLUMN_PENDING, actionTypes.ADD_TABLE_COLUMN_SUCCESS, actionTypes.ADD_TABLE_COLUMN_FAIL],
		{ query: addDataTableColumnQL, variables: { tableName, columnName, columnType } }
	);

export const deleteDataTableColumn = (tableName, columnName) =>
	requests.qlAuthRequest(
		[
			actionTypes.DELETE_TABLE_COLUMN_PENDING,
			actionTypes.DELETE_TABLE_COLUMN_SUCCESS,
			actionTypes.DELETE_TABLE_COLUMN_FAIL,
		],
		{ query: deleteDataTableColumnQL, variables: { tableName, columnName } }
	);

export const insertUpdateTableRow = (tableName, rows) =>
	requests.qlAuthRequest(
		[actionTypes.UPDATE_TABLE_ROWS_PENDING, actionTypes.UPDATE_TABLE_ROWS_SUCCESS, actionTypes.UPDATE_TABLE_ROWS_FAIL],
		{ query: insertUpdateTableRowQL, variables: { tableName, rows } }
	);

export const deleteTableRow = (tableName, rowId) =>
	requests.qlAuthRequest(
		[actionTypes.DELETE_TABLE_ROW_PENDING, actionTypes.DELETE_TABLE_ROW_SUCCESS, actionTypes.DELETE_TABLE_ROW_FAIL],
		{ query: deleteTableRowQL, variables: { tableName, rowId } }
	);

export const importRows = (tableName, rows) =>
	requests.qlAuthRequest(
		[actionTypes.IMPORT_ROWS_PENDING, actionTypes.IMPORT_ROWS_SUCCESS, actionTypes.IMPORT_ROWS_FAIL],
		{ query: importRowsQL, variables: { tableName, rows } }
	);
