import { gql } from 'graphql-tag';

const MODEL_USERS_FIELDS = gql`
	fragment MODEL_USERS_FIELDS on Model {
		users {
			id
			name
			modelRole
		}
	}
`;

const MODEL_FIELDS = gql`
	fragment MODEL_FIELDS on Model {
		id
		name
		description
		organization {
			name
			id
		}
		createdAt
		createdBy {
			name
		}
	}
`;

const CONFIG_FIELDS = gql`
	fragment CONFIG_FIELDS on Config {
		id
		configHash
		data
		trained
		type
		modelVersion {
			id
		}
	}
`;

const IMAGE_FIELDS = gql`
	fragment IMAGE_FIELDS on Image {
		id
		url
		fileId
	}
`;

const ASSET_FIELDS = gql`
	fragment ASSET_FIELDS on Asset {
		id
		name
		type
		data
		model {
			id
		}
	}
`;

const MODEL_VERSION_FIELDS = gql`
	fragment MODEL_VERSION_FIELDS on ModelVersion {
		id
		name
		description
		updatedAt
		createdAt
		version
		createdBy {
			name
		}
		model {
			...MODEL_FIELDS
			...MODEL_USERS_FIELDS
		}
		configs {
			...CONFIG_FIELDS
		}
	}
	${MODEL_FIELDS}
	${MODEL_USERS_FIELDS}
	${CONFIG_FIELDS}
`;

export const createModel = gql`
	mutation createModel($name: String!, $description: String!) {
		models: createModel(name: $name, description: $description) {
			...MODEL_FIELDS
		}
	}
	${MODEL_FIELDS}
`;

export const getModel = gql`
	query getModel($id: ID!) {
		models: model(id: $id) {
			...MODEL_FIELDS
			images {
				...IMAGE_FIELDS
			}
			assets {
				...ASSET_FIELDS
			}
			versions {
				...MODEL_VERSION_FIELDS
			}
		}
	}
	${MODEL_FIELDS}
	${IMAGE_FIELDS}
	${ASSET_FIELDS}
	${MODEL_VERSION_FIELDS}
`;

export const deleteModel = gql`
	mutation deleteModel($id: ID!) {
		models: deleteModel(id: $id) {
			...MODEL_FIELDS
		}
	}
	${MODEL_FIELDS}
`;

export const updateModel = gql`
	mutation updateModel($modelId: ID!, $name: String, $description: String) {
		models: updateModel(id: $modelId, name: $name, description: $description) {
			...MODEL_FIELDS
		}
	}
	${MODEL_FIELDS}
`;

export const getModelVersion = gql`
	query modelVersion($id: ID!) {
		versions: modelVersion(id: $id) {
			...MODEL_VERSION_FIELDS
			data
		}
	}
	${MODEL_VERSION_FIELDS}
	${CONFIG_FIELDS}
`;

export const createModelVersion = gql`
	mutation createModelVersion($model: ID!, $name: String!, $description: String, $version: String!, $data: JSONObject) {
		versions: createModelVersion(
			name: $name
			description: $description
			model: $model
			version: $version
			data: $data
		) {
			...MODEL_VERSION_FIELDS
		}
	}
	${MODEL_VERSION_FIELDS}
`;

export const updateModelVersion = gql`
	mutation updateModelVersion($modelVersionId: ID!, $data: JSONObject, $description: String) {
		versions: updateModelVersion(id: $modelVersionId, data: $data, description: $description) {
			...MODEL_VERSION_FIELDS
		}
	}
	${MODEL_VERSION_FIELDS}
`;

export const deleteModelVersion = gql`
	mutation deleteModelVersion($modelVersionId: ID!) {
		versions: deleteModelVersion(id: $modelVersionId) {
			...MODEL_VERSION_FIELDS
		}
	}
	${MODEL_VERSION_FIELDS}
`;

export const validateModelVersionQl = gql`
	mutation validateModelVersion($rawYaml: String!) {
		validateModelVersion(rawYaml: $rawYaml) {
			errors
		}
	}
`;

export const createConfig = gql`
	mutation createConfig($type: Types = GoodBot, $modelVersionId: ID!) {
		configs: createConfig(type: $type, modelVersion: $modelVersionId) {
			...CONFIG_FIELDS
		}
	}
	${CONFIG_FIELDS}
`;

export const deleteConfig = gql`
	mutation deleteConfig($id: ID!) {
		deleteConfig(id: $id) {
			id
			modelVersion {
				id
			}
		}
	}
`;

export const trainConfig = gql`
	mutation trainConfig($id: ID!, $trainingData: String!, $jsonData: JSONObject!) {
		configs: trainConfig(id: $id, trainingData: $trainingData, jsonData: $jsonData) {
			...CONFIG_FIELDS
		}
	}
	${CONFIG_FIELDS}
`;

export const getModelsBasicInfoQuery = gql`
	query models {
		models {
			...MODEL_FIELDS
		}
	}
	${MODEL_FIELDS}
`;

export const deleteImage = gql`
	mutation deleteImage($id: ID!) {
		images: deleteImage(id: $id) {
			...IMAGE_FIELDS
		}
	}
	${IMAGE_FIELDS}
`;

export const editModelUserRole = gql`
	mutation editModelUserRole($modelId: ID!, $userId: ID!, $role: InputModelRoles!) {
		model: editModelUserRole(modelId: $modelId, userId: $userId, role: $role) {
			id
			...MODEL_USERS_FIELDS
		}
	}
	${MODEL_USERS_FIELDS}
`;

export const getUsers = gql`
	query {
		users {
			id
			name
			role
		}
	}
`;
export const getHealthsQL = gql`
	query healths {
		healths {
			Nlp {
				status
				version
			}
			IntentResolver {
				status
				version
			}
			Voice {
				status
				version
			}
			VoiceConnector {
				status
				version
			}
			OutCalls {
				status
				version
			}
			Bubble {
				status
				version
			}
			BuilderServer {
				status
				version
			}
		}
	}
`;

export const deleteModelAsset = gql`
	mutation deleteModelAsset($id: ID!) {
		assets: deleteAsset(id: $id) {
			id
		}
	}
`;

export const createModelAssetQl = gql`
	mutation createModelAsset($modelId: ID!, $type: AssetType!, $name: String!, $data: JSONObject!) {
		asset: createAsset(modelId: $modelId, type: $type, name: $name, data: $data) {
			...ASSET_FIELDS
		}
	}
	${ASSET_FIELDS}
`;

export const updateModelAssetQl = gql`
	mutation updateModelAsset($id: ID!, $name: String, $data: JSONObject) {
		asset: updateAsset(id: $id, name: $name, data: $data) {
			...ASSET_FIELDS
		}
	}
	${ASSET_FIELDS}
`;

export const getExtractorsQl = gql`
	query {
		extractors {
			name
			parameters {
				name
				type
				default_value
			}
		}
	}
`;

export const utteranceAnalysisQl = gql`
	query utteranceAnalysis($configHash: String!, $utterances: [Utterance!]!) {
		analysis: utteranceAnalysis(configHash: $configHash, utterances: $utterances) {
			start_state
			utterance
			target_state
			target_state_match
			preprocessed_utterance
			predicted
			confidence
			candidates_scores {
				node
				score
			}
			error
		}
	}
`;
