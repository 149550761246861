import { compose, curry, filter, nth, o, path, prop, values } from 'ramda';
import { createSelector } from 'reselect';
import { getActiveModelConfigType } from '../model/selectors';
import { ID } from './actions';

export const getModelsData = (state) => state[ID][ID]; // DON'T use R.path([ID, ID]) here. It does not work.
export const getExtractors = createSelector(getModelsData, path(['extractors', 'data']));
export const selectedModelId = createSelector(getModelsData, prop('selectedModelId'));
export const selectedModelVersionId = createSelector(getModelsData, prop('selectedModelVersionId'));
export const getModelEntities = createSelector(getModelsData, prop('entities'));
export const getModels = createSelector(getModelEntities, prop('models'));
export const getVersions = createSelector(getModelEntities, prop('versions'));
export const getConfigs = createSelector(getModelEntities, prop('configs'));
export const getImages = createSelector(getModelEntities, prop('images'));
export const getAssets = createSelector(getModelEntities, (modelEntities) => Object.values(modelEntities.assets));
export const getAssetsAnnouncements = createSelector(getAssets, (assets) =>
	assets.filter((a) => a.type === 'WIDGET_ANNOUNCEMENT')
);
export const getImage = curry((imageId, state) => path([imageId], getImages(state)));
export const getAppsHealth = createSelector(getModelsData, prop('healths'));

export const getIsFetchingAssets = o(path(['isFetchingAssets']), getModelsData);
export const getModelAssets = curry((modelId, state) => {
	const assets = getAssets(state);
	return assets.filter((asset) => asset.model === modelId);
});
export const getSpecificAssetsType = curry((modelId, type, include = true, state) => {
	const assets = getModelAssets(modelId, state);
	return assets.filter((asset) => (include ? asset.type === type : asset.type !== type));
});
export const getBackgroundMusicFiles = (state) => {
	const modelId = selectedModelId(state);
	const assets = getModelAssets(modelId, state);
	return assets.filter((asset) => asset.type === 'FILE_AUDIO');
};

export const getDashboardFilteredValue = o(path(['filteredValue']), getModelsData);
/**
 * GraphQL image id differs from file id returned from upload image.
 */
export const getImageByFileId = curry((fileIdWithParams, state) => {
	const images = getImages(state) || {};
	const fileId = fileIdWithParams.split('&')[0];
	return compose(
		nth(0),
		values,
		filter((image) => image.fileId === fileId)
	)(images);
});
export const getConfig = curry((configId, state) => getConfigs(state)[configId] ?? {});
export const getModel = curry((modelId, state) => path([modelId], getModels(state)));
export const getModelVersion = curry((modelVersionId, state) => path([modelVersionId], getVersions(state)));
export const getModelVersionIds = curry((modelId, state) => path(['versions'], getModel(modelId, state)));
export const getSelectedModelVersionIds = curry((state) => path(['versions'], getModel(selectedModelId(state), state)));
export const getSelectedModelVersions = (state) => {
	const versions = getVersions(state);
	const selectedModelVersionIds = getSelectedModelVersionIds(state);
	return Object.values(versions).filter((version) => selectedModelVersionIds.includes(version.id));
};
export const getSelectedModel = (state) => getModel(selectedModelId(state), state) || {};
export const getSelectedModelName = (state) => getSelectedModel(state).name;
export const getSelectedVersion = (state) => getModelVersion(selectedModelVersionId(state), state) || {};
export const getUsers = o(path(['users']), getModelsData);

export const getMaximumModelVersion = curry((modelId, state) => {
	const modelVersionIds = values(getModelVersionIds(modelId, state)) ?? [];
	let maximumModelVersion = { version: '0.0' };

	for (const modelVersionId of modelVersionIds) {
		const modelVersion = getModelVersion(modelVersionId, state);
		const [major = 0, minor = 0] = modelVersion.version?.split('.') ?? [0, 0];
		const [maxMajor = 0, maxMinor = 0] = maximumModelVersion.version?.split('.');

		if (Number(major) > Number(maxMajor) || (Number(major) === Number(maxMajor) && Number(minor) > Number(maxMinor))) {
			maximumModelVersion = modelVersion;
			// In the very unlikely case of a duplicate version, use the timestamp as criterion
		} else if (
			modelVersion.version === maximumModelVersion.version &&
			new Date(modelVersion.updatedAt) > new Date(maximumModelVersion.updatedAt)
		) {
			maximumModelVersion = modelVersion;
		}
	}

	return maximumModelVersion;
});

export const getModelVersionConfigIds = curry(
	(modelVersionId, state) => getModelVersion(modelVersionId, state)?.configs ?? []
);

export const getActiveModelVersionConfig = (state) => {
	const modelVersionId = selectedModelVersionId(state);
	const configIds = getModelVersionConfigIds(modelVersionId, state) ?? [];

	const configType = getActiveModelConfigType(state);
	return configIds.map((id) => getConfig(id, state)).find((config) => config.type === configType) ?? {};
};

export const isFetchingModelsInfo = path([ID, 'fetchingModelsInfo', 'isFetching']);
export const initiallyFetchedModelsInfo = path([ID, 'fetchingModelsInfo', 'initiallyFetched']);

export const isFetchingVersionsTrainedStatus = path([ID, 'fetchingVersionsTrainedStatus', 'isFetching']);
export const initiallyFetchedVersionsTrainedStatus = path([ID, 'fetchingVersionsTrainedStatus', 'initiallyFetched']);

export const isFetchingCreateModel = path([ID, 'fetchingCreateModel', 'isFetching']);
export const initiallyFetchedCreateModel = path([ID, 'fetchingCreateModel', 'initiallyFetched']);

export const isFetchingDeleteModel = path([ID, 'fetchingDeleteModel', 'isFetching']);
export const initiallyFetchedDeleteModel = path([ID, 'fetchingDeleteModel', 'initiallyFetched']);

export const isFetchingDeleteModelVersion = path([ID, 'fetchingDeleteModelVersion', 'isFetching']);
export const initiallyFetchedDeleteModelVersion = path([ID, 'fetchingDeleteModelVersion', 'initiallyFetched']);

export const isValidatingModelVersion = path([ID, 'fetchingValidateModelVersion', 'isFetching']);

export const isFetchingCreateConfig = path([ID, 'fetchingCreateConfig', 'isFetching']);
export const initiallyFetchedCreateConfig = path([ID, 'fetchingCreateConfig', 'initiallyFetched']);

export const isFetchingTrainConfig = curry((versionId, state) =>
	Object.values(state[ID].fetchingTrainConfig.isFetching).includes(versionId)
);

export const isSelectedModelVersionTraining = (state) => isFetchingTrainConfig(selectedModelVersionId(state), state);
export const initiallyFetchedTrainConfig = curry((versionId, state) =>
	state[ID].fetchingTrainConfig.initiallyFetched.includes(versionId)
);

export const isFetchingModelVersion = path([ID, 'fetchingModelVersion', 'isFetching']);
export const initiallyFetchedModelVersion = path([ID, 'fetchingModelVersion', 'initiallyFetched']);

export const isFetchingModelRolesChange = path([ID, 'fetchingModelRolesChange', 'isFetching']);
export const initiallyFetchedModelRolesChange = path([ID, 'fetchingModelRolesChange', 'initiallyFetched']);
