import { Alert, AlertTitle } from '@mui/lab';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { fetchModel, saveModelVersion } from '../../redux/model-table/actions';
import {
	getMaximumModelVersion,
	getSelectedVersion,
	selectedModelId as getSelectedModelId,
} from '../../redux/model-table/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const SaveModelVersionModal = () => {
	// Make sure the latest version has been fetched
	useEffect(() => {
		dispatch(fetchModel(selectedModelId));
	}, []);

	const selectedModelId = useSelector(getSelectedModelId);
	const maximumVersion = useSelector(getMaximumModelVersion(selectedModelId));
	const currentVersion = useSelector(getSelectedVersion);

	const [modelVersionDesc, setModelVersionDesc] = useState('');
	const dispatch = useDispatch();

	const handleAdd = async () => {
		await dispatch(saveModelVersion(!isEmpty(modelVersionDesc) && modelVersionDesc));
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.saveModelVersion} />}
				saveBtnTitle={<FormattedMessage {...messages.saveModelVersion} />}
				onSave={handleAdd}
			>
				<Grid container justifyContent="center">
					{maximumVersion.id !== currentVersion.id ? (
						<Grid item xs={12}>
							<Alert severity="warning">
								<AlertTitle>
									<FormattedMessage {...messages.warning} />
								</AlertTitle>
								<FormattedMessage
									{...messages[
										maximumVersion.version === currentVersion.version
											? 'newerVersionFetchedWarning'
											: 'differentVersionWarning'
									]}
									values={{ maximumVersion: maximumVersion.version, currentVersion: currentVersion.version }}
								/>
							</Alert>
						</Grid>
					) : null}
					<Grid item xs={12}>
						<TextField
							autoFocus
							value={modelVersionDesc}
							fullWidth
							onChange={(e) => {
								setModelVersionDesc(e.target.value);
							}}
							onKeyPress={handleKeyPress}
							label={<FormattedMessage {...messages.versionDesc} />}
							variant="standard"
						/>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default SaveModelVersionModal;
