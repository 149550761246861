import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const EnhancedTextField = ({ validatePred, extraValidationParams = [], onValidate, errorMsg, ...rest }) => {
	const [value, setValue] = useState('');

	useEffect(() => {
		if (value !== '' && errorMsg !== null) {
			const errorMsg = validatePred(value, ...extraValidationParams);
			const isValid = errorMsg === null;

			if (isValid) {
				onValidate({ value, isValid, errorMsg });
			}
		}
	}, [extraValidationParams]);

	const handleOnChange = (e) => {
		setValue(e.target.value);

		const errorMsg = validatePred(e.target.value, ...extraValidationParams);

		onValidate({ value: e.target.value, isValid: errorMsg === null, errorMsg });
	};

	return (
		<TextField
			value={value}
			error={!!errorMsg}
			helperText={errorMsg}
			fullWidth
			variant="standard"
			onChange={handleOnChange}
			{...rest}
		/>
	);
};

EnhancedTextField.propTypes = {
	validatePred: PropTypes.func.isRequired,
	extraValidationParams: PropTypes.array,
	onValidate: PropTypes.func.isRequired,
	errorMsg: PropTypes.string,
};

export default EnhancedTextField;
