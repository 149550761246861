import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { updateDataChangesInStore } from '../../redux/data-management/actions';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const UnsavedDataEditorChangesModal = ({ tableName, setEditable }) => {
	const dispatch = useDispatch();
	const handleDiscard = async () => {
		dispatch(hideModal());
		dispatch(updateDataChangesInStore(tableName));
		setEditable(false);
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.unsavedChanges} />}
				saveBtnTitle={<FormattedMessage {...messages.discardChanges} />}
				saveBtnColor="red"
				onSave={handleDiscard}
			>
				<span>
					{
						<FormattedMessage
							{...messages.changesWillBeLost}
							values={{ table: tableName, strong: (msg) => <strong>{msg}</strong> }}
						/>
					}
				</span>
			</SaveCloseModalTemplate>
		</div>
	);
};

UnsavedDataEditorChangesModal.propTypes = {
	tableName: PropTypes.string.isRequired,
	setEditable: PropTypes.func.isRequired,
};

export default UnsavedDataEditorChangesModal;
