import Autocomplete from '@mui/lab/Autocomplete';
import { alpha, Grid, TextField, Tooltip } from '@mui/material';
import { blueGrey, cyan, grey, red } from '@mui/material/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { createStyles, makeStyles } from '@mui/styles';
import escapeRegExp from 'lodash/escapeRegExp';
import PropTypes from 'prop-types';
import { adjust, concat, endsWith, flatten, groupWith, includes, init, isEmpty, last, prop, uniq, update } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { palette } from '../../mui-theme-builder';
import { hideModal } from '../../redux/modal/actions';
import {
	clearSelectedNodes,
	createCondition,
	createNode,
	registerStateHistory,
	updateRemoveCondition,
} from '../../redux/model/actions';
import {
	getAllConditions,
	getAllVariables,
	getAvailableTransitionNodes,
	getNode,
	getNodeIds,
} from '../../redux/model/selectors';
import { normalizeNodeId } from '../../utils/normalization';
import {
	validateGroupCompliance,
	validateIsEmpty,
	validateNodeName,
	validateShouldBeNodeCreated,
	validationStyles,
} from '../../utils/validation';
import { PythonEditor } from '../PythonEditor';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

// styles
const css = {
	opacity: {
		bg: 0.015,
		bgHov: 0.2,
		border: 0.2,
	},
	colors: {
		var: cyan[500],
		compa: grey[600],
		val: cyan[500],
		exam: blueGrey[500],
		logic: grey[800],
		cond: palette.primary.main,
	},
};

const suggestionsStyles = makeStyles({
	autocomplete: {
		minWidth: '-webkit-fill-available',
		height: '300px',
		overflowY: 'auto',
		borderRadius: '0 0 4px 4px',
		marginTop: '10px',
	},
	row: {
		cursor: 'pointer',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	var: {
		background: alpha(css.colors.var, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.var, css.opacity.border),
		color: css.colors.var,
		'& span.tag': {
			background: css.colors.var,
		},
		'&:hover': {
			background: alpha(css.colors.var, css.opacity.bgHov),
		},
	},
	compa: {
		background: alpha(css.colors.compa, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.compa, css.opacity.border),
		color: css.colors.compa,
		'& span.tag': {
			background: css.colors.compa,
		},
		'&:hover': {
			background: alpha(css.colors.compa, css.opacity.bgHov),
		},
	},
	val: {
		background: alpha(css.colors.val, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.val, css.opacity.border),
		color: css.colors.val,
		'& span.tag': {
			background: css.colors.val,
		},
		'&:hover': {
			background: alpha(css.colors.val, css.opacity.bgHov),
		},
	},
	logic: {
		background: alpha(css.colors.logic, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.logic, css.opacity.border),
		color: css.colors.logic,
		'& span.tag': {
			background: css.colors.logic,
		},
		'&:hover': {
			background: alpha(css.colors.logic, css.opacity.bgHov),
		},
	},
	cond: {
		background: alpha(css.colors.cond, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.cond, css.opacity.border),
		color: css.colors.cond,
		'& span.tag': {
			background: css.colors.cond,
		},
		'&:hover': {
			background: alpha(css.colors.cond, css.opacity.bgHov),
		},
	},
	exam: {
		background: alpha(css.colors.exam, css.opacity.bg),
		borderBottom: '1px solid ' + alpha(css.colors.exam, css.opacity.border),
		color: css.colors.exam,
		'& span.tag': {
			background: css.colors.exam,
		},
		'&:hover': {
			background: alpha(css.colors.exam, css.opacity.bgHov),
		},
	},
	tag: {
		fontSize: '10px',
		textAlign: 'center',
		borderRadius: '4px',
		padding: '3px 4px',
		color: 'white',
		lineHeight: '17px',
		top: '4px',
		position: 'relative',
		'&::after': {
			background: css.colors.val,
		},
	},
	value: {
		fontSize: '13px',
		padding: '6px 0 6px 15px',
	},
});

const condSetStyles = makeStyles((theme) => ({
	bounding: {
		borderWidth: '1px',
		margin: '15px auto',
		padding: '8px',
		cursor: 'text',
		position: 'relative',
	},
	boundingDefault: {
		border: '1px solid ' + grey[100],
		lineHeight: '35px',
		'& legend': {
			color: grey[400],
		},
		'&:hover': {
			border: '1px solid ' + grey[400],
		},
	},
	boundingSuccess: {
		border: '2px solid ' + theme.palette.primary.main,
		lineHeight: '45px',
		'& legend': {
			color: theme.palette.primary.main,
		},
	},
	boundingError: {
		border: '1px solid ' + theme.palette.error.main,
		lineHeight: '35px',
		'& legend': {
			color: theme.palette.error.main,
		},
	},
	legend: {
		fontSize: '11px',
		padding: '0 4px',
		lineHeight: '0px',
	},
	cell: {
		maxWidth: '170px',
		border: 'none',
		display: 'inline-block',
		margin: '0 4px 0 0',
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			background: 'none',
		},
	},
	tag: {
		fontSize: '14px',
		top: '0px',
		padding: '5px 7px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: '140px',
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	tooLongTag: {
		'&::after': {
			right: '0px',
			top: '0px',
			content: '"... "',
			position: 'absolute',
			color: 'white',
			padding: '0 6px 1px 4px',
			lineHeight: '27px',
		},
	},
	assetTooltip: {
		padding: '5px',
	},
	assetTooltipVal: {
		color: 'white',
		borderRadius: '4px',
		padding: '3px 6px',
		margin: '5px 0 0 0',
		textAlign: 'center',
	},
	removingPreloader: {
		animation: `$myEffect 2500ms ${theme.transitions.easing.easeInOut}`,
		backgroundColor: theme.palette.error.main + ' !important',
		width: '0%',
		height: '100%',
		display: 'block',
		position: 'absolute',
		opacity: '0',
		top: 0,
		left: 0,
	},
	'@keyframes myEffect': {
		'0%': {
			opacity: 0,
			width: '0%',
		},
		'100%': {
			opacity: 0.4,
			width: '100%',
		},
	},
}));

const condTextFieldStyles = (color) => {
	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				verticalAlign: 'middle',
				'& input': {
					color: color,
					fontWeight: 'bold',
				},
				'& label.Mui-focused, label.MuiFormLabel-root': {
					color: color,
				},
				'& .MuiOutlinedInput-root': {
					'& fieldset': {
						borderColor: color,
						color: color,
					},
					'&.Mui-focused fieldset': {
						borderColor: color,
						color: color,
					},
				},
			},
		})
	);
	const classes = useStyles();
	return classes.root;
};

// prettier-ignore
/** https://docs.python.org/3/reference/lexical_analysis.html#string-and-bytes-literals */
const STRING_PREFIXES_PATTERN = [
	// unicode string
	'r', 'u', 'R', 'U', 'f', 'F', 'fr', 'Fr', 'fR', 'FR', 'rf', 'rF', 'Rf', 'RF',
	// byte string
	'b', 'B', 'br', 'Br', 'bR', 'BR', 'rb', 'rB', 'Rb', 'RB',
].join('|');
const STRING_MARKS = Object.freeze(['"""', "'''", '"', "'"]);
const STRING_PATTERN = STRING_MARKS.map(
	(m) => `(?:(?:${STRING_PREFIXES_PATTERN})?${m}(?:[^\n${m}\\\\]*(?:\\\\.[^\n${m}\\\\]*)*)${m})`
).join('|');
const RELATIONAL_SIGN_OPERATORS = Object.freeze(['==', '!=', '>=', '<=', '>', '<']);
const RELATIONAL_TEXT_OPERATORS = Object.freeze(['not in', 'in', 'is not', 'is']);
const RELATIONAL_OPERATORS = Object.freeze([...RELATIONAL_SIGN_OPERATORS, ...RELATIONAL_TEXT_OPERATORS]);
const LOGICAL_OPERATORS = Object.freeze(['and', 'or', 'not']);
const ALL_OPERATORS = Object.freeze(new Set([...RELATIONAL_OPERATORS, ...LOGICAL_OPERATORS]));
const CONDITIONS_SPLIT_PATTERN = new RegExp(
	'\\s*(' +
		`(?:${STRING_PATTERN})` +
		'|' +
		'\\b(?:' +
		[...RELATIONAL_TEXT_OPERATORS, ...LOGICAL_OPERATORS].map((o) => `(?:${escapeRegExp(o)})`).join('|') +
		')\\b' +
		'|' +
		RELATIONAL_SIGN_OPERATORS.map((o) => `(?:${escapeRegExp(o)})`).join('|') +
		')\\s*',
	'g'
);

// condition examples
export const CONDITION_EXAMPLES = [
	'varName != "variable value" 1 or varName is None',
	'varName1 > 0 and varName2 > 0 and varName3 > 0 and varName4 == "No match"',
	'varName1 == True and varName2 <= 10',
];

/**
 * @param {String[]} cond
 * @returns {String}
 */
export const stringifyCondition = (cond) => cond.join(' ');

/**
 * Splits condition into the tokens. It's only RegExp base.
 * Much better implementation would do it with the grammar rules.
 * See https://github.com/miso-belica/py.js/blob/main/lib/py.js#L257-L344
 *
 * @param {String} cond
 * @returns {String[]}
 */
export const parseCondition = (cond) => {
	if (!cond) {
		return [];
	}

	const tokens = cond.split(CONDITIONS_SPLIT_PATTERN).filter(Boolean);
	if (isEmpty(tokens)) {
		return tokens;
	}

	const group = groupWith((a, b) => !ALL_OPERATORS.has(a) && !ALL_OPERATORS.has(b), tokens);
	return group.map((group) => group.join(''));
};

// component
const ConditionSuggestions = ({ conditionTokens, activeTokenIndex, setAssetValAndNext }) => {
	const variables = useSelector(getAllVariables);
	const activeConditionToken = conditionTokens[activeTokenIndex].toLowerCase();
	const conditionString = stringifyCondition(conditionTokens).toLowerCase();

	// condition suggestions types
	const suggestions = {
		cond: useSelector(getAllConditions),
		exam: CONDITION_EXAMPLES,
	};
	switch (activeTokenIndex % 4) {
		case 0: // Variables Names
		case 2: // Variables Values
			suggestions.var = uniq(variables.map(prop(0))).filter(Boolean);
			suggestions.val = uniq(variables.map(prop(1))).filter(Boolean);
			break;
		case 1: // Comparison Operators
			suggestions.compa = RELATIONAL_OPERATORS;
			break;
		case 3: // Logical Operators
			suggestions.logic = LOGICAL_OPERATORS;
			break;
		default:
	}

	// condition suggestions filtering
	const useSuggestionsStyles = suggestionsStyles();
	const list = [];
	for (const [condAssetKey, condAssetValues] of Object.entries(suggestions)) {
		const isConditionType = activeTokenIndex % 4 !== 0 && condAssetKey === 'cond';
		const isExampleType = activeTokenIndex % 4 !== 0 && condAssetKey === 'exam';

		for (const _condAssetValue of condAssetValues) {
			// filter mismatch
			if (condAssetKey !== 'exam' && !_condAssetValue.toLowerCase().includes(activeConditionToken)) {
				continue;
			}
			// filter whole condition mismatch
			if (isConditionType && !_condAssetValue.toLowerCase().includes(conditionString)) {
				continue;
			}

			list.push(
				<Grid
					container
					spacing={0}
					key={condAssetKey + list.length}
					className={`${useSuggestionsStyles['row']} ${useSuggestionsStyles[condAssetKey]}`}
					onClick={() => !isExampleType && setAssetValAndNext(_condAssetValue, isConditionType)}
					justify="center"
				>
					<Grid item xs={1} align="center">
						<span className={`${useSuggestionsStyles.tag} tag`}>{condAssetKey}</span>
					</Grid>
					<Grid item xs={11} className={useSuggestionsStyles.value}>
						{_condAssetValue}
					</Grid>
				</Grid>
			);
		}
	}

	return !isEmpty(list) ? <div className={useSuggestionsStyles.autocomplete}>{list}</div> : null;
};

ConditionSuggestions.propTypes = {
	conditionTokens: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeTokenIndex: PropTypes.number,
	setAssetValAndNext: PropTypes.func,
};

// component
const StyledCondition = ({
	conditionTokens,
	activeTokenIndex,
	setConditionTokens,
	setActiveTokenIndex,
	displaySuggestions,
	errorCondSetInvalid,
	errorConditionTokens,
}) => {
	const [operatorError, setOperatorError] = useState(null);
	const [isRemoving, setIsRemoving] = useState(false);
	const [stepBackAllowed, setStepBackAllowed] = useState(false);

	const useSuggestionsStyles = suggestionsStyles();
	const useCondSetStyles = condSetStyles();
	const condCurrAsset = conditionTokens[activeTokenIndex];

	// focusing condition styled field
	const condTextFieldRef = useRef();
	useEffect(() => {
		if (condTextFieldRef.current) {
			condTextFieldRef.current.focus();
		}
		setOperatorError(null);
	}, [activeTokenIndex]);

	useEffect(() => {
		if (condTextFieldRef.current && displaySuggestions) {
			condTextFieldRef.current.focus();
		}
	}, [displaySuggestions]);

	const conAssetOnClick = (key) => {
		if (endsWith([''], conditionTokens)) {
			setConditionTokens(init(conditionTokens));
		}
		setActiveTokenIndex(key);
	};

	// handle condition styled field input keydown
	let removeCondSetLimit = 0;
	const handleKeyDown = (e) => {
		removeCondSetLimit = e.key === 'Backspace' ? removeCondSetLimit + 1 : removeCondSetLimit;

		if (removeCondSetLimit > 4) {
			setIsRemoving(true);
		}
		if (removeCondSetLimit >= 30) {
			setConditionTokens(['']);
			setActiveTokenIndex(0);
			setIsRemoving(false);
			removeCondSetLimit = 0;
		}
	};

	// handle condition styled field input keyup
	const handleKeyUp = (e) => {
		removeCondSetLimit = 0;
		setIsRemoving(false);
		const nConAssetVal = e.target.value;
		const cursorPos = e.target.selectionStart;
		const backKey = e.key === 'Backspace' || e.key === 'ArrowLeft';
		const stepBack = stepBackAllowed && !cursorPos && activeTokenIndex !== 0 && backKey;
		const isValValid = nConAssetVal.match(/^ *$/g) === null;
		const isOperatorIncorrect =
			(activeTokenIndex % 4 === 1 && !includes(nConAssetVal, RELATIONAL_OPERATORS)) ||
			(activeTokenIndex % 4 === 3 && !includes(nConAssetVal, LOGICAL_OPERATORS));

		// handling step backward to the next condition asset
		setStepBackAllowed((backKey && !cursorPos) || false);

		if (stepBack) {
			if (conditionTokens.length - 1 === activeTokenIndex && nConAssetVal.length === 0) {
				setConditionTokens(init(conditionTokens));
			}
			setActiveTokenIndex(activeTokenIndex - 1);
			return;
		}

		// handling step forward to the next condition asset
		const stepForward =
			(cursorPos === nConAssetVal.length &&
				((e.key === ' ' && activeTokenIndex % 4 !== 2) || e.key === 'ArrowRight')) ||
			e.key === 'Enter';

		if (stepForward) {
			if (isOperatorIncorrect) {
				setOperatorError(
					<span style={{ color: red[500] }}>
						<FormattedMessage {...messages.invalidOperator} />
					</span>
				);
				return;
			}
			if (conditionTokens[activeTokenIndex + 1] === undefined && isValValid) {
				setConditionTokens(
					concat(
						adjust(activeTokenIndex, () => e.target.value, conditionTokens),
						['']
					)
				);
			}
			if (isValValid) {
				setActiveTokenIndex(activeTokenIndex + 1);
			}
		}

		if (!isOperatorIncorrect) {
			setOperatorError(null);
		}
	};

	// vallidation styles
	let validationStyle = 'boundingDefault';
	if (errorCondSetInvalid[0] !== null && !displaySuggestions) {
		validationStyle = 'boundingError';
	} else if (errorConditionTokens[0] !== null && !displaySuggestions) {
		validationStyle = 'boundingError';
	} else if (displaySuggestions) {
		validationStyle = 'boundingSuccess';
	}

	return (
		<fieldset
			className={`${useCondSetStyles.bounding} ${useCondSetStyles[validationStyle]} MuiOutlinedInput-notchedOutline`}
			aria-hidden="true"
		>
			<legend className={useCondSetStyles.legend}>
				<FormattedMessage {...messages.conditionValue} />
			</legend>

			<div className={isRemoving ? useCondSetStyles.removingPreloader : null} />

			{conditionTokens.map((asset, key) => {
				let conf = {};
				switch (key % 4) {
					case 0: // Variables Names
						conf = {
							class: 'var',
							color: css.colors.var,
							labelText: 'newVariableName',
							tooltipText: 'newVariableName',
							required: true,
						};
						break;
					case 1: // Comparison Operators
						conf = {
							class: 'compa',
							color: css.colors.compa,
							labelText: 'comparisonOperator',
							tooltipText: 'comparisonOperator',
							required: true,
						};
						break;
					case 2: // Variables Values
						conf = {
							class: 'val',
							color: css.colors.val,
							labelText: 'newVariableValue',
							tooltipText: 'newVariableTooltip',
							required: true,
						};
						break;
					case 3: // Logical Operators
						conf = {
							class: 'logic',
							color: css.colors.logic,
							labelText: 'logicalOperator',
							tooltipText: 'logicalOperator',
						};
						break;
					default:
				}

				const isCondAssetOpened = activeTokenIndex === key;
				const isDisplay3dots = asset.length > 20 && !isCondAssetOpened && useCondSetStyles.tooLongTag;

				return (
					<span key={'styledCond' + key} className={`${useSuggestionsStyles[conf.class]} ${useCondSetStyles.cell}`}>
						{isCondAssetOpened && (
							<TextField
								className={condTextFieldStyles(operatorError ? red[500] : conf.color)}
								autoFocus
								style={{ display: !displaySuggestions && 'none' }}
								error={!!operatorError}
								inputRef={condTextFieldRef}
								value={condCurrAsset}
								label={operatorError || <FormattedMessage {...messages[conf.labelText]} />}
								onKeyDown={handleKeyDown}
								onKeyUp={handleKeyUp}
								required={conf.required}
								variant={'outlined'}
								size="small"
								onChange={(e) => setConditionTokens(update(activeTokenIndex, e.target.value, conditionTokens))}
							/>
						)}
						<Tooltip
							style={{
								display: isCondAssetOpened && (asset === '' || displaySuggestions) && 'none',
							}}
							title={
								<div className={useCondSetStyles.assetTooltip}>
									<em>
										<FormattedMessage {...messages[conf.tooltipText]} /> :
									</em>
									<br />
									<div className={useCondSetStyles.assetTooltipVal} style={{ backgroundColor: conf.color }}>
										{asset}
									</div>
								</div>
							}
							placement={'top'}
						>
							<span
								onClick={() => conAssetOnClick(key)}
								className={`${useSuggestionsStyles.tag} ${useCondSetStyles.tag} tag ${
									isDisplay3dots && isDisplay3dots
								}`}
							>
								{asset}
							</span>
						</Tooltip>
					</span>
				);
			})}
		</fieldset>
	);
};

StyledCondition.propTypes = {
	conditionTokens: PropTypes.array,
	activeTokenIndex: PropTypes.number,
	setConditionTokens: PropTypes.func,
	setActiveTokenIndex: PropTypes.func,
	displaySuggestions: PropTypes.bool,
	errorCondSetInvalid: PropTypes.array,
	errorConditionTokens: PropTypes.array,
};

// component
const AddEditConditionModal = (props) => {
	const [targetNodeValidationMessage, setTargetNodeValidationMessage] = useState(null);
	const [errorConditionTokens, setErrorConditionTokens] = useState(null);
	const [errorCondSetInvalid, setErrorCondSetInvalid] = useState(null);
	const [condTargetNode, setCondTargetNode] = useState(props.condTargetNodeId || '');
	const [conditionTokens, setConditionTokens] = useState(props.condValue ? parseCondition(props.condValue) : ['']);
	const [activeTokenIndex, setActiveTokenIndex] = useState(0);
	const [displaySuggestions, setDisplaySuggestions] = useState(false);
	const [usePythonEditor, setUsePythonEditor] = useState(false);
	const intl = useIntl();
	const dispatch = useDispatch();
	const allNodes = useSelector(getNodeIds);
	const nodeIds = useSelector(getAvailableTransitionNodes(props.nodeId));
	const node = useSelector(getNode(props.nodeId));

	const modalTitleText = <FormattedMessage {...messages[props.condValue ? 'editCondition' : 'newCondition']} />;

	// display / hide suggestions
	const styledConditionBound = useRef();

	const checkClick = (e) => {
		if (styledConditionBound.current) {
			setDisplaySuggestions(styledConditionBound.current.contains(e.target));
		}
	};

	// conditionTokens validity check
	const checkConditionTokensValidity = () => {
		const testLength = last(conditionTokens) === '' ? 0 : 3;
		setErrorCondSetInvalid(
			conditionTokens.length % 4 !== testLength && conditionTokens.length !== 1 ? (
				<span style={validationStyles.error}>
					<FormattedMessage {...messages.invalidConditionSet} />
				</span>
			) : null
		);
	};

	useEffect(() => {
		checkConditionTokensValidity();
	}, [displaySuggestions]);

	useEffect(() => {
		if (displaySuggestions) {
			setErrorConditionTokens(validateIsEmpty(conditionTokens[0]));
		}
	}, [conditionTokens]);

	const handleKeyDown = (e) => {
		if (e.key === 'Tab') {
			e.preventDefault();
			setDisplaySuggestions(true);
		}
	};

	// handling adding/editing condition to the store
	const handleAdd = () => {
		setErrorConditionTokens(validateIsEmpty(conditionTokens[0]));
		setTargetNodeValidationMessage(
			validateNodeName(condTargetNode) ||
				validateShouldBeNodeCreated(condTargetNode, allNodes) ||
				validateGroupCompliance(condTargetNode, nodeIds)
		);
		checkConditionTokensValidity();

		if (
			// Don't allow adding if the node exists but cannot be selected
			// However, if a node of that name does not exist in any of the groups, let it be created and the condition be valid
			(validateGroupCompliance(condTargetNode, nodeIds) && !validateShouldBeNodeCreated(condTargetNode, allNodes)) ||
			validateNodeName(condTargetNode) ||
			validateIsEmpty(conditionTokens[0]) ||
			errorCondSetInvalid !== null
		) {
			return;
		}

		dispatch(registerStateHistory());
		let prefixedNodeName = '';
		if (validateShouldBeNodeCreated(condTargetNode, allNodes)) {
			dispatch(clearSelectedNodes());
			dispatch(createNode({ newNodeId: condTargetNode, shouldShowActiveNode: true, baseNodeId: props.nodeId }));
			// The nodeId gets prefixed by the respective group name during creation if groupView is on
			if (node.group) {
				prefixedNodeName = `${node.group}_${condTargetNode}`;
			}
		}

		if (props.condValue) {
			dispatch(
				updateRemoveCondition(
					props.nodeId,
					props.condId,
					stringifyCondition(conditionTokens),
					prefixedNodeName || condTargetNode
				)
			);
		} else {
			dispatch(createCondition(props.nodeId, stringifyCondition(conditionTokens), prefixedNodeName || condTargetNode));
		}

		dispatch(hideModal());
	};

	// setting condition whole set / asset value and moving to the next asset
	const setAssetValAndNext = (assetNewVal, replaceWholeCondSet) => {
		const _condSetVal = replaceWholeCondSet
			? parseCondition(assetNewVal)
			: flatten([[update(activeTokenIndex, parseCondition(assetNewVal), conditionTokens)], ['']]);
		setConditionTokens(_condSetVal);
		setActiveTokenIndex(_condSetVal.length - 1);
	};

	return (
		<div onClick={(e) => checkClick(e)}>
			<SaveCloseModalTemplate title={modalTitleText} saveBtnTitle={modalTitleText} onSave={handleAdd}>
				<FormControlLabel
					label={intl.formatMessage(messages.usePythonEditor)}
					control={
						<Switch checked={usePythonEditor} onChange={(e) => setUsePythonEditor(e.target.checked)} color="primary" />
					}
				/>
				<Autocomplete
					freeSolo
					disableClearable
					options={nodeIds}
					getOptionLabel={String}
					inputValue={condTargetNode}
					renderInput={(params) => (
						<TextField
							{...params}
							error={targetNodeValidationMessage?.key === 'itsEmpty' ?? false}
							helperText={targetNodeValidationMessage}
							label={<FormattedMessage {...messages.conditionTargetNode} />}
							variant={'outlined'}
							fullWidth
							autoFocus
							onKeyDown={handleKeyDown}
							onChange={(e) => {
								setCondTargetNode(normalizeNodeId(e.target.value));
								setTargetNodeValidationMessage(
									validateNodeName(e.target.value) ||
										validateShouldBeNodeCreated(e.target.value, allNodes) ||
										validateGroupCompliance(e.target.value, nodeIds)
								);
							}}
							required
						/>
					)}
					onChange={(e, v) => {
						setCondTargetNode(normalizeNodeId(v));
						setTargetNodeValidationMessage(
							validateNodeName(v) || validateShouldBeNodeCreated(v, allNodes) || validateGroupCompliance(v, nodeIds)
						);
					}}
				/>
				{usePythonEditor ? (
					<PythonEditor
						value={stringifyCondition(conditionTokens)}
						placeholder={intl.formatMessage(messages.newVariableValue)}
						onChange={(value) => setConditionTokens(parseCondition(value))}
					/>
				) : (
					<div ref={styledConditionBound}>
						<StyledCondition
							conditionTokens={conditionTokens}
							activeTokenIndex={activeTokenIndex}
							setConditionTokens={setConditionTokens}
							setActiveTokenIndex={setActiveTokenIndex}
							displaySuggestions={displaySuggestions}
							errorCondSetInvalid={[errorCondSetInvalid]}
							errorConditionTokens={[errorConditionTokens]}
						/>
						<div style={{ position: 'relative', left: '15px', top: '-13px', display: 'inline' }}>
							{errorConditionTokens}
							{errorConditionTokens && <br />}
							{errorCondSetInvalid}
						</div>
						{displaySuggestions && (
							<ConditionSuggestions
								conditionTokens={conditionTokens}
								activeTokenIndex={activeTokenIndex}
								setAssetValAndNext={setAssetValAndNext}
							/>
						)}
					</div>
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

AddEditConditionModal.propTypes = {
	condTargetNodeId: PropTypes.string,
	condValue: PropTypes.string,
	nodeId: PropTypes.string,
	condId: PropTypes.number,
};

export default AddEditConditionModal;
