import { produce } from 'immer';
import { isEmpty } from 'ramda';
import { ASYNC_ACTION_STATES } from '../../utils/action-utils';
import { actionTypes as actionTypesModelList } from '../model-table/actions';
import { actionTypes } from './actions';

const EMPTY_MATRIX_DATA = { intents: [], stats: [], rows: [] };
const DEFAULT_STATE = {
	request: {
		state: ASYNC_ACTION_STATES.READY,
	},
	matrix: EMPTY_MATRIX_DATA,
};

const confusionMatrix = produce((state, { type, payload }) => {
	if (type === actionTypes.CACHED_MATRIX_SUCCESS) {
		if (isEmpty(payload.intents)) {
			state.request = { state: ASYNC_ACTION_STATES.READY };
			state.matrix = EMPTY_MATRIX_DATA;
		} else {
			state.request = { state: ASYNC_ACTION_STATES.SUCCESS };
			state.matrix = payload;
		}
	} else if (type === actionTypes.MATRIX_PENDING) {
		state.request = { state: ASYNC_ACTION_STATES.PENDING };
		state.matrix = EMPTY_MATRIX_DATA;
	} else if (type === actionTypesModelList.FETCH_MODEL_VERSION_SUCCESS) {
		// reset matrix for new model
		return DEFAULT_STATE;
	} else if (state.request.state !== ASYNC_ACTION_STATES.PENDING) {
		// ignore matrix SUCCESS/FAIL responses if the matrix is already generated
	} else if (type === actionTypes.MATRIX_SUCCESS) {
		state.request = { state: ASYNC_ACTION_STATES.SUCCESS, response: payload };
		state.matrix = payload;
	} else if (type === actionTypes.MATRIX_FAIL) {
		state.request = { state: ASYNC_ACTION_STATES.FAIL, response: payload };
		state.matrix = EMPTY_MATRIX_DATA;
	} else if (type === actionTypes.ABORT_MATRIX_SUCCESS) {
		return DEFAULT_STATE;
	} else if (type === actionTypes.ABORT_MATRIX_PENDING) {
		state.request = { state: ASYNC_ACTION_STATES.PENDING, response: payload };
		state.matrix = EMPTY_MATRIX_DATA;
	} else if (type === actionTypes.ABORT_MATRIX_FAIL) {
		state.request = { state: ASYNC_ACTION_STATES.PENDING, response: payload };
		state.matrix = EMPTY_MATRIX_DATA;
	}
}, DEFAULT_STATE);

export default confusionMatrix;
