import { LinearProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import JSZip from 'jszip';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { fetchRecording } from '../../redux/sip/actions';
import blobToBase64 from '../../utils/blobToBase64';
import saveBlob from '../../utils/saveBlob';
import { areCallDataAvail } from '../MaterialDashboard/Outbound/CampaignDetail';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const errorHandler = (err) => {
	Sentry.captureException(err);
	console.error(err);
};

const DownloadCampRecsModal = ({ campaign, isSip }) => {
	const [downloadIId, setdownloadIId] = useState(0);
	const [progress, setProgress] = useState(0);
	const [files, setFiles] = useState([]);

	const [callIds] = useState(
		Object.values(campaign.phoneCalls)
			.filter((call) => areCallDataAvail(call.status))
			.map((call) => call.callId)
	);
	const downloadsTotal = size(callIds);
	const progressTotal = Math.round((downloadIId / downloadsTotal) * 100);
	const isDownloadDone = downloadsTotal === downloadIId;
	const dispatch = useDispatch();
	const currCallId = callIds[downloadIId];

	useEffect(() => {
		if (!isDownloadDone) {
			dispatch(fetchRecording(currCallId, isSip)).then((data) => {
				let url;
				if (isSip) {
					url = 'data:audio/wav;base64,' + data.payload.data.callRecordings[0].url;
				} else {
					url = data.payload.data.callRecordings[0].url + '.wav';
				}
				axios({
					url,
					onDownloadProgress(progress) {
						setProgress(Math.round((progress.loaded / progress.total) * 100));
					},
					responseType: 'blob',
				}).then(async (response) => {
					setFiles({ ...files, [currCallId]: response.data });
					setdownloadIId(downloadIId + 1);
					setProgress(0);
				});
			}, errorHandler);
		} else if (downloadIId !== 0 && isDownloadDone) {
			(async () => {
				const zip = new JSZip();
				const dir = zip.folder(campaign.name);

				for (const [fileName, file] of Object.entries(files)) {
					try {
						const fileBase64 = await blobToBase64(file);
						dir.file(fileName + '.wav', fileBase64, { base64: true });
					} catch (err) {
						errorHandler(err);
					}
				}

				zip.generateAsync({ type: 'blob' }).then((content) => {
					saveBlob(content, campaign.name + '.zip');
				});
			})();
		}
	}, [downloadIId, isDownloadDone]);

	return (
		<SaveCloseModalTemplate
			title={
				<FormattedMessage
					{...messages.downloadingVoiceRecording}
					values={{
						detail: `${downloadIId > downloadsTotal - 1 ? downloadsTotal : downloadIId + 1}/${downloadsTotal}`,
					}}
				/>
			}
			isSaveBtnVisible={false}
		>
			{isDownloadDone ? (
				<FormattedMessage {...messages.downloadingCompleted} />
			) : (
				<>
					<FormattedMessage {...messages.currVoiceRec} /> <strong>{currCallId}.wav</strong>: <br />
					<LinearProgress variant="determinate" value={progress} />
					<br />
					<FormattedMessage {...messages.total} /> ({progressTotal}%):
					<br />
					<LinearProgress variant="determinate" value={progressTotal} />
				</>
			)}
		</SaveCloseModalTemplate>
	);
};

DownloadCampRecsModal.propTypes = {
	campaign: PropTypes.object,
	isSip: PropTypes.bool,
};

export default DownloadCampRecsModal;
