import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../intl';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const DeleteModal = ({ title, buttonTitle, message, handler }) => {
	const dispatch = useDispatch();

	const handleDelete = () => {
		if (typeof handler === 'function') {
			handler();
		}

		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={title || buttonTitle}
				saveBtnTitle={buttonTitle || <FormattedMessage {...messages.deleteModalButton} />}
				onSave={handleDelete}
			>
				<span>{message || <FormattedMessage {...messages.deleteModalMessage} />}</span>
			</SaveCloseModalTemplate>
		</div>
	);
};

DeleteModal.propTypes = {
	handler: PropTypes.func.isRequired,
	title: PropTypes.string,
	buttonTitle: PropTypes.string,
	message: PropTypes.string,
};

export default DeleteModal;
