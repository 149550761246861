import { keys, values, path } from 'ramda';
import { ID } from './actions';

export const getCampaigns = path([ID, ID, 'campaigns']);
export const getCampaign = (id) => path([ID, ID, 'campaigns', id]);
export const getCampaignName = (id) => path([ID, ID, 'campaigns', id, 'name']);
export const getCallerNumber = (id) => path([ID, ID, 'campaigns', id, 'phoneNumberTwilio']);
export const getDefaultCallStatuses = path([ID, ID, 'callStatuses']);
export const getTrunkAccount = (id) => path([ID, ID, 'campaigns', id, 'trunkAccount']);
export const getMetaDataPattern = (campaign) => {
	const pattern = { ...values(campaign?.phoneCalls ?? {})?.[0]?.metaData } ?? {};
	for (const param of keys(pattern)) {
		pattern[param] = '';
	}
	return pattern;
};

export const campaignsInitiallyFetched = path([ID, 'fetchingCampaigns', 'initiallyFetched']);
export const campaignsFetching = path([ID, 'fetchingCampaigns', 'isFetching']);
