import { createAction } from 'redux-api-middleware';
import { createActionsMap } from '../../utils/action-utils';
import { psf } from '../utils';
import { ID } from './selectors';

export const actionTypes = createActionsMap(ID, [...psf('FETCH_EMAILS'), ...psf('SEND_EMAIL_RESPONSE')]);

export const fetchEmailList = () =>
	request('GET', 'https://analyse-received-email.azurewebsites.net/api/list-emails', [
		actionTypes.FETCH_EMAILS_PENDING,
		actionTypes.FETCH_EMAILS_SUCCESS,
		actionTypes.FETCH_EMAILS_FAIL,
	]);

export const sendResponse = (receiver, subject, body) =>
	request(
		'POST',
		'https://prod-35.northeurope.logic.azure.com:443/workflows/a1672c6bb0804698be5a619a88485faa/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SfHVMTFyHyYwfQZL_MMLC9-gzDG5-IcKyvkWw7CRiHk',
		[
			actionTypes.SEND_EMAIL_RESPONSE_PENDING,
			actionTypes.SEND_EMAIL_RESPONSE_SUCCESS,
			actionTypes.SEND_EMAIL_RESPONSE_FAIL,
		],
		{ receiver, subject, body: body.replaceAll('\n', '<br />') }
	);

function request(method, endpoint, types, data) {
	return createAction({
		endpoint,
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		method,
		body: JSON.stringify(data),
		types: types,
	});
}
