import * as Sentry from '@sentry/react';
import { produce } from 'immer';
import jsYaml from 'js-yaml';
import { takeRight, unset } from 'lodash';
import escapeRegExp from 'lodash/escapeRegExp';
import {
	append,
	assocPath,
	clamp,
	clone,
	compose,
	concat,
	difference,
	dissocPath,
	dropLastWhile,
	equals,
	filter,
	forEachObjIndexed,
	fromPairs,
	has,
	identity,
	includes,
	indexOf,
	invertObj,
	isEmpty,
	last,
	map,
	mapObjIndexed,
	mergeDeepRight,
	o,
	omit,
	pick,
	prepend,
	remove,
	sortBy,
	toPairs,
	update,
	without,
} from 'ramda';
/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as fromConfigs from '../../core/configs';
import {
	CONFIGURATION,
	FLOW,
	FLOW_EDITOR_UI,
	GLOBAL_START_NODE,
	GOODBOT,
	GOTO_TRANSITION_TYPES,
	INTENTS,
	LEGACY_INTENTS_NAME_IN_YAML,
	NODE_GROUPS,
	PROPERTY_TYPES,
	SEMANTICALLY_SIMILAR_INTENTS,
	STOPWORDS,
	TRANSITION_TYPES,
	VOCABULARY,
	YAML_EDITOR_UI,
} from '../../core/configs';
import { INTL, messages } from '../../intl';
import addOrRemoveFromArray from '../../utils/addOrRemoveFromArray';
import delObjNumKeyKeepSequence from '../../utils/delObjNumKeyKeepSequence';
import { normalizeNodeId } from '../../utils/normalization';
import { actionTypes as authActionTypes } from '../auth/actions';
import { actionTypes as modelTableActionTypes } from '../model-table/actions';
import { getSelectedModelName, selectedModelId, getSelectedModel } from '../model-table/selectors';
import { actionTypes } from './actions';
import {
	getCanvasZoom,
	getColorsFromFlow,
	getGlobalStartNodeId,
	getNodes,
	getNodesConditions,
	getNodesProperty,
	getNodeVariables,
	getYamlEditorUiConfig,
	getYamlFromModel,
	getYamlFromModelFormatted,
	ID,
	MODEL_DATA_FIELDS,
} from './selectors';
import {
	appendDuplicitySuffix,
	findCommentsInRawYaml,
	findRowInRawYaml,
	hasTooManyNodes,
	matchNodeIdWithTemplateColor,
	removeNullCoords,
} from './utils';

// A subset of the whole state that is parsed to and from YAMl
const DATA_TO_PARSE_FROM_AND_TO_YAML = [
	CONFIGURATION,
	FLOW,
	INTENTS,
	SEMANTICALLY_SIMILAR_INTENTS,
	VOCABULARY,
	STOPWORDS,
];
// A subset of node properties that are used and usable in YAML
const { responseTypes, propertyTypes, transitionTypes } = fromConfigs[GOODBOT];

const DEFAULT_MODEL_DATA = {
	[FLOW]: {},
	[NODE_GROUPS]: {
		activeNodeGroupId: null,
		groups: [],
	},
	[INTENTS]: {},
	[SEMANTICALLY_SIMILAR_INTENTS]: {},
	[CONFIGURATION]: {
		project: null,
		project_id: null,
		organization_id: null,
		description: null,
		language: 'cs',
		[GLOBAL_START_NODE]: '',
		allow_jumping: false,
		allow_jumping_to_nodes: [],
		classifier: {
			aspell: 'replace',
			lemmatizer: null,
			stopwords: true,
			tokenizer: 'nist',
		},
		disabled_utterance_transformers: [],
		intent_relative_threshold: 10,
		intent_threshold: 0.6,
		max_delay_milliseconds: 2500,
		original_intent_threshold: 0.000001,
		speed_coefficient: 0.3,
		train: {
			epoch: 3,
			lr: 0.1,
		},
	},
	modelConfigType: GOODBOT,
	validationErrors: [],
	activeNodeId: null,
	[FLOW_EDITOR_UI]: {
		selectedNodes: [],
		isModelLocked: false,
		showColorCaptions: false,
		colorCaptions: {},
		zoomLevel: 2,
		// Global offset when no nodeGroup is active
		canvasOffset: { x: 0, y: 0 },
		canvasZoom: 100,
		canvasHeaderHeight: 0,
		closestNodesTarget: null,
		displayClosestNodesOnly: false,
		hiddenNodes: [],
		diagrams: {
			positions: {},
			canvasOffset: {},
			canvasZoom: {},
		},
	},
	[YAML_EDITOR_UI]: {
		shouldFormat: false,
		doFormat: false,
		comments: [],
	},
	[VOCABULARY]: {},
	[STOPWORDS]: {},
};

const DEFAULT_STATE = {
	rawYaml: '',
	rawYamlErrors: [],
	rawYamlWarnings: [],
	...DEFAULT_MODEL_DATA,
	/**
	 * This is kind of the hack for autosave feature. We need to save updated version every time it changes
	 * but only if it changes as an reaction to user action, not importing from YAML or API.
	 * So this flag only changes when some manual change is dispatched and we can subscribe to it later
	 * to store data into the local storage.
	 */
	manualChangeVersion: 0,
	pastStateVersions: [],
	futureStateVersions: [],
};

export const DEFAULT_NODE_GROUP_DATA = {
	name: '',
	startNode: null,
	nodes: [],
	color: null,
	referenceNodeDiagramPositions: {},
};

const DEFAULT_NODE_DATA = {
	color: null,
	group: null,
};

const DEFAULT_DISTANCE_FROM_START_NODE = {
	x: 0,
	y: 200,
};

const model = produce((state, { type, payload }, fullState) => {
	switch (type) {
		// PROJECT AND AUTH
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		// TODO: This case wont be needed once ql solutio1n implemented (Config)
		case modelTableActionTypes.CREATE_MODEL_SUCCESS: {
			const model = payload?.entities?.models[payload?.result];
			return {
				...state,
				...DEFAULT_MODEL_DATA,
				configuration: {
					...DEFAULT_MODEL_DATA.configuration,
					project: model.name,
					project_id: model.id ?? null,
					organization_id: model.organization.id ?? null,
					description: model.description,
				},
			};
		}
		case modelTableActionTypes.SELECT_MODEL_VERSION:
			// Readonly object...
			const versionData = clone(fullState.modelTable.modelTable.entities.versions[payload.id]?.data);

			if (!versionData) {
				return { ...state, ...DEFAULT_MODEL_DATA };
			}
			const newModelState = {
				...DEFAULT_MODEL_DATA,
				...state,
				...pick(MODEL_DATA_FIELDS, versionData),
				activeNodeId: null,
				// Don't persist the history of a different version or perhaps even model
				pastStateVersions: [],
				futureStateVersions: [],
				[FLOW_EDITOR_UI]: {
					...state[FLOW_EDITOR_UI],
					...versionData[FLOW_EDITOR_UI],
					// Explicitly get rid of the captions from the previous project
					colorCaptions: versionData[FLOW_EDITOR_UI].colorCaptions || {},
					selectedNodes: [],
					isModelLocked: false,
					displayClosestNodesOnly: versionData?.[NODE_GROUPS]?.groups.length <= 1 && hasTooManyNodes(versionData[FLOW]),
				},
			};
			// Backwards compatibility functions
			normalizeProjectData(newModelState, payload);
			// project_id should be set after normalize. We already have mapping of models to organizations, project_id serves just for indexing
			// organization_id can't be set the same way as project_id - the payload is querying for only project_id
			newModelState[CONFIGURATION].organization_id =
				fullState.modelTable.modelTable.entities.models[newModelState[CONFIGURATION].project_id]?.organization?.id ??
				null;
			addDefaultNodeProperties(newModelState);
			addDefaultGroup(newModelState);
			addGroupReferencesToNodes(
				newModelState[FLOW],
				newModelState[NODE_GROUPS],
				newModelState[FLOW_EDITOR_UI].diagrams.positions
			);
			addNodePositionsInDiagram(newModelState);
			refreshColorCaptions(newModelState);

			return newModelState;
		case modelTableActionTypes.VALIDATE_MODEL_VERSION_SUCCESS:
			return {
				...state,
				validationErrors: payload.data.validateModelVersion.errors,
			};
		case modelTableActionTypes.VALIDATE_MODEL_VERSION_FAIL:
			return {
				...state,
				validationErrors: [],
			};
		case modelTableActionTypes.TRAIN_CONFIG_FAIL:
			/* eslint-disable */
			const errors = payload.errors[0]?.extensions?.errors
				? payload.errors[0]?.extensions?.errors
				: payload.errors[0]?.message
				? [{ message: payload.errors[0].message, severity: 'error' }]
				: [];
			/* eslint-enable */
			state.rawYamlErrors.push(
				...errors
					.filter((item) => item.severity === 'error')
					.map((error) => ({ text: error.message, row: 0, column: 0, isApiError: true }))
			);
			state.rawYamlWarnings.push(
				...errors
					.filter((item) => item.severity === 'warning')
					.map((warning) => ({ text: warning.message, row: 0, column: 0, isApiError: true }))
			);
			break;
		case actionTypes.REMOVE_PREVIOUS_MODEL:
			return { ...state, ...DEFAULT_MODEL_DATA };
		case actionTypes.UPDATE_YAML_DATA:
			state.rawYaml = payload.rawYaml;
			break;
		case actionTypes.SET_PROJECT_DATA:
			return setProjectData(state, payload.data);
		case actionTypes.IMPORT_FROM_JSON:
			const newState = setProjectData(state, payload.data);
			// Backwards compatibility functions
			normalizeProjectData(newState);
			newState[CONFIGURATION].organization_id =
				fullState.modelTable.modelTable.entities.models[newState[CONFIGURATION].project_id]?.organization?.id ?? null;
			addDefaultNodeProperties(newState);
			addDefaultGroup(newState);
			addGroupReferencesToNodes(newState[FLOW], newState[NODE_GROUPS], newState[FLOW_EDITOR_UI].diagrams.positions);
			addNodePositionsInDiagram(newState);
			refreshColorCaptions(newState);
			return newState;
		case modelTableActionTypes.SELECT_MODEL:
			return DEFAULT_STATE;
		case actionTypes.CREATE_UPDATE_CONFIG_PROPERTY:
			let path = payload.path.split('.');
			path = prepend('configuration', path);
			return assocPath(path, payload.value, state);
		case actionTypes.SET_YAML_UI_CONFIG:
			return {
				...state,
				[YAML_EDITOR_UI]: {
					...state[YAML_EDITOR_UI],
					...payload.config,
				},
			};
		case actionTypes.REGISTER_STATE_HISTORY:
			return {
				...state,
				// Persist only the last 3 changes
				pastStateVersions: [...takeRight(state.pastStateVersions, 3), state],
				futureStateVersions: [],
			};
		case actionTypes.UNDO_STATE_HISTORY:
			const previousState = last(state.pastStateVersions);
			const newPast = state.pastStateVersions.slice(0, state.pastStateVersions.length - 1);
			return {
				...previousState,
				pastStateVersions: newPast,
				futureStateVersions: [state, ...state.futureStateVersions],
			};
		case actionTypes.REDO_STATE_HISTORY:
			const nextState = state.futureStateVersions[0];
			const newFuture = state.futureStateVersions.slice(1);
			return {
				...nextState,
				pastStateVersions: [...state.pastStateVersions, state],
				futureStateVersions: newFuture,
			};
		case actionTypes.SET_SHOW_COLOR_CAPTIONS: {
			state[FLOW_EDITOR_UI].showColorCaptions = payload.showColorCaptions;
			break;
		}
		case actionTypes.SET_COLOR_CAPTIONS: {
			const { captions } = payload;

			state[FLOW_EDITOR_UI].colorCaptions = {
				...state[FLOW_EDITOR_UI].colorCaptions,
				...captions,
			};
			break;
		}
		case actionTypes.REMOVE_COLOR_CAPTION: {
			Reflect.deleteProperty(state[FLOW_EDITOR_UI].colorCaptions, payload.color);
			break;
		}
		case actionTypes.REFRESH_COLOR_CAPTIONS: {
			refreshColorCaptions(state);
			break;
		}
		case actionTypes.SET_VOCABULARY: {
			const { vocabulary } = payload;
			state[VOCABULARY] = vocabulary;
			break;
		}
		case actionTypes.SET_STOPWORDS: {
			const { stopwords } = payload;
			state[STOPWORDS] = stopwords;
			break;
		}

		// DIAGRAM CONTROLS
		case actionTypes.SET_ZOOM_LEVEL:
			state[FLOW_EDITOR_UI].zoomLevel = payload.zoomLevel;
			break;
		case actionTypes.SET_ACTIVE_NODE_GROUP:
			state[NODE_GROUPS].activeNodeGroupId = payload.groupId;
			break;
		case actionTypes.SET_ACTIVE_NODE:
			state.activeNodeId = payload.id;
			break;
		case actionTypes.TOGGLE_LOCK_MODEL:
			state[FLOW_EDITOR_UI].isModelLocked = payload.isModelLocked;
			break;
		case actionTypes.UPDATE_CANVAS_ZOOM:
			const { data } = payload;
			let updatedCanvasZoom = data.canvasZoom;
			if (data.direction) {
				updatedCanvasZoom = updateCanvasZoom(data.direction, state);
			}
			if (data.groupId) {
				state[FLOW_EDITOR_UI].diagrams.canvasZoom[data.groupId] = updatedCanvasZoom;
			} else {
				state[FLOW_EDITOR_UI].canvasZoom = updatedCanvasZoom;
			}
			break;
		case actionTypes.SET_CANVAS_HEADER_HEIGHT:
			state[FLOW_EDITOR_UI].canvasHeaderHeight = payload.value;
			break;
		case actionTypes.UPDATE_CANVAS_OFFSET:
			if (payload.groupId) {
				state[FLOW_EDITOR_UI].diagrams.canvasOffset[payload.groupId] = payload.canvasOffset;
			} else {
				state[FLOW_EDITOR_UI].canvasOffset = payload.canvasOffset;
			}
			break;
		case actionTypes.SET_DIAGRAM_POSITIONS:
			for (const [nodeId, { diagramPosition, isReferenceNode, referencedGroupId }] of Object.entries(
				payload.positions
			)) {
				if (isReferenceNode) {
					const activeNodeGroupId = state[NODE_GROUPS].activeNodeGroupId;
					const activeNodeGroup = state[NODE_GROUPS].groups.find((group) => group.name === activeNodeGroupId);
					if (activeNodeGroup) {
						// GroupId and not nodeId is used so that groupStartNode can be changed without replacing this entry
						activeNodeGroup.referenceNodeDiagramPositions[referencedGroupId] = diagramPosition;
					}
				} else {
					state[FLOW_EDITOR_UI].diagrams.positions[nodeId] = diagramPosition;
				}
			}
			break;
		case actionTypes.TOGGLE_DISPLAY_CLOSEST_NODES_ONLY:
			return {
				...state,
				[FLOW_EDITOR_UI]: {
					...state[FLOW_EDITOR_UI],
					displayClosestNodesOnly: payload.bool,
				},
			};
		case actionTypes.UPDATE_CLOSEST_NODES_TARGET:
			return {
				...state,
				[FLOW_EDITOR_UI]: {
					...state[FLOW_EDITOR_UI],
					closestNodesTarget: payload.nodeId,
				},
			};

		// NODE
		case actionTypes.SET_GLOBAL_START_NODE:
			state[CONFIGURATION][GLOBAL_START_NODE] = payload.id;
			break;
		case actionTypes.ADD_TO_SELECTED_NODES:
			for (const nodeId of payload.ids) {
				if (!state[FLOW_EDITOR_UI].selectedNodes.includes(nodeId)) {
					state[FLOW_EDITOR_UI].selectedNodes.push(nodeId);
				}
			}
			break;
		case actionTypes.REMOVE_FROM_SELECTED_NODES:
			state[FLOW_EDITOR_UI].selectedNodes = state[FLOW_EDITOR_UI].selectedNodes.filter(
				(selectedNodeId) => payload.id !== selectedNodeId
			);
			break;
		case actionTypes.CLEAR_SELECTED_NODES:
			state[FLOW_EDITOR_UI].selectedNodes = [];
			break;
		case actionTypes.CREATE_UPDATE_NODE:
			state[FLOW][payload.id] = { ...DEFAULT_NODE_DATA, ...payload.node };
			break;
		case actionTypes.COPY_SELECTED_NODES: {
			const groupsConfig = state[NODE_GROUPS];

			const copiedGroups = [];
			let copiedNodes = {};

			for (const selectedNodeId of payload.selectedNodes) {
				const group = groupsConfig.groups.find((group) => group.name === selectedNodeId);

				if (group) {
					const newGroup = {
						name: appendDuplicitySuffix(`COPY_${group.name}`, Object.keys(state[FLOW])),
						nodes: group.nodes.map((nodeId) => appendDuplicitySuffix(`COPY_${nodeId}`, Object.keys(state[FLOW]))),
					};

					newGroup.startNode = newGroup.nodes[0];

					const newNodes = group.nodes.reduce(
						(acc, nodeId) => ({
							...acc,
							[appendDuplicitySuffix(`COPY_${nodeId}`, state)]: {
								...clone(state[FLOW][nodeId]),
								group: newGroup.name,
							},
						}),
						{}
					);
					copiedGroups.push(newGroup);
					copiedNodes = { ...copiedNodes, ...newNodes };
				} else {
					const copiedNodeId = appendDuplicitySuffix(`COPY_${selectedNodeId}`, Object.keys(state[FLOW]));
					if (state[FLOW][selectedNodeId].group) {
						const group = groupsConfig.groups.find((group) => group.name === state[FLOW][selectedNodeId].group);
						group.nodes.push(copiedNodeId);
					}
					copiedNodes = {
						...copiedNodes,
						[copiedNodeId]: clone(state[FLOW][selectedNodeId]),
					};
				}
			}

			state[FLOW] = { ...state[FLOW], ...copiedNodes };
			state[NODE_GROUPS].groups.push(...copiedGroups);

			if (copiedGroups.length) {
				state.activeNodeGroupId = copiedGroups[0];
			} else if (!isEmpty(copiedNodes)) {
				state.activeNodeId = Object.keys(copiedNodes)[0];
				state[FLOW_EDITOR_UI].selectedNodes = Object.keys(copiedNodes);
			}
			break;
		}
		case actionTypes.TOGGLE_NODES_VISIBILITY:
			state[FLOW_EDITOR_UI].hiddenNodes = addOrRemoveFromArray(payload.nodeId, state[FLOW_EDITOR_UI].hiddenNodes);
			break;
		case actionTypes.SET_NODE_COLOR: {
			const groupsConfig = state[NODE_GROUPS];
			for (const selectedNode of state[FLOW_EDITOR_UI].selectedNodes) {
				const group = groupsConfig.groups.find((group) => group.name === selectedNode);

				if (group) {
					// Use the same color for all nodes in the group unless they already have a color set
					group.color = payload.color;
					group.nodes.forEach((nodeId) => {
						if (!state[FLOW][nodeId].color) {
							state[FLOW][nodeId].color = payload.color;
						}
					});
				} else {
					state[FLOW][selectedNode].color = payload.color;
				}
			}

			refreshColorCaptions(state);

			break;
		}
		case actionTypes.REMOVE_NODE:
			const stateAfterRemovingNode = setProjectData(state, removeNode(payload.nodeId, pick(MODEL_DATA_FIELDS, state)));
			refreshColorCaptions(stateAfterRemovingNode);
			return stateAfterRemovingNode;
		/**
		 * Iterates over all nodes that fulfill the criteria and replaces the provided nodeId with a new one in transitions
		 * We cannot just search the whole flow as a string and replace every reference because we are updating only transitions of certain nodes
		 */
		case actionTypes.REPLACE_NODE_IN_TRANSITIONS:
			const { nodeId, newNodeId, filterFunction } = payload;
			const { ACTIONS, ACTIONS_SIGNALS, CONDITIONS } = TRANSITION_TYPES;

			for (const [sourceNodeId, sourceNode] of Object.entries(state[FLOW]).filter(filterFunction)) {
				// All of these transitions can be set only once, their value is the nodeId itself
				for (const TYPE of GOTO_TRANSITION_TYPES) {
					if (sourceNode[TYPE] === nodeId) {
						state[FLOW][sourceNodeId] = omit(GOTO_TRANSITION_TYPES, state[FLOW][sourceNodeId]);
						state[FLOW][sourceNodeId][TYPE] = newNodeId;
						// No need to loop through the other types, there can be only one
						break;
					}
				}

				// Each node can have multiple actions but they must target different nodes
				// Its value is an object e. g. {nodeId: intentName, anotherNodeId: intentName2}
				if (sourceNode[ACTIONS] && sourceNode[ACTIONS][nodeId]) {
					const newActions = { ...sourceNode[ACTIONS], [newNodeId]: sourceNode[ACTIONS][nodeId] };
					Reflect.deleteProperty(newActions, nodeId);
					state[FLOW][sourceNodeId][ACTIONS] = newActions;
				}

				// There are subtypes of signals, its value is an object specifying the subtypes
				// e.g. {no_input: nodeId, no_match: anotherNodeId}
				if (sourceNode[ACTIONS_SIGNALS]) {
					for (const [signalName, signalTargetNodeId] of Object.entries(sourceNode[ACTIONS_SIGNALS])) {
						if (signalTargetNodeId === nodeId) {
							state[FLOW][sourceNodeId][ACTIONS_SIGNALS] = {
								...state[FLOW][sourceNodeId][ACTIONS_SIGNALS],
								[signalName]: newNodeId,
							};
						}
					}
				}

				// Multiple conditions are valid, even targetting the same node
				// The value of conditions is a list of [condition, targetNode] lists
				if (sourceNode[CONDITIONS]) {
					sourceNode[CONDITIONS].forEach(([condition, conditionTargetNodeId], index) => {
						if (conditionTargetNodeId === nodeId) {
							state[FLOW][sourceNodeId][CONDITIONS][index] = [condition, newNodeId];
						}
					});
				}
			}
			break;
		case actionTypes.REMOVE_NODES_FROM_GROUP:
			for (const nodeId of payload.nodeIds) {
				const group = state[NODE_GROUPS].groups.find((group) => group.name === state[FLOW][nodeId].group);

				if (group) {
					group.nodes = group.nodes.filter((nodeIdInGroup) => nodeIdInGroup !== nodeId);
				}
			}
			break;
		// NODE GROUPS
		case actionTypes.CREATE_NODE_GROUP:
			state[NODE_GROUPS].groups.push(payload.group);
			break;
		case actionTypes.UPDATE_NODE_GROUP:
			const index = state[NODE_GROUPS].groups.findIndex(
				(group) => group.name === payload.groupId || group.name === payload.group.name
			);
			if (index > -1) {
				state[NODE_GROUPS].groups[index] = payload.group;
			}
			break;
		case actionTypes.REMOVE_NODE_GROUPS:
			state[NODE_GROUPS].groups = state[NODE_GROUPS].groups.filter((group) => !includes(group.name, payload.groupIds));
			break;
		case actionTypes.REPLACE_NODE_GROUPS:
			state[NODE_GROUPS].groups = payload.groups;
			break;
		case actionTypes.SET_GROUP_START_NODE:
			const selectedGroup = state[NODE_GROUPS].groups.find((group) => group.name === payload.groupId);
			if (selectedGroup) {
				selectedGroup.startNode = payload.nodeId;
			}
			break;

		// TRANSITIONS, VARIABLES, SMART FUNCTIONS
		case actionTypes.UPDATE_TRANSITIONS:
			state[FLOW][payload.nodeId][payload.transitionType] = payload.transitions;
			break;
		case actionTypes.REMOVE_TRANSITIONS:
			Reflect.deleteProperty(state[FLOW][payload.nodeId], payload.transitionType);
			break;
		case actionTypes.CREATE_UPDATE_TRANSITION_ACTION: {
			const { sourceNodeId, targetNodeId, intentName, ssiGroup } = payload;
			const node = state[FLOW][sourceNodeId];
			node[TRANSITION_TYPES.ACTIONS] = { ...node[TRANSITION_TYPES.ACTIONS], [targetNodeId]: intentName };
			updateSsiGroup(state, targetNodeId, ssiGroup);
			break;
		}
		case actionTypes.CREATE_UPDATE_TRANSITION_GOTO_REUSE_UTTERANCE: {
			const { sourceNodeId, targetNodeId, utterance } = payload;
			state[FLOW][sourceNodeId] = omit(GOTO_TRANSITION_TYPES, state[FLOW][sourceNodeId]);
			state[FLOW][sourceNodeId][TRANSITION_TYPES.GOTO_REUSE_UTTERANCE] = [targetNodeId, utterance];
			break;
		}
		case actionTypes.CREATE_UPDATE_TRANSITION_GOTO: {
			const { gotoType, sourceNodeId, targetNodeId, variable } = payload;
			state[FLOW][sourceNodeId] = omit(GOTO_TRANSITION_TYPES, state[FLOW][sourceNodeId]);
			if (variable) {
				state[FLOW][sourceNodeId][gotoType] = `{${variable}}`;
			} else {
				state[FLOW][sourceNodeId][gotoType] = targetNodeId;
			}
			break;
		}
		case actionTypes.CREATE_UPDATE_TRANSITION_SIGNAL:
			const { sourceNodeId, targetNodeId, signal } = payload;
			const node = state[FLOW][sourceNodeId];
			node[TRANSITION_TYPES.ACTIONS_SIGNALS] = { ...node[TRANSITION_TYPES.ACTIONS_SIGNALS], [signal]: targetNodeId };
			break;
		case actionTypes.REMOVE_RESPONSE:
			return {
				...state,
				[FLOW]: {
					...state[FLOW],
					[payload.nodeId]: delObjNumKeyKeepSequence(state[FLOW][payload.nodeId], payload.indexInNodeResponses),
				},
			};
		case actionTypes.CREATE_UPDATE_VARIABLE:
			/**
			 * Node's variables valid data structure:
			 * PROPERTY_TYPES.SET:[
			 * 		{ variableName: variableValue },
			 * 		...
			 * ],
			 *
			 * DEPRECATED:
			 * PROPERTY_TYPES.SET:[
			 * 		[ 'variableName', 'variable value' ],
			 * 		...
			 * ],
			 */
			return mergeDeepRight(state, {
				[FLOW]: {
					[payload.nodeId]: {
						[PROPERTY_TYPES.SET]: dropLastWhile(
							isEmpty,
							update(
								payload.varId,
								{ [payload.varName]: payload.varValue },
								concat(getNodeVariables(payload.nodeId)({ [ID]: state }) || [], [''])
							)
						),
					},
				},
			});
		case actionTypes.RENAME_VARIABLE:
			const flow = {};

			for (const [nodeId, node] of Object.entries(state[FLOW])) {
				flow[nodeId] = {};

				for (const [prop, propContent] of Object.entries(node)) {
					const isSentence = !isNaN(prop);

					flow[nodeId][prop] = JSON.parse(
						JSON.stringify(propContent).replace(
							new RegExp(isSentence ? '\\b' + payload.varPrev + '\\b' : '{' + payload.varPrev + '}', 'g'),
							isSentence ? payload.varNew : '{' + payload.varNew + '}'
						)
					);
				}
			}
			state[FLOW] = flow;

			break;
		case actionTypes.REMOVE_VARIABLE:
			return {
				...state,
				[FLOW]: {
					...state[FLOW],
					[payload.nodeId]: {
						...state[FLOW][payload.nodeId],
						[PROPERTY_TYPES.SET]: remove(payload.varId, 1, state[FLOW][payload.nodeId][PROPERTY_TYPES.SET]),
					},
				},
			};
		case actionTypes.CREATE_UPDATE_VARIABLES_TO_RESET:
			return {
				...state,
				[FLOW]: {
					...state[FLOW],
					[payload.nodeId]: {
						...state[FLOW][payload.nodeId],
						[PROPERTY_TYPES.RESET]: payload.varsToReset,
					},
				},
			};
		case actionTypes.REMOVE_VARIABLES_TO_RESET:
			return {
				...state,
				[FLOW]: {
					...state[FLOW],
					[payload.nodeId]: omit([PROPERTY_TYPES.RESET], { ...state[FLOW][payload.nodeId] }),
				},
			};
		case actionTypes.SET_YAML_ERROR_WARNING:
			if (payload.type === 'error') {
				state.rawYamlErrors = payload.value;
			} else if (payload.type === 'warning') {
				state.rawYamlWarnings = payload.value;
			}
			break;
		case actionTypes.REMOVE_YAML_ERROR_WARNING:
			if (payload.type === 'error') {
				state.rawYamlErrors.splice(payload.index, 1);
			} else if (payload.type === 'warning') {
				state.rawYamlWarnings.splice(payload.index, 1);
			}
			break;
		case actionTypes.CREATE_UPDATE_SMART_FUNCTION:
			/**
			 * CALL / EXTRACT data structure: [
			 * 		{
			 *			[output param]: [
			 *				"function name" {string},
			 *				{ inputParamName: "input param value" {string}, ... }
			 *	 		]
			 * 		},
			 * 		...
			 * ]
			 */
			return mergeDeepRight(state, {
				flow: {
					[payload.nodeId]: {
						[payload.propertyType]: dropLastWhile(
							isEmpty,
							update(
								payload.funcId,
								payload.smartFunc,
								concat(getNodesProperty(payload.nodeId, payload.propertyType)({ [ID]: state }) || [], [''])
							)
						),
					},
				},
			});
		case actionTypes.REMOVE_SMART_FUNCTION:
			return {
				...state,
				flow: {
					...state[FLOW],
					[payload.nodeId]: {
						...state[FLOW][payload.nodeId],
						[payload.propertyType]: remove(payload.funcId, 1, state[FLOW][payload.nodeId][payload.propertyType]),
					},
				},
			};
		case actionTypes.CREATE_CONDITION:
			return {
				...state,
				flow: {
					...state[FLOW],
					[payload.nodeId]: {
						...state[FLOW][payload.nodeId],
						[TRANSITION_TYPES.CONDITIONS]: [
							...(state[FLOW][payload.nodeId][TRANSITION_TYPES.CONDITIONS]
								? [...state[FLOW][payload.nodeId][TRANSITION_TYPES.CONDITIONS]]
								: []),
							[payload.condValue, payload.condTargetNodeId],
						],
					},
				},
			};
		case actionTypes.UPDATE_REMOVE_CONDITION:
			return updateRemoveCondition(
				state,
				payload.nodeId,
				payload.conditionIndex,
				payload.condValue,
				payload.condTargetNodeId
			);
		case actionTypes.ADD_INTENT: {
			const { intentId, utterances } = payload;
			state.intents[intentId] = utterances ?? [];
			break;
		}
		case actionTypes.UPDATE_INTENT:
			const { prevIntentId, nextIntentId } = payload;

			return JSON.parse(JSON.stringify(state).replace(new RegExp(`"${prevIntentId}"`, 'g'), `"${nextIntentId}"`));
		case actionTypes.REMOVE_INTENT:
			return removeIntent(state, payload.intentId);
		case actionTypes.ADD_UTTERANCE:
			return {
				...state,
				intents: {
					...state.intents,
					[payload.intentId]: append(payload.utteranceId, state.intents[payload.intentId]),
				},
			};
		case actionTypes.UPDATE_UTTERANCE:
			const { intentId, prevUtteranceId, nextUtteranceId } = payload;
			const utterances = state.intents[intentId];
			return {
				...state,
				intents: {
					...state.intents,
					[intentId]: update(indexOf(prevUtteranceId, utterances), nextUtteranceId, utterances),
				},
			};
		case actionTypes.REMOVE_UTTERANCE:
			return {
				...state,
				intents: {
					...state.intents,
					[payload.intentId]: without([payload.utteranceId], state.intents[payload.intentId]),
				},
			};
	}
}, DEFAULT_STATE);

const updateRemoveCondition = (state, nodeId, conditionIndex, condValue, condTargetNodeId) => {
	const normState = { [ID]: state };
	const conditions = getNodesConditions(nodeId)(normState);
	const conditionsUpdated = [...conditions];

	if (condValue) {
		conditionsUpdated.splice(conditionIndex, 1, [condValue, condTargetNodeId]);
	} else {
		conditionsUpdated.splice(conditionIndex, 1);
	}

	return mergeDeepRight(state, {
		flow: {
			[nodeId]: {
				[TRANSITION_TYPES.CONDITIONS]: conditionsUpdated,
			},
		},
	});
};

/**
 * Removes intent from intents. Removes all occurences of given intent from flow actions.
 *
 * @param {object} state - Store representation to fowk with.
 * @param {string} intentId - to remove.
 */
const removeIntent = (state, intentId) => {
	const normState = { [ID]: state };
	const flow = getNodes(normState);
	const toDelete = o(
		filter(([, intent]) => intent === intentId),
		toPairs
	);

	return {
		...state,
		flow: map((node) => {
			const actions = node[TRANSITION_TYPES.ACTIONS];
			if (actions) {
				const filtered = toDelete(actions);
				// filtered[0][0] - This is shortcut, filtered should always return max one element for particular ACTIONS
				return isEmpty(filtered) ? node : dissocPath([TRANSITION_TYPES.ACTIONS, filtered[0][0]], node);
			} else {
				return node;
			}
		})(flow),
		intents: omit([intentId], state.intents),
	};
};

const updateSsiGroup = (state, targetNodeId, ssiGroup) => {
	if (ssiGroup === null) {
		Reflect.deleteProperty(state[SEMANTICALLY_SIMILAR_INTENTS], targetNodeId);
		for (const [intent, ssi] of Object.entries(state[SEMANTICALLY_SIMILAR_INTENTS])) {
			state[SEMANTICALLY_SIMILAR_INTENTS][intent] = ssi.filter((s) => s !== targetNodeId);
		}
	} else if (ssiGroup && !state[SEMANTICALLY_SIMILAR_INTENTS][targetNodeId]) {
		state[SEMANTICALLY_SIMILAR_INTENTS][targetNodeId] = [...ssiGroup];
		for (const intent of ssiGroup) {
			if (Array.isArray(state[SEMANTICALLY_SIMILAR_INTENTS][intent])) {
				state[SEMANTICALLY_SIMILAR_INTENTS][intent].push(targetNodeId);
			} else {
				state[SEMANTICALLY_SIMILAR_INTENTS][intent] = [...ssiGroup, targetNodeId];
			}
		}
	}
};

const addIntentsFromFlow = (state) => {
	if (!state[FLOW]) {
		return;
	}

	const intents = state[INTENTS] || {};

	const sortUtterances = sortBy(identity);

	const prevIntentsByUtterancesId = invertObj(mapObjIndexed((utt) => JSON.stringify(sortUtterances(utt)), intents));
	const intentsByUtterancesId = {};

	Object.entries(state[FLOW])
		.map(([nodeId, node]) => [nodeId, node[TRANSITION_TYPES.ACTIONS]])
		.filter(([_, actions]) => Boolean(actions))
		.map(([nodeId, actions]) => {
			Object.entries(actions).map(([actionId, utterances]) => {
				if (typeof utterances === 'string' && utterances.startsWith('.')) {
					return; // ignore generic intent
				}

				let previousIntentId = `${nodeId}_${actionId}`;
				if (!Array.isArray(utterances)) {
					// the intent was already created for this flow
					previousIntentId = utterances;
					// part ` ?? []` is workaround for the utterances written as `-'utterance'`
					// see https://trello.com/c/0dMSO3tN/705-cannot-load-yaml-from-file
					utterances = intents[previousIntentId] ?? [];
				}

				utterances = sortUtterances(utterances);
				const utterancesId = JSON.stringify(utterances);
				const intent = intentsByUtterancesId[utterancesId];
				const intentId = intent?.intentId ?? prevIntentsByUtterancesId[utterancesId] ?? previousIntentId;
				if (!intent) {
					intentsByUtterancesId[utterancesId] = {
						intentId,
						utterances,
					};
				}

				actions[actionId] = intentId;
			});
		});

	state[INTENTS] = {
		...state[INTENTS],
		...fromPairs(Object.values(intentsByUtterancesId).map(({ intentId, utterances }) => [intentId, utterances])),
	};
};

/**
 * Normalizes all conditions to use the uniform data structure
 * Converts {condition: targetNode} to [condition, targetNode]
 * @see https://trello.com/c/JILS9DzB/920-absence-%C5%A1ipek-ve-flow-builderu-pro-conditions
 */
const normalizeConditionsInYaml = (result) => {
	const flow = result.parsedYaml[FLOW];
	forEachObjIndexed((node) => {
		if (has(TRANSITION_TYPES.CONDITIONS, node)) {
			node[TRANSITION_TYPES.CONDITIONS] = node[TRANSITION_TYPES.CONDITIONS].map(fromConfigs.unifyCondition);
		}
	}, flow);

	return result;
};

/**
 * Normalizes all node names to conform our convention.
 * We allow only UPPER letters, numbers and underscore character `_`.
 * @see https://trello.com/c/Yd9x3yQ8/323-validate-accents-in-node-names
 */
const normalizeNodeIdsInYaml = (parsedYaml, rawYaml) => {
	const normalizedNodeIds = {};
	for (const nodeId of Object.keys(parsedYaml[FLOW])) {
		const normalizedNodeId = normalizeNodeId(nodeId);
		if (nodeId !== normalizedNodeId) {
			const regex = new RegExp(`^\\s*${escapeRegExp(nodeId)}:$`);
			normalizedNodeIds[nodeId] = { normalizedNodeId, row: findRowInRawYaml(regex, rawYaml) || 0 };
		}
	}

	if (isEmpty(normalizedNodeIds)) {
		// everything is OK so don't need to normalize YAML
		return { rawYaml, parsedYaml, errors: [], warnings: [] };
	}

	const pattern = new RegExp(`(?:${Object.keys(normalizedNodeIds).map(escapeRegExp).join(')|(?:')})`, 'gu');
	rawYaml = rawYaml.replace(pattern, (match) => normalizedNodeIds[match].normalizedNodeId);

	try {
		parsedYaml = jsYaml.load(rawYaml);
	} catch (e) {
		Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { rawYaml } });
		console.warn(e);
		return {
			rawYaml,
			parsedYaml: {},
			warnings: [],
			errors: Object.entries(normalizedNodeIds).map(([oldNodeId, { normalizedNodeId, row }]) => ({
				row,
				column: 0,
				text: INTL.formatMessage(messages.yamlNodeIdsInvalid, { node: oldNodeId + ' -> ' + normalizedNodeId }),
			})),
		};
	}

	return {
		rawYaml,
		parsedYaml,
		errors: [],
		warnings: Object.entries(normalizedNodeIds).map(([oldNodeId, { normalizedNodeId, row }]) => ({
			row,
			column: 0,
			text: INTL.formatMessage(messages.yamlNormalizedNodeIds, { node: oldNodeId + ' -> ' + normalizedNodeId }),
		})),
	};
};

export const parseYaml = (rawYaml) => {
	if (!rawYaml) {
		return { rawYaml, parsedYaml: {}, errors: [], warnings: [] };
	}

	let parsedYaml = null;
	try {
		parsedYaml = jsYaml.load(rawYaml);
	} catch (error) {
		return {
			rawYaml,
			parsedYaml: {},
			warnings: [],
			errors: [
				{
					row: error?.mark?.line,
					column: error?.mark?.column,
					text: error.message,
				},
			],
		};
	}

	if (!parsedYaml) {
		return {
			rawYaml,
			parsedYaml: {},
			warnings: [],
			errors: [
				{
					row: 0,
					column: 0,
					text: INTL.formatMessage(messages.yamlParsingFailed),
				},
			],
		};
	}

	const configuration = parsedYaml[CONFIGURATION];

	if (!configuration?.[GLOBAL_START_NODE]) {
		return {
			rawYaml,
			parsedYaml,
			warnings: [],
			errors: [
				{
					row: 0,
					column: 0,
					text: INTL.formatMessage(messages.yamlMissingGlobalStartNode, { globalStartNode: GLOBAL_START_NODE }),
				},
			],
		};
	} else if (!parsedYaml[FLOW]) {
		return {
			rawYaml,
			parsedYaml,
			warnings: [],
			errors: [
				{
					row: 0,
					column: 0,
					text: INTL.formatMessage(messages.yamlMissingFlowNode, { flow: FLOW }),
				},
			],
		};
	}

	const result = compose(normalizeConditionsInYaml, normalizeNodeIdsInYaml)(parsedYaml, rawYaml);
	if (isEmpty(result.parsedYaml)) {
		return result;
	}

	// 'anchor' is used in (older) projects for intents in Yaml
	// If it is found, reassign it to the correct property name
	if (!isEmpty(result.parsedYaml[LEGACY_INTENTS_NAME_IN_YAML])) {
		result.parsedYaml[INTENTS] = result.parsedYaml[LEGACY_INTENTS_NAME_IN_YAML];
	}

	rawYaml = result.rawYaml;
	parsedYaml = pick(DATA_TO_PARSE_FROM_AND_TO_YAML, result.parsedYaml);
	const { errors, warnings } = result;

	// iterate over flow nodes
	const flow = parsedYaml[FLOW];
	for (const [nodeId, node] of Object.entries(flow)) {
		const { validFields, errors: invalidFieldErrors } = filterYamlSpecificFields(node);
		flow[nodeId] = pick(validFields, node);
		errors.push(...invalidFieldErrors);
	}

	return {
		rawYaml,
		parsedYaml: { ...parsedYaml, flow },
		errors,
		warnings,
	};
};

/**
 * Iterates over the node's properties and selects only those that are defined and editable in YAML
 * This is also used in reverse to omit such properties in the store version of nodes when YAML and store version of nodes get merged back into one representation
 */
const filterYamlSpecificFields = (node) => {
	const validFields = [];
	const errors = [];
	forEachObjIndexed((propertyValue, propertyKey) => {
		if (has(propertyKey)(transitionTypes)) {
			validFields.push(propertyKey);
		} else if (has(propertyKey)(propertyTypes)) {
			validFields.push(propertyKey);
		} else if (!isNaN(propertyKey)) {
			forEachObjIndexed((response, responseType) => {
				if (has(responseType)(responseTypes)) {
					validFields.push(propertyKey);
				}
			}, propertyValue);
		} else {
			errors.push({
				row: errors.length,
				column: 0,
				text: INTL.formatMessage(messages.yamlNodePropertyInvalid, { property: propertyKey }),
			});
		}
	}, node);
	return { validFields, errors };
};

const removeNode = (nodeId, projectData) => {
	const str = JSON.stringify(projectData);
	return JSON.parse(str, (key, value) => {
		// check for empty array values ( side effect of removing node trace )
		if (Array.isArray(value) && value.includes(undefined)) {
			return undefined;
		}

		// remove node trace ( may generate empty array fields, e.g. when removed nodeId was a CONDITION target node )
		return value !== nodeId && key !== nodeId ? value : undefined;
	});
};

const setProjectData = (state, data) =>
	mergeDeepRight({ ...state, ...DEFAULT_MODEL_DATA }, pick(MODEL_DATA_FIELDS, data));

export const updateCanvasZoom = (direction, state) => {
	const normState = { [ID]: state };
	const zoomingCoef = 10;
	return clamp(17, 170, getCanvasZoom(normState)) + zoomingCoef * direction;
};

/**
 * Combines what node properties were and could be set in YAML with all the properties of nodes in store
 */
const mergeYamlFlowWithFlowInStore = (yamlFlow, storeFlow) => {
	if (!yamlFlow) {
		return storeFlow;
	}

	for (const [nodeId, node] of Object.entries(yamlFlow)) {
		// The store representation of the node is cleaned of the same properties that are incoming from YAML
		// This is necessary to keep track of deletions made in YAML
		const { validFields: fieldsToBeOmitted } = filterYamlSpecificFields(storeFlow[nodeId]);

		yamlFlow[nodeId] = {
			...DEFAULT_NODE_DATA,
			...omit(fieldsToBeOmitted, storeFlow[nodeId]),
			...node,
		};
		// Add a default color if the nodeId matches any of the template names
		if (!yamlFlow[nodeId].color) {
			yamlFlow[nodeId].color = matchNodeIdWithTemplateColor(nodeId);
		}
	}

	return yamlFlow;
};

/**
 * Combines various checkers for legacy/duplicate/ etc. data structures
 */
const normalizeProjectData = (state, payload = {}) => {
	state[FLOW_EDITOR_UI].diagrams = Object.assign({}, { ...state[FLOW_EDITOR_UI].diagrams });
	state[FLOW_EDITOR_UI].diagrams.positions = Object.assign(
		{},
		{ ...removeNullCoords(state[FLOW_EDITOR_UI].diagrams.positions) }
	);
	state[FLOW_EDITOR_UI].diagrams.canvasOffset = Object.assign(
		{},
		{ ...removeNullCoords(state[FLOW_EDITOR_UI].diagrams.canvasOffset) }
	);
	state[FLOW_EDITOR_UI].diagrams.canvasZoom = Object.assign({}, { ...state[FLOW_EDITOR_UI].diagrams.canvasZoom });
	if (!state[CONFIGURATION].project_id) {
		state[CONFIGURATION].project_id = payload.result ?? null;
	}

	// Conditions
	forEachObjIndexed((node) => {
		if (has(TRANSITION_TYPES.CONDITIONS, node)) {
			node[TRANSITION_TYPES.CONDITIONS] = node[TRANSITION_TYPES.CONDITIONS].map(fromConfigs.unifyCondition);
		}
	}, state[FLOW]);

	const { x, y } = state[FLOW_EDITOR_UI].canvasOffset;
	if (!x || !y) {
		state[FLOW_EDITOR_UI].canvasOffset = {
			x: 0,
			y: 0,
		};
	}
};

/**
 * If the model has not even a single group but there are nodes, create a default group and put all the nodes in it
 * The reference to the group inside each node is handled later
 */
const addDefaultGroup = (state) => {
	if (!state[NODE_GROUPS].groups.length && !isEmpty(state[FLOW])) {
		const nodes = Object.keys(state[FLOW]);
		const globalStartNodeId = getGlobalStartNodeId(state);

		state[NODE_GROUPS].groups.push({
			...DEFAULT_NODE_GROUP_DATA,
			name: 'DEFAULT',
			startNode: globalStartNodeId || nodes[0],
			nodes,
		});

		state[NODE_GROUPS].activeNodeGroupId = 'DEFAULT';
	}
};

/**
 * Add new properties to nodes and nodeGroups of older models
 */
const addDefaultNodeProperties = (state) => {
	for (const [index, group] of state[NODE_GROUPS].groups.entries()) {
		state[NODE_GROUPS].groups[index] = {
			...DEFAULT_NODE_GROUP_DATA,
			...group,
		};

		// Designate a groupStartNode if there is none
		if (!state[NODE_GROUPS].groups[index].startNode) {
			state[NODE_GROUPS].groups[index].startNode = state[NODE_GROUPS].groups[index].nodes[0];
		}
	}
	for (const [nodeId, node] of Object.entries(state[FLOW])) {
		state[FLOW][nodeId] = {
			...DEFAULT_NODE_DATA,
			...node,
		};
		// Add a default color if the nodeId matches any of the template names
		if (!state[FLOW][nodeId].color) {
			state[FLOW][nodeId].color = matchNodeIdWithTemplateColor(nodeId);
		}
	}
};

/**
 * Add missing and remove unused color captions
 */
const refreshColorCaptions = (state) => {
	const colorsUsedInFlow = getColorsFromFlow({ [ID]: state });

	for (const color of Object.keys(state[FLOW_EDITOR_UI].colorCaptions)) {
		// Remove color captions if none of the nodes have such a color
		if (!colorsUsedInFlow.includes(color)) {
			Reflect.deleteProperty(state[FLOW_EDITOR_UI].colorCaptions, color);
		}
	}

	for (const color of colorsUsedInFlow) {
		// Add missing colors so that they can be captioned
		if (!(color in state[FLOW_EDITOR_UI].colorCaptions)) {
			state[FLOW_EDITOR_UI].colorCaptions = { ...state[FLOW_EDITOR_UI].colorCaptions, color };
		}
	}
};

/**
 * Adds nodeId into node groups config, in case there was new node added into parsedYaml.
 * Otherwise it returns original node groups config.
 */
const addNodeIdToGroups = (parsedYaml, newState) => {
	if (!parsedYaml[FLOW] || !newState[FLOW] || !newState[NODE_GROUPS]) {
		return newState[NODE_GROUPS];
	}

	const newNodeId = difference(Object.keys(parsedYaml[FLOW]), Object.keys(newState[FLOW]))[0];
	if (!newNodeId) {
		return newState[NODE_GROUPS];
	}

	let i = 0;
	for (const group of newState[NODE_GROUPS].groups) {
		if (new RegExp(`^${group.name}_`).test(newNodeId)) {
			const newConfig = { ...newState[NODE_GROUPS] };
			newConfig.groups[i].nodes.push(newNodeId);
			return newConfig;
		}
		i++;
	}
};

/**
 * Iterates over nodeGroups and adds their groupId as a property to nodes they contain
 * Also gets rid of references to non-existent groups in nodes and one node being included in 2 groups
 * If the node is not found, it is no longer included in the group either
 */
const addGroupReferencesToNodes = (flow, groupConfig, positions) => {
	if (!flow || !groupConfig) {
		return;
	}

	for (const group of groupConfig.groups) {
		for (const nodeId of group.nodes) {
			if (flow[nodeId] && flow[nodeId].group !== group.name) {
				// In case the group referenced by the node exists, change that group's list of nodes
				if (groupConfig.groups.includes(flow[nodeId].group)) {
					const nodeGroupWithReferenceToNode = groupConfig.groups.find(
						(nodeGroup) => nodeGroup.name === flow[nodeId].group
					);

					nodeGroupWithReferenceToNode.nodes = nodeGroupWithReferenceToNode.nodes.filter(
						(filteredNodeId) => filteredNodeId !== nodeId
					);
				}

				// Whenever the node does not contain a reference to any or just this specific group, add it
				flow[nodeId].group = group.name;
			} else if (!flow[nodeId]) {
				group.nodes = group.nodes.filter((filteredNodeId) => filteredNodeId !== nodeId);
			}
			normalizeRefNodeDiagramPositions(group, positions);
		}
	}
};

/**
 * Checks if referenceNodeDiagramPositions in all groups does not contain non numeric values (x: null etc.) for import purposes
 * If values are not correct, derives them from startNodePosition of the group or set them default values
 */
const normalizeRefNodeDiagramPositions = (group, positions) => {
	const refPosition = group.referenceNodeDiagramPositions;
	Object.keys(refPosition).forEach((id) => {
		if (!refPosition[id].x || !refPosition[id].y) {
			const startNodePosition = positions[group.startNode];
			if (typeof startNodePosition?.x === 'number' && typeof startNodePosition?.y === 'number') {
				refPosition[id] = {
					x: startNodePosition.x + DEFAULT_DISTANCE_FROM_START_NODE.x,
					y: startNodePosition.y + DEFAULT_DISTANCE_FROM_START_NODE.y,
				};
			} else {
				refPosition[id] = { x: 0, y: 0 };
			}
		}
	});
};

/**
 * Extract x,y coordinates into a separate UI object for optimization purposes
 * This is also done for nodeGroups since they are displayed as nodes in the general overview
 */
const addNodePositionsInDiagram = (state) => {
	for (const [index, group] of state[NODE_GROUPS].groups.entries()) {
		if (
			!state[FLOW_EDITOR_UI].diagrams.positions[group.name] ||
			isEmpty(state[FLOW_EDITOR_UI].diagrams.positions[group.name])
		) {
			if (group.diagramPosition) {
				// Mutating the object caused redux errors, copy it instead
				state[FLOW_EDITOR_UI].diagrams.positions = Object.assign(
					{ ...state[FLOW_EDITOR_UI].diagrams.positions },
					{ [group.name]: clone(group.diagramPosition) }
				);
			} else {
				state[FLOW_EDITOR_UI].diagrams.positions = Object.assign(
					{ ...state[FLOW_EDITOR_UI].diagrams.positions },
					{ [group.name]: { x: 0, y: 0 } }
				);
			}
		}
		unset(state[NODE_GROUPS].groups[index], 'diagramPosition');
		unset(state[NODE_GROUPS].groups[index], 'canvasOffset');
		unset(state[NODE_GROUPS].groups[index], 'canvasZoom');
	}
	for (const [nodeId, node] of Object.entries(state[FLOW])) {
		if (
			!state[FLOW_EDITOR_UI].diagrams.positions[nodeId] ||
			isEmpty(state[FLOW_EDITOR_UI].diagrams.positions[nodeId])
		) {
			if (node.diagramPosition) {
				state[FLOW_EDITOR_UI].diagrams.positions = Object.assign({
					...state[FLOW_EDITOR_UI].diagrams.positions,
					[nodeId]: clone(node.diagramPosition),
				});
			} else {
				state[FLOW_EDITOR_UI].diagrams.positions = Object.assign({
					...state[FLOW_EDITOR_UI].diagrams.positions,
					[nodeId]: { x: 0, y: 0 },
				});
			}
		}
		unset(state[FLOW][nodeId], 'diagramPosition');
	}
};

export default (state = DEFAULT_STATE, action, fullState) => {
	const newState = model(state, action, fullState);
	if (state === newState) {
		return newState;
	} else if (state.rawYaml !== newState.rawYaml) {
		// YAML model has changed -> generate Redux model
		return produce(newState, () => {
			const { parsedYaml, rawYaml, errors: rawYamlErrors, warnings: rawYamlWarnings } = parseYaml(newState.rawYaml);

			/**
			 * When YAML changes, we need to:
			 * 1. Make sure all the default data are in the model (e.g. in case YAML did not include CONFIGURATION)
			 * 2. Re-add everything the model already contained because only some data are shown and editable in YAML
			 * 3. Overwrite all the overlapping data with YAML values
			 * 4. The same process of providing default data, re-adding store values and overwriting with YAML values is then repeated for individual nodes in FLOW
			 */

			const newStateCloned = clone(newState);

			// When the YAML gets reparsed, do not delete errors/warnings that came from a failed training attempt
			// They get removed either manually by the user or when new training is attempted
			const yamlApiErrors = newState.rawYamlErrors?.filter((error) => error.isApiError) || [];
			const yamlApiWarnings = newState.rawYamlWarnings?.filter((error) => error.isApiError) || [];

			const resultState = {
				...DEFAULT_MODEL_DATA,
				...newStateCloned,
				...parsedYaml,
				[FLOW]: mergeYamlFlowWithFlowInStore(parsedYaml[FLOW], newStateCloned[FLOW]),
				[YAML_EDITOR_UI]: {
					...newState[YAML_EDITOR_UI],
					comments: findCommentsInRawYaml(rawYaml),
				},
				[NODE_GROUPS]: addNodeIdToGroups(parsedYaml, newStateCloned) ?? newStateCloned[NODE_GROUPS],
				// If these entities are deleted from YAML, the values from state persist since they are not overwritten in the {...} assignment above
				[VOCABULARY]: parsedYaml[VOCABULARY] || {},
				[SEMANTICALLY_SIMILAR_INTENTS]: parsedYaml[SEMANTICALLY_SIMILAR_INTENTS] || {},
				[STOPWORDS]: parsedYaml[STOPWORDS] || {},

				rawYaml,
				rawYamlErrors: [...yamlApiErrors, ...rawYamlErrors],
				rawYamlWarnings: [...yamlApiWarnings, ...rawYamlWarnings],
			};

			// Based on the changes made in YAML, update nodeGroups, this is why 'newState' above had to be cloned to prevent mutating it
			addGroupReferencesToNodes(
				resultState[FLOW],
				resultState[NODE_GROUPS],
				resultState[FLOW_EDITOR_UI].diagrams.positions
			);
			// Intents defined in INTENTS are merged with intents written in individual nodes
			addIntentsFromFlow(resultState);

			// Ensure the model's name in store takes precedence
			const projectName = getSelectedModelName(fullState);
			const oldProjectName = resultState[CONFIGURATION].project;
			if (projectName && projectName !== oldProjectName) {
				resultState[CONFIGURATION].project = projectName;
				// In case the name was used somewhere else in the file
				resultState.rawYaml = resultState.rawYaml.replace(
					new RegExp(`project: ${oldProjectName}`),
					'project: ' + projectName
				);
			}
			// Ditto for model's id (project_id)
			const projectId = selectedModelId(fullState);

			const organizationId = getSelectedModel(fullState)?.organization?.id ?? null;
			const oldProjectId = resultState[CONFIGURATION].project_id;
			if (projectId && projectId !== oldProjectId) {
				resultState[CONFIGURATION].project_id = projectId;
				// In case project_id was used somewhere else in the file
				resultState.rawYaml = resultState.rawYaml.replace(
					new RegExp(`project_id: ${oldProjectId}`),
					'project_id: ' + projectId
				);
			}
			const oldOrganizationId = resultState[CONFIGURATION].organization_id;
			if (organizationId && organizationId !== oldOrganizationId) {
				resultState[CONFIGURATION].organization_id = organizationId;
				resultState.rawYaml = resultState.rawYaml.replace(
					new RegExp(`organization_id: ${oldOrganizationId}`),
					'organization_id: ' + organizationId
				);
			}

			return resultState;
		});
	} else {
		// Redux model has changed -> generate YAML model for the selected data from state
		for (const attr of DATA_TO_PARSE_FROM_AND_TO_YAML) {
			const prevYamlUiConfig = getYamlEditorUiConfig({ [ID]: state });
			const yamlUiConfig = getYamlEditorUiConfig({ [ID]: newState });

			const editorUiConfigChanged = prevYamlUiConfig.shouldFormat !== yamlUiConfig.shouldFormat;
			const formatYaml = yamlUiConfig.shouldFormat && yamlUiConfig.doFormat;
			const modelChanged = !equals(state[attr], newState[attr]);

			if (editorUiConfigChanged || formatYaml || modelChanged) {
				// Generate YAML model
				const projectName = getSelectedModelName(fullState);
				const projectId = selectedModelId(fullState);
				const organizationId = getSelectedModel(fullState)?.organization?.id ?? null;

				return produce(newState, (mutableState) => {
					if (projectName && action.type !== modelTableActionTypes.FETCH_MODEL_VERSION_SUCCESS) {
						mutableState[CONFIGURATION].project = projectName;
						mutableState[CONFIGURATION].project_id = projectId ?? null;
						mutableState[CONFIGURATION].organization_id = organizationId ?? null;
					}
					mutableState.rawYaml = formatYaml
						? getYamlFromModelFormatted({ [ID]: newState })
						: getYamlFromModel({ [ID]: newState });
					mutableState.rawYamlErrors = [];
					mutableState.rawYamlWarnings = [];
					mutableState.manualChangeVersion += Number(action.type.startsWith(`${ID}/`)); // converts false -> 0, true -> 1
					mutableState[YAML_EDITOR_UI].doFormat = false;
				});
			}
		}

		return newState;
	}
};
