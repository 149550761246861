import {
	ArrowDropDown as ArrowDropDownIcon,
	ArrowLeft as ArrowLeftIcon,
	Clear as ClearIcon,
	Delete as DeleteIcon,
	FilterList as FilterListIcon,
	Forum as ForumIcon,
	Search as SearchIcon,
} from '@mui/icons-material';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Grid, IconButton, InputAdornment, TablePagination, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../../intl';
import { showCampaignPhoneCallFilterModal, showPhoneCallTranscriptionModal } from '../../../redux/modal/actions';
import { updateNumbers } from '../../../redux/outbound/actions';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import { isPhoneNumberInvalid } from '../../../utils/validation';
import UniButton from '../../UniButton';
import { areCallDataAvail, OutboundStatusIcon, useStylesCampaignDetail } from './CampaignDetail';

export const FILTER_TYPES = {
	LESSER_THAN: 'lesserThan',
	GREATER_THAN: 'greaterThan',
};

const handleAddNumber = ({ e, campaign, dispatch, metaDataPattern, setNumberErr, save }) => {
	const number = e.target.value;
	if (e.key === 'Enter' || (number && save)) {
		if (isPhoneNumberInvalid(number)) {
			setNumberErr(isPhoneNumberInvalid(number));
			return;
		}
		setNumberErr(null);
		if (!campaign.phoneCalls[number]) {
			dispatch(updateNumbers(campaign.id, number.toString(), metaDataPattern));
		}
		e.target.value = '';
	}
};

const StyledTablePagination = withStyles({
	toolbar: {
		padding: '0 !important',
	},
})(TablePagination);

const PhoneCalls = ({
	campaign,
	disableUI,
	metaDataPattern,
	setSelectedNumber,
	selectedNumber,
	selectedCallStatuses,
	isPhoneCallAudioPlaying,
	setIsPhoneCallAudioPlaying,
	phoneCallsListPage,
	setPhoneCallsListPage,
}) => {
	const classes = useStylesCampaignDetail();
	const dispatch = useDispatch();
	const [numberErr, setNumberErr] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [isSearchFieldShown, setIsSearchFieldShown] = useState(false);
	const [filterString, setFilterString] = useState('');
	const [phoneCallFilter, setPhoneCallFilter] = useState({ type: FILTER_TYPES.LESSER_THAN, value: '' });
	const phoneCalls = campaign?.phoneCalls ?? {};
	const filteredAndArrayedPhoneCalls = Object.entries(phoneCalls).filter(([number, callDetails]) => {
		const { value, type } = phoneCallFilter;
		// Use filter only when the value is not falsy, nobody cares about calls longer or shorter than 0...
		const durationFilter = value
			? type === FILTER_TYPES.LESSER_THAN
				? callDetails.durationInSeconds < value
				: callDetails.durationInSeconds > value
			: true;

		return (
			durationFilter &&
			number.includes(filterString) &&
			(selectedCallStatuses.includes(callDetails.status) || isEmpty(selectedCallStatuses))
		);
	});

	const handleFilter = ({ value, type }) => {
		setPhoneCallsListPage(0);
		setPhoneCallFilter({ value, type });
	};

	const handleSearch = (value) => {
		setPhoneCallsListPage(0);
		setFilterString(value);
	};

	return (
		<div className={classes.section}>
			<div className={classes.sectionHeader}>
				{isSearchFieldShown ? (
					<>
						<TextField
							variant="standard"
							// classes={{ root: classes.textField }}
							className={classes.phoneCallSearchField}
							value={filterString}
							onChange={(e) => handleSearch(e.target.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position={'end'}>
										{filterString.length > 0 ? (
											<ClearIcon classes={{ root: classes.icon }} onClick={() => handleSearch('')} />
										) : (
											<SearchIcon color={'disabled'} />
										)}
									</InputAdornment>
								),
							}}
						/>
						<FilterListIcon
							classes={{ root: classes.icon }}
							onClick={() => dispatch(showCampaignPhoneCallFilterModal(handleFilter, phoneCallFilter))}
						/>
					</>
				) : (
					<span>
						<FormattedMessage {...messages.phoneNumbers} /> ({size(filteredAndArrayedPhoneCalls)} / {size(phoneCalls)})
					</span>
				)}

				<IconButton className={classes.foldBtn} onClick={() => setIsSearchFieldShown(!isSearchFieldShown)}>
					{isSearchFieldShown ? <ArrowLeftIcon /> : <ArrowDropDownIcon />}
				</IconButton>
			</div>
			{filteredAndArrayedPhoneCalls
				.slice(phoneCallsListPage * rowsPerPage, phoneCallsListPage * rowsPerPage + rowsPerPage)
				.map(([number, callDetails]) => {
					const isNumSelected = selectedNumber === number;
					const _isPhoneCallAudioPlaying = isNumSelected && isPhoneCallAudioPlaying;
					const status = callDetails.status || null;
					const callId = callDetails.callId;

					return (
						<Grid
							container
							key={number}
							direction="row"
							alignItems="center"
							className={`${classes[isNumSelected ? 'numberRow' : 'selectedNumberRow']} row`}
						>
							<Grid item xs={1}>
								<OutboundStatusIcon status={status} />
							</Grid>
							<Grid item xs={7} className={classes.defaultFont} onClick={() => setSelectedNumber(number)}>
								{formatPhoneNumber(number)}
							</Grid>
							<Grid item xs={4}>
								<div className={classes.floatRight}>
									{callId && areCallDataAvail(status) && (
										<>
											<UniButton
												title={
													<FormattedMessage
														{...messages[_isPhoneCallAudioPlaying ? 'pausePhoneCallAudio' : 'playPhoneCallAudio']}
													/>
												}
												icon={_isPhoneCallAudioPlaying ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}
												onClick={() => {
													setSelectedNumber(number);
													setIsPhoneCallAudioPlaying(!_isPhoneCallAudioPlaying);
												}}
											/>
											<UniButton
												title={<FormattedMessage {...messages.callTranscription} />}
												icon={<ForumIcon />}
												onClick={() => dispatch(showPhoneCallTranscriptionModal(callId))}
											/>
										</>
									)}
									{!disableUI && (
										<UniButton
											title={<FormattedMessage {...messages.deleteNumber} />}
											icon={<DeleteIcon />}
											onClick={() => {
												setSelectedNumber(null);
												dispatch(updateNumbers(campaign.id, number));
											}}
										/>
									)}
								</div>
							</Grid>
						</Grid>
					);
				})}
			<br />
			{!disableUI && (
				<TextField
					label={<FormattedMessage {...messages.addNumber} />}
					variant={'outlined'}
					onKeyUp={(e) => handleAddNumber({ e, campaign, dispatch, metaDataPattern, setNumberErr })}
					onBlur={(e) => handleAddNumber({ e, campaign, dispatch, metaDataPattern, setNumberErr, save: true })}
					disabled={disableUI}
					helperText={numberErr}
					error={!!numberErr}
				/>
			)}
			{size(filteredAndArrayedPhoneCalls) !== 0 && (
				<StyledTablePagination
					rowsPerPageOptions={[15, 30, 60, 100]}
					component="div"
					count={size(filteredAndArrayedPhoneCalls)}
					rowsPerPage={rowsPerPage}
					page={phoneCallsListPage}
					labelRowsPerPage={null}
					onPageChange={(e, newPage) => setPhoneCallsListPage(newPage)}
					onRowsPerPageChange={(e) => {
						setRowsPerPage(+e.target.value);
						setPhoneCallsListPage(0);
					}}
				/>
			)}
		</div>
	);
};

PhoneCalls.propTypes = {
	campaign: PropTypes.object,
	disableUI: PropTypes.bool,
	id: PropTypes.string,
	setSelectedNumber: PropTypes.func,
	metaDataPattern: PropTypes.object,
	selectedNumber: PropTypes.string,
	selectedCallStatuses: PropTypes.arrayOf(PropTypes.string),
	isPhoneCallAudioPlaying: PropTypes.bool,
	setIsPhoneCallAudioPlaying: PropTypes.func,
	phoneCallsListPage: PropTypes.number,
	setPhoneCallsListPage: PropTypes.func,
};

export default PhoneCalls;
