import { Grid, Checkbox } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { keys, omit, without } from 'ramda';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../../intl';
import { useStylesCampaignDetail, OutboundStatusIcon } from './CampaignDetail';

const Stats = ({ campaign, selectedCallStatuses, setSelectedCallStatuses, setPhoneCallsListPage }) => {
	const classes = useStylesCampaignDetail();
	const mainStatuses = ['waiting', 'in-progress', 'no-answer', 'busy', 'customer-hang-up'];

	const stats = {};
	if (!isEmpty(campaign.phoneCalls)) {
		for (const call of Object.values(campaign.phoneCalls)) {
			if (call.status) {
				stats[call.status] = stats[call.status] ? stats[call.status] + 1 : 1;
			}
		}
	}

	const handleSelectCallStatus = (e, checked) => {
		setPhoneCallsListPage(0);
		if (checked) {
			setSelectedCallStatuses([...selectedCallStatuses, e.target.name]);
		} else {
			setSelectedCallStatuses(without([e.target.name], selectedCallStatuses));
		}
	};

	return (
		<div className={classes.section} style={{ minHeight: 'auto' }}>
			<div className={`${classes.sectionHeader} ${classes.cursorPointer}`}>
				<FormattedMessage {...messages.statistics} />
			</div>
			{mainStatuses.map((status) => (
				<Grid spacing={0} container key={'mainStatus_' + status}>
					<Grid item xs={1} className={classes.defaultFont}>
						<Checkbox
							className={classes.callStatusCheckbox}
							name={status}
							checked={selectedCallStatuses.includes(status)}
							onChange={handleSelectCallStatus}
							color="primary"
						/>
					</Grid>
					<Grid item xs={1} className={classes.defaultFont}>
						<OutboundStatusIcon status={status} isSelected={selectedCallStatuses.includes(status)} />
					</Grid>
					<Grid item xs={8} className={classes.defaultFont}>
						{status}:
					</Grid>
					<Grid item xs={2} className={`${classes.defaultFont} ${classes.textAlignRight}`}>
						{' '}
						{stats[status] ? stats[status] : 0}
					</Grid>
				</Grid>
			))}
			{keys(omit(mainStatuses, stats)).map((status) => (
				<Grid spacing={0} container key={'customStatus_' + status}>
					<Grid item xs={1} className={classes.defaultFont}>
						<Checkbox
							className={classes.callStatusCheckbox}
							name={status}
							checked={selectedCallStatuses.includes(status)}
							onChange={handleSelectCallStatus}
							color="primary"
						/>
					</Grid>
					<Grid item xs={1} className={classes.defaultFont}>
						<OutboundStatusIcon status={status} isSelected={selectedCallStatuses.includes(status)} />
					</Grid>
					<Grid item xs={8} className={classes.defaultFont}>
						{status}:
					</Grid>
					<Grid item xs={2} className={`${classes.defaultFont} ${classes.textAlignRight}`}>
						{' '}
						{stats[status] ? stats[status] : 0}
					</Grid>
				</Grid>
			))}
		</div>
	);
};

Stats.propTypes = {
	campaign: PropTypes.object,
	selectedCallStatuses: PropTypes.arrayOf(PropTypes.string),
	setSelectedCallStatuses: PropTypes.func,
	setPhoneCallsListPage: PropTypes.func,
};

export default Stats;
