/**
 * Generates unique ID as UNIX timestamp. It relies on the fact that JS
 * is single-threaded and in given time only one thread can generate one ID.
 * To decrease the chance of the collision you can provide some other identifiers.
 */
export const generateUniqueId = (...salt) => {
	if (salt.length === 0) {
		return String(Date.now());
	} else {
		return `${salt.map(String).join('-')}-${Date.now()}`;
	}
};
