import { CloudDownload as DownloadIcon, Delete as DeleteIcon, MusicNote as MusicNoteIcon } from '@mui/icons-material';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';
import UploadFileButton from '../UploadFileButton';

const handleDownload = (asset) => {
	const link = document.createElement('a');
	link.href = asset.data.url.href || asset.data.url;
	link.target = '_blank';
	link.download = asset.name;
	document.body.appendChild(link);
	link.click();
};

export const AssetAudioFileListItem = ({ asset, onDelete }) => {
	return (
		<ListItem dense>
			<ListItemIcon>
				<Tooltip title="wav audio file">
					<MusicNoteIcon />
				</Tooltip>
			</ListItemIcon>
			<ListItemText primary={asset.name || 'untitled'} secondary={asset.id} />
			<ListItemSecondaryAction>
				{asset.data.url && (
					<IconButton edge="end" onClick={() => handleDownload(asset)}>
						<DownloadIcon />
					</IconButton>
				)}
				<IconButton onClick={() => onDelete(asset.id)}>
					<DeleteIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

AssetAudioFileListItem.propTypes = {
	asset: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
};

export const AssetAudioFileCreation = ({ onDataChange }) => {
	const [fileName, setFileName] = useState('');

	return (
		<>
			<UploadFileButton
				showIconOnly={false}
				title={<FormattedMessage {...messages.assetsUpload} />}
				onFileSelected={(fileData, fileName) => {
					setFileName(fileName);
					onDataChange({ fileName, fileData });
				}}
				accept=".wav"
			/>
			{fileName && <span style={{ marginLeft: '10px' }}>{fileName}</span>}
		</>
	);
};

AssetAudioFileCreation.propTypes = {
	onDataChange: PropTypes.func.isRequired,
};
