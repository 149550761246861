import { isEmpty } from 'ramda';
import React from 'react';
import NodeHeader from '../../components/NodeHeader';
import { PROPERTY_TYPES, TRANSITION_TYPES } from '../configs';
import { getMarkdowns } from './responses';

/**
 * Visualization (flow diagram) based on zoom level (1 - most compact, 3 - largest)
 */
const zoomConfig = ({ nodeId, node, isGlobalStartNode, isGroupStartNode, isReferenceNode } = {}) => {
	const markdowns = getMarkdowns(node);

	const config = {
		icons: {
			hasCondition: !isEmpty(node?.[TRANSITION_TYPES['CONDITIONS']] ?? []),
			hasIntent: !isEmpty(node?.[TRANSITION_TYPES['ACTIONS']] ?? []),
			hasSmartFunction: !isEmpty(node?.[PROPERTY_TYPES['CALL']] ?? []),
			hasVariable: !isEmpty(node?.[PROPERTY_TYPES['SET']] ?? []),
			isGlobalStartNode,
			isGroupStartNode,
		},
		isGroup: node?.isGroup,
		nodeTitle: isReferenceNode ? node.group : nodeId,
	};

	return {
		1: {
			name: config.nodeTitle ? <NodeHeader {...config} /> : null,
			dimension: { DIST_X: 250, DIST_Y_MIN: 100 },
		},
		2: {
			name: config.nodeTitle ? <NodeHeader {...config} content={isReferenceNode ? '' : markdowns} /> : null,
			dimension: { DIST_X: 300, DIST_Y_MIN: 200 },
		},
		3: {
			name: config.nodeTitle ? <NodeHeader {...config} content={isReferenceNode ? '' : markdowns} /> : null,
			dimension: { DIST_X: 300, DIST_Y_MIN: 200 },
		},
	};
};

export default zoomConfig;
