import { IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { palette } from '../../mui-theme-builder';

const UniButton = ({
	className,
	color,
	cursor,
	title,
	placement,
	icon,
	size,
	active,
	alert,
	disabled,
	onClick,
	style,
}) => {
	const styles = {
		wrap: {
			listStyleType: 'none',
			display: 'inline-block',
		},
		iconBtn: {
			padding: 4,
		},
		icon: {
			width: size ? size : 18,
			height: size ? size : 18,
			margin: 0,
			color: palette.secondary.main,
		},
		active: {
			color: palette.primary.main,
		},
		disabled: {
			pointerEvents: 'none',
			color: grey[50],
		},
		alert: {
			pointerEvents: 'none',
			color: 'red',
		},
		onlyIcon: {
			cursor: 'auto',
		},
		cursor: {
			cursor: cursor,
		},
		color: {
			color,
		},
	};

	icon = React.cloneElement(icon, {
		style: {
			...styles.icon,
			...styles[active && 'active'],
			...styles[disabled && 'disabled'],
			...styles[alert && 'alert'],
			...styles[cursor && 'cursor'],
			...styles[color && 'color'],
		},
	});

	return (
		<div style={{ ...styles.wrap, ...styles[disabled && 'disabled'], ...style }} className={className || undefined}>
			<Tooltip title={title || ''} placement={placement || 'top'}>
				{onClick ? (
					<IconButton aria-label={title} style={styles.iconBtn} onClick={onClick}>
						{icon}
					</IconButton>
				) : (
					<div style={{ ...styles.iconBtn, ...styles.onlyIcon }} className="MuiButtonBase-root MuiIconButton-root">
						{icon}
					</div>
				)}
			</Tooltip>
		</div>
	);
};

UniButton.propTypes = {
	active: PropTypes.bool,
	alert: PropTypes.bool,
	className: PropTypes.string,
	color: PropTypes.string,
	cursor: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.object,
	onClick: PropTypes.func,
	placement: PropTypes.string,
	size: PropTypes.number,
	style: PropTypes.object,
	title: PropTypes.object,
	type: PropTypes.string,
};

export default UniButton;
