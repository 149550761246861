import { gql } from 'graphql-tag';

export const getStatistics = gql`
	query statistics {
		statistics {
			...STATISTICS_FIELDS
		}
	}
	fragment STATISTICS_FIELDS on Statistic {
		messageCount
		conversationCount
		goodBotStatistics {
			cpuTotal
			cpuFree
			memoryTotal
			memoryFree
		}
	}
`;

export const getHistogram = gql`
	query statistics($range: Range, $in: AggregationFieldEnum) {
		statistics(range: $range, in: $in) {
			histogramMessageCount
			histogram {
				timeValue
				conversationCount
			}
		}
	}
`;
