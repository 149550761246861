import { InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import {
	addDataTableColumn,
	fetchDataTableColumns,
	fetchDataTableColumnsType,
	fetchDataTableRows,
} from '../../redux/data-management/actions';
import { hideModal } from '../../redux/modal/actions';
import { validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const SUPPORTED_DATATYPES = new Map([
	['String', 'VARCHAR'],
	['Numeric', 'float8'],
	['Boolean', 'BOOLEAN'],
	['JSON', 'JSONB'],
]);
const AddColumnModal = ({ tableName }) => {
	const [errorTitle, setErrorTitle] = useState(null);
	const [columnName, setColumnName] = useState('');
	const [columnType, setColumnType] = useState('String');
	const dispatch = useDispatch();

	const handleAdd = async () => {
		setErrorTitle(validateIsEmpty(columnName));
		if (validateIsEmpty(columnName)) {
			return;
		}
		await dispatch(addDataTableColumn(tableName, columnName, SUPPORTED_DATATYPES.get(columnType)));
		await dispatch(fetchDataTableColumns(tableName));
		await dispatch(fetchDataTableColumnsType(tableName));
		await dispatch(fetchDataTableRows(tableName));
		dispatch(hideModal());
	};

	const handleKeyPress = async (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};
	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.addColumn} />}
				saveBtnTitle={<FormattedMessage {...messages.addColumn} />}
				onSave={handleAdd}
			>
				<div>
					<TextField
						autoFocus
						value={columnName}
						error={!!errorTitle}
						helperText={errorTitle}
						fullWidth
						onChange={(e) => {
							setColumnName(e.target.value);
							setErrorTitle(validateIsEmpty(e.target.value));
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.columnName} />}
						data-testid="columnNameInput"
						variant="standard"
					/>
					<br />
					<br />
					<InputLabel>
						<FormattedMessage {...messages.columnType} />
					</InputLabel>
					<Select
						value={columnType}
						onChange={(e) => {
							setColumnType(e.target.value);
						}}
					>
						{[...SUPPORTED_DATATYPES].map((option) => (
							<MenuItem key={option[0]} value={option[0]}>
								{option[0]}
							</MenuItem>
						))}
					</Select>
				</div>
			</SaveCloseModalTemplate>
		</div>
	);
};
AddColumnModal.propTypes = {
	tableName: PropTypes.string.isRequired,
};
export default AddColumnModal;
