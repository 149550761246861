import { Delete as DeleteIcon } from '@mui/icons-material';
import Autocomplete from '@mui/lab/Autocomplete';
import {
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	List,
	ListItem,
	ListItemText,
	CircularProgress,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../intl';
import { fetchOrganizations } from '../redux/auth/actions';
import { getOrganizations } from '../redux/auth/selectors';
import {
	isFetchingOrganizations as isFetchingOrganizationsSel,
	isAssigningPhoneNumber as isAssigningPhoneNumberSel,
	isUnassigningPhoneNumber as isUnassigningPhoneNumberSel,
} from '../redux/fetching/selectors';
import { fetchSipAccounts } from '../redux/sip/actions';
import {
	assignPhoneNumberToOrganization,
	fetchPhoneNumbers,
	unassignPhoneNumberFromOrganization,
} from '../redux/voice/actions';
import { getPhoneNumbers } from '../redux/voice/selectors';
import formatPhoneNumber from '../utils/formatPhoneNumber';
import isNilOrEmpty from '../utils/isNilOrEmpty';
import { normalizePhoneNumber } from '../utils/normalization';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: 10,
	},
	submitButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	numberList: {
		cursor: 'default',
	},
	buttonLoading: {
		marginBottom: '1rem',
		color: theme.palette.primary.main,
	},
}));

const filter = createFilterOptions();

const CreatePhoneNumber = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const organizations = useSelector(getOrganizations);
	const phoneNumbers = useSelector(getPhoneNumbers);
	const isFetchingOrganizations = useSelector(isFetchingOrganizationsSel);
	const isAssigningPhoneNumber = useSelector(isAssigningPhoneNumberSel);
	const isUnassigningPhoneNumber = useSelector(isUnassigningPhoneNumberSel);

	const [selectedOrganization, setSelectedOrganization] = useState({});
	const [isSipShortcode, setIsSipShortcode] = useState(false);
	const [numbersAreLoading, setNumbersAreLoading] = useState(true);

	const [activePhoneNumber, setActivePhoneNumber] = useState(phoneNumbers.length ? phoneNumbers[0].phoneNumber : {});

	const [currentInputValue, setCurrentInputValue] = useState('');

	const areFieldsValid = !isNilOrEmpty(activePhoneNumber) && !isNilOrEmpty(selectedOrganization);

	const handlePhoneNumberInputChange = (e, newValue) => {
		newValue = isSipShortcode ? newValue : normalizePhoneNumber(newValue);
		setCurrentInputValue(newValue);
	};

	const handlePhoneNumberChange = (e, newValue) => {
		if (newValue.isSipShortcode) setIsSipShortcode(true);
		setActivePhoneNumber(newValue);
	};

	useEffect(() => {
		setNumbersAreLoading(isAssigningPhoneNumber || isUnassigningPhoneNumber);
	}, [isAssigningPhoneNumber, isUnassigningPhoneNumber]);

	const handlePhoneNumberAssign = () => {
		dispatch(
			assignPhoneNumberToOrganization({
				phoneNumber: activePhoneNumber.phoneNumber,
				name: activePhoneNumber.name,
				organization: selectedOrganization.id,
				isSipShortcode,
			})
		);
		setIsSipShortcode(false);
		setCurrentInputValue('');
		setActivePhoneNumber({});
	};

	const handleDeletePhoneNumber = (num) => {
		dispatch(unassignPhoneNumberFromOrganization(num.phoneNumber));
	};

	const getOrgName = (id) => {
		return id ? organizations.find((org) => org.id === id)?.name : 'Unassigned';
	};

	useEffect(() => {
		dispatch(fetchOrganizations());
		dispatch(fetchPhoneNumbers());
		dispatch(fetchSipAccounts());
	}, []);

	return (
		<div className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Autocomplete
						options={phoneNumbers.sort((a, b) =>
							typeof b.organization === 'string' ? b.organization.localeCompare(a.organization) : 1
						)}
						freeSolo
						fullWidth
						blurOnSelect
						value={activePhoneNumber}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							// Suggest the creation of a new value
							if (params.inputValue !== '') {
								filtered.push({
									name: 'Custom number',
									phoneNumber: params.inputValue,
								});
							}

							return filtered;
						}}
						inputValue={currentInputValue}
						onInputChange={handlePhoneNumberInputChange}
						onChange={handlePhoneNumberChange}
						groupBy={(option) => getOrgName(option.organization)}
						getOptionLabel={(option) => (option.phoneNumber ? option.phoneNumber : '')}
						renderOption={(props, option) => (
							<option {...props}>
								{formatPhoneNumber(option.phoneNumber).concat(option.name ? ` - ${option.name}` : '')}
							</option>
						)}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								helperText={<FormattedMessage {...messages.organizationReassignPhoneNumberWarning} />}
								label={<FormattedMessage {...messages.assignedPhoneNumbers} />}
							/>
						)}
					/>
					<FormControlLabel
						control={<Checkbox checked={isSipShortcode} onChange={(e) => setIsSipShortcode(event.target.checked)} />}
						label={<FormattedMessage {...messages.isSipShortcode} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						options={organizations}
						blurOnSelect
						getOptionLabel={({ name }) => (name ? name : '')}
						renderInput={(params) => (
							<TextField {...params} label={<FormattedMessage {...messages.selectOrganization} />} fullWidth />
						)}
						value={selectedOrganization}
						onChange={(e, v) => setSelectedOrganization(v)}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12} className={classes.submitButton}>
					<Button
						onClick={handlePhoneNumberAssign}
						color={'primary'}
						disabled={!areFieldsValid || isFetchingOrganizations}
					>
						<FormattedMessage {...messages.assignPhoneNumber} />
					</Button>
				</Grid>
				{selectedOrganization?.id && (
					<Grid item xs={12}>
						{numbersAreLoading ? (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<CircularProgress classes={{ root: classes.buttonLoading }} size={24} />
							</div>
						) : (
							<List className={classes.numberList}>
								{phoneNumbers
									.filter((num) => num.organization === selectedOrganization.id)
									.map((num) => (
										<ListItem key={num.phoneNumber} divider>
											<ListItemText primary={num.phoneNumber.concat(num.name ? ` - ${num.name}` : '')} />
											<Tooltip title={<FormattedMessage {...messages.deletePhoneNumber} />}>
												<IconButton
													onClick={() => {
														handleDeletePhoneNumber(num);
													}}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</ListItem>
									))}
							</List>
						)}
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default CreatePhoneNumber;
