import { produce } from 'immer';
import { pluck, without } from 'ramda';
import LSM from '../../utils/LocalStorageManager';
import { actionTypes } from './actions';

const EMPTY_STATE = {
	token: null,
	modelRoles: [],
	organizationRoles: [],
	organization: {},
	organizations: [],
};

const DEFAULT_STATE = { ...EMPTY_STATE, token: LSM.getAccessToken() ?? null };

const auth = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.LOGIN_SUCCESS:
		case actionTypes.REFRESH_TOKEN:
			state.token = payload.token;
			break;
		case actionTypes.LOGIN_FAIL:
		case actionTypes.LOGOUT_FAIL:
		case actionTypes.LOGOUT_SUCCESS:
			return EMPTY_STATE;
		case actionTypes.FETCH_MODEL_ROLES_SUCCESS:
			state.modelRoles = without(['Null'], pluck('name', payload?.data?.__type?.enumValues ?? []));
			break;
		case actionTypes.FETCH_ORGANIZATION_ROLES_SUCCESS:
			state.organizationRoles = without(['Null', 'SuperAdmin'], pluck('name', payload?.data?.__type?.enumValues ?? []));
			break;
		case actionTypes.FETCH_ORGANIZATION_SUCCESS:
			state.organization = payload.data.user.organization;
			break;
		case actionTypes.FETCH_ORGANIZATIONS_SUCCESS:
			state.organizations = payload.data?.organizations ?? [];
			break;
	}
}, DEFAULT_STATE);

export default auth;
