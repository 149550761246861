import { makeStyles } from '@mui/styles';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { clone } from 'ramda';
import React, { useEffect, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import UINotification from '../../containers/UINotification';
import useAuth from '../../hooks/auth';
import messages from '../../intl/messages';
import { getOrganization } from '../../redux/auth/selectors';
import fontFace from '../../utils/fontFace';
import LSM from '../../utils/LocalStorageManager';
import { ChatBotBubble } from '../ChatBotBubble';
import ErrorBoundary from '../ErrorBoundary';
import ExternalPage from '../ExternalPage';
import Login from '../LoginPage/Login';
import { container, drawerWidth, transition } from './assets/jss/material-dashboard-react';
import ROUTES, { ROUTE_NAMES } from './routes';
import Sidebar from './Sidebar/Sidebar';

const styles = (theme) => ({
	'@global': { ...fontFace },
	wrapper: {
		position: 'relative',
		top: '0',
		height: '100vh',
	},
	mainPanel: {
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${drawerWidth})`,
			height: '100vh',
		},
		overflow: 'auto',
		position: 'relative',
		float: 'right',
		...transition,
		maxHeight: '100%',
		width: '100%',
		overflowScrolling: 'touch',
	},
	content: {
		marginTop: '1rem',
		minHeight: 'calc(100vh - 1rem)',
	},
	container,
	map: {
		marginTop: '70px',
	},
});

let ps;
const useStyles = makeStyles(styles);

const Builder = () => {
	const navigate = useNavigate();
	const visibleRoutes = clone(ROUTES);
	const { isUserLoggedIn, role } = useAuth();
	const location = useLocation();
	const organization = useSelector(getOrganization())?.name ?? null;

	const classes = useStyles();
	// ref to help us initialize PerfectScrollbar on windows devices
	const mainPanel = React.createRef();

	// states and functions
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const resizeFunction = () => {
		if (window.innerWidth >= 960) {
			setMobileOpen(false);
		}
	};

	for (const [name, route] of Object.entries(visibleRoutes)) {
		if (!role || !route.visible({ organization, role })) {
			Reflect.deleteProperty(visibleRoutes, name);
		}
	}

	// initialize and destroy the PerfectScrollbar plugin
	React.useEffect(() => {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
			document.body.style.overflow = 'hidden';
		}
		window.addEventListener('resize', resizeFunction);
		// Specify how to clean up after this effect:
		return function cleanup() {
			if (navigator.platform.indexOf('Win') > -1) {
				ps.destroy();
			}
			window.removeEventListener('resize', resizeFunction);
		};
	}, [mainPanel]);

	useEffect(() => {
		if (!isUserLoggedIn) {
			LSM.removeAccessToken();
			if (location.pathname.startsWith('/external')) {
				navigate(ROUTES[ROUTE_NAMES.LOGIN].path, { state: { isFromExternal: true } });
				return;
			}
			if (!location.pathname.startsWith('/login')) {
				navigate(ROUTES[ROUTE_NAMES.LOGIN].path, { state: { ...location.state } });
			}
		} else if (
			(location.pathname.startsWith('/login') && !location.state?.isFromExternal) ||
			location.pathname === '/'
		) {
			navigate(ROUTES[ROUTE_NAMES.DASHBOARD].path);
		}
	}, [isUserLoggedIn, location.pathname, navigate]);

	return (
		<div className={classes.wrapper}>
			<Sidebar routes={Object.values(visibleRoutes)} open={mobileOpen} />
			<UINotification />
			<div className={classes.mainPanel} ref={mainPanel}>
				<div className={classes.content}>
					<div className={classes.container}>
						<ErrorBoundary>
							<Suspense fallback={<FormattedMessage {...messages.loadingContent} />}>
								<Routes>
									{Object.entries(visibleRoutes).map(([key, { path, component: RouteComponent, isPrivate }]) => {
										return isPrivate && isUserLoggedIn && <Route path={path} element={<RouteComponent />} key={key} />;
									})}
									<Route path={ROUTES[ROUTE_NAMES.EXTERNAL].path} element={<ExternalPage />} key="external" />
									<Route path={ROUTES[ROUTE_NAMES.LOGIN].path} element={<Login />} key="login" />
								</Routes>
							</Suspense>
						</ErrorBoundary>
					</div>
				</div>
			</div>
			<ErrorBoundary>
				{Object.values(ROUTES).find((r) => r.path === window.location.pathname)?.showBubble ? <ChatBotBubble /> : null}
			</ErrorBoundary>
		</div>
	);
};

export default Builder;
