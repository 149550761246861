import Autocomplete from '@mui/lab/Autocomplete';
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../constants';
import { INTL, messages } from '../intl';
import { createUser as createUserAction, fetchOrganizations, fetchOrganizationRoles } from '../redux/auth/actions';
import { getOrganizations, getOrganizationRoles } from '../redux/auth/selectors';
import {
	isCreatingUser as isCreatingUserSel,
	isFetchingOrganizations as isFetchingOrganizationsSel,
} from '../redux/fetching/selectors';
import { fetchUsers } from '../redux/model-table/actions';
import { showNotification } from '../redux/notification/actions';
import isNilOrEmpty from '../utils/isNilOrEmpty';
import { isEmptyValidation, passwordValidation } from '../utils/validation';
import EnhancedTextField from './EnhancedTextField';

const useStyles = makeStyles((theme) => ({
	// Not used at the moment.
	marginSpacing: {
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	wrapper: {
		padding: 10,
	},
}));

const CreateUser = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const organizations = useSelector(getOrganizations);
	const organizationRoles = useSelector(getOrganizationRoles);
	const isFetchingOrganizations = useSelector(isFetchingOrganizationsSel);
	const isCreatingUser = useSelector(isCreatingUserSel);
	const [reset, setReset] = useState(0);
	const [specifyPassword, setSpecifyPassword] = useState(false);
	const [email, setEmail] = useState({});
	const [pwd, setPwd] = useState({});
	const [name, setName] = useState({});
	const [organization, setOrganization] = useState({});
	const [userRole, setUserRole] = useState(organizationRoles[0]);
	const areFieldsValid =
		email?.isValid && (!specifyPassword || pwd?.isValid) && name?.isValid && !isNilOrEmpty(organization) && userRole;

	useEffect(() => {
		dispatch(fetchOrganizations());
		dispatch(fetchOrganizationRoles());
	}, []);

	const createUser = async () => {
		if (specifyPassword) {
			const passwordError = passwordValidation(false)(pwd.value, email.value || '');
			const isPasswordValid = passwordError === null;

			setPwd({ value: pwd.value, errorMsg: passwordError, isValid: isPasswordValid });

			if (!isPasswordValid) {
				return;
			}
		}
		const res = await dispatch(createUserAction(email.value, name.value, pwd.value, organization.id, userRole));
		const user = res?.payload?.data?.register?.name ?? null;
		if (user) {
			dispatch(showNotification(INTL.formatMessage(messages.userCreated, { user }), notificationTypes.INFO));
			setReset(reset + 1);
			dispatch(fetchUsers());
		}
	};

	return (
		<div key={reset} className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<EnhancedTextField
						onValidate={setEmail}
						errorMsg={email.errorMsg}
						validatePred={isEmptyValidation}
						label={<FormattedMessage {...messages.email} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={<Checkbox checked={specifyPassword} onChange={(e) => setSpecifyPassword(event.target.checked)} />}
						label={<FormattedMessage {...messages.specifyPassword} />}
					/>
					<FormHelperText>
						<FormattedMessage {...messages.specifyPasswordInfo} />
					</FormHelperText>
					{specifyPassword && (
						<EnhancedTextField
							type="password"
							autoComplete="new-password"
							onValidate={setPwd}
							errorMsg={pwd.errorMsg}
							validatePred={passwordValidation(false)}
							extraValidationParams={[email.value]}
							label={<FormattedMessage {...messages.password} />}
						/>
					)}
				</Grid>
				<Grid item xs={12}>
					<EnhancedTextField
						onValidate={setName}
						errorMsg={name.errorMsg}
						validatePred={isEmptyValidation}
						label={<FormattedMessage {...messages.name} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						options={organizations}
						getOptionLabel={({ name }) => name}
						renderInput={(params) => (
							<TextField {...params} label={<FormattedMessage {...messages.selectOrganization} />} fullWidth />
						)}
						onChange={(e, v) => setOrganization(v)}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<Autocomplete
						options={organizationRoles}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								label={<FormattedMessage {...messages.selectUserRole} />}
								fullWidth
							/>
						)}
						onChange={(e, v) => setUserRole(v)}
					/>
				</Grid>
			</Grid>
			<Grid container justify={'flex-end'} spacing={1}>
				<Grid item>
					{/* TODO: VS className */}
					<Button
						onClick={createUser}
						color={'primary'}
						disabled={!areFieldsValid || isFetchingOrganizations || isCreatingUser}
						className="action-button"
					>
						<FormattedMessage {...messages.createUser} />
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateUser;
