import { schema as Schema } from 'normalizr';
import { configSchema } from '../model-table/schema';

const phoneNumberSchema = new Schema.Entity(
	'phoneNumbers',
	{
		config: configSchema,
	},
	{ idAttribute: 'phoneNumber' }
);
export const phoneNumberListSchema = [phoneNumberSchema];
