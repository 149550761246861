import * as requests from '../../core/requests';
import { kibanaDashboardsQL } from '../../graphql/kibana';
import { createActionsMap } from '../../utils/action-utils';
import { psf } from '../utils';

export const ID = 'kibana';

export const actionTypes = createActionsMap(ID, [...psf('FETCH_KIBANA_DASHBOARDS')]);

export const fetchKibanaDashboards = (organizationId) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_KIBANA_DASHBOARDS_PENDING,
			actionTypes.FETCH_KIBANA_DASHBOARDS_SUCCESS,
			actionTypes.FETCH_KIBANA_DASHBOARDS_FAIL,
		],
		{ query: kibanaDashboardsQL, variables: { id: organizationId } }
	);
