import Apps from '@mui/icons-material/Apps';
import Dashboard from '@mui/icons-material/Dashboard';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SubjectIcon from '@mui/icons-material/Subject';
import { without } from 'ramda';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { intentEditorSvg, flowEditorSvg, yamlEditorSvg, DataManagementSvg } from '../../components/Icons';
import { ELK_INTEGRATION_ENABLED } from '../../core/configs/consts';
import { messages } from '../../intl';
import { USER_ROLES } from '../../redux/auth/actions';
import { AnalyticsIcon } from '../IconsWithTooltip';

const ROUTES_PUBLIC_PATH = process.env.ROUTES_PUBLIC_PATH ?? '/';

export const ROUTE_NAMES = Object.freeze({
	DASHBOARD: 'dashboard',
	FLOW_EDITOR: 'flow-editor',
	INTENT_EDITOR: 'intent-editor',
	YAML_EDITOR: 'yaml-editor',
	DATA_MANAGEMENT: 'data-management',
	UTTERANCE_TESTER: 'utterance-tester',
	CONFUSION_MATRIX: 'confusion-matrix',
	ANALYTICS: 'analytics',
	TECHNICAL_LOG: 'technical-log',
	EMAIL_ANALYTICS: 'email-analytics',
	CUSTOMER_INSIGHT: 'customer-insight',
	TEXT_CLASSIFICATION: 'text-classification',
	VOICE_SYNTHESIS: 'voice-synthesis',
	OUTBOUND: 'outbound',
	OUTBOUND_DETAIL: 'outbound/:campaignId',
	CALL_CONFIGURATION: 'call-configuration',
	SIP_MANAGEMENT: 'sip-configuration',
	LOGIN: 'login',
	EXTERNAL: 'external',
});

const USER_RIGHTS = {
	[USER_ROLES.SUPER_ADMIN]: Object.values(ROUTE_NAMES),
	[USER_ROLES.ADMIN]: without([ROUTE_NAMES.SIP_MANAGEMENT], Object.values(ROUTE_NAMES)),
	[USER_ROLES.USER]: without([ROUTE_NAMES.SIP_MANAGEMENT], Object.values(ROUTE_NAMES)),
	[USER_ROLES.END_USER]: [
		ROUTE_NAMES.DASHBOARD,
		ROUTE_NAMES.ANALYTICS,
		ROUTE_NAMES.OUTBOUND,
		ROUTE_NAMES.OUTBOUND_DETAIL,
		ROUTE_NAMES.CALL_CONFIGURATION,
		ROUTE_NAMES.DATA_MANAGEMENT,
	],
};

// The env variables are left as strings since arrays and strings have an equally named includes method used below
// This takes care of checking if the values are seperated by a comma with/without whitespace
const OUTBOUND_ORGANIZATIONS =
	process.env.OUTBOUND_ORGANIZATIONS && process.env.OUTBOUND_ORGANIZATIONS.length > 0
		? process.env.OUTBOUND_ORGANIZATIONS
		: ['Born Digital', 'Kooperativa', 'Cool People', 'PSS', 'Srovnejto', 'EPET'];

const VOICE_SYNTHESIS_ORGANIZATIONS =
	process.env.VOICE_SYNTHESIS_ORGANIZATIONS && process.env.VOICE_SYNTHESIS_ORGANIZATIONS.length > 0
		? process.env.VOICE_SYNTHESIS_ORGANIZATIONS
		: ['Born Digital', 'Axa', 'AXA'];

const ROUTES = {
	root: {
		path: ROUTES_PUBLIC_PATH,
		visible: () => false,
		showBubble: true,
	},
	[ROUTE_NAMES.DASHBOARD]: {
		name: <FormattedMessage {...messages.dashboard} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.DASHBOARD}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.DASHBOARD),
		icon: Dashboard,
		component: React.lazy(() => import(/* webpackChunkName: "dashboard" */ './Dashboard/Dashboard')),
		showBubble: true,
		showInMenu: true,
		isPrivate: true,
		section: 1,
	},
	[ROUTE_NAMES.FLOW_EDITOR]: {
		name: <FormattedMessage {...messages.flowEditor} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.FLOW_EDITOR}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.FLOW_EDITOR),
		icon: () => flowEditorSvg,
		component: React.lazy(() => import(/* webpackChunkName: "flow-editor" */ './FlowEditor')),
		showBubble: true,
		showInMenu: true,
		isPrivate: true,
		section: 2,
	},
	[ROUTE_NAMES.INTENT_EDITOR]: {
		name: <FormattedMessage {...messages.intentEditor} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.INTENT_EDITOR}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.INTENT_EDITOR),
		icon: () => intentEditorSvg,
		component: React.lazy(() => import(/* webpackChunkName: "intent-editor" */ './IntentEditor/IntentEditor')),
		showInMenu: true,
		isPrivate: true,
		section: 2,
	},
	[ROUTE_NAMES.YAML_EDITOR]: {
		name: <FormattedMessage {...messages.configEditor} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.YAML_EDITOR}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.YAML_EDITOR),
		icon: () => yamlEditorSvg,
		component: React.lazy(() => import(/* webpackChunkName: "yaml-editor" */ './YAMLEditor/YAMLEditor')),
		showBubble: true,
		showInMenu: true,
		isPrivate: true,
		section: 2,
	},
	[ROUTE_NAMES.DATA_MANAGEMENT]: {
		name: <FormattedMessage {...messages.dataManagement} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.DATA_MANAGEMENT}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.DATA_MANAGEMENT),
		icon: () => DataManagementSvg,
		component: React.lazy(() => import(/* webpackChunkName: "data-management" */ './DataManagement/DataManagement')),
		showBubble: true,
		showInMenu: true,
		isPrivate: true,
		section: 2,
	},
	[ROUTE_NAMES.UTTERANCE_TESTER]: {
		name: <FormattedMessage {...messages.utteranceTester} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.UTTERANCE_TESTER}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.UTTERANCE_TESTER),
		icon: SpellcheckIcon,
		component: React.lazy(() => import(/* webpackChunkName: "utterance-tester" */ './UtteranceTester/UtteranceTester')),
		showInMenu: true,
		isPrivate: true,
		section: 3,
	},
	[ROUTE_NAMES.CONFUSION_MATRIX]: {
		name: <FormattedMessage {...messages.confusionMatrix} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.CONFUSION_MATRIX}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.CONFUSION_MATRIX),
		icon: Apps,
		component: React.lazy(() => import(/* webpackChunkName: "matrix" */ './ConfusionMatrix/ConfusionMatrix')),
		showInMenu: true,
		isPrivate: true,
		section: 3,
	},
	[ROUTE_NAMES.ANALYTICS]: {
		name: <FormattedMessage {...messages.analytics} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.ANALYTICS}`,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.ANALYTICS),
		icon: AnalyticsIcon,
		component: React.lazy(() => import(/* webpackChunkName: "analytics" */ './Analytics/Analytics')),
		showInMenu: true,
		isPrivate: true,
		section: 4,
	},
	[ROUTE_NAMES.TECHNICAL_LOG]: {
		name: <FormattedMessage {...messages.technicalLog} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.TECHNICAL_LOG}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.TECHNICAL_LOG) && !ELK_INTEGRATION_ENABLED,
		icon: DeveloperModeIcon,
		component: React.lazy(() => import(/* webpackChunkName: "tech-log" */ './Analytics/TechnicalLog')),
		showInMenu: true,
		isPrivate: true,
		section: 4,
	},
	[ROUTE_NAMES.EMAIL_ANALYTICS]: {
		name: <FormattedMessage {...messages.emailAnalytics} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.EMAIL_ANALYTICS}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.EMAIL_ANALYTICS) && ['Born Digital'].includes(organization),
		icon: EmailIcon,
		component: React.lazy(() => import(/* webpackChunkName: "email-analytics" */ './Analytics/EmailAnalytics')),
		showInMenu: true,
		isPrivate: true,
		section: 4,
	},
	[ROUTE_NAMES.CUSTOMER_INSIGHT]: {
		name: <FormattedMessage {...messages.customerInsight} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.CUSTOMER_INSIGHT}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.CUSTOMER_INSIGHT) && ['Born Digital'].includes(organization),
		icon: PhoneIcon,
		component: React.lazy(() => import(/* webpackChunkName: "customer-insight" */ './Analytics/CustomerInsight')),
		showInMenu: true,
		isPrivate: true,
		section: 4,
	},
	[ROUTE_NAMES.TEXT_CLASSIFICATION]: {
		name: <FormattedMessage {...messages.textClassification} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.TEXT_CLASSIFICATION}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.TEXT_CLASSIFICATION) && ['Born Digital'].includes(organization),
		icon: SubjectIcon,
		component: React.lazy(() => import(/* webpackChunkName: "text-classification" */ './Analytics/TextClassification')),
		showInMenu: true,
		isPrivate: true,
		section: 4,
	},
	[ROUTE_NAMES.VOICE_SYNTHESIS]: {
		name: <FormattedMessage {...messages.voiceSynthesis} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.VOICE_SYNTHESIS}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.VOICE_SYNTHESIS) && VOICE_SYNTHESIS_ORGANIZATIONS.includes(organization),
		icon: RecordVoiceOverIcon,
		component: React.lazy(() => import(/* webpackChunkName: "voice-synthesis" */ './VoiceSynthesis/VoiceSynthesis')),
		showInMenu: true,
		isPrivate: true,
		section: 5,
	},
	[ROUTE_NAMES.OUTBOUND]: {
		name: <FormattedMessage {...messages.campaigns} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.OUTBOUND}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.OUTBOUND) &&
			(OUTBOUND_ORGANIZATIONS.includes(organization) || OUTBOUND_ORGANIZATIONS === 'All'),
		icon: PhoneForwardedIcon,
		component: React.lazy(() => import(/* webpackChunkName: "outbound" */ './Outbound/Campaigns')),
		showInMenu: true,
		isPrivate: true,
		section: 5,
	},
	[ROUTE_NAMES.OUTBOUND_DETAIL]: {
		name: <FormattedMessage {...messages.campaigns} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.OUTBOUND_DETAIL}`,
		visible: ({ organization, role }) =>
			USER_RIGHTS[role].includes(ROUTE_NAMES.OUTBOUND_DETAIL) &&
			(OUTBOUND_ORGANIZATIONS.includes(organization) || OUTBOUND_ORGANIZATIONS === 'All'),
		icon: PhoneForwardedIcon,
		component: React.lazy(() => import(/* webpackChunkName: "outbound" */ './Outbound/Campaigns')),
		isPrivate: true,
		section: 5,
	},
	[ROUTE_NAMES.CALL_CONFIGURATION]: {
		name: <FormattedMessage {...messages.callConfiguration} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.CALL_CONFIGURATION}`,
		icon: PhonelinkSetupIcon,
		visible: ({ organization, role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.CALL_CONFIGURATION),
		component: React.lazy(() =>
			import(/* webpackChunkName: "call-configuration" */ './CallConfiguration/CallConfiguration')
		),
		showInMenu: true,
		isPrivate: true,
		section: 5,
	},
	[ROUTE_NAMES.SIP_MANAGEMENT]: {
		name: <FormattedMessage {...messages.sipManagement} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.SIP_MANAGEMENT}`,
		icon: PhonelinkSetupIcon,
		visible: ({ role }) => USER_RIGHTS[role].includes(ROUTE_NAMES.SIP_MANAGEMENT),
		component: React.lazy(() =>
			import(/* webpackChunkName: "call-configuration" */ '../SipConfiguration/SipConfiguration')
		),
		showInMenu: true,
		isPrivate: true,
		section: 5,
	},
	[ROUTE_NAMES.LOGIN]: {
		name: <FormattedMessage {...messages.login} />,
		path: `${ROUTES_PUBLIC_PATH}${ROUTE_NAMES.LOGIN}`,
		visible: () => true,
		component: React.lazy(() => import(/* webpackChunkName: "login" */ '../LoginPage/Login')),
	},
	// "External page for showing modals from external context (like Kibana etc)"
	[ROUTE_NAMES.EXTERNAL]: {
		name: 'external',
		path: `${ROUTES_PUBLIC_PATH}external/:id/:type`,
		visible: () => true,
		component: React.lazy(() => import(/* webpackChunkName: "external" */ '../ExternalPage')),
		showBubble: false,
	},
};

export default ROUTES;
