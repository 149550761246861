import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, removeUtterance } from '../../redux/model/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveUtteranceModal = (props) => {
	const dispatch = useDispatch();
	const { intentId, utteranceId } = props;

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.deleteUtteranceTitle} />}
			saveBtnTitle={<FormattedMessage {...messages.deleteUtteranceTitle} />}
			onSave={() => {
				dispatch(registerStateBeforeDispatchingAction(removeUtterance(intentId, utteranceId)));
				dispatch(hideModal());
			}}
			isSaveBtnDisabled={false}
		>
			<FormattedMessage
				{...messages.deleteUtteranceContent}
				values={{ name: utteranceId, strong: (msg) => <strong>{msg}</strong> }}
			/>
		</SaveCloseModalTemplate>
	);
};

RemoveUtteranceModal.propTypes = {
	intentId: PropTypes.string,
	utteranceId: PropTypes.string,
};

export default RemoveUtteranceModal;
