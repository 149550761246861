import { gql } from 'graphql-tag';

const METADATA_FIELDS = gql`
	fragment METADATA_FIELDS on Metadata {
		id
		name
		description
		createdAt
		updatedAt
		createdBy {
			name
		}
		updatedBy {
			name
		}
	}
`;

export const qlcreateMetadata = gql`
	mutation createMetadata($name: String!, $description: String!) {
		createMetadata(name: $name, description: $description) {
			...METADATA_FIELDS
		}
	}
	${METADATA_FIELDS}
`;

export const metadatas = gql`
	query($sort: [MetadataSort!], $filter: String) {
		metadatas(sort: $sort, name: $filter) {
			...METADATA_FIELDS
		}
	}
	${METADATA_FIELDS}
`;

export const metadata = gql`
	query($id: ID!) {
		metadata(id: $id) {
			...METADATA_FIELDS
		}
	}
	${METADATA_FIELDS}
`;

export const deleteMetadata = gql`
	mutation($id: ID!) {
		deleteMetadata(id: $id) {
			...METADATA_FIELDS
		}
	}
	${METADATA_FIELDS}
`;

export const updateMetadata = gql`
	mutation($id: ID!, $name: String, $description: String) {
		updateMetadata(id: $id, name: $name, description: $description) {
			...METADATA_FIELDS
		}
	}
	${METADATA_FIELDS}
`;

export const getDataTableColumns = gql`
	query($tableName: String!) {
		getDataTableColumns(tableName: $tableName) {
			tableName
			columnNames
		}
	}
`;

export const getDataTableRows = gql`
	query($tableName: String!, $columnNames: [String]) {
		getDataTableRows(tableName: $tableName, columnNames: $columnNames)
	}
`;

export const addDataTableColumn = gql`
	mutation($tableName: String!, $columnName: String!, $columnType: ColumnDataTypesEnum!) {
		addDataTableColumn(tableName: $tableName, columnName: $columnName, columnType: $columnType) {
			tableName
			columnNames
		}
	}
`;

export const deleteDataTableColumn = gql`
	mutation($tableName: String!, $columnName: String!) {
		deleteDataTableColumn(tableName: $tableName, columnName: $columnName) {
			tableName
			columnNames
		}
	}
`;

export const insertUpdateTableRow = gql`
	mutation($tableName: String!, $rows: [String!]!) {
		insertUpdateRow(tableName: $tableName, rows: $rows)
	}
`;

export const deleteTableRow = gql`
	mutation($tableName: String!, $rowId: String!) {
		deleteRow(tableName: $tableName, rowId: $rowId)
	}
`;

export const importRows = gql`
	mutation($tableName: String!, $rows: [String!]!) {
		importRows(tableName: $tableName, rows: $rows) {
			rows
			error
		}
	}
`;

export const getDataTableColumnsType = gql`
	query($tableName: String!) {
		getDataTableColumnsType(tableName: $tableName)
	}
`;
