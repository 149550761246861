import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { isEmptyValidation } from '../../utils/validation';
import { FILTER_TYPES } from '../MaterialDashboard/Outbound/PhoneCalls';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles(() => ({
	container: {
		alignItems: 'center',
	},
}));

const CampaignPhoneCallFilterModal = ({ setPhoneCallFilter, phoneCallFilter }) => {
	const classes = useStyles();
	const [filterString, setFilterString] = useState(phoneCallFilter.value);
	const [filterType, setFilterType] = useState(phoneCallFilter.type);
	const [errorMessage, setErrorMessage] = useState(null);
	const dispatch = useDispatch();

	const validateFilter = (filter) => {
		const isEmpty = isEmptyValidation(filter);
		if (isEmpty) {
			setErrorMessage(isEmpty);
			return;
		}

		const isNotNumber = isNaN(filter);
		if (isNotNumber) {
			setErrorMessage(isNotNumber);
			return;
		}

		return true;
	};

	const handleAddFilter = () => {
		if (validateFilter) {
			setPhoneCallFilter({ value: filterString, type: filterType });
			dispatch(hideModal());
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAddFilter();
		}
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.campaignPhoneCallFilter} />}
			saveBtnTitle={<FormattedMessage {...messages.filter} />}
			onSave={handleAddFilter}
		>
			<Grid container classes={{ root: classes.container }}>
				<Grid item xs={4}>
					<FormControl component="fieldset">
						<FormLabel component="legend">
							<FormattedMessage {...messages.filterType} />
						</FormLabel>
						<RadioGroup value={filterType} onChange={(e, v) => setFilterType(v)}>
							<FormControlLabel value={FILTER_TYPES.LESSER_THAN} control={<Radio />} label="<" />
							<FormControlLabel value={FILTER_TYPES.GREATER_THAN} control={<Radio />} label=">" />
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={8} align="left">
					<TextField
						autoFocus
						variant={'outlined'}
						fullWidth
						label={<FormattedMessage {...messages.durationInSeconds} />}
						onKeyPress={handleKeyPress}
						error={errorMessage}
						value={filterString}
						onChange={(e) => {
							validateFilter(e.target.value);
							setFilterString(e.target.value);
						}}
					/>
				</Grid>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

CampaignPhoneCallFilterModal.propTypes = {
	setPhoneCallFilter: PropTypes.func,
	phoneCallFilter: PropTypes.object,
};

export default CampaignPhoneCallFilterModal;
