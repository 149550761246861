import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
	textField: {
		maxWidth: 300,
		margin: '10px 0 20px !important',
	},
}));

const SearchField = ({ onSearch, debounceTime = 0, label }) => {
	const classes = useStyles();
	const [value, setValue] = useState('');

	const debouncedSearch = debounce(onSearch, debounceTime);
	const handleChange = (e) => {
		setValue(e.target.value);
		debouncedSearch(e.target.value);
	};
	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			onSearch(value);
			debouncedSearch.cancel();
		}
	};

	const handleClear = () => {
		setValue('');
		onSearch('');
	};

	return (
		<Box display={'flex'} flexDirection={'row-reverse'}>
			<TextField
				label={label}
				className={classes.textField}
				value={value}
				variant="standard"
				onChange={handleChange}
				onKeyPress={handleKeyPress}
				InputProps={{
					startAdornment: <SearchIcon color={'disabled'} />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={handleClear}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</Box>
	);
};

SearchField.propTypes = {
	debounceTime: PropTypes.number,
	onSearch: PropTypes.func,
	label: PropTypes.object,
};

export default SearchField;
