import { FormControl, Grid, InputLabel, Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../intl';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, setSttBoostEntry } from '../../redux/model/actions';
import FreeSoloAutocomplete from '../inputs/FreeSoloAutocomplete';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles({
	formField: {
		width: '90%',
		marginBottom: '20px',
		marginLeft: '20px',
	},
});

const SttBoostModal = ({ nodeId, boostEntry = {}, indexInBoostEntries = -1 }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [boostValue, setBoostValue] = useState(boostEntry.boost || 0);
	const [boostPhrases, setBoostPhrases] = useState(boostEntry.phrases || []);

	const handleSaveBoostEntry = () => {
		dispatch(
			registerStateBeforeDispatchingAction(
				setSttBoostEntry(nodeId, { boost: boostValue, phrases: boostPhrases }, indexInBoostEntries)
			)
		);

		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.sttBoostModal} />}
				saveBtnTitle={<FormattedMessage {...messages.save} />}
				onSave={handleSaveBoostEntry}
				disableEscapeDialogClose
				disableBackdropClickDialogClose
			>
				<Grid container>
					<Grid container>
						<Grid item xs={12}>
							<FreeSoloAutocomplete values={boostPhrases} setValues={setBoostPhrases} label="boostPhrases" />
						</Grid>
						<Grid item xs={12}>
							<FormControl className={classes.formField}>
								<InputLabel>
									<FormattedMessage {...messages.boostValue} />
								</InputLabel>
								<Slider
									value={boostValue}
									min={0}
									max={20}
									step={1}
									valueLabelDisplay="auto"
									onChange={(e, value) => setBoostValue(value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

SttBoostModal.propTypes = {
	nodeId: PropTypes.string,
	boostEntry: PropTypes.object,
	indexInBoostEntries: PropTypes.number,
};

export default SttBoostModal;
