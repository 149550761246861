import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { IconButton, Grid, TextField, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../../intl';
import FormattedDate from '../../../intl/FormattedDate';
import { updateMetaDataParam } from '../../../redux/outbound/actions';
import { fetchRecording } from '../../../redux/sip/actions';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import { areCallDataAvail, useStylesCampaignDetail } from './CampaignDetail';

const PhoneCallDetail = ({
	call,
	disableUI,
	id,
	number,
	isPhoneCallAudioPlaying,
	setIsPhoneCallAudioPlaying,
	isSip,
}) => {
	const classes = useStylesCampaignDetail();
	const dispatch = useDispatch();
	const [audioUrl, setAudioUrl] = useState(null);
	const [nextCallTime, setNextCallTime] = useState(null);
	const audioPlayer = useRef(null);

	useEffect(() => {
		if (!audioUrl && number && call.callId && areCallDataAvail(call.status)) {
			dispatch(fetchRecording(call.callId, isSip)).then(
				(data) => {
					if (isSip) {
						setAudioUrl('data:audio/wav;base64,' + data.payload.data.callRecordings[0].url);
					} else {
						setAudioUrl(data.payload.data.callRecordings[0].url + '.wav');
					}
				},
				() => {
					setAudioUrl(null);
				}
			);
		}
	}, [audioUrl, call?.callId, call?.status, number]);

	useEffect(() => {
		setAudioUrl(null);
	}, [number]);

	useEffect(() => {
		if (audioUrl && isPhoneCallAudioPlaying) {
			audioPlayer.current.play();
			audioPlayer.current.addEventListener('ended', () => setIsPhoneCallAudioPlaying(false));
		} else if (audioUrl) {
			audioPlayer.current.pause();
		}
	}, [audioUrl, isPhoneCallAudioPlaying]);

	useEffect(() => {
		if (call?.nextCallTime) {
			setNextCallTime(call?.nextCallTime);
		} else if (call?.firstCallTime > new Date().toISOString()) {
			setNextCallTime(call?.firstCallTime);
		} else {
			setNextCallTime(null);
		}
	}, [call?.nextCallTime, call?.firstCallTime]);

	return (
		<div key={number + 'PhoneCallDetail'} className={classes.section}>
			<div className={classes.sectionHeader}>
				<FormattedMessage {...messages.outboundDetails} />
				<strong>{number && ' (' + formatPhoneNumber(number) + ')'}</strong>
			</div>
			{number && (
				<>
					{audioUrl && (
						<Grid spacing={2} container direction="row" className={classes.defaultFont}>
							<Grid item xs={12}>
								<Tooltip
									title={
										<FormattedMessage
											{...messages[isPhoneCallAudioPlaying ? 'pausePhoneCallAudio' : 'playPhoneCallAudio']}
										/>
									}
									placement={'top'}
								>
									<IconButton
										className={classes.customPlayBtn}
										onClick={() => setIsPhoneCallAudioPlaying(!isPhoneCallAudioPlaying)}
									>
										{isPhoneCallAudioPlaying ? (
											<PauseCircleFilledIcon className={classes.customPlayBtnIcon} />
										) : (
											<PlayCircleFilledIcon className={classes.customPlayBtnIcon} />
										)}
									</IconButton>
								</Tooltip>
								<audio controls className={{ width: '100%' }} ref={audioPlayer} src={audioUrl} type="audio/mpeg" />
							</Grid>
						</Grid>
					)}
					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={4}>
							<FormattedMessage {...messages.status} />
						</Grid>
						<Grid item xs={8}>
							{call?.status}
						</Grid>
					</Grid>

					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={4}>
							<FormattedMessage {...messages.trials} />
						</Grid>
						<Grid item xs={8}>
							{call?.trials}
						</Grid>
					</Grid>

					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={4}>
							<FormattedMessage {...messages.calledAt} />
						</Grid>
						<Grid item xs={8}>
							{call?.calledAt && <FormattedDate value={call?.calledAt} />}
						</Grid>
					</Grid>

					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={4}>
							<FormattedMessage {...messages.error} />
						</Grid>
						<Grid item xs={8}>
							{call?.error}
						</Grid>
					</Grid>
					<Grid spacing={2} container direction="row" className={classes.defaultFont}>
						<Grid item xs={4}>
							<FormattedMessage {...messages.nextCallTime} />
						</Grid>
						<Grid item xs={8}>
							{nextCallTime && <FormattedDate value={nextCallTime} />}
						</Grid>
					</Grid>
					<br />
					{Object.entries(call?.metaData ?? {}).map(([param, value]) => (
						<Grid spacing={2} container key={number + param} direction="row" alignItems="center">
							<Grid item xs={12}>
								<TextField
									className={classes.defaultFont}
									label={param}
									InputLabelProps={{
										shrink: true,
									}}
									defaultValue={value}
									onChange={(e) => dispatch(updateMetaDataParam(id, number, param, e.target.value))}
									disabled={disableUI}
									variant="standard"
								/>
							</Grid>
						</Grid>
					))}
				</>
			)}
		</div>
	);
};

PhoneCallDetail.propTypes = {
	call: PropTypes.object,
	disableUI: PropTypes.bool,
	id: PropTypes.string,
	number: PropTypes.string,
	isPhoneCallAudioPlaying: PropTypes.bool,
	setIsPhoneCallAudioPlaying: PropTypes.func,
	isSip: PropTypes.bool,
};

export default PhoneCallDetail;
