/**
 * Serves to configure the bot. Configuration should affect the whole app (possibility to configure it), e.g.:
 * according to `transitionTypes` the Editor should show relevant components.
 *
 * This file contains common identifiers and exported all the configurations for the bots (only GoodBot now).
 *
 * The file `const.js` contains all the existing identifiers. You should import all the types from here in the code
 * and also in every specific configuration for the bot.
 *
 * The Redux store is handled accordingly by `model.modelConfigType` attribute.
 */

import * as Sentry from '@sentry/react';
import isPlainObject from 'lodash/isPlainObject';
import size from 'lodash/size';

export {
	SIGNAL_TRANSITION_TYPES,
	TRANSITION_TYPES,
	GOTO_TRANSITION_TYPES,
	PROPERTY_TYPES,
	RESPONSE_TYPES,
} from './consts';

export GoodBot from './GoodBot';

export const GOODBOT = 'GoodBot';

export const CONFIGURATION = 'configuration';
export const GLOBAL_START_NODE = 'start_node';

export const FLOW = 'flow';
export const INTENTS = 'intents';
export const LEGACY_INTENTS_NAME_IN_YAML = 'anchor';
/**
 * So called SSI (Semantically Same Intents). The format stored here is the following.
 * { NODE_A: [NODE_B, NODE_C], NODE_B: [NODE_A], NODE_C: [NODE_A, NODE_B] }
 */
export const SEMANTICALLY_SIMILAR_INTENTS = 'state_nodes_groups';
export const NODE_GROUPS = 'diagram_groups_config';
export const FLOW_EDITOR_UI = 'ui';
export const YAML_EDITOR_UI = 'yamlUi';
export const MODEL_CONFIG_TYPE = 'modelConfigType';

export const VOCABULARY = 'vocabulary';
export const STOPWORDS = 'stopwords';

/**
 * This function serves for the backward compatibility purpose of the outdated CALL and EXTRACT records
 *
 * @param {Object | [String, String | [String, Object]]} fn - potentially outdated smartFunction data structure to be adjusted
 * @returns {Object} (smartFunction) - uniform data structure
 */
export const unifySmartFunction = (fn) => {
	if (isPlainObject(fn) && size(fn) === 1) {
		fn = Object.entries(fn)[0];
	}

	if (Array.isArray(fn) && fn.length === 2) {
		const [variable, extractor] = fn;
		return { [variable]: extractor };
	} else {
		const message = `Unrecognized smart function format encountered: ${JSON.stringify(fn)}`;
		console.warn(message);
		Sentry.captureException(message, { level: Sentry.Severity.Warning });
		return fn;
	}
};

/**
 * This function serves for the backward compatibility purpose of the outdated CONDITION records with !omap.
 *
 * @param {Object | [String, String]} condition - potentially outdated condition data structure to be adjusted
 * @returns {[String, String]} ([condition, target node]) - uniform data structure
 */
export const unifyCondition = (condition) => {
	if (isPlainObject(condition) && size(condition) === 1) {
		return Object.entries(condition)[0];
	} else if (Array.isArray(condition) && condition.length === 2) {
		return condition;
	} else {
		const message = `Unrecognized format of condition encountered: ${JSON.stringify(condition)}`;
		console.warn(message);
		Sentry.captureException(message, { level: Sentry.Severity.Warning });
		return condition;
	}
};
