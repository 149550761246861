import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { update } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { getModels } from '../../redux/model-table/selectors';
import { fetchSipAccountsForModel } from '../../redux/sip/actions';
import { getSipAccounts } from '../../redux/sip/selectors';
import { fetchPhoneNumbers } from '../../redux/voice/actions';
import { getPhoneNumbersAssignedToProject } from '../../redux/voice/selectors';
import { convertDateToString, convertStringToDate } from '../../utils/date-time';
import FreeSoloAutocomplete from '../inputs/FreeSoloAutocomplete';
import { FIELD, validateCampaignField } from '../Modals/CreateEditCopyCampaignModal';

const useStyles = makeStyles({
	textField: {
		'& input': {
			height: '19px',
		},
	},
});

const TEL_PROVIDER = {
	TWILIO: 'Twilio',
	SIP: 'Sip account',
};
const CAMPAIGN_TYPE = {
	BATCH: 'BATCH',
	CONTINUOUS: 'CONTINUOUS',
};
const CampaignForm = ({ state, setState, handleAdd }) => {
	const styles = useStyles();
	const dispatch = useDispatch();

	const models = useSelector(getModels);
	const sipAccounts = useSelector(getSipAccounts);
	const phoneNumbers = useSelector(getPhoneNumbersAssignedToProject);

	const [telProvider, setTelProvider] = useState(TEL_PROVIDER.SIP);

	const [repeatCallDatesManually, setRepeatCallDatesManually] = useState(state[FIELD.CALL_DATES].length > 0);
	const [syncStatus, setSyncStatus] = useState(!!state[FIELD.STATUS_NOTIF]);

	const [formFieldErrors, setFormFieldErrors] = useState({
		[FIELD.MODEL_ID]: null,
		[FIELD.TWILIO_NUMBER]: null,
		[FIELD.TRUNK_ACCOUNT]: null,
		[FIELD.TWILIO_FLOW_ID]: null,
		[FIELD.PAR_CALLS]: null,
		[FIELD.REP_CALL_DELAY]: null,
		[FIELD.TRIALS]: null,
		[FIELD.campaignType]: null,
	});

	const validateAndUpdate = (value, field) => {
		const error = validateCampaignField(value, field);

		setFormFieldErrors({ ...formFieldErrors, [field]: error });
		setState({ ...state, [field]: value });
	};

	useEffect(() => {
		if (telProvider === TEL_PROVIDER.SIP && state[FIELD.MODEL_ID]) {
			dispatch(fetchSipAccountsForModel(state[FIELD.MODEL_ID]));
			setState({
				...state,
				[FIELD.TWILIO_NUMBER]: state[FIELD.TWILIO_NUMBER],
				[FIELD.TWILIO_FLOW_ID]: state[FIELD.TWILIO_FLOW_ID],
			});
		} else {
			dispatch(fetchPhoneNumbers());
			setState({ ...state, [FIELD.TRUNK_ACCOUNT]: state[FIELD.TRUNK_ACCOUNT] });
		}
	}, [
		telProvider,
		state[FIELD.TRUNK_ACCOUNT],
		state[FIELD.TWILIO_FLOW_ID],
		state[FIELD.TWILIO_NUMBER],
		state[FIELD.MODEL_ID],
	]);

	// Used for initial selection in case of copy/update campaign
	useEffect(() => {
		if (state[FIELD.TWILIO_FLOW_ID]) {
			setTelProvider(TEL_PROVIDER.TWILIO);
		} else if (state[FIELD.TRUNK_ACCOUNT]) {
			setTelProvider(TEL_PROVIDER.SIP);
		}
	}, [state]);

	useEffect(() => {
		// Remove all callDates and use the delay between repeated calls instead
		if (!repeatCallDatesManually) {
			setState({
				...state,
				[FIELD.CALL_DATES]: [],
			});
		}
		// Adjust number of call dates based on call trials
		else if (state[FIELD.TRIALS] - 1 < state[FIELD.CALL_DATES].length) {
			setState({
				...state,
				[FIELD.CALL_DATES]:
					state[FIELD.TRIALS] - 1 > 0 ? state[FIELD.CALL_DATES].slice(0, state[FIELD.TRIALS] - 1) : [],
			});
		} else if (state[FIELD.TRIALS] - 1 > state[FIELD.CALL_DATES].length) {
			const difference = state[FIELD.TRIALS] - 1 - state[FIELD.CALL_DATES].length;
			const newElements = new Array(difference).fill(new Date());
			setState({
				...state,
				[FIELD.CALL_DATES]: [...state[FIELD.CALL_DATES], ...newElements],
			});
		}
	}, [state[FIELD.TRIALS], repeatCallDatesManually]);

	return (
		<>
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel id="telephonyProvider">
						<FormattedMessage {...messages.telephonyProvider} />
					</InputLabel>
					<Select
						labelId="telephonyProvider"
						label={<FormattedMessage {...messages.telephonyProvider} />}
						value={telProvider || ''}
						defaultValue={telProvider}
						onChange={(e) => {
							// Not resetting the values on change always resets provider back to the original value
							setState({ ...state, [FIELD.TWILIO_FLOW_ID]: '', [FIELD.TWILIO_NUMBER]: '', [FIELD.TRUNK_ACCOUNT]: '' });
							setTelProvider(e.target.value);
						}}
						// disabled={!!campId}
					>
						{Object.values(TEL_PROVIDER).map((provider) => (
							<MenuItem key={provider} value={provider || ''}>
								{provider}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel id="project">
						<FormattedMessage {...messages.project} />
					</InputLabel>
					<Select
						labelId="project"
						label={<FormattedMessage {...messages.project} />}
						value={state[FIELD.MODEL_ID] || ''}
						error={Boolean(formFieldErrors[FIELD.MODEL_ID])}
						helperText={formFieldErrors[FIELD.MODEL_ID]}
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.MODEL_ID)}
					>
						{Object.values(models).map((model) => (
							<MenuItem key={model.id} value={model.id}>
								{model.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel id="campaignTyoe">
						<FormattedMessage {...messages.campaignType} />
					</InputLabel>
					<Select
						labelId="campaignTyoe"
						label={<FormattedMessage {...messages.campaignType} />}
						value={state[FIELD.CAMPAIGN_TYPE]}
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.CAMPAIGN_TYPE)}
					>
						{Object.values(CAMPAIGN_TYPE).map((type) => (
							<MenuItem key={type} value={type || ''}>
								{type.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
									index === 0 ? match.toUpperCase() : match.toLowerCase()
								)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			{telProvider === TEL_PROVIDER.SIP ? (
				<Grid item xs={6}>
					<FormControl error={Boolean(formFieldErrors[FIELD.TRUNK_ACCOUNT])} fullWidth>
						<InputLabel id="trunkAccountSelect">
							<FormattedMessage {...messages.trunkAccount} />
						</InputLabel>
						<Select
							error={Boolean(formFieldErrors[FIELD.TRUNK_ACCOUNT])}
							labelId="trunkAccountSelect"
							label={<FormattedMessage {...messages.trunkAccount} />}
							value={state[FIELD.TRUNK_ACCOUNT] || ''}
							onChange={(e) => validateAndUpdate(e.target.value, FIELD.TRUNK_ACCOUNT)}
						>
							{sipAccounts.map(({ accountUri }) => (
								<MenuItem key={accountUri} value={accountUri || ''}>
									{accountUri}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{formFieldErrors[FIELD.TRUNK_ACCOUNT]}</FormHelperText>
					</FormControl>
				</Grid>
			) : (
				<Grid item xs={6}>
					<FormControl error={Boolean(formFieldErrors[FIELD.TWILIO_NUMBER])} fullWidth>
						<InputLabel id="twilioNumberSelect">
							<FormattedMessage {...messages.twilioNumber} />
						</InputLabel>
						<Select
							error={Boolean(formFieldErrors[FIELD.TWILIO_NUMBER])}
							labelId="twilioNumberSelect"
							label={<FormattedMessage {...messages.twilioNumber} />}
							value={state[FIELD.TWILIO_NUMBER] || ''}
							onChange={(e) => validateAndUpdate(e.target.value, FIELD.TWILIO_NUMBER)}
						>
							{phoneNumbers.map(({ phoneNumber, name }) => (
								<MenuItem key={phoneNumber} value={phoneNumber || ''}>
									{name}
								</MenuItem>
							))}
						</Select>
						<FormHelperText>{formFieldErrors[FIELD.TWILIO_NUMBER]}</FormHelperText>
					</FormControl>
				</Grid>
			)}
			<Grid container item spacing={2}>
				<Grid item xs={6}>
					<TextField
						autoFocus
						value={state[FIELD.NAME] || ''}
						error={Boolean(formFieldErrors[FIELD.NAME])}
						helperText={formFieldErrors[FIELD.NAME]}
						fullWidth
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.NAME)}
						onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
						label={<FormattedMessage {...messages.campaignName} />}
						className={styles.textField}
						variant="standard"
					/>
				</Grid>
				{telProvider === TEL_PROVIDER.TWILIO && (
					<Grid item xs={6}>
						<TextField
							value={state[FIELD.TWILIO_FLOW_ID] || ''}
							error={Boolean(formFieldErrors[FIELD.TWILIO_FLOW_ID])}
							helperText={formFieldErrors[FIELD.TWILIO_FLOW_ID]}
							fullWidth
							onChange={(e) => validateAndUpdate(e.target.value, FIELD.TWILIO_FLOW_ID)}
							onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
							label={<FormattedMessage {...messages.twilioFlowId} />}
							variant="standard"
						/>
					</Grid>
				)}
			</Grid>
			<Grid item xs={4}>
				<TextField
					value={state[FIELD.PAR_CALLS] || ''}
					error={Boolean(formFieldErrors[FIELD.PAR_CALLS])}
					helperText={formFieldErrors[FIELD.PAR_CALLS]}
					fullWidth
					type="number"
					onChange={(e) =>
						Number(e.target.value) > 0 || !e.target.value
							? validateAndUpdate(Number(e.target.value), FIELD.PAR_CALLS)
							: null
					}
					onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
					label={<FormattedMessage {...messages.maxParallelCalls} />}
					variant="standard"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					value={state[FIELD.TRIALS] || ''}
					error={Boolean(formFieldErrors[FIELD.TRIALS])}
					helperText={formFieldErrors[FIELD.TRIALS]}
					fullWidth
					type="number"
					onChange={(e) =>
						Number(e.target.value) > 0 || !e.target.value
							? validateAndUpdate(Number(e.target.value), FIELD.TRIALS)
							: null
					}
					onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
					label={<FormattedMessage {...messages.maxCallTrials} />}
					variant="standard"
				/>
			</Grid>
			{!repeatCallDatesManually && (
				<Grid item xs={4}>
					<TextField
						value={state[FIELD.REP_CALL_DELAY] || ''}
						error={Boolean(formFieldErrors[FIELD.REP_CALL_DELAY])}
						helperText={formFieldErrors[FIELD.REP_CALL_DELAY]}
						fullWidth
						type="number"
						onChange={(e) =>
							Number(e.target.value) > 0 || !e.target.value
								? validateAndUpdate(Number(e.target.value), FIELD.REP_CALL_DELAY)
								: null
						}
						onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
						label={<FormattedMessage {...messages.repeatCallDelayInMinutes} />}
						variant="standard"
					/>
				</Grid>
			)}
			<Grid item xs={5}>
				<FormControlLabel
					className="floatLeft mt15 approve-checkbox"
					control={
						<Checkbox
							checked={syncStatus}
							onChange={() => {
								setSyncStatus(!syncStatus);
								setState({
									...state,
									[FIELD.STATUS_NOTIF]: syncStatus ? '' : null,
								});
							}}
						/>
					}
					label={<FormattedMessage {...messages.syncStatus} />}
				/>
			</Grid>
			{syncStatus ? (
				<Grid item xs={7}>
					<TextField
						value={state[FIELD.STATUS_NOTIF] || ''}
						error={Boolean(formFieldErrors[FIELD.STATUS_NOTIF])}
						helperText={formFieldErrors[FIELD.STATUS_NOTIF]}
						fullWidth
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.STATUS_NOTIF)}
						onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
						label={<FormattedMessage {...messages.callStatusNotifier} />}
						variant="standard"
					/>
				</Grid>
			) : null}

			<Grid item xs={12}>
				<FreeSoloAutocomplete
					values={state[FIELD.CALL_STATUSES_TO_REPEAT]}
					setValues={(values) => validateAndUpdate(values, FIELD.CALL_STATUSES_TO_REPEAT)}
					label="setCallStatuses"
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControlLabel
					className="floatLeft mt15 approve-checkbox"
					control={
						<Checkbox
							checked={repeatCallDatesManually}
							onChange={() => setRepeatCallDatesManually(!repeatCallDatesManually)}
						/>
					}
					label={<FormattedMessage {...messages.setRepeatCallDatesManually} />}
				/>
			</Grid>
			{repeatCallDatesManually &&
				state[FIELD.CALL_DATES].map((callDate, index) => (
					<Grid item xs={7} key={'repeatCallDatesManually' + index}>
						<TextField
							variant="standard"
							type="datetime-local"
							value={convertDateToString(new Date(callDate)) || ''}
							onChange={(e, v) =>
								validateAndUpdate(
									update(index, convertStringToDate(e.target.value), state[FIELD.CALL_DATES]),
									FIELD.CALL_DATES
								)
							}
						/>
					</Grid>
				))}
		</>
	);
};

CampaignForm.propTypes = {
	state: PropTypes.object,
	setState: PropTypes.func,
	handleAdd: PropTypes.func,
};

export default CampaignForm;
