import { useKeycloak } from '@react-keycloak/web';
import payload from 'jwt-decode';
import React from 'react';
import { useSelector } from 'react-redux';
import { USE_KEYCLOAK } from '../../core/configs/consts';
import { getToken } from '../../redux/auth/selectors';

const useAuth = () => {
	if (USE_KEYCLOAK) {
		const { keycloak } = useKeycloak();
		const { token, tokenParsed, authenticated } = keycloak;
		if (!token || token === 'null') {
			return { isUserLoggedIn: false };
		}

		return {
			...tokenParsed,
			isUserLoggedIn: authenticated,
			role: tokenParsed.roles.find((value) => ['SuperAdmin', 'Admin', 'EndUser'].includes(value)) || 'User',
			userId: tokenParsed.sub,
			userName: tokenParsed.name,
			userEmail: tokenParsed.email,
		};
	}

	const token = useSelector(getToken);
	if (!token || token === 'null') {
		return { isUserLoggedIn: false };
	}

	const { email, role, id } = payload(token);
	return { userEmail: email, role, isUserLoggedIn: true, userId: id };
};

export default useAuth;
