import * as requests from '../../core/requests';
import { getStatistics } from '../../graphql/statistics';
import { createActionsMap } from '../../utils/action-utils';
import { psf } from '../utils';

export const ID = 'statistics';

export const actionTypes = createActionsMap(ID, [...psf('FETCH_STATISTICS')]);

export const fetchStatistics = () => (dispatch) => {
	if (!process.env.USE_STATISTICS) return;
	return dispatch(
		requests.qlAuthRequest(
			[actionTypes.FETCH_STATISTICS_PENDING, actionTypes.FETCH_STATISTICS_SUCCESS, actionTypes.FETCH_STATISTICS_FAIL],
			{ query: getStatistics }
		)
	);
};
