import * as Sentry from '@sentry/react';
import combineSectionReducers from 'combine-section-reducers';
import LogRocket from 'logrocket';
import { applyMiddleware, compose, createStore } from 'redux';
import { createMiddleware as createApiMiddleware, RSAA } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';
import { USE_KEYCLOAK } from '../core/configs/consts';
import { AUTH_CALL_API } from '../core/requests';
import { apiErrorHandler, authApi } from '../middleware';
import auth from './auth/reducers';
import confusionMatrix from './confusion-matrix/reducers';
import dataManagement from './data-management/reducers';
import emailAnalytics from './email-analytics/reducers';
import fetching from './fetching/reducers';
import flowEditor from './flow-editor/reducers';
import histogram from './histogram/reducers';
import intl from './intl/reducers';
import kibana from './kibana/reducers';
import modal from './modal/reducers';
import modelTable from './model-table/reducers';
import model from './model/reducers';
import uinotification from './notification/reducers';
import outbound from './outbound/reducers';
import sip from './sip/reducers';
import statistics from './statistics/reducers';
import utteranceTester from './utterance-tester/reducers';
import voice from './voice/reducers';

const USE_EXT = window.__REDUX_DEVTOOLS_EXTENSION__;
const composeEnhancers = USE_EXT ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const combinedReducers = combineSectionReducers({
	auth,
	confusionMatrix,
	emailAnalytics,
	fetching,
	flowEditor,
	histogram,
	intl,
	modal,
	model,
	modelTable,
	outbound,
	statistics,
	uinotification,
	utteranceTester,
	voice,
	sip,
	kibana,
	dataManagement,
});

const reducers = (state, action) => {
	return action.type.includes('LOGIN_') || state?.auth?.token || typeof state === 'undefined' || USE_KEYCLOAK
		? combinedReducers(state, action)
		: state;
};

const configureStore = (fetchFn = fetch) => {
	const authorizeAllRequests = () => (next) => (action) => {
		const rsaa = action?.[AUTH_CALL_API];
		if (rsaa) {
			return next({ [RSAA]: rsaa });
		} else {
			return next(action);
		}
	};
	const middlewares = [
		fetchFn === fetch ? authApi : authorizeAllRequests,
		createApiMiddleware({ fetch: fetchFn }),
		apiErrorHandler,
		thunkMiddleware,
	];
	let enhancers = composeEnhancers(applyMiddleware(...middlewares));
	if (process.env.DIAGNOSTIC_LOGGING_ENABLED) {
		enhancers = composeEnhancers(
			applyMiddleware(...middlewares, LogRocket.reduxMiddleware()),
			Sentry.createReduxEnhancer()
		);
	}

	return createStore(reducers, enhancers);
};

export default configureStore;
