import { gql } from 'graphql-tag';

export const PHONE_NUMBER_FIELDS = gql`
	fragment PHONE_NUMBER_FIELDS on PhoneNumber {
		name
		organization
		phoneNumber
		sttProvider
		ttsProvider
		recordCalls
		ttsVoiceName
		ttsRate
		ttsPitch
		userNoInputLimit
		bgMusicFilename
		bgMusicVolume
		isSipShortcode
		whitelist {
			phoneNumber
			allowedCallCount
		}
		blacklist
		config {
			id
			configHash
		}
	}
`;

export const fetchVoiceNamesQL = gql`
	query voiceNames($locale: String!) {
		voiceNames(locale: $locale) {
			label
			locale
			value
			gender
		}
	}
`;

export const fetchPhoneNumbersQL = gql`
	query phoneNumbers {
		phoneNumbers {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;

export const assignPhoneNumbersToConfigQL = gql`
	mutation assignPhoneNumbersToConfigQL($configId: ID!, $phoneNumbers: [PhoneNumberInput]) {
		assignPhoneNumbersToConfig(configId: $configId, phoneNumbers: $phoneNumbers) {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;

export const unassignPhoneNumberFromConfigQL = gql`
	mutation unassignPhoneNumberFromConfig($phoneNumber: PhoneNumberInput!) {
		unassignPhoneNumberFromConfig(phoneNumber: $phoneNumber) {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;

export const assignPhoneNumberToOrganizationQL = gql`
	mutation assignPhoneNumberToOrganizationQL($phoneNumber: PhoneNumberInput) {
		assignPhoneNumberToOrganization(phoneNumber: $phoneNumber) {
			name
			phoneNumber
			organization
		}
	}
`;

export const unassignPhoneNumberFromOrganizationQL = gql`
	mutation unassignPhoneNumberFromOrganization($phoneNumber: String!) {
		unassignPhoneNumberFromOrganization(phoneNumber: $phoneNumber) {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;

export const getAvailablePhoneNumbers = gql`
	query availablePhoneNumbers($modelId: ID!) {
		availablePhoneNumbers(modelId: $modelId) {
			...PHONE_NUMBER_FIELDS
		}
	}
	${PHONE_NUMBER_FIELDS}
`;
