import { Autocomplete } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../constants';
import { INTL, messages } from '../intl';
import { unlockUserAccount } from '../redux/auth/actions';
import { fetchUsers } from '../redux/model-table/actions';
import { getUsers } from '../redux/model-table/selectors';
import { showNotification } from '../redux/notification/actions';

const useStyles = makeStyles((theme) => ({
	// Not used at the moment.
	marginSpacing: {
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	wrapper: {
		padding: 10,
		width: '100%',
	},
}));

const UnlockUserAccount = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const users = useSelector(getUsers);
	const [userName, setUserName] = useState({ name: '', id: '' });

	useEffect(() => {
		dispatch(fetchUsers());
	}, []);

	const handleSubmit = async () => {
		const res = await dispatch(unlockUserAccount(userName.id));
		if (res.unlockUserAccount) {
			dispatch(
				showNotification(
					INTL.formatMessage(messages.userAccountUnlocked, { name: userName.name }),
					notificationTypes.INFO
				)
			);
			setUserName({});
		}
	};

	return (
		<div className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Autocomplete
						options={users}
						getOptionLabel={(option) => option.name}
						value={userName}
						onChange={(e, v) => setUserName(v)}
						renderInput={(params) => (
							<TextField {...params} label={<FormattedMessage {...messages.selectUser} />} variant="outlined" />
						)}
						variant="standard"
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						style={{ float: 'right' }}
						onClick={handleSubmit}
						color={'primary'}
						disabled={!userName}
						className="action-button"
					>
						<FormattedMessage {...messages.unlockUserAccount} />
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default UnlockUserAccount;
