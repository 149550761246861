import { path, prop } from 'ramda';
import { createSelector } from 'reselect';
import { ID } from './actions';

export const getDataManagement = (state) => state[ID][ID];

export const getDataManagementMetadatas = createSelector(getDataManagement, prop('metadatas'));
export const getDataManagementFilteredValue = path([ID, ID, 'filteredValue']);
export const isFetchingDataManagementMetadatas = path([ID, 'fetchingMetadatas', 'isFetching']);
export const initiallyFetchedDataManagementMetadatas = path([ID, 'fetchingMetadatas', 'initiallyFetched']);
export const getDataTable = createSelector(getDataManagement, prop('dataTable'));
