import { TRANSITION_TYPES } from '../core/configs';
import GoodBotConfig from '../core/configs/GoodBot';
import keyMirror from '../utils/keyMirror';

export const notificationTypes = keyMirror({
	INFO: null,
	WARNING: null,
	ERROR: null,
});

export const inOutLevelZoomLabels = keyMirror({
	IN: null,
	OUT: null,
});

export const transitionLevelZoomLabels = Object.assign({}, GoodBotConfig.transitionOrder, {
	[TRANSITION_TYPES.ACTIONS_SIGNALS]: 'SIGNALS',
});

export const configOptions = {
	disabledUtteranceTransformers: [1, 2, 3, 4],
	language: ['en', 'cs', 'sk', 'pl', 'de', 'ru'],
	original_intent_threshold: [0.000001, 0.000005, 0.00001, 0.00005, 0.0001, 0.0005, 0.001, 0.005],
	classifier: {
		aspell: ['replace', 'duplicate', 'whitelist', null],
		lemmatizer: ['replace', 'duplicate', null],
		stopwords: [false, true],
	},
};

export const responseMarkdownItemsDelimiter = ' OR ';
