import { TextareaAutosize, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { sendResponse } from '../../redux/email-analytics/actions';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	emailBody: {
		border: '1px solid ' + theme.palette.grey[300],
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		margin: '1rem 0 0 0',
		padding: '0.5rem',
		fontSize: '0.9rem',
		width: 'calc(100% - 1.2rem)',
	},
}));

const EmailClassifierReplyModal = ({ email }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const subject = `RE: ${email.subject}`;
	const [body, setBody] = useState(email.suggested_reply);

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.reply} />}
			saveBtnTitle={<FormattedMessage {...messages.reply} />}
			onSave={() => {
				dispatch(sendResponse(email.sender, subject, body));
				dispatch(hideModal());
			}}
			saveOnEnterKey
		>
			<TextField value={subject} label={<FormattedMessage {...messages.subject} />} fullWidth variant="standard" />
			<TextareaAutosize value={body} onChange={(e) => setBody(e.currentTarget.value)} className={classes.emailBody} />
		</SaveCloseModalTemplate>
	);
};

EmailClassifierReplyModal.propTypes = {
	email: PropTypes.shape({
		sender: PropTypes.string.isRequired,
		subject: PropTypes.string.isRequired,
		suggested_reply: PropTypes.string.isRequired,
	}).isRequired,
};

export default EmailClassifierReplyModal;
