import { getOrganization } from '../auth/selectors';
import { getActiveModelVersionConfig, getVersions } from '../model-table/selectors';
import { getSipAccounts } from '../sip/selectors';
import { ID } from './actions';

const getVoiceState = (state) => state[ID]; // DON'T use R.path([ID, ID]) here. It does not work.

export const getVoiceNames = (state) => {
	return getVoiceState(state).entities.voiceNames;
};

export const getPhoneNumbers = (state) => {
	const phoneNumbersFromVoiceApp = Object.values(getVoiceState(state).entities.phoneNumbers);

	// Numbers corresponding to sip:<username>@sip-domain
	// Only add those that are already not configured in the voice app
	const sipAccountPhoneNumbers = getSipAccounts(state)
		?.filter(({ username }) => !phoneNumbersFromVoiceApp.some(({ phoneNumber }) => phoneNumber === username))
		.map(({ username, accountUri }) => ({
			phoneNumber: username,
			name: accountUri,
			organization: '',
			isSipShortcode: true,
		}));

	return [...sipAccountPhoneNumbers, ...phoneNumbersFromVoiceApp];
};
export const getAvailablePhoneNumbers = (state) => Object.values(getVoiceState(state).entities.availablePhoneNumbers);

// The server fetches phoneNumbers assigned to any (or no) organization for users with SuperAdmin privileges
// For legacy (or other reasons), some phoneNumbers do not have an organization assigned, display those too
export const getPhoneNumbersAssignedToOrganization = (state) => {
	const { id } = getOrganization(state);
	return getPhoneNumbers(state).filter((n) => !n.organization || n.organization === id);
};

export const getPhoneNumbersAssignedToProject = (state) => {
	const { id } = getActiveModelVersionConfig(state);
	return getPhoneNumbersAssignedToOrganization(state).filter((n) => n.config === id);
};

// The difference from getPhoneNumbersAssignedToVersion is different server-side filtering
export const getAvailablePhoneNumbersAssignedToVersion = (state) => {
	const { id } = getActiveModelVersionConfig(state);
	return getAvailablePhoneNumbers(state).filter((n) => n.config === id);
};

// Currently unused anywhere. Leaving it here for possible future use
export const getAvailablePhoneNumbersAssignedToProject = (state) => {
	const versions = getVersions(state);
	const result = {};
	if (versions) {
		for (const [version, { configs: config }] of Object.entries(versions)) {
			result[version] = getAvailablePhoneNumbers(state).filter((n) => n.config === config[0]);
		}
		return result;
	}
	return {};
};
