import Autocomplete from '@mui/lab/Autocomplete';
import { Box, Button, Popper } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { and, includes, isEmpty, not, pathOr, prepend } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal, showAddIntentFromLibModal, showCreateEditTransitionModal } from '../../redux/modal/actions';
import {
	addIntent,
	clearSelectedNodes,
	createNode,
	createUpdateTransitionAction,
	registerStateHistory,
} from '../../redux/model/actions';
import { getAvailableTransitionNodes, getIntents, getNode } from '../../redux/model/selectors';
import { normalizeNodeId } from '../../utils/normalization';
import { validateNodeName } from '../../utils/validation';
import NodeIdTextField from '../NodeIdTextField/NodeIdTextField';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles(() => ({
	grid: { overflow: 'hidden' },
	autocompleteFix: { padding: 10 },
	warningText: { ccolor: 'orange' },
	errorText: { color: 'red' },
	popper: {
		zIndex: 10000,
		'& .MuiAutocomplete-paper': {
			paddingTop: '3.3rem',
			'& .MuiAutocomplete-listbox': {
				'& .button-bounding': {
					position: 'fixed',
					top: '0px',
					width: '100%',
					margin: '-16px 0 0 0',
					'& div': {
						background: 'white',
						margin: '0 20px 0 0',
						padding: '7px 0 10px 20px',
						fontSize: '13px',
						fontWeight: 'bold',
						textAlign: 'center',
					},
				},
			},
		},
	},
}));

const isNewNamePredicate = (name, names) => and(not(isEmpty(name)), not(includes(name, names)));
const isGenericIntent = (intentName) => intentName.startsWith('.');

const CreateEditTransitionModal = ({ intentName: initIntentName, nodeId, targetNodeName, isCallback }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const intents = useSelector(getIntents);

	const nodeNames = useSelector(getAvailableTransitionNodes(nodeId));
	const node = useSelector(getNode(nodeId));
	const intentsNames = Object.keys(intents);
	const intl = useIntl();

	const [targetNodeConf, setTargetNodeConf] = useState({
		validation: null,
		caretPos: null,
		nodeId: targetNodeName || '',
	});

	const isNewNode = targetNodeConf.nodeId && !includes(targetNodeConf.nodeId, nodeNames);
	const isCreate = isEmpty(targetNodeName);

	const [intentName, setIntentName] = useState(initIntentName || '');
	const [intentError, setIntentError] = useState(targetNodeName || null);
	const [intentNameWarning, setIntentWarning] = useState(targetNodeName || null);
	const [intentNameFieldTouched, setIntentFieldTouched] = useState(false);
	const [intentCaretPosition, setIntentCaretPosition] = useState();

	const intentInputRef = useRef();

	const intentCurrentlyCreated = isCallback && intentName === initIntentName;

	const handleIntentNameChange = (value) => {
		if (value === intl.formatMessage(messages.addFromIntentLib)) {
			return;
		}
		setIntentCaretPosition(pathOr(null, ['selectionEnd'], intentInputRef.current));
		// Generic intent, do not convert to uppercase
		setIntentName(isGenericIntent(value) ? value : normalizeNodeId(value));
	};

	const validateIntentName = () => {
		if (!isGenericIntent(intentName)) {
			setIntentError(validateNodeName(intentName));

			if (isNewNamePredicate(intentName, intentsNames)) {
				setIntentWarning(intl.formatMessage(messages.createEditTransitionModalWarnIntentCreated, { intentName }));
			} else {
				setIntentWarning(null);
			}
		} else {
			setIntentError(null);
			setIntentWarning(null);
		}
	};

	useEffect(() => {
		validateIntentName();
	}, []);

	useEffect(() => {
		validateIntentName();
	}, [intentName]);

	const handleSubmit = () => {
		if (cannotSubmit) {
			return;
		}

		dispatch(registerStateHistory());

		let prefixedNodeName = '';
		if (isNewNamePredicate(targetNodeConf.nodeId, nodeNames)) {
			dispatch(clearSelectedNodes());
			dispatch(createNode({ newNodeId: targetNodeConf.nodeId, shouldShowActiveNode: true, baseNodeId: nodeId }));
			// The nodeId gets prefixed by the respective group name during creation if groupView is on
			if (node.group) {
				prefixedNodeName = `${node.group}_${targetNodeConf.nodeId}`;
			}
		}
		if (!isGenericIntent(intentName) && isNewNamePredicate(intentName, intentsNames)) {
			dispatch(addIntent(intentName));
		}
		dispatch(createUpdateTransitionAction(nodeId, prefixedNodeName || targetNodeConf.nodeId, intentName));
		dispatch(hideModal());
	};

	const cannotSubmit = !!(!targetNodeConf.nodeId || targetNodeConf.validation || intentError);

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSubmit();
		}
	};

	const handleAddLibIntent = () => {
		dispatch(hideModal());
		dispatch(showAddIntentFromLibModal({ callback: showCreateEditTransitionModal, nodeId }));
	};

	return (
		<SaveCloseModalTemplate
			title={
				isCreate ? (
					<FormattedMessage {...messages.createEditTransitionModalCreateTitle} />
				) : (
					<FormattedMessage {...messages.createEditTransitionModalEditTitle} />
				)
			}
			saveBtnTitle={<FormattedMessage {...messages.save} />}
			onSave={handleSubmit}
			isSaveBtnDisabled={cannotSubmit}
		>
			<Box className={classes.autocompleteFix}>
				<Grid container spacing={2} className={classes.grid}>
					{/* INTENT */}
					<Grid item xs={12}>
						<Autocomplete
							key="intentNameInput"
							freeSolo
							disableClearable
							getOptionLabel={String}
							options={prepend(intl.formatMessage(messages.addFromIntentLib), intentsNames)}
							inputValue={intentName}
							onChange={(event, value) => handleIntentNameChange(value)}
							onKeyPress={handleKeyPress}
							PopperComponent={(props) => <Popper {...props} className={classes.popper} placement="bottom-start" />}
							renderOption={(props, option, state) =>
								option === intl.formatMessage(messages.addFromIntentLib) ? (
									<div className="button-bounding" key={option}>
										<Button fullWidth variant="contained" color="primary" onClick={() => handleAddLibIntent()}>
											{option}
										</Button>
										<div>
											<FormattedMessage {...messages.orChooseFromIntents} />:
										</div>
									</div>
								) : (
									<div {...props}>{option}</div>
								)
							}
							renderInput={(params) => {
								const updatedInputParams = { ...params };

								if (updatedInputParams.inputProps?.ref?.current) {
									updatedInputParams.inputProps.ref.current.selectionStart = intentCaretPosition;
									updatedInputParams.inputProps.ref.current.selectionEnd = intentCaretPosition;
								}

								return (
									<TextField
										{...updatedInputParams}
										required
										error={!!(intentNameFieldTouched && intentError)}
										onFocus={() => (!intentNameFieldTouched ? setIntentFieldTouched(true) : null)}
										onChange={(event) => handleIntentNameChange(event.target.value)}
										label={<FormattedMessage {...messages.createEditTransitionModalTargetNodeLabel} />}
										helperText={
											intentCurrentlyCreated ? <FormattedMessage {...messages.intentCreatedChooseTarget} /> : null
										}
										fullWidth
										variant={'outlined'}
										inputRef={intentInputRef}
									/>
								);
							}}
						/>
						<FormHelperText
							error={!!(intentError && intentNameFieldTouched)}
							className={intentError ? classes.errorText : classes.warningText}
						>
							{intentNameFieldTouched && intentError}
							{!intentError && intentNameWarning}
						</FormHelperText>
					</Grid>

					{/* TARGET */}
					<Grid item xs={12}>
						<Autocomplete
							key="targetNodeNameInput"
							blurOnSelect
							freeSolo
							getOptionLabel={String}
							options={nodeNames}
							inputValue={targetNodeConf.nodeId}
							// In case a nodeId from the list is selected and the clear icon is clicked
							onChange={(e, nodeId) =>
								setTargetNodeConf({
									caretPos: null,
									validation: null,
									nodeId: nodeId || '',
								})
							}
							// In case a custom nodeId value not on the list is inputted and the clear icon is clicked
							onInputChange={(e, v, reason) => {
								if (reason === 'clear') {
									setTargetNodeConf({
										caretPos: null,
										validation: null,
										nodeId: '',
									});
								}
							}}
							onKeyPress={handleKeyPress}
							renderInput={(params) => (
								<NodeIdTextField
									nodeId={targetNodeConf.nodeId}
									label={<FormattedMessage {...messages.createEditTransitionModalTargetNodeLabel} />}
									handleChange={setTargetNodeConf}
									helperText={
										isNewNode && targetNodeConf.nodeId !== nodeId ? (
											<FormattedMessage {...messages.newNodeInfo} values={{ id: targetNodeConf.nodeId }} />
										) : null
									}
									allowDuplicate
									{...params}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Box>
		</SaveCloseModalTemplate>
	);
};

CreateEditTransitionModal.propTypes = {
	nodeId: PropTypes.string,
	targetNodeName: PropTypes.string,
	intentName: PropTypes.string,
	isCallback: PropTypes.bool,
};

export default CreateEditTransitionModal;
