import PropTypes from 'prop-types';
import { fromPairs, isNil } from 'ramda';

export const normalizeNodeId = (str) => {
	if (isNil(str)) {
		return '';
	}
	return str
		.replace(/[\s-]+/gu, '_')
		.toUpperCase()
		.normalize('NFD')
		.replace(/[^A-Z0-9_]+/gu, '')
		.replace(/_{2,}/g, '_');
};

export const normalizePhoneNumber = (str) => {
	if (isNil(str)) {
		return '';
	}
	// Purge everything other than digits and the '+' sign
	// Don't allow for more digits (with or without '+' at the beginning)
	return String(str)
		.replace(/[^0-9\+]/g, '')
		.slice(0, str[0] === '+' ? 13 : 12);
};

export const stringToBoolean = (value = 'false') => {
	switch (value.toLowerCase().trim()) {
		case 'true':
		case 'yes':
		case '1':
			return true;

		case 'false':
		case 'no':
		case '0':
		case null:
			return false;

		default:
			return Boolean(value);
	}
};

normalizeNodeId.propTypes = { str: PropTypes.string };

export const objFromKeys = (keys, value) => fromPairs(keys.map((n) => [n, value]));
