import React, { useEffect, useRef } from 'react';

const useInterval = (callback, delay, keepGoing = true) => {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const tick = () => {
			if (keepGoing) {
				savedCallback.current();
			}
		};

		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay, keepGoing]);
};

export default useInterval;
