import * as Sentry from '@sentry/react';
import { defaultTo, prop } from 'ramda';
import keycloak from '../auth/keycloak';
import { USE_KEYCLOAK } from '../core/configs/consts';
import { AUTH_CALL_API } from '../core/requests';
import { INTL, messages } from '../intl';
import { refreshToken } from '../redux/auth/actions';
import { showNotification } from '../redux/notification/actions';

const IGNORED_ACTIONS = ['modelTable/TRAIN_CONFIG_SUCCESS'];
/**
 * There may be many concurrent actions waiting for the token to refresh.
 * We want to invoke only one refresh for all of them.
 */
let refreshingTokenPromise = null;

/**
 * Transforms action with field `payload.errors` with HTTP status 200 from GraphQL API into FAILED actions.
 * It adds also `error` field as it is described in https://github.com/agraboso/redux-api-middleware#error
 * See https://trello.com/c/vz8QyAno/352-graphql-return-proper-http-status-on-error
 */
const convertToErrorAction = (action) => ({ ...action, error: true, type: action.type.replace('_SUCCESS', '_FAIL') });

const fetchNewToken = async () => {
	const response = await fetch(`${process.env.GRAPHQL_URL}/refreshToken`, {
		method: 'POST',
		credentials: 'include', // IMPORTANT: accepts cookie with the refresh token
	});
	return await response.json();
};

const repeatWithNewToken = async (store, next, action, errors, rsaa) => {
	let json;
	if (refreshingTokenPromise) {
		json = await refreshingTokenPromise;
	} else if (USE_KEYCLOAK) {
		json = { token: keycloak.token, success: true };
	} else {
		refreshingTokenPromise = fetchNewToken();
		json = await refreshingTokenPromise;
		refreshingTokenPromise = null;
	}

	if (json.success) {
		store.dispatch(refreshToken(json.token));
		return store.dispatch({ [AUTH_CALL_API]: rsaa });
	} else {
		store.dispatch(refreshToken(null)); // it is important to clear invalid token
		store.dispatch(showNotification(errors.map(({ message }) => message).join('\n')));
		return next(convertToErrorAction(action));
	}
};

/**
 * Middleware catches action errors and dispatches notification action.
 */
export default (store) => (next) => (action) => {
	const { type, error, payload, meta } = action;
	const originalRSAA = meta?.originalRSAA;
	const { errors } = defaultTo({}, payload);

	if (error) {
		Sentry.captureException(typeof error === 'boolean' ? payload : error, { extra: { type, payload, errors } });
		const details = `Action "${type}". Payload: ${JSON.stringify(payload)}`;
		const explanation = messages[payload.name === 'RequestError' ? 'connectionError' : 'requestError'];
		store.dispatch(showNotification(INTL.formatMessage(explanation, { details })));
		return next(convertToErrorAction(action));
	} else if (!errors) {
		return next(action);
	} else if (!originalRSAA || !errors.some((e) => e.extensions.code === 'UNAUTHENTICATED')) {
		if (!IGNORED_ACTIONS.includes(type)) {
			store.dispatch(showNotification(errors.map(prop('message')).join('\n')));
		}
		return next(convertToErrorAction(action));
	} else {
		return repeatWithNewToken(store, next, action, errors, originalRSAA);
	}
};
