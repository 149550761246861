import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { deleteDataTableColumn, fetchDataTableColumns } from '../../redux/data-management/actions';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const DeleteColumnModal = ({ tableName, columnName }) => {
	const dispatch = useDispatch();
	const handleDelete = async () => {
		await dispatch(deleteDataTableColumn(tableName, columnName));
		dispatch(fetchDataTableColumns(tableName));
		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.deleteColumn} />}
				saveBtnTitle={<FormattedMessage {...messages.deleteColumnButton} />}
				onSave={handleDelete}
			>
				<span>{<FormattedMessage {...messages.deleteColumnContent} />}</span>
			</SaveCloseModalTemplate>
		</div>
	);
};

DeleteColumnModal.propTypes = {
	tableName: PropTypes.string.isRequired,
	columnName: PropTypes.string.isRequired,
};

export default DeleteColumnModal;
