import PropTypes from 'prop-types';
import React from 'react';
import AceEditor from './MaterialDashboard/Editor/ReactAceEditor';

export const PythonEditor = ({ value, placeholder, onChange }) => (
	<AceEditor
		mode="python"
		theme="github"
		value={value}
		placeholder={placeholder}
		onChange={onChange}
		fontSize={14}
		editorProps={{ $blockScrolling: true }}
		width="100%"
		height="200px"
		setOptions={{
			showGutter: false,
			showPrintMargin: false,
			showLineNumbers: false,
			tabSize: 2,
			minLines: 10,
			maxLines: 20,
			enableLiveAutocompletion: true,
			vScrollBarAlwaysVisible: false,
			hScrollBarAlwaysVisible: false,
		}}
	/>
);

PythonEditor.propTypes = {
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
