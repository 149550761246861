import { createIntl, createIntlCache } from 'react-intl';
import { LOCALE } from '../core/configs/consts';
import messages from './messages';
import translation from './translation';

export { messages };
export { translation };

export const INTL = createIntl(
	{
		messages,
		locale: LOCALE.EN,
	},
	createIntlCache()
);

/**
 * Translates MUI DataGrid components
 * example: <DataGrid localeText={translateDataGrid()} />
 */
export const translateDataGrid = (intl) => ({
	checkboxSelectionHeaderName: intl.formatMessage(messages.checkboxSelectionHeaderName),
	columnHeaderFiltersLabel: intl.formatMessage(messages.columnHeaderFiltersLabel),
	columnHeaderFiltersTooltipActive: (count) =>
		count !== 1
			? `${count} ${intl.formatMessage(messages.activeFilters)}`
			: `${count} ${intl.formatMessage(messages.activeFilter)}`,
	columnHeaderSortIconLabel: intl.formatMessage(messages.columnHeaderSortIconLabel),
	columnMenuFilter: intl.formatMessage(messages.columnMenuFilter),
	columnMenuHideColumn: intl.formatMessage(messages.columnMenuHideColumn),
	columnMenuLabel: intl.formatMessage(messages.columnMenuLabel),
	columnMenuShowColumns: intl.formatMessage(messages.columnMenuShowColumns),
	columnMenuSortAsc: intl.formatMessage(messages.columnMenuSortAsc),
	columnMenuSortDesc: intl.formatMessage(messages.columnMenuSortDesc),
	columnMenuUnsort: intl.formatMessage(messages.columnMenuUnsort),
	columnsPanelDragIconLabel: intl.formatMessage(messages.columnsPanelDragIconLabel),
	columnsPanelHideAllButton: intl.formatMessage(messages.columnsPanelHideAllButton),
	columnsPanelShowAllButton: intl.formatMessage(messages.columnsPanelShowAllButton),
	columnsPanelTextFieldLabel: intl.formatMessage(messages.columnsPanelTextFieldLabel),
	columnsPanelTextFieldPlaceholder: intl.formatMessage(messages.columnsPanelTextFieldPlaceholder),
	errorOverlayDefaultLabel: intl.formatMessage(messages.errorOverlayDefaultLabel),
	filterOperatorAfter: intl.formatMessage(messages.filterOperatorAfter),
	filterOperatorBefore: intl.formatMessage(messages.filterOperatorBefore),
	filterOperatorContains: intl.formatMessage(messages.filterOperatorContains),
	filterOperatorEndsWith: intl.formatMessage(messages.filterOperatorEndsWith),
	filterOperatorEquals: intl.formatMessage(messages.filterOperatorEquals),
	filterOperatorIs: intl.formatMessage(messages.filterOperatorIs),
	filterOperatorNot: intl.formatMessage(messages.filterOperatorNot),
	filterOperatorOnOrAfter: intl.formatMessage(messages.filterOperatorOnOrAfter),
	filterOperatorOnOrBefore: intl.formatMessage(messages.filterOperatorOnOrBefore),
	filterOperatorStartsWith: intl.formatMessage(messages.filterOperatorStartsWith),
	filterPanelAddFilter: intl.formatMessage(messages.filterPanelAddFilter),
	filterPanelColumns: intl.formatMessage(messages.filterPanelColumns),
	filterPanelDeleteIconLabel: intl.formatMessage(messages.filterPanelDeleteIconLabel),
	filterPanelInputLabel: intl.formatMessage(messages.filterPanelInputLabel),
	filterPanelInputPlaceholder: intl.formatMessage(messages.filterPanelInputPlaceholder),
	filterPanelOperatorAnd: intl.formatMessage(messages.filterPanelOperatorAnd),
	filterPanelOperatorOr: intl.formatMessage(messages.filterPanelOperatorOr),
	filterPanelOperators: intl.formatMessage(messages.filterPanelOperators),
	footerRowSelected: (count) =>
		count !== 1
			? `${count.toLocaleString()} ${intl.formatMessage(messages.rowsSelected)}`
			: `${count.toLocaleString()} ${intl.formatMessage(messages.rowSelected)}`,
	footerTotalRows: intl.formatMessage(messages.footerTotalRows),
	noRowsLabel: intl.formatMessage(messages.noRowsLabel),
	rootGridLabel: intl.formatMessage(messages.rootGridLabel),
	toolbarColumns: intl.formatMessage(messages.toolbarColumns),
	toolbarColumnsLabel: intl.formatMessage(messages.toolbarColumnsLabel),
	toolbarDensity: intl.formatMessage(messages.toolbarDensity),
	toolbarDensityComfortable: intl.formatMessage(messages.toolbarDensityComfortable),
	toolbarDensityCompact: intl.formatMessage(messages.toolbarDensityCompact),
	toolbarDensityLabel: intl.formatMessage(messages.toolbarDensityLabel),
	toolbarDensityStandard: intl.formatMessage(messages.toolbarDensityStandard),
	toolbarExportCSV: intl.formatMessage(messages.toolbarExportCSV),
	toolbarExportLabel: intl.formatMessage(messages.toolbarExportLabel),
	toolbarFilters: intl.formatMessage(messages.toolbarFilters),
	toolbarFiltersLabel: intl.formatMessage(messages.toolbarFiltersLabel),
	toolbarFiltersTooltipActive: (count) =>
		count !== 1
			? `${count} ${intl.formatMessage(messages.activeFilters)}`
			: `${count} ${intl.formatMessage(messages.activeFilter)}`,
	toolbarFiltersTooltipHide: intl.formatMessage(messages.toolbarFiltersTooltipHide),
	toolbarFiltersTooltipShow: intl.formatMessage(messages.toolbarFiltersTooltipShow),
});

/**
 * Console log all EN translations into one array
 * to simply copy/paste into google translate
 * @param Object of en translations
 */
export const extractEnTranslations = (enMssages) => {
	// eslint-disable-next-line no-console
	console.log(
		'Translation array:',
		Object.values(enMssages).map(({ defaultMessage }) => defaultMessage)
	);
};
// extractEnTranslations(messages);

/**
 * Console log maped translation object
 * @param Array of translated strings
 */
export const composeTranslation = (translatedArray) => {
	const result = {};
	const enSource = Object.values(messages);
	for (let i = 0; i < enSource.length; i++) {
		result['"' + enSource[i].id + '"'] = translatedArray[i];
	}
	// eslint-disable-next-line no-console
	console.log('result', result);
};
// composeTranslation([]);
