import { gql } from 'graphql-tag';

export const buildConfusionMatrixQuery = gql`
	mutation buildConfusionMatrix($rawYaml: String!, $tryCacheOnly: Boolean! = false, $ignoreGenerics: Boolean! = false) {
		buildConfusionMatrix(rawYaml: $rawYaml, tryCacheOnly: $tryCacheOnly, ignoreGenerics: $ignoreGenerics) {
			intents
			stats {
				errors
				accuracy
				group_accuracy
			}
			rows
		}
	}
`;

export const abortConfusionMatrixQuery = gql`
	mutation abortConfusionMatrix($rawYaml: String!, $tryCacheOnly: Boolean! = false, $ignoreGenerics: Boolean! = false) {
		abortConfusionMatrix(rawYaml: $rawYaml, tryCacheOnly: $tryCacheOnly, ignoreGenerics: $ignoreGenerics) {
			intents
			stats {
				errors
				accuracy
				group_accuracy
			}
			rows
		}
	}
`;
