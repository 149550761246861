import { produce } from 'immer';
import { combineReducers } from 'redux';
import { actionTypes as authActionTypes } from '../auth/actions';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = { histogramMessageCount: 0, histogram: [] };

const histogram = produce((state, action) => {
	const { type, payload } = action;
	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.FETCH_HISTOGRAM_SUCCESS:
			const stats = payload.data.statistics;
			return stats.histogram ? stats : DEFAULT_STATE;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: histogram,
	fetchingHistogram: fetching([
		actionTypes.FETCH_HISTOGRAM_PENDING,
		actionTypes.FETCH_HISTOGRAM_SUCCESS,
		actionTypes.FETCH_HISTOGRAM_FAIL,
	]),
});
