/**
 * I moved the editor with its plugins to be together as an logical piece and
 * also because my editor ignores the comment "eslint-disable import/order" and
 * reorders the imports wrongly when I import anything. That's really annoying.
 * I hope this file will be edited rarely so it will not be a problem.
 * Ou, and during review this will be easier to spot if anything changes.
 */
import AceEditor from 'react-ace';

// these need to be after the import of 'react-ace'
/* eslint-disable import/order */
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-github';
/* eslint-enable import/order */

export default AceEditor;
