import { FormControl, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LOCALE } from '../../core/configs/consts';
import useAuth from '../../hooks/auth';
import messages from '../../intl/messages';
import { fetchOrganization, logout, USER_ROLES } from '../../redux/auth/actions';
import { getOrganizationName } from '../../redux/auth/selectors';
import { setLocale } from '../../redux/intl/actions';
import { getLocale } from '../../redux/intl/reducers';
import { showUserSettingsModal, showAppsStatusModal } from '../../redux/modal/actions';
import {
	getModel,
	getModelVersion,
	selectedModelId as selectedModelIdSelector,
	selectedModelVersionId as selectedModelVersionIdSelector,
} from '../../redux/model-table/selectors';
import { logoMutedSvg } from '../Icons';
import {
	AdminIcon,
	EndUserIcon,
	LogoutIcon,
	SettingsIcon,
	StatusIcon,
	SuperadminIcon,
	UserIcon,
} from '../IconsWithTooltip';

const styles = (theme) => ({
	root: {
		width: '100%',
		padding: 0,
		boxSizing: 'border-box',
		zIndex: '9',
		bottom: 0,
		'& .row': {
			display: 'flex',
			marginBottom: 5,
			'&.footer': {
				display: 'block',
				borderBottom: '1px solid ' + theme.palette.secondary.darker,
				paddingBottom: '3.3rem',
				marginBottom: '0.9rem',
				'& .iconH': { height: 25 },
				'& .logo': {
					float: 'left',
				},
				'& .constrols': {
					float: 'right',
					padding: '0 0 0 10px',
					height: '1.6rem',
					width: 'auto',
					marginLeft: '0.3rem',
					cursor: 'pointer',
				},
				'& .user-icon': {
					fill: 'white',
				},
			},
		},
		'& .children': {
			position: 'relative',
			padding: '20px 16px',
			fontSize: '0.75rem',
			boxShadow: '-30px -15px 30px 10px rgba(0,0,0,0.25)',
		},
		'& .title': {
			display: 'flex',
			color: theme.palette.secondary.light,
			marginRight: 5,
			flex: '1 0 27%',
			maxWidth: '27%',
			lineHeight: '20px',
			alignItems: 'center',
		},
		'& .detail': {
			flex: '1 0 70%',
			maxWidth: '70%',
			lineHeight: '20px',
			fontWeight: 700,
			color: theme.palette.secondary.light,
			wordBreak: 'break-all',
		},
		'& .notSelected': {
			color: 'white',
		},
		'& .langSelector': {
			margin: 0,
			'& .MuiInput-root': {
				fontSize: '0.85rem',
				width: '4.5rem',
				'& .MuiSelect-select': {
					padding: 0,
				},
			},
		},
	},
});

const useStyles = makeStyles(styles);

const SidebarModelBox = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const selectedModelId = useSelector(selectedModelIdSelector);
	const selectedModel = useSelector(getModel(selectedModelId));
	const selectedModelVersionId = useSelector(selectedModelVersionIdSelector);
	const selectedModelVersion = useSelector(getModelVersion(selectedModelVersionId));
	const { isUserLoggedIn, userEmail, userId, role } = useAuth();
	const organizationName = useSelector(getOrganizationName);
	const locale = useSelector(getLocale);

	useEffect(() => {
		if (!organizationName && isUserLoggedIn) {
			dispatch(fetchOrganization(userId));
		}
	});

	const renderUserRoleIcon = () => {
		switch (role) {
			case USER_ROLES.SUPER_ADMIN:
				return <SuperadminIcon />;
			case USER_ROLES.ADMIN:
				return <AdminIcon />;
			case USER_ROLES.USER:
				return <UserIcon />;
			case USER_ROLES.END_USER:
				return <EndUserIcon />;
			default:
				return '';
		}
	};

	return (
		isUserLoggedIn && (
			<div className={classes.root}>
				<div className="children">
					{/* logo area */}
					<div className="row footer">
						<span className="logo">{logoMutedSvg}</span>
						{/* logout */}
						<LogoutIcon
							className="constrols"
							onClick={() => dispatch(logout())}
							title={<FormattedMessage {...messages.logout} />}
						/>
						{/* settings */}
						<SettingsIcon
							className="constrols"
							onClick={() => dispatch(showUserSettingsModal())}
							title={<FormattedMessage {...messages.settings} />}
						/>
					</div>
					<div className="row">
						<span className="title">
							<FormattedMessage {...messages.statusSectionDescription} />
						</span>
						<StatusIcon
							className="constrols"
							onClick={() => dispatch(showAppsStatusModal())}
							title={<FormattedMessage {...messages.statusSectionTitle} />}
						/>
					</div>
					{/* project area */}
					<div className="row">
						<span className="title">
							<FormattedMessage {...messages.project} />:
						</span>
						<span className="detail">
							{selectedModel?.name ?? (
								<span className="notSelected">
									<FormattedMessage {...messages.notSelected} />
								</span>
							)}
						</span>
					</div>
					{/* version */}
					<div className="row">
						<span className="title">
							<FormattedMessage {...messages.versionUsed} />:
						</span>
						<span className="detail">
							{selectedModelVersion?.version ?? (
								<span className="notSelected">
									<FormattedMessage {...messages.notSelected} />
								</span>
							)}
						</span>
					</div>
					{/* user email */}
					<div className="row info">
						<span className="title">
							{renderUserRoleIcon()}
							<FormattedMessage {...messages.user} />:
						</span>
						<span className="detail">
							{userEmail ?? (
								<span className="notSelected">
									<FormattedMessage {...messages.notSelected} />
								</span>
							)}
						</span>
					</div>
					{/* lang selector */}
					<div className="row info">
						<span className="title">
							<FormattedMessage {...messages.language} />:
						</span>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="langSelector">
							<Select value={locale} label="Age" onChange={(e) => dispatch(setLocale(e.target.value))}>
								{Object.values(LOCALE).map((lang, i) => (
									<MenuItem key={lang + i} value={lang}>
										{lang}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			</div>
		)
	);
};

export default SidebarModelBox;
