import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
	// DataGrid
	activeFilter: { id: 'global.activeFilter', defaultMessage: `active filter` },
	activeFilters: { id: 'global.activeFilters', defaultMessage: `active filters` },
	checkboxSelectionHeaderName: { id: 'global.checkboxSelectionHeaderName', defaultMessage: `Checkbox selection` },
	columnHeaderFiltersLabel: { id: 'global.columnHeaderFiltersLabel', defaultMessage: `Show filters` },
	columnHeaderSortIconLabel: { id: 'global.columnHeaderSortIconLabel', defaultMessage: `Sort` },
	columnMenuFilter: { id: 'global.columnMenuFilter', defaultMessage: `Filter` },
	columnMenuHideColumn: { id: 'global.columnMenuHideColumn', defaultMessage: `Hide` },
	columnMenuLabel: { id: 'global.columnMenuLabel', defaultMessage: `Menu` },
	columnMenuShowColumns: { id: 'global.columnMenuShowColumns', defaultMessage: `Show columns` },
	columnMenuSortAsc: { id: 'global.columnMenuSortAsc', defaultMessage: `Sort by ASC` },
	columnMenuSortDesc: { id: 'global.columnMenuSortDesc', defaultMessage: `Sort by DESC` },
	columnMenuUnsort: { id: 'global.columnMenuUnsort', defaultMessage: `Unsort` },
	columnsPanelDragIconLabel: { id: 'global.columnsPanelDragIconLabel', defaultMessage: `Reorder column` },
	columnsPanelHideAllButton: { id: 'global.columnsPanelHideAllButton', defaultMessage: `Hide all` },
	columnsPanelShowAllButton: { id: 'global.columnsPanelShowAllButton', defaultMessage: `Show all` },
	columnsPanelTextFieldLabel: { id: 'global.columnsPanelTextFieldLabel', defaultMessage: `Find column` },
	columnsPanelTextFieldPlaceholder: { id: 'global.columnsPanelTextFieldPlaceholder', defaultMessage: `Column title` },
	errorOverlayDefaultLabel: { id: 'global.errorOverlayDefaultLabel', defaultMessage: `An error occurred.` },
	filterOperatorAfter: { id: 'global.filterOperatorAfter', defaultMessage: `is after` },
	filterOperatorBefore: { id: 'global.filterOperatorBefore', defaultMessage: `is before` },
	filterOperatorContains: { id: 'global.filterOperatorContains', defaultMessage: `contains` },
	filterOperatorEndsWith: { id: 'global.filterOperatorEndsWith', defaultMessage: `ends with` },
	filterOperatorEquals: { id: 'global.filterOperatorEquals', defaultMessage: `equals` },
	filterOperatorIs: { id: 'global.filterOperatorIs', defaultMessage: `is` },
	filterOperatorIsEmpty: { id: 'global.filterOperatorIsEmpty', defaultMessage: `is empty` },
	filterOperatorNot: { id: 'global.filterOperatorNot', defaultMessage: `in not` },
	filterOperatorOnOrAfter: { id: 'global.filterOperatorOnOrAfter', defaultMessage: `is on or after` },
	filterOperatorOnOrBefore: { id: 'global.filterOperatorOnOrBefore', defaultMessage: `is on or before` },
	filterOperatorStartsWith: { id: 'global.filterOperatorStartsWith', defaultMessage: `starts with` },
	filterPanelAddFilter: { id: 'global.filterPanelAddFilter', defaultMessage: `Add filter` },
	filterPanelColumns: { id: 'global.filterPanelColumns', defaultMessage: `Columns` },
	filterPanelDeleteIconLabel: { id: 'global.filterPanelDeleteIconLabel', defaultMessage: `Delete` },
	filterPanelInputLabel: { id: 'global.filterPanelInputLabel', defaultMessage: `Value` },
	filterPanelInputPlaceholder: { id: 'global.filterPanelInputPlaceholder', defaultMessage: `Filter value` },
	filterPanelOperatorAnd: { id: 'global.filterPanelOperatorAnd', defaultMessage: `And` },
	filterPanelOperatorOr: { id: 'global.filterPanelOperatorOr', defaultMessage: `Or` },
	filterPanelOperators: { id: 'global.filterPanelOperators', defaultMessage: `Operators` },
	footerTotalRows: { id: 'global.footerTotalRows', defaultMessage: `Total Rows:` },
	noRowsLabel: { id: 'global.noRowsLabel', defaultMessage: `No rows` },
	rootGridLabel: { id: 'global.rootGridLabel', defaultMessage: `grid` },
	rowSelected: { id: 'global.rowSelected', defaultMessage: `row selected` },
	rowsSelected: { id: 'global.rowsSelected', defaultMessage: `rows selected` },
	toolbarColumns: { id: 'global.toolbarColumns', defaultMessage: `Columns` },
	toolbarColumnsLabel: { id: 'global.toolbarColumnsLabel', defaultMessage: `Select columns` },
	toolbarDensity: { id: 'global.toolbarDensity', defaultMessage: `Density` },
	toolbarDensityComfortable: { id: 'global.toolbarDensityComfortable', defaultMessage: `Comfortable` },
	toolbarDensityCompact: { id: 'global.toolbarDensityCompact', defaultMessage: `Compact` },
	toolbarDensityLabel: { id: 'global.toolbarDensityLabel', defaultMessage: `Density` },
	toolbarDensityStandard: { id: 'global.toolbarDensityStandard', defaultMessage: `Standard` },
	toolbarExport: { id: 'global.toolbarExport', defaultMessage: `Export` },
	toolbarExportCSV: { id: 'global.toolbarExportCSV', defaultMessage: `Download as CSV` },
	toolbarExportLabel: { id: 'global.toolbarExportLabel', defaultMessage: `Export` },
	toolbarFilters: { id: 'global.toolbarFilters', defaultMessage: `Filters` },
	toolbarFiltersLabel: { id: 'global.toolbarFiltersLabel', defaultMessage: `Show filters` },
	toolbarFiltersTooltipHide: { id: 'global.toolbarFiltersTooltipHide', defaultMessage: `Hide filters` },
	toolbarFiltersTooltipShow: { id: 'global.toolbarFiltersTooltipShow', defaultMessage: `Show filters` },
	// Rest
	AddGroupNode: { id: 'global.AddGroupNode', defaultMessage: 'Add existing node into this group' },
	Bubble: { id: 'global.Bubble', defaultMessage: 'Chatbot Bubble' },
	Builder: { id: 'global.Builder', defaultMessage: 'Builder' },
	BuilderServer: { id: 'global.BuilderServer', defaultMessage: 'Builder Server' },
	IntentResolver: { id: 'global.IntentResolver', defaultMessage: 'Intent Resolver' },
	MARKDOWN: { id: 'global.MARKDOWN', defaultMessage: 'Text' },
	Nlp: { id: 'global.Nlp', defaultMessage: 'NLP Engine' },
	OutCalls: { id: 'global.OutCalls', defaultMessage: 'Out Calls' },
	RemoveGroupNode: { id: 'global.RemoveGroupNode', defaultMessage: 'Remove selected node from this group' },
	SORT_ALPHABETICALLY: { id: 'global.SORT_ALPHABETICALLY', defaultMessage: 'Alphabetically' },
	SORT_NEWEST_PROJECTS_FIRST: { id: 'global.SORT_NEWEST_PROJECTS_FIRST', defaultMessage: 'Newest project first' },
	SORT_NEWEST_VERSIONS_FIRST: { id: 'global.SORT_NEWEST_VERSIONS_FIRST', defaultMessage: 'Newest version first' },
	SPEECH: { id: 'global.SPEECH', defaultMessage: 'Speech' },
	Voice: { id: 'global.Voice', defaultMessage: 'Voice' },
	VoiceConnector: { id: 'global.VoiceConnector', defaultMessage: 'Voice Connector' },
	actions: { id: 'global.actions', defaultMessage: 'Actions' },
	add: { id: 'global.add', defaultMessage: 'Add' },
	addButton: { id: 'global.addButton', defaultMessage: 'Add button' },
	addColumn: { id: 'global.addColumn', defaultMessage: 'Add Column' },
	addCondition: { id: 'global.addCondition', defaultMessage: `Add Condition` },
	addEditUtteranceInMarkdownOptions: {
		id: 'global.addEditUtteranceInMarkdownOptions',
		defaultMessage: `Add utterance`,
	},
	addEntryCondition: { id: 'global.addEntryCondition', defaultMessage: 'Add entry condition' },
	addFallback: { id: 'global.addFallback', defaultMessage: 'Add fallback' },
	addFromIntentLib: { id: 'global.addFromIntentLib', defaultMessage: 'Add from intent library' },
	addIntent: { id: 'global.addIntent', defaultMessage: 'Add intent' },
	addIntentFromLibrary: {
		id: 'global.addIntentFromLibrary',
		defaultMessage: 'Add intent from library',
	},
	addIntentUtteranceToMarkdownOptions: {
		id: 'global.addIntentUtteranceToMarkdownOptions',
		defaultMessage: `Add one of the intent's utterances as a markdownOption button`,
	},
	addMetadata: { id: 'global.addMetadata', defaultMessage: 'Add Data' },
	addNode: { id: 'global.addNode', defaultMessage: 'Add node' },
	addNodeIntoGroup: {
		id: 'global.addGroupNodeQuestion',
		defaultMessage: 'Please select the nodes you want to add into this group:',
	},
	addNumber: { id: 'global.addNumber', defaultMessage: 'Add number' },
	addOutput: { id: 'global.addOutput', defaultMessage: 'Add output' },
	addParam: { id: 'global.addParam', defaultMessage: 'Add parameter' },
	addRow: { id: 'global.addRow', defaultMessage: 'Add Row' },
	addSmartFunction: { id: 'global.addSmartFunction', defaultMessage: 'Add smart function' },
	addSttBoostEntry: { id: 'global.addSttBoostEntry', defaultMessage: 'Add new boost entry' },
	addToBlacklist: { id: 'global.addToBlacklist', defaultMessage: 'Add to Blacklist' },
	addToWhitelist: { id: 'global.addToWhitelist', defaultMessage: 'Add to Whitelist' },
	addTransErr: { id: 'global.addTransErr', defaultMessage: `Please add a response value first.` },
	addTransition: { id: 'global.addTransition', defaultMessage: 'Add transition' },
	addUser: { id: 'global.addUser', defaultMessage: 'Add user' },
	addUtterance: { id: 'global.addUtterance', defaultMessage: 'Add utterance' },
	addUtteranceButton: { id: 'global.addUtteranceButton', defaultMessage: 'Add utterance as button' },
	addVariable: { id: 'global.addVariable', defaultMessage: 'Add variable' },
	addVariablesToReset: { id: 'global.addVariablesToReset', defaultMessage: 'Add variables to reset' },
	addWidget: { id: 'global.addWidget', defaultMessage: 'Add widget' },
	addingUtteranceToMarkdownOptionsObjectsNotification: {
		id: 'global.addingUtteranceToMarkdownOptionsObjectsNotification',
		defaultMessage:
			'MarkdownOptions of the node {nodeId} have an object-like structure while the utterance being added is only a string.',
	},
	administration: { id: 'global.administration', defaultMessage: 'Administration' },
	administrationTools: { id: 'global.administrationTools', defaultMessage: 'Utilities' },
	allDay: { id: 'global.allDay', defaultMessage: 'All-day' },
	allowJumping: { id: 'global.allowJumping', defaultMessage: 'Allow jumping' },
	allowJumpingItemDescription: {
		id: 'global.allowJumpingItemDescription',
		defaultMessage: 'Leave the conversation tree and start a new topic',
	},
	allowJumpingToNodesDescription: {
		id: 'global.allowJumpingToNodesDescription',
		defaultMessage: 'List of nodes to which the conversation can jump in the middle of another conversation',
	},
	allowJumpingToNodesItemTitle: {
		id: 'global.allowJumpingToNodesItemTitle',
		defaultMessage: 'Nodes allowed to jump to',
	},
	allowedCallCount: { id: 'global.allowedCallCount', defaultMessage: 'Allowed Call Count' },
	alreadyIsGlobalStartNode: {
		id: 'global.alreadyIsGlobalStartNode',
		defaultMessage: 'The node is already the global start node',
	},
	alreadyIsGroupStartNode: {
		id: 'global.alreadyIsGroupStartNode',
		defaultMessage: 'The node is already the group start node',
	},
	analytics: { id: 'global.analytics', defaultMessage: 'Conversation Dashboard' },
	analyticsTools: { id: 'global.analyticsTools', defaultMessage: 'Analytics' },
	announcementText: { id: 'global.announcementText', defaultMessage: 'Announcement text' },
	announcementVoice: { id: 'global.announcementVoice', defaultMessage: 'Announcement voice' },
	announcementWidget: { id: 'global.announcementWidget', defaultMessage: 'Announcement' },
	announcements: { id: 'global.announcements', defaultMessage: 'Announcements' },
	appVersion: { id: 'global.appVersion', defaultMessage: 'Release version' },
	arrangeDiagrams: {
		id: 'global.arrangeDiagrams',
		defaultMessage: `<h1>Arrange Diagrams</h1><p><strong>Set custom position of any node diagram by dragging it to the place you visually like the most.</strong></p><p>To select multiple nodes, press and hold <strong>Shift + Left mouse click and hold to make a selection</strong></p><p>To add a node to the selection, press and hold <strong>Shift + Left mouse click</strong></p>`,
	},
	aspellItemDescription: { id: 'global.aspellItemDescription', defaultMessage: 'Interactive spell checker' },
	aspellItemHelp: {
		id: 'global.aspellItemHelp',
		defaultMessage: 'replace = ; duplicate = ; whitelist = ; null = disable',
	},
	aspellItemTitle: { id: 'global.aspellItemTitle', defaultMessage: 'Aspell' },
	assets: { id: 'global.assets', defaultMessage: 'Assets' },
	assetsFileType: { id: 'global.fileType', defaultMessage: 'File type' },
	assetsFriendlyName: { id: 'global.assetsFriendlyName', defaultMessage: 'Friendly name' },
	assetsUpload: { id: 'global.assetsUpload', defaultMessage: 'Upload File' },
	assignPhoneNumber: { id: 'global.assignPhoneNumber', defaultMessage: 'Assign phone number' },
	assignPhoneNumberWarning: {
		id: 'global.assignPhoneNumberWarning',
		defaultMessage: 'This will reassign numbers if they are assigned to another project',
	},
	assignRole: { id: 'global.assignRole', defaultMessage: 'Assign role' },
	assignTwilioNumberWarning: {
		id: 'global.assignTwilioNumberWarning',
		defaultMessage: 'If the number is linked to a different model, it will get unlinked.',
	},
	assignUserToModel: {
		id: 'global.assignUserToModel',
		defaultMessage: 'Assign role "{role}" of the model "{modelName}" to the user:',
	},
	assignedAndSavedPhoneNumberExplanation: {
		id: 'global.assignedAndSavedPhoneNumberExplanation',
		defaultMessage: 'To unassign the number, select the number and use the delete button below instead',
	},
	assignedPhoneNumbers: { id: 'global.assignedPhoneNumbers', defaultMessage: 'Assigned phone numbers' },
	assigningPhoneNumbersNotification: {
		id: 'global.assigningPhoneNumbersNotification',
		defaultMessage: 'The numbers are being configured, the changes will take effect in a few seconds.',
	},
	backToFlow: { id: 'global.backToFlow', defaultMessage: 'Back to Flow editor' },
	backToIntent: { id: 'global.backToIntent', defaultMessage: 'Back to intent list' },
	backToList: { id: 'global.backToList', defaultMessage: 'Back to list' },
	backupModelVersion: { id: 'global.backupModelVersion', defaultMessage: 'JSON' },
	bgMusicFilename: { id: 'global.bgMusicFilename', defaultMessage: 'Background music filename' },
	bgMusicVolume: { id: 'global.bgMusicVolume', defaultMessage: 'Background music volume' },
	blacklist: { id: 'global.blacklist', defaultMessage: 'Blacklist' },
	boostPhrases: { id: 'global.boostPhrases', defaultMessage: 'Boosted phrases' },
	boostPhrasesHelperText: {
		id: 'global.boostPhrasesHelperText',
		defaultMessage: 'You can use `Enter` and `Backspace` to add and delete phrases.',
	},
	boostValue: { id: 'global.boostValue', defaultMessage: 'Boost value' },
	callConfiguration: { id: 'global.callConfiguration', defaultMessage: 'Calls' },
	sipManagement: { id: 'global.sipManagement', defaultMessage: 'SIP management' },
	callDate: { id: 'global.callDate', defaultMessage: 'Date' },
	callEnd: { id: 'global.callEnd', defaultMessage: 'End' },
	callId: { id: 'global.callId', defaultMessage: 'Call ID' },
	callRecording: { id: 'global.callRecording', defaultMessage: 'Phone call recording' },
	callStart: { id: 'global.callStart', defaultMessage: 'Start' },
	callStatus: { id: 'global.callStatus', defaultMessage: 'Status' },
	callStatusNotifier: { id: 'global.callStatusNotifier', defaultMessage: 'Status Change Notification' },
	callTranscription: { id: 'global.callTranscription', defaultMessage: 'Phone call transcription' },
	calledAt: { id: 'global.calledAt', defaultMessage: 'Called at' },
	calls: { id: 'global.calls', defaultMessage: 'Campaign' },
	callsRepetition: { id: 'global.callsRepetition', defaultMessage: 'Calls repetition' },
	campLaunchedAgain: { id: 'global.campLaunchedAgain', defaultMessage: 'Campaign was launched again' },
	campROnly: { id: 'global.campROnly', defaultMessage: 'Campaign settings (read only)' },
	campSaved: { id: 'global.campSaved', defaultMessage: 'Campaign was successfully saved' },
	campSavedAndLaunched: {
		id: 'global.campSavedAndLaunched',
		defaultMessage: 'Campaign was successfully saved and launched',
	},
	campaign: { id: 'global.campaign', defaultMessage: 'Campaign' },
	campaignActions: { id: 'global.campaignActions', defaultMessage: 'Actions' },
	campaignCreatedAt: { id: 'global.campaignCreatedAt', defaultMessage: 'Created' },
	campaignFinishedAt: { id: 'global.campaignFinishedAt', defaultMessage: 'Finished' },
	campaignMaxCallTrials: { id: 'global.campaignMaxCallTrials', defaultMessage: 'Max call trials' },
	campaignName: { id: 'global.campaignName', defaultMessage: 'Campaign name' },
	campaignPhoneCallFilter: { id: 'global.campaignPhoneCallFilter', defaultMessage: 'Filter phone calls by duration' },
	campaignRepeatCallsAt: { id: 'global.campaignRepeatCalssAt', defaultMessage: 'Repeat calls' },
	campaignStatus: { id: 'global.campaignStatus', defaultMessage: 'Status' },
	campaignType: { id: 'global.campaignType', defaultMessage: 'Campaign type' },
	campaigns: { id: 'global.campaigns', defaultMessage: 'Campaigns' },
	cancel: { id: 'global.cancel', defaultMessage: 'Cancel' },
	cancelChanges: { id: 'global.cancelChanges', defaultMessage: 'Cancel Changes' },
	candidateScores: { id: 'global.candidateScores', defaultMessage: 'Candidates' },
	cantBeYesOrNo: { id: 'global.cantBeYesOrNo', defaultMessage: 'Keywords as "yes" or "no" are disallowed!' },
	categories: { id: 'global.categories', defaultMessage: 'Categories' },
	centerCanvas: { id: 'global.centerCanvas', defaultMessage: 'Center canvas' },
	changePreferences: { id: 'global.changePreferences', defaultMessage: 'Change preferences' },
	changePwd: { id: 'global.changePwd', defaultMessage: 'Change password' },
	chatBubbleHidden: { id: 'global.chatBubbleHidden', defaultMessage: 'Hide chat bubble' },
	chatBubblePulsatingHidden: {
		id: 'global.chatBubblePulsatingHidden',
		defaultMessage: 'Hide chat pulsating animation',
	},
	chatBubbleTypingIndicatorHidden: {
		id: 'global.chatBubbleTypingIndicatorHidden',
		defaultMessage: 'Hide chat typing indicator',
	},
	checkCacheOnly: { id: 'global.checkCacheOnly', defaultMessage: 'Check cache only' },
	chooseModelFirst: { id: 'global.chooseModelFirst', defaultMessage: 'No project selected' },
	classifyText: { id: 'global.classifyText', defaultMessage: 'Classify' },
	clientData: { id: 'global.clientData', defaultMessage: 'Client data' },
	clipboard: { id: 'global.clipboard', defaultMessage: 'Copy' },
	clipboardText: { id: 'global.clipboardText', defaultMessage: 'Copy model ID to clipboard' },
	close: { id: 'global.close', defaultMessage: 'Close' },
	colorCaptions: { id: 'global.colorCaptions', defaultMessage: 'Edit color captions' },
	colors: { id: 'global.colors', defaultMessage: 'Node color' },
	columnName: { id: 'global.columnName', defaultMessage: 'Column Name' },
	columnType: { id: 'global.columnType', defaultMessage: 'Column Data Type' },
	comment: { id: 'global.comment', defaultMessage: 'Comment' },
	comparisonOperator: { id: 'global.comparisonOperator', defaultMessage: 'Comparison Operator' },
	composeNewIntent: { id: 'global.composeNewIntent', defaultMessage: 'Compose new intent' },
	condition: { id: 'global.condition', defaultMessage: `Condition` },
	conditionTargetNode: { id: 'global.conditionTargetNode', defaultMessage: `Condition target node` },
	conditionValue: { id: 'global.conditionValue', defaultMessage: `Condition value *` },
	conditions: { id: 'global.conditions', defaultMessage: `Conditions` },
	confidence: { id: 'global.confidence', defaultMessage: 'Confidence' },
	configEditor: { id: 'global.configEditor', defaultMessage: 'YAML Editor' },
	confirm: { id: 'global.confirm', defaultMessage: 'Confirm' },
	confusionMatrix: { id: 'global.confusionMatrix', defaultMessage: 'Confusion Matrix' },
	confusionMatrixAbort: { id: 'global.abort', defaultMessage: 'Abort matrix generation' },
	confusionMatrixFail: {
		id: 'global.confusionMatrixFail',
		defaultMessage:
			'The matrix generation failed. Try it later, please. If the problem persists, report it to your maintainer.',
	},
	confusionMatrixGenerate: { id: 'global.generate', defaultMessage: 'Generate matrix (may take up to 15 minutes)' },
	confusionMatrixGenerateDescription: {
		id: 'global.generateDescription',
		defaultMessage: 'You may generate matrix for the selected model.',
	},
	confusionMatrixNoModel: {
		id: 'global.noModel',
		defaultMessage: 'Please select the model version to create confusion matrix from it or create a new project.',
	},
	confusionMatrixPending: {
		id: 'global.pending',
		defaultMessage: 'The matrix is currently generating. It may take up to 15 minutes, please be patient.',
	},
	connectedPhoneNumber: { id: 'global.connectedPhoneNumber', defaultMessage: 'Phone number' },
	connectionError: {
		id: 'global.connectionError',
		defaultMessage:
			'There was an unexpected error while calling the API. Try to check your internet connection. ' +
			'If it is OK try to repeat your request in a few minutes. ' +
			'If the problem persists report it to your maintainer, please. ' +
			'Here are details about the error: {details}',
	},
	continueFallback: { id: 'global.continueFallback', defaultMessage: 'Continue / Fallback' },
	continueSignal: { id: 'global.continueSignal', defaultMessage: 'Continue / Signal' },
	contract_currency: { id: 'global.contract_currency', defaultMessage: 'Contract currency' },
	contract_expiration: { id: 'global.contract_expiration', defaultMessage: 'Contract expiration' },
	contract_id: { id: 'global.contract_id', defaultMessage: 'Contract ID' },
	contract_value: { id: 'global.contract_value', defaultMessage: 'Contract value' },
	conversationCount: { id: 'global.conversationCount', defaultMessage: 'Conversation Count' },
	conversationDesc: {
		id: 'global.conversationDesc',
		defaultMessage:
			'All conversations within every project and every version linked to your organization. Try conversations in Smart Debug page.',
	},
	copyCampaign: { id: 'global.copyCampaign', defaultMessage: 'Copy campaign' },
	copyNodeOrGroup: { id: 'global.copyNodeOrGroup', defaultMessage: 'Copy node' },
	copyOf: { id: 'global.copyOf', defaultMessage: 'Copy of' },
	copyPhoneCalls: { id: 'global.copyPhoneCalls', defaultMessage: 'Copy phoneCalls and metadata' },
	copySelected: { id: 'global.copySelected', defaultMessage: 'Copy ({count})' },
	copyToClipboard: { id: 'global.copyToClipboard', defaultMessage: 'Copy to clipboard' },
	cpu: { id: 'global.cpu', defaultMessage: 'CPU utilization' },
	cpuDesc: {
		id: 'global.cpuDesc',
		defaultMessage:
			'The allocated computing capacity has its limits. Training more models can lead to reduced performance. More detail about Running Processes.',
	},
	createCampaign: { id: 'global.createCampaign', defaultMessage: 'Create campaign' },
	createEditTransitionModalCreateTitle: {
		id: 'global.title.create',
		defaultMessage: 'Add transition',
	},
	createEditTransitionModalEditTitle: { id: 'global.title.edit', defaultMessage: 'Edit transition' },
	createEditTransitionModalTargetNodeLabel: {
		id: 'global.label.targetNod',
		defaultMessage: 'Target node (to add new node, type in the name)',
	},
	createEditTransitionModalWarnIntentCreated: {
		id: 'global.warn.intentCreated',
		defaultMessage: 'Intent "{intentName}" will be created',
	},
	createEditTransitionModalWarnNodeCreated: {
		id: 'global.warn.nodeCreated',
		defaultMessage: 'Node "{nodeName}" will be created',
	},
	createNewNode: { id: 'global.createNewNode', defaultMessage: 'Create a new node' },
	createNodeAndGroupNode: {
		id: 'global.createNodeAndGroupNode',
		defaultMessage: 'Create a new node and add it into this group',
	},
	createNodeAndTransition: {
		id: 'global.createNodeAndTransition',
		defaultMessage: 'Create new node and its transition',
	},
	createNodeGroup: { id: 'global.createNodeGroup', defaultMessage: 'Create a node group' },
	createOrganization: { id: 'global.createOrganization', defaultMessage: 'Create organization' },
	createPhoneNumber: { id: 'global.createPhoneNumber', defaultMessage: 'Create phone number' },
	createPhoneNumbers: { id: 'global.createPhoneNumbers', defaultMessage: 'Create and assign phone numbers' },
	createSipShortcode: { id: 'global.createSipShortcode', defaultMessage: 'Create SIP shortcode' },
	createTransition: { id: 'global.createTransition', defaultMessage: 'Create transition' },
	createUser: { id: 'global.createUser', defaultMessage: 'Create user' },
	created: { id: 'global.created', defaultMessage: 'Created' },
	createdBy: { id: 'global.createdBy', defaultMessage: 'Created by' },
	createdAt: { id: 'global.createdAt', defaultMessage: 'Created At' },
	updatedBy: { id: 'global.updatedBy', defaultMessage: 'Updated By' },
	updatedAt: { id: 'global.updatedAt', defaultMessage: 'Updated At' },
	createdLabel: { id: 'global.createdLabel', defaultMessage: 'Created' },
	creationTime: { id: 'global.creationTime', defaultMessage: 'Created ' },
	creator: { id: 'global.creator', defaultMessage: '{name} (Creator)' },
	currVoiceRec: { id: 'global.currVoiceRec', defaultMessage: 'Current voice recording' },
	customMessages: { id: 'global.customMessages', defaultMessage: 'Custom message' },
	customerInsight: { id: 'global.customerInsight', defaultMessage: 'Customer Insight' },
	customer_address: { id: 'global.customer_address', defaultMessage: 'Address' },
	customer_birthday: { id: 'global.customer_birthday', defaultMessage: 'Birthday' },
	customer_id: { id: 'global.customer_id', defaultMessage: 'ID' },
	customer_name: { id: 'global.customer_name', defaultMessage: 'Name' },
	dashboard: { id: 'global.dashboard', defaultMessage: 'DashBoard' },
	dataManagement: { id: 'global.dataManagement', defaultMessage: 'Data Management' },
	dateInterval: { id: 'global.dateInterval', defaultMessage: 'Date interval' },
	dateTimeLabel: { id: 'global.dateTimeLabel', defaultMessage: 'Pick first call date and time' },
	defaultMessage: { id: 'global.defaultMessage', defaultMessage: 'Default message' },
	delete: { id: 'global.delete', defaultMessage: 'Delete' },
	deleteAllModelVersionsQuestion: {
		id: 'global.question.deleteAllModelVersionsQuestion:',
		defaultMessage:
			'You are about to remove all versions of this model. Are you sure you want to remove this whole model ?',
	},
	deleteBoostPhrase: { id: 'global.deleteBoostPhrase', defaultMessage: 'Delete boost phrase' },
	deleteCampaign: { id: 'global.deleteCampaign', defaultMessage: 'Delete campaign' },
	deleteCampaigns: { id: 'global.deleteCampaigns', defaultMessage: 'Delete campaigns' },
	deleteCampaignsQuestions: {
		id: 'global.c',
		defaultMessage: 'You are going to permanently remove campaign data. Are you sure you want to do that ?',
	},
	deleteColumn: { id: 'global.deleteColumn', defaultMessage: 'Delete Column' },
	deleteColumnButton: { id: 'global.deleteColumnButton', defaultMessage: 'Delete Permanently' },
	deleteColumnContent: { id: 'global.deleteColumnContent', defaultMessage: 'Are you sure?' },
	unsavedChanges: { id: 'global.unsavedChanges', defaultMessage: 'Discard unsaved changes' },
	discardChanges: { id: 'global.discardChanges', defaultMessage: 'Discard' },
	changesWillBeLost: {
		id: 'global.changesWillBeLost',
		defaultMessage:
			'Table <strong>{table}</strong> contains changes not yet saved to the Database, are you sure you want to discard them?',
	},
	deleteIntentContent: {
		id: 'global.deleteIntentContent',
		defaultMessage: 'Are you sure to delete intent <strong>{name}</strong>?',
	},
	deleteIntentTitle: { id: 'global.deleteIntentTitle', defaultMessage: 'Delete intent' },
	deleteMetadata: { id: 'global.deleteMetadata', defaultMessage: 'Delete Data' },
	deleteMetadataButton: { id: 'global.deleteMetadataButton', defaultMessage: 'Delete Permanently' },
	deleteMetadataContent: { id: 'global.deleteMetadataContent', defaultMessage: 'Are you sure about deleting' },
	deleteModelConfirm: { id: 'global.confirm.deleteModel', defaultMessage: 'Delete permanently' },
	deleteModelConfirmInProgress: { id: 'global.confirm.deleteModelInProgress', defaultMessage: 'Deleting...' },
	deleteModelQuestion: {
		id: 'global.question.deleteModel',
		defaultMessage: 'Are you sure you want to delete project <strong>{model}</strong>?',
	},
	deleteModelTitle: { id: 'global.title.deleteModel', defaultMessage: 'Delete model' },
	deleteModelVersionQuestion: {
		id: 'global.question.deleteModelVersion',
		defaultMessage:
			'Are you sure you want to delete model version <strong>{name}</strong> - <strong>{version}</strong>?',
	},
	deleteModelVersionTitle: { id: 'global.title.deleteModelVersion', defaultMessage: 'Delete model version' },
	deleteModelVersionsQuestion: {
		id: 'global.question.deleteModelVersions',
		defaultMessage: 'Are you sure you want to delete selected model versions ?',
	},
	deleteModelVersionsTitle: { id: 'global.title.deleteModelVersionsTitle', defaultMessage: 'Delete model versions' },
	deleteNodeQuestion: {
		id: 'global.deleteNodeQuestion',
		defaultMessage: 'Are you sure you want to delete the node <strong>{name}</strong>?',
	},
	deleteNumber: { id: 'global.deleteNumber', defaultMessage: 'Delete number' },
	deleteParameter: { id: 'global.deleteParameter', defaultMessage: 'Delete parameter' },
	deletePhoneNumber: {
		id: 'global.deletePhoneNumber',
		defaultMessage: 'Delete this phone number and its configuration.',
	},
	deleteSelected: { id: 'global.deleteSelected', defaultMessage: 'Delete selected' },
	deleteUtteranceContent: {
		id: 'global.deleteUtteranceContent',
		defaultMessage: 'Are you sure to delete utterance <strong>{name}</strong>?',
	},
	deleteUtteranceTitle: { id: 'global.deleteUtteranceTitle', defaultMessage: 'Delete utterance' },
	deleteVocabularyEntry: { id: 'global.deleteVocabularyEntry', defaultMessage: 'Delete vocabulary entry' },
	deployVersion: { id: 'global.deployVersion', defaultMessage: 'Deploy version' },
	desc: { id: 'global.desc', defaultMessage: 'Description' },
	description: { id: 'global.description', defaultMessage: 'Description' },
	designTools: { id: 'global.designTools', defaultMessage: 'Design' },
	detectedEntities: { id: 'global.detectedEntities', defaultMessage: 'Detected entities' },
	diffYamlModal: { id: 'global.diffYamlModal', defaultMessage: 'Show difference in YAMLs' },
	differentVersionWarning: {
		id: 'global.differentVersionWarning',
		defaultMessage: `There is a newer version of this model ({maximumVersion}) than the one you are currently saving from ({currentVersion}). Are you sure you want this model's versions to branch out like this?`,
	},
	direction: { id: 'global.direction', defaultMessage: 'Direction' },
	disableGroupedView: { id: 'global.disableGroupedView', defaultMessage: 'Disable grouped view' },
	disabledUtteranceTransformersItemHelp: {
		id: 'global.disabledUtteranceTransformersItemHelp',
		defaultMessage: '1 = ; 2 = ; 3 = ; 4 = ',
	},
	disabledUtteranceTransformersItemTitle: {
		id: 'global.disabledUtteranceTransformersItemTitle',
		defaultMessage: 'Disabled utterance transformers',
	},
	disallowArrangingDiagrams: {
		id: 'global.disallowArrangingDiagrams',
		defaultMessage: 'You can not arrange diagrams positions while creating a new diagram group',
	},
	displayClosestNodesOnly: { id: 'global.displayClosestNodesOnly', defaultMessage: 'Show related nodes only' },
	downloadCampaignCSV: { id: 'global.downloadCampaignCSV', defaultMessage: 'Download campaign (CSV)' },
	downloadCampaignJSON: { id: 'global.downloadCampaignJSON', defaultMessage: 'Download campaign (JSON)' },
	downloadCampaignXLS: { id: 'global.downloadCampaignXLS', defaultMessage: 'Download campaign (XLS)' },
	downloadCampaignsRecs: { id: 'global.downloadCampaignsRecs', defaultMessage: 'Download campaign recordings' },
	downloadDataCSV: { id: 'global.downloadDataCSV', defaultMessage: 'Download Data (CSV)' },
	downloadDataJSON: { id: 'global.downloadDataJSON', defaultMessage: 'Download Data (JSON)' },
	downloadDataXLS: { id: 'global.downloadDataXLS', defaultMessage: 'Download Data (XLS)' },
	downloadingCompleted: {
		id: 'global.downloadingCompleted',
		defaultMessage: 'Downloading successfully completed !',
	},
	downloadingVoiceRecording: {
		id: 'global.downloadingVoiceRecording',
		defaultMessage: 'Downloading voice recording {detail}',
	},
	dragToSort: { id: 'global.dragToSort', defaultMessage: 'Drag to sort' },
	duplicate: { id: 'global.duplicate', defaultMessage: 'Invalid value, duplicate!' },
	duration: { id: 'global.duration', defaultMessage: 'Duration' },
	durationInSeconds: { id: 'global.durationInSeconds', defaultMessage: 'Duration in seconds' },
	edit: { id: 'global.edit', defaultMessage: 'Edit' },
	editAnnouncement: { id: 'global.editAnnouncement', defaultMessage: 'Edit announcement' },
	editAnnouncements: { id: 'global.editAnnouncements', defaultMessage: 'Edit announcements' },
	editCondition: { id: 'global.editCondition', defaultMessage: `Edit condition` },
	editDataButton: { id: 'global.editButton', defaultMessage: 'Edit Data' },
	editEntryCondition: { id: 'global.editEntryCondition', defaultMessage: 'Edit entry condition' },
	editIntentTitle: { id: 'global.editIntentTitle', defaultMessage: 'Edit intent' },
	editNodeGroup: { id: 'global.editNodeGroup', defaultMessage: 'Edit group' },
	editProjectDesc: { id: 'global.editProjectDesc', defaultMessage: 'Edit project description' },
	editProjectVersionDesc: { id: 'global.editProjectVersionDesc', defaultMessage: 'Edit model version description' },
	editSelected: { id: 'global.editSelected', defaultMessage: 'Edit ({count})' },
	editSmartFunction: { id: 'global.editSmartFunction', defaultMessage: 'Edit smart function' },
	editUtteranceButton: { id: 'global.editUtteranceButton', defaultMessage: 'Edit utterance as button' },
	editVariable: { id: 'global.editVariable', defaultMessage: 'Edit variable' },
	editor: { id: 'global.editor', defaultMessage: 'Editor' },
	email: { id: 'global.email', defaultMessage: 'Email' },
	emailAnalytics: { id: 'global.emailAnalytics', defaultMessage: 'Email Analytics' },
	emailBody: { id: 'global.emailBody', defaultMessage: 'Email body' },
	empty: { id: 'global.empty', defaultMessage: 'Invalid value, the field can not be empty!' },
	doesNotContainString: {
		id: 'global.doesNotContainString',
		defaultMessage: 'Invalid value, the field must include {string}!',
	},
	emptyValueNotAllowed: { id: 'global.emptyValueNotAllowed', defaultMessage: 'Empty value not allowed.' },
	enableGroupedView: { id: 'global.enableGroupedView', defaultMessage: 'Enable grouped view' },
	enabled: { id: 'global.enabled', defaultMessage: 'Enabled' },
	endDate: { id: 'global.endDate', defaultMessage: 'End date' },
	endTime: { id: 'global.endTime', defaultMessage: 'End time' },
	endsWith: { id: 'global.endsWith', defaultMessage: 'Ends with' },
	enterTheName: { id: 'global.enterTheName', defaultMessage: 'Enter the name' },
	entryConditions: { id: 'global.entryConditions', defaultMessage: 'Entry Conditions' },
	epochItemDescription: {
		id: 'global.epochItemDescription',
		defaultMessage: 'Number of passes over the entire dataset',
	},
	epochItemHelp: { id: 'global.epochItemHelp', defaultMessage: 'Recommended value is between 3 and 15' },
	epochItemTitle: { id: 'global.epochItemTitle', defaultMessage: 'Epoch' },
	error: { id: 'global.error', defaultMessage: 'Error' },
	errors: { id: 'global.errors', defaultMessage: 'Errors' },
	fail: {
		id: 'global.fail',
		defaultMessage: 'Processing failed, please report the issue with the following details: <pre>{error}</pre>',
	},
	fallback: { id: 'global.fallback', defaultMessage: `Continue / Fallback` },
	fieldRequired: { id: 'global.field.required', defaultMessage: 'This field is required' },
	filter: { id: 'global.filter', defaultMessage: 'Filter' },
	filterByIntentAndUtterances: {
		id: 'global.filterByIntentAndUtterances',
		defaultMessage: "Filter by occurance in the intent name or in it's utterances (case insensitive)",
	},
	filterProperty: { id: 'global.filterProperty', defaultMessage: 'Filter property' },
	filterType: { id: 'global.filterType', defaultMessage: 'Filter type' },
	finished: { id: 'global.finished', defaultMessage: 'Finished' },
	flowEditor: { id: 'global.flowEditor', defaultMessage: 'Flow Editor' },
	flowSearch: { id: 'global.flowSearch', defaultMessage: 'Search in the whole flow' },
	flowSearchResultNodeId: { id: 'global.flowSearchResultNodeId', defaultMessage: 'Entity ID' },
	flowSearchResultType: { id: 'global.flowSearchResultType', defaultMessage: 'Type' },
	flowSearchResultValue: { id: 'global.flowSearchResultValue', defaultMessage: 'Whole value' },
	flowSearchResults: { id: 'global.flowSearchResults', defaultMessage: 'Search results' },
	focusOnWarning: { id: 'global.focusOnWarning', defaultMessage: 'Show warnings' },
	foldCategories: { id: 'global.foldCategories', defaultMessage: 'Fold categories' },
	foldIntents: { id: 'global.foldIntents', defaultMessage: 'Fold intents' },
	formatYaml: { id: 'global.formatYaml', defaultMessage: 'Format YAML' },
	friday: { id: 'global.friday', defaultMessage: 'Friday' },
	friendlyName: { id: 'global.friendlyName', defaultMessage: 'Name assigned to the number' },
	globalStartNode: {
		id: 'global.globalStartNode',
		defaultMessage: 'The is is the start node (group) of the whole model.',
	},
	globalStartNodeItemDescription: {
		id: 'global.globalStartNodeItemDescription',
		defaultMessage: 'At which node the conversation will always start',
	},
	globalStartNodeItemTitle: { id: 'global.globalStartNodeItemTitle', defaultMessage: 'Start node' },
	goBack: { id: 'global.goBack', defaultMessage: 'Go back' },
	goToReuseUtteranceVariable: {
		id: 'global.goToReuseUtteranceVariable',
		defaultMessage: `Variable containing utterance to be reused`,
	},
	gotoModalGOTO: {
		id: 'global.gotoModalGOTO',
		defaultMessage: `<h1>GOTO</h1><p>If there is no other option, travel from the current node to GOTO destination</p>`,
	},
	gotoModalGOTO_BACK: {
		id: 'global.gotoModalGOTO_BACK',
		defaultMessage: `<h1>GOTO_BACK</h1><p>Fallback leading to an already visited node.</p>`,
	},
	gotoModalGOTO_CONVERSATION_LOGIC: {
		id: 'global.gotoModalGOTO_CONVERSATION_LOGIC',
		defaultMessage: `<h1>GOTO_CONVERSATION_LOGIC</h1><p></p>`,
	},
	gotoModalGOTO_REUSE_UTTERANCE: {
		id: 'global.gotoModalGOTO_REUSE_UTTERANCE',
		defaultMessage: `<h1>GOTO_REUSE_UTTERANCE</h1><p>If there is no other option, travel from the current node to GOTO_REUSE_UTTERANCE destination. The utterance written/said by the user can be modified and reused later.</p><p>	More information can be found in the Goodbot documentation at 	<a>		"text":"GOTO_REUSE_UTTERANCE",		"href":"https://docs.partner.borndigital.ai/yaml_main.html#goto-wait"	</a>.</p>`,
	},
	gotoModalGOTO_WAIT: {
		id: 'global.gotoModalGOTO_WAIT',
		defaultMessage: `<h1>GOTO_WAIT</h1><p>Waiting in this node until the next API call. The API call can contain a user statement, a signal, or it can be empty.Can be used in situations where you need to ignore user input (user is waiting, music is playing; waiting for a backend call, etc.) or you want to use only regular expressions and variables.</p><p>	More information can be found in the Goodbot documentation at 	<a>		"text":"GOTO_WAIT",		"href":"https://docs.partner.borndigital.ai/yaml_main.html#goto-wait"	</a>.</p>`,
	},
	groupName: { id: 'global.groupName', defaultMessage: 'Group name' },
	groupStartNode: { id: 'global.groupStartNode', defaultMessage: 'This is the start node of the group.' },
	groupView: { id: 'global.groupView', defaultMessage: 'Display all groups' },
	hashId: { id: 'global.hashId', defaultMessage: 'Hash id' },
	histogram: { id: 'global.histogram', defaultMessage: 'Histogram of all messages' },
	hostItemDescription: { id: 'global.hostItemDescription', defaultMessage: '...' },
	hostItemHelp: { id: 'global.hostItemHelp', defaultMessage: '...' },
	hostItemTitle: { id: 'global.hostItemTitle', defaultMessage: 'Host' },
	ignoreCase: { id: 'global.ignoreCase', defaultMessage: 'Ignore case' },
	ignoreGenerics: { id: 'global.ignoreGenerics', defaultMessage: 'Generate matrix without generic intents' },
	importData: { id: 'global.importData', defaultMessage: 'Import' },
	importDataError: { id: 'global.importDataError', defaultMessage: '{serverError}' },
	importVocabulary: { id: 'global.importVocabulary', defaultMessage: 'Import from CSV' },
	includes: { id: 'global.includes', defaultMessage: 'Includes' },
	incorrectHeader: {
		id: 'global.incorrectHeader',
		defaultMessage: "Incorrect header: file header doesn't match the existing table columns",
	},
	inputBgMusicManually: { id: 'global.inputBgMusicManually', defaultMessage: 'Custom background music id' },
	insertVariable: { id: 'global.insertVariable', defaultMessage: 'Insert variable' },
	intent: { id: 'global.intent', defaultMessage: 'Intent' },
	intentCreatedChooseTarget: {
		id: 'global.intentCreatedChooseTarget',
		defaultMessage: 'This intent was currently created, please choose a target node',
	},
	intentEditor: { id: 'global.intentEditor', defaultMessage: 'Intent Editor' },
	intentExists: { id: 'global.intentExists', defaultMessage: 'Intent with this name exists already !' },
	intentLabel: { id: 'global.intentLabel', defaultMessage: 'Intent label' },
	intentName: { id: 'global.intentName', defaultMessage: 'Intent name' },
	intentRelativeThresholdItemDescription: {
		id: 'global.intentRelativeThresholdItemDescription',
		defaultMessage: 'Set by how much the the intent with highest confidence value must exceed the second best result',
	},
	intentRelativeThresholdItemHelp: {
		id: 'global.intentRelativeThresholdItemHelp',
		defaultMessage: 'The score of the first is compared to the score of the second times this multiplier',
	},
	intentRelativeThresholdItemTitle: {
		id: 'global.intentRelativeThresholdItemTitle',
		defaultMessage: 'Intent relative threshold',
	},
	intentSameId: { id: 'global.intentSameId', defaultMessage: 'Intent already exists' },
	intentThresholdItemDescription: {
		id: 'global.intentThresholdItemDescription',
		defaultMessage: 'The smallest value which must be exceeded to select the intent',
	},
	intentThresholdItemTitle: { id: 'global.intentThresholdItemTitle', defaultMessage: 'Intent threshold' },
	intents: { id: 'global.intents', defaultMessage: 'Intents' },
	invalidConditionSet: { id: 'global.invalidConditionSet', defaultMessage: 'Invalid condition set.' },
	invalidDate: { id: 'global.invalidDate', defaultMessage: 'Call time is in the past' },
	invalidInput: { id: 'global.invalidInput', defaultMessage: 'Invalid Input' },
	invalidMetadataName: {
		id: 'global.invalidMetadataName',
		defaultMessage: 'Invalid value: Table name cannot contain special characters',
	},
	invalidOperator: { id: 'global.invalidOperator', defaultMessage: 'Invalid Operator.' },
	isGoToVariable: { id: 'global.isGoToVariable', defaultMessage: 'Use variable as target' },
	isMultipleTopicsEnabled: {
		id: 'global.isMultipleTopicsEnabled',
		defaultMessage: 'Multiple topics can be true',
	},
	isSipShortcode: { id: 'global.isSipShortcode', defaultMessage: 'SIP shortcode' },
	jsonUnknownError: {
		id: 'global.jsonUnknownError',
		defaultMessage: 'Found errors in JSON file. Please fix the file and import it again. Got: {error}',
	},
	language: { id: 'global.language', defaultMessage: 'Language' },
	languageItemDescription: {
		id: 'global.languageItemDescription',
		defaultMessage: 'Select the language of the conversation model',
	},
	languageItemTitle: { id: 'global.languageItemTitle', defaultMessage: 'Language' },
	launchCampaign: { id: 'global.launchCampaign', defaultMessage: 'Launch campaign' },
	lemmatizerItemDescription: {
		id: 'global.lemmatizerItemDescription',
		defaultMessage: 'Converter of words into their base form',
	},
	lemmatizerItemHelp: { id: 'global.lemmatizerItemHelp', defaultMessage: 'replace = ; duplicate = ; null = disable' },
	lemmatizerItemTitle: { id: 'global.lemmatizerItemTitle', defaultMessage: 'Lemmatizer' },
	limited: { id: 'global.limited', defaultMessage: 'Limited' },
	loadModelFile: { id: 'global.loadModelFile', defaultMessage: 'Import' },
	loadPreviousData: { id: 'global.loadPreviousData', defaultMessage: 'Load previous data' },
	loadYamlPlease: { id: 'global.loadYamlPlease', defaultMessage: 'Load valid YAML file, please.' },
	loadingContent: { id: 'global.loadingContent', defaultMessage: 'Loading content...' },
	loadingModels: { id: 'global.loadingModels', defaultMessage: 'Loading models data. Please wait ...' },
	logicalOperator: { id: 'global.logicalOperator', defaultMessage: 'Logical Operator' },
	login: { id: 'global.login', defaultMessage: 'Login' },
	logout: { id: 'global.logout', defaultMessage: 'Logout' },
	lrItemDescription: {
		id: 'global.lrItemDescription',
		defaultMessage: 'Determines the speed of neural network learning',
	},
	lrItemHelp: { id: 'global.lrItemHelp', defaultMessage: '...' },
	lrItemTitle: { id: 'global.lrItemTitle', defaultMessage: 'Learning rate' },
	madeCallsCount: { id: 'global.madeCallCount', defaultMessage: 'Total calls count' },
	maxCallTrials: { id: 'global.maxCallTrials', defaultMessage: 'Max. call trials' },
	maxDelayMillisecondsItemDescription: {
		id: 'global.maxDelayMillisecondsItemDescription',
		defaultMessage: 'Maximum time delay between messages sent by the Chatbot',
	},
	maxDelayMillisecondsItemTitle: {
		id: 'global.maxDelayMillisecondsItemTitle',
		defaultMessage: 'Max delay milliseconds',
	},
	maxLimit250: { id: 'global.maxLimit250', defaultMessage: 'Invalid value, the max. limit is 250 characters!' },
	maxParallelCalls: { id: 'global.maxParallelCalls', defaultMessage: 'Max. parallel calls' },
	messageCount: { id: 'global.messageCount', defaultMessage: 'Message Count' },
	messageDesc: {
		id: 'global.messageDesc',
		defaultMessage:
			'Count of all messages generated by your organization. For more details and analytics switch to Analytics view.',
	},
	messages: { id: 'global.messages', defaultMessage: 'Messages' },
	messagesProvider: { id: 'global.messagesProvider', defaultMessage: 'Service provider' },
	messengerOutput: { id: 'global.messengerOutput', defaultMessage: 'Includes Messenger output' },
	metaDataLabel: { id: 'global.metaDataLabel', defaultMessage: 'Metadata Parameters:' },
	metadataDesc: { id: 'global.metadataDesc', defaultMessage: 'Metadata Description' },
	metadataName: { id: 'global.metadataName', defaultMessage: 'Metadata Name' },
	metadataParameters: { id: 'global.metadataParameters', defaultMessage: 'Metadata parameters' },
	missingToken: {
		id: 'global.missingToken',
		defaultMessage: 'Trying to do an authorized request {method} {endpoint} but the token is missing.',
	},
	modelAnnouncement: { id: 'global.modelAnnouncement', defaultMessage: 'Announcements for project:' },
	modelId: { id: 'global.modelId', defaultMessage: 'Model ID' },
	modelIdInClipboard: {
		id: 'global.modelIdInClipboard',
		defaultMessage: 'The model id has been copied into your clipboard.',
	},
	modelName: { id: 'global.modelName', defaultMessage: 'Model name' },
	modelTrained: { id: 'global.modelTrained', defaultMessage: 'Model is successfully trained.' },
	modelTrainingInProgress: { id: 'global.trainingInProgress', defaultMessage: 'Training...' },
	modelTrainingInProgressEx: {
		id: 'global.modelTrainingInProgressEx',
		defaultMessage: 'The training of the model is in progress. Please wait a few minutes.',
	},
	monday: { id: 'global.monday', defaultMessage: 'Monday' },
	multipleFileImportDisallowed: {
		id: 'global.multipleFileImportDisallowed',
		defaultMessage: "You can't import more than one JSON file. Only multiple YAML files are allowed.",
	},
	name: { id: 'global.name', defaultMessage: 'Name' },
	nameYourNewIntent: { id: 'global.nameYourNewIntent', defaultMessage: 'Name your new intent' },
	newAnnouncement: { id: 'global.newAnnouncement', defaultMessage: 'New announcement' },
	newAsset: { id: 'global.newAsset', defaultMessage: 'New asset' },
	newCondition: { id: 'global.newCondition', defaultMessage: `Add new condition` },
	newName: { id: 'global.newName', defaultMessage: 'Enter the new name' },
	newNodeInfo: { id: 'global.newNodeInfo', defaultMessage: `New node "{id}" will be created` },
	newProject: { id: 'global.newProject', defaultMessage: 'Create a new project' },
	newPwd: { id: 'global.newPwd', defaultMessage: 'New password' },
	newVariable: { id: 'global.newVariable', defaultMessage: 'Add a new variable' },
	newVariableName: { id: 'global.newVariableName', defaultMessage: 'Variable name' },
	newVariableTooltip: {
		id: 'global.newVariableTooltip',
		defaultMessage:
			'Python code here. Type strings with double quotes (e.g. "Hi !"), NOT single quotes (e.g. \'Hi !\')',
	},
	newVariableValue: { id: 'global.newVariableValue', defaultMessage: 'Python code here.' },
	newerVersionFetchedWarning: {
		id: 'global.newerVersionFetchedWarning',
		defaultMessage: `There is a newer version of this model with the same version number ({maximumVersion}). It is likely somebody else updated the model in the meanwhile. Saving now will create diverging branches of the same model. Are you sure about this?`,
	},
	nextCallTime: { id: 'global.nextCallTime', defaultMessage: 'Next Call Time' },
	no: { id: 'global.no', defaultMessage: 'No' },
	noFlowSearchResults: {
		id: 'global.noFlowSearchResults',
		defaultMessage: 'No results found with the inputted query.',
	},
	noNodesSelectedNotification: {
		id: 'global.noNodesSelectedNotification',
		defaultMessage: 'No nodes starting with "{string}" were found!',
	},
	noProjectSelected: { id: 'global.noProjectSelected', defaultMessage: 'No project selected.' },
	noVersionSelected: { id: 'global.noVersionSelected', defaultMessage: 'No version selected.' },
	nodeIsHidden: { id: 'global.nodeIsHidden', defaultMessage: 'Node is hidden' },
	nodeIsVisible: { id: 'global.nodeIsVisible', defaultMessage: 'Node is visible' },
	nodeName: { id: 'global.nodeName', defaultMessage: 'Node name' },
	nodeNotAvailable: {
		id: 'global.nodeNotAvailable',
		defaultMessage:
			'The node must either be independent or belong to the same group or be a start node of another group.',
	},
	nodeWillBeCreated: { id: 'global.nodeWillBeCreated', defaultMessage: 'New node will be created !' },
	nodes: { id: 'global.nodes', defaultMessage: 'Nodes' },
	nodesAddedIntoNodeGroup: {
		id: 'global.nodesAddedIntoNodeGroup',
		defaultMessage: 'Nodes "{nodes}" added into nodeGroup "{nodeGroupId}"',
	},
	nodesInHistory: { id: 'global.nodesInHistory', defaultMessage: `node(s) in history` },
	nodesSelectedNotification: {
		id: 'global.nodesSelectedNotification',
		defaultMessage: '{count} nodes starting with "{string}" were selected.',
	},
	nonWorkingHrs: {
		id: 'global.nonWorkingHrs',
		defaultMessage: 'Call time is in non-working hrs. It should be between 8:00 and 18:30',
	},
	notApplicable: { id: 'global.notApplicable', defaultMessage: 'n/a' },
	notFound: { id: 'global.notFound', defaultMessage: '{value} NOT FOUND' },
	notResponding: { id: 'global.notResponding', defaultMessage: 'not responding' },
	notSelected: { id: 'global.notSelected', defaultMessage: 'Not selected' },
	notTrainedVersion: { id: 'global.notTrainedVersion', defaultMessage: 'This version is not trained' },
	numberOfNodesToGoBack: { id: 'global.numberOfNodesToGoBack', defaultMessage: `Number of nodes to go back` },
	number_claims: { id: 'global.number_claims', defaultMessage: 'Number of claims' },
	openAllIntents: { id: 'global.openAllIntents', defaultMessage: 'Open all in Intent Editor' },
	orChooseFromIntents: {
		id: 'global.orChooseFromIntents',
		defaultMessage: 'or choose from created intents',
	},
	orCreateNewVar: { id: 'global.orCreateNewVar', defaultMessage: 'or create a new variable here:' },
	organizationCreated: {
		id: 'global.organizationCreated',
		defaultMessage: 'Organization {name} was successfully created.',
	},
	organizationReassignPhoneNumberWarning: {
		id: 'global.organizationReassignPhoneNumberWarning',
		defaultMessage: "Assigning a number to an organization (even the same one) will reset that number's configuration!",
	},
	original: { id: 'global.original', defaultMessage: 'Original' },
	originalIntentThresholdItemDescription: {
		id: 'global.originalIntentThresholdItemDescription',
		defaultMessage: '...',
	},
	originalIntentThresholdItemHelp: { id: 'global.originalIntentThresholdItemHelp', defaultMessage: '...' },
	originalIntentThresholdItemTitle: {
		id: 'global.originalIntentThresholdItemTitle',
		defaultMessage: 'Original intent threshold',
	},
	outboundDetails: { id: 'global.outboundDetails', defaultMessage: 'Outbound details' },
	outputs: { id: 'global.outputs', defaultMessage: 'Chatbot says' },
	owner: { id: 'global.owner', defaultMessage: 'Owner' },
	password: { id: 'global.password', defaultMessage: 'Password' },
	passwordDoesNotContainSpecialChars: {
		id: 'global.passwordDoesNotContainSpecialChars',
		defaultMessage: "The password has to contain special characters (~!@#$%^&*_-+=`|\\()​​​​​​​'{}[]'​​​​:;\"'<>,.?/)",
	},
	passwordDoesNotContainsBaseDigits: {
		id: 'global.passwordDoesNotContainsBaseDigits',
		defaultMessage: 'The password has to contain base digits.',
	},
	passwordDoesNotContainsLowerChars: {
		id: 'global.passwordDoesNotContainsLowerChars',
		defaultMessage: 'The password has to contain lower characters.',
	},
	passwordDoesNotContainsUpperChars: {
		id: 'global.passwordDoesNotContainsUpperChars',
		defaultMessage: 'The password has to contain upper characters.',
	},
	passwordRequirments: {
		id: 'global.passwordRequirments',
		defaultMessage: 'The password needs to contain {numberOfNeeded} out of {numberOfMissing} following categories:',
	},
	passwordSameAsUsername: {
		id: 'global.passwordSameAsUsername',
		defaultMessage: 'The password cannot be a part of or the same as the username.',
	},
	passwordTooShort: {
		id: 'global.passwordTooShort',
		defaultMessage: 'The password has to be at least {length} characters long.',
	},
	passwordTooWeak: {
		id: 'global.passwordTooWeak',
		defaultMessage: 'The password is easily guessable. {warning} {suggestion}',
	},
	pauseCampaign: { id: 'global.pauseCampaign', defaultMessage: 'Pause campaign' },
	pausePhoneCallAudio: { id: 'global.pausePhoneCallAudio', defaultMessage: 'Pause phone call audio recording' },
	phoneNumber: { id: 'global.phoneNumber', defaultMessage: 'Phone Number' },
	phoneNumberFrom: { id: 'global.phoneNumberFrom', defaultMessage: 'From' },
	phoneNumberTo: { id: 'global.phoneNumberTo', defaultMessage: 'To' },
	phoneNumbers: { id: 'global.phoneNumbers', defaultMessage: 'Phone calls' },
	playPhoneCallAudio: { id: 'global.playPhoneCallAudio', defaultMessage: 'Play phone call audio recording' },
	possibleTopics: { id: 'global.possibleTopics', defaultMessage: 'Possible topics' },
	predictedState: { id: 'global.predictedState', defaultMessage: 'Predicted state' },
	predictions: { id: 'global.predictions', defaultMessage: 'Predictions' },
	preferences: { id: 'global.preferences', defaultMessage: 'Preferences' },
	preprocessedUtterance: { id: 'global.preprocessedUtterance', defaultMessage: 'Preprocessed Utterance' },
	preview: { id: 'global.preview', defaultMessage: 'Model Preview' },
	processing: { id: 'global.processing', defaultMessage: 'Testing utterances, please wait...' },
	products: { id: 'global.products', defaultMessage: 'Products' },
	project: { id: 'global.project', defaultMessage: 'Project' },
	projectDescription: { id: 'global.projectDescription', defaultMessage: 'Project description' },
	projectName: { id: 'global.projectName', defaultMessage: 'Project name' },
	projectNameCantChange: { id: 'global.projectNameCantChange', defaultMessage: 'Project name can not be changed !' },
	projectSettings: { id: 'global.projectSettings', defaultMessage: 'Project settings' },
	projectVersion: { id: 'global.projectVersion', defaultMessage: 'Version' },
	projectsAndModels: { id: 'global.projectsAndModels', defaultMessage: 'Projects and Linked Models' },
	ram: { id: 'global.ram', defaultMessage: 'RAM utilization' },
	ramDesc: {
		id: 'global.ramDesc',
		defaultMessage:
			'Storing saved models require a lot of memory. Lets have that in mind. See Memory utilization analytics.',
	},
	receivedAt: { id: 'global.receivedAt', defaultMessage: 'Received at' },
	recordCalls: { id: 'global.recordCalls', defaultMessage: 'Record calls' },
	redo: { id: 'global.redo', defaultMessage: 'Redo last action' },
	regExToAddNodes: { id: 'global.regExToAddNodes', defaultMessage: 'Type RegEx to add nodes' },
	remove: { id: 'global.remove', defaultMessage: 'Remove' },
	removeButton: { id: 'global.removeButton', defaultMessage: 'Remove button' },
	removeCondition: { id: 'global.removeCondition', defaultMessage: 'Remove condition' },
	removeConditionQuestion: {
		id: 'global.removeConditionQuestion',
		defaultMessage: 'Are you sure you want to delete the condition <strong>{name}</strong>?',
	},
	removeFallback: { id: 'global.removeFallback', defaultMessage: 'Remove fallback' },
	removeFallbackQuestion: {
		id: 'global.removeFallbackQuestion',
		defaultMessage: 'Are you sure you want to delete the continue / fallback <strong>{name}</strong>?',
	},
	removeFromSelection: { id: 'global.removeFromSelection', defaultMessage: 'Remove from selection' },
	removeGlobalStartNodeInGroupNotification: {
		id: 'global.removeGlobalStartNodeInGroupNotification',
		defaultMessage:
			'This group contains the global start node. Please select a node from a different group before removing the group.',
	},
	removeGlobalStartNodeNotification: {
		id: 'global.removeGlobalStartNodeNotification',
		defaultMessage: 'You cannot remove the global start node. Please select a different node before removing this one.',
	},
	removeGroupNodeQuestion: {
		id: 'global.removeGroupNodeQuestion',
		defaultMessage: 'Do you want to remove the node <strong>{name}</strong> from this group ?',
	},
	removeGroupStartNodeNotification: {
		id: 'global.removeGroupStartNodeNotification',
		defaultMessage:
			'You cannot remove the group start node. Please select a different node before removing this one. You can also remove the whole group including this node.',
	},
	removeIntent: { id: 'global.removeIntent', defaultMessage: 'Remove intent' },
	removeIntentQuestion: {
		id: 'global.removeIntentQuestion',
		defaultMessage: 'Are you sure you want to delete the intent <strong>{name}</strong>?',
	},
	removeLastGroupNotification: {
		id: 'global.removeLastGroupNotification',
		defaultMessage: 'At least one group of nodes has to always exist.',
	},
	removeNode: { id: 'global.removeNode', defaultMessage: 'Remove node' },
	removeNodeGroup: { id: 'global.removeNodeGroup', defaultMessage: 'Remove group' },
	removeNodeGroupAndNodes: {
		id: 'global.removeNodeGroupAndNodes',
		defaultMessage: 'Remove group and all of its nodes',
	},
	removeNodeGroupQuestion: {
		id: 'global.removeNodeGroupQuestion',
		defaultMessage: 'Are you sure you want to delete the group <strong>{name}</strong> and all of its nodes?',
	},
	removeSignal: { id: 'global.removeSignal', defaultMessage: 'Remove signal' },
	removeSignalQuestion: {
		id: 'global.removeSignalQuestion',
		defaultMessage: 'Are you sure you want to delete the continue / signal <strong>{name}</strong>?',
	},
	removeUser: { id: 'global.removeUser', defaultMessage: 'Remove user' },
	removeUtterance: { id: 'global.removeUtterance', defaultMessage: 'Remove utterance' },
	renameVariable: { id: 'global.renameVariable', defaultMessage: 'Rename variable' },
	repeat: { id: 'global.repeat', defaultMessage: 'Repeat' },
	repeatCallDelayInMinutes: { id: 'global.repeatCallDelayInMinutes', defaultMessage: 'Rep. call delay (mins.)' },
	repeatCallsAt: { id: 'global.repeatCallsAt', defaultMessage: 'Repeat calls at' },
	repeatDefinitelyInDays: {
		id: 'global.repeatDefinitelyInDays',
		defaultMessage: 'Repeating up until {endsAt}, in days: {days}.',
	},
	repeatInfinitely: { id: 'global.repeatInfinitely', defaultMessage: 'Repeating infinitely' },
	repeatInfinitelyInDays: {
		id: 'global.repeatInfinitelyInDays',
		defaultMessage: 'Repeating infinitely, in days: {days}.',
	},
	repeatPwd: { id: 'global.repeatPwd', defaultMessage: 'Repeat password' },
	repeatUpUntil: { id: 'global.repeatUpUntil', defaultMessage: 'Repeating up until' },
	replaceWith: { id: 'global.replaceWith', defaultMessage: 'Replace with' },
	reply: { id: 'global.reply', defaultMessage: 'Reply' },
	requestError: {
		id: 'global.requestError',
		defaultMessage:
			'There was an unexpected error while calling the API. Try to repeat your request in a few minutes. ' +
			'Please, if the problem persists report it to your maintainer with the following details: {details}',
	},
	requestKey: { id: 'global.requestKey', defaultMessage: 'Request key' },
	resetDiagramPositions: { id: 'global.resetDiagramPositions', defaultMessage: 'Reset positions' },
	resetVariables: { id: 'global.resetVariables', defaultMessage: 'Reset variables' },
	resetVariablesModalInfo_all: {
		id: 'global.resetVariablesModalInfo_all',
		defaultMessage: 'Resets all existing variables',
	},
	resetVariablesModalInfo_except: {
		id: 'global.resetVariablesModalInfo_except',
		defaultMessage: 'Reset all existing variable except for the listed here',
	},
	resetVariablesModalInfo_including: {
		id: 'global.resetVariablesModalInfo_including',
		defaultMessage: 'Reset all variables listed here',
	},
	resetVariables_all: { id: 'global.resetVariables_all', defaultMessage: 'Reset all variables' },
	resetVariables_except: { id: 'global.resetVariables_except', defaultMessage: 'Reset all variables except:' },
	resetVariables_including: { id: 'global.resetVariables_including', defaultMessage: 'Reset these variables:' },
	result: { id: 'global.result', defaultMessage: 'Result' },
	saturday: { id: 'global.saturday', defaultMessage: 'Saturday' },
	save: { id: 'global.save', defaultMessage: 'Save' },
	saveAsset: { id: 'global.create', defaultMessage: 'Save asset' },
	saveCampaign: { id: 'global.saveCampaign', defaultMessage: 'Save campaign' },
	saveChanges: { id: 'global.saveChanges', defaultMessage: 'Save Changes' },
	saveModelVersion: { id: 'global.saveModelVersion', defaultMessage: 'Save model version' },
	saveResponses: { id: 'global.saveResponses', defaultMessage: 'Responses as CSV' },
	saveCompleteConfig: { id: 'global.saveCompleteConfig', defaultMessage: 'Save complete configuration' },
	saveToCsvFile: { id: 'global.saveToCsvFile', defaultMessage: 'Download as CSV file' },
	saveToCsvFileError: {
		id: 'global.saveToCsvFileError',
		defaultMessage: 'There was an error downloading the CSV file.',
	},
	saveToFile: { id: 'global.saveToFile', defaultMessage: 'Export' },
	saveToPdfFile: { id: 'global.saveToPdfFile', defaultMessage: 'PDF' },
	saveToYamlFile: { id: 'global.saveToYamlFile', defaultMessage: 'YAML' },
	saved: { id: 'global.saved', defaultMessage: 'Saved' },
	saving: { id: 'global.saving', defaultMessage: 'Saving ...' },
	searchCampaigns: { id: 'global.searchCampaigns', defaultMessage: 'Search campaigns' },
	searchIntent: { id: 'global.searchIntent', defaultMessage: 'Search intent' },
	searchMetadata: { id: 'global.searchMetadata', defaultMessage: 'Search Metadata by Name' },
	searchModel: {
		id: 'global.searchModel',
		defaultMessage: 'Search model by name, description or configHash (starts with "cfg-")',
	},
	searchNodes: { id: 'global.searchNodes', defaultMessage: 'Search and select nodes' },
	searchOperator: { id: 'global.searchOperator', defaultMessage: 'Search operator' },
	searchPhoneCalls: { id: 'global.searchPhoneCalls', defaultMessage: 'Search phone calls' },
	searchString: { id: 'global.searchString', defaultMessage: 'Search' },
	select: { id: 'global.select', defaultMessage: 'Use this model' },
	selectEmail: { id: 'global.selectEmail', defaultMessage: 'Select email' },
	selectExistVariable: { id: 'global.selectExistVariable', defaultMessage: 'Please select an existing variable:' },
	selectModelFirst: { id: 'global.selectModelFirst', defaultMessage: 'Please select model first' },
	selectNodesFirst: {
		id: 'global.selectNodesFirst',
		defaultMessage: 'Before saving a group of nodes, please select its nodes first.',
	},
	selectOrganization: { id: 'global.selectOrganization', defaultMessage: 'Organization' },
	selectPhoneNumbers: { id: 'global.selectPhoneNumbers', defaultMessage: 'Assign an already created phone number' },
	selectSmartFunction: { id: 'global.selectSmartFunction', defaultMessage: 'Please select smart function name' },
	selectUser: { id: 'global.selectUser', defaultMessage: 'Select user' },
	selectUserRole: { id: 'global.selectUserRole', defaultMessage: 'User role' },
	selectUtterance: { id: 'global.selectUtterance', defaultMessage: `Utterance` },
	selectVariable: { id: 'global.selectVariable', defaultMessage: 'Select variable' },
	selectVersion: { id: 'global.selectVersion', defaultMessage: 'Select version' },
	selectVersionBeforeTraining: {
		id: 'global.selectVersionBeforeTraining',
		defaultMessage:
			'Please save YAML as project version before training it. A project has to be selected to save the version.',
	},
	selected: { id: 'global.selected', defaultMessage: 'Selected' },
	selectedProject: { id: 'global.selectedProject', defaultMessage: 'Selected project' },
	selectedVersion: { id: 'global.selectedVersion', defaultMessage: 'Selected version' },
	semanticallySimilarIntents: {
		id: 'global.label.semanticallySimilarIntents',
		defaultMessage: 'Semantically Similar Intents',
	},
	sender: { id: 'global.sender', defaultMessage: 'Sender' },
	sentiment: { id: 'global.sentiment', defaultMessage: 'Sentiment' },
	setAsGlobalStartNode: {
		id: 'global.setAsGlobalStartNode',
		defaultMessage: 'Set as the start node of the whole model',
	},
	setAsGroupStartNode: { id: 'global.setAsGroupStartNode', defaultMessage: 'Set as group start node' },
	setCallStatuses: { id: 'global.setCallStatuses', defaultMessage: 'Repeat calls that finish with these statuses' },
	setColorsFirst: {
		id: 'global.setColorsFirst',
		defaultMessage:
			'Please set colors for nodes first. If they are set and you are seeing this message, resetting the color of any node should be enough.',
	},
	setRepeatCallDatesManually: {
		id: 'global.setRepeatCallDatesManually',
		defaultMessage: 'Specify when to repeat calls',
	},
	settings: { id: 'global.settings', defaultMessage: 'Settings' },
	showActiveNode: { id: 'global.showActiveNode', defaultMessage: 'Show the active node' },
	showActiveNodeDetails: { id: 'global.showActiveNodeDetails', defaultMessage: 'Show details of the active node' },
	showAll: { id: 'global.showAll', defaultMessage: 'Show all nodes' },
	showColorCaptions: { id: 'global.showColorCaptions', defaultMessage: 'Display captions on the screen' },
	showErrors: { id: 'global.showErrors', defaultMessage: 'Show errors and warnings' },
	signals: { id: 'global.signals', defaultMessage: 'Signals' },
	sipAccount: { id: 'global.sipAccount', defaultMessage: 'Sip account' },
	slackOutput: { id: 'global.slackOutput', defaultMessage: 'Includes Slack output' },
	smartFunction: { id: 'global.smartFunction', defaultMessage: 'Includes a smart function' },
	smartFunctionInput: { id: 'global.smartFunctionInput', defaultMessage: 'Input' },
	smartFunctionName: { id: 'global.smartFunctionName', defaultMessage: 'Name' },
	smartFunctionNameMismatch: {
		id: 'global.smartFunctionNameMismatch',
		defaultMessage: 'The function name mismatch!',
	},
	smartFunctionOutput: { id: 'global.smartFunctionOutput', defaultMessage: 'Output' },
	smartFunctions: { id: 'global.smartFunctions', defaultMessage: 'Smart functions' },
	smartFunctionsAndVars: { id: 'global.smartFunctionsAndVars', defaultMessage: 'Smart functions & variables' },
	sort: { id: 'global.sort', defaultMessage: 'Sort' },
	sourceNode: { id: 'global.sourceNode', defaultMessage: 'Source node' },
	specifyPassword: { id: 'global.specifyPassword', defaultMessage: 'Specify password' },
	specifyPasswordInfo: {
		id: 'global.specifyPasswordInfo',
		defaultMessage: 'Email to set up password will be sent if not specified',
	},
	speedCoefficientItemDescription: {
		id: 'global.speedCoefficientItemDescription',
		defaultMessage: 'The smaller the coefficient, the faster new messages will be sent by the Chatbot',
	},
	speedCoefficientItemTitle: { id: 'global.speedCoefficientItemTitle', defaultMessage: 'Speed coefficient' },
	splitNode: { id: 'global.splitNode', defaultMessage: 'Split node' },
	splittedNodeTail: { id: 'global.splittedNodeTail', defaultMessage: '_SPLITTED' },
	ssiGroup: { id: 'global.ssiGroup', defaultMessage: 'SSI group' },
	startDate: { id: 'global.startDate', defaultMessage: 'Start date' },
	startState: { id: 'global.startState', defaultMessage: 'Start state' },
	startTime: { id: 'global.startTime', defaultMessage: 'Start time' },
	startTrainingModel: { id: 'global.startTraining', defaultMessage: 'Train' },
	startsWith: { id: 'global.startsWith', defaultMessage: 'Starts with' },
	statistics: { id: 'global.statistics', defaultMessage: 'Statistics' },
	status: { id: 'global.status', defaultMessage: 'Status' },
	statusSectionDescription: { id: 'global.statusSectionDescription', defaultMessage: 'Status: ' },
	statusSectionTitle: { id: 'global.statusSectionTitle', defaultMessage: 'Status of linked applications' },
	statusesToMessage: { id: 'global.statusesToMessage', defaultMessage: 'Statuses to message' },
	stopCampaign: { id: 'global.stopCampaign', defaultMessage: 'Stop campaign' },
	stopwordsItemDescription: { id: 'global.stopwordsItemDescription', defaultMessage: '...' },
	stopwordsItemHelp: {
		id: 'global.stopwordsItemHelp',
		defaultMessage: 'Remove words without meaning from utterances',
	},
	stopwordsItemTitle: { id: 'global.stopwordsItemTitle', defaultMessage: 'Stopwords' },
	sttBoost: { id: 'global.sttBoost', defaultMessage: 'STT Boost' },
	sttBoostModal: { id: 'global.sttBoostModal', defaultMessage: 'Google STT Boost' },
	sttProvider: { id: 'global.sttProvider', defaultMessage: 'Speech to Text provider' },
	subject: { id: 'global.subject', defaultMessage: 'Subject' },
	summaryProjectFewSentences: {
		id: 'global.summaryProjectFewSentences',
		defaultMessage: 'Summary of the project in a few sentences',
	},
	sunday: { id: 'global.sunday', defaultMessage: 'Sunday' },
	syncStatus: { id: 'global.syncStatus', defaultMessage: 'Sync status' },
	synthetise: { id: 'global.synthetise', defaultMessage: 'Synthetise' },
	targetCount: { id: 'global.targetCount', defaultMessage: 'This intent targets other {count} nodes' },
	targetNode: { id: 'global.targetNode', defaultMessage: 'Target node' },
	targetNodeTooltip: {
		id: 'global.targetNodeTooltip',
		defaultMessage: 'Node which is the target of a GOTO transition from a node referenced in ACTIONS of the originNode',
	},
	targetNodes: { id: 'global.targetNodes', defaultMessage: 'These intents target nodes: ' },
	targetState: { id: 'global.targetState', defaultMessage: 'Target state' },
	technicalLog: { id: 'global.technicalLog', defaultMessage: 'Technical Dashboard' },
	telephonyProvider: { id: 'global.telephonyProvider', defaultMessage: 'Telephony provider' },
	test: { id: 'global.test', defaultMessage: 'Testing translation' },
	testingTools: { id: 'global.testingTools', defaultMessage: 'Testing' },
	text: { id: 'global.text', defaultMessage: 'Text' },
	textClassification: { id: 'global.textClassification', defaultMessage: 'Text Classification' },
	textClassificationHeadline: {
		id: 'global.textClassificationHeadline',
		defaultMessage: 'Zero Shot Text Classification Demo',
	},
	textForClassification: { id: 'global.textForClassification', defaultMessage: 'Text to classify' },
	textOutput: { id: 'global.textOutput', defaultMessage: 'Includes text output' },
	textToBeSynthetised: { id: 'global.textToBeSynthetised', defaultMessage: 'Text for synthetisation' },
	thursday: { id: 'global.thursday', defaultMessage: 'Thursday' },
	timeInterval: { id: 'global.timeInterval', defaultMessage: 'Time interval' },
	tokenizerItemDescription: {
		id: 'global.tokenizerItemDescription',
		defaultMessage: 'Splits sentences into lists of individual words',
	},
	tokenizerItemHelp: { id: 'global.tokenizerItemHelp', defaultMessage: 'Possible values: ...' },
	tokenizerItemTitle: { id: 'global.tokenizerItemTitle', defaultMessage: 'Tokenizer' },
	total: { id: 'global.total', defaultMessage: 'Total' },
	trainVersion: { id: 'global.trainVersion', defaultMessage: 'Train version' },
	trainVersionToSeeConfig: {
		id: 'global.trainVersionToSeeConfig',
		defaultMessage: 'To see the detailed config information of this version, please, train it first',
	},
	trained: { id: 'global.trained', defaultMessage: 'Trained' },
	trainedVersion: { id: 'global.trainedModel', defaultMessage: 'This version is trained' },
	training: { id: 'global.training', defaultMessage: 'Training' },
	transitionType: { id: 'global.transitionType', defaultMessage: 'Type' },
	transitionValue: { id: 'global.transitionValue', defaultMessage: 'Value' },
	transitions: { id: 'global.transitions', defaultMessage: 'Transitions' },
	transitionsTabsIn: { id: 'global.transitionsTabsIn', defaultMessage: 'In' },
	transitionsTabsOut: { id: 'global.transitionsTabsOut', defaultMessage: 'Out' },
	translate: { id: 'global.translate', defaultMessage: 'Translate' },
	trials: { id: 'global.trials', defaultMessage: 'Trials' },
	trunkAccount: { id: 'global.trunkAccount', defaultMessage: 'Trunk account' },
	ttsPitch: { id: 'global.ttsPitch', defaultMessage: 'Voice pitch' },
	ttsProvider: { id: 'global.ttsProvider', defaultMessage: 'Text to Speech provider' },
	ttsRate: { id: 'global.ttsRate', defaultMessage: 'Speaking rate' },
	tuesday: { id: 'global.tuesday', defaultMessage: 'Tuesday' },
	twilioFlowId: { id: 'global.twilioFlowId', defaultMessage: 'Twilio flow ID' },
	twilioNumber: { id: 'global.twilioNumber', defaultMessage: 'Twilio number' },
	type: { id: 'global.type', defaultMessage: 'Type' },
	typeItemDescription: { id: 'global.typeItemDescription', defaultMessage: '...' },
	typeItemHelp: { id: 'global.typeItemHelp', defaultMessage: '...' },
	typeItemTitle: { id: 'global.typeItemTitle', defaultMessage: 'Type' },
	typeNodeId: { id: 'global.typeNodeId', defaultMessage: 'Type node id' },
	unassignPhoneNumber: {
		id: 'global.unassignPhoneNumber',
		defaultMessage: 'Unassign this phone number from this model version (but keep its other configuration).',
	},
	unassigned: { id: 'global.unassigned', defaultMessage: 'Unassigned' },
	undo: { id: 'global.undo', defaultMessage: 'Undo last action' },
	unfoldCategories: { id: 'global.unfoldCategories', defaultMessage: 'Unfold categories' },
	unfoldIntents: { id: 'global.unfoldIntents', defaultMessage: 'Unfold intents' },
	unlimited: { id: 'global.unlimited', defaultMessage: 'Unlimited' },
	unlockUserAccount: { id: 'global.unlockUserAccount', defaultMessage: 'Unlock account' },
	untrainSelected: { id: 'global.untrainSelected', defaultMessage: 'Untrain selected' },
	untrainVersion: { id: 'global.untrainVersion', defaultMessage: 'Untrain version' },
	unversionedModel: { id: 'global.unversionedModel', defaultMessage: 'Unversioned' },
	upAndRunning: { id: 'global.upAndRunning', defaultMessage: 'up and running' },
	updateCampaign: { id: 'global.updateCampaign', defaultMessage: 'Update campaign' },
	updateIntent: { id: 'global.updateIntent', defaultMessage: 'Update intent' },
	updateTransition: { id: 'global.updateTransition', defaultMessage: 'Update transition' },
	updated: { id: 'global.updated', defaultMessage: 'Updated' },
	upload: { id: 'global.upload', defaultMessage: 'Upload' },
	uploadCampaign: { id: 'global.uploadCampaign', defaultMessage: 'Upload campaign' },
	uploadImage: { id: 'global.uploadImage', defaultMessage: 'Upload image' },
	uploadInstructionsCopyPaste: {
		id: 'global.uploadInstructionsCopyPaste',
		defaultMessage:
			' Or simply <span><kbd>Ctrl+A</kbd>, <kbd>Ctrl+C</kbd>, <kbd>Ctrl+V</kbd></span> the data from the excel.',
	},
	uploadInstructionsFile: {
		id: 'global.uploadInstructionsFile',
		defaultMessage:
			'testing scenario as CSV file (<code>tab</code> delimited, <code>^</code> separator) with 2 or 3 columns: <code>start_state</code>, <code>utterance</code> (both required) and <code>target_state</code> (optional).',
	},
	uploadProject: {
		id: 'global.uploadProject',
		defaultMessage: 'Upload a project ZIP',
	},
	uploadProjectWarning: {
		id: 'global.uploadProjectWarning',
		defaultMessage: 'Upload a new project ZIP. This may take a while.',
	},
	use: { id: 'global.use', defaultMessage: 'Use' },
	useALawAudioFormat: { id: 'global.useALawAudioFormat', defaultMessage: 'Use A-Law 8bit 8kHZ audio format' },
	useIntentAndUtterances: { id: 'global.useIntentAndUtterances', defaultMessage: "Use intent and it's utterances" },
	useLastVersion: { id: 'global.useLastVersion', defaultMessage: 'Use last version' },
	useMessages: { id: 'global.useMessages', defaultMessage: 'Send messages to calls with specified statuses' },
	usePythonEditor: { id: 'global.usePythonEditor', defaultMessage: 'Use Python editor' },
	useThisProject: { id: 'global.useThisProject', defaultMessage: 'Use this project' },
	useVersion: { id: 'global.useVersion', defaultMessage: 'Use version' },
	usedCount: { id: 'global.usedCount', defaultMessage: 'This intent is used in another {count} nodes' },
	usedInNode: { id: 'global.usedInNode', defaultMessage: 'Used in node' },
	usedInNodeTooltip: {
		id: 'global.usedInNodeTooltip',
		defaultMessage: 'Node(s) where the intent originates from, i.e. is included in ACTIONS',
	},
	usedNodes: { id: 'global.usedNodes', defaultMessage: 'This intent combination is used in nodes: ' },
	user: { id: 'global.user', defaultMessage: 'User' },
	userAccountUnlocked: {
		id: 'global.userAccountUnlocked',
		defaultMessage: 'The account with the name "{name}" has been unlocked. Logging in can be attempted once again.',
	},
	userConfiguration: { id: 'global.userConfiguration', defaultMessage: 'User Configuration' },
	userCreated: { id: 'global.userCreated', defaultMessage: 'User {user} was successfully created.' },
	userDoesntExist: { id: 'global.userDoesntExist', defaultMessage: "User doesn't exist." },
	userNoInputLimit: { id: 'global.userNoInputLimit', defaultMessage: 'No input from user limit' },
	userRights: { id: 'global.userRights', defaultMessage: 'User rights' },
	usingLastVersion: { id: 'global.usingLastVersion', defaultMessage: 'Using last version' },
	usingThisProject: { id: 'global.usingThisProject', defaultMessage: 'Using this project' },
	utilities: { id: 'global.utilities', defaultMessage: 'Utilities' },
	utterance: { id: 'global.utterance', defaultMessage: 'Utterance' },
	utteranceAlreadyExists: { id: 'global.utteranceAlreadyExists', defaultMessage: 'Utterance already exists.' },
	utteranceNotReachable: {
		id: 'global.utteranceNotReachable',
		defaultMessage: "This utterance is not reachable at this node, please make sure it's valid !",
	},
	utteranceSample: { id: 'global.utterances', defaultMessage: 'Utterances' },
	utteranceTester: { id: 'global.utteranceTester', defaultMessage: 'Utterance Tester' },
	utteranceTesterStats: {
		id: 'global.utteranceTesterStats',
		defaultMessage:
			'Score: {errors} errors, {fails} fails, {unknowns} unknowns, {ssiMatches} SSI matches, {matches} matches',
	},
	utterances: { id: 'global.utterances', defaultMessage: 'Utterances' },
	valid: { id: 'global.valid', defaultMessage: 'Valid' },
	validationError: { id: 'global.validationError', defaultMessage: '{error}' },
	validationInProgress: { id: 'global.validationInProgress', defaultMessage: 'Still validating...' },
	validationResult: { id: 'global.validationResult', defaultMessage: '{issuesCount} issues found' },
	valueDiffers: { id: 'global.valueDiffers', defaultMessage: 'Values are not the same.' },
	varName: { id: 'global.varName', defaultMessage: 'Variable name' },
	varValue: { id: 'global.varValue', defaultMessage: 'Variable value' },
	variable: { id: 'global.variable', defaultMessage: 'Includes a variable' },
	variableDoesntExist: { id: 'global.variableDoesntExist', defaultMessage: "Variable doesn't exist!" },
	variables: { id: 'global.variables', defaultMessage: 'Variables' },
	variablesToReset: { id: 'global.variablesToReset', defaultMessage: 'Variables to reset' },
	variablesToResetType: { id: 'global.variablesToResetType', defaultMessage: 'Reset type' },
	variant: { id: 'global.variant', defaultMessage: 'Variant' },
	version: { id: 'global.version', defaultMessage: 'Version' },
	versionDesc: { id: 'global.versionDesc', defaultMessage: 'Version description' },
	versionInUse: { id: 'global.versionInUse', defaultMessage: 'In use' },
	versionSaved: { id: 'global.versionSaved', defaultMessage: 'Model was saved with version {version}' },
	versionUsed: { id: 'global.versionUsed', defaultMessage: 'Version' },
	viewer: { id: 'global.viewer', defaultMessage: 'Viewer' },
	visibility: { id: 'global.visibility', defaultMessage: 'Visibility' },
	vocabularyExamples: { id: 'global.vocabularyExamples', defaultMessage: 'Example values:' },
	vocabularyIcon: { id: 'global.vocabularyIcon', defaultMessage: 'Edit vocabulary' },
	vocabularyImportDuplicatesRemovedWarning: {
		id: 'global.vocabularyImportDuplicatesRemovedWarning',
		defaultMessage: 'Duplicate entries: {keys}',
	},
	vocabularyImportOverwriteDuplicateKeys: {
		id: 'global.vocabularyImportOverwriteDuplicateKeys',
		defaultMessage: 'Overwrite duplicate keys on import',
	},
	vocabularyKey: { id: 'global.vocabularyKey', defaultMessage: 'Key' },
	vocabularyKeyAlreadyExists: {
		id: 'global.vocabularyKeyAlreadyExists',
		defaultMessage: 'This vocabulary key already exists.',
	},
	vocabularyModal: { id: 'global.vocabularyModal', defaultMessage: 'Vocabulary' },
	vocabularyValue: { id: 'global.vocabularyValue', defaultMessage: 'Value' },
	voiceName: { id: 'global.voiceName', defaultMessage: 'Voice' },
	voiceOutput: { id: 'global.voiceOutput', defaultMessage: 'Includes voice output' },
	voiceSynthesis: { id: 'global.voiceSynthesis', defaultMessage: 'Voice Synthesis' },
	volume: { id: 'global.volume', defaultMessage: 'Volume' },
	waitingForNextRound: { id: 'global.waitingForNextRound', defaultMessage: 'Waiting for the next round' },
	warning: { id: 'global.warning', defaultMessage: 'Warning' },
	warningUntrainedModel: {
		id: 'global.warningUntrainedModel',
		defaultMessage:
			'Please <nav-select>select existing model</nav-select> or <nav-train>train the new model</nav-train> from YAML file before uploading your CSV.',
	},
	warnings: { id: 'global.warnings', defaultMessage: 'Warnings' },
	wednesday: { id: 'global.wednesday', defaultMessage: 'Wednesday' },
	weekend: { id: 'global.weekend', defaultMessage: 'The call date is on weekend' },
	whitelist: { id: 'global.whitelist', defaultMessage: 'Whitelist' },
	wholeWeek: { id: 'global.wholeWeek', defaultMessage: 'Whole week' },
	widgetType: { id: 'global.widgetType', defaultMessage: 'Widget type' },
	wrongNumberFormat: {
		id: 'global.wrongNumberFormat',
		defaultMessage: 'Incorrect number format (E.g. +421911222333)',
	},
	wrongSipAccountFormat: {
		id: 'global.wrongSipAccountFormat',
		defaultMessage: 'Incorrect Sip account format (E.g. sip:14@ovbslovakia.centrex.wia.cz)',
	},
	wrongTwilioFlowIdFormat: {
		id: 'global.wrongTwilioFlowIdFormat',
		defaultMessage: 'Incorrect Twilio flow id format (E.g. FW6aac4704070ae187184c4de4984b6606)',
	},
	yamlDiff: { id: 'global.yamlDiff', defaultMessage: 'Show changes' },
	yamlErrorsModal: { id: 'global.yamlErrorsModal', defaultMessage: 'Errors and warnings in YAML' },
	yamlFormatted: { id: 'global.yamlFormatted', defaultMessage: 'Unformat YAML' },
	yamlMissingFlowNode: {
		id: 'global.yamlMissingFlowNode',
		defaultMessage: 'Missing key "{flow}" in YAML. Please, add it with one node at least.',
	},
	yamlMissingGlobalStartNode: {
		id: 'global.yamlMissingGlobalStartNode',
		defaultMessage: 'Missing start node for the whole model. Please add it at "configuration.{globalStartNode}".',
	},
	yamlNodeIdsInvalid: {
		id: 'global.yamlNodeIdsInvalid',
		defaultMessage:
			'Some nodeIds are not valid. Please, change the following nodeIds to contain only UPPER letters, ' +
			'numbers and use underscore character as a word separator. Examples START_NODE, DEPARTMENT_007, {node}',
	},
	yamlNodePropertyInvalid: {
		id: 'global.yamlNodePropertyInvalid',
		defaultMessage:
			'Unknown property type {property}. The property will be ignored. Please report this to your maintainer',
	},
	yamlNormalizedNodeIds: {
		id: 'global.yamlNormalizedNodeIds',
		defaultMessage: 'Some nodeIds were automatically normalized to conform to our convention: {node}',
	},
	yamlParsingError: {
		id: 'global.yamlParsingError',
		defaultMessage:
			'Internal error while parsing the YAML. Please check the console and report its output and the file being imported to your maintainer: {error}',
	},
	yamlParsingFailed: {
		id: 'global.yamlParsingFailed',
		defaultMessage: 'The given YAML could not be parsed for unknown reason. Please report the YAML to your maintainer.',
	},
	yamlUnknownError: {
		id: 'global.yamlUnknownError',
		defaultMessage: 'Found errors in YAML file. Use "Show errors" button in YAML editor to see them.',
	},
	yes: { id: 'global.yes', defaultMessage: 'Yes' },
	zoomIn: { id: 'global.zoomIn', defaultMessage: 'Zoom In' },
	zoomOne: { id: 'global.zoomOne', defaultMessage: 'Zoom level 1' },
	zoomOut: { id: 'global.zoomOut', defaultMessage: 'Zoom Out' },
	zoomThree: { id: 'global.zoomThree', defaultMessage: 'Zoom level 3' },
	zoomTwo: { id: 'global.zoomTwo', defaultMessage: 'Zoom level 2' },
	createSipAccount: { id: 'global.createSipAccount', defaultMessage: 'Create SIP account' },
	accountUri: { id: 'global.accountUri', defaultMessage: 'Account URI' },
	domain: { id: 'global.domain', defaultMessage: 'Domain' },
	registral: { id: 'global.registral', defaultMessage: 'Registral' },
	registralInfo: { id: 'global.registralInfo', defaultMessage: 'Should start with "sip:"' },
	registration: { id: 'global.registration', defaultMessage: 'Registration' },
	protocol: { id: 'global.protocol', defaultMessage: 'Protocol' },
	accountStatus: { id: 'global.accountStatus', defaultMessage: 'Account status' },
	srtp: { id: 'global.srtp', defaultMessage: 'SRTP' },
	username: { id: 'global.username', defaultMessage: 'Username' },
	voiceConnectorInstance: { id: 'global.voiceConnectorInstance', defaultMessage: 'Voice connector instance' },
	organizationId: { id: 'global.organizationId', defaultMessage: 'Organization ID' },
	deleteSipAccount: { id: 'global.deleteSipAccount', defaultMessage: 'Delete SIP Account' },
	updateSipAccount: { id: 'global.updateSipAccount', defaultMessage: 'Update SIP Account' },
	deleteModalMessage: {
		id: 'global.deleteModalMessage',
		defaultMessage: 'Are you sure you want to delete this?',
	},
	deleteModalButton: {
		id: 'global.deleteModalButton',
		defaultMessage: 'Delete',
	},
});
