import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../intl';
import { importModelFromFile } from '../redux/model/actions';
import { getRawYaml } from '../redux/model/selectors';
import LSM from '../utils/LocalStorageManager';

export const uiSquareButtonStyles = {
	minWidth: 40,
	width: 40,
	height: 30,
	padding: 0,
	margin: '0 5px',
};

export const FlowSearchButton = ({ onClick }) => (
	<>
		<label onClick={onClick}>
			<Tooltip title={<FormattedMessage {...messages.flowSearch} />} placement="top">
				<Button variant="contained" component="span" color="primary" style={uiSquareButtonStyles}>
					<SearchIcon />
				</Button>
			</Tooltip>
		</label>
	</>
);

export const AutoSaveButton = ({ placement, showIconOnly }) => {
	const dispatch = useDispatch();
	const data = useSelector(getRawYaml);

	const dataInStorage = LSM.getAutosaveData();
	if (!dataInStorage || dataInStorage === data) {
		return null;
	}

	const textLabel = <FormattedMessage {...messages.loadPreviousData} />;
	return (
		<>
			<label onClick={() => dispatch(importModelFromFile(dataInStorage))}>
				<Tooltip title={showIconOnly ? textLabel : ''} placement={placement || 'top'}>
					<Button
						variant="contained"
						component="span"
						color="primary"
						style={showIconOnly ? uiSquareButtonStyles : null}
					>
						<>
							<RestoreIcon />
							{Boolean(showIconOnly) || textLabel}
						</>
					</Button>
				</Tooltip>
			</label>
		</>
	);
};

export const ProjectSettingsButton = ({ onClick }) => (
	<>
		<label onClick={onClick}>
			<Tooltip title={<FormattedMessage {...messages.projectSettings} />} placement="top">
				<Button variant="contained" component="span" color="primary" style={uiSquareButtonStyles}>
					<SettingsIcon />
				</Button>
			</Tooltip>
		</label>
	</>
);

FlowSearchButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

AutoSaveButton.propTypes = {
	placement: PropTypes.string,
	showIconOnly: PropTypes.bool,
};

ProjectSettingsButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};
