import { print } from 'graphql/language/printer';
import { RSAA } from 'redux-api-middleware';

export const AUTH_CALL_API = Symbol('Call Auth API');

export const rsaaAuthFormData = (resource, types, formData) => ({
	[AUTH_CALL_API]: {
		endpoint: `${process.env.GRAPHQL_URL}/${resource}`,
		method: 'POST',
		body: formData,
		types,
	},
});

export const qlRequest = ([pending, originalSuccess, fail], data) => {
	data = {
		query: print(data.query),
		variables: data.variables,
	};

	const rsaa = {
		endpoint: `${process.env.GRAPHQL_URL}/graphql`,
		credentials: 'include', // IMPORTANT: accepts cookie with the refresh token
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
		method: 'POST',
		body: JSON.stringify(data),
		types: [pending, originalSuccess, fail],
	};
	let success = originalSuccess;
	if (typeof originalSuccess === 'string') {
		success = { type: originalSuccess };
	}
	success = {
		...success,
		meta: { ...success.meta, originalRSAA: rsaa },
		payload: async (action, state, response) => {
			const json = await response.clone().json();
			if (originalSuccess.payload && !json.errors) {
				return originalSuccess.payload(action, state, response);
			} else {
				// Transform all errors with HTTP status 200 from GraphQL API into FAILED actions. See `apiErrorHandler`.
				// TODO: remove/refactor after https://trello.com/c/vz8QyAno/352-graphql-return-proper-http-status-on-error
				return json;
			}
		},
	};

	return {
		[RSAA]: {
			...rsaa,
			types: [pending, success, fail],
		},
	};
};

export const qlAuthRequest = (types, data) => {
	const rsaa = qlRequest(types, data);
	return { [AUTH_CALL_API]: rsaa[RSAA] };
};
