import {
	AddEditConditionModal,
	AddEditGotoModal,
	AddEditIntentModal,
	AddEditSignalModal,
	AddEditSmartFunctionModal,
	AddEditVariableModal,
	AddIntentFromLibraryModal,
	AddMarkdownOptionModal,
	AddRemoveGroupNodeModal,
	AddUserRightsModal,
	AddWidgetModal,
	CampaignPhoneCallFilterModal,
	ColorCaptionsModal,
	CreateEditCopyCampaignModal,
	CreateEditTransitionModal,
	CreateNewModelModal,
	CreateNodeGroupModal,
	DiffYamlModal,
	DownloadCampRecsModal,
	EditNodeGroupModal,
	EmailClassifierReplyModal,
	InsertVariableModal,
	ModelAnnouncementsModal,
	ModelAssetsModal,
	PhoneCallTranscriptionModal,
	PhoneCallRecordingModal,
	RemoveCampaignsModal,
	RemoveIntentModal,
	RemoveModal,
	RemoveModelModal,
	RemoveModelVersionModal,
	RemoveModelVersionsModal,
	RemoveNodeModal,
	RemoveUtteranceModal,
	RenameVariableModal,
	ResetVariablesModal,
	SaveModelVersionModal,
	ShowActiveNodeModal,
	SttBoostModal,
	UpdateModelAndVersionModal,
	UserSettingsModal,
	VersionConfigModal,
	VocabularyModal,
	YamlErrorsModal,
	AppsStatusModal,
	AddNewNumberModal,
	AddMetadataModal,
	DeleteMetadataModal,
	AddColumnModal,
	DeleteColumnModal,
	CreateUpdateSipAccountModal,
	DeleteModal,
} from '../components/Modals';
import UnsavedDataEditorChangesModal from '../components/Modals/UnsavedDataEditorChangesModal';
import keyMirror from '../utils/keyMirror';

export const MODAL_TYPES = keyMirror({
	ADD_EDIT_CONDITION: null,
	ADD_EDIT_GOTO: null,
	ADD_EDIT_INTENT: null,
	ADD_EDIT_SIGNAL: null,
	ADD_EDIT_SMART_FUNCTION: null,
	ADD_INTENT_FROM_LIB: null,
	ADD_MARKDOWN_OPTION: null,
	ADD_REMOVE_GROUP_NODE: null,
	CREATE_UPDATE_SIP_ACCOUNT: null,
	DELETE_MODAL: null,
	ADD_USER_RIGHTS: null,
	ADD_VARIABLE: null,
	ADD_WIDGET: null,
	CAMPAIGN_PHONE_CALL_FILTER_MODAL: null,
	CHANGE_PASSWORD: null,
	COLOR_CAPTIONS: null,
	CONFIRM_CALLER_NUMBER: null,
	CREATE_EDIT_COPY_CAMPAIGN: null,
	CREATE_EDIT_TRANSITION: null,
	CREATE_NEW_MODEL: null,
	CREATE_NODE_GROUP: null,
	DIFF_YAML: null,
	DOWNLOAD_CAMP_RECS: null,
	EDIT_NODE_GROUP: null,
	EMAIL_CLASSIFIER_REPLY_MODAL: null,
	GENERIC_REMOVE: null,
	INSERT_VARIABLE: null,
	LOADING: null,
	MODEL_ANNOUNCEMENTS: null,
	MODEL_ASSETS: null,
	PHONE_CALL_RECORDING: null,
	PHONE_CALL_TRANSCRIPTION: null,
	REMOVE_CAMPAIGNS: null,
	REMOVE_INTENT: null,
	REMOVE_MODEL: null,
	REMOVE_MODEL_VERSION: null,
	REMOVE_MODEL_VERSIONS: null,
	REMOVE_NODE: null,
	REMOVE_UTTERANCE: null,
	RENAME_VARIABLE: null,
	RESET_VARIABLES: null,
	SAVE_MODEL_VERSION: null,
	SET_ACTIVE_NODE: null,
	STATUS_SECTION: null,
	STT_BOOST: null,
	UPDATE_MODEL_VERSION_DESC: null,
	USER_SETTINGS: null,
	VERSION_CONFIG: null,
	VOCABULARY: null,
	YAML_ERRORS: null,
	ADD_NEW_NUMBER: null,
	ADD_METADATA: null,
	DELETE_METADATA: null,
	ADD_COLUMN: null,
	DELETE_COLUMN: null,
	UNSAVED_DATA_EDITOR_CHANGES: null,
});

const modals = {
	[MODAL_TYPES.DELETE_MODAL]: DeleteModal,
	[MODAL_TYPES.CREATE_UPDATE_SIP_ACCOUNT]: CreateUpdateSipAccountModal,
	[MODAL_TYPES.ADD_EDIT_CONDITION]: AddEditConditionModal,
	[MODAL_TYPES.ADD_EDIT_GOTO]: AddEditGotoModal,
	[MODAL_TYPES.ADD_EDIT_INTENT]: AddEditIntentModal,
	[MODAL_TYPES.ADD_EDIT_SIGNAL]: AddEditSignalModal,
	[MODAL_TYPES.ADD_EDIT_SMART_FUNCTION]: AddEditSmartFunctionModal,
	[MODAL_TYPES.ADD_INTENT_FROM_LIB]: AddIntentFromLibraryModal,
	[MODAL_TYPES.ADD_MARKDOWN_OPTION]: AddMarkdownOptionModal,
	[MODAL_TYPES.ADD_WIDGET]: AddWidgetModal,
	[MODAL_TYPES.ADD_REMOVE_GROUP_NODE]: AddRemoveGroupNodeModal,
	[MODAL_TYPES.ADD_USER_RIGHTS]: AddUserRightsModal,
	[MODAL_TYPES.ADD_VARIABLE]: AddEditVariableModal,
	[MODAL_TYPES.CAMPAIGN_PHONE_CALL_FILTER_MODAL]: CampaignPhoneCallFilterModal,
	[MODAL_TYPES.COLOR_CAPTIONS]: ColorCaptionsModal,
	[MODAL_TYPES.CREATE_EDIT_COPY_CAMPAIGN]: CreateEditCopyCampaignModal,
	[MODAL_TYPES.CREATE_EDIT_TRANSITION]: CreateEditTransitionModal,
	[MODAL_TYPES.CREATE_NEW_MODEL]: CreateNewModelModal,
	[MODAL_TYPES.CREATE_NODE_GROUP]: CreateNodeGroupModal,
	[MODAL_TYPES.DIFF_YAML]: DiffYamlModal,
	[MODAL_TYPES.DOWNLOAD_CAMP_RECS]: DownloadCampRecsModal,
	[MODAL_TYPES.EDIT_NODE_GROUP]: EditNodeGroupModal,
	[MODAL_TYPES.EMAIL_CLASSIFIER_REPLY_MODAL]: EmailClassifierReplyModal,
	[MODAL_TYPES.GENERIC_REMOVE]: RemoveModal,
	[MODAL_TYPES.INSERT_VARIABLE]: InsertVariableModal,
	[MODAL_TYPES.MODEL_ASSETS]: ModelAssetsModal,
	[MODAL_TYPES.MODEL_ANNOUNCEMENTS]: ModelAnnouncementsModal,
	[MODAL_TYPES.PHONE_CALL_TRANSCRIPTION]: PhoneCallTranscriptionModal,
	[MODAL_TYPES.PHONE_CALL_RECORDING]: PhoneCallRecordingModal,
	[MODAL_TYPES.REMOVE_CAMPAIGNS]: RemoveCampaignsModal,
	[MODAL_TYPES.REMOVE_INTENT]: RemoveIntentModal,
	[MODAL_TYPES.REMOVE_MODEL]: RemoveModelModal,
	[MODAL_TYPES.REMOVE_MODEL_VERSIONS]: RemoveModelVersionsModal,
	[MODAL_TYPES.REMOVE_MODEL_VERSION]: RemoveModelVersionModal,
	[MODAL_TYPES.REMOVE_NODE]: RemoveNodeModal,
	[MODAL_TYPES.REMOVE_UTTERANCE]: RemoveUtteranceModal, // TODO: Currently not used, if this comment is old, feel free to remove  all around RemoveUtteranceModal.
	[MODAL_TYPES.RENAME_VARIABLE]: RenameVariableModal,
	[MODAL_TYPES.RESET_VARIABLES]: ResetVariablesModal,
	[MODAL_TYPES.SAVE_MODEL_VERSION]: SaveModelVersionModal,
	[MODAL_TYPES.SET_ACTIVE_NODE]: ShowActiveNodeModal,
	[MODAL_TYPES.STT_BOOST]: SttBoostModal,
	[MODAL_TYPES.UPDATE_MODEL_VERSION_DESC]: UpdateModelAndVersionModal,
	[MODAL_TYPES.USER_SETTINGS]: UserSettingsModal,
	[MODAL_TYPES.STATUS_SECTION]: AppsStatusModal,
	[MODAL_TYPES.VERSION_CONFIG]: VersionConfigModal,
	[MODAL_TYPES.VOCABULARY]: VocabularyModal,
	[MODAL_TYPES.YAML_ERRORS]: YamlErrorsModal,
	[MODAL_TYPES.ADD_NEW_NUMBER]: AddNewNumberModal,
	[MODAL_TYPES.ADD_METADATA]: AddMetadataModal,
	[MODAL_TYPES.DELETE_METADATA]: DeleteMetadataModal,
	[MODAL_TYPES.ADD_COLUMN]: AddColumnModal,
	[MODAL_TYPES.DELETE_COLUMN]: DeleteColumnModal,
	[MODAL_TYPES.UNSAVED_DATA_EDITOR_CHANGES_MODAL]: UnsavedDataEditorChangesModal,
};

export default modals;
