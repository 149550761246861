import { gql } from 'graphql-tag';

export const loginQuery = gql`
	mutation login($email: String!, $password: String!) {
		login(email: $email, password: $password) {
			id
			email
			token
			passwordExpired
		}
	}
`;

/**
 * Removes refresh token from db. Returns true if success.
 */
export const logout = gql`
	mutation logout {
		logout
	}
`;

export const getModelRoles = gql`
	query {
		__type(name: "InputModelRoles") {
			enumValues {
				name
			}
		}
	}
`;

export const getOrganizationRoles = gql`
	query {
		__type(name: "OrganizationRoles") {
			enumValues {
				name
			}
		}
	}
`;

export const getOrganization = gql`
	query user($id: ID!) {
		user: user(id: $id) {
			id
			organization {
				name
				id
			}
		}
	}
`;

export const getOrganizations = gql`
	query organizations($sort: [OrganizationSort!]) {
		organizations(sort: $sort) {
			id
			name
		}
	}
`;

export const createUser = gql`
	mutation register($email: String!, $name: String!, $password: String, $organization: ID!, $role: OrganizationRoles) {
		register(email: $email, name: $name, password: $password, organization: $organization, role: $role) {
			id
			name
		}
	}
`;

export const updateUser = gql`
	mutation updateUser($id: ID!, $name: String, $password: String) {
		updateUser(id: $id, name: $name, password: $password) {
			id
			name
		}
	}
`;

export const unlockUserAccount = gql`
	mutation unlockUserAccount($id: ID!) {
		unlockUserAccount(id: $id)
	}
`;

export const createOrganization = gql`
	mutation createOrganization($name: String!) {
		createOrganization(name: $name) {
			id
			name
		}
	}
`;
