import { palette } from '../mui-theme-builder';

export const scrollbarVertical = {
	overflowY: 'scroll !important',
	'&::-webkit-scrollbar': { width: '0.1875rem' },
	'&:hover::-webkit-scrollbar': { width: '0.6875rem' },
	'&::-webkit-scrollbar-track': { backgroundColor: 'transparent' },
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: palette.primary.main,
		outline: 'none',
		borderRadius: 0,
	},
};

export const scrollbarHorizontal = {
	overflowX: 'scroll',
	'&::-webkit-scrollbar': { height: '0.1875rem' },
	'&:hover::-webkit-scrollbar': { width: '0.6875rem' },
	'&::-webkit-scrollbar-track': { backgroundColor: 'transparent !important' },
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: palette.primary.main + ' !important',
		outline: 'none',
		borderRadius: 0,
	},
};
