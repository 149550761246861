import { gql } from 'graphql-tag';

export const kibanaDashboardsQL = gql`
	query dashboard($id: ID!) {
		dashboards(id: $id) {
			type
			id
			coreMigrationVersion
			updated_at
			attributes {
				description
				title
			}
		}
	}
`;
