import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { IconButton, Grid, TextField } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { keys, omit, mergeLeft } from 'ramda';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { messages } from '../../../intl';
import { CALL_LIST_STATUS, updateMetaData } from '../../../redux/outbound/actions';
import TrashButton from '../../TrashButton/TrashButton';
import { useStylesCampaignDetail } from './CampaignDetail';

const addMetaDataParam = ({ e, dispatch, updateMetaData, metaDataPattern, campaign, setMetaDataPattern }) => {
	if (e.key === ' ') {
		e.preventDefault();
	} else if (e.key === 'Enter') {
		if (!metaDataPattern[e.target.value]) {
			dispatch(updateMetaData(campaign, mergeLeft({ [e.target.value]: '' }, metaDataPattern)));
			setMetaDataPattern(mergeLeft({ [e.target.value]: '' }, metaDataPattern));
		}
		e.target.value = '';
	}
};

const MetaData = ({ campaign, disableUI, metaDataPattern, setMetaDataPattern }) => {
	const classes = useStylesCampaignDetail();
	const [fold, setFold] = useState(false);
	const dispatch = useDispatch();
	const isCampInProgress = campaign.status === CALL_LIST_STATUS.IN_PROGRESS;

	const stats = {};
	if (!isEmpty(campaign.phoneCalls)) {
		for (const call of Object.values(campaign.phoneCalls)) {
			if (call.status) {
				stats[call.status] = stats[call.status] ? stats[call.status] + 1 : 1;
			}
		}
	}

	useEffect(() => {
		if (isCampInProgress) {
			setFold(true);
		}
	}, [isCampInProgress]);

	return (
		<div className={classes.section} style={{ minHeight: 'auto' }}>
			<div
				className={`${classes.sectionHeader} ${classes.cursorPointer}`}
				style={{ marginBottom: fold ? 0 : '15px' }}
				onClick={() => setFold(!fold)}
			>
				<FormattedMessage {...messages.metadataParameters} />
				<IconButton className={classes.foldBtn}>{!fold ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}</IconButton>
			</div>
			{!fold &&
				keys(metaDataPattern).map((param) => (
					<Grid spacing={2} container key={param} direction="row" alignItems="center" className={classes.defaultFont}>
						<Grid item xs={11}>
							{param}
						</Grid>
						<Grid item xs={1}>
							{!disableUI && (
								<TrashButton
									title={<FormattedMessage {...messages.deleteParameter} />}
									onClick={() => {
										dispatch(updateMetaData(campaign, omit([param], metaDataPattern)));
										setMetaDataPattern(omit([param], metaDataPattern));
									}}
								/>
							)}
						</Grid>
					</Grid>
				))}
			{!disableUI && !fold && (
				<div>
					<br />
					<TextField
						label={<FormattedMessage {...messages.addParam} />}
						variant={'outlined'}
						onKeyDown={(e) =>
							addMetaDataParam({ e, dispatch, updateMetaData, metaDataPattern, campaign, setMetaDataPattern })
						}
					/>
				</div>
			)}
		</div>
	);
};

MetaData.propTypes = {
	campaign: PropTypes.object,
	disableUI: PropTypes.bool,
	metaDataPattern: PropTypes.object,
	setMetaDataPattern: PropTypes.func,
};

export default MetaData;
