import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import Papa from 'papaparse';
import { isEmpty, includes, update, remove, fromPairs, pluck } from 'ramda';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../../constants';
import { INTL, messages } from '../../intl';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, setVocabulary } from '../../redux/model/actions';
import { getVocabulary } from '../../redux/model/selectors';
import { showNotification } from '../../redux/notification/actions';
import TrashButton from '../TrashButton/TrashButton';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles({
	examplesHeading: {
		margin: 0,
	},
	examplesContainer: {
		background: grey[50],
		marginTop: 0,
		marginBottom: '10px',
		padding: '10px',
		fontSize: '12px',
		lineHeight: '1em',
	},
	formField: {
		width: '90%',
		marginBottom: '20px',
	},
	inputsContainer: {
		maxHeight: '450px',
		overflow: 'auto',
		alignItems: 'center',
	},
	importButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	overwriteCheckboxContainer: {
		marginTop: '10px',
	},
	overwriteCheckbox: {
		marginLeft: '5px',
		padding: 0,
	},
	overwriteCheckboxLabel: {
		fontSize: '12px',
	},
});

const VocabularyModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const vocabulary = useSelector(getVocabulary);

	// The component works with an array because of the need to keep track of indeces of each key
	const [vocabularyEntries, setVocabularyEntries] = useState(Object.entries(vocabulary));

	const newVocabularyKeyInput = useRef(null);
	const [newVocabularyKeyError, setNewVocabularyKeyError] = useState(null);
	const [newVocabularyValueError, setNewVocabularyValueError] = useState(null);
	const [newVocabularyKey, setNewVocabularyKey] = useState('');
	const [newVocabularyValue, setNewVocabularyValue] = useState('');

	const [overwriteDuplicateKeys, setOverwriteDuplicateKeys] = useState(true);

	const handleImport = async (e) => {
		e.preventDefault();

		const csvFile = e.target.files[0];

		const { data, errors } = await new Promise((resolve) => Papa.parse(csvFile, { complete: resolve }));

		if (!isEmpty(errors)) {
			const errorMessage = errors.map((e) => e.message).join('\n');
			dispatch(showNotification(errorMessage, notificationTypes.ERROR));
		}

		if (!isEmpty(data)) {
			// Check for empty rows, it is enough to have either key or value filled
			const trimmedEntries = data.filter(([k, v]) => k || v);
			// Check for duplicate keys

			const vocabularyEntriesWithImportEntries = [...vocabularyEntries];
			const duplicateKeys = [];
			for (const [key, value] of trimmedEntries) {
				// Keys have to be updated after each new entry is pushed in case the import itself contained duplicates
				const vocabularyKeys = pluck(0, vocabularyEntriesWithImportEntries);
				if (includes(key, vocabularyKeys)) {
					if (overwriteDuplicateKeys) {
						const index = vocabularyKeys.indexOf(key);
						vocabularyEntriesWithImportEntries.splice(index, 1, [key, value]);
					}
					duplicateKeys.push(key);
				} else {
					vocabularyEntriesWithImportEntries.push([key, value]);
				}
			}

			if (!isEmpty(duplicateKeys)) {
				const keys = duplicateKeys.join(', ');
				dispatch(
					showNotification(
						INTL.formatMessage(messages.vocabularyImportDuplicatesRemovedWarning, { keys }),
						notificationTypes.WARNING
					)
				);
			}

			setVocabularyEntries(vocabularyEntriesWithImportEntries);
		}
	};

	const hasVocabularyKeyErrors = (key) => {
		if (isEmpty(key)) {
			return <FormattedMessage {...messages.emptyValueNotAllowed} />;
		} else if (vocabularyEntries.some(([k]) => k === key)) {
			return <FormattedMessage {...messages.vocabularyKeyAlreadyExists} />;
		} else {
			return null;
		}
	};
	const hasVocabularyValueErrors = (value) => {
		if (isEmpty(value)) {
			return <FormattedMessage {...messages.emptyValueNotAllowed} />;
		} else {
			return null;
		}
	};

	const handleAddVocabularyItem = () => {
		// There is a special case where the validation does not report empty values
		// before this function is invoked, hence the check for empty values
		if (isEmpty(newVocabularyKey) || isEmpty(newVocabularyValue)) {
			return;
		} else if (!newVocabularyKeyError && !newVocabularyValueError) {
			setVocabularyEntries([...vocabularyEntries, [newVocabularyKey, newVocabularyValue]]);
			setNewVocabularyKey('');
			setNewVocabularyValue('');

			newVocabularyKeyInput.current.focus();
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAddVocabularyItem();
		}
	};

	const handleKeyInputChange = (e, index) => {
		const newVocabularyKey = e.target.value;
		// Make an exception for an empty value because that key-value pair gets deleted onBlur if nothing is inputted in its place
		if (!hasVocabularyKeyErrors(newVocabularyKey) || isEmpty(newVocabularyKey)) {
			const vocabularyValue = vocabularyEntries[index][1];
			const newVocabulary = update(index, [newVocabularyKey, vocabularyValue], vocabularyEntries);
			setVocabularyEntries(newVocabulary);
		}
	};

	const deleteVocabularyEntry = (index) => {
		setVocabularyEntries(remove(index, 1, vocabularyEntries));
	};

	const handleValueInputChange = (e, index) => {
		const newVocabularyValue = e.target.value;
		const vocabularyKey = vocabularyEntries[index][0];
		const newVocabulary = update(index, [vocabularyKey, newVocabularyValue], vocabularyEntries);
		setVocabularyEntries(newVocabulary);
	};

	const handleSaveVocabulary = () => {
		const vocabulary = fromPairs(vocabularyEntries);
		dispatch(registerStateBeforeDispatchingAction(setVocabulary(vocabulary)));

		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.vocabularyModal} />}
				saveBtnTitle={<FormattedMessage {...messages.save} />}
				onSave={handleSaveVocabulary}
				disableEscapeDialogClose
				disableBackdropClickDialogClose
			>
				<Grid container>
					<Grid item xs={7}>
						<h4 className={classes.examplesHeading}>
							<FormattedMessage {...messages.vocabularyExamples} />
						</h4>
						<p className={classes.examplesContainer}>
							hw: hardware <br /> sw: software <br /> edg[ei]: Edge
						</p>
					</Grid>
					<Grid item xs={5} className={classes.importButtonContainer}>
						<div>
							<input
								type="file"
								accept=".csv,.tsv,.txt"
								onChange={handleImport}
								id="upload-utterances-csv-button"
								style={{ display: 'none' }}
							/>
							<label htmlFor="upload-utterances-csv-button">
								<Button component="span" variant="contained">
									<CloudUploadIcon style={{ marginRight: 5 }} />
									<FormattedMessage {...messages.importVocabulary} />
								</Button>
							</label>
						</div>
						<FormControlLabel
							control={
								<Checkbox
									checked={overwriteDuplicateKeys}
									onChange={() => setOverwriteDuplicateKeys(!overwriteDuplicateKeys)}
									color="primary"
									classes={{ root: classes.overwriteCheckbox }}
								/>
							}
							label={<FormattedMessage {...messages.vocabularyImportOverwriteDuplicateKeys} />}
							classes={{ root: classes.overwriteCheckboxContainer, label: classes.overwriteCheckboxLabel }}
						/>
					</Grid>
					<Grid container classes={{ root: classes.inputsContainer }}>
						{vocabularyEntries.map(([vocabularyKey, vocabularyValue], index) => (
							<>
								<Grid item xs={5} key={index + 'key'}>
									<TextField
										classes={{ root: classes.formField }}
										label={index === 0 ? <FormattedMessage {...messages.vocabularyKey} /> : null}
										value={vocabularyKey}
										onChange={(e) => handleKeyInputChange(e, index)}
										onBlur={(e) => (isEmpty(e.target.value) ? deleteVocabularyEntry(index) : null)}
										variant="standard"
									/>
								</Grid>
								<Grid item xs={6} key={index + 'value'}>
									<TextField
										classes={{ root: classes.formField }}
										label={index === 0 ? <FormattedMessage {...messages.vocabularyValue} /> : null}
										value={vocabularyValue}
										onChange={(e) => handleValueInputChange(e, index)}
										variant="standard"
									/>
								</Grid>
								<Grid item xs={1} key={index + 'delete'}>
									<TrashButton
										title={<FormattedMessage {...messages.deleteVocabularyEntry} />}
										onClick={() => deleteVocabularyEntry(index)}
									/>
								</Grid>
							</>
						))}
					</Grid>
					<Grid item xs={5}>
						<TextField
							inputRef={newVocabularyKeyInput}
							classes={{ root: classes.formField }}
							label={<FormattedMessage {...messages.vocabularyKey} />}
							value={newVocabularyKey}
							error={!!newVocabularyKeyError}
							helperText={newVocabularyKeyError}
							fullWidth
							variant={'outlined'}
							onChange={(e) => {
								setNewVocabularyKey(e.target.value);
								setNewVocabularyKeyError(hasVocabularyKeyErrors(e.target.value));
							}}
							onKeyPress={handleKeyPress}
							onBlur={() => handleAddVocabularyItem()}
							onFocus={() => setNewVocabularyKeyError(hasVocabularyKeyErrors(newVocabularyKey))}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							classes={{ root: classes.formField }}
							label={<FormattedMessage {...messages.vocabularyValue} />}
							value={newVocabularyValue}
							error={!!newVocabularyValueError}
							helperText={newVocabularyValueError}
							fullWidth
							variant={'outlined'}
							onChange={(e) => {
								setNewVocabularyValue(e.target.value);
								setNewVocabularyValueError(hasVocabularyValueErrors(e.target.value));
							}}
							onKeyPress={handleKeyPress}
							onBlur={() => handleAddVocabularyItem()}
							onFocus={() => setNewVocabularyValueError(hasVocabularyValueErrors(newVocabularyValue))}
						/>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default VocabularyModal;
