import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { deleteModel } from '../../redux/model-table/actions';
import { isFetchingDeleteModel } from '../../redux/model-table/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveModelModal = ({ id, name }) => {
	const dispatch = useDispatch();
	const isFetching = useSelector(isFetchingDeleteModel);

	const handleSave = async () => {
		await dispatch(deleteModel(id));
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.deleteModelTitle} />}
			saveBtnTitle={
				<FormattedMessage {...messages[isFetching ? 'deleteModelConfirmInProgress' : 'deleteModelConfirm']} />
			}
			onSave={handleSave}
			saveOnEnterKey
			isSaveBtnDisabled={isFetching}
		>
			<FormattedMessage
				{...messages.deleteModelQuestion}
				values={{ model: name, strong: (msg) => <strong>{msg}</strong> }}
			/>
		</SaveCloseModalTemplate>
	);
};

RemoveModelModal.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
};

export default RemoveModelModal;
