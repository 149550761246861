import Autocomplete from '@mui/lab/Autocomplete';
import { Chip, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { remove } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';

const useStyles = makeStyles({
	formField: {
		width: '90%',
		marginBottom: '20px',
		marginLeft: '20px',
	},
});

const FreeSoloAutocomplete = ({ values, setValues, label, disabled }) => {
	const classes = useStyles();

	const [currentValue, setCurrentValue] = useState('');

	const handleChange = (e) => {
		const val = e.target.value;
		if (e.key === 'Enter' && val && !disabled) {
			if (values.includes(val)) {
				return;
			}
			setValues([...values, val]);
			setCurrentValue('');
		} else if (e.key === 'Backspace' && !val && !disabled) {
			setValues(values.slice(0, values.length - 1));
		}
	};

	return (
		<Autocomplete
			classes={{ root: classes.formField }}
			freeSolo
			multiple
			open={false}
			value={values}
			options={values}
			getOptionLabel={(option) => option}
			onKeyDown={handleChange}
			inputValue={currentValue}
			closeIcon=""
			onInputChange={(_, v) => setCurrentValue(v)}
			renderTags={(values, getValueProps) =>
				values.map((value, index) => (
					<Chip
						label={value}
						{...getValueProps({ index })}
						style={{ pointerEvents: 'auto' }}
						onDelete={() => {
							if (!disabled) {
								setValues(remove(index, 1, values));
							}
						}}
					/>
				))
			}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label={label ? <FormattedMessage {...messages[label]} /> : ''}
					helperText={<FormattedMessage {...messages.boostPhrasesHelperText} />}
					disabled={disabled}
				/>
			)}
		/>
	);
};

FreeSoloAutocomplete.propTypes = {
	values: PropTypes.array,
	setValues: PropTypes.func,
	label: PropTypes.string,
	disabled: PropTypes.bool,
};

export default FreeSoloAutocomplete;
