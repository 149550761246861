export const copyToClipboard = (containerid) => {
	if (document.selection) {
		const range = document.body.createTextRange();
		range.moveToElementText(document.getElementById(containerid));
		range.select().createTextRange();
		document.execCommand('copy');
	} else if (window.getSelection) {
		const range = document.createRange();
		range.selectNode(document.getElementById(containerid));
		window.getSelection().addRange(range);
		document.execCommand('copy');
	}
};

export const copyTextToClipboard = (text) => {
	navigator.clipboard.writeText(text);
};
