import { produce } from 'immer';
import { combineReducers } from 'redux';
import { actionTypes as authActionTypes } from '../auth/actions';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = { messageCount: NaN, conversationCount: NaN };

const statistics = produce((state, { type, payload }) => {
	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.FETCH_STATISTICS_SUCCESS:
			return payload.data.statistics;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: statistics,
	fetchingStatistics: fetching([
		actionTypes.FETCH_STATISTICS_PENDING,
		actionTypes.FETCH_STATISTICS_SUCCESS,
		actionTypes.FETCH_STATISTICS_FAIL,
	]),
});
