import DeleteIcon from '@mui/icons-material/Delete';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const AssetUnknownListItem = ({ asset, onDelete }) => {
	return (
		<ListItem dense>
			<ListItemIcon>
				<Tooltip title={`unknown type "${asset.type}"`}>
					<DeviceUnknownIcon />
				</Tooltip>
			</ListItemIcon>
			<ListItemText primary={asset.name || 'untitled'} secondary={asset.id} />
			<ListItemSecondaryAction>
				<IconButton onClick={() => onDelete(asset.id)}>
					<DeleteIcon />
				</IconButton>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

AssetUnknownListItem.propTypes = {
	asset: PropTypes.object.isRequired,
	onDelete: PropTypes.func.isRequired,
};

// noinspection JSUnusedGlobalSymbols
export const AssetUnknownCreation = () => {
	throw new Error(`Unknown asset should not be created. Create custom component for creation.`);
};
