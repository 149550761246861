import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import StopIcon from '@mui/icons-material/Stop';
import { Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as xlsx from 'xlsx';
import { messages } from '../../../intl';
import {
	showCreateEditCopyCampaignModal,
	showDownloadCampRecsModal,
	showAddNewNumberModal,
} from '../../../redux/modal/actions';
import { selectedModelId } from '../../../redux/model-table/selectors';
import {
	CALL_LIST_STATUS,
	downloadCampaignCSV,
	downloadCampaignJSON,
	downloadCampaignXLS,
	updateCampaign,
	updateCampaignStatus,
	updateNumbers,
} from '../../../redux/outbound/actions';
import { getCallerNumber, getCampaign, getTrunkAccount } from '../../../redux/outbound/selectors';
import { isPhoneNumberInvalid, isTrunkAccountInvalid } from '../../../utils/validation';
import { useStylesCampaignDetail } from './CampaignDetail';

const resetPhoneCalls = (campaign, dispatch) => {
	// This just has to happen one-by-one
	// updateCampaign always finishes after the other updates, reversing all changes
	// eslint-disable-next-line guard-for-in
	for (const phoneNumber in campaign.phoneCalls) {
		dispatch(updateNumbers(campaign.id, phoneNumber));
	}
};

const handleLoadCampaign = ({ e, currrCampId, activeModelId, dispatch, setReset, campaign, useSelector }) => {
	e.persist();
	const type = e.target.files[0].type; // The type gets lost in target.result if read as binary string, so we save it for branching
	const reader = new FileReader();
	if (type === 'application/json') {
		reader.readAsText(e.target.files[0]);
	} else {
		reader.readAsBinaryString(e.target.files[0]);
	}

	reader.onload = (e) => {
		switch (type) {
			case 'application/json':
				// A JSON input
				const campaignBackUp = JSON.parse(e.target.result);
				campaignBackUp.id = campaign.id;
				campaignBackUp.modelId = activeModelId;
				dispatch(updateCampaign(campaignBackUp));
				setReset(+new Date() + 'CampaignDetail');
				return;
			default:
				// Assumes either a .xls or .xlsx input (The input html element doesn't accept others, so all cases are covered)
				const campaignBackUpXls = xlsx.read(e.target.result, { type: 'binary' });
				const wsname = campaignBackUpXls.SheetNames[0];
				const ws = campaignBackUpXls.Sheets[wsname];
				const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
				const headers = makeColumns(ws['!ref']);
				if (headers.length === 4) {
					// User imported just numbers to call
					resetPhoneCalls(campaign, dispatch);
					// Skipping the header indexes at 0
					for (let i = 1; i < data.length; ++i) {
						if (!isPhoneNumberInvalid(data[i][0])) {
							const metaDataPattern = {
								ID: data[i][1],
								Name: data[i][2],
								Surname: data[i][3],
							};
							// Updating phoneCalls in bulk would not generate new IDs and CallLists
							dispatch(updateNumbers(campaign.id, data[i][0].toString(), metaDataPattern));
						}
					}
				} else {
					const excelCampaignBackup = {};
					// Dummy values for campaign validity
					// Excel export doesn't export everything properly
					Object.keys(campaign).forEach((key) => {
						excelCampaignBackup[key] = excelCampaignBackup[key] ? excelCampaignBackup[key] : campaign[key];
						return;
					});
					for (let i = 0; i < data[0].length; ++i) {
						excelCampaignBackup[data[0][i]] = data[1][i] ? data[1][i] : null;
					}
					excelCampaignBackup['id'] = campaign.id;
					excelCampaignBackup['modelId'] = activeModelId;
					// Imported metadata shouldn't change campaigns creation date
					// Excel export also changes the Date format to an invalid one
					excelCampaignBackup['createdAt'] = campaign.createdAt;
					dispatch(updateCampaign(excelCampaignBackup));
					setReset(+new Date() + 'CampaignDetail');
				}
				return;
		}
	};

	// Reads only the header cells and returns their array
	const makeColumns = (refstr) => {
		const result = [];
		const columns = xlsx.utils.decode_range(refstr).e.c + 1;
		for (let i = 0; i < columns; ++i) {
			result[i] = { name: xlsx.utils.encode_col(i), key: i };
		}
		return result;
	};

	/*reader.onload = () => {
		const campaignBackUp = JSON.parse(reader.result);

		campaignBackUp.id = currrCampId;
		campaignBackUp.modelId = activeModelId;
		dispatch(updateCampaign(campaignBackUp));
		setReset(+new Date() + 'CampaignDetail');
	};*/
};

const ControlBar = ({
	callerNumberErr,
	disableUI,
	hasCampNumbers,
	id,
	onComplete,
	setCallerNumberErr,
	setReset,
	setTrunkAccountErr,
	campaignType,
	metaDataPattern,
}) => {
	const classes = useStylesCampaignDetail();
	const intl = useIntl();
	const [isPopperOpen, setIsPopperOpen] = useState(false);
	const anchorRef = React.useRef(null);
	const campaign = useSelector(getCampaign(id));
	const activeModelId = useSelector(selectedModelId);
	const callerNumber = useSelector(getCallerNumber(id));
	const trunkAccount = useSelector(getTrunkAccount(id));
	const isSip = !!trunkAccount;
	const dispatch = useDispatch();
	const isCampFinished = campaign.status === CALL_LIST_STATUS.FINISHED;
	const disableLaunch = callerNumberErr || isCampFinished;
	const phoneCalls = campaign?.phoneCalls ?? {};
	const isCampInProgress = campaign.status === CALL_LIST_STATUS.IN_PROGRESS;

	const allowManualNextRoundLaunch = (() => {
		const anyRoundsRemaining =
			isCampInProgress && campaign.finishedRounds <= campaign.maxCallTrials && campaign.lastRoundFinishedAt;

		let withinTimeframeToLaunchManually = false;
		// Get the corresponding repeatCallDate in case there are more
		const nextCampaignRoundAt = campaign.repeatCallsAt?.[campaign.finishedRounds - 1];
		if (campaign?.repeatCallsAt?.length > 0 && nextCampaignRoundAt) {
			withinTimeframeToLaunchManually = new Date(nextCampaignRoundAt).getTime() > Date.now();
		} else {
			withinTimeframeToLaunchManually =
				typeof campaign.lastRoundFinishedAt &&
				new Date(campaign.lastRoundFinishedAt).getTime() + campaign?.repeatCallDelayInMinutes * 60_000 > Date.now();
		}

		return anyRoundsRemaining && withinTimeframeToLaunchManually;
	})();

	const handleUpdateCampaign = (launch) => {
		if (callerNumber && isPhoneNumberInvalid(callerNumber)) {
			setCallerNumberErr(isPhoneNumberInvalid(callerNumber));
			return;
		}

		if (trunkAccount && isTrunkAccountInvalid(trunkAccount)) {
			setTrunkAccountErr(trunkAccount);
			return;
		}

		dispatch(
			updateCampaign({
				...campaign,
				id,
				phoneNumberTwilio: callerNumber,
				trunkAccount,
				phoneCalls,
				launch,
				intl,
			})
		);
	};

	const handleClosePopper = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setIsPopperOpen(false);
	};

	return (
		<Grid spacing={3} container>
			<Grid item xs={12}>
				<Button onClick={onComplete} variant="contained">
					<KeyboardArrowLeft />
					{<FormattedMessage {...messages.goBack} />}
				</Button>
				<span className={classes.floatRight}>
					{campaignType === 'CONTINUOUS' && (
						<Button
							onClick={() => {
								dispatch(
									showAddNewNumberModal({
										id,
										metaDataPattern,
									})
								);
							}}
							variant="contained"
						>
							<AddCircleIcon />
							{<FormattedMessage {...messages.addNumber} />}
						</Button>
					)}
					<Tooltip title={<FormattedMessage {...messages.updateCampaign} />}>
						<Button
							variant="contained"
							className={classes.controlButton}
							onClick={() =>
								dispatch(
									showCreateEditCopyCampaignModal({
										campId: id,
										callback: setReset,
										operation: 'update',
									})
								)
							}
						>
							<SettingsIcon />
						</Button>
					</Tooltip>{' '}
					<input
						accept=".json, .xls"
						id="contained-button-file"
						type="file"
						style={{ display: 'none' }}
						onChange={(e) => {
							handleLoadCampaign({ e, id, activeModelId, dispatch, setReset, campaign });
						}}
					/>
					<label htmlFor={!disableUI ? 'contained-button-file' : null}>
						<Tooltip title={<FormattedMessage {...messages.uploadCampaign} />}>
							<span>
								<Button
									variant="contained"
									className={disableUI ? classes.controlButtonDisabled : classes.controlButton}
									component="span"
								>
									<CloudUploadIcon />
								</Button>
							</span>
						</Tooltip>
					</label>{' '}
					<Tooltip title={<FormattedMessage {...messages.saveToFile} />}>
						<Button
							ref={anchorRef}
							variant="contained"
							className={classes.controlButton}
							onClick={() => setIsPopperOpen(!isPopperOpen)}
						>
							<CloudDownloadIcon />
						</Button>
					</Tooltip>{' '}
					<Popper
						open={isPopperOpen}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
						className={{ zIndex: 1 }}
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								className={{
									transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
								}}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClosePopper}>
										<MenuList id="split-button-menu">
											<MenuItem onClick={() => downloadCampaignJSON(campaign)}>
												<FormattedMessage {...messages.downloadCampaignJSON} />
											</MenuItem>
											<MenuItem onClick={() => downloadCampaignXLS(campaign)}>
												<FormattedMessage {...messages.downloadCampaignXLS} />
											</MenuItem>
											<MenuItem onClick={() => downloadCampaignCSV(campaign)}>
												<FormattedMessage {...messages.downloadCampaignCSV} />
											</MenuItem>
											{isCampFinished && (
												<MenuItem onClick={() => dispatch(showDownloadCampRecsModal(campaign, isSip))}>
													<FormattedMessage {...messages.downloadCampaignsRecs} />
												</MenuItem>
											)}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>{' '}
					<Tooltip title={<FormattedMessage {...messages.saveCampaign} />}>
						<Button
							color="primary"
							variant="contained"
							className={disableUI ? classes.controlButtonDisabled : classes.controlButton}
							onClick={() => !disableUI && handleUpdateCampaign()}
						>
							<SaveIcon />
						</Button>
					</Tooltip>
					{!isCampInProgress ? (
						<Tooltip title={<FormattedMessage {...messages.launchCampaign} />}>
							<Button
								color="primary"
								variant="contained"
								className={disableLaunch || !hasCampNumbers ? classes.controlButtonDisabled : classes.controlButton}
								onClick={() => (disableLaunch || !hasCampNumbers ? null : handleUpdateCampaign({ launch: true }))}
							>
								<PlayArrowIcon />
							</Button>
						</Tooltip>
					) : (
						<>
							<Tooltip title={<FormattedMessage {...messages.stopCampaign} />}>
								<Button
									variant="contained"
									className={isCampFinished ? classes.controlButtonDisabled : classes.controlButton}
									onClick={() => !isCampFinished && dispatch(updateCampaignStatus(id, CALL_LIST_STATUS.STOP))}
								>
									<StopIcon />
								</Button>
							</Tooltip>
							<Tooltip title={<FormattedMessage {...messages.pauseCampaign} />}>
								<Button
									variant="contained"
									className={isCampFinished ? classes.controlButtonDisabled : classes.controlButton}
									onClick={() => !isCampFinished && dispatch(updateCampaignStatus(id, CALL_LIST_STATUS.PAUSED))}
								>
									<PauseIcon />
								</Button>
							</Tooltip>
							{allowManualNextRoundLaunch && (
								<Tooltip title={<FormattedMessage {...messages.launchCampaign} />}>
									<Button
										color="primary"
										variant="contained"
										className={disableLaunch || !hasCampNumbers ? classes.controlButtonDisabled : classes.controlButton}
										onClick={() => (disableLaunch || !hasCampNumbers ? null : handleUpdateCampaign({ launch: true }))}
									>
										<PlayArrowIcon />
									</Button>
								</Tooltip>
							)}
						</>
					)}
				</span>
			</Grid>
		</Grid>
	);
};

ControlBar.propTypes = {
	callerNumberErr: PropTypes.object,
	disableUI: PropTypes.bool,
	hasCampNumbers: PropTypes.bool,
	id: PropTypes.string,
	onComplete: PropTypes.func,
	setCallerNumberErr: PropTypes.func,
	setTrunkAccountErr: PropTypes.func,
	setReset: PropTypes.func,
	campaignType: PropTypes.string,
	metaDataPattern: PropTypes.object,
};

export default ControlBar;
