import { normalize } from 'normalizr';
import { path } from 'ramda';

export const cud = (postfix) => [`ADD_${postfix}`, `UPDATE_${postfix}`, `REMOVE_${postfix}`];

export const aur = cud;

export const psf = (prefix) => [`${prefix}_PENDING`, `${prefix}_SUCCESS`, `${prefix}_FAIL`];

export const normalizedSuccess = (actionType, resPath, schema, customFnc = () => {}) => {
	return {
		type: actionType,
		payload: async (action, state, res) => {
			const jsonData = await res.json();
			try {
				const normalizedData = normalize(path(resPath, jsonData), schema);
				customFnc(normalizedData, action, state, res);

				return normalizedData;
			} catch (e) {
				throw new Error(
					`Response normalization of ${actionType} at ${JSON.stringify(resPath)} failed: ${e} for ${JSON.stringify(
						jsonData
					)}`
				);
			}
		},
	};
};

export const increaseMinorVersion = (version = '0.0') => {
	const [major = 0, minor = 0] = version.split('.');

	return `${major}.${Number(minor) + 1}`;
};
