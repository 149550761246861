import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveModal = ({ type, id, removeHandler = () => {} }) => {
	const dispatch = useDispatch();
	let removeLabel = '';
	let removeQuestion = '';

	switch (type) {
		case 'nodeGroup':
			removeLabel = messages.removeNodeGroup;
			removeQuestion = messages.removeNodeGroupQuestion;
			break;
		case 'intent':
			removeLabel = messages.removeIntent;
			removeQuestion = messages.removeIntentQuestion;
			break;
		case 'condition':
			removeLabel = messages.removeCondition;
			removeQuestion = messages.removeConditionQuestion;
			break;
		case 'fallback':
			removeLabel = messages.removeFallback;
			removeQuestion = messages.removeFallbackQuestion;
			break;
		case 'signal':
			removeLabel = messages.removeSignal;
			removeQuestion = messages.removeSignalQuestion;
			break;
		default:
			const message = `Type: ${type} unrecognized in RemoveModal.`;
			console.error(message);
			Sentry.captureException(message);
	}

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...removeLabel} />}
			saveBtnTitle={<FormattedMessage {...removeLabel} />}
			onSave={() => {
				removeHandler(id);
				dispatch(hideModal());
			}}
		>
			<FormattedMessage {...removeQuestion} values={{ name: id, strong: (msg) => <strong>{msg}</strong> }} />
		</SaveCloseModalTemplate>
	);
};

RemoveModal.propTypes = {
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	removeHandler: PropTypes.func.isRequired,
};

export default RemoveModal;
