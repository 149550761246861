import { qlAuthRequest, qlRequest } from '../../core/requests';
import {
	createOrganization as createOrganizationQL,
	createUser as createUserQL,
	getModelRoles as getModelRolesQL,
	getOrganizationRoles as getOrganizationRolesQL,
	getOrganization as getOrganizationQL,
	getOrganizations,
	loginQuery,
	logout as logoutQuery,
	updateUser as updateUserQL,
	unlockUserAccount as unlockUserAccountQL,
} from '../../graphql/auth';
import { createActionsMap } from '../../utils/action-utils';
import LSM from '../../utils/LocalStorageManager';
import { psf } from '../utils';
import { ID } from './selectors';

export const USER_ROLES = Object.freeze({
	SUPER_ADMIN: 'SuperAdmin',
	ADMIN: 'Admin',
	USER: 'User',
	END_USER: 'EndUser',
});

export const actionTypes = createActionsMap(ID, [
	'REFRESH_TOKEN',
	...psf('CREATE_ORGANIZATION'),
	...psf('CREATE_USER'),
	...psf('FETCH_MODEL_ROLES'),
	...psf('FETCH_ORGANIZATION_ROLES'),
	...psf('FETCH_ORGANIZATION'),
	...psf('FETCH_ORGANIZATIONS'),
	...psf('LOGIN'),
	...psf('LOGOUT'),
	...psf('UPDATE_USER'),
	...psf('UNLOCK_USER_ACCOUNT'),
]);

export const refreshToken = (token) => {
	LSM.setAccessToken(token);
	return { type: actionTypes.REFRESH_TOKEN, payload: { token } };
};

export const login = (email, password) => (dispatch) =>
	dispatch(
		qlRequest(
			[
				actionTypes.LOGIN_PENDING,
				{
					type: actionTypes.LOGIN_SUCCESS,
					payload: async (action, state, response) => {
						const json = await response.json();
						const payload = json.data.login;
						LSM.setAccessToken(payload.token);
						return payload;
					},
				},
				actionTypes.LOGIN_FAIL,
			],
			{
				query: loginQuery,
				variables: { email, password },
			}
		)
	);

/**
 * Due to apiErrorHandler (refreshToken part) order of calls is important. qlRequest needs to be handled before LSM.removeAll.
 */
export const logout = () => async (dispatch) => {
	const logoutResponse = await dispatch(
		qlRequest([actionTypes.LOGOUT_PENDING, actionTypes.LOGOUT_SUCCESS, actionTypes.LOGOUT_FAIL], {
			query: logoutQuery,
		})
	);

	LSM.removeAll();

	return logoutResponse;
};

export const fetchModelRoles = () => (dispatch) => {
	dispatch(
		qlAuthRequest(
			[
				actionTypes.FETCH_MODEL_ROLES_PENDING,
				actionTypes.FETCH_MODEL_ROLES_SUCCESS,
				actionTypes.FETCH_MODEL_ROLES_FAIL,
			],
			{ query: getModelRolesQL }
		)
	);
};

export const fetchOrganizationRoles = () => (dispatch) => {
	dispatch(
		qlAuthRequest(
			[
				actionTypes.FETCH_ORGANIZATION_ROLES_PENDING,
				actionTypes.FETCH_ORGANIZATION_ROLES_SUCCESS,
				actionTypes.FETCH_ORGANIZATION_ROLES_FAIL,
			],
			{ query: getOrganizationRolesQL }
		)
	);
};

/**
 * @param {string} id Logged user id
 */
export const fetchOrganization = (id) => (dispatch) => {
	dispatch(
		qlAuthRequest(
			[
				actionTypes.FETCH_ORGANIZATION_PENDING,
				actionTypes.FETCH_ORGANIZATION_SUCCESS,
				actionTypes.FETCH_ORGANIZATION_FAIL,
			],
			{ query: getOrganizationQL, variables: { id } }
		)
	);
};

export const fetchOrganizations = () => (dispatch) => {
	dispatch(
		qlAuthRequest(
			[
				actionTypes.FETCH_ORGANIZATIONS_PENDING,
				actionTypes.FETCH_ORGANIZATIONS_SUCCESS,
				actionTypes.FETCH_ORGANIZATIONS_FAIL,
			],
			{ query: getOrganizations, variables: { sort: [{ field: 'name', direction: 'ASC' }] } }
		)
	);
};

export const createUser = (email, name, password, organization, role) => (dispatch) =>
	dispatch(
		qlAuthRequest([actionTypes.CREATE_USER_PENDING, actionTypes.CREATE_USER_SUCCESS, actionTypes.CREATE_USER_FAIL], {
			query: createUserQL,
			variables: { email, name, password, organization, role },
		})
	);

export const updateUser = (id, password, name) => (dispatch) => {
	dispatch(
		qlAuthRequest([actionTypes.UPDATE_USER_PENDING, actionTypes.UPDATE_USER_SUCCESS, actionTypes.UPDATE_USER_FAIL], {
			query: updateUserQL,
			variables: { id, name, password },
		})
	);
};

export const unlockUserAccount = (id) => async (dispatch) => {
	const { payload } = await dispatch(
		qlAuthRequest(
			[
				actionTypes.UNLOCK_USER_ACCOUNT_PENDING,
				actionTypes.UNLOCK_USER_ACCOUNT_SUCCESS,
				actionTypes.UNLOCK_USER_ACCOUNT_FAIL,
			],
			{
				query: unlockUserAccountQL,
				variables: { id },
			}
		)
	);
	return payload.data;
};

export const createOrganization = (name) => (dispatch) =>
	dispatch(
		qlAuthRequest(
			[
				actionTypes.CREATE_ORGANIZATION_PENDING,
				actionTypes.CREATE_ORGANIZATION_SUCCESS,
				actionTypes.CREATE_ORGANIZATION_FAIL,
			],
			{
				query: createOrganizationQL,
				variables: { name },
			}
		)
	);
