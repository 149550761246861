import { schema as Schema } from 'normalizr';

export const configSchema = new Schema.Entity('configs', {
	modelVersion: new Schema.Entity('versions'),
});
export const configListSchema = [configSchema];

export const modelVersionSchema = new Schema.Entity('versions', {
	model: new Schema.Entity('models'),
	configs: configListSchema,
});
export const modelVersionListSchema = [modelVersionSchema];

export const imagesSchema = new Schema.Entity('images');
export const imagesListSchema = [imagesSchema];

export const modelAssetsSchema = new Schema.Entity('assets', {
	model: new Schema.Entity('models'),
});
export const modelAssetsListSchema = [modelAssetsSchema];

export const modelUsersSchema = new Schema.Entity('models', {
	model: new Schema.Entity('models'),
});

export const modelSchema = new Schema.Entity('models', {
	images: imagesListSchema,
	assets: modelAssetsListSchema,
	versions: modelVersionListSchema,
});
export const modelListSchema = [modelSchema];
