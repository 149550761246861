import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { concat } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { registerStateBeforeDispatchingAction, updateReferencesToNode } from '../../redux/model/actions';
import { getAllDiagramGroupsNames, getNodeIds } from '../../redux/model/selectors';
import { normalizeNodeId } from '../../utils/normalization';
import { validateNodeName } from '../../utils/validation';

const NodeIdTextField = ({
	allowDuplicate,
	handleChange,
	helperText,
	nodeId,
	saveOnMouseOut,
	caretPosition,
	...rest
}) => {
	const [inputConf, setInputConf] = useState({
		validation: null,
		caretPos: caretPosition || null,
		nodeId: nodeId || '',
	});
	const reservedIds = concat(useSelector(getAllDiagramGroupsNames), useSelector(getNodeIds));
	const inputRef = useRef(null);
	const dispatch = useDispatch();

	const handleNodeNameChange = (e) => {
		const newNodeId = normalizeNodeId(e.target.value);
		const validation = nodeId === newNodeId ? null : validateNodeName(newNodeId, !allowDuplicate ? reservedIds : null);

		const _inputConf = {
			validation,
			caretPos: e.target.selectionStart,
			nodeId: newNodeId,
		};

		setInputConf(_inputConf);

		if (handleChange) {
			handleChange(_inputConf);
		}
	};

	const handleOnMouseOut = () => {
		if (saveOnMouseOut) {
			inputRef.current.blur();
			if (!inputConf.validation) {
				dispatch(registerStateBeforeDispatchingAction(updateReferencesToNode(inputConf.nodeId, nodeId)));
			}
		}
	};

	useEffect(() => {
		inputRef.current.selectionStart = inputConf.caretPos;
		inputRef.current.selectionEnd = inputConf.caretPos;
		inputRef.current.nodeId = inputConf.nodeId;
	}, [inputConf]);

	useEffect(() => {
		setInputConf({
			...inputConf,
			nodeId,
		});
	}, [nodeId]);

	return (
		<TextField
			inputRef={inputRef}
			value={inputConf.nodeId}
			label={<FormattedMessage {...messages.nodeName} />}
			error={!!inputConf.validation}
			helperText={inputConf.validation || helperText}
			variant={'outlined'}
			onMouseOut={handleOnMouseOut}
			fullWidth
			onChange={handleNodeNameChange}
			required
			{...rest}
		/>
	);
};

NodeIdTextField.propTypes = {
	allowDuplicate: PropTypes.bool,
	handleChange: PropTypes.func,
	helperText: PropTypes.object,
	nodeId: PropTypes.string,
	caretPosition: PropTypes.number,
	saveOnMouseOut: PropTypes.bool,
};

export default NodeIdTextField;
