import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { createMetadata, fetchMetadatas, updateDataManagementFilteredValue } from '../../redux/data-management/actions';
import { hideModal } from '../../redux/modal/actions';
import { validateHasSpecialChars, validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const AddMetadataModal = () => {
	const [errorTitle, setErrorTitle] = useState(null);
	const [metadataName, setMetadataName] = useState('');
	const [metadataDescription, setMetadataDescription] = useState('');
	const dispatch = useDispatch();

	const handleAdd = async () => {
		setErrorTitle(validateIsEmpty(metadataName));
		setErrorTitle(validateHasSpecialChars(metadataName));
		if (validateIsEmpty(metadataName)) {
			return;
		}
		if (validateHasSpecialChars(metadataName)) {
			return;
		}
		await dispatch(createMetadata(metadataName, metadataDescription));
		await dispatch(fetchMetadatas());
		dispatch(updateDataManagementFilteredValue(''));
		dispatch(hideModal());
	};

	const handleKeyPress = async (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};
	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.addMetadata} />}
				saveBtnTitle={<FormattedMessage {...messages.addMetadata} />}
				onSave={handleAdd}
			>
				<div>
					<TextField
						autoFocus
						value={metadataName}
						error={!!errorTitle}
						helperText={errorTitle}
						fullWidth
						onChange={(e) => {
							setMetadataName(e.target.value);
							setErrorTitle(validateIsEmpty(e.target.value));
							setErrorTitle(validateHasSpecialChars(e.target.value));
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.metadataName} />}
						data-testid="metadataNameInput"
						variant="standard"
					/>
					<p />
					<TextField
						value={metadataDescription}
						fullWidth
						onChange={(e) => {
							setMetadataDescription(e.target.value);
						}}
						onKeyPress={handleKeyPress}
						label={<FormattedMessage {...messages.metadataDesc} />}
						data-testid="metadataDescInput"
						variant="standard"
					/>
				</div>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default AddMetadataModal;
