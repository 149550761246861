import { Close as CloseIcon } from '@mui/icons-material';
import Alert from '@mui/lab/Alert';
import { Button, Tab, Tabs, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../intl';
import { hideModal } from '../../redux/modal/actions';
import { removeYamlErrorWarning } from '../../redux/model/actions';
import { getYamlErrors, getYamlWarnings } from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles({
	alert: {
		margin: '10px',
		'& MuiAlert-icon': {
			alignItems: 'center',
		},
	},
	showLineButton: {
		color: 'rgb(97, 26, 21)',
	},
});

const YamlErrorsModal = ({ showLine }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const issues = { error: useSelector(getYamlErrors()), warning: useSelector(getYamlWarnings()) };

	const [currentTab, setCurrentTab] = useState('error');

	const deleteIssue = (issueIndex) => {
		dispatch(removeYamlErrorWarning(issueIndex, currentTab));
	};

	const goToIssue = (issueRow) => {
		dispatch(hideModal());
		showLine(issueRow);
	};

	return (
		<div>
			<SaveCloseModalTemplate title={<FormattedMessage {...messages.yamlErrorsModal} />} isSaveBtnVisible={false}>
				<Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
					<Tab value="error" label={<FormattedMessage {...messages.errors} />} />
					<Tab value="warning" label={<FormattedMessage {...messages.warnings} />} />
				</Tabs>
				<Grid container>
					<Grid item xs={12}>
						{issues[currentTab].map((issue, index) => (
							<Alert
								className={classes.alert}
								key={index}
								severity={currentTab}
								action={<CloseIcon onClick={() => deleteIssue(index)} />}
							>
								<p>{issue.text}</p>
								{!!issue.row && ( // Some errors do not indicate where they occurred
									<Button className={classes.showLineButton} variant="text" onClick={() => goToIssue(issue.row + 1)}>
										Line: {issue.row + 1}
									</Button>
								)}
							</Alert>
						))}
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

YamlErrorsModal.propTypes = {
	showLine: PropTypes.func,
};

export default YamlErrorsModal;
