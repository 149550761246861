import * as Sentry from '@sentry/react';
import { compose, isEmpty, keys, last } from 'ramda';
import { notificationTypes } from '../../constants';
import * as requests from '../../core/requests';
import {
	createConfig as createConfigQL,
	createModel as createModelQL,
	createModelAssetQl,
	createModelVersion as createModelVersionQL,
	deleteConfig as deleteConfigQL,
	deleteImage as deleteImageQL,
	deleteModel as deleteModelQL,
	deleteModelAsset as deleteModelAssetQl,
	deleteModelVersion as deleteModelVersionQL,
	editModelUserRole as addUserToModelGL,
	getExtractorsQl,
	getModel as getModelQL,
	getModelsBasicInfoQuery,
	getModelVersion,
	getUsers as getUsersQL,
	trainConfig as trainConfigQL,
	updateModel as updateModelQL,
	updateModelAssetQl,
	updateModelVersion as updateModelVersionQL,
	validateModelVersionQl,
	getHealthsQL,
} from '../../graphql/model';
import { INTL, messages } from '../../intl';
import { createActionsMap, makeActionCreator } from '../../utils/action-utils';
import { generateUniqueId } from '../../utils/generateUniqueId';
import { removePreviousModel, setYamlErrorWarning, removeYamlErrorWarning } from '../model/actions';
import { parseYaml } from '../model/reducers';
import {
	getActiveModelConfigType,
	getProjectData,
	getRawYaml,
	getYamlErrors,
	getYamlWarnings,
} from '../model/selectors';
import { isYamlValid } from '../model/utils';
import { showNotification } from '../notification/actions';
import { increaseMinorVersion, normalizedSuccess, psf } from '../utils';
import {
	configSchema,
	imagesSchema,
	modelAssetsSchema,
	modelListSchema,
	modelSchema,
	modelUsersSchema,
	modelVersionSchema,
} from './schema';
import {
	getActiveModelVersionConfig,
	getExtractors,
	getMaximumModelVersion,
	getModel,
	selectedModelId,
	selectedModelVersionId,
} from './selectors';

export const ID = 'modelTable';

export const actionTypes = createActionsMap(ID, [
	'UPDATE_DASHBOARD_FILTERED_VALUE',
	'SET_IS_FETCHING_ASSETS',
	'SELECT_MODEL',
	'SELECT_MODEL_VERSION',
	...psf('CREATE_CONFIG'),
	...psf('CREATE_MODEL'),
	...psf('CREATE_MODEL_VERSION'),
	...psf('DELETE_CONFIG'),
	...psf('DELETE_IMAGE'),
	...psf('DELETE_MODEL'),
	...psf('DELETE_MODEL_VERSION'),
	...psf('EDIT_MODEL_USER_ROLE'),
	...psf('FETCH_EXTRACTORS_DATA'),
	...psf('FETCH_MODEL'),
	...psf('FETCH_MODEL_VERSION'),
	...psf('FETCH_USERS'),
	...psf('FETCH_USERS_DATA'),
	...psf('MODELS_INFO'),
	...psf('TRAIN_CONFIG'),
	...psf('UPDATE_MODEL'),
	...psf('UPDATE_MODEL_VERSION'),
	...psf('VALIDATE_MODEL_VERSION'),
	...psf('VERSIONS_TRAINED_STATUS'),
	...psf('FETCH_MODEL_ASSETS'),
	...psf('UPLOAD_MODEL_ASSET'),
	...psf('CREATE_MODEL_ASSET'),
	...psf('UPDATE_MODEL_ASSET'),
	...psf('DELETE_MODEL_ASSET'),
	...psf('FETCH_APP_HEALTHS'),
]);

export const updateDashboardFilteredValue = makeActionCreator(actionTypes.UPDATE_DASHBOARD_FILTERED_VALUE, 'value');
export const setIsFetchingAssets = makeActionCreator(actionTypes.SET_IS_FETCHING_ASSETS, 'value');

export const fetchUsers = () =>
	requests.qlAuthRequest(
		[actionTypes.FETCH_USERS_PENDING, actionTypes.FETCH_USERS_SUCCESS, actionTypes.FETCH_USERS_FAIL],
		{ query: getUsersQL }
	);

export const fetchModel = (id) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_MODEL_PENDING,
			normalizedSuccess(actionTypes.FETCH_MODEL_SUCCESS, ['data', 'models'], modelSchema),
			actionTypes.FETCH_MODEL_FAIL,
		],
		{ query: getModelQL, variables: { id } }
	);

export const editModelUserRole = (modelId, userId, role) =>
	requests.qlAuthRequest(
		[
			actionTypes.EDIT_MODEL_USER_ROLE_PENDING,
			normalizedSuccess(actionTypes.EDIT_MODEL_USER_ROLE_SUCCESS, ['data', 'model'], modelUsersSchema),
			actionTypes.EDIT_MODEL_USER_ROLE_FAIL,
		],
		{ query: addUserToModelGL, variables: { modelId, userId, role } }
	);

export const deleteModel = (id) => (dispatch, getState) => {
	const state = getState();
	const selectedId = selectedModelId(state);

	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.DELETE_MODEL_PENDING,
				normalizedSuccess(actionTypes.DELETE_MODEL_SUCCESS, ['data', 'models'], modelSchema, () => {
					if (id === selectedId) {
						dispatch(removePreviousModel());
					}
				}),
				actionTypes.DELETE_MODEL_FAIL,
			],
			{ query: deleteModelQL, variables: { id } }
		)
	);
};

export const deleteModelVersion = (id) => (dispatch, getState) => {
	const state = getState();
	const selectedId = selectedModelVersionId(state);

	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.DELETE_MODEL_VERSION_PENDING,
				normalizedSuccess(actionTypes.DELETE_MODEL_VERSION_SUCCESS, ['data', 'versions'], modelVersionSchema, () => {
					if (id === selectedId) {
						dispatch(removePreviousModel());
					}
				}),
				actionTypes.DELETE_MODEL_VERSION_FAIL,
			],
			{ query: deleteModelVersionQL, variables: { modelVersionId: id } }
		)
	);
};

export const validateActiveModelVersion = () => (dispatch, getState) => {
	const state = getState();
	const rawYaml = getRawYaml(state);

	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.VALIDATE_MODEL_VERSION_PENDING,
				actionTypes.VALIDATE_MODEL_VERSION_SUCCESS,
				actionTypes.VALIDATE_MODEL_VERSION_FAIL,
			],
			{ query: validateModelVersionQl, variables: { rawYaml } }
		)
	);
};

export const fetchModelVersion = (id) =>
	requests.qlAuthRequest(
		[
			actionTypes.FETCH_MODEL_VERSION_PENDING,
			normalizedSuccess(actionTypes.FETCH_MODEL_VERSION_SUCCESS, ['data', 'versions'], modelVersionSchema),
			actionTypes.FETCH_MODEL_VERSION_FAIL,
		],
		{ query: getModelVersion, variables: { id } }
	);

export const createModel = (name, description) =>
	requests.qlAuthRequest(
		[
			actionTypes.CREATE_MODEL_PENDING,
			normalizedSuccess(actionTypes.CREATE_MODEL_SUCCESS, ['data', 'models'], modelSchema),
			actionTypes.CREATE_MODEL_FAIL,
		],
		{
			query: createModelQL,
			variables: { name, description },
		}
	);

const _selectModel = makeActionCreator(actionTypes.SELECT_MODEL, 'id');

export const selectModel = (id, selectLatestVersion) => async (dispatch) => {
	const response = await dispatch(fetchModel(id));

	try {
		dispatch(_selectModel(response.payload.result));
		if (selectLatestVersion) {
			const latestVersionId = compose(last, keys)(response.payload.entities.versions);
			dispatch(selectModelVersion(latestVersionId));
		}

		return response;
	} catch (e) {
		Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { modelId: id } });
		console.warn(e); // we can suppress the error because this is not critical functionality
		return response;
	}
};

export const _selectModelVersion = makeActionCreator(actionTypes.SELECT_MODEL_VERSION, 'id');

export const selectModelVersion = (id) => async (dispatch) => {
	const response = await dispatch(fetchModelVersion(id));

	try {
		dispatch(validateActiveModelVersion());
		dispatch(_selectModelVersion(id));
		return response;
	} catch (e) {
		Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { modelVersionId: id } });
		console.warn(e); // we can suppress the error because this is not critical functionality
		return response;
	}
};

export const updateModel = (data) =>
	requests.qlAuthRequest(
		[
			actionTypes.UPDATE_MODEL_PENDING,
			normalizedSuccess(actionTypes.UPDATE_MODEL_SUCCESS, ['data', 'models'], modelSchema),
			actionTypes.UPDATE_MODEL_FAIL,
		],
		{ query: updateModelQL, variables: { modelId: data.id, name: data.name, description: data.description } }
	);

export const getModelsBasicInfo = () =>
	requests.qlAuthRequest(
		[
			actionTypes.MODELS_INFO_PENDING,
			normalizedSuccess(actionTypes.MODELS_INFO_SUCCESS, ['data', 'models'], modelListSchema),
			actionTypes.MODELS_INFO_FAIL,
		],
		{ query: getModelsBasicInfoQuery }
	);

export const createModelVersion = ({ model, name, description, version, data }) =>
	requests.qlAuthRequest(
		[
			actionTypes.CREATE_MODEL_VERSION_PENDING,
			normalizedSuccess(actionTypes.CREATE_MODEL_VERSION_SUCCESS, ['data', 'versions'], modelVersionSchema),
			actionTypes.CREATE_MODEL_VERSION_FAIL,
		],
		{ query: createModelVersionQL, variables: { model, name, description, version, data } }
	);

export const updateModelVersDesc = (id, description) =>
	requests.qlAuthRequest(
		[
			actionTypes.UPDATE_MODEL_VERSION_PENDING,
			normalizedSuccess(actionTypes.UPDATE_MODEL_VERSION_SUCCESS, ['data', 'versions'], modelVersionSchema),
			actionTypes.UPDATE_MODEL_VERSION_FAIL,
		],
		{ query: updateModelVersionQL, variables: { modelVersionId: id, description } }
	);

export const saveModelVersion = (modelVersionDesc) => async (dispatch, getState) => {
	const modelId = selectedModelId(getState());

	// Make sure the latest version has been fetched
	await dispatch(fetchModel(modelId));

	const state = getState();
	const { name, description } = getModel(modelId, state);
	const data = getProjectData(state);
	const { version: maxVersion } = getMaximumModelVersion(modelId, state);
	const version = increaseMinorVersion(maxVersion);

	const result = await dispatch(
		createModelVersion({ model: modelId, name, description: modelVersionDesc || description, version, data })
	);
	dispatch(showNotification(INTL.formatMessage(messages.versionSaved, { version }), notificationTypes.INFO));
	return result;
};

export const fetchExtractorsData = () => (dispatch, getState) => {
	const state = getState();
	const extractors = getExtractors(state);

	if (!isEmpty(extractors)) {
		return;
	}

	return dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_EXTRACTORS_DATA_PENDING,
				actionTypes.FETCH_EXTRACTORS_DATA_SUCCESS,
				actionTypes.FETCH_EXTRACTORS_DATA_FAIL,
			],
			{ query: getExtractorsQl }
		)
	);
};

export const createConfig = (modelVersionId) => (dispatch, getState) => {
	const state = getState();
	const type = getActiveModelConfigType(state);

	return dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.CREATE_CONFIG_PENDING,
				normalizedSuccess(actionTypes.CREATE_CONFIG_SUCCESS, ['data', 'configs'], configSchema),
				actionTypes.CREATE_CONFIG_FAIL,
			],
			{ query: createConfigQL, variables: { type, modelVersionId } }
		)
	);
};

export const deleteConfigs = (ids) => (dispatch) => {
	for (const id of ids) {
		dispatch(
			requests.qlAuthRequest(
				[actionTypes.DELETE_CONFIG_PENDING, actionTypes.DELETE_CONFIG_SUCCESS, actionTypes.DELETE_CONFIG_FAIL],
				{ query: deleteConfigQL, variables: { id } }
			)
		);
	}
};

export const trainConfig = (versionId) => async (dispatch, getState) => {
	const errors = getYamlErrors(getState());
	if (errors.length > 0) {
		errors.forEach((issue, issueIndex) => {
			dispatch(removeYamlErrorWarning(0, 'error'));
		});
	}
	const warnings = getYamlWarnings(getState());
	if (warnings.length > 0) {
		warnings.forEach((issue, issueIndex) => {
			dispatch(removeYamlErrorWarning(0, 'warning'));
		});
	}

	const state = getState();
	const yamlData = getRawYaml(state);
	const modelVersionId = versionId || selectedModelVersionId(state);
	if (!modelVersionId) {
		dispatch(showNotification(INTL.formatMessage(messages.selectVersionBeforeTraining)));
	} else if (!isYamlValid(yamlData)) {
		const { errors: rawYamlErrors } = parseYaml(yamlData);
		dispatch(setYamlErrorWarning(rawYamlErrors, 'error'));
		dispatch(showNotification(INTL.formatMessage(messages.yamlUnknownError)));
	} else {
		const project = getProjectData(state);
		const modelVersionConfig = getActiveModelVersionConfig(state);

		let configId = modelVersionConfig.id;
		if (!configId) {
			const response = await dispatch(createConfig(modelVersionId));
			if (response.error) {
				dispatch(showNotification(INTL.formatMessage(messages.yamlUnknownError)));
				return response;
			} else {
				configId = response.payload?.result;
			}
		}

		const meta = { requestId: generateUniqueId(modelVersionId), versionId: modelVersionId };
		return await dispatch(
			requests.qlAuthRequest(
				[
					{ type: actionTypes.TRAIN_CONFIG_PENDING, meta },
					{ ...normalizedSuccess(actionTypes.TRAIN_CONFIG_SUCCESS, ['data', 'configs'], configSchema), meta },
					{ type: actionTypes.TRAIN_CONFIG_FAIL, meta },
				],
				{ query: trainConfigQL, variables: { id: configId, trainingData: yamlData, jsonData: project } }
			)
		);
	}
};

export const restoreSelectedModelVersion = (modelVersionId) => async (dispatch) => {
	const data = await dispatch(selectModelVersion(modelVersionId));
	const { error, payload } = data;
	const { errors } = payload;

	if (errors) {
		return dispatch(showNotification(errors[0].message));
	} else if (error) {
		// notification is shown by default by `apiErrorHandler` so we just log it here
		console.error(`Unexpected response for ${modelVersionId}: ${JSON.stringify(data)}`);
		Sentry.captureException(error, { extra: { modelVersionId, payload } });
	} else {
		const modelId = payload.entities.versions[payload.result].model;

		return dispatch(fetchModel(modelId));
	}
};

export const deleteImage = (id) =>
	requests.qlAuthRequest(
		[
			actionTypes.DELETE_IMAGE_PENDING,
			normalizedSuccess(actionTypes.DELETE_IMAGE_SUCCESS, ['data', 'images'], imagesSchema),
			actionTypes.DELETE_IMAGE_FAIL,
		],
		{ query: deleteImageQL, variables: { id } }
	);

export const deleteModelAsset = (id) =>
	requests.qlAuthRequest(
		[
			actionTypes.DELETE_MODEL_ASSET_PENDING,
			normalizedSuccess(actionTypes.DELETE_MODEL_ASSET_SUCCESS, ['data', 'assets'], modelAssetsSchema),
			actionTypes.DELETE_MODEL_ASSET_FAIL,
		],
		{ query: deleteModelAssetQl, variables: { id } }
	);

export const upsertModelAsset = (modelId, asset) => {
	if (asset.id) {
		return requests.qlAuthRequest(
			[
				actionTypes.UPDATE_MODEL_ASSET_PENDING,
				normalizedSuccess(actionTypes.UPDATE_MODEL_ASSET_SUCCESS, ['data', 'asset'], modelAssetsSchema),
				actionTypes.UPDATE_MODEL_ASSET_FAIL,
			],
			{ query: updateModelAssetQl, variables: asset }
		);
	} else {
		return requests.qlAuthRequest(
			[
				actionTypes.CREATE_MODEL_ASSET_PENDING,
				normalizedSuccess(actionTypes.CREATE_MODEL_ASSET_SUCCESS, ['data', 'asset'], modelAssetsSchema),
				actionTypes.CREATE_MODEL_ASSET_FAIL,
			],
			{ query: createModelAssetQl, variables: { modelId, ...asset } }
		);
	}
};

export const uploadAudioFile = ({ name, data }) => (dispatch, getState) => {
	const state = getState();
	const modelId = selectedModelId(state);
	const formData = new FormData();

	formData.append('file', data.fileData, data.fileName);
	formData.append('modelId', modelId);
	formData.append('name', name || data.fileName);

	dispatch(setIsFetchingAssets(true));

	return dispatch(
		requests.rsaaAuthFormData(
			'uploadAudioFile',
			[
				actionTypes.UPLOAD_MODEL_ASSET_PENDING,
				actionTypes.UPLOAD_MODEL_ASSET_SUCCESS,
				actionTypes.UPLOAD_MODEL_ASSET_FAIL,
			],
			formData
		)
	);
};

export const fetchHealths = () => requests.qlRequest(psf(`${ID}/FETCH_APP_HEALTHS`), { query: getHealthsQL });
