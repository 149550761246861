import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { updateModel, updateModelVersDesc } from '../../redux/model-table/actions';
import keyMirror from '../../utils/keyMirror';
import { validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

export const MODAL_UPDATE_TYPES = keyMirror({
	MODEL_DESC: null,
	VERSION_DESC: null,
});

const UpdateModelAndVersionModal = ({ id, currDataStr, modalUpdateType }) => {
	const [errorDataStr, setErrorDataStr] = useState(null);
	const [newDataStr, setNewDataStr] = useState(currDataStr || '');
	const dispatch = useDispatch();
	const entity = (() => {
		switch (modalUpdateType) {
			case MODAL_UPDATE_TYPES.MODEL_DESC:
				return {
					text: <FormattedMessage {...messages.editProjectDesc} />,
					action: updateModel({ id: id, description: newDataStr }),
					doNotValidate: true,
				};
			case MODAL_UPDATE_TYPES.VERSION_DESC:
				return {
					text: <FormattedMessage {...messages.editProjectVersionDesc} />,
					action: updateModelVersDesc(id, newDataStr),
				};
			default:
				break;
		}
	})();

	const handleAdd = async () => {
		setErrorDataStr(!entity.doNotValidate ? validateIsEmpty(newDataStr) : null);

		if (!entity.doNotValidate && validateIsEmpty(newDataStr)) {
			return;
		}

		dispatch(entity.action);
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<SaveCloseModalTemplate title={entity.text} saveBtnTitle={entity.text} onSave={handleAdd}>
			<TextField
				value={newDataStr}
				error={!!errorDataStr}
				helperText={errorDataStr}
				fullWidth
				onChange={(e) => {
					setNewDataStr(e.target.value);
					setErrorDataStr(!entity.doNotValidate && validateIsEmpty(e.target.value));
				}}
				onKeyPress={handleKeyPress}
				label={<FormattedMessage {...messages.projectDescription} />}
				variant="standard"
			/>
		</SaveCloseModalTemplate>
	);
};

UpdateModelAndVersionModal.propTypes = {
	id: PropTypes.string,
	currDataStr: PropTypes.string,
	modalUpdateType: PropTypes.string,
};

export default UpdateModelAndVersionModal;
