import { Autocomplete } from '@mui/lab';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../../constants';
import { RESPONSE_TYPES, TRANSITION_TYPES } from '../../core/configs';
import { INTL } from '../../intl';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { createUpdateResponses, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import { getIntents, getNodeOutputtedMarkdownOptions, getTransitions } from '../../redux/model/selectors';
import { showNotification } from '../../redux/notification/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	bounding: {
		'& .warning': {
			background: theme.palette.warning.main,
			color: 'white',
			padding: '10px',
			marginTop: '5px',
		},
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
		'& .MuiOutlinedInput-root': {
			padding: '0.5rem',
			maxHeight: '3rem',
		},
	},
}));

const variants = ['primary', , 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link'];
const visibilities = ['shown', 'hidden'];

/**
 * @param object intents
 * @param object transitionsActions
 * @returns List of utterances, extracted from node's intents,
 * Autocomplete sortable by targetNodeId, e.g. [ { utterance, targetNodeId }, ... ]
 */
const getUtterancesUsedInNodeSortable = (intents, transitionsActions) => {
	const sortableUtteracnces = [];

	for (const [targetNodeId, intentName] of Object.entries(transitionsActions || {})) {
		const utterances = intents[intentName];

		if (!utterances || isEmpty(utterances)) {
			continue;
		}

		for (const utterance of utterances) {
			sortableUtteracnces.push({ utterance, targetNodeId });
		}
	}

	return sortableUtteracnces;
};

/**
 * @param object intents
 * @param object transitionsActions
 * @returns List of utterances, extracted from node's intents,
 * Autocomplete sortable by targetNodeId, e.g. [ { utterance, targetNodeId }, ... ]
 */
const AddMarkdownOptionModal = ({ nodeId, initMarkdownOption }) => {
	const classes = useStyles();
	const { [RESPONSE_TYPES.MARKDOWN_OPTIONS]: markdownOptions } = useSelector(getNodeOutputtedMarkdownOptions(nodeId));
	const [newMarkdownOption, setNewMarkdownOption] = useState('');
	const [visibility, setVisibility] = useState(visibilities[0]);
	const [variant, setVariant] = useState(variants[0]);
	const [advancedStyling, setAdvancedStyling] = useState(false);
	const [warning, setWarning] = useState(false);
	const [defaultMarkdownOption, setDefaultMarkdownOption] = useState('');

	const dispatch = useDispatch();

	const intents = useSelector(getIntents);
	const transitionsActions = useSelector(getTransitions(nodeId, TRANSITION_TYPES.ACTIONS));
	const utterancesUsedInNodeSortable = getUtterancesUsedInNodeSortable(intents, transitionsActions);

	useEffect(() => {
		if (initMarkdownOption) {
			if (typeof initMarkdownOption === 'object') {
				dispatch(
					showNotification(
						INTL.formatMessage(messages.addingUtteranceToMarkdownOptionsObjectsNotification, { nodeId }),
						notificationTypes.ERROR
					)
				);
				dispatch(hideModal());
			} else {
				const parts = initMarkdownOption.split('|');
				if (parts.length > 1) {
					setDefaultMarkdownOption(parts[2]);
					setNewMarkdownOption(parts[2]);
					setVisibility(parts[0]);
					setVariant(parts[1]);
					setAdvancedStyling(true);
				} else {
					setDefaultMarkdownOption(parts[0]);
					setNewMarkdownOption(parts[0]);
				}
			}
		}
	}, [initMarkdownOption]);

	const handleSave = () => {
		let newMarkdownOptions = markdownOptions.slice();

		const areMarkdownOptionsObjects = newMarkdownOptions.length > 0 && typeof markdownOptions[0] === 'object';
		if (areMarkdownOptionsObjects) {
			dispatch(
				showNotification(
					INTL.formatMessage(messages.addingUtteranceToMarkdownOptionsObjectsNotification, { nodeId }),
					notificationTypes.ERROR
				)
			);
			return;
		}

		const newMarkdownOptionWithStyle = advancedStyling
			? visibility + '|' + variant + '|' + newMarkdownOption
			: newMarkdownOption;

		newMarkdownOptions = newMarkdownOptions.filter((option) => option !== initMarkdownOption);

		dispatch(
			registerStateBeforeDispatchingAction(
				createUpdateResponses(nodeId, {
					markdownOptions: [...newMarkdownOptions, newMarkdownOptionWithStyle],
				})
			)
		);
		dispatch(hideModal());
	};

	const handleOnChange = (inputValue) => {
		setNewMarkdownOption(inputValue);

		for (const { utterance } of utterancesUsedInNodeSortable) {
			if (utterance === inputValue || isEmpty(utterance)) {
				setWarning(false);
				return;
			}
		}

		setWarning(true);
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages[initMarkdownOption ? 'editUtteranceButton' : 'addUtteranceButton']} />}
				saveBtnTitle={
					<FormattedMessage {...messages[initMarkdownOption ? 'editUtteranceButton' : 'addUtteranceButton']} />
				}
				onSave={handleSave}
				isSaveBtnDisabled={isEmpty(newMarkdownOption)}
				className={classes.bounding}
			>
				<div className={classes.bounding}>
					<Autocomplete
						freeSolo
						defaultValue={{ utterance: defaultMarkdownOption }}
						getOptionLabel={(option) => option.utterance}
						groupBy={(option) => option.targetNodeId}
						options={utterancesUsedInNodeSortable.sort((a, b) => -b.targetNodeId.localeCompare(a.targetNodeId))}
						renderInput={(params) => (
							<TextField
								{...params}
								autoFocus
								label={<FormattedMessage {...messages.selectUtterance} />}
								variant={'outlined'}
								fullWidth
								onChange={(e) => handleOnChange(e.target.value)}
							/>
						)}
						onChange={(e, v) => {
							setNewMarkdownOption(v?.utterance ? v.utterance : '');
							setWarning(false);
						}}
					/>
					{warning && (
						<div className="warning">
							<FormattedMessage {...messages.utteranceNotReachable} />
						</div>
					)}
					<br />
					<FormGroup>
						<FormControlLabel
							control={<Checkbox checked={advancedStyling} onChange={() => setAdvancedStyling(!advancedStyling)} />}
							label="Advanced styling"
						/>
					</FormGroup>
					{advancedStyling && (
						<Grid container style={{ marginTop: '1rem' }} spacing={3}>
							<Grid item xs={4}>
								<FormControl variant="outlined" style={{ margin: 2 }} fullWidth>
									<InputLabel id="select-visibility">
										<FormattedMessage {...messages.visibility} />
									</InputLabel>
									<Select
										labelId="select-visibility"
										value={visibility}
										variant="outlined"
										onChange={(e) => setVisibility(e.target.value)}
										label={<FormattedMessage {...messages.visibility} />}
									>
										{visibilities.map((visibility) => (
											<MenuItem key={'visibility-' + visibility} value={visibility}>
												{visibility}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={4}>
								<FormControl variant="outlined" sx={{ m: 1 }} fullWidth>
									<InputLabel id="select-variant">
										<FormattedMessage {...messages.variant} />
									</InputLabel>
									<Select
										labelId="select-variant"
										value={variant}
										variant="outlined"
										onChange={(e) => setVariant(e.target.value)}
										label={<FormattedMessage {...messages.variant} />}
									>
										{variants.map((variant) => (
											<MenuItem key={'variant' + variant} value={variant}>
												{variant}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					)}
				</div>
			</SaveCloseModalTemplate>
		</div>
	);
};

AddMarkdownOptionModal.propTypes = {
	nodeId: PropTypes.string,
	initMarkdownOption: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			img: PropTypes.string,
			label: PropTypes.string,
			utterance: PropTypes.string,
			target_node: PropTypes.string,
			theme: PropTypes.string,
		}),
	]),
};

export default AddMarkdownOptionModal;
