import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { isSelectedModelVersionTraining } from '../../redux/model-table/selectors';
import { getIsModelLocked } from '../../redux/model/selectors';

const useStyles = makeStyles((theme) => ({
	iconBtn: {
		padding: '8px !important',
		minWidth: 'auto !important',
	},
	round: {
		borderRadius: '50% !important',
	},
	icon: {
		width: 20,
		height: 20,
		margin: 0,
		color: 'white',
	},
	title: {
		marginLeft: '5px',
	},
	input: {
		display: 'none',
	},
}));

const UploadFileButton = ({ placement, showIconOnly, accept, onFileSelected, multiple = false, title }) => {
	const classes = useStyles();
	const isModelVersionTraining = useSelector(isSelectedModelVersionTraining);
	const isModelLocked = useSelector(getIsModelLocked);

	const handleLoadFilesFromDisk = (e) => {
		const files = e.target.files;

		for (const file of files) {
			onFileSelected(file, file.name);
		}

		// This is here so that onChange is always trigerred.
		e.target.value = null;
	};

	const BTN_ID = 'contained-upload-button-file';

	return (
		<>
			<input
				type="file"
				id={BTN_ID}
				accept={accept}
				multiple={multiple}
				className={classes.input}
				onChange={handleLoadFilesFromDisk}
			/>
			<label htmlFor={BTN_ID}>
				<Tooltip title={showIconOnly ? title : ''} placement={placement || 'top'}>
					<Button
						variant={showIconOnly ? 'text' : 'contained'}
						disabled={isModelVersionTraining || isModelLocked}
						component="span"
						className={clsx(classes.iconBtn, showIconOnly ? classes.round : '')}
					>
						<>
							<CloudUploadIcon className={classes.icon} />
							{!showIconOnly && title && <div className={classes.title}>{title}</div>}
						</>
					</Button>
				</Tooltip>
			</label>
		</>
	);
};

UploadFileButton.propTypes = {
	accept: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	multiple: PropTypes.bool,
	onFileSelected: PropTypes.func,
	placement: PropTypes.string,
	showIconOnly: PropTypes.bool,
};

export default UploadFileButton;
