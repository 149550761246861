import { produce } from 'immer';
import { pickBy, toPairs, uniq } from 'ramda';
import { PROPERTY_TYPES, RESPONSE_TYPES, TRANSITION_TYPES } from '../../core/configs';
import { actionTypes as modelTableActionTypes } from '../model-table/actions';
import { getIntents, getNodesForDiagram } from '../model/selectors';
import { actionTypes } from './actions';

const DEFAULT_STATE = {
	searchResults: [],
	searchValue: '',
};

const flowEditor = produce((state, { type, payload }, fullState) => {
	switch (type) {
		case actionTypes.SEARCH_AND_SET_RESULTS:
			const { searchValue, searchOperator, ignoreCase } = payload;
			const intents = getIntents(fullState);
			const nodes = getNodesForDiagram(fullState);
			const matches = [];

			let regex;
			switch (searchOperator) {
				case 'includes':
					regex = new RegExp(`${searchValue}`, ignoreCase ? 'i' : '');
					break;
				case 'startsWith':
					regex = new RegExp(`^${searchValue}`, ignoreCase ? 'i' : '');
					break;
				case 'endsWith':
					regex = new RegExp(`${searchValue}$`, ignoreCase ? 'i' : '');
					break;
			}

			for (const [nodeId, node] of Object.entries(nodes)) {
				if (regex.test(nodeId)) {
					matches.push({
						type: 'nodeId',
						value: nodeId,
						nodeId,
					});
				}

				// TODO: replace by getResponses from branch externals/responses when merged
				const responses = pickBy((value, key) => !isNaN(key), node);

				const flattenedResponses = Object.entries(responses).map(([responseKey, response]) => {
					const [responseType, value] = toPairs(response)[0];
					return { responseKey, value, responseType };
				});
				for (const { responseKey, value, responseType } of flattenedResponses) {
					let meetsCriterion = (value) => regex.test(value);
					if (responseType === RESPONSE_TYPES.MARKDOWN_OPTIONS) {
						meetsCriterion = (markdownOptions) => markdownOptions.some((markdownOption) => regex.test(markdownOption));
					}
					if (meetsCriterion(value)) {
						matches.push({
							type: 'response',
							responseType,
							responseKey,
							// To properly display markdown options
							value: JSON.stringify(value),
							nodeId,
						});
					}
				}

				const variables = uniq([...(node[PROPERTY_TYPES.SET] || []), ...(node[PROPERTY_TYPES.CALL] || [])]);
				for (const variable of variables) {
					const [variableKey, variableValue] = Object.entries(variable)[0];
					if (regex.test(variableKey) || (typeof variableValue === 'string' && regex.test(variableValue))) {
						matches.push({
							type: 'variable',
							// In case the value is e.g. specifying a smartFunction
							value: JSON.stringify(variableValue),
							variableKey,
							nodeId,
						});
					}
				}

				for (const [targetNodeId, intentId] of Object.entries(node[TRANSITION_TYPES.ACTIONS] || {})) {
					let meetsCriterion = (value) => regex.test(value);
					// In case the intent is specified directly as a list of utterances and not intentId pointing to the utterances
					if (Array.isArray(intentId)) {
						meetsCriterion = (utterances) => utterances.some((utterance) => regex.test(utterance));
					}

					if (meetsCriterion(intentId)) {
						matches.push({
							type: 'intent',
							value: intentId,
							intentTargetNodeId: targetNodeId,
							nodeId,
						});
					}
				}
			}

			for (const [intentId, utterances] of Object.entries(intents)) {
				const meetsCriterion = (utterances) => utterances.some((utterance) => regex.test(utterance));
				if (meetsCriterion(utterances)) {
					matches.push({
						type: 'utterances',
						value: utterances,
						intentId,
					});
				}
			}

			state.searchResults = matches;
			break;
		case actionTypes.SET_SEARCH_VALUE:
			state.searchValue = payload.searchValue;
			break;
		case modelTableActionTypes.FETCH_MODEL_VERSION_SUCCESS:
			state.searchResults = [];
			state.searchValue = '';
	}
}, DEFAULT_STATE);

export default flowEditor;
