import keyMirror from '../../utils/keyMirror';
import { stringToBoolean } from '../../utils/normalization';

export const PROPERTY_TYPES = keyMirror({
	EXTRACT: null,
	CALL: null,
	RESET: null,
	SET: null,
});

export const LOCALE = {
	CS: 'cs-CZ',
	EN: 'en-US',
	PL: 'pl-PL',
	SK: 'sk-SK',
};

export const STT_BOOST = 'stt_boost';

export const PROPERTY_TYPES_RESET = {
	ALL: 'all',
	EXCEPT: 'except',
	INCLUDING: 'including',
};

export const RESPONSE_TYPES = keyMirror({
	MARKDOWN: null,
	MARKDOWN_OPTIONS: null,
	SPEECH: null,
	WIDGET: null,
	WIDGET_OPTIONS: null,
});

export const TRANSITION_TYPES = keyMirror({
	ACTIONS: null,
	ACTIONS_SIGNALS: null,
	CONDITIONS: null,
	GOTO: null,
	GOTO_REUSE_UTTERANCE: null,
	GOTO_WAIT: null,
	GOTO_BACK: null,
	GOTO_CONVERSATION_LOGIC: null,
});

export const GOTO_TRANSITION_TYPES = Object.freeze(Object.keys(TRANSITION_TYPES).filter((t) => t.includes('GOTO')));

export const SIGNAL_TRANSITION_TYPES = {
	NO_INPUT: 'no_input',
	NO_MATCH: 'no_match',
	CUSTOMER_HANGUP: 'call_end_customer_hangup',
	SHUT_UP: 'shut_up',
};

export const ELK_INTEGRATION_ENABLED = stringToBoolean(process.env.ELK_INTEGRATION_ENABLED || 'false');

export const USE_KEYCLOAK = stringToBoolean(process.env.USE_KEYCLOAK || 'false');
export const KEYCLOAK_URI = process.env.KEYCLOAK_URI || '';
export const REALM_NAME = process.env.REALM_NAME || '';
export const CLIENT_ID = process.env.CLIENT_ID || '';
