import * as React from 'react';
import { useSelector } from 'react-redux';
import { getActiveModelVersionConfig } from '../redux/model-table/selectors';
import LSM from '../utils/LocalStorageManager';

const { TYPING_HIDDEN, HIDDEN } = LSM.CHAT_CONFIGURATION;

const handleChangedConfigHash = (configHash, isTrained) => {
	const bubbleHidden = LSM.getChatBubbleConfigProperty(HIDDEN);
	if (configHash && !bubbleHidden && isTrained) {
		window.bubble.postMessageToIframe({ type: 'BUBBLE_MSG_TYPE_NEW_CONFIG_HASH', payload: configHash });

		// On some environments (e.g. development.borndigital.ai), the iframe redirects itself to the builder page instead of showing the chat
		// Waiting for a short period and reloading the src forces the iframe to show proper content
		const srcSetter = () => {
			if (!firstLoad) {
				firstLoad = true;
				// you always get the original URL of the iframe from the `getAttribute('src')` even
				// if the real URL of the `$iframe.contentWindow.location.href` is different.
				// But if you set the `src` it forces the browser to reload the iframe anyway.
				window.bubble.$iframe.setAttribute('src', window.bubble.$iframe.getAttribute('src'));
				window.bubble.show();
			}
		};

		let firstLoad = false;
		window.bubble.$iframe.addEventListener('load', srcSetter);
	} else {
		window.bubble.hide();
	}
};

/**
 * Unfortunately the bubble is not npm package nor the component so we need to add it to the code like this.
 * Memoization is there to prevent initialization of the script multiple times.
 * See https://bitbucket.org/goodaidigital/standalone-bubble/src/38668e8ef12904c0f6591314f5f968e96b2d81e7/bubble/src/bubble/bubble.tsx#lines-246
 */
export const ChatBotBubble = () => {
	const { configHash, trained } = useSelector(getActiveModelVersionConfig);

	const bubbleConfiguration = {
		[TYPING_HIDDEN]: LSM.getChatBubbleConfigProperty(TYPING_HIDDEN),
	};

	React.useEffect(() => {
		if (!window.bubble && window.bubbleFactory) {
			window.bubble = window.bubbleFactory({ bubbleConfiguration });
		}

		if (bubbleConfiguration[HIDDEN]) {
			window.bubble.onInit(() => window.bubble.hide());
		}

		return () => window.bubble?.wasInitialized && window.bubble.hide();
	}, [window.bubbleFactory]);

	// reset chat bubble when new model is selected
	React.useEffect(() => {
		if (!window.bubble) {
			return;
		}

		if (window.bubble.wasInitialized) {
			handleChangedConfigHash(configHash, trained);
		} else {
			window.bubble.onInit(() => handleChangedConfigHash(configHash, trained));
		}
	}, [configHash, trained, window.bubble?.wasInitialized]);

	return null;
};
