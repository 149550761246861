import { produce } from 'immer';
import { equals, omit, pipe } from 'ramda';
import { actionTypes } from './actions';

const DEFAULT_STATE = [];

const uinotification = produce((notifications, { type: actionType, payload: notification }) => {
	switch (actionType) {
		case actionTypes.SHOW_NOTIFICATION:
			const oldNotification = notifications.find(pipe(omit(['count']), equals(notification)));

			if (oldNotification) {
				oldNotification.count += 1;
			} else {
				notifications.push({ ...notification, count: 1 });
			}
			break;
		case actionTypes.CLEAR_NOTIFICATION:
			notifications.shift();
			break;
	}
}, DEFAULT_STATE);

export default uinotification;
