import { Refresh, Info, Delete as DeleteIcon, Edit as EditIcon, FileCopy as CopyIcon } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import {
	DataGrid,
	GridActionsCellItem,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import isEmpty from 'lodash/isEmpty';
import { omit, pick } from 'ramda';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages, translateDataGrid } from '../../intl';
import { palette } from '../../mui-theme-builder';
import { deleteModelAsset, upsertModelAsset } from '../../redux/model-table/actions';
import { getIsFetchingAssets, getSpecificAssetsType, selectedModelId } from '../../redux/model-table/selectors';
import { getConfiguration } from '../../redux/model/selectors';
import { copyTextToClipboard } from '../../utils/clipboard';
import { AssetAnnouncement, repeatSummary } from '../model-assets/AssetAnnouncement';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	bounding: {
		'& .MuiDataGrid-root': {
			padding: '0 !important',
		},
		'& .running': {
			backgroundColor: theme.palette.warning.light,
		},
		'& .finished': {
			backgroundColor: theme.palette.primary.light,
		},
		'& .disabled': {
			backgroundColor: theme.palette.grey[100],
		},
	},
}));

const ASSET_TYPES = Object.freeze({
	WIDGET_ANNOUNCEMENT: {
		label: 'announcement',
	},
});

const buildColumns = (handleDelete, handleDuplicate, handleEdit, intl) => [
	{
		field: 'enabled',
		headerName: intl.formatMessage(messages.enabled),
		type: 'boolean',
		editable: true,
		valueGetter: (params) => params.row.data.enabled,
		width: 120,
	},
	{
		field: 'name',
		headerName: intl.formatMessage(messages.name),
		type: 'string',
		editable: true,
		width: 200,
	},
	{
		field: 'text',
		headerName: intl.formatMessage(messages.text),
		type: 'string',
		editable: true,
		flex: 1,
		valueGetter: (params) => params.row.data.text || '',
	},
	{
		field: 'voice',
		headerName: intl.formatMessage(messages.Voice),
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.data.speech || '',
	},
	{
		field: 'start',
		headerName: intl.formatMessage(messages.dateInterval),
		type: 'date',
		editable: true,
		width: 150,
		valueGetter: (params) =>
			`${new Date(params.row.data.startsAt).toLocaleDateString('en-US')} - ${new Date(
				params.row.data.endsAt
			).toLocaleDateString('en-US')}`,
	},
	{
		field: 'end',
		headerName: intl.formatMessage(messages.timeInterval),
		type: 'date',
		editable: true,
		width: 150,
		valueGetter: (params) => `${params.row.data.startsDailyAt} - ${params.row.data.endsDailyAt}`,
	},
	{
		field: 'status',
		headerName: intl.formatMessage(messages.status),
		type: 'date',
		editable: true,
		width: 100,
		valueGetter: (params) => `${rowClass(params?.row?.data ?? null)}`,
	},
	{
		field: 'actions',
		type: 'actions',
		headerName: 'Actions',
		width: 150,
		filterable: false,
		getActions: (params) => [
			<GridActionsCellItem
				icon={params.row.data.isInfinite ? <Info /> : <Refresh />}
				title={repeatSummary(params.row.data)}
			/>,
			<GridActionsCellItem icon={<EditIcon />} title="Edit" onClick={() => handleEdit(params)} />,
			<GridActionsCellItem icon={<CopyIcon />} label="Duplicate" onClick={() => handleDuplicate(params)} showInMenu />,
			<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => handleDelete(params.id)} showInMenu />,
			<GridActionsCellItem
				icon={<CopyIcon />}
				label="Copy ID to clipboard "
				onClick={() => copyTextToClipboard(params.id)}
				showInMenu
			/>,
		],
	},
];

const rowClass = (params) => {
	if (!params) {
		return '';
	}

	const now = +new Date();
	const isRunning = now > params.startsAt && (now < params.endsAt || params.isInfinite);
	const isFinished = now > params.endsAt && !params.isInfinite;

	return !params.enabled ? 'disabled' : isRunning ? 'running' : isFinished ? 'finished' : 'scheduled';
};

const ModelAnnouncementsModal = () => {
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();
	const modelId = useSelector(selectedModelId);
	const configuration = useSelector(getConfiguration);
	const defaultAssetType = Object.keys(ASSET_TYPES)[0];

	const announcments = useSelector((state) => getSpecificAssetsType(modelId, 'WIDGET_ANNOUNCEMENT', true, state));
	const isFetchingAssets = useSelector(getIsFetchingAssets());

	const [currentAsset, setCurrentAsset] = useState({ type: defaultAssetType, data: {} });
	const [isAddingAsset, setIsAddingAsset] = useState(false);
	const [isEditingAsset, setIsEditingAsset] = useState(false);

	const title = messages[isEditingAsset ? 'editAnnouncement' : isAddingAsset ? 'newAnnouncement' : 'modelAnnouncement'];
	const modalTitle = configuration.project
		? `${intl.formatMessage(title)} (${configuration.project})`
		: intl.formatMessage(title);

	const handleAssetDataChange = (data) => {
		setCurrentAsset({ ...currentAsset, data });
	};

	const handleAssetCreate = () => {
		switch (currentAsset.type) {
			case 'WIDGET_ANNOUNCEMENT':
				dispatch(
					upsertModelAsset(modelId, {
						...currentAsset,
						name: currentAsset.data.name,
						data: omit(['name'], currentAsset.data),
					})
				);
				break;
			default:
				break;
		}

		handleGoBack();
	};

	const handleAddAsset = () => {
		setCurrentAsset({ type: currentAsset.type, data: {} });
		setIsAddingAsset(true);
	};

	const handleDuplicate = (e) => {
		setIsAddingAsset(true);
		setCurrentAsset({ ...pick(['type'], e.row), data: { ...e.row.data, name: e.row.name + '_copy' } });
	};

	const handleDelete = (id) => {
		dispatch(deleteModelAsset(id));
	};

	const handleEdit = (e) => {
		setIsEditingAsset(true);
		setCurrentAsset({ ...e.row, data: { ...e.row.data, name: e.row.name } });
	};

	const handleGoBack = () => {
		setCurrentAsset({ type: currentAsset.type, data: {} });
		setIsAddingAsset(false);
		setIsEditingAsset(false);
	};

	const handleEditCellCommit = (e) => {
		const currentRow = currentAsset.data;
		Object.keys(currentRow).forEach((key) => {
			if (e.field === key) {
				currentRow[key] = e.value;
			}
		});
		setCurrentAsset({ ...currentAsset, data: { ...currentRow } });
		handleAssetCreate();
	};

	const handleEditCellStart = (e) => {
		setCurrentAsset({ ...e.row, data: { ...e.row.data, name: e.row.name } });
	};

	const dataGridConfig = {
		columns: buildColumns(handleDelete, handleDuplicate, handleEdit, intl),
		autoHeight: true,
		density: 'compact',
		hideFooter: true,
		rows: announcments,
		onCellEditCommit: handleEditCellCommit,
		onCellEditStart: handleEditCellStart,
		localeText: translateDataGrid(intl),
		components: {
			Toolbar: () => (
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
			),
		},
	};

	const isAddEditView = isAddingAsset || isEditingAsset;

	return (
		<div>
			<SaveCloseModalTemplate
				title={modalTitle}
				isSaveBtnVisible={isAddEditView}
				isSaveBtnDisabled={!currentAsset.data?.name || isEmpty(currentAsset.data)}
				saveBtnTitle={intl.formatMessage(messages.save)}
				onSave={handleAssetCreate}
				maxWidth={isAddEditView ? 'md' : 'xl'}
				headerButton={
					<Button
						onClick={isAddEditView ? handleGoBack : handleAddAsset}
						style={{ background: isAddEditView ? grey[600] : palette.primary.main, float: 'right' }}
						variant="contained"
						disabled={!modelId}
					>
						{intl.formatMessage(messages[isAddEditView ? 'backToList' : 'newAnnouncement'])}
					</Button>
				}
			>
				{!modelId ? (
					intl.formatMessage(messages.chooseModelFirst)
				) : isAddEditView ? (
					<AssetAnnouncement
						onDataChange={handleAssetDataChange}
						data={(isAddingAsset || isEditingAsset) && !isEmpty(currentAsset.data) ? currentAsset.data : null}
					/>
				) : (
					<>
						{announcments && (
							<div className={classes.bounding} style={{ width: '100%' }}>
								<DataGrid {...dataGridConfig} getRowClassName={(params) => rowClass(params?.row?.data ?? null)} />
							</div>
						)}
						{isFetchingAssets && <CircularProgress style={{ margin: 'auto' }} />}
					</>
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

export default ModelAnnouncementsModal;
