import React from 'react';
import { FormattedDate as FormattedDateIntl } from 'react-intl';

/**
 * Intl date wrapper. Adjust formatting here, use it everywhere.
 */
// eslint-disable-next-line react/prop-types
const FormattedDate = ({ value, ...props }) => (
	<>
		{value ? (
			<FormattedDateIntl
				year={'numeric'}
				month={'numeric'}
				day={'numeric'}
				hour={'numeric'}
				minute={'numeric'}
				second={'numeric'}
				hour12={false}
				value={value}
				{...props}
			/>
		) : null}
	</>
);

export default FormattedDate;
