import Autocomplete from '@mui/lab/Autocomplete';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { flatten, isEmpty, reverse, uniq } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { PROPERTY_TYPES_RESET } from '../../core/configs/consts';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { createUpdateVariablesToReset, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import { getAllSmartFunctionsOutput, getAllVariables } from '../../redux/model/selectors';
import InfoButton from '../InfoButton/InfoButton';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: 13,
	},
	formLabel: {
		color: theme.palette.primary.main,
	},
}));

const ResetVariablesModal = ({ nodeId, prevVariablesToReset }) => {
	const [variablesToReset, setVariablesToReset] = useState(prevVariablesToReset);
	const existingVariables = useSelector(getAllVariables).map((variable) => variable[0]);
	const smartFunctionsOutput = useSelector(getAllSmartFunctionsOutput);
	const variables = uniq(flatten([existingVariables, smartFunctionsOutput]));
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleSave = () => {
		const varList =
			variablesToReset.type === PROPERTY_TYPES_RESET.ALL
				? [variablesToReset.type]
				: variablesToReset.list.concat(variablesToReset.type);

		dispatch(registerStateBeforeDispatchingAction(createUpdateVariablesToReset(nodeId, varList)));
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.resetVariables} />}
			saveBtnTitle={<FormattedMessage {...messages.confirm} />}
			onSave={handleSave}
			isSaveBtnDisabled={variablesToReset.type !== PROPERTY_TYPES_RESET.ALL && isEmpty(variablesToReset.list)}
		>
			<FormattedMessage {...messages.selectVariable} />
			<br />
			<br />
			<FormControl component="fieldset" fullWidth>
				<FormLabel component="legend" className={classes.formLabel}>
					<FormattedMessage {...messages.type} />:
				</FormLabel>
				<RadioGroup
					value={variablesToReset.type}
					onChange={(_, type) => setVariablesToReset({ ...variablesToReset, type })}
				>
					<Grid container spacing={0}>
						{reverse(Object.values(PROPERTY_TYPES_RESET)).map((_handleType) => (
							<Grid item xs key={_handleType}>
								<FormControlLabel
									classes={{
										label: classes.label,
									}}
									value={_handleType}
									control={<Radio color="primary" />}
									label={_handleType.toUpperCase()}
								/>
								<InfoButton title={'resetVariablesModalInfo_' + _handleType} />
							</Grid>
						))}
					</Grid>
				</RadioGroup>
			</FormControl>
			<br />
			<br />
			<strong>
				<FormattedMessage {...messages['resetVariables_' + variablesToReset.type]} />
			</strong>
			<br />
			{variablesToReset.type !== PROPERTY_TYPES_RESET.ALL && (
				<Autocomplete
					multiple
					fullWidth
					options={variables}
					value={variablesToReset.list}
					getOptionLabel={(option) => option}
					renderInput={(params) => (
						<TextField {...params} variant={'outlined'} label={<FormattedMessage {...messages.selectVariable} />} />
					)}
					onChange={(e, list) => setVariablesToReset({ ...variablesToReset, list })}
					variant="standard"
				/>
			)}
		</SaveCloseModalTemplate>
	);
};

ResetVariablesModal.propTypes = {
	nodeId: PropTypes.string,
	prevVariablesToReset: PropTypes.shape({
		type: PropTypes.oneOf(Object.values(PROPERTY_TYPES_RESET)).isRequired,
		list: PropTypes.arrayOf(PropTypes.string).isRequired,
	}),
};

export default ResetVariablesModal;
