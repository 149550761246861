import keyMirror from '../../utils/keyMirror';
import { PROPERTY_TYPES, RESPONSE_TYPES, TRANSITION_TYPES } from './consts';

export default {
	responseTypes: RESPONSE_TYPES,
	propertyTypes: PROPERTY_TYPES,
	transitionTypes: TRANSITION_TYPES,
	transitionOrder: keyMirror({
		[TRANSITION_TYPES.ACTIONS_SIGNALS]: null,
		[TRANSITION_TYPES.ACTIONS]: null,
		[TRANSITION_TYPES.CONDITIONS]: null,
		[TRANSITION_TYPES.GOTO]: null,
		// Show these after better design, see https://cgi-goodai.slack.com/archives/CPQ3ELGHM/p1586182942003000
		// [TRANSITION_TYPES.GOTO_WAIT]: null,
		// [TRANSITION_TYPES.GOTO_REUSE_UTTERANCE]: null,
	}),
};
