import { notificationTypes } from '../../constants';
import * as requests from '../../core/requests';
import { utteranceAnalysisQl } from '../../graphql/model';
import { createActionsMap } from '../../utils/action-utils';
import parseUtteranceTesterInputData from '../../utils/parseUtteranceTesterInputData';
import { showNotification } from '../notification/actions';

export const ID = 'utteranceTester';

export const actionTypes = createActionsMap(ID, [
	'UTTERANCES_STATS_PENDING',
	'UTTERANCES_STATS_SUCCESS',
	'UTTERANCES_STATS_FAIL',
]);

export const fetchUtterancesStats = (configHash, utterances) =>
	requests.qlAuthRequest(
		[actionTypes.UTTERANCES_STATS_PENDING, actionTypes.UTTERANCES_STATS_SUCCESS, actionTypes.UTTERANCES_STATS_FAIL],
		{ query: utteranceAnalysisQl, variables: { configHash, utterances } }
	);

export const updateCsvData = (configHash, data) => async (dispatch) => {
	try {
		const parsedData = await parseUtteranceTesterInputData(data);
		dispatch(fetchUtterancesStats(configHash, parsedData));
	} catch (error) {
		dispatch(showNotification(error.message, notificationTypes.ERROR));
	}
};
