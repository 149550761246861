import { FileCopy as CopyIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Chip,
	FormControl,
	FormControlLabel,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Slider,
	Switch,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Checkbox,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	IconButton,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { last, remove } from 'ramda';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../../constants';
import { sttProviders, ttsProviders } from '../../core/configs/voice';
import { INTL, messages } from '../../intl';
import { fetchOrganizations } from '../../redux/auth/actions';
import { getOrganizations } from '../../redux/auth/selectors';
import { hideModal } from '../../redux/modal/actions';
import {
	getActiveModelVersionConfig,
	getBackgroundMusicFiles,
	getSelectedModel,
} from '../../redux/model-table/selectors';
import { getConfiguration } from '../../redux/model/selectors';
import { showNotification } from '../../redux/notification/actions';
import {
	assignPhoneNumbersToConfig,
	unassignPhoneNumberFromConfig,
	fetchAvailablePhoneNumbers,
	fetchVoiceNames,
} from '../../redux/voice/actions';
import {
	getAvailablePhoneNumbers,
	getAvailablePhoneNumbersAssignedToVersion,
	getVoiceNames,
} from '../../redux/voice/selectors';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import { normalizePhoneNumber } from '../../utils/normalization';
import Blacklist from '../Blacklist';
import TrainButton from '../TrainButton';
import Whitelist from '../Whitelist';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	formField: {
		width: '100%',
		marginBottom: '20px !important',
	},
	tabContainer: {
		display: 'flex',
	},
	formContainer: {
		padding: '10px',
		position: 'relative',
	},
	deleteButton: {
		position: 'absolute',
		right: '0px',
		marginBottom: '0 !important',
		padding: '0 !important',
		paddingRight: '6px',
	},
	tabs: {
		overflow: 'unset',
	},
	indicator: {
		background: theme.palette.primary.main,
	},
	tab: {
		minWidth: '160px !important',
		marginRight: '10px !important',
	},
	musicItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	accordionGrid: {
		marginTop: '1.5rem',
	},
	accordionGridBottom: {
		margin: '1rem 0 3rem 0',
	},
	accordion: {
		overflow: 'hidden',
		width: '100%',
	},
}));

const filter = createFilterOptions();

const VersionConfigModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const phoneNumbers = useSelector(getAvailablePhoneNumbers);
	const { id: configId, configHash } = useSelector(getActiveModelVersionConfig);
	const configuration = useSelector(getConfiguration);
	const backgroundMusicFiles = useSelector(getBackgroundMusicFiles);
	const [inputBgMusicManually, setInputBgMusicManually] = useState(false);
	const [assignedPhoneNumbers, setAssignedPhoneNumbers] = useState(
		useSelector(getAvailablePhoneNumbersAssignedToVersion)
	);
	const [activePhoneNumber, setActivePhoneNumber] = useState(
		assignedPhoneNumbers.length ? assignedPhoneNumbers[0].phoneNumber : ''
	);
	const organizations = useSelector(getOrganizations);
	const voiceNames = useSelector(getVoiceNames);
	const selectedModelId = useSelector(getSelectedModel).id ?? null;

	useEffect(() => {
		dispatch(fetchVoiceNames(configuration?.language));
		dispatch(fetchAvailablePhoneNumbers(selectedModelId));
		dispatch(fetchOrganizations());
	}, []);

	const [currentInputValue, setCurrentInputValue] = useState('');
	const handlePhoneNumberInputChange = (e, newValue) => {
		newValue = normalizePhoneNumber(newValue);
		setCurrentInputValue(newValue);
	};

	// These numbers cannot be unselected in the Autocomplete to avoid confusion about deleting them
	// To unassign these numbers, there is a special button instead
	const assignedAndSavedPhoneNumbers = phoneNumbers.filter((number) => number.config === configId);

	// A number was selected from the options or a new option was created
	const handleAssignedPhoneNumbersChange = (event, newValue) => {
		const numbersToBeFiltered = assignedAndSavedPhoneNumbers.map((number) => number.phoneNumber);

		setAssignedPhoneNumbers([
			...assignedAndSavedPhoneNumbers,
			...newValue.filter((number) => !numbersToBeFiltered.includes(number.phoneNumber)),
		]);

		if (newValue.length) {
			setActivePhoneNumber(last(newValue).phoneNumber);
		} else if (!newValue.length && !numbersToBeFiltered.length) {
			setActivePhoneNumber('');
		}
	};
	const setPhoneNumberProperty = (name, value) => {
		setAssignedPhoneNumbers((numbers) => {
			const index = numbers.findIndex((number) => number.phoneNumber === activePhoneNumber);
			const updatedNumbers = numbers.slice();
			if (index > -1) {
				updatedNumbers.splice(index, 1, { ...numbers[index], [name]: value });
			}
			return updatedNumbers;
		});
	};

	const assignPhoneNumber = () => {
		dispatch(assignPhoneNumbersToConfig(configId, assignedPhoneNumbers));

		dispatch(showNotification(INTL.formatMessage(messages.assigningPhoneNumbersNotification), notificationTypes.INFO));
		dispatch(hideModal());
	};

	const targetNumber = () => {
		return assignedPhoneNumbers.find((number) => number.phoneNumber === activePhoneNumber) || {};
	};

	const unassignPhoneNumber = (index) => {
		dispatch(unassignPhoneNumberFromConfig(assignedPhoneNumbers[index]));

		const assignedPhoneNumbersWithoutDeletedNumber = remove(index, 1, assignedPhoneNumbers);
		setAssignedPhoneNumbers(assignedPhoneNumbersWithoutDeletedNumber);
		setActivePhoneNumber(
			assignedPhoneNumbersWithoutDeletedNumber.length ? assignedPhoneNumbersWithoutDeletedNumber[0].phoneNumber : ''
		);
	};
	const getOrgName = (id) => {
		let name = 'Unassigned';
		if (id) {
			name = organizations.find((org) => org.id === id)?.name;
			if (typeof name === 'undefined') {
				name = 'Assigned to model collaborators';
			}
		}
		return name;
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={configuration.project}
				saveBtnTitle={<FormattedMessage {...messages.confirm} />}
				onSave={assignPhoneNumber}
				isSaveBtnDisabled={!assignedPhoneNumbers.length}
				maxWidth="md"
			>
				{configHash ? (
					<>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									variant="standard"
									classes={{ root: classes.formField }}
									label={<FormattedMessage {...messages.hashId} />}
									value={configHash}
									fullWidth
									InputProps={{
										readOnly: true,
										startAdornment: (
											<InputAdornment position="start">
												<CopyIcon />
											</InputAdornment>
										),
									}}
									autoFocus
									onFocus={({ target }) => {
										target.setSelectionRange(0, target.value.length);
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Autocomplete
									classes={{ root: classes.formField }}
									multiple
									freeSolo
									options={phoneNumbers.sort((a, b) =>
										typeof b.organization === 'string' ? b.organization.localeCompare(a.organization) : 1
									)}
									blurOnSelect
									filterOptions={(options, params) => {
										const filtered = filter(options, params);

										// Suggest the creation of a new value
										if (params.inputValue !== '') {
											filtered.push({
												name: 'Custom number',
												phoneNumber: params.inputValue,
											});
										}

										return filtered;
									}}
									value={assignedPhoneNumbers}
									onChange={handleAssignedPhoneNumbersChange}
									inputValue={currentInputValue}
									onInputChange={handlePhoneNumberInputChange}
									getOptionLabel={(option) => option.phoneNumber}
									groupBy={(option) => getOrgName(option.organization)}
									renderTags={(values, getValueProps) => {
										const fixedNumbers = assignedAndSavedPhoneNumbers.map((number) => number.phoneNumber);

										return values.map((value, index) => {
											const isFixed = fixedNumbers.includes(value.phoneNumber);

											return (
												<Tooltip
													title={
														isFixed ? (
															<FormattedMessage {...messages.assignedAndSavedPhoneNumberExplanation} />
														) : (
															value.name || 'No name'
														)
													}
												>
													<Chip
														label={formatPhoneNumber(value.phoneNumber)}
														{...getValueProps({ index })}
														disabled={isFixed}
														style={{ pointerEvents: 'auto' }}
													/>
												</Tooltip>
											);
										});
									}}
									renderOption={(props, option) => (
										<option {...props}>
											{formatPhoneNumber(option.phoneNumber).concat(option.name ? ` - ${option.name}` : '')}
										</option>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											label={<FormattedMessage {...messages.assignedPhoneNumbers} />}
											helperText={<FormattedMessage {...messages.assignPhoneNumberWarning} />}
										/>
									)}
								/>
							</Grid>
						</Grid>

						<div className={classes.tabContainer}>
							<Tabs
								classes={{ root: classes.tabs, indicator: classes.indicator }}
								value={activePhoneNumber}
								onChange={(event, newValue) => setActivePhoneNumber(newValue)}
								variant="scrollable"
								orientation="vertical"
							>
								{assignedPhoneNumbers.map((number, index) => (
									<Tab
										classes={{ root: classes.tab }}
										key={index}
										value={number.phoneNumber}
										label={formatPhoneNumber(number.phoneNumber)}
										icon={
											activePhoneNumber === number.phoneNumber &&
											number.config === configId && (
												<Tooltip title={<FormattedMessage {...messages.unassignPhoneNumber} />}>
													<IconButton
														classes={{ root: classes.deleteButton }}
														aria-label="delete"
														onClick={(e) => {
															// The onChange handler of <Tabs> would otherwise be called
															e.stopPropagation();
															unassignPhoneNumber(index);
														}}
													>
														<LinkOffIcon />
													</IconButton>
												</Tooltip>
											)
										}
									/>
								))}
							</Tabs>
							{activePhoneNumber ? (
								<Grid container classes={{ root: classes.formContainer }}>
									<Grid item xs={12}>
										<TextField
											variant="standard"
											classes={{ root: classes.formField }}
											name="name"
											label={<FormattedMessage {...messages.friendlyName} />}
											value={targetNumber().name}
											onChange={(e) => setPhoneNumberProperty('name', e.target.value)}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.sttProvider} />
											</InputLabel>
											<Select
												variant="standard"
												name="sttProvider"
												value={targetNumber().sttProvider}
												onChange={(e) => setPhoneNumberProperty('sttProvider', e.target.value)}
											>
												{sttProviders.map((provider) => (
													<MenuItem key={provider} value={provider}>
														{provider}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.ttsProvider} />
											</InputLabel>
											<Select
												variant="standard"
												name="ttsProvider"
												value={targetNumber().ttsProvider}
												onChange={(e) => setPhoneNumberProperty('ttsProvider', e.target.value)}
											>
												{ttsProviders.map((provider) => (
													<MenuItem key={provider} value={provider}>
														{provider}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									{targetNumber().ttsProvider === 'AZURE' ? (
										<Grid item xs={12}>
											<FormControl className={classes.formField}>
												<InputLabel>
													<FormattedMessage {...messages.voiceName} />
												</InputLabel>
												<Select
													variant="standard"
													name="ttsVoiceName"
													value={targetNumber().ttsVoiceName}
													onChange={(e) => setPhoneNumberProperty('ttsVoiceName', e.target.value)}
												>
													{voiceNames.map(({ value }) => (
														<MenuItem key={value} value={value}>
															{value}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									) : null}
									<Grid item xs={12}>
										<FormControlLabel
											label={<FormattedMessage {...messages.recordCalls} />}
											control={
												<Switch
													name="recordCalls"
													checked={targetNumber().recordCalls}
													onChange={(e, value) => setPhoneNumberProperty('recordCalls', value)}
													color="primary"
												/>
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.ttsRate} />
											</InputLabel>
											<Slider
												name="ttsRate"
												value={targetNumber().ttsRate || 0}
												valueLabelDisplay="auto"
												step={1}
												min={-100}
												max={100}
												onChange={(e, value) => setPhoneNumberProperty('ttsRate', value)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.ttsPitch} />
											</InputLabel>
											<Slider
												name="ttsPitch"
												value={targetNumber().ttsPitch || 0}
												valueLabelDisplay="auto"
												step={1}
												min={-100}
												max={100}
												onChange={(e, value) => setPhoneNumberProperty('ttsPitch', value)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.userNoInputLimit} />
											</InputLabel>
											<Slider
												name="userNoInputLimit"
												value={targetNumber().userNoInputLimit || 0}
												valueLabelDisplay="auto"
												step={1}
												min={0}
												max={30}
												onChange={(e, value) => setPhoneNumberProperty('userNoInputLimit', value)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													checked={inputBgMusicManually}
													onChange={(e) => setInputBgMusicManually(event.target.checked)}
												/>
											}
											label={<FormattedMessage {...messages.inputBgMusicManually} />}
										/>
										{inputBgMusicManually ? (
											<TextField
												variant="standard"
												className={classes.formField}
												value={targetNumber().bgMusicFilename}
												onChange={(e) => setPhoneNumberProperty('bgMusicFilename', e.target.value)}
												label={<FormattedMessage {...messages.bgMusicFilename} />}
											/>
										) : (
											<FormControl className={classes.formField}>
												<InputLabel>
													<FormattedMessage {...messages.bgMusicFilename} />{' '}
												</InputLabel>
												<Select
													variant="standard"
													name="bgMusicFilename"
													value={targetNumber().bgMusicFilename}
													onChange={(e) => setPhoneNumberProperty('bgMusicFilename', e.target.value)}
													renderValue={(value) => {
														const index = backgroundMusicFiles.findIndex((file) => file.data?.fileId === value);
														return backgroundMusicFiles[index]?.name;
													}}
												>
													{[{ name: 'none', data: { fileId: '' } }, ...backgroundMusicFiles].map(
														({ name, data }, index) => (
															<MenuItem key={index} value={data.fileId} classes={{ root: classes.musicItem }}>
																<span>{name}</span>
																<span style={{ fontSize: '0.8em', color: 'grey' }}>{data.fileId}</span>
															</MenuItem>
														)
													)}
												</Select>
											</FormControl>
										)}
									</Grid>
									<Grid item xs={12}>
										<FormControl className={classes.formField}>
											<InputLabel>
												<FormattedMessage {...messages.bgMusicVolume} />
											</InputLabel>
											<Slider
												name="bgMusicVolume"
												value={Math.round(targetNumber().bgMusicVolume * 100) || 100}
												valueLabelDisplay="auto"
												step={5}
												min={0}
												max={200}
												onChange={(e, value) => setPhoneNumberProperty('bgMusicVolume', value / 100)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={12} className={classes.accordionGrid}>
										<Accordion className={classes.accordion}>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<FormattedMessage {...messages.whitelist} />
											</AccordionSummary>
											<AccordionDetails>
												<Whitelist
													configHash={configHash}
													whitelist={targetNumber().whitelist || []}
													onChange={(e, value) => setPhoneNumberProperty('whitelist', value)}
												/>
											</AccordionDetails>
										</Accordion>
									</Grid>
									<Grid item xs={12} className={classes.accordionGridBottom}>
										<Accordion className={classes.accordion}>
											<AccordionSummary expandIcon={<ExpandMoreIcon />}>
												<FormattedMessage {...messages.blacklist} />
											</AccordionSummary>
											<AccordionDetails>
												<Blacklist
													blacklist={targetNumber().blacklist || []}
													onChange={(e, value) => setPhoneNumberProperty('blacklist', value)}
												/>
											</AccordionDetails>
										</Accordion>
									</Grid>
								</Grid>
							) : null}
						</div>
					</>
				) : (
					<>
						<FormattedMessage {...messages.trainVersionToSeeConfig} />:
						<br />
						<br />
						<TrainButton />
					</>
				)}
			</SaveCloseModalTemplate>
		</div>
	);
};

export default VersionConfigModal;
