import * as Sentry from '@sentry/react';
import keyMirror from '../utils/keyMirror';

class LSM {
	_keyList = keyMirror({
		KEYCLOAK_IDENTITY: null,
		refresh_token: null,
		addLibIntentPanePosition: null,
		autosave: null,
		dashboardAccordionPanel: null,
		dashboardModelsListScrollTop: null,
		flowEditorPanePosition: null,
		selectedModelVersionId: null,
	});

	// Though the options are stored together in localStorage, each value is used in a different place
	CHAT_CONFIGURATION = {
		// Used in the Builder app itself to (not0 display the chat iframe
		HIDDEN: 'chatBubbleHidden',
		// Sent into the ChatBubble upon creating it because the content of the iframe does not share the localStorage with the Builder
		TYPING_HIDDEN: 'chatBubbleTypingIndicatorHidden',
	};

	get = (key) => {
		try {
			return localStorage.getItem(key);
		} catch (e) {
			console.warn(`error reading ${key} from localStorage`, e);
			Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { key } });
			return null;
		}
	};

	set = (key, value) => {
		try {
			localStorage.setItem(key, value);
			return true;
		} catch (e) {
			console.error(`error setting ${key} to localStorage`, e);
			Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { key } });
			return false;
		}
	};

	remove = (key) => {
		try {
			return localStorage.removeItem(key);
		} catch (e) {
			console.warn(`error removing ${key} from localStorage`, e);
			Sentry.captureException(e, { level: Sentry.Severity.Warning, extra: { key } });
			return null;
		}
	};
	removeAll = () => Object.values(this._keyList).map((key) => this.remove(key));

	getSelectedModelVersionId = () => this.get(this._keyList.selectedModelVersionId);
	setSelectedModelVersionId = (id) => this.set(this._keyList.selectedModelVersionId, id);
	removeSelectedModelVersionId = () => this.remove(this._keyList.selectedModelVersionId);

	getAccessToken = () => this.get(this._keyList.KEYCLOAK_IDENTITY);
	setAccessToken = (token) => this.set(this._keyList.KEYCLOAK_IDENTITY, token);
	removeAccessToken = () => this.remove(this._keyList.KEYCLOAK_IDENTITY);

	getRefreshToken = () => this.get(this._keyList.refresh_token);
	setRefreshToken = (token) => this.set(this._keyList.refresh_token, token);
	removeRefreshToken = () => this.remove(this._keyList.refresh_token);

	getFlowEditorPanePosition = () => this.get(this._keyList.flowEditorPanePosition) || '50%';
	setFlowEditorPanePosition = (position) => this.set(this._keyList.flowEditorPanePosition, position);

	getAddLibIntentPanePosition = () => this.get(this._keyList.addLibIntentPanePosition) || '75%';
	setAddLibIntentPanePosition = (position) => this.set(this._keyList.addLibIntentPanePosition, position);

	getAutosaveData = () => {
		const data = this.get(this._keyList.autosave);
		return data ? JSON.parse(data) : data;
	};
	setAutosaveData = (data) => this.set(this._keyList.autosave, JSON.stringify(data));

	// LSM stores only strings, 'false' !== false...
	getChatBubbleConfigProperty = (property) => this.get(property) === 'true';

	getDashboardAccordionPanel = () => this.get(this._keyList.dashboardAccordionPanel) || '';
	setDashboardAccordionPanel = (id) => this.set(this._keyList.dashboardAccordionPanel, id);

	getDashboardModelsListScrollTop = () => this.get(this._keyList.dashboardModelsListScrollTop) || 0;
	setDashboardModelsListScrollTop = (position) => this.set(this._keyList.dashboardModelsListScrollTop, position);
}

export default new LSM();
