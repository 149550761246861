import { notificationTypes } from '../../constants';
import { createActionsMap } from '../../utils/action-utils';

export const ID = 'uinotification';

export const actionTypes = createActionsMap(ID, ['SHOW_NOTIFICATION', 'CLEAR_NOTIFICATION']);

/**
 * Sets message for notification. (can be extended to utilize onClick and so on)
 *
 * @param {string} message displaying in notification.
 * @param {string} type - One of INFO, WARNING, ERROR.
 */
export const showNotification = (message, type = notificationTypes.ERROR) => ({
	type: actionTypes.SHOW_NOTIFICATION,
	payload: {
		message,
		type,
	},
});

export const clearNotification = () => ({
	type: actionTypes.CLEAR_NOTIFICATION,
});
