import * as Sentry from '@sentry/react';

const blobToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => resolve(e.target.result.substr(e.target.result.indexOf(',') + 1));
		reader.onerror = (err) => {
			console.error('Error: ' + err);
			Sentry.captureException(err, { extra: { file } });
			reject(err);
		};
		reader.readAsDataURL(file);
	});
};

export default blobToBase64;
