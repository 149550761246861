import { pick } from 'ramda';
import xlsx from 'xlsx';
import { notificationTypes } from '../../constants';
import * as requests from '../../core/requests';
import {
	createCampaign as createCampaignQL,
	deleteCampaigns as deleteCampaignsQL,
	getCallStatusesQl,
	getCampaign as getCampaignQL,
	getCampaigns as getCampaignsQL,
	getConversation as getConversationQL,
	updateCampaign as updateCampaignQL,
	updateCampaignStatusQL,
} from '../../graphql/outbound';
import { messages } from '../../intl';
import { createActionsMap, makeActionCreator } from '../../utils/action-utils';
import keyMirror from '../../utils/keyMirror';
import { selectedModelId } from '../model-table/selectors';
import { showNotification } from '../notification/actions';
import { psf } from '../utils';

export const ID = 'outbound';

export const actionTypes = createActionsMap(ID, [
	'UPDATE_CALLER_NUMBER',
	'UPDATE_TRUNK_ACCOUNT',
	'UPDATE_CAMPAIGN_NAME',
	'UPDATE_META_DATA',
	'UPDATE_NUMBERS',
	'UPDATE_PARAM',
	'UPLOAD_CAMPAIGN',
	...psf('FETCH_CAMPAIGN'),
	...psf('FETCH_CAMPAIGNS'),
	...psf('FETCH_CREATE_CAMPAIGN'),
	...psf('FETCH_CONVERSATION'),
	...psf('FETCH_DELETE_CAMPAIGNS'),
	...psf('FETCH_UPDATE_CAMPAIGN_STATUS'),
	...psf('FETCH_UPDATE_CAMPAIGN'),
	...psf('FETCH_CALL_STATUSES'),
]);

export const CALL_LIST_STATUS = keyMirror({
	WAITING: null,
	IN_PROGRESS: null,
	PAUSED: null,
	STOP: null,
	FINISHED: null,
});

export const CALL_STATUS = {
	BUSY: 'busy',
	NO_ANSWER: 'no-answer',
	PAUSED: 'paused',
	FAILED: 'failed',
	ERROR: 'error',
};

const PHONE_CALL_FIELDS = [
	'metaData',
	'phoneNumber',
	'callId',
	'calledAt',
	'status',
	'trials',
	'error',
	'messageSent',
	'id',
	'firstCallTime',
];

export const updateCampaignName = makeActionCreator(actionTypes.UPDATE_CAMPAIGN_NAME, 'campId', 'name');
export const updateCallerNumber = makeActionCreator(actionTypes.UPDATE_CALLER_NUMBER, 'campId', 'number');
export const updateTrunkAccount = makeActionCreator(actionTypes.UPDATE_TRUNK_ACCOUNT, 'campId', 'trunkAccount');
export const updateMetaData = makeActionCreator(actionTypes.UPDATE_META_DATA, 'campaign', 'metaData');
export const updateNumbers = makeActionCreator(actionTypes.UPDATE_NUMBERS, 'campId', 'phoneNumber', 'metaData');
export const updateMetaDataParam = makeActionCreator(actionTypes.UPDATE_PARAM, 'campId', 'number', 'param', 'value');

export const downloadCampaignJSON = (campaign) => {
	const data = JSON.stringify(campaign);

	const element = document.createElement('a');
	element.href = URL.createObjectURL(new Blob([data], { type: 'application/json' }));
	element.download = `${campaign.name}.json`;
	element.click();
};

export const downloadCampaignCSV = (campaignData) => {
	const workbook = generateSpreadsheet(campaignData);

	const campaignElement = document.createElement('a');
	campaignElement.href = URL.createObjectURL(
		new Blob([xlsx.utils.sheet_to_csv(workbook.Sheets['Campaign'])], { type: 'text/csv;charset=utf-8' })
	);
	campaignElement.download = `${campaignData.name}_campaign.csv`;
	campaignElement.click();

	const callsElement = document.createElement('a');
	callsElement.href = URL.createObjectURL(
		new Blob([xlsx.utils.sheet_to_csv(workbook.Sheets['Phone calls'])], { type: 'text/csv;charset=utf-8' })
	);
	callsElement.download = `${campaignData.name}_phone-calls.csv`;
	callsElement.click();
};

export const downloadCampaignXLS = (campaignData) => {
	const xlsSpreadsheet = generateSpreadsheet(campaignData);
	xlsx.writeFile(xlsSpreadsheet, `${campaignData.name}.xls`);
};

const generateSpreadsheet = (campaignData) => {
	const workbook = xlsx.utils.book_new();

	const campaignFields = [
		'id',
		'name',
		'status',
		'callStatusNotifier',
		'phoneNumberTwilio',
		'twilioFlowId',
		'createdAt',
		'lastRoundFinishedAt',
		'maxCallTrials',
		'maxParallelCalls',
		'repeatCallDelayInMinutes',
		'repeatCallsAt',
		'finishedRounds',
		'callStatusesToRepeat',
	];

	const campaignWithouCalls = pick(campaignFields, campaignData);
	['createdAt', 'lastRoundFinishedAt'].forEach((dateProperty) => {
		if (campaignWithouCalls[dateProperty]) {
			campaignWithouCalls[dateProperty] = new Date(campaignWithouCalls[dateProperty]).toLocaleString();
		}
	});
	// xlsx works on an array of JS objects, hence the square brackets
	const campaignSheet = xlsx.utils.json_to_sheet([campaignWithouCalls]);

	const flattenedPhoneCalls = Object.values(campaignData.phoneCalls).map((phoneCall) => {
		const flatCall = { ...phoneCall, ...phoneCall.metaData };
		Reflect.deleteProperty(flatCall, 'metaData');

		if (phoneCall.calledAt) {
			flatCall.calledAt = new Date(phoneCall.calledAt).toLocaleString();
		}

		return flatCall;
	});
	const phoneCallsSheet = xlsx.utils.json_to_sheet(flattenedPhoneCalls);

	xlsx.utils.book_append_sheet(workbook, campaignSheet, 'Campaign');
	xlsx.utils.book_append_sheet(workbook, phoneCallsSheet, 'Phone calls');

	return workbook;
};
export const createCampaign = (variables) => (dispatch, getState) => {
	const modelId = selectedModelId(getState());
	return dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_CREATE_CAMPAIGN_PENDING,
				actionTypes.FETCH_CREATE_CAMPAIGN_SUCCESS,
				actionTypes.FETCH_CREATE_CAMPAIGN_FAIL,
			],
			{
				query: createCampaignQL,
				variables: { ...variables, modelId },
			}
		)
	);
};

export const updateCampaign = ({
	id,
	name,
	modelId,
	createdAt,
	lastRoundFinishedAt,
	twilioFlowId,
	phoneNumberTwilio,
	trunkAccount,
	maxParallelCalls,
	maxCallTrials,
	repeatCallDelayInMinutes,
	repeatCallsAt,
	callStatusNotifier,
	callStatusesToRepeat,
	phoneCalls,
	status,
	messageList,
	launch,
	intl,
}) => async (dispatch) => {
	const _updateCampaign = (variables) =>
		dispatch(
			requests.qlAuthRequest(
				[
					actionTypes.FETCH_UPDATE_CAMPAIGN_PENDING,
					actionTypes.FETCH_UPDATE_CAMPAIGN_SUCCESS,
					actionTypes.FETCH_UPDATE_CAMPAIGN_FAIL,
				],
				{
					query: updateCampaignQL,
					variables,
				}
			)
		);

	if (status !== CALL_LIST_STATUS.STOP && launch) {
		// 1. save, then launch camp
		const data = await _updateCampaign({
			id,
			name,
			modelId,
			phoneCalls: Object.values(phoneCalls).map(pick(PHONE_CALL_FIELDS)),
			phoneNumberTwilio,
			status: CALL_LIST_STATUS.IN_PROGRESS,
			twilioFlowId,
			trunkAccount,
		});

		if (data.payload.data.callLists && intl) {
			dispatch(showNotification(intl.formatMessage(messages.campSavedAndLaunched), notificationTypes.INFO));
		}
	} else if (status === CALL_LIST_STATUS.STOP && launch) {
		// 2. just launch camp
		const data = await _updateCampaign({
			id,
			name,
			modelId,
			phoneNumberTwilio,
			status: CALL_LIST_STATUS.IN_PROGRESS,
			twilioFlowId,
			trunkAccount,
		});

		if (data.payload.data.callLists && intl) {
			dispatch(showNotification(intl.formatMessage(messages.campLaunchedAgain), notificationTypes.INFO));
		}
	} else {
		// 3. just save camp
		const data = await _updateCampaign({
			id,
			name,
			modelId,
			createdAt,
			lastRoundFinishedAt,
			status,
			twilioFlowId,
			phoneNumberTwilio,
			trunkAccount,
			maxParallelCalls,
			maxCallTrials,
			repeatCallDelayInMinutes,
			callStatusNotifier,
			callStatusesToRepeat,
			repeatCallsAt,
			phoneCalls: Object.values(phoneCalls).map(pick(PHONE_CALL_FIELDS)),
			messageList,
		});

		if (data.payload.data.callLists && intl) {
			dispatch(showNotification(intl.formatMessage(messages.campSaved), notificationTypes.INFO));
		}

		return data;
	}
};

export const fetchCampaignsForActiveModel = () => (dispatch, getState) => {
	const modelId = selectedModelId(getState());
	if (modelId) {
		dispatch(
			requests.qlAuthRequest(
				[actionTypes.FETCH_CAMPAIGNS_PENDING, actionTypes.FETCH_CAMPAIGNS_SUCCESS, actionTypes.FETCH_CAMPAIGNS_FAIL],
				{
					query: getCampaignsQL,
					variables: { modelId },
				}
			)
		);
	}
};

export const fetchCampaign = (id) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[actionTypes.FETCH_CAMPAIGN_PENDING, actionTypes.FETCH_CAMPAIGN_SUCCESS, actionTypes.FETCH_CAMPAIGN_FAIL],
			{
				query: getCampaignQL,
				variables: { id },
			}
		)
	);

export const deleteCampaigns = (list) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_DELETE_CAMPAIGNS_PENDING,
				actionTypes.FETCH_DELETE_CAMPAIGNS_SUCCESS,
				actionTypes.FETCH_DELETE_CAMPAIGNS_FAIL,
			],
			{
				query: deleteCampaignsQL,
				variables: { ids: list },
			}
		)
	);

export const updateCampaignStatus = (id, status) => (dispatch) => {
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_UPDATE_CAMPAIGN_STATUS_PENDING,
				actionTypes.FETCH_UPDATE_CAMPAIGN_STATUS_SUCCESS,
				actionTypes.FETCH_UPDATE_CAMPAIGN_STATUS_FAIL,
			],
			{
				query: updateCampaignStatusQL,
				variables: { id, status },
			}
		)
	);
};

export const fetchConversation = (id) => async (dispatch) => {
	const result = await dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_CONVERSATION_PENDING,
				actionTypes.FETCH_CONVERSATION_SUCCESS,
				actionTypes.FETCH_CONVERSATION_FAIL,
			],
			{
				query: getConversationQL,
				variables: { id },
			}
		)
	);

	return result.payload.data.conversation ?? {};
};

export const fetchCallStatuses = () => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_CALL_STATUSES_PENDING,
				actionTypes.FETCH_CALL_STATUSES_SUCCESS,
				actionTypes.FETCH_CALL_STATUSES_FAIL,
			],
			{
				query: getCallStatusesQl,
			}
		)
	);
