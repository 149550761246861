import { includes } from 'ramda';
import React from 'react';
import useAuth from '../../hooks/auth';

const Permissions = ({ children, roles }) => {
	const { role } = useAuth();

	return includes(role, roles) && children;
};

export default Permissions;
