import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarFilterButton,
} from '@mui/x-data-grid';
import clsx from 'clsx';
import { filter, includes, isEmpty, toLower } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useInterval from '../../../hooks/useInterval';
import { messages, translateDataGrid } from '../../../intl';
import FormattedDate from '../../../intl/FormattedDate';
import {
	showCreateEditCopyCampaignModal,
	showDeleteCampaignsModal,
	showCreateNewModelModal,
} from '../../../redux/modal/actions';
import { getModelsBasicInfo } from '../../../redux/model-table/actions';
import {
	selectedModelId,
	initiallyFetchedModelsInfo as initiallyFetchedModelsInfoSelector,
} from '../../../redux/model-table/selectors';
import { CALL_LIST_STATUS, fetchCampaignsForActiveModel } from '../../../redux/outbound/actions';
import { getCampaign, getCampaigns } from '../../../redux/outbound/selectors';
import LSM from '../../../utils/LocalStorageManager';
import SearchField from '../../SearchField/SearchField';
import UniButton from '../../UniButton';
import ROUTES, { ROUTE_NAMES } from '../routes';
import CampaignDetail from './CampaignDetail';
import { countNextRoundTime } from './CampaignInfo';

const TRANSITION_DURATION_SECONDS = 0.6;

const useStyles = makeStyles(() => ({
	firstColumnVisible: {
		transform: 'translateX(0)',
		transition: `ease-in-out ${TRANSITION_DURATION_SECONDS}s`,
		zIndex: 999,
	},
	firstColumnInvisible: {
		transform: 'translateX(-120%)',
		transition: `ease-in-out ${TRANSITION_DURATION_SECONDS}s`,
	},
	secondColumnVisible: {
		transform: 'translateX(-100%)',
		transition: `ease-in-out ${TRANSITION_DURATION_SECONDS}s`,
		boxSizing: 'border-box',
		width: '100%',
	},
	secondColumnInvisible: {
		transform: 'translateX(20%)',
		transition: `ease-in-out ${TRANSITION_DURATION_SECONDS}s`,
	},
	footer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '15px 0',
	},
	add: {
		marginRight: '0.75rem !important',
	},
}));

export const setInProgressRounds = (campaigns, intl) =>
	campaigns.map((campaign) => {
		const {
			finishedRounds,
			lastRoundFinishedAt,
			maxCallTrials,
			repeatCallDelayInMinutes,
			repeatCallsAt,
			status,
		} = campaign;

		let statusOrRounds = status;

		if (status === CALL_LIST_STATUS.IN_PROGRESS && maxCallTrials > 1 && finishedRounds) {
			statusOrRounds =
				+new Date() <
				(isEmpty(repeatCallsAt)
					? new Date(lastRoundFinishedAt).getTime() + repeatCallDelayInMinutes * 60 * 1000
					: new Date(repeatCallsAt[finishedRounds - 1]).getTime())
					? `${intl.formatMessage(messages.waitingForNextRound)} (${finishedRounds}/${maxCallTrials})`
					: CALL_LIST_STATUS.IN_PROGRESS;
		}

		return { ...campaign, status: statusOrRounds };
	});

const Campaigns = () => {
	const navigate = useNavigate();
	const intl = useIntl();
	const classes = useStyles();
	const dispatch = useDispatch();

	const { campaignId } = useParams();
	const campaign = useSelector(getCampaign(campaignId ? campaignId.substring(12) : campaignId));
	const campaigns = Object.values(useSelector(getCampaigns));
	const [selectedCampaigns, setSelectedCampaigns] = useState([]);

	const initiallyFetchedModelsInfo = useSelector(initiallyFetchedModelsInfoSelector);
	const activeModelId = useSelector(selectedModelId);

	const [filterString, setFilterString] = useState('');

	useEffect(() => {
		const selectedModelVersionId = LSM.getSelectedModelVersionId();

		if (!activeModelId && !selectedModelVersionId) {
			dispatch(showCreateNewModelModal());
		} else if (!campaign) {
			dispatch(fetchCampaignsForActiveModel());
		}
	}, [activeModelId]);

	useEffect(() => {
		if (!initiallyFetchedModelsInfo) {
			dispatch(getModelsBasicInfo());
		}
	}, [initiallyFetchedModelsInfo]);

	useInterval(
		() => {
			dispatch(fetchCampaignsForActiveModel());
		},
		5000,
		!campaign
	);

	const handleNameClick = (id) => {
		setSelectedCampaigns([id]);
		navigate(`:campaignId=${id}`, { replace: true });
	};

	/* eslint-disable */
	const searchedCampaigns = isEmpty(filterString)
		? setInProgressRounds(campaigns, intl)
		: filter(
				(campaign) =>
					includes(toLower(filterString), toLower(campaign.name)) ||
					// in case phoneNumber is null or something
					includes(filterString, campaign.phoneNumberTwilio || ''),
				setInProgressRounds(campaigns, intl)
		  );
	/* eslint-enable */

	const gridColumns = [
		{
			field: 'index',
			headerName: '#',
			filterable: false,
			width: 100,
			type: 'number',
			valueGetter: ({ row }) => campaigns.findIndex((campaign) => campaign.id === row.id) + 1,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'name',
			headerName: intl.formatMessage(messages.campaignName),
			flex: 1,
			minWidth: 300,
			type: 'string',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'createdAt',
			headerName: intl.formatMessage(messages.campaignCreatedAt),
			width: 180,
			renderCell: (params) => <FormattedDate value={params.row.createdAt} />,
			type: 'dateTime',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'lastRoundFinishedAt',
			headerName: intl.formatMessage(messages.campaignFinishedAt),
			renderCell: (params) => <FormattedDate value={params.row.lastRoundFinishedAt} />,
			width: 180,
			type: 'dateTime',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'maxCallTrials',
			headerName: intl.formatMessage(messages.campaignMaxCallTrials),
			width: 180,
			type: 'number',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'repeatCallsAt',
			headerName: intl.formatMessage(messages.campaignRepeatCallsAt),
			renderCell: (params) => {
				const repeatCallsAt =
					params.row?.repeatCallsAt?.[params.row.finishedRounds - 1] ||
					params.row?.repeatCallsAt?.[params.row.finishedRounds];
				const showNextRoundTime =
					params.row.status !== CALL_LIST_STATUS.FINISHED && params.row.lastRoundFinishedAt && !repeatCallsAt;

				return repeatCallsAt ? (
					<strong>
						<FormattedDate value={repeatCallsAt} />
					</strong>
				) : showNextRoundTime ? (
					countNextRoundTime(params.row.lastRoundFinishedAt, params.row.repeatCallDelayInMinutes)
				) : (
					params.row.repeatCallDelayInMinutes
				);
			},
			width: 180,
			type: 'dateTime',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'status',
			headerName: intl.formatMessage(messages.campaignStatus),
			width: 220,
			type: 'string',
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'action',
			filterable: false,
			headerName: ' ',
			width: 100,
			type: 'string',
			align: 'right',
			renderCell: ({ row }) => {
				return (
					<div style={{ display: 'flex' }} onClick={(e) => e.stopPropagation()}>
						<UniButton
							title={<FormattedMessage {...messages.copyCampaign} />}
							icon={<FileCopyIcon />}
							onClick={() =>
								dispatch(
									showCreateEditCopyCampaignModal({
										campId: row.id,
										callback: handleNameClick,
										operation: 'copy',
									})
								)
							}
						/>
						<UniButton
							title={<FormattedMessage {...messages.delete} />}
							icon={<DeleteIcon />}
							onClick={() => dispatch(showDeleteCampaignsModal([row.id]))}
						/>
					</div>
				);
			},
		},
	];

	const gridConfig = {
		components: {
			Toolbar: () => (
				<GridToolbarContainer>
					<GridToolbarColumnsButton />
					<GridToolbarFilterButton />
					<GridToolbarDensitySelector />
				</GridToolbarContainer>
			),
		},
		columns: gridColumns,
		rows: searchedCampaigns,
		autoHeight: true,
		density: 'compact',
		checkboxSelection: true,
		disableSelectionOnClick: true,
		onRowClick: ({ row }) => handleNameClick(row.id),
		selectionModel: selectedCampaigns,
		onSelectionModelChange: (campaigns) => setSelectedCampaigns(campaigns),
		localeText: translateDataGrid(intl),
	};

	return (
		<Box className="intent-editor-table">
			<Box className={clsx('intent-list', campaign ? classes.firstColumnInvisible : classes.firstColumnVisible)}>
				<Box className={classes.dataGridContainer}>
					<SearchField
						onSearch={setFilterString}
						debounceTime={500}
						label={<FormattedMessage {...messages.searchCampaigns} />}
					/>
					<DataGrid {...gridConfig} />
				</Box>
				<Box className={classes.footer}>
					<Box>
						<Button
							disabled={!activeModelId}
							className={classes.add}
							onClick={() => {
								dispatch(
									showCreateEditCopyCampaignModal({
										callback: handleNameClick,
										modelId: activeModelId,
										operation: 'create',
									})
								);
							}}
							variant="contained"
							color="primary"
						>
							<FormattedMessage {...messages.createCampaign} />
						</Button>
						<Button
							className={classes.add}
							onClick={() => {
								dispatch(showDeleteCampaignsModal(selectedCampaigns));
							}}
							variant="contained"
							disabled={isEmpty(selectedCampaigns)}
						>
							<FormattedMessage {...messages[selectedCampaigns.length < 2 ? 'deleteCampaign' : 'deleteCampaigns']} />
						</Button>
					</Box>
				</Box>
			</Box>
			<Box className={clsx('intent-selected', campaign ? classes.secondColumnVisible : classes.secondColumnInvisible)}>
				{campaign && (
					<CampaignDetail
						campaign={campaign}
						onComplete={() => navigate(ROUTES[ROUTE_NAMES.OUTBOUND].path, { replace: true })}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Campaigns;
