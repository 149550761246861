import { Autocomplete } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { mergeDeepRight, prop } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { getAssetsAnnouncements } from '../../redux/model-table/selectors';
import { createUpdateNode } from '../../redux/model/actions';
import { getNode } from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	bounding: {
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main,
		},
	},
}));

/** https://docs.partner.borndigital.ai/yaml_main.html#id3 */
const WIDGET_TYPES = ['announcement'];

/**
 * @param object intents
 * @param object transitionsActions
 * @returns List of utterances, extracted from node's intents,
 * Autocomplete sortable by targetNodeId, e.g. [ { utterance, targetNodeId }, ... ]
 */
const AddWidgetModal = ({ nodeId }) => {
	const classes = useStyles();
	const node = useSelector(getNode(nodeId));
	const [widgetType, setWidgetType] = useState(WIDGET_TYPES[0]);
	const [announcement, setAnnouncement] = useState();
	const announcements = useSelector(getAssetsAnnouncements);
	const dispatch = useDispatch();

	const handleAddWidget = () => {
		// TODO: this is hardcoded here just because we have only 1 widget now.
		//  Every widget should be a separate component in the future.
		dispatch(
			createUpdateNode(
				nodeId,
				mergeDeepRight(node, {
					SET: [...(node.SET ?? []), { widget_announcement_id: `"${announcement.id}"` }],
					1: {
						WIDGET: 'announcement',
						DATA: {
							widget: {
								data: '{widget_announcement(widget_id=widget_announcement_id)}',
							},
						},
					},
				})
			)
		);
		dispatch(hideModal());
	};

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.addWidget} />}
				saveBtnTitle={<FormattedMessage {...messages.addWidget} />}
				onSave={handleAddWidget}
				isSaveBtnDisabled={!announcement}
				className={classes.bounding}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Autocomplete
							disableClearable
							blurOnSelect
							autoSelect
							value={widgetType}
							options={WIDGET_TYPES}
							onChange={(_, v) => setWidgetType(v)}
							renderInput={(params) => (
								<TextField
									{...params}
									autoFocus
									fullWidth
									label={<FormattedMessage {...messages.widgetType} />}
									variant={'outlined'}
								/>
							)}
						/>
						{/* TODO: this is hardcoded here just because we have only 1 widget now.
						     Every widget should be a separate component in the future. */}
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							disableClearable
							blurOnSelect
							autoSelect
							options={announcements}
							getOptionLabel={prop('name')}
							onChange={(_, v) => setAnnouncement(v)}
							renderInput={(params) => (
								<TextField
									{...params}
									autoFocus
									fullWidth
									label={<FormattedMessage {...messages.announcementWidget} />}
									variant={'outlined'}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

AddWidgetModal.propTypes = {
	nodeId: PropTypes.string.isRequired,
};

export default AddWidgetModal;
