import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Grid, Tooltip } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInterval from '../../../hooks/useInterval';
import { CALL_LIST_STATUS, fetchCampaign } from '../../../redux/outbound/actions';
import { getMetaDataPattern, getTrunkAccount } from '../../../redux/outbound/selectors';
import CampaignInfo from './CampaignInfo';
import ControlBar from './ControlBar';
import MetaData from './MetaData';
import PhoneCallDetail from './PhoneCallDetail';
import PhoneCalls from './PhoneCalls';
import Stats from './Stats';

export const useStylesCampaignDetail = makeStyles((theme) => ({
	section: {
		background: '#fff',
		boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
		padding: '20px',
		minHeight: '260px',
	},
	sectionHeader: {
		background: theme.palette.primary.main,
		color: '#fff',
		marginBottom: '15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '1rem',
	},
	phoneCallSearchField: {
		width: '90%',
	},
	selectedNumberRow: {
		cursor: 'pointer',
	},
	numberRow: {
		cursor: 'pointer',
		background: yellow[300],
	},
	floatRight: {
		float: 'right',
	},
	numberStatusIcon: { width: 18, height: 18, marginTop: 4 },
	colorRed: {
		color: theme.palette.error.main,
	},
	colorGreen: {
		color: theme.palette.primary.main,
	},
	colorGrey: {
		color: grey[900],
	},
	defaultFont: { fontSize: '13px', color: grey[900] },
	controlButton: {
		marginLeft: '2px',
	},
	controlButtonDisabled: {
		marginLeft: '2px',
		opacity: 0.6,
		cursor: 'not-allowed',
	},
	foldBtn: {
		padding: '0 !important',
		float: 'right',
		margin: '0 !important',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	textAlignRight: {
		textAlign: 'right',
	},
	customPlayBtn: {
		padding: '8px',
		color: '#000',
		position: 'absolute',
		zIndex: 100,
		margin: '7px',
	},
	customPlayBtnIcon: {
		background: '#F1F3F4',
		borderRadius: '50%',
	},
	callStatusCheckbox: {
		padding: '0 !important',
	},
	spanLabel: {
		fontWeight: 'bold',
		backgroundColor: 'azure',
	},
	textField: {
		'& .MuiInputBase-root': { color: 'white' },
	},
	icon: {
		cursor: 'pointer',
	},
}));

export const OutboundStatusIcon = ({ status, isSelected }) => {
	const classes = useStylesCampaignDetail();
	const classNames = `${classes.numberStatusIcon} ${classes[isSelected ? 'colorGreen' : 'colorGrey']}`;

	switch (status) {
		case 'waiting':
			return (
				<Tooltip title="waiting">
					<HourglassEmptyIcon className={classNames} />
				</Tooltip>
			);
		case 'paused':
			return (
				<Tooltip title="paused">
					<PauseCircleFilledIcon className={classNames} />
				</Tooltip>
			);
		case 'failed':
		case 'error':
		case 'dicsonnected':
			return (
				<Tooltip title="error">
					<PhoneDisabledIcon className={classNames} />
				</Tooltip>
			);
		case 'completed':
			return (
				<Tooltip title="completed">
					<ThumbUpIcon className={classNames} />
				</Tooltip>
			);
		case 'busy':
			return (
				<Tooltip title="busy">
					<PhonePausedIcon className={classNames} />
				</Tooltip>
			);
		case 'no-answer':
			return (
				<Tooltip title="no-answer">
					<PhoneMissedIcon className={classNames} />
				</Tooltip>
			);
		case 'in-progress':
			return (
				<Tooltip title="in-progress">
					<SettingsPhoneIcon className={classNames} />
				</Tooltip>
			);
		default:
			return status ? (
				<Tooltip title={status}>
					<CheckCircleIcon className={classNames} />
				</Tooltip>
			) : (
				<LocalPhoneIcon className={classNames} />
			);
	}
};

OutboundStatusIcon.propTypes = {
	status: PropTypes.string,
	isSelected: PropTypes.bool,
};

export const areCallDataAvail = (status) =>
	!!status &&
	status !== 'error' &&
	status !== 'failed' &&
	status !== 'busy' &&
	status !== 'no-answer' &&
	status !== 'waiting';

const CampaignDetail = ({ onComplete, campaign }) => {
	const dispatch = useDispatch();
	const [selectedNumber, setSelectedNumber] = useState(null);
	const [selectedCallStatuses, setSelectedCallStatuses] = useState([]);
	const [phoneCallsListPage, setPhoneCallsListPage] = useState(0);
	const [metaDataPattern, setMetaDataPattern] = useState(getMetaDataPattern(campaign));
	const [callerNumberErr, setCallerNumberErr] = useState(null);
	const [trunkAccountErr, setTrunkAccountErr] = useState(null);
	const [isPhoneCallAudioPlaying, setIsPhoneCallAudioPlaying] = useState(false);
	const [reset, setReset] = useState('campaignDetail');
	const disableUI = !!callerNumberErr;
	const phoneCalls = campaign.phoneCalls ?? {};
	const hasCampNumbers = !isEmpty(phoneCalls);
	const isSip = !!useSelector(getTrunkAccount(campaign.id));

	useInterval(() => dispatch(fetchCampaign(campaign.id)), 5000, campaign.status === CALL_LIST_STATUS.IN_PROGRESS);

	useEffect(() => {
		if (campaign.status !== CALL_LIST_STATUS.IN_PROGRESS) {
			dispatch(fetchCampaign(campaign.id));
		}
	}, [campaign.id]);

	useEffect(() => {
		const _metaDataPattern = getMetaDataPattern(campaign);
		if (!isEmpty(_metaDataPattern)) {
			setMetaDataPattern(getMetaDataPattern(campaign));
		}
	}, [campaign]);

	return (
		<div key={reset}>
			<ControlBar
				callerNumberErr={callerNumberErr}
				disableUI={disableUI}
				hasCampNumbers={hasCampNumbers}
				id={campaign.id}
				onComplete={onComplete}
				setCallerNumberErr={setCallerNumberErr}
				setReset={setReset}
				setTrunkAccountErr={setTrunkAccountErr}
				campaignType={campaign.campaignType}
				metaDataPattern={metaDataPattern}
			/>
			<br />
			<Grid spacing={3} container>
				<Grid item xs={3}>
					<CampaignInfo
						callerNumberErr={callerNumberErr}
						disableUI={disableUI}
						id={campaign.id}
						setCallerNumberErr={setCallerNumberErr}
						trunkAccountErr={trunkAccountErr}
						setTrunkAccountErr={setTrunkAccountErr}
					/>
					<br />
					<MetaData
						campaign={campaign}
						disableUI={disableUI}
						metaDataPattern={metaDataPattern}
						setMetaDataPattern={setMetaDataPattern}
					/>
					<br />
					{campaign.status !== CALL_LIST_STATUS.WAITING && (
						<Stats
							campaign={campaign}
							selectedCallStatuses={selectedCallStatuses}
							setSelectedCallStatuses={setSelectedCallStatuses}
							setPhoneCallsListPage={setPhoneCallsListPage}
						/>
					)}
				</Grid>
				<Grid item xs={3}>
					<PhoneCalls
						campaign={campaign}
						disableUI={disableUI}
						metaDataPattern={metaDataPattern}
						setSelectedNumber={setSelectedNumber}
						selectedNumber={selectedNumber}
						selectedCallStatuses={selectedCallStatuses}
						isPhoneCallAudioPlaying={isPhoneCallAudioPlaying}
						setIsPhoneCallAudioPlaying={setIsPhoneCallAudioPlaying}
						phoneCallsListPage={phoneCallsListPage}
						setPhoneCallsListPage={setPhoneCallsListPage}
					/>
				</Grid>
				<Grid item xs={6}>
					<PhoneCallDetail
						call={phoneCalls[selectedNumber]}
						disableUI={disableUI}
						id={campaign.id}
						number={selectedNumber}
						isPhoneCallAudioPlaying={isPhoneCallAudioPlaying}
						setIsPhoneCallAudioPlaying={setIsPhoneCallAudioPlaying}
						isSip={isSip}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

CampaignDetail.propTypes = {
	campaign: PropTypes.object.isRequired,
	onComplete: PropTypes.func,
};

export default CampaignDetail;
