import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
	loaderWrap: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		left: 0,
		top: 0,
		zIndex: '9',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		borderRadius: 4,
	},
	loader: {
		border: '6px solid transparent',
		borderRadius: '50%',
		width: 40,
		height: 40,
		borderTop: '6px solid #7CBA2D',
		borderBottom: '6px solid #7CBA2D',
		animation: 'spin 1.2s linear infinite',
	},
	text: {
		animationName: 'slideUp',
		animationDuration: '0.5s',
		fontSize: 14,
		color: '#333',
		fontWeight: '500',
		marginTop: 20,
		display: 'block',
	},
}));

const Preloader = ({ loaderWrapStyle, loaderStyle, loaderText }) => {
	const classes = useStyles();

	return (
		<div className={classes.loaderWrap} style={loaderWrapStyle}>
			<div className={classes.loader} style={loaderStyle} />
			{loaderText ? <p className={classes.text}>{loaderText}</p> : null}
		</div>
	);
};

Preloader.propTypes = {
	loaderWrapStyle: PropTypes.object,
	loaderStyle: PropTypes.object,
	loaderText: PropTypes.object,
};

export default Preloader;
