// TODO - fix Gilroy font paths after we get the font w diacritics

/* eslint-disable import/no-duplicates */
import GilroyBold from '../assets/fonts/arial/Arial.woff';
import Gilroy from '../assets/fonts/arial/Arial.woff';
import GilroySemibold from '../assets/fonts/arial/Arial.woff';
import PuristaBold from '../assets/fonts/purista/Purista-Bold.woff';
import Purista from '../assets/fonts/purista/Purista-Medium.woff';
import PuristaSemibold from '../assets/fonts/purista/Purista-Semibold.woff';

const fonts = [
	{ font: 'Gilroy', src: Gilroy },
	{ font: 'GilroyBold', src: GilroyBold },
	{ font: 'GilroySemibold', src: GilroySemibold },
	{ font: 'Purista', src: Purista },
	{ font: 'PuristaBold', src: PuristaBold },
	{ font: 'PuristaSemibold', src: PuristaSemibold },
];

// create and insert list of fonts
export default {
	'@font-face': fonts.map(({ font, src }) => ({
		fontFamily: font,
		src: `local(${font}), url(${src}) format('woff')`,
	})),
};
