import { produce } from 'immer';
import { lowerCase } from 'lodash';
import { keys, mergeLeft, omit, pick, pluck, without } from 'ramda';
import { combineReducers } from 'redux';
import fetching from '../util/reducers';
import { actionTypes, ID } from './actions';

const CAMPAIGN_TYPE = {
	BATCH: 'BATCH',
	CONTINUOUS: 'CONTINUOUS',
};

const optimizeCampaignsData = (callLists) => {
	const callListsOptimized = {};

	for (const callList of callLists) {
		const phoneCallsOptimized = {};
		if (callList.phoneCalls) {
			for (const phoneCall of callList.phoneCalls.reverse()) {
				if (!callList.campaignType || callList.campaignType !== CAMPAIGN_TYPE.CONTINUOUS) {
					phoneCallsOptimized[phoneCall.phoneNumber] = phoneCall;
				} else if (phoneCall.phoneNumber in phoneCallsOptimized) {
					// Create new entity for already existing numbers for continous campaign only
					let count = 0;
					Object.keys(phoneCallsOptimized).forEach((key) => {
						const phoneNumber = key.split(' ')[0];
						if (phoneNumber === phoneCall.phoneNumber) {
							count++;
						}
					});
					phoneCallsOptimized[`${phoneCall.phoneNumber} (${count})`] = phoneCall;
				} else {
					phoneCallsOptimized[phoneCall.phoneNumber] = phoneCall;
				}
			}
		}
		// show latest call first
		const reversedKeys = Object.keys(phoneCallsOptimized).reverse();
		const reversedPhoneCallsOptimizedObj = {};
		reversedKeys.forEach((key) => {
			reversedPhoneCallsOptimizedObj[key] = phoneCallsOptimized[key];
		});
		callListsOptimized[callList.id] = { ...callList, phoneCalls: reversedPhoneCallsOptimizedObj };
	}
	return callListsOptimized;
};

const DEFAULT_STATE = {
	campaigns: [],
	callStatuses: [],
};

const outbound = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.FETCH_CALL_STATUSES_SUCCESS: {
			state.callStatuses = without(['Null'], pluck('name', payload?.data?.__type?.enumValues ?? []))
				.map(lowerCase)
				.map((status) => status.replace(' ', '-'));
			break;
		}
		case actionTypes.FETCH_DELETE_CAMPAIGNS_SUCCESS: {
			state.campaigns = omit(payload.data.callLists.ids, state.campaigns);
			break;
		}
		case actionTypes.FETCH_CAMPAIGNS_SUCCESS: {
			state.campaigns = optimizeCampaignsData(payload.data.callLists);
			break;
		}
		case actionTypes.FETCH_CAMPAIGN_SUCCESS: {
			state.campaigns = mergeLeft(optimizeCampaignsData([payload.data.callLists]), { ...state.campaigns });
			break;
		}
		case actionTypes.FETCH_CREATE_CAMPAIGN_SUCCESS: {
			state.campaigns = mergeLeft(optimizeCampaignsData([payload.data.callLists]), { ...state.campaigns });
			break;
		}
		case actionTypes.FETCH_UPDATE_CAMPAIGN_SUCCESS: {
			state.campaigns = mergeLeft(optimizeCampaignsData([payload.data.callLists]), { ...state.campaigns });
			break;
		}
		case actionTypes.FETCH_UPDATE_CAMPAIGN_STATUS_SUCCESS: {
			state.campaigns[payload.data.callLists.id].status = payload.data.callLists.status;
			break;
		}
		case actionTypes.UPLOAD_CAMPAIGN: {
			state.campaigns[payload.campaign.id] = payload.campaign;
			break;
		}
		case actionTypes.UPDATE_NUMBERS: {
			const phoneCalls = { ...state.campaigns[payload.campId].phoneCalls };

			if (phoneCalls[payload.phoneNumber]) {
				Reflect.deleteProperty(phoneCalls, payload.phoneNumber);
			} else {
				phoneCalls[payload.phoneNumber] = {
					phoneNumber: payload.phoneNumber,
					callList: payload.campId,
					metaData: payload.metaData,
				};
			}

			state.campaigns[payload.campId].phoneCalls = phoneCalls;
			break;
		}
		case actionTypes.UPDATE_META_DATA: {
			const phoneCalls = {};

			for (const phoneCall of Object.values(payload.campaign.phoneCalls)) {
				const _phoneCall = { ...phoneCall };
				_phoneCall.metaData = pick(keys(payload.metaData), phoneCall.metaData || {});
				_phoneCall.metaData = mergeLeft(_phoneCall.metaData, payload.metaData);
				phoneCalls[phoneCall.phoneNumber] = _phoneCall;
			}

			state.campaigns[payload.campaign.id].phoneCalls = phoneCalls;
			break;
		}
		case actionTypes.UPDATE_PARAM: {
			state.campaigns[payload.campId].phoneCalls[payload.number].metaData[payload.param] = payload.value;
			break;
		}
		case actionTypes.UPDATE_CALLER_NUMBER: {
			state.campaigns[payload.campId].phoneNumberTwilio = payload.number;
			break;
		}
		case actionTypes.UPDATE_TRUNK_ACCOUNT: {
			state.campaigns[payload.campId].trunkAccount = payload.trunkAccount;
			break;
		}
		case actionTypes.UPDATE_CAMPAIGN_NAME: {
			state.campaigns[payload.campId].name = payload.name;
			break;
		}
		default:
			return state;
	}
}, DEFAULT_STATE);

export default combineReducers({
	[ID]: outbound,
	fetchingCampaigns: fetching([
		actionTypes.FETCH_CAMPAIGNS_PENDING,
		actionTypes.FETCH_CAMPAIGNS_SUCCESS,
		actionTypes.FETCH_CAMPAIGNS_FAIL,
	]),
});
