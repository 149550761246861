import { red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { always, compose, cond, defaultTo, equals, includes, isEmpty, not, pipe, T, trim } from 'ramda';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import zxcvbn from 'zxcvbn';
import messages from '../intl/messages';
import { palette } from '../mui-theme-builder';

export const validationStyles = {
	message: {
		margin: '10px 0 5px 5px',
		fontSize: 13,
	},
	error: {
		color: red[500],
	},
	success: {
		color: palette.primary.main,
	},
};

/**
 * Returns `true` is the string contains only whitespace characters of is empty.
 * It's null-safe and returns `true` if the provided value if `null` or `undefined`.
 */
export const isBlank = pipe(defaultTo(''), trim, isEmpty);

export const validateIsYesNo = (value) => {
	let message = null;

	if (value.toLowerCase() === 'yes' || value.toLowerCase() === 'no') {
		message = (
			<span key="itsYesOrNo" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.cantBeYesOrNo}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};

export const validateIsDuplicate = (item, list) => {
	let message = null;

	if (includes(item, list)) {
		message = (
			<span style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage {...messages.duplicate} />
			</span>
		);
	}

	return message;
};

export const validateContainsString = (value, string) => {
	let message = null;

	if (value && !String(value).includes(string)) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.doesNotContainString}
					values={{ string }}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};

export const validateIsEmpty = (value) => {
	let message = null;

	if (!Number.isInteger(value) && isBlank(value)) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage {...messages.empty} style={{ ...validationStyles.message, ...validationStyles.error }} />
			</span>
		);
	}

	return message;
};

export const validateHasSpecialChars = (value) => {
	let message = null;
	const validTableRegex = new RegExp('^[a-zA-Z0-9_ -]*$', 'g');

	if (!validTableRegex.test(value)) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.invalidMetadataName}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};

export const validateNodeName = (nodeName, nodeIds) => {
	let message = null;

	message =
		validateIsYesNo(nodeName) ||
		validateIsEmpty(nodeName) ||
		validateNodeNameLength(nodeName) ||
		(nodeIds ? validateIsDuplicate(nodeName, nodeIds) : null);

	return message;
};

export const validateNodeNameLength = (nodeName) => {
	let message = null;
	if (nodeName.length > 250) {
		message = (
			<span style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.maxLimit250}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}
	return message;
};

export const validateGroupCompliance = (nodeName, availableNodes) => {
	let message = null;

	if (!availableNodes.includes(nodeName)) {
		message = (
			<span style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.nodeNotAvailable}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}
	return message;
};

export const validateShouldBeNodeCreated = (node, nodes) => {
	let message = null;

	if (!nodes.includes(node)) {
		message = (
			<span style={{ ...validationStyles.message, ...validationStyles.success }}>
				<FormattedMessage
					{...messages.nodeWillBeCreated}
					style={{ ...validationStyles.message, ...validationStyles.success }}
				/>
			</span>
		);
	}

	return message;
};

validateNodeName.propTypes = { str: PropTypes.string };

const isEmptyPredicate = [isEmpty, always(<FormattedMessage {...messages.emptyValueNotAllowed} />)];
const valueDiffersPredicate = [compose(not, equals), always(<FormattedMessage {...messages.valueDiffers} />)];
const passwordLengthPredicate = [
	(password) => password.length < 8,
	always(<FormattedMessage {...messages.passwordTooShort} values={{ length: 8 }} />),
];
const adminPasswordLengthPredicate = [
	(password) => password.length < 16,
	always(<FormattedMessage {...messages.passwordTooShort} values={{ length: 16 }} />),
];
const passwordDifferentFromUsernamePredicate = [
	(password, username) => username.includes(password),
	always(<FormattedMessage {...messages.passwordSameAsUsername} values={{ length: 16 }} />),
];

const getPasswordCharacterValidationArray = (password) => [
	{
		id: 'hasUpperCaseChar',
		errorMessage: messages.passwordDoesNotContainsUpperChars,
		isValid: /[A-Z]/.test(password),
	},
	{
		id: 'hasLowerCaseChar',
		errorMessage: messages.passwordDoesNotContainsLowerChars,
		isValid: /[a-z]/.test(password),
	},
	{ id: 'hasBaseDigit', errorMessage: messages.passwordDoesNotContainsBaseDigits, isValid: /\d/.test(password) },
	{
		id: 'hasNonAlphaNumvericChar',
		errorMessage: messages.passwordDoesNotContainSpecialChars,
		isValid: /[^a-zA-Z\d\s:]/.test(password),
	},
];

const passwordContainsUnallowedChars = [
	(password) => {
		const validationArray = getPasswordCharacterValidationArray(password);
		const count = validationArray.reduce((acc, curr) => (acc += curr.isValid ? 1 : 0), 0);
		return count < 3;
	},
	(password) => {
		const validationArray = getPasswordCharacterValidationArray(password);
		const filteredArray = validationArray.filter((item) => !item.isValid);
		const numberOfMissing = filteredArray.length;
		const numberOfNeeded = numberOfMissing - 1;
		return (
			<>
				<FormattedMessage {...messages.passwordRequirments} values={{ numberOfMissing, numberOfNeeded }} />
				<ul>
					{filteredArray.map((obj) => (
						<li>
							<FormattedMessage {...obj.errorMessage} />
						</li>
					))}
				</ul>
			</>
		);
	},
];

const passwordNotCommonOrGuessablePredicate = [
	(password) => zxcvbn(password).score < 3,
	(password) => {
		const { feedback } = zxcvbn(password);
		return (
			<FormattedMessage
				{...messages.passwordTooWeak}
				values={{ warning: feedback.warning, suggestion: feedback.suggestions.join(', ') }}
			/>
		);
	},
];

const defaultNull = [T, always(null)];

const validationTemplate = (predicates) => cond([...predicates, defaultNull]);

export const isEmptyValidation = validationTemplate([isEmptyPredicate]);

export const passwordValidation = (isUserAdmin) =>
	validationTemplate([
		isEmptyPredicate,
		passwordDifferentFromUsernamePredicate,
		passwordNotCommonOrGuessablePredicate,
		passwordContainsUnallowedChars,
		isUserAdmin ? adminPasswordLengthPredicate : passwordLengthPredicate,
	]);

export const repeatPasswordValidation = validationTemplate([valueDiffersPredicate]);

export const isPhoneNumberInvalid = (number) => {
	let message = null;

	if (!number.match(/\+[0-9]{12,13}/g) || number.length > 14) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.wrongNumberFormat}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};

export const isTwilioFlowIdValid = (number) => {
	let message = null;

	if (!number.match(/^FW[0-9a-zA-Z]{32}$/)) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.wrongTwilioFlowIdFormat}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};

export const isTrunkAccountInvalid = (sipAccount) => {
	let message = null;

	if (!sipAccount.match(/^sip:(.*)@(.*)/)) {
		message = (
			<span key="itsEmpty" style={{ ...validationStyles.message, ...validationStyles.error }}>
				<FormattedMessage
					{...messages.wrongSipAccountFormat}
					style={{ ...validationStyles.message, ...validationStyles.error }}
				/>
			</span>
		);
	}

	return message;
};
