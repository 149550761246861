import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { registerStateBeforeDispatchingAction, removeNode } from '../../redux/model/actions';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveNodeModal = ({ id }) => {
	const dispatch = useDispatch();

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.removeNode} />}
			saveBtnTitle={<FormattedMessage {...messages.removeNode} />}
			onSave={async () => {
				dispatch(registerStateBeforeDispatchingAction(removeNode(id)));
				dispatch(hideModal());
			}}
		>
			<FormattedMessage
				{...messages.deleteNodeQuestion}
				values={{ name: id, strong: (msg) => <strong>{msg}</strong> }}
			/>
		</SaveCloseModalTemplate>
	);
};

RemoveNodeModal.propTypes = {
	id: PropTypes.string.isRequired,
};

export default RemoveNodeModal;
