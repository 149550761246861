import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { INTL, messages } from '../../intl';
import { convertDateToString } from '../../utils/date-time';

const weekDays = {
	monday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	tuesday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	wednesday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	thursday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	friday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	saturday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
	sunday: {
		isActive: false,
		value: ['00:00', '23:59'],
	},
};

const activeAllDays = (days) => {
	const activated = { ...days };

	for (const day of Object.keys(days)) {
		activated[day] = { ...activated[day], isActive: true };
	}

	return activated;
};

const getActiveDaysInStr = (days) =>
	Object.entries(days)
		.filter((day) => day[1].isActive)
		.map(([day]) => day)
		.join(', ');

export const repeatSummary = (announcement) =>
	INTL.formatMessage(messages[!announcement.isInfinite ? 'repeatDefinitelyInDays' : 'repeatInfinitelyInDays'], {
		days: getActiveDaysInStr(announcement.weekDays),
		endsAt: new Date(announcement?.endsAt).toLocaleString() || '',
	});

export const AssetAnnouncement = ({ onDataChange, data }) => {
	const [announcement, setAnnouncement] = useState(
		data || {
			enabled: true,
			endsAt: Date.now(),
			endsDailyAt: '23:59',
			isAllDay: true,
			isInfinite: true,
			isWholeWeek: true,
			name: '',
			speech: '',
			startsAt: Date.now(),
			startsDailyAt: '00:00',
			text: '',
			weekDays,
		}
	);

	const handleDataChange = (newData) => {
		newData = { ...announcement, ...newData };
		setAnnouncement(newData);
		onDataChange(newData);
	};

	useEffect(() => {
		if (announcement.isWholeWeek) {
			handleDataChange({ weekDays: activeAllDays(announcement.weekDays) });
		}
	}, [announcement.isWholeWeek]);

	return (
		<Grid container spacing={2} style={{ margin: '-1rem -1rem -1rem -2rem' }}>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					label={<FormattedMessage {...messages.assetsFriendlyName} />}
					value={announcement.name}
					onChange={(e) => handleDataChange({ name: e.target.value })}
					autoFocus
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					label={<FormattedMessage {...messages.announcementText} />}
					value={announcement.text}
					variant="outlined"
					onChange={(e) => handleDataChange({ text: e.target.value })}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					label={<FormattedMessage {...messages.announcementVoice} />}
					value={announcement.speech}
					variant="outlined"
					onChange={(e) => handleDataChange({ speech: e.target.value })}
				/>
			</Grid>
			<Grid item xs={2}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							variant="outlined"
							checked={announcement.enabled}
							onChange={(e) => handleDataChange({ enabled: e.target.checked })}
						/>
					}
					label={<FormattedMessage {...messages.enabled} />}
				/>
			</Grid>
			<Grid item xs={10}>
				<TextField
					type="datetime-local"
					label={<FormattedMessage {...messages.startDate} />}
					value={convertDateToString(new Date(announcement.startsAt))}
					variant="outlined"
					disabled={!announcement.enabled}
					onChange={(e) => handleDataChange({ startsAt: new Date(e.target.value).getTime() })}
				/>
			</Grid>
			<Grid style={{ margin: 'auto' }} item xs={2}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							variant="outlined"
							checked={announcement.isInfinite}
							onChange={(e) => handleDataChange({ isInfinite: !announcement.isInfinite })}
						/>
					}
					label={<FormattedMessage {...messages.unlimited} />}
				/>
			</Grid>
			<Grid item xs={10}>
				<TextField
					type="datetime-local"
					label={<FormattedMessage {...messages.endDate} />}
					value={convertDateToString(new Date(announcement.endsAt))}
					variant="outlined"
					disabled={announcement.isInfinite}
					onChange={(e) => handleDataChange({ endsAt: new Date(e.target.value).getTime() })}
				/>
			</Grid>
			<Grid style={{ margin: 'auto' }} item xs={2}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							variant="outlined"
							checked={announcement.isAllDay}
							onChange={(e) => handleDataChange({ isAllDay: !announcement.isAllDay })}
						/>
					}
					label={<FormattedMessage {...messages.allDay} />}
				/>
			</Grid>
			<Grid item xs={5}>
				<TextField
					type="time"
					label={<FormattedMessage {...messages.startTime} />}
					value={announcement.startsDailyAt}
					variant="outlined"
					disabled={announcement.isAllDay}
					onChange={(e) => handleDataChange({ startsDailyAt: e.target.value })}
				/>
			</Grid>
			<Grid item xs={5}>
				<TextField
					type="time"
					label={<FormattedMessage {...messages.endTime} />}
					value={announcement.endsDailyAt}
					variant="outlined"
					disabled={announcement.isAllDay}
					onChange={(e) => handleDataChange({ endsDailyAt: e.target.value })}
				/>
			</Grid>
			<Grid item xs={2}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							variant="outlined"
							checked={announcement.isWholeWeek}
							onChange={(e) => handleDataChange({ isWholeWeek: !announcement.isWholeWeek })}
						/>
					}
					label={<FormattedMessage {...messages.wholeWeek} />}
				/>
			</Grid>
			<Grid item xs={10}>
				<strong style={{ margin: '10px 0', display: 'block' }}> {repeatSummary(announcement)}</strong>
				{!announcement.isWholeWeek &&
					Object.keys(weekDays).map((item) => (
						<Grid key={item + '-week-days'} container spacing={2} style={{ margin: 0 }}>
							<Grid item xs={2}>
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											variant="outlined"
											disabled={announcement.isWholeWeek}
											checked={announcement.weekDays[item].isActive}
											value={announcement.weekDays[item]}
											onChange={(e) =>
												handleDataChange({
													weekDays: {
														...announcement.weekDays,
														[item]: { ...announcement.weekDays[item], isActive: e.target.checked },
													},
												})
											}
										/>
									}
									label={<FormattedMessage {...messages[item]} />}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									type="time"
									label={<FormattedMessage {...messages.startTime} />}
									value={announcement.weekDays[item].value[0]}
									variant="outlined"
									disabled={!announcement.weekDays[item].isActive}
									onChange={(e) =>
										handleDataChange({
											weekDays: {
												...announcement.weekDays,
												[item]: {
													isActive: true,
													value: [e.target.value, announcement.weekDays[item].value[1]],
												},
											},
										})
									}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									type="time"
									label={<FormattedMessage {...messages.endTime} />}
									value={announcement.weekDays[item].value[1]}
									variant="outlined"
									disabled={!announcement.weekDays[item].isActive}
									onChange={(e) =>
										handleDataChange({
											weekDays: {
												...announcement.weekDays,
												[item]: {
													isActive: true,
													value: [announcement.weekDays[item].value[0], e.target.value],
												},
											},
										})
									}
								/>
							</Grid>
						</Grid>
					))}
			</Grid>
		</Grid>
	);
};

AssetAnnouncement.propTypes = {
	onDataChange: PropTypes.func.isRequired,
	data: PropTypes.object,
};
