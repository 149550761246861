import { produce } from 'immer';
import { path } from 'ramda';
import { actionTypes as authActionTypes } from '../auth/actions';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = {
	modalType: '',
	modalProps: {},
};

const modal = produce((state, { type, payload }) => {
	switch (type) {
		case actionTypes.SHOW_MODAL:
			state.modalType = payload.modalType;
			state.modalProps = payload.modalProps;
			break;
		case actionTypes.HIDE_MODAL:
			return DEFAULT_STATE;
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
	}
}, DEFAULT_STATE);

export const getModalType = (state) => path([ID, 'modalType'])(state);
export const getModalProps = (state) => path([ID, 'modalProps'])(state);

export default modal;
