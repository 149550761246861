import jsYaml from 'js-yaml';
import { escape, escapeRegExp, isEmpty, size } from 'lodash';
import { remove } from 'ramda';
import { RESPONSE_TYPES } from '../../core/configs';
import { NODE_ID_TEMPLATE_COLORS } from '../../core/diagram/constants';
import zoomConfig from '../../core/diagram/zooming';
import keyMirror from '../../utils/keyMirror';
import { getNode, getZoomLevel, getNodePosition } from './selectors';

export const parseImageMarkdown = (value) => value.match(/!\[(?<fileName>.*)]\((?<url>.*)\?id=(?<id>.*) /);

/**
 * Naive check.
 */
export const isImageMarkdown = (parsedImageMarkdown) => parsedImageMarkdown?.length === 4;

export const formatImageMarkdown = (fileName, fileUrl) => {
	return `![${fileName}](${fileUrl} "${fileName}")`;
};

export const SYNTAX_CONF = keyMirror({
	VARIABLE: null,
	PYTHON_OR: null,
	SSML_TAG: null,
	SSML_ATTRIBUTE: null,
	SSML_ATTRIBUTE_VALUE: null,
});

export const markSyntax = (str, conf) => {
	const modifyStr = (regex, color, modifyMatch = (match) => match) => {
		str = str.replace(regex, (match) => '<span style="color: ' + color + '">' + modifyMatch(match) + '</span>');
	};

	Object.entries(conf).map(([entity, color]) => {
		switch (entity) {
			case SYNTAX_CONF.VARIABLE:
				modifyStr(new RegExp('{(.*?)}', 'g'), color);
				break;
			case SYNTAX_CONF.PYTHON_OR:
				modifyStr(new RegExp('OR', 'g'), color);
				break;
			case SYNTAX_CONF.SSML_TAG:
				modifyStr(/<\/?(\w+)(.*?>|)/g, color, escape);
				break;
			case SYNTAX_CONF.SSML_ATTRIBUTE:
				// This is using escaped characters since it expects that the SSML_TAG escaped the whole tag first
				str = str.replace(/(\w+=)&quot;/g, '<span style="color: ' + color + '">$1</span>&quot;');
				break;
			case SYNTAX_CONF.SSML_ATTRIBUTE_VALUE:
				// This is using escaped characters since it expects that the SSML_TAG escaped the whole tag first
				str = str.replace(new RegExp('(&quot;.+?&quot;)', 'g'), '<span style="color: ' + color + '">$1</span>');
				break;
		}
	});

	return str;
};

export const removeGroupPrefix = (nodeId, groupId) => {
	if (groupId) {
		const groupPrefix = new RegExp(`${groupId}_`);
		return nodeId.replace(groupPrefix, '');
	} else {
		return nodeId;
	}
};

export const hasTooManyNodes = (flow) => size(flow) >= 30;

// Duplicate nodeId, intentId, etc prevention
export const appendDuplicitySuffix = (originalId, listOfIds) => {
	let newId = originalId;
	// eslint-disable-next-line no-loop-func
	for (let duplicateNameCounter = 1; listOfIds.findIndex((id) => id === newId) > -1; duplicateNameCounter++) {
		newId = `${originalId}_${duplicateNameCounter}`;
	}
	return newId;
};

export const isYamlValid = (yaml) => {
	try {
		const parsedYaml = jsYaml.load(yaml);
		return Boolean(parsedYaml);
	} catch (e) {
		console.warn(e);
		return false;
	}
};

export const getNearbyDiagramPosition = (baseNodeId, state) => {
	const baseNode = getNode(baseNodeId, state);
	const baseNodePosition = getNodePosition(baseNodeId, state);

	if ((!baseNode || isEmpty(baseNode.diagramPosition)) && !baseNodePosition) {
		// when empty or with zero coords there is problem with init position in starting node while creating new node group
		return { x: 200, y: 200 };
	}

	const zoomLevel = getZoomLevel(state);
	const {
		dimension: { DIST_X, DIST_Y_MIN },
	} = zoomConfig()[zoomLevel];

	return {
		x: baseNode.diagramPosition?.x ? baseNode.diagramPosition?.x : baseNodePosition.x + DIST_X,
		y: baseNode.diagramPosition?.y ? baseNode.diagramPosition?.y : baseNodePosition.y + DIST_Y_MIN,
	};
};

/**
 * Reshuffles indices of the node' responses to make all MARKDOWN first, all SPEECH second and MARKDOWN_OPTIONS as last
 * Discards potentially empty responses
 */
export const sortNodeResponsesAndMarkdownOptions = (
	{ markdown, speech, markdownOptions },
	appendMarkdownOptions = true
) => {
	const responses = {};
	Object.entries(markdown).forEach(([index, value]) => {
		if (!isEmpty(value)) {
			responses[index] = { ...responses[index], ...value };
		}
	});

	Object.entries(speech).forEach(([index, value]) => {
		if (!isEmpty(value)) {
			responses[index] = { ...responses[index], ...value };
		}
	});

	if (!isEmpty(markdownOptions) && appendMarkdownOptions) {
		responses[1] = { ...responses[1], [RESPONSE_TYPES.MARKDOWN_OPTIONS]: markdownOptions };
	}

	return responses;
};

/**
 * Splits the YAML string by each newline and returns the index of the line where a match is found
 */
export const findRowInRawYaml = (regex, rawYaml) => {
	const yamlSplittedIntoRows = rawYaml.split('\n');
	for (let i = 0; i < yamlSplittedIntoRows.length; i++) {
		if (yamlSplittedIntoRows[i].match(regex)) {
			return i;
		}
	}
	return null;
};

export const findCommentsInRawYaml = (rawYaml) => {
	const commentRegex = /^ *([^\r\n\t\f\v#]*)[^\n#]*#+\s*(.*)\n?/;

	// Search for the closest line that does not start with '# ...'
	const findNonCommentLine = (lineStartingWithComment, previousLineIndex) => {
		const nextLineIndex =
			(previousLineIndex || findRowInRawYaml(new RegExp(escapeRegExp(lineStartingWithComment)), rawYaml)) + 1;
		const nextLine = rawYaml.split('\n')[nextLineIndex];

		// End of file
		if (typeof nextLine === 'undefined') {
			return '';
			// Empty line
		} else if (nextLine === '') {
			return findNonCommentLine(nextLine, nextLineIndex);
		}

		const match = nextLine.match(commentRegex);
		// nextLine is comment-free, the regex found no match
		if (!match) {
			return nextLine;
		} else {
			const [, potentialNonCommentTarget, commentOnNextLine] = match;
			// nextLine comprises of something followed by a comment (e.g. NODE: # comment)
			if (potentialNonCommentTarget) {
				return potentialNonCommentTarget;
				// nextLine is another line-spanning comment and nothing else (e.g. # comment)
			} else if (commentOnNextLine && !potentialNonCommentTarget) {
				return findNonCommentLine(nextLine, nextLineIndex);
			}
		}
	};

	return [...rawYaml.matchAll(new RegExp(commentRegex, 'gm'))].map(([, commentTarget, comment]) => {
		let commentTargetPosition = 'sameLine';
		// If the comment is the only thing on the line, assume it is related to the string below it
		if (!commentTarget) {
			commentTargetPosition = 'nextLine';
			commentTarget = findNonCommentLine(comment);

			// findNonCommentLine inevitably begins searching from the top of rawYaml for the next comment
			// Once a wholeLine comment gets its position, delete it from rawYaml in case there was an identical comment further down the file
			const commentLineIndex = findRowInRawYaml(new RegExp(escapeRegExp(comment)), rawYaml);
			if (commentLineIndex) {
				rawYaml = remove(commentLineIndex, 1, rawYaml.split('\n')).join('\n');
			}
		}

		return {
			comment,
			commentTarget: commentTarget.trim(),
			commentTargetPosition,
		};
	});
};

export const placeCommentsIntoRawYaml = (comments, yaml) => {
	for (const { comment, commentTarget, commentTargetPosition } of comments) {
		// The match must be exact since commentTarget could be a part of a longer line somewhere else too
		const targetRow = findRowInRawYaml(new RegExp(`^\\s*${escapeRegExp(commentTarget)}\\s*$`), yaml);
		if (typeof targetRow === 'number') {
			yaml = yaml.split('\n');
			if (commentTargetPosition === 'sameLine') {
				yaml[targetRow] = yaml[targetRow].concat(` # ${comment}`);
				yaml = yaml.join('\n');
			} else if (commentTargetPosition === 'nextLine') {
				yaml.splice(targetRow, 0, `# ${comment}`);
				yaml = yaml.join('\n');
			}
		}
	}

	return yaml;
};

export const matchNodeIdWithTemplateColor = (nodeId) => {
	for (const [template, color] of Object.entries(NODE_ID_TEMPLATE_COLORS)) {
		// Matches only when the template is not part of a longer string (e.g. 'MSG' won't match 'NOMSG' but only: 'MSG' || '..._MSG' || 'MSG_...' || '..._MSG_...')
		const regexp = new RegExp(`^${template}$|^${template}_|_${template}$|_${template}_`);
		if (nodeId.match(regexp)) {
			return color;
		}
	}

	return null;
};

export const removeNullCoords = (nodes) => {
	Object.entries(nodes).forEach(([index, coords]) => {
		if (!coords.x || !coords.y) {
			nodes[index] = {
				x: 0,
				y: 0,
			};
		}
	});
	return nodes;
};
