import { AddCircle, ArrowBack, ArrowForward, Delete, KeyboardArrowDown, KeyboardArrowLeft } from '@mui/icons-material';
import { alpha, Grid, TextField, InputAdornment, Paper } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { isEmpty, remove, uniq, update } from 'ramda';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import cs from '../../assets/intentsLibCs';
import sk from '../../assets/intentsLibSk';
import UniButton from '../../components/UniButton';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { addIntent, registerStateBeforeDispatchingAction } from '../../redux/model/actions';
import { getConfiguration, getIntents } from '../../redux/model/selectors';
import { scrollbarVertical } from '../../styles/scrollbar';
import LSM from '../../utils/LocalStorageManager';
import { normalizeNodeId as normalizeIntent } from '../../utils/normalization';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	bounding: {
		padding: '10px',
		marginTop: '-30px',
		wordBreak: 'break-word',

		'& div[data-type="Resizer"]': {
			'&:before, &:after': {
				content: '""',
				display: 'block',
				width: 0,
				height: 0,
				position: 'relative',
				top: '50%',
				transform: 'rotate(-90deg) translateY(-50%)',
				left: '-7px',
				borderLeft: '5px solid transparent',
				borderRight: '5px solid transparent',
				borderBottom: '5px solid black',
			},
			'&:after': { transform: 'rotate(90deg) translateY(-50%)', left: '-2px', marginTop: '-5px' },
		},

		'& .MuiGrid-container': { overflow: 'hidden' },
		'& .box': {
			height: 'calc(100vh - 450px)',
			margin: '15px',
			padding: '5px 15px 15px 0',
			'& .add-utterance': {
				position: 'relative',
				'& div': { opacity: 0, position: 'absolute', right: 0, top: '-1px', pointerEvents: 'none' },
				'&:hover': { backgroundColor: alpha(theme.palette.primary.light, 0.2), '& div': { opacity: 1 } },
			},
			...scrollbarVertical,
		},
		'& h4': { color: 'black', margin: '15px 0' },
		'& .MuiPaper-root': {
			height: 'calc(100vh - 375px)',
			margin: '20px 0',
			overflow: 'hidden',
			'& h4': {
				background: theme.palette.primary.main,
				color: 'white',
				marginTop: 0,
				padding: '10px 20px',
			},
		},
		'& .bold': { fontWeight: 'bold', color: 'black' },
		'& .categories div, .intents div, .utterances div': { cursor: 'pointer' },
		'& h4 div, .new-intent h5 div': { float: 'right', marginTop: '-3px' },
		'& .library': { paddingRight: '20px' },
		'& .new-intent': {
			paddingLeft: '20px',
			'& .new-intent .box': { paddingTop: '15px' },
			'& h5': { marginTop: '13px', marginBottom: '23px' },
			'& .MuiTextField-root': { margin: '0 0 15px 0' },
		},
		'& .categories.folded h4, & .intents.folded h4': {
			writingMode: 'vertical-rl',
			textOrientation: 'mixed',
			height: '100%',
			padding: '2px 0 0 0',
			verticalAlign: 'middle',
			lineHeight: 'calc(100vw/32)',
			'& div': {
				float: 'none',
				'& button': { margin: '8px 0px 0 -10px' },
			},
		},
	},
}));

const updateFilter = debounce((e, setFilter) => setFilter(e.target.value), 500, {
	leading: false,
	trailing: true,
});

/**
 * Filter by occurance in the intent name or in it's utterances (case insensitive),
 * then categorize intents by particulart cut of the intent name
 * @param {string} filter
 * @param {object} libraryIntents
 * @returns {object} Filtered and categorized object of intents and their utterances
 */
const filterAndCategorize = (filter, libraryIntents) => {
	const intentsCategorized = {};

	for (const [intentName, utterances] of Object.entries(libraryIntents)) {
		// filter
		if (
			!isEmpty(filter) &&
			!intentName.includes(filter.toUpperCase()) &&
			!JSON.stringify(utterances).toLowerCase().includes(filter.toLowerCase())
		) {
			continue;
		}

		// categorize by first two underslashes cut of intent name
		const category = intentName.match('(.*?_){2}')?.[0] ?? 'UNCLASIFIED';
		intentsCategorized[category] = { ...(intentsCategorized[category] || {}), [intentName]: utterances };
	}

	return intentsCategorized;
};

const AddIntentFromLibraryModal = ({ callback, nodeId }) => {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const intentsLibrary = { cs, sk };
	const [intent, setIntent] = useState(null);
	const [intentCategory, setIntentCategory] = useState(null);
	const [filter, setFilter] = useState('');
	const [newIntent, setNewIntent] = useState({ name: '', utterances: [] });
	const { language } = useSelector(getConfiguration);
	const existingIntentNames = Object.keys(useSelector(getIntents) || {});
	const doesIntentExist = existingIntentNames.includes(newIntent.name);
	const libraryIntents = intentsLibrary[language] || {};
	const [intentsCategorized, setIntentsCategorized] = useState({});
	const [foldCategories, setFoldCategories] = useState(false);
	const [foldIntents, setFoldIntents] = useState(false);

	useEffect(() => {
		setIntentsCategorized(filterAndCategorize(filter, libraryIntents));
	}, [filter]);

	useEffect(() => {
		handleCategoryClick();
	}, [intentsCategorized]);

	const handleCategoryClick = (intentCategory) => {
		const initIntentCategory = intentCategory ?? Object.keys(intentsCategorized)?.[0] ?? null;
		setIntentCategory(initIntentCategory);
		setIntent(Object.keys(intentsCategorized?.[initIntentCategory] ?? [])?.[0] ?? null);
	};

	const handleFilterChange = (e) => {
		e.persist();
		updateFilter(e, setFilter);
	};

	const handleOnChange = (e) => {
		const name = normalizeIntent(e.target.value);
		setNewIntent({ ...newIntent, name });
	};

	const handleAddIntent = (intent) =>
		setNewIntent({
			name: newIntent.name || intent,
			utterances: uniq([...libraryIntents[intent], ...newIntent.utterances]),
		});

	const handleAddRemoveUtterance = (utterance) => {
		const toRemove =
			newIntent.utterances.indexOf(utterance) !== -1 ? newIntent.utterances.indexOf(utterance) + 1 : false;

		setNewIntent({
			...newIntent,
			utterances: toRemove ? remove(toRemove - 1, 1, newIntent.utterances) : [utterance || '', ...newIntent.utterances],
		});
	};

	const handleSaveIntent = () => {
		dispatch(hideModal());
		dispatch(registerStateBeforeDispatchingAction(addIntent(newIntent.name, uniq(newIntent.utterances))));

		if (callback) {
			dispatch(callback(nodeId, '', newIntent.name, true));
		}
	};

	const handleDragUtterances = ({ draggableId, destination }) => {
		if (destination?.droppableId === 'utterances' || !draggableId) {
			return;
		}
		setNewIntent({
			...newIntent,
			utterances: uniq([...newIntent.utterances, draggableId]),
		});
	};

	const getListStyle = (isDraggingOver) => ({
		padding: '1rem',
		background: isDraggingOver ? alpha(theme.palette.primary.light, 0.2) : '',
	});

	const getItemStyle = (isDragging, draggableStyle) => ({
		background: isDragging ? alpha(theme.palette.primary.light, 0.2) : '',
		...draggableStyle,
	});

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.addIntent} />}
			saveBtnTitle={<FormattedMessage {...messages.addIntent} />}
			onSave={handleSaveIntent}
			maxWidth="xl"
			isSaveBtnDisabled={isEmpty(newIntent.name) || isEmpty(newIntent.utterances) || doesIntentExist}
		>
			<div className={classes.bounding}>
				<DragDropContext onDragEnd={handleDragUtterances}>
					<SplitPane split="vertical" onChange={([position]) => LSM.setAddLibIntentPanePosition(position)}>
						{/* INTENT LIBRARY */}
						<Pane initialSize={LSM.getAddLibIntentPanePosition()} minSize="50%" className="library">
							<h4>
								<FormattedMessage {...messages.addIntentFromLibrary} />
							</h4>
							{/* FILTER */}
							<TextField
								onChange={handleFilterChange}
								label={<FormattedMessage {...messages.filterByIntentAndUtterances} />}
								variant="outlined"
							/>
							<Grid container spacing={2}>
								{/* INTENT CATEGORIES */}
								<Grid xs={foldCategories ? 1 : 4} item className={foldCategories ? 'folded categories' : 'categories'}>
									<Paper elevation={4} square>
										<h4>
											<UniButton
												onClick={() => setFoldCategories(!foldCategories)}
												icon={foldCategories ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
												title={
													<FormattedMessage {...messages[foldCategories ? 'unfoldCategories' : 'foldCategories']} />
												}
												size={25}
												color="white"
											/>
											<FormattedMessage {...messages.categories} />
										</h4>
										{!foldCategories && (
											<div className="box">
												{Object.keys(intentsCategorized).map((_intentCategory) => (
													<div
														onClick={() => handleCategoryClick(_intentCategory)}
														className={intentCategory === _intentCategory ? 'bold' : ''}
														key={'intent-category-' + _intentCategory}
													>
														{_intentCategory}
													</div>
												))}
											</div>
										)}
									</Paper>
								</Grid>

								{/* INTENTS */}
								<Grid xs={foldIntents ? 1 : 4} item className={foldIntents ? 'folded intents' : 'intents'}>
									<Paper elevation={4} square>
										<h4>
											<UniButton
												onClick={() => setFoldIntents(!foldIntents)}
												icon={foldIntents ? <KeyboardArrowDown /> : <KeyboardArrowLeft />}
												title={<FormattedMessage {...messages[foldIntents ? 'unfoldIntents' : 'foldIntents']} />}
												size={25}
												color="white"
											/>
											<FormattedMessage {...messages.intents} />
										</h4>
										{!foldIntents && (
											<div className="box">
												{intentCategory &&
													Object.keys(intentsCategorized?.[intentCategory] ?? {}).map((_intent) => (
														<div
															onClick={() => setIntent(_intent)}
															key={'intent-' + _intent}
															className={intent === _intent ? 'bold' : ''}
														>
															{_intent}
														</div>
													))}
											</div>
										)}
									</Paper>
								</Grid>

								{/* UTTERANCES */}
								<Grid xs={4 + (foldIntents ? 3 : 0) + (foldCategories ? 3 : 0)} item className="utterances">
									<Paper elevation={4} square>
										<h4>
											<FormattedMessage {...messages.utterances} />
											<UniButton
												onClick={() => handleAddIntent(intent)}
												icon={<ArrowForward />}
												title={<FormattedMessage {...messages.useIntentAndUtterances} />}
												size={25}
												color="white"
											/>
										</h4>
										<Droppable droppableId="utterances">
											{(provided) => (
												<div ref={provided.innerRef} {...provided.droppableProps} className="box">
													{provided.placeholder}
													{intentCategory &&
														intent &&
														(intentsCategorized?.[intentCategory]?.[intent] ?? []).map((utterance, i) => {
															const isInUse = newIntent.utterances.includes(utterance);
															return (
																<Draggable draggableId={utterance} index={i}>
																	{(provided, snapshot) => (
																		<div
																			onClick={() => handleAddRemoveUtterance(utterance)}
																			className={`add-utterance ${isInUse ? 'bold' : ''}`}
																			key={'lib-utterance-' + i}
																			{...provided.draggableProps}
																			{...provided.dragHandleProps}
																			ref={provided.innerRef}
																			style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
																		>
																			{utterance}
																			<UniButton
																				icon={isInUse ? <ArrowBack /> : <ArrowForward />}
																				size={20}
																				active={!isInUse}
																				alert={isInUse}
																			/>
																		</div>
																	)}
																</Draggable>
															);
														})}
												</div>
											)}
										</Droppable>
									</Paper>
								</Grid>
							</Grid>
						</Pane>
						{/* NEW INTENT */}
						<Pane minSize="15%" className="new-intent">
							<h4>
								<FormattedMessage {...messages.composeNewIntent} />
							</h4>
							<TextField
								value={newIntent.name}
								onChange={handleOnChange}
								label={<FormattedMessage {...messages.nameYourNewIntent} />}
								variant="outlined"
								error={doesIntentExist}
								helperText={doesIntentExist ? <FormattedMessage {...messages.intentExists} /> : null}
							/>
							<h5>
								<FormattedMessage {...messages.utterances} />
								<UniButton
									onClick={() => handleAddRemoveUtterance()}
									icon={<AddCircle />}
									title={<FormattedMessage {...messages.addUtterance} />}
									size={25}
									active
								/>
							</h5>
							<Droppable droppableId="newUtterances">
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
										style={getListStyle(snapshot.isDraggingOver)}
										className="box"
									>
										{provided.placeholder}
										{newIntent.utterances.map((utterance, i) => (
											<>
												<TextField
													value={utterance}
													onChange={(e) =>
														setNewIntent({
															...newIntent,
															utterances: update(i, e.target.value, newIntent.utterances),
														})
													}
													label={<FormattedMessage {...messages.utterance} />}
													variant="outlined"
													key={'new-utterance-' + i}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<UniButton
																	onClick={() =>
																		setNewIntent({ ...newIntent, utterances: remove(i, 1, newIntent.utterances) })
																	}
																	icon={<Delete />}
																	title={<FormattedMessage {...messages.removeUtterance} />}
																	size={20}
																/>
															</InputAdornment>
														),
													}}
												/>
											</>
										))}
									</div>
								)}
							</Droppable>
						</Pane>
					</SplitPane>
				</DragDropContext>
			</div>
		</SaveCloseModalTemplate>
	);
};

AddIntentFromLibraryModal.propTypes = {
	callback: PropTypes.func,
	nodeId: PropTypes.string,
};

export default AddIntentFromLibraryModal;
