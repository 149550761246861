import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import close from '../../assets/icons/closeModal.svg';
import messages from '../../intl/messages';
import { palette } from '../../mui-theme-builder';
import { hideModal } from '../../redux/modal/actions';
import { scrollbarVertical } from '../../styles/scrollbar';
import UniButton from '../UniButton/UniButton';

const useStyles = makeStyles({
	bounding: {
		'& .title': {
			borderBottom: '1px solid' + grey[50],
			padding: '0px 0 10px 0',
		},
		'& .titleText': {
			color: grey[600],
			fontSize: 22,
		},
		'& .MuiDialogContent-root': {
			padding: '0 1rem',
			'&:hover': { padding: '0 .5rem 0 1rem' },
			...scrollbarVertical,
		},
		'& .bodyContent': {
			padding: '20px 0',
			margin: 0,
			color: '#5c5c5c',
		},
		'& .footer': {
			borderTop: '1px solid' + grey[300],
			padding: '15px 0 0 0',
		},
		'& .hidden': {
			display: 'none',
		},
		'& .close': { float: 'right', marginLeft: '1rem' },
	},
});

const SaveCloseModalTemplate = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		children,
		closeBtnTitle,
		disableBackdropClickDialogClose = false,
		disableCloseDialog,
		disableEscapeDialogClose = false,
		isSaveBtnDisabled,
		isSaveBtnVisible = true,
		maxWidth,
		headerButton,
		previousUrlOnClose = false,
		saveOnEnterKey,
		noControls,
		onSave,
		onClose = () => {},
		saveBtnColor,
		saveBtnTitle,
		title,
	} = props;

	const closeDialog = () => {
		if (previousUrlOnClose) {
			navigate(-1);
		}

		dispatch(hideModal());
		onClose();
	};

	const handleKeyPress = (e) => {
		if (saveOnEnterKey && e.key === 'Enter') {
			onSave(e);
		}
	};

	const handleClose = (e, reason) => {
		if (
			disableCloseDialog ||
			(reason === 'escapeKeyDown' && disableEscapeDialogClose) ||
			(reason === 'backdropClick' && disableBackdropClickDialogClose)
		) {
			return;
		} else {
			closeDialog();
		}
	};

	return (
		<Dialog
			open
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			className={classes.bounding}
			fullWidth
			maxWidth={maxWidth || 'sm'}
			onKeyPress={handleKeyPress}
		>
			<DialogTitle id="alert-dialog-title" className="title">
				<span className="titleText">{title}</span>
				<UniButton
					onClick={closeDialog}
					icon={<img src={close} />}
					title={closeBtnTitle || <FormattedMessage {...messages.close} />}
					className="close"
				/>
				{headerButton}
			</DialogTitle>
			<DialogContent>
				<div id="alert-dialog-description" className="bodyContent">
					{children}
				</div>
			</DialogContent>
			<DialogActions className={`footer ${noControls && 'hidden'}`}>
				{isSaveBtnVisible && (
					<Button
						onClick={onSave}
						disabled={isSaveBtnDisabled}
						style={{ background: saveBtnColor || palette.primary.main }}
						variant="contained"
						data-testid="modalConfirmButton"
					>
						{saveBtnTitle}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

SaveCloseModalTemplate.propTypes = {
	children: PropTypes.node.isRequired,
	closeBtnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	disableBackdropClickDialogClose: PropTypes.bool,
	disableCloseDialog: PropTypes.bool,
	disableEscapeDialogClose: PropTypes.bool,
	isSaveBtnDisabled: PropTypes.bool,
	isSaveBtnVisible: PropTypes.bool,
	maxWidth: PropTypes.string,
	noControls: PropTypes.bool,
	onSave: PropTypes.func,
	onClose: PropTypes.func,
	saveBtnColor: PropTypes.string,
	saveBtnTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	saveOnEnterKey: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	headerButton: PropTypes.node,
	previousUrlOnClose: PropTypes.bool,
};

export default SaveCloseModalTemplate;
