import { grey } from '@mui/material/colors';
import { scrollbarVertical } from '../../../../../../styles/scrollbar';
import { boxShadow, drawerWidth, transition } from '../../material-dashboard-react';

const sidebarStyle = (theme) => ({
	drawerPaper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',

		border: 'none',
		backgroundColor: theme.palette.secondary.dark + ' !important',
		overflow: 'hidden',
		position: 'fixed',
		top: '0',
		bottom: '0',
		left: '0',
		zIndex: '1',
		...boxShadow,
		width: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			position: 'fixed',
			height: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: drawerWidth,
			...boxShadow,
			position: 'fixed',
			display: 'block',
			top: '0',
			height: '100vh',
			right: '0',
			left: 'auto',
			zIndex: '1032',
			visibility: 'visible',
			overflowY: 'visible',
			borderTop: 'none',
			textAlign: 'left',
			paddingRight: '0',
			paddingLeft: '0',
			transform: `translate3d(${drawerWidth}, 0, 0)`,
			...transition,
		},
	},
	drawerPaperRTL: {
		[theme.breakpoints.up('md')]: {
			left: 'auto !important',
			right: '0 !important',
		},
		[theme.breakpoints.down('sm')]: {
			left: '0  !important',
			right: 'auto !important',
		},
	},
	logo: {
		position: 'relative',
		padding: '0.9rem 0.9rem',
		zIndex: '4',
		textAlign: 'center',
	},
	logoImage: {
		width: '100%',
	},
	img: {
		width: '100%',
		maxWidth: 210,
	},
	background: {
		position: 'absolute',
		zIndex: '1',
		height: '100%',
		width: '100%',
		display: 'block',
		top: '0',
		left: '0',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		'&:after': {
			position: 'absolute',
			zIndex: '3',
			width: '100%',
			height: '100%',
			content: '""',
			display: 'block',
			background: grey[900],
			opacity: '.8',
		},
	},
	list: {
		marginTop: '0.6rem',
		paddingLeft: '0',
		paddingTop: '0',
		paddingBottom: '0',
		marginBottom: '0',
		listStyle: 'none',
		position: 'unset',
	},
	title: {
		color: theme.palette.primary.main,
		fontSize: '0.875rem',
		fontWeight: 600,
		fontFamily: 'Purista',
		textTransform: 'uppercase',
		padding: '0.5rem 0',
		margin: '1.3rem 1.3rem 0 1.3rem',
		borderBottom: '0.13rem solid ' + theme.palette.secondary.darker,
	},
	item: {
		position: 'relative',
		display: 'block',
		textDecoration: 'none',
		'&:hover,&:focus,&:visited,&': {
			color: 'white',
		},
		'&.active div:first-child': {
			'& svg': {
				fill: theme.palette.primary.main,
				'& path': {
					fill: theme.palette.primary.main,
				},
			},
			'& div': {
				color: theme.palette.primary.main,
			},
		},
	},
	itemLink: {
		width: 'auto',
		transition: 'all 300ms linear',
		margin: '0 0.9rem 0',
		borderRadius: '0.19rem',
		position: 'relative',
		display: 'flex',
		padding: '0.5rem 0.6rem',
		backgroundColor: 'transparent',
		color: 'white',
		'& svg': {
			width: '1.3rem',
			height: 'auto',
			'& path': {
				fill: 'white',
			},
		},
	},
	itemIconRTL: {
		marginRight: '0.19rem',
		marginLeft: '0.9rem',
		float: 'right',
	},
	itemText: {
		color: 'rgba(255, 255, 255, 0.8)',
		fontSize: '0.875rem',
		fontWeight: 600,
		lineHeight: '1.9rem',
		margin: '0',
		marginLeft: 15,
		transition: 'all 150ms linear',
	},
	sidebarWrapper: {
		position: 'relative',
		overflow: 'auto',
		width: '16.25rem',
		zIndex: '4',
		overflowScrolling: 'touch',
		paddingBottom: 20,
		paddingTop: 5,
		...scrollbarVertical,
	},
	activePro: {
		[theme.breakpoints.up('md')]: {
			position: 'absolute',
			width: '100%',
			bottom: '0.813rem',
		},
	},
});

export default sidebarStyle;
