import Autocomplete from '@mui/lab/Autocomplete';
import { CircularProgress, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { palette } from '../../mui-theme-builder';
import { hideModal } from '../../redux/modal/actions';
import { editModelUserRole, fetchUsers } from '../../redux/model-table/actions';
import { getUsers } from '../../redux/model-table/selectors';
import { validateIsEmpty } from '../../utils/validation';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const AddUserRightsModal = ({ role, modelId, modelName, creator }) => {
	const users = useSelector(getUsers());
	const [errorName, setErrorName] = useState(null);
	const [usersFromApi, setUsersFromApi] = useState(users);
	const [userName, setUserName] = useState({ name: '', id: '' });
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	const isUserMatch = (_userName) => {
		for (const user of usersFromApi) {
			if (user.name === _userName) {
				setUserName(user);
				return null;
			}
		}

		setUserName({ ...userName, name: _userName });
		return <FormattedMessage {...messages.userDoesntExist} />;
	};

	useEffect(() => {
		if (loading) {
			dispatch(fetchUsers());
		}
	}, []);

	useEffect(() => {
		setUsersFromApi(users.filter((user) => user.name !== creator && (user.role !== 'EndUser' || role === 'Viewer')));
		setLoading(false);
	}, [users]);

	const handleUserNameChange = (e, v) => {
		const _userName = v || e.target.value;
		if (typeof _userName === 'object') {
			setErrorName(null);
			setUserName(_userName);
		} else {
			setErrorName(isUserMatch(_userName));
		}
	};

	const handleAdd = () => {
		setErrorName(validateIsEmpty(userName.name));
		if (validateIsEmpty(userName.name) || errorName) {
			return;
		}

		dispatch(editModelUserRole(modelId, userName.id, role));
		dispatch(hideModal());
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.assignUserToModel} values={{ role, modelName }} />}
			saveBtnTitle={<FormattedMessage {...messages.assignRole} />}
			onSave={handleAdd}
		>
			<Grid container spacing={2} style={{ width: '100%' }}>
				<Grid item xs={12} align="left">
					<Autocomplete
						options={usersFromApi}
						getOptionLabel={(option) => option.name}
						freeSolo
						disabled={loading}
						inputValue={userName.name}
						renderInput={(params) => (
							<TextField
								{...params}
								autoFocus
								error={!!errorName}
								helperText={errorName}
								label={!loading && <FormattedMessage {...messages.selectUser} />}
								variant={'outlined'}
								fullWidth
								onKeyPress={handleKeyPress}
								required={!loading}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<span style={{ color: palette.primary.main, position: 'absolute', left: '15px' }}>
											{loading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</span>
									),
								}}
								onChange={handleUserNameChange}
							/>
						)}
						onChange={handleUserNameChange}
					/>
				</Grid>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

AddUserRightsModal.propTypes = {
	role: PropTypes.string,
	modelId: PropTypes.string,
	modelName: PropTypes.string,
	creator: PropTypes.string,
};

export default AddUserRightsModal;
