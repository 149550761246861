import { createActionsMap, makeActionCreator } from '../../utils/action-utils';
import { ID } from './selectors';

export const actionTypes = createActionsMap(ID, ['SEARCH_AND_SET_RESULTS', 'SET_SEARCH_VALUE']);

const searchAndSetResults = makeActionCreator(
	actionTypes.SEARCH_AND_SET_RESULTS,
	'searchValue',
	'searchOperator',
	'ignoreCase',
	'nodes',
	'intents'
);
const setSearchValue = makeActionCreator(actionTypes.SET_SEARCH_VALUE, 'searchValue');

export const searchForAllReferencesInModel = (searchValue, searchOperator, ignoreCase) => (dispatch) => {
	dispatch(setSearchValue(searchValue));
	dispatch(searchAndSetResults(searchValue, searchOperator, ignoreCase));
};
