import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	wrap: {
		listStyleType: 'none',
		display: 'inline-block',
	},
	iconBtn: {
		padding: 4,
	},
	icon: {
		width: 18,
		height: 18,
		margin: 0,
		color: theme.palette.secondary.main,
	},
}));

const TrashButton = ({ title, placement, type, ...rest }) => {
	const classes = useStyles();

	return (
		<div className={`${classes.wrap} trash-button`}>
			<Tooltip title={title} placement={placement || 'top'}>
				<IconButton aria-label={title} className={classes.iconBtn} {...rest}>
					{type === 'close' ? <Close className={classes.icon} /> : <Delete className={classes.icon} />}
				</IconButton>
			</Tooltip>
		</div>
	);
};

TrashButton.propTypes = {
	placement: PropTypes.string,
	title: PropTypes.object,
	type: PropTypes.string,
};

export default TrashButton;
