import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import * as React from 'react';

export default class ErrorBoundary extends React.Component {
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	state = { hasError: false };

	componentDidCatch(error, info) {
		console.error('An error occurred while rendering an element:', error, info);

		if (process.env.DIAGNOSTIC_LOGGING_ENABLED) {
			Sentry.withScope((scope) => {
				scope.setExtra('errorStack', error.stack);
				scope.setExtra('componentStack', info.componentStack);
				scope.setLevel(Sentry.Severity.Error);
				Sentry.captureException(error);
			});
			LogRocket.captureException(error, { extra: { errorStack: error.stack, componentStack: info.componentStack } });
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<h1>Oops, something went wrong!</h1>
					Please try to refresh the page and repeat your action again.
					<br />
					If the error persists, please <strong>contact our support</strong> and report the output of your browser
					console.
				</>
			);
		} else {
			return this.props.children;
		}
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
};
