import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';

const useStyles = makeStyles((theme) => ({
	title: {
		borderBottom: '1px solid' + grey[50],
		padding: '0px 0 10px 0',
	},
	titleText: {
		color: grey[900],
		fontSize: 22,
	},
	body: {
		padding: 0,
	},
	bodyContent: {
		padding: '20px 0',
		margin: 0,
		color: '#5c5c5c',
	},
	footer: {
		borderTop: '1px solid' + grey[50],
		padding: '15px 0 0 0',
	},
	save: {
		background: theme.palette.primary.main,
		color: '#fff',
		minWidth: 100,
	},
	cancel: {
		background: grey[900],
		color: '#fff',
		minWidth: 100,
	},
}));

export default function Modal(props) {
	const classes = useStyles();
	const { handleClose, handleConfirm, open, data, dialogTitle, dialogBody } = props;
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className="modal-window"
			>
				<DialogTitle id="alert-dialog-title" className={classes.title}>
					<span className={classes.titleText}>{dialogTitle}</span>
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div id="alert-dialog-description" className={classes.bodyContent}>
						{dialogBody}
						{data ? data : null}
					</div>
				</DialogContent>
				<DialogActions className={classes.footer}>
					<Button onClick={handleConfirm} variant="contained" color="primary">
						<FormattedMessage {...messages.save} />
					</Button>
					<Button onClick={handleClose} variant="contained">
						<FormattedMessage {...messages.cancel} />
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

Modal.propTypes = {
	handleClose: PropTypes.func,
	handleConfirm: PropTypes.func,
	open: PropTypes.bool,
	data: PropTypes.bool,
	dialogTitle: PropTypes.object,
	dialogBody: PropTypes.object,
};
