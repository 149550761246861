import { omit } from 'lodash';
import * as requests from '../../core/requests';
import {
	assignPhoneNumbersToConfigQL,
	assignPhoneNumberToOrganizationQL,
	fetchPhoneNumbersQL,
	unassignPhoneNumberFromOrganizationQL,
	unassignPhoneNumberFromConfigQL,
	getAvailablePhoneNumbers as getAvailablePhoneNumbersQL,
	fetchVoiceNamesQL,
} from '../../graphql/voice';
import { createActionsMap } from '../../utils/action-utils';
import { normalizedSuccess, psf } from '../utils';
import { phoneNumberListSchema } from './schema';

export const ID = 'voice';

export const actionTypes = createActionsMap(ID, [
	...psf('FETCH_PHONE_NUMBERS'),
	...psf('FETCH_VOICE_NAMES'),
	...psf('ASSIGN_PHONE_NUMBERS_TO_CONFIG'),
	...psf('UNASSIGN_PHONE_NUMBER_FROM_CONFIG'),
	...psf('ASSIGN_PHONE_NUMBER_TO_ORGANIZATION'),
	...psf('UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION'),
	...psf('FETCH_AVAILABLE_PHONE_NUMBERS'),
]);

export const fetchVoiceNames = (locale) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_VOICE_NAMES_PENDING,
				actionTypes.FETCH_VOICE_NAMES_SUCCESS,
				actionTypes.FETCH_VOICE_NAMES_FAIL,
			],
			{ query: fetchVoiceNamesQL, variables: { locale } }
		)
	);

export const fetchPhoneNumbers = () => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_PHONE_NUMBERS_PENDING,
				normalizedSuccess(actionTypes.FETCH_PHONE_NUMBERS_SUCCESS, ['data', 'phoneNumbers'], phoneNumberListSchema),
				actionTypes.FETCH_PHONE_NUMBERS_FAIL,
			],
			{ query: fetchPhoneNumbersQL }
		)
	);

export const assignPhoneNumbersToConfig = (configId, phoneNumbers) => (dispatch) => {
	phoneNumbers = phoneNumbers.map((phoneNumber) => omit(phoneNumber, 'config'));

	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.ASSIGN_PHONE_NUMBERS_TO_CONFIG_PENDING,
				normalizedSuccess(
					actionTypes.ASSIGN_PHONE_NUMBERS_TO_CONFIG_SUCCESS,
					['data', 'assignPhoneNumbersToConfig'],
					phoneNumberListSchema
				),
				actionTypes.ASSIGN_PHONE_NUMBERS_TO_CONFIG_FAIL,
			],
			{ query: assignPhoneNumbersToConfigQL, variables: { configId, phoneNumbers } }
		)
	);
};

export const unassignPhoneNumberFromConfig = (phoneNumber) => (dispatch) => {
	phoneNumber = omit(phoneNumber, 'config');

	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.UNASSIGN_PHONE_NUMBER_FROM_CONFIG_PENDING,
				normalizedSuccess(
					actionTypes.UNASSIGN_PHONE_NUMBER_FROM_CONFIG_SUCCESS,
					['data', 'unassignPhoneNumberFromConfig'],
					phoneNumberListSchema
				),
				actionTypes.UNASSIGN_PHONE_NUMBER_FROM_CONFIG_FAIL,
			],
			{ query: unassignPhoneNumberFromConfigQL, variables: { phoneNumber } }
		)
	);
};

export const assignPhoneNumberToOrganization = (phoneNumber) => (dispatch) => {
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_PENDING,
				normalizedSuccess(
					actionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_SUCCESS,
					['data', 'assignPhoneNumberToOrganization'],
					phoneNumberListSchema
				),
				actionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_FAIL,
			],
			{ query: assignPhoneNumberToOrganizationQL, variables: { phoneNumber } }
		)
	);
};

export const unassignPhoneNumberFromOrganization = (phoneNumber) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_PENDING,
				normalizedSuccess(
					actionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_SUCCESS,
					['data', 'unassignPhoneNumberFromOrganization'],
					phoneNumberListSchema
				),
				actionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_FAIL,
			],
			{ query: unassignPhoneNumberFromOrganizationQL, variables: { phoneNumber } }
		)
	);

export const fetchAvailablePhoneNumbers = (modelId) => (dispatch) =>
	dispatch(
		requests.qlAuthRequest(
			[
				actionTypes.FETCH_AVAILABLE_PHONE_NUMBERS_PENDING,
				normalizedSuccess(
					actionTypes.FETCH_AVAILABLE_PHONE_NUMBERS_SUCCESS,
					['data', 'availablePhoneNumbers'],
					phoneNumberListSchema
				),
				actionTypes.FETCH_AVAILABLE_PHONE_NUMBERS_FAIL,
			],
			{
				query: getAvailablePhoneNumbersQL,
				variables: { modelId },
			}
		)
	);
