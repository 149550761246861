import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'ramda';
import React, { useEffect, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from '../../intl';
import { fetchModelVersion } from '../../redux/model-table/actions';
import { getModelVersion, getSelectedModelVersions, selectedModelVersionId } from '../../redux/model-table/selectors';
import { getRawYaml, getYamlFromModel, ID } from '../../redux/model/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles({
	examplesHeading: {
		margin: 0,
	},
	examplesContainer: {
		background: grey[50],
		marginTop: 0,
		marginBottom: '10px',
		padding: '10px',
		fontSize: '12px',
		lineHeight: '1em',
	},
	formField: {
		width: '250px',
		float: 'right',
	},
	inputsContainer: {
		maxHeight: '450px',
		overflow: 'auto',
		alignItems: 'center',
	},
	importButtonContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	overwriteCheckboxContainer: {
		marginTop: '10px',
	},
	overwriteCheckbox: {
		marginLeft: '5px',
		padding: 0,
	},
	overwriteCheckboxLabel: {
		fontSize: '12px',
	},
});

const DiffYamlModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const yamlWithCurrentChanges = useSelector(getRawYaml);
	const versions = useSelector(getSelectedModelVersions);

	// Use the unmodified last version as default
	const [selectedVersionId, setSelectedVersionId] = useState(useSelector(selectedModelVersionId));
	const originalVersion = useSelector(getModelVersion(selectedVersionId));
	const [originalYaml, setOriginalYaml] = useState('');

	useEffect(() => {
		if (selectedVersionId) {
			dispatch(fetchModelVersion(selectedVersionId));
		}
	}, [selectedVersionId]);

	useEffect(() => {
		if (!isEmpty(originalVersion) && originalVersion.data) {
			const originalVersionYaml = getYamlFromModel({ [ID]: originalVersion.data });
			setOriginalYaml(originalVersionYaml);
		}
	}, [originalVersion]);

	return (
		<div>
			<SaveCloseModalTemplate
				title={<FormattedMessage {...messages.diffYamlModal} />}
				isSaveBtnVisible={false}
				disableEscapeDialogClose
				disableBackdropClickDialogClose
				maxWidth="xl"
				headerButton={
					<FormControl className={classes.formField}>
						<InputLabel id="select-label">
							<FormattedMessage {...messages.selectVersion} />
						</InputLabel>
						<Select
							labelId="select-label"
							variant="outlined"
							value={selectedVersionId}
							onChange={(e, v) => setSelectedVersionId(e.target.value)}
						>
							{versions
								.sort((a, b) => +new Date(a.updatedAt) - +new Date(b.updatedAt))
								.map((version) => (
									<MenuItem key={version.id} value={version.id}>
										<span>{version.version}</span>
										{version.description && <span>({version.description})</span>}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				}
			>
				<Grid container>
					<Grid item xs={12}>
						<ReactDiffViewer
							oldValue={originalYaml}
							newValue={yamlWithCurrentChanges}
							splitView={false}
							styles={{ contentText: { lineHeight: '20px' } }}
						/>
					</Grid>
				</Grid>
			</SaveCloseModalTemplate>
		</div>
	);
};

export default DiffYamlModal;
