import Autocomplete from '@mui/lab/Autocomplete';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SIGNAL_TRANSITION_TYPES } from '../../core/configs';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import {
	clearSelectedNodes,
	createNode,
	createUpdateTransitionSignal,
	registerStateHistory,
} from '../../redux/model/actions';
import { getAvailableTransitionNodes, getNode, getNodeIds } from '../../redux/model/selectors';
import NodeIdTextField from '../NodeIdTextField/NodeIdTextField';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: 13,
	},
	formLabel: {
		color: theme.palette.primary.main,
	},
}));

const AddEditSignalModal = ({ nodeId, currTargetNode, currSignalType }) => {
	const classes = useStyles();
	const [inputConf, setInputConf] = useState({
		validation: null,
		caretPos: null,
		nodeId: currTargetNode || '',
	});

	const signalTypes = Object.entries(SIGNAL_TRANSITION_TYPES);
	const defaultSignalType = currSignalType || signalTypes[0][1];
	const [signalType, setSignalType] = useState(defaultSignalType);
	const nodeIds = useSelector(getAvailableTransitionNodes(nodeId));
	const allNodes = useSelector(getNodeIds);
	const node = useSelector(getNode(nodeId));
	const dispatch = useDispatch();
	const isNewNode = inputConf.nodeId && !includes(inputConf.nodeId, allNodes);

	const handleSignalTypeChange = (e) => {
		e.persist();
		setSignalType(e.target.value);
		setInputConf({
			...inputConf,
			validation: null,
			caretPos: null,
		});
	};

	const handleSave = () => {
		if (inputConf.validation) {
			return;
		}

		dispatch(registerStateHistory());

		let prefixedNodeName = '';
		if (isNewNode) {
			dispatch(clearSelectedNodes());
			dispatch(createNode({ newNodeId: inputConf.nodeId, shouldShowActiveNode: true, baseNodeId: nodeId }));
			// The nodeId gets prefixed by the respective group name during creation if groupView is on
			if (node.group) {
				prefixedNodeName = `${node.group}_${inputConf.nodeId}`;
			}
		}
		dispatch(createUpdateTransitionSignal(nodeId, prefixedNodeName || inputConf.nodeId, signalType));
		dispatch(hideModal());
	};

	const btnText = isNewNode ? 'createNodeAndTransition' : currSignalType ? 'updateTransition' : 'createTransition';

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.continueSignal} />}
			saveBtnTitle={<FormattedMessage {...messages[btnText]} />}
			onSave={handleSave}
			isSaveBtnDisabled={!inputConf.nodeId || inputConf.nodeId.length < 1 || inputConf.nodeId === nodeId}
		>
			<FormControl component="fieldset">
				<FormLabel component="legend" className={classes.formLabel}>
					<FormattedMessage {...messages.type} />:
				</FormLabel>
				<RadioGroup row defaultValue={defaultSignalType} onChange={handleSignalTypeChange}>
					<Grid container>
						{signalTypes.map(([signalTypeUpperCase, signalTypeOriginal], index) => (
							<Grid item xs={6} key={signalTypeUpperCase + index}>
								<FormControlLabel
									classes={{
										label: classes.label,
									}}
									value={signalTypeOriginal}
									control={<Radio color="primary" />}
									label={signalTypeUpperCase}
								/>
								{/* <InfoButton title={'prefix' + signalTypeUpperCase} /> */}
							</Grid>
						))}
					</Grid>
				</RadioGroup>
			</FormControl>
			<br />
			<br />
			<Autocomplete
				freeSolo
				blurOnSelect
				options={nodeIds}
				inputValue={inputConf.nodeId}
				getOptionLabel={String}
				renderInput={(params) => (
					<NodeIdTextField
						autoFocus
						nodeId={inputConf.nodeId}
						label={<FormattedMessage {...messages.targetNode} />}
						handleChange={setInputConf}
						helperText={
							isNewNode && inputConf.nodeId !== nodeId ? (
								<FormattedMessage {...messages.newNodeInfo} values={{ id: inputConf.nodeId }} />
							) : null
						}
						allowDuplicate
						{...params}
					/>
				)}
				// In case a nodeId from the list is selected and the clear icon is clicked
				onChange={(e, nodeId) =>
					setInputConf({
						caretPos: null,
						validation: null,
						nodeId: nodeId || '',
					})
				}
				// In case a custom nodeId value not on the list is inputted and the clear icon is clicked
				onInputChange={(e, v, reason) => {
					if (reason === 'clear') {
						setInputConf({
							caretPos: null,
							validation: null,
							nodeId: '',
						});
					}
				}}
			/>
		</SaveCloseModalTemplate>
	);
};

AddEditSignalModal.propTypes = {
	nodeId: PropTypes.string,
	currTargetNode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	currSignalType: PropTypes.string,
};

export default AddEditSignalModal;
