import { produce } from 'immer';
import { actionTypes } from './actions';

const DEFAULT_STATE = {
	emails: [],
};

const emailAnalytics = produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case actionTypes.FETCH_EMAILS_SUCCESS:
			state.emails = payload?.emails ?? [];
			break;
		case actionTypes.FETCH_EMAILS_FAIL:
			return DEFAULT_STATE;
	}
}, DEFAULT_STATE);

export default emailAnalytics;
