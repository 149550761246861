import { combineReducers } from 'redux';
import { actionTypes as authActionTypes } from '../auth/actions';
import fetching from '../util/reducers';
import { actionTypes as voiceActionTypes } from '../voice/actions';

export default combineReducers({
	fetchOrganizations: fetching([
		authActionTypes.FETCH_ORGANIZATIONS_PENDING,
		authActionTypes.FETCH_ORGANIZATIONS_SUCCESS,
		authActionTypes.FETCH_ORGANIZATIONS_FAIL,
	]),
	assignPhoneNumberToOrganization: fetching([
		voiceActionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_PENDING,
		voiceActionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_SUCCESS,
		voiceActionTypes.ASSIGN_PHONE_NUMBER_TO_ORGANIZATION_FAIL,
	]),
	unassignPhoneNumberFromOrganization: fetching([
		voiceActionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_PENDING,
		voiceActionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_SUCCESS,
		voiceActionTypes.UNASSIGN_PHONE_NUMBER_FROM_ORGANIZATION_FAIL,
	]),
	createUser: fetching([
		authActionTypes.CREATE_USER_PENDING,
		authActionTypes.CREATE_USER_SUCCESS,
		authActionTypes.CREATE_USER_FAIL,
	]),
	updateUser: fetching([
		authActionTypes.UPDATE_USER_PENDING,
		authActionTypes.UPDATE_USER_SUCCESS,
		authActionTypes.UPDATE_USER_FAIL,
	]),
});
