import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Add from '@mui/icons-material/Add';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CodeIcon from '@mui/icons-material/Code';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SelectIcon from '@mui/icons-material/FindInPage';
import AddToGroupIcon from '@mui/icons-material/Input';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonIcon from '@mui/icons-material/Person';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RedoIcon from '@mui/icons-material/Redo';
import SearchIcon from '@mui/icons-material/Search';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import StarsIcon from '@mui/icons-material/Stars';
import Stop from '@mui/icons-material/Stop';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import UndoIcon from '@mui/icons-material/Undo';
import ViewComfy from '@mui/icons-material/ViewComfy';
import ViewModule from '@mui/icons-material/ViewModule';
import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../intl/messages';
import { palette } from '../mui-theme-builder';
import { USER_ROLES } from '../redux/auth/actions';
import { binSvg, logoutSvg, settingsSvg, checkMark, crossIcon } from './Icons';

export const styles = {
	zoom: {
		margin: 0,
		fill: palette.secondary.main,
	},
	add: {
		fill: '#fff',
		margin: 0,
	},
	clickableMenu: {
		fill: '#fff',
		margin: 0,
		cursor: 'pointer',
	},
};

export const Condition = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.condition} />} placement={'top'}>
		<DeviceHubIcon />
	</Tooltip>
);

export const Intent = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.intent} />} placement={'top'}>
		<MyLocationIcon />
	</Tooltip>
);

export const SmartFunction = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.smartFunction} />} placement={'top'}>
		<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
			<path d="m89.168 45-6.6719-5-11.66 15.555-10.418-13.891h-17.344l6.0742-28.84c0.078124-0.375 0.85156-0.36719 0.85156 0.089843v1.668h8.3359v-1.668c0-4.8242-3.9258-8.75-8.7852-8.75-4.1172 0-7.7148 2.9219-8.5625 6.9492l-6.4297 30.551h-9.5586v8.3359h7.8047l-6.9492 33.008c-0.082031 0.375-0.85547 0.36719-0.85547-0.085937v-1.6719h-8.332v1.6719c0 4.8242 3.9219 8.7422 8.7812 8.7422 4.1133 0 7.7188-2.918 8.5625-6.9453l7.3047-34.719h14.934l8.7461 11.664 0.62891 0.83594-13.129 17.5 6.6719 5.0039 11.668-15.559 11.66 15.559 6.6719-5.0039-13.125-17.5z" />
		</svg>
	</Tooltip>
);

export const GlobalStartNode = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.globalStartNode} />} placement={'top'}>
		<StarsIcon />
	</Tooltip>
);

export const GroupStartNode = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.groupStartNode} />} placement={'top'}>
		<PlayArrowIcon />
	</Tooltip>
);

export const Variable = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.variable} />} placement={'top'}>
		<CodeIcon />
	</Tooltip>
);

export const TextOutput = () => (
	<Tooltip title={<FormattedMessage {...messages.textOutput} />} placement={'top'}>
		<span className="output-icon">
			<svg width="100px" height="100px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m12.5 20.832h58.332v8.332h-58.332z" />
					<path d="m12.5 45.832h75v8.332h-75z" />
					<path d="m12.5 70.832h41.668v8.332h-41.668z" />
				</g>
			</svg>
		</span>
	</Tooltip>
);

export const VoiceOutput = () => (
	<Tooltip title={<FormattedMessage {...messages.voiceOutput} />} placement={'top'}>
		<span className="output-icon">
			<svg width="100px" height="100px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				<g>
					<path d="m53.898 9.6992l-29.797 22.801-21.602 1.8984v31.203l21.602 1.8984 29.797 22.801z" />
					<path d="m72.301 23.898l-6.6992 6.1016c4.8008 5.3008 7.6992 12.301 7.6992 20s-2.8984 14.801-7.6992 20l6.6992 6.1016c6.3008-6.8984 10.102-16.102 10.102-26.102-0.003906-10-3.8008-19.199-10.102-26.102z" />
					<path d="m83.5 13.699l-6.6992 6.1016c7.1992 8 11.699 18.602 11.699 30.199 0 11.602-4.3984 22.199-11.699 30.199l6.6992 6.1016c8.6992-9.6016 14-22.301 14-36.301s-5.3008-26.699-14-36.301z" />
				</g>
			</svg>
		</span>
	</Tooltip>
);

export const MessengerOutput = () => (
	<svg width="100px" height="100px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
		<path d="m89.168 45-6.6719-5-11.66 15.555-10.418-13.891h-17.344l6.0742-28.84c0.078124-0.375 0.85156-0.36719 0.85156 0.089843v1.668h8.3359v-1.668c0-4.8242-3.9258-8.75-8.7852-8.75-4.1172 0-7.7148 2.9219-8.5625 6.9492l-6.4297 30.551h-9.5586v8.3359h7.8047l-6.9492 33.008c-0.082031 0.375-0.85547 0.36719-0.85547-0.085937v-1.6719h-8.332v1.6719c0 4.8242 3.9219 8.7422 8.7812 8.7422 4.1133 0 7.7188-2.918 8.5625-6.9453l7.3047-34.719h14.934l8.7461 11.664 0.62891 0.83594-13.129 17.5 6.6719 5.0039 11.668-15.559 11.66 15.559 6.6719-5.0039-13.125-17.5z" />
	</svg>
);

export const SlackOutput = () => (
	<svg width="100px" height="100px" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
		<path d="m89.168 45-6.6719-5-11.66 15.555-10.418-13.891h-17.344l6.0742-28.84c0.078124-0.375 0.85156-0.36719 0.85156 0.089843v1.668h8.3359v-1.668c0-4.8242-3.9258-8.75-8.7852-8.75-4.1172 0-7.7148 2.9219-8.5625 6.9492l-6.4297 30.551h-9.5586v8.3359h7.8047l-6.9492 33.008c-0.082031 0.375-0.85547 0.36719-0.85547-0.085937v-1.6719h-8.332v1.6719c0 4.8242 3.9219 8.7422 8.7812 8.7422 4.1133 0 7.7188-2.918 8.5625-6.9453l7.3047-34.719h14.934l8.7461 11.664 0.62891 0.83594-13.129 17.5 6.6719 5.0039 11.668-15.559 11.66 15.559 6.6719-5.0039-13.125-17.5z" />
	</svg>
);

export const Undo = () => (
	<Tooltip title={<FormattedMessage {...messages.undo} />} placement={'top'}>
		<UndoIcon style={styles.zoom} />
	</Tooltip>
);

export const Redo = () => (
	<Tooltip title={<FormattedMessage {...messages.redo} />} placement={'top'}>
		<RedoIcon style={styles.zoom} />
	</Tooltip>
);

export const CenterCanvas = () => (
	<Tooltip title={<FormattedMessage {...messages.centerCanvas} />} placement={'top'}>
		<CenterFocusStrongIcon style={styles.zoom} />
	</Tooltip>
);

export const ZoomOne = () => (
	<Tooltip title={<FormattedMessage {...messages.zoomOne} />} placement={'right'}>
		<Stop style={styles.zoom} />
	</Tooltip>
);

export const ZoomTwo = () => (
	<Tooltip title={<FormattedMessage {...messages.zoomTwo} />} placement={'right'}>
		<ViewModule style={styles.zoom} />
	</Tooltip>
);

export const ZoomThree = () => (
	<Tooltip title={<FormattedMessage {...messages.zoomThree} />} placement={'right'}>
		<ViewComfy style={styles.zoom} />
	</Tooltip>
);

export const AddNode = ({ ...rest }) => (
	<Tooltip title={<FormattedMessage {...messages.createNewNode} />} placement={'top'} {...rest}>
		<Add data-testid="addNode" style={styles.add} />
	</Tooltip>
);

export const CreateNodeGroup = ({ ...rest }) => (
	<Tooltip title={<FormattedMessage {...messages.createNodeGroup} />} placement={'top'} {...rest}>
		<Add data-testid="createNodeGroup" style={styles.add} />
	</Tooltip>
);

export const ZoomInBtn = () => (
	<Tooltip title={<FormattedMessage {...messages.zoomIn} />} placement={'top'}>
		<ZoomIn style={styles.zoom} />
	</Tooltip>
);

export const ZoomOutBtn = () => (
	<Tooltip title={<FormattedMessage {...messages.zoomOut} />} placement={'top'}>
		<ZoomOut style={styles.zoom} />
	</Tooltip>
);

export const ShowActiveNode = () => (
	<Tooltip title={<FormattedMessage {...messages.showActiveNodeDetails} />} placement={'top'}>
		<SelectIcon style={styles.zoom} />
	</Tooltip>
);

export const DisplayClosestNodesOnly = ({ displayClosestNodesOnly }) => (
	<Tooltip
		title={<FormattedMessage {...messages[displayClosestNodesOnly ? 'showAll' : 'displayClosestNodesOnly']} />}
		placement={'top'}
	>
		<SelectAllIcon style={styles.zoom} />
	</Tooltip>
);

export const CopyNodeOrGroupIcon = () => (
	<Tooltip title={<FormattedMessage {...messages.copyNodeOrGroup} />} placement={'top'}>
		<FileCopyIcon style={styles.zoom} />
	</Tooltip>
);

export const AddGroupNode = ({ ...rest }) => (
	<Tooltip title={<FormattedMessage {...messages.AddGroupNode} />} placement={'top'} {...rest}>
		<AddToGroupIcon style={styles.zoom} {...rest} />
	</Tooltip>
);

export const RemoveNodeGroup = ({ classes, ...rest }) => (
	<Tooltip title={<FormattedMessage {...messages.removeNodeGroupAndNodes} />} placement={'top'} {...rest}>
		<DeleteIcon style={styles.zoom} classes={classes} {...rest} />
	</Tooltip>
);

export const GroupViewMode = ({ ...rest }) => (
	<Tooltip {...rest} title={<FormattedMessage {...messages.groupView} />} placement={'top'}>
		<AccountTreeIcon style={styles.zoom} />
	</Tooltip>
);

export const SearchNodes = () => (
	<Tooltip title={<FormattedMessage {...messages.searchNodes} />} placement={'top'}>
		<SearchIcon style={styles.zoom} />
	</Tooltip>
);

// Icons for sidebar menu list
export const FlowEditorIcon = () => (
	<svg
		width="24px"
		height="24px"
		version="1.1"
		viewBox="0 0 100 100"
		xmlns="http://www.w3.org/2000/svg"
		className="MuiSvgIcon-root makeStyles-itemIcon-17"
	>
		<path d="m94.602 69.102h-7.3008v-18.301c0-1.6562-1.3438-3-3-3h-31.301v-16.902h8.3008c1.6562 0 3-1.3398 3-3v-16.797c0-1.6602-1.3438-3-3-3h-22.602c-1.6562 0-3 1.3398-3 3v16.797c0 1.6602 1.3438 3 3 3h8.3008v16.902h-31.301c-1.6562 0-3 1.3438-3 3v18.301h-7.3008c-1.6328 0.050782-2.9453 1.3633-3 3v16.797c0.054687 1.6367 1.3672 2.9492 3 3h19.602c1.6367-0.050782 2.9492-1.3633 3-3v-16.797c-0.050781-1.6367-1.3633-2.9492-3-3h-6.3008v-15.301h28.301v15.301h-6.8008c-1.6328 0.050782-2.9492 1.3633-3 3v16.797c0.050781 1.6367 1.3672 2.9492 3 3h19.602c1.6328-0.050782 2.9492-1.3633 3-3v-16.797c-0.050781-1.6367-1.3672-2.9492-3-3h-6.8008v-15.301h28.301v15.301h-6.3008c-1.6367 0.050782-2.9492 1.3633-3 3v16.797c0.050781 1.6367 1.3633 2.9492 3 3h19.602c1.6328-0.050782 2.9453-1.3633 3-3v-16.797c-0.054687-1.6367-1.3672-2.9492-3-3z" />
	</svg>
);

export const DataManagementIcon = () => (
	<svg
		width="24px"
		height="24px"
		version="1.1"
		viewBox="0 0 100 100"
		xmlns="http://www.w3.org/2000/svg"
		className="MuiSvgIcon-root makeStyles-itemIcon-17"
	>
		<path d="M6.5 57.006h25.006c3.859 0 7-3.141 7-7V13.995c0-3.86-3.141-7-7-7H6.5c-3.859 0-7 3.14-7 7v36.011c0 3.859 3.141 7 7 7zm0-48.011h25.006c2.757 0 5 2.243 5 5s-2.243 5-5 5H6.5c-2.757 0-5-2.243-5-5s2.243-5 5-5zm0 12h25.006c1.958 0 3.729-.81 5-2.11v4.113c0 2.757-2.243 5-5 5H6.5c-2.757 0-5-2.243-5-5v-4.113a6.975 6.975 0 0 0 5 2.11zm0 9.003h25.006c1.958 0 3.729-.81 5-2.11v4.11a5.007 5.007 0 0 1-5 5.002H6.5c-2.757 0-5-2.244-5-5.002v-4.11a6.975 6.975 0 0 0 5 2.11zM6.5 39h25.006a6.976 6.976 0 0 0 5-2.111v4.115c0 2.757-2.243 5-5 5H6.5c-2.757 0-5-2.243-5-5v-4.115A6.976 6.976 0 0 0 6.5 39zm-5 6.894a6.975 6.975 0 0 0 5 2.11h25.006a6.979 6.979 0 0 0 5-2.11v4.112c0 2.757-2.243 5-5 5H6.5c-2.757 0-5-2.243-5-5v-4.112zM63.5 30h-.002a1 1 0 0 0-1 1v.019A2.002 2.002 0 0 1 60.5 33l-.01.002h-1.996c-2.73 0-4.949-2.202-4.992-4.923 0-.026.004-.051.004-.077a1 1 0 0 0-1-1l-.006.001-.006-.001a1 1 0 0 0-1 1c0 .026.004.051.004.077a5 5 0 0 1-4.985 4.922L46.506 33h-2.008a2.002 2.002 0 0 1-1.998-1.981v-.007l-.002-.008V31a1 1 0 1 0-2 0l.002.019v13.987c0 .005.003.009.003.013.007 6.059 4.937 10.984 10.996 10.985a.999.999 0 0 0 1.997.002h.002c6.065 0 11-4.935 11-11V31.019L64.5 31a1 1 0 0 0-1-1zm-9.971 24.004c-.011 0-.021-.006-.031-.006a1.98 1.98 0 0 0-.995.275 1.987 1.987 0 0 0-1.013-.283c-.019 0-.034.01-.052.011-4.934-.034-8.937-4.054-8.937-8.995l-.002-.01V34.444a3.958 3.958 0 0 0 1.999.556h1.998l.01.002a6.995 6.995 0 0 0 5.994-3.404c1.226 2.035 3.45 3.404 5.994 3.404H60.5c.007 0 .013-.004.02-.004a3.956 3.956 0 0 0 1.979-.554v10.562c-.001 4.952-4.021 8.981-8.97 8.998z" />
		<path d="M52.488 37.197a1 1 0 0 0-1 1v9.791a1 1 0 1 0 2 0v-9.791a1 1 0 0 0-1-1zM52.502 24.996c4.963 0 9-4.038 9-9s-4.037-9-9-9-9 4.038-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.14 7 7s-3.141 7-7 7-7-3.14-7-7 3.141-7 7-7z" />
		<path d="M50.789 18.7a.997.997 0 0 0 1.414 0l4-4.001a.999.999 0 1 0-1.414-1.414l-3.293 3.293-1.289-1.29a.999.999 0 1 0-1.414 1.414l1.996 1.998z" />{' '}
	</svg>
);

export const ConfigEditor = () => (
	<svg
		width="24px"
		height="24px"
		version="1.1"
		viewBox="0 0 100 100"
		xmlns="http://www.w3.org/2000/svg"
		className="MuiSvgIcon-root makeStyles-itemIcon-17"
	>
		<g>
			<path d="m30.578 69.762c-6.918-6.1602-13.859-12.34-20.777-18.523-1-0.87891-2-1.7812-2.9805-2.6602v2.8203c6.9219-6.1797 13.859-12.34 20.781-18.52 1-0.87891 2-1.7812 2.9805-2.6602 1.9219-1.7188-0.92188-4.5391-2.8203-2.8203-6.9219 6.1797-13.859 12.34-20.781 18.52-1 0.87891-2 1.7812-2.9805 2.6602-0.78125 0.69922-0.78125 2.1406 0 2.8203 6.9219 6.1797 13.859 12.34 20.781 18.52 1 0.87891 2 1.7812 2.9805 2.6602 1.918 1.7227 4.7383-1.0977 2.8164-2.8164z" />
			<path d="m72.238 72.602c6.9219-6.1797 13.859-12.34 20.781-18.52 1-0.87891 2-1.7812 2.9805-2.6602 0.78125-0.69922 0.78125-2.1406 0-2.8203-6.9219-6.1797-13.859-12.34-20.781-18.52-1-0.87891-2-1.7812-2.9805-2.6602-1.9219-1.6992-4.7617 1.1211-2.8203 2.8203 6.9219 6.1797 13.859 12.34 20.781 18.52 1 0.87891 2 1.7812 2.9805 2.6602v-2.8203c-6.9219 6.1797-13.859 12.34-20.781 18.52-1 0.87891-2 1.7812-2.9805 2.6602-1.918 1.6992 0.90234 4.5195 2.8203 2.8203z" />
			<path d="m60.102 18.121c-2.7188 7.0781-5.4414 14.16-8.1602 21.238-4.3008 11.219-8.6211 22.441-12.922 33.66-1 2.6016-2 5.1797-2.9805 7.7812-0.37891 1 0.42188 2.1992 1.3984 2.4609 1.1211 0.30078 2.0781-0.37891 2.4609-1.3984 2.7188-7.0781 5.4414-14.16 8.1602-21.238 4.3008-11.219 8.6211-22.441 12.922-33.66 1-2.6016 2-5.1797 2.9805-7.7812 0.37891-1-0.42188-2.1992-1.3984-2.4609-1.1211-0.30078-2.0625 0.39844-2.4609 1.3984z" />
		</g>
	</svg>
);

export const AnalyticsIcon = () => (
	<svg
		width="24px"
		height="24px"
		version="1.1"
		viewBox="0 0 100 100"
		xmlns="http://www.w3.org/2000/svg"
		className="MuiSvgIcon-root makeStyles-itemIcon-17"
	>
		<path d="m5.9922 7.9922c-1.0977 0-1.9844 0.92187-1.9844 2.0234v80.008c0 1.0977 0.88672 1.9844 1.9844 1.9844h88.016c1.0977 0 1.9844-0.88672 1.9844-1.9844 0-1.1328-0.88672-2.0195-1.9844-2.0195h-85.996v-77.988c0-1.1016-0.88672-2.0234-2.0195-2.0234zm66.012 26.008v44.008h8.0078v-44.008zm-36 5.9883v38.02h8.0078v-38.02zm18 6.0234v31.996h8.0078v-31.996zm-36 5.9883v26.008h8.0078v-26.008z" />
	</svg>
);

export const SettingsIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		{settingsSvg}
	</Tooltip>
);

export const SuperadminIcon = ({ ...rest }) => (
	<Tooltip title={USER_ROLES.SUPER_ADMIN} placement={'top'} {...rest}>
		<MilitaryTechIcon />
	</Tooltip>
);

export const AdminIcon = ({ ...rest }) => (
	<Tooltip title={USER_ROLES.ADMIN} placement={'top'} {...rest}>
		<LocalPoliceIcon />
	</Tooltip>
);

export const UserIcon = ({ ...rest }) => (
	<Tooltip title={USER_ROLES.USER} placement={'top'} {...rest}>
		<PersonIcon />
	</Tooltip>
);

export const EndUserIcon = ({ ...rest }) => (
	<Tooltip title={USER_ROLES.END_USER} placement={'top'} {...rest}>
		<SupervisedUserCircleIcon />
	</Tooltip>
);

export const StatusIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		<FactCheckIcon style={styles.clickableMenu} />
	</Tooltip>
);

export const LogoutIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		{logoutSvg}
	</Tooltip>
);

export const BinIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		{binSvg}
	</Tooltip>
);

export const CheckIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		{checkMark}
	</Tooltip>
);

export const CrossIcon = ({ ...rest }) => (
	<Tooltip title="" placement={'top'} {...rest}>
		{crossIcon}
	</Tooltip>
);

DisplayClosestNodesOnly.propTypes = {
	displayClosestNodesOnly: PropTypes.bool,
};

RemoveNodeGroup.propTypes = {
	classes: PropTypes.object,
};
