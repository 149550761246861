import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/auth';
import messages from '../../intl/messages';
import { updateUser, USER_ROLES } from '../../redux/auth/actions';
import { hideModal } from '../../redux/modal/actions';
import LSM from '../../utils/LocalStorageManager';
import { passwordValidation, repeatPasswordValidation } from '../../utils/validation';
import CreateOrganization from '../CreateOrganization';
import CreatePhoneNumber from '../CreatePhoneNumber';
import CreateUser from '../CreateUser';
import EnhancedTextField from '../EnhancedTextField';
import Permissions from '../Permissions';
import UnlockUserAccount from '../UnlockUserAccount';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	formContainer: {
		margin: '5px',
		padding: '10px',
	},
	textField: {
		marginBottom: theme.spacing(3),
	},
	saveButtonContainer: {
		textAlign: 'right',
	},
	accordion: {
		margin: theme.spacing(1),
		overflow: 'hidden', // prevents horizontal scrollbar when panel expanded
		'&.Mui-expanded': {
			marginTop: '1rem !important',
		},
		'& .MuiAccordionSummary-content': {
			paddingLeft: '1rem',
		},
		'& .MuiAccordionDetails-root': {
			padding: '0 2rem',
		},
	},
	sectionTitle: {
		marginTop: '5px',
	},
}));

const UserSettingsModal = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [newPwd, setNewPwd] = useState({});
	const [rePwd, setRePwd] = useState({});
	const { userId, userEmail, role } = useAuth();

	const { HIDDEN, TYPING_HIDDEN } = LSM.CHAT_CONFIGURATION;
	const [chatBubbleHidden, setChatBubbleHidden] = useState(LSM.getChatBubbleConfigProperty(HIDDEN));
	const [chatBubbleTypingIndicatorHidden, setChatBubbleTypingIndicatorHidden] = useState(
		LSM.getChatBubbleConfigProperty(TYPING_HIDDEN)
	);

	const isUserAdmin = role === USER_ROLES.SUPER_ADMIN || role === USER_ROLES.ADMIN;

	const handlePasswordSave = () => {
		const passwordError = passwordValidation(isUserAdmin)(newPwd.value, userEmail);
		const repeatPasswordError = repeatPasswordValidation(newPwd.value, rePwd.value);

		const isPasswordValid = passwordError === null;
		const isRepeatPasswordValid = repeatPasswordError === null;

		setNewPwd({ value: newPwd.value, errorMsg: passwordError, isValid: isPasswordValid });
		setRePwd({ value: rePwd.value, errorMsg: repeatPasswordError, isValid: isRepeatPasswordValid });

		if (!isPasswordValid || !isRepeatPasswordValid) {
			return;
		}
		dispatch(updateUser(userId, newPwd.value));
		dispatch(hideModal());
	};

	const handlePreferencesSave = () => {
		LSM.set(HIDDEN, chatBubbleHidden);
		LSM.set(TYPING_HIDDEN, chatBubbleTypingIndicatorHidden);

		window.bubble.postMessageToIframe({
			type: 'BUILDER_SETTINGS',
			payload: { [TYPING_HIDDEN]: chatBubbleTypingIndicatorHidden },
		});
		// eslint-disable-next-line no-unused-expressions
		chatBubbleHidden ? window.bubble && window.bubble.hide() : window.bubble && window.bubble.show();

		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate title={<FormattedMessage {...messages.userConfiguration} />} isSaveBtnVisible={false}>
			<Grid container>
				<Grid container className={classes.formContainer}>
					<Grid item xs={12}>
						<h3 className={classes.sectionTitle}>
							<FormattedMessage {...messages.changePwd} />
						</h3>
					</Grid>
					<Grid item xs={12}>
						<EnhancedTextField
							className={classes.textField}
							type="password"
							autoComplete="new-password"
							onValidate={setNewPwd}
							errorMsg={newPwd.errorMsg}
							validatePred={passwordValidation(isUserAdmin)}
							extraValidationParams={[userEmail]}
							label={<FormattedMessage {...messages.newPwd} />}
						/>
					</Grid>
					<Grid item xs={12}>
						<EnhancedTextField
							className={classes.textField}
							type="password"
							autoComplete="new-password"
							onValidate={setRePwd}
							errorMsg={rePwd.errorMsg}
							validatePred={repeatPasswordValidation}
							extraValidationParams={[newPwd.value]}
							label={<FormattedMessage {...messages.repeatPwd} />}
						/>
					</Grid>
					<Grid item xs={12} className={classes.saveButtonContainer}>
						<Button
							onClick={handlePasswordSave}
							disabled={!(newPwd.isValid && rePwd.isValid)}
							color="primary"
							variant="contained"
							data-testid="modalConfirmButton"
						>
							<FormattedMessage {...messages.changePwd} />
						</Button>
					</Grid>
				</Grid>
				<Grid container className={classes.formContainer}>
					<Grid item xs={12}>
						<h3 className={classes.sectionTitle}>
							<FormattedMessage {...messages.preferences} />
						</h3>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={chatBubbleHidden}
									onChange={(e) => setChatBubbleHidden(e.target.checked)}
									color="primary"
								/>
							}
							label={<FormattedMessage {...messages.chatBubbleHidden} />}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={chatBubbleTypingIndicatorHidden}
									onChange={(e) => setChatBubbleTypingIndicatorHidden(e.target.checked)}
									color="primary"
								/>
							}
							label={<FormattedMessage {...messages.chatBubbleTypingIndicatorHidden} />}
						/>
					</Grid>
					<Grid item xs={12} className={classes.saveButtonContainer}>
						<Button
							onClick={handlePreferencesSave}
							color="primary"
							variant="contained"
							data-testid="modalConfirmButton"
						>
							<FormattedMessage {...messages.changePreferences} />
						</Button>
					</Grid>
				</Grid>
				<Permissions roles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]}>
					<Grid container className={classes.formContainer}>
						<Grid item xs={12}>
							<h3 className={classes.sectionTitle}>
								<FormattedMessage {...messages.administration} />
							</h3>
						</Grid>
						<Grid item xs={12}>
							<Accordion className={classes.accordion}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<FormattedMessage {...messages.createUser} />
								</AccordionSummary>
								<AccordionDetails>
									<CreateUser />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Grid item xs={12}>
							<Accordion className={classes.accordion}>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<FormattedMessage {...messages.unlockUserAccount} />
								</AccordionSummary>
								<AccordionDetails>
									<UnlockUserAccount />
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Permissions roles={[USER_ROLES.SUPER_ADMIN]}>
							<Grid item xs={12}>
								<Accordion className={classes.accordion}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<FormattedMessage {...messages.createOrganization} />
									</AccordionSummary>
									<AccordionDetails>
										<CreateOrganization />
									</AccordionDetails>
								</Accordion>
							</Grid>
							<Grid item xs={12}>
								<Accordion className={classes.accordion}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<FormattedMessage {...messages.createPhoneNumbers} />
									</AccordionSummary>
									<AccordionDetails>
										<CreatePhoneNumber />
									</AccordionDetails>
								</Accordion>
							</Grid>
						</Permissions>
					</Grid>
				</Permissions>
			</Grid>
		</SaveCloseModalTemplate>
	);
};

export default UserSettingsModal;
