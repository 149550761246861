import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import { deleteModel, deleteModelVersion } from '../../redux/model-table/actions';
import { getModel, isFetchingDeleteModelVersion } from '../../redux/model-table/selectors';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const RemoveModelVersionModal = ({ modelVersion, modelVersionCount }) => {
	const dispatch = useDispatch();
	const isFetching = useSelector(isFetchingDeleteModelVersion);
	const { id: modelVersionId, model: modelId, name: modelVersionName, version } = modelVersion;
	const model = useSelector(getModel(modelId));

	const handleSave = async () => {
		await dispatch(deleteModelVersion(modelVersionId));
		if (modelVersionCount === 1) {
			await dispatch(deleteModel(modelId));
		}
		dispatch(hideModal());
	};

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.deleteModelVersionTitle} />}
			saveBtnTitle={
				<FormattedMessage {...messages[isFetching ? 'deleteModelConfirmInProgress' : 'deleteModelConfirm']} />
			}
			onSave={handleSave}
			saveOnEnterKey
			isSaveBtnDisabled={isFetching}
		>
			<Box>
				<FormattedMessage
					{...messages.deleteModelVersionQuestion}
					values={{ name: modelVersionName, version, strong: (msg) => <strong>{msg}</strong> }}
				/>
			</Box>
			{modelVersionCount === 1 && (
				<Box>
					<FormattedMessage
						{...messages.deleteModelQuestion}
						values={{ model: model?.name, strong: (msg) => <strong>{msg}</strong> }}
					/>
				</Box>
			)}
		</SaveCloseModalTemplate>
	);
};

RemoveModelVersionModal.propTypes = {
	modelVersion: PropTypes.object.isRequired,
	modelVersionCount: PropTypes.number.isRequired,
};

export default RemoveModelVersionModal;
