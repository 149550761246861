import * as requests from '../../core/requests';
import { getHistogram } from '../../graphql/statistics';
import { createActionsMap } from '../../utils/action-utils';
import { psf } from '../utils';

export const ID = 'histogram';

export const actionTypes = createActionsMap(ID, [...psf('FETCH_HISTOGRAM')]);

export const fetchHistogram = (range, agg) => (dispatch) => {
	return dispatch(
		requests.qlAuthRequest(
			[actionTypes.FETCH_HISTOGRAM_PENDING, actionTypes.FETCH_HISTOGRAM_SUCCESS, actionTypes.FETCH_HISTOGRAM_FAIL],
			{ query: getHistogram, variables: { range: range, in: agg } }
		)
	);
};
