import {
	Chip,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { last } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../intl/messages';
import { MESSAGE_FIELD as FIELD, validateCampaignField } from '../Modals/CreateEditCopyCampaignModal';

const useStyles = makeStyles((theme) => ({
	textField: {
		'& input': {
			height: '19px',
		},
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
	tabs: {
		overflow: 'unset',
	},
	indicator: {
		background: theme.palette.primary.main,
	},
	customMessageContainer: {
		width: '100%',
		display: 'flex',
	},
	customMessageTextField: {
		marginLeft: '10px',
		transition: 'margin-top 0.7s',
	},
}));

const MESSAGE_PROVIDER = {
	SMS_MANAGER: 'smsmanager',
};

const DEFAULT_TAB_HEIGHT = 48;

const CampaignMessagesForm = ({ state, setState, callStatuses }) => {
	const classes = useStyles();

	const [formFieldErrors, setFormFieldErrors] = useState({
		[FIELD.SERVICE_PROVIDER]: null,
	});

	const [activeCustomMessageStatus, setActiveCustomMessageStatus] = useState('');
	const customMessageFieldOffset = Math.max(
		0,
		state[FIELD.STATUSES_TO_MESSAGE].indexOf(activeCustomMessageStatus) * DEFAULT_TAB_HEIGHT
	);

	const validateAndUpdate = (value, field, messageStatus = activeCustomMessageStatus) => {
		const error = validateCampaignField(value, field);

		setFormFieldErrors({ ...formFieldErrors, [field]: error });

		if (field === FIELD.CUSTOM_MESSAGES) {
			value = { ...state[FIELD.CUSTOM_MESSAGES], [messageStatus]: value };
		}

		setState({ ...state, [field]: value });
	};

	const handleStatusesToMessageChange = (value) => {
		validateAndUpdate(value, FIELD.STATUSES_TO_MESSAGE);

		if (value.length) {
			setActiveCustomMessageStatus(last(value));
		} else {
			setActiveCustomMessageStatus('');
		}
	};
	return (
		<>
			<Grid item xs={6}>
				<FormControl fullWidth>
					<InputLabel id="messagesProvider">
						<FormattedMessage {...messages.messagesProvider} />
					</InputLabel>
					<Select
						labelId="messagesProvider"
						value={state[FIELD.SERVICE_PROVIDER] || ''}
						error={Boolean(formFieldErrors[FIELD.SERVICE_PROVIDER])}
						helperText={formFieldErrors[FIELD.SERVICE_PROVIDER]}
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.SERVICE_PROVIDER)}
					>
						{Object.values(MESSAGE_PROVIDER).map((provider) => (
							<MenuItem key={provider} value={provider || ''}>
								{provider}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{formFieldErrors[FIELD.SERVICE_PROVIDER]}</FormHelperText>
				</FormControl>
			</Grid>
			{state[FIELD.SERVICE_PROVIDER] === MESSAGE_PROVIDER.SMS_MANAGER && (
				<Grid item xs={6}>
					<TextField
						value={state[FIELD.REQUEST_KEY] || ''}
						error={Boolean(formFieldErrors[FIELD.REQUEST_KEY])}
						helperText={formFieldErrors[FIELD.REQUEST_KEY]}
						fullWidth
						onChange={(e) => validateAndUpdate(e.target.value, FIELD.REQUEST_KEY)}
						label={<FormattedMessage {...messages.requestKey} />}
						variant="standard"
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<TextField
					value={state[FIELD.DEFAULT_MESSAGE] || ''}
					error={Boolean(formFieldErrors[FIELD.DEFAULT_MESSAGE])}
					helperText={formFieldErrors[FIELD.DEFAULT_MESSAGE]}
					fullWidth
					multiline
					onChange={(e) => validateAndUpdate(e.target.value, FIELD.DEFAULT_MESSAGE)}
					label={<FormattedMessage {...messages.defaultMessage} />}
					variant="standard"
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth>
					<InputLabel id="statusesToMessage">
						<FormattedMessage {...messages.statusesToMessage} />
					</InputLabel>
					<Select
						labelId="statusesToMessage"
						value={state[FIELD.STATUSES_TO_MESSAGE]}
						multiple
						error={Boolean(formFieldErrors[FIELD.STATUSES_TO_MESSAGE])}
						helperText={formFieldErrors[FIELD.STATUSES_TO_MESSAGE]}
						onChange={(e) => handleStatusesToMessageChange(e.target.value)}
						renderValue={(selected) => (
							<div className={classes.chips}>
								{selected.map((value) => (
									<Chip key={value} label={value} className={classes.chip} />
								))}
							</div>
						)}
					>
						{callStatuses.map((callStatus) => (
							<MenuItem key={callStatus} value={callStatus || ''}>
								{callStatus}
							</MenuItem>
						))}
					</Select>
					<FormHelperText>{formFieldErrors[FIELD.STATUSES_TO_MESSAGE]}</FormHelperText>
				</FormControl>
			</Grid>
			<div className={classes.customMessageContainer}>
				<Tabs
					classes={{ root: classes.tabs, indicator: classes.indicator }}
					value={activeCustomMessageStatus}
					onChange={(event, newValue) => setActiveCustomMessageStatus(newValue)}
					orientation="vertical"
				>
					{state[FIELD.STATUSES_TO_MESSAGE].map((status, index) => (
						<Tab classes={{ root: classes.tab }} key={index} value={status} label={status} />
					))}
				</Tabs>
				{!!activeCustomMessageStatus && (
					<Grid item xs={12}>
						<TextField
							className={classes.customMessageTextField}
							style={{ marginTop: customMessageFieldOffset }}
							value={state[FIELD.CUSTOM_MESSAGES][activeCustomMessageStatus] || ''}
							fullWidth
							multiline
							onChange={(e) => validateAndUpdate(e.target.value, FIELD.CUSTOM_MESSAGES)}
							label={<FormattedMessage {...messages.customMessages} />}
							variant="standard"
						/>
					</Grid>
				)}
			</div>
		</>
	);
};

CampaignMessagesForm.propTypes = {
	state: PropTypes.object,
	setState: PropTypes.func,
	callStatuses: PropTypes.arrayOf(PropTypes.string),
};

export default CampaignMessagesForm;
