import { Button, Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { notificationTypes } from '../constants';
import { INTL, messages } from '../intl';
import { createOrganization, fetchOrganizations } from '../redux/auth/actions';
import { getOrganizations } from '../redux/auth/selectors';
import { isFetchingOrganizations as isFetchingOrganizationsSel } from '../redux/fetching/selectors';
import { showNotification } from '../redux/notification/actions';
import { validateIsDuplicate, isEmptyValidation } from '../utils/validation';

const useStyles = makeStyles((theme) => ({
	// Not used at the moment.
	marginSpacing: {
		margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	wrapper: {
		padding: 10,
		width: '100%',
	},
}));

const CreateOrganization = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const organizations = useSelector(getOrganizations).map((org) => org.name) || [];
	const isFetchingOrganizations = useSelector(isFetchingOrganizationsSel);
	const [name, setName] = useState('');
	const [errorMsg, setErrorMsg] = useState(null);

	const handleOnChange = (e) => {
		const val = e.target.value;
		setErrorMsg(validateIsDuplicate(val, organizations) || isEmptyValidation(val));
		setName(val);
	};

	useEffect(() => {
		dispatch(fetchOrganizations());
	}, []);

	const handleCreateOrg = async () => {
		const res = await dispatch(createOrganization(name));
		const created = res?.payload?.data?.createOrganization?.name ?? null;

		if (created) {
			dispatch(
				showNotification(INTL.formatMessage(messages.organizationCreated, { name: created }), notificationTypes.INFO)
			);
			setName('');
			dispatch(fetchOrganizations());
		}
	};

	return (
		<div className={classes.wrapper}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<TextField
						variant="standard"
						value={name}
						error={!!errorMsg}
						helperText={errorMsg}
						fullWidth
						onChange={handleOnChange}
						label={<FormattedMessage {...messages.name} />}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						style={{ float: 'right' }}
						onClick={handleCreateOrg}
						color={'primary'}
						disabled={!!errorMsg || isFetchingOrganizations || name === ''}
						className="action-button"
					>
						<FormattedMessage {...messages.createOrganization} />
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default CreateOrganization;
