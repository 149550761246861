import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import messages from '../../intl/messages';
import { fetchHealths } from '../../redux/model-table/actions';
import { getAppsHealth } from '../../redux/model-table/selectors';
import { CheckIcon, CrossIcon } from '../IconsWithTooltip';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const styles = (theme) => ({
	healthTitle: {
		fontSize: '1.125rem',
		fontFamily: 'GilroyBold',
		'@media screen and (max-width: 1200px)': {
			fontSize: '1.3rem',
		},
	},
	unhealthyTitle: {
		fontSize: '1.125rem',
		fontFamily: 'GilroyBold',
		'@media screen and (max-width: 1200px)': {
			fontSize: '1.3rem',
		},
		color: '#F44336',
	},
	builderTitle: {
		fontSize: '1.125rem',
		fontFamily: 'GilroyBold',
		'@media screen and (max-width: 1200px)': {
			fontSize: '1.3rem',
		},
	},
	builderVersion: {
		fontSize: '1.125rem',
		'@media screen and (max-width: 1200px)': {
			fontSize: '1.3rem',
		},
	},
});

const useStyles = makeStyles(styles);

const AppsStatusModal = () => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const appHealths = useSelector(getAppsHealth);
	const classes = useStyles();
	const appVersion = process.env.APP_VERSION;
	const allAppHealths = { ...appHealths, Builder: { status: 'up', version: appVersion } };

	useEffect(() => {
		dispatch(fetchHealths());
	}, []);

	const sortAscByIntl = (arr) =>
		arr.sort((a, b) => {
			const A = intl.formatMessage(messages[a]);
			const B = intl.formatMessage(messages[b]);
			return A < B ? -1 : A > B ? 1 : 0;
		});

	return (
		<SaveCloseModalTemplate title={<FormattedMessage {...messages.statusSectionTitle} />} isSaveBtnVisible={false}>
			<List>
				{sortAscByIntl(Object.keys(allAppHealths))
					.filter((appName) => allAppHealths[appName].status !== '400')
					.map((appName) => {
						const isHealthy = allAppHealths[appName].status !== '500';

						return (
							<div key={appName}>
								<ListItem disablePadding>
									<ListItemIcon>{isHealthy ? <CheckIcon /> : <CrossIcon />}</ListItemIcon>
									<ListItemText
										primary={
											<Typography
												className={clsx({
													[classes.healthTitle]: isHealthy,
													[classes.unhealthyTitle]: !isHealthy,
												})}
											>
												<strong>
													<FormattedMessage {...messages[appName]} />:{' '}
													<FormattedMessage {...messages[isHealthy ? 'upAndRunning' : 'notResponding']} />
												</strong>
											</Typography>
										}
										secondary={
											isHealthy ? <Typography>{allAppHealths[appName]?.version ?? undefined}</Typography> : undefined
										}
									/>
								</ListItem>
							</div>
						);
					})}
			</List>
		</SaveCloseModalTemplate>
	);
};

export default AppsStatusModal;
