/**
 * Converts a `Date` to string accepted by <input type="datetime-local" />
 * See https://stackoverflow.com/a/66558369/2988107
 */
export function convertDateToString(date) {
	if (!date || isNaN(date.getTime())) {
		return '';
	}

	const utc = new Date(date.getTime() - date.getTimezoneOffset() * 60_000);
	utc.setMilliseconds(0); // https://dev.azure.com/borndigitalai/Born%20Digital/_workitems/edit/2158/
	utc.setSeconds(0);
	return utc.toISOString().replace(/Z$/, '');
}

export const convertStringToDate = (string) => new Date(string);
