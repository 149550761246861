import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useAuth from '../../../hooks/auth';
import messages from '../../../intl/messages';
import SidebarModelBox from '../../SidebarModelBox/SidebarModelBox';
import styles from '../assets/jss/material-dashboard-react/components/sidebarStyle';
import SidebarSection from './SidebarSection';

const useStyles = makeStyles(styles);

export default function Sidebar({ routes }) {
	const classes = useStyles();
	const { isUserLoggedIn } = useAuth();

	return (
		<Hidden smDown implementation="css">
			<Drawer anchor={'left'} variant="permanent" open classes={{ paper: clsx(classes.drawerPaper) }}>
				<div className={classes.sidebarWrapper}>
					<SidebarSection sectionValue={1} routes={routes} />
					<SidebarSection title={<FormattedMessage {...messages.designTools} />} sectionValue={2} routes={routes} />
					<SidebarSection title={<FormattedMessage {...messages.testingTools} />} sectionValue={3} routes={routes} />
					{(process.env.KIBANA_URL ||
						process.env.KIBANA_BUSN_DASHBOARD_URL ||
						process.env.KIBANA_TECH_DASHBOARD_URL) && (
						<SidebarSection
							title={<FormattedMessage {...messages.analyticsTools} />}
							sectionValue={4}
							routes={routes}
						/>
					)}
					<SidebarSection
						title={<FormattedMessage {...messages.administrationTools} />}
						sectionValue={5}
						routes={routes}
					/>
				</div>
				{isUserLoggedIn && <SidebarModelBox />}
			</Drawer>
		</Hidden>
	);
}

Sidebar.propTypes = {
	routes: PropTypes.arrayOf(PropTypes.object),
};
