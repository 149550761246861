import {
	always,
	compose,
	find,
	has,
	head,
	identity,
	ifElse,
	is,
	path,
	values,
	flatten,
	join,
	map,
	filter,
} from 'ramda';
import { INTL, messages } from '../../intl';
import { parseImageMarkdown } from '../../redux/model/utils';
import isNilOrEmpty from '../../utils/isNilOrEmpty';
import { RESPONSE_TYPES } from '../configs';

const headOrArg = ifElse(is(Array), head, identity);

const defaultTo = ifElse(
	isNilOrEmpty,
	always(INTL.formatMessage(messages.notFound, { value: RESPONSE_TYPES.MARKDOWN })),
	identity
);

export const getMarkdowns = compose(
	join(' | '),
	map((md) => parseImageMarkdown(md)?.groups?.fileName ?? md),
	flatten,
	map((response) => response[RESPONSE_TYPES.MARKDOWN]),
	filter((value) => has(RESPONSE_TYPES.MARKDOWN, value)),
	values
);

export const getFirstMarkdown = compose(
	headOrArg,
	defaultTo,
	path([RESPONSE_TYPES.MARKDOWN]),
	find(has(RESPONSE_TYPES.MARKDOWN)),
	values
);
