import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../assets/jss/material-dashboard-react/components/sidebarStyle';

const useStyles = makeStyles(styles);

const SidebarSection = ({ title, routes, sectionValue }) => {
	const classes = useStyles();
	let is1stListItem = -1;

	const sectionRoutes = routes.filter(({ showInMenu, section }) => showInMenu && section === sectionValue);

	return (
		<div>
			{title && sectionRoutes.length ? <h2 className={classes.title}>{title}</h2> : null}
			<List className={classes.list}>
				{sectionRoutes.map((prop, key) => {
					is1stListItem++;

					return (
						<NavLink to={prop.path} className={`${classes.item} ${!is1stListItem ? 'firstItem' : ''}`} key={key}>
							<ListItem button className={classes.itemLink}>
								{typeof prop.icon === 'string' ? <Icon>{prop.icon}</Icon> : <prop.icon />}
								<ListItemText
									primary={routes.rtlActive ? prop.rtlName : prop.name}
									className={classes.itemText}
									disableTypography
								/>
							</ListItem>
						</NavLink>
					);
				})}
			</List>
		</div>
	);
};

SidebarSection.propTypes = {
	title: PropTypes.object,
	routes: PropTypes.array,
	sectionValue: PropTypes.number,
	color: PropTypes.string,
	filter: PropTypes.func,
	rtlActive: PropTypes.bool,
};

export default SidebarSection;
