import { isNil } from 'ramda';
import { RSAA } from 'redux-api-middleware';
import keycloak from '../auth/keycloak';
import { USE_KEYCLOAK } from '../core/configs/consts';
import { AUTH_CALL_API } from '../core/requests';
import { INTL, messages } from '../intl/index';
import { getToken } from '../redux/auth/selectors';

/**
 * Middleware adds Authorization header to authorized request.
 */
export default (store) => (next) => (action) => {
	const rsaaAction = action?.[AUTH_CALL_API];
	if (!rsaaAction) {
		return next(action);
	}

	const state = store.getState();
	let token;

	if (USE_KEYCLOAK) {
		token = keycloak.token;
	} else {
		token = getToken(state);
	}

	// Forward error action if needed.
	if (isNil(token)) {
		throw new Error(
			INTL.formatMessage(messages.missingToken, { method: rsaaAction.method, endpoint: rsaaAction.endpoint })
		);
	}

	return next({
		[RSAA]: {
			...rsaaAction,
			headers: {
				...rsaaAction.headers,
				Authorization: `Bearer ${token}`,
			},
		},
	});
};
