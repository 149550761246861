import { produce } from 'immer';
import { actionTypes as authActionTypes } from '../auth/actions';
import { actionTypes } from './actions';

const DEFAULT_STATE = {
	phoneCalls: [],
	sipAccounts: [],
};

export default produce((state, action) => {
	const { type, payload } = action;

	switch (type) {
		case authActionTypes.LOGOUT_SUCCESS:
			return DEFAULT_STATE;
		case actionTypes.FETCH_SIP_ACCOUNTS_SUCCESS: {
			state.sipAccounts = payload.data.sipAccounts;
			break;
		}
		case actionTypes.CREATE_SIP_ACCOUNT_SUCCESS: {
			state.sipAccounts = payload.data.createSipAccount;
			break;
		}
		case actionTypes.UPDATE_SIP_ACCOUNT_SUCCESS: {
			state.sipAccounts = payload.data.updateSipAccount;
			break;
		}
		case actionTypes.DELETE_SIP_ACCOUNT_SUCCESS: {
			state.sipAccounts = payload.data.deleteSipAccount;
			break;
		}
		case actionTypes.FETCH_SIP_ACCOUNTS_FOR_MODEL_SUCCESS: {
			state.sipAccounts = payload.data.sipAccountsForModel;
			break;
		}
		case actionTypes.FETCH_PHONE_CALLS_SUCCESS:
			state.phoneCalls = payload.data.phoneCalls ?? [];
			break;
	}
}, DEFAULT_STATE);
