import { MODAL_TYPES } from '../../constants/modals';
import { createActionsMap, makeActionCreator } from '../../utils/action-utils';

export const ID = 'modal';

export const actionTypes = createActionsMap(ID, ['SHOW_MODAL', 'HIDE_MODAL']);

export const showModal = makeActionCreator(actionTypes.SHOW_MODAL, 'modalType', 'modalProps');
export const hideModal = makeActionCreator(actionTypes.HIDE_MODAL);

export const showCreateUpdateSipAccountModal = (params) => showModal(MODAL_TYPES.CREATE_UPDATE_SIP_ACCOUNT, params);
export const showDeleteModal = (params) => showModal(MODAL_TYPES.DELETE_MODAL, params);
export const showRemoveIntentModal = (intentId) => showModal(MODAL_TYPES.REMOVE_INTENT, { intentId });
export const showAddEditIntentModal = (id) => showModal(MODAL_TYPES.ADD_EDIT_INTENT, { id });
export const showRemoveModelModal = (id, name) => showModal(MODAL_TYPES.REMOVE_MODEL, { id, name });
export const showRemoveModelVersionModal = (modelVersion, modelVersionCount) =>
	showModal(MODAL_TYPES.REMOVE_MODEL_VERSION, {
		modelVersion,
		modelVersionCount,
	});
export const showRemoveModelVersionsModal = (modelVersions, removeModelId, callback) =>
	showModal(MODAL_TYPES.REMOVE_MODEL_VERSIONS, {
		modelVersions,
		removeModelId,
		callback,
	});
export const showRemoveUtteranceModal = (intentId, utteranceId) =>
	showModal(MODAL_TYPES.REMOVE_UTTERANCE, { intentId, utteranceId });
export const showActiveNodeModal = (callback) => showModal(MODAL_TYPES.SET_ACTIVE_NODE, { callback });
export const showAddEditGotoModal = (nodeId, currGoToNodeId, currGoToType, reuseUtteranceVariable) =>
	showModal(MODAL_TYPES.ADD_EDIT_GOTO, { nodeId, currGoToNodeId, currGoToType, reuseUtteranceVariable });
export const showAddEditSignalModal = (nodeId, currTargetNode, currSignalType) =>
	showModal(MODAL_TYPES.ADD_EDIT_SIGNAL, { nodeId, currTargetNode, currSignalType });
export const showCreateEditTransitionModal = (nodeId, targetNodeName = '', intentName = '', isCallback) =>
	showModal(MODAL_TYPES.CREATE_EDIT_TRANSITION, { nodeId, targetNodeName, intentName, isCallback });
export const showAddIntentFromLibModal = (props) => showModal(MODAL_TYPES.ADD_INTENT_FROM_LIB, props);
export const showCreateNewModelModal = () => showModal(MODAL_TYPES.CREATE_NEW_MODEL, {});
export const showRemoveNodeModal = (id) => showModal(MODAL_TYPES.REMOVE_NODE, { id });
export const showRemoveModal = (type, id, removeHandler) =>
	showModal(MODAL_TYPES.GENERIC_REMOVE, { type, id, removeHandler });
export const showAddEditVariableModal = (nodeId, varId, varName, varValue) =>
	showModal(MODAL_TYPES.ADD_VARIABLE, { nodeId, varId, varName, varValue });
export const showAddMarkdownOptionModal = (nodeId, initMarkdownOption = '') =>
	showModal(MODAL_TYPES.ADD_MARKDOWN_OPTION, { nodeId, initMarkdownOption });
export const showAddWidgetModal = (nodeId) => showModal(MODAL_TYPES.ADD_WIDGET, { nodeId });
export const showInsertVariableModal = (handleInsertVariable) =>
	showModal(MODAL_TYPES.INSERT_VARIABLE, { handleInsertVariable });
export const showRenameVariableModal = () => showModal(MODAL_TYPES.RENAME_VARIABLE);
export const showColorCaptionsModal = () => showModal(MODAL_TYPES.COLOR_CAPTIONS);
export const showVocabularyModal = () => showModal(MODAL_TYPES.VOCABULARY);
export const showYamlErrorsModal = (showLine) => showModal(MODAL_TYPES.YAML_ERRORS, { showLine });
export const showAddEditConditionModal = (nodeId, condId, condValue, condTargetNodeId) =>
	showModal(MODAL_TYPES.ADD_EDIT_CONDITION, { nodeId, condId, condValue, condTargetNodeId });
export const showAddEditSmartFunctionModal = (nodeId, propertyType, funcId, existingFunc) =>
	showModal(MODAL_TYPES.ADD_EDIT_SMART_FUNCTION, { nodeId, propertyType, funcId, existingFunc });
export const showResetVariablesModal = (nodeId, prevVariablesToReset) =>
	showModal(MODAL_TYPES.RESET_VARIABLES, { nodeId, prevVariablesToReset });
export const showAddUserRightsModal = (role, modelId, modelName, creator) =>
	showModal(MODAL_TYPES.ADD_USER_RIGHTS, { role, modelId, modelName, creator });
export const showCampaignPhoneCallFilterModal = (setPhoneCallFilter, phoneCallFilter) =>
	showModal(MODAL_TYPES.CAMPAIGN_PHONE_CALL_FILTER_MODAL, { setPhoneCallFilter, phoneCallFilter });
export const showUpdateModelAndVersionModal = (id, currDataStr, modalUpdateType) =>
	showModal(MODAL_TYPES.UPDATE_MODEL_VERSION_DESC, { id, currDataStr, modalUpdateType });
export const showSttBoostModal = (nodeId, boostEntry, indexInBoostEntries) =>
	showModal(MODAL_TYPES.STT_BOOST, { nodeId, boostEntry, indexInBoostEntries });
export const showUserSettingsModal = () => showModal(MODAL_TYPES.USER_SETTINGS, {});
export const showAppsStatusModal = () => showModal(MODAL_TYPES.STATUS_SECTION, {});
export const showModelAssetsModal = () => showModal(MODAL_TYPES.MODEL_ASSETS);
export const showModelAnnouncementsModal = () => showModal(MODAL_TYPES.MODEL_ANNOUNCEMENTS);
export const showVersionConfigModal = () => showModal(MODAL_TYPES.VERSION_CONFIG);
export const showCreateNodeGroupModal = () => showModal(MODAL_TYPES.CREATE_NODE_GROUP, {});
export const showDiffYamlModal = () => showModal(MODAL_TYPES.DIFF_YAML, {});
export const showAddMetadataModal = () => showModal(MODAL_TYPES.ADD_METADATA, {});
export const showAddColumnModal = (tableName) => showModal(MODAL_TYPES.ADD_COLUMN, { tableName });
export const showDeleteMetadataModal = (id, name) => showModal(MODAL_TYPES.DELETE_METADATA, { id, name });
export const showUnsavedDataEditorChangesModal = (tableName, setEditable) =>
	showModal(MODAL_TYPES.UNSAVED_DATA_EDITOR_CHANGES_MODAL, { tableName, setEditable });
export const showDeleteColumnModal = (tableName, columnName) =>
	showModal(MODAL_TYPES.DELETE_COLUMN, { tableName, columnName });
export const showEditNodeGroupModal = (groupId) => showModal(MODAL_TYPES.EDIT_NODE_GROUP, { groupId });
export const showAddRemoveGroupNodeModal = (selectedNodes) =>
	showModal(MODAL_TYPES.ADD_REMOVE_GROUP_NODE, { selectedNodes });
export const showSaveModelVersionModal = () => showModal(MODAL_TYPES.SAVE_MODEL_VERSION, {});
export const showCreateEditCopyCampaignModal = (props) => showModal(MODAL_TYPES.CREATE_EDIT_COPY_CAMPAIGN, props);
export const showAddNewNumberModal = (props) => showModal(MODAL_TYPES.ADD_NEW_NUMBER, props);
export const showDeleteCampaignsModal = (list) => showModal(MODAL_TYPES.REMOVE_CAMPAIGNS, { list });
export const showPhoneCallTranscriptionModal = (conversationId, isOpenedFromExternal) =>
	showModal(MODAL_TYPES.PHONE_CALL_TRANSCRIPTION, { conversationId, isOpenedFromExternal });
export const showPhoneCallRecordingModal = (conversationId, isOpenedFromExternal) =>
	showModal(MODAL_TYPES.PHONE_CALL_RECORDING, { conversationId, isOpenedFromExternal });
export const showDownloadCampRecsModal = (campaign, isSip) =>
	showModal(MODAL_TYPES.DOWNLOAD_CAMP_RECS, { campaign, isSip });
export const showEmailClassifierReplyModal = (email) => showModal(MODAL_TYPES.EMAIL_CLASSIFIER_REPLY_MODAL, { email });
