import Autocomplete from '@mui/lab/Autocomplete';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Switch, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { includes } from 'ramda';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { GOTO_TRANSITION_TYPES, TRANSITION_TYPES } from '../../core/configs';
import messages from '../../intl/messages';
import { hideModal } from '../../redux/modal/actions';
import {
	clearSelectedNodes,
	createNode,
	createUpdateTransitionGoto,
	createUpdateTransitionGotoReuseUtterance,
	registerStateHistory,
} from '../../redux/model/actions';
import { getAllVariables, getAvailableTransitionNodes, getNode, getNodeIds } from '../../redux/model/selectors';
import InfoButton from '../InfoButton/InfoButton';
import NodeIdTextField from '../NodeIdTextField/NodeIdTextField';
import SaveCloseModalTemplate from './SaveCloseModalTemplate';

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: 13,
	},
	formLabel: {
		color: theme.palette.primary.main,
	},
	formField: {
		padding: '5px',
	},
}));

const includesVariable = (value) => /\{\w+\}/.test(value);

const AddEditGotoModal = ({ nodeId, currGoToNodeId, currGoToType, reuseUtteranceVariable }) => {
	const classes = useStyles();

	const [goToType, setGoToType] = useState(currGoToType || GOTO_TRANSITION_TYPES[0]);
	const [isGoToVariable, setIsGoToVariable] = useState(includesVariable(currGoToNodeId));

	const nodeIds = useSelector(getAvailableTransitionNodes(nodeId));
	const allNodes = useSelector(getNodeIds);

	// Just the key of the variable is relevant, not the value
	const variables = uniq(useSelector(getAllVariables).map((variable) => variable[0]));
	const [variable, setVariable] = useState(isGoToVariable && currGoToNodeId ? currGoToNodeId.replace(/[{}]/g, '') : '');
	const [variableInputValue, setVariableInputValue] = useState('');

	const [goToReuseUtteranceVariable, setGoToReuseUtteranceVariable] = useState(reuseUtteranceVariable || '');

	const [targetNodeConf, setTargetNodeConf] = useState({
		validation: null,
		caretPos: null,
		nodeId: isGoToVariable ? '' : currGoToNodeId || '',
	});

	const node = useSelector(getNode(nodeId));
	const dispatch = useDispatch();
	const isNewNode = targetNodeConf.nodeId && !includes(targetNodeConf.nodeId, allNodes);
	const isGoToBack = goToType === TRANSITION_TYPES.GOTO_BACK;
	const isGoToReuseUtterance = goToType === TRANSITION_TYPES.GOTO_REUSE_UTTERANCE;

	const handleGotoTypeChange = (e) => {
		e.persist();
		const previousGoToType = goToType;

		setGoToType(e.target.value);

		if (e.target.value === TRANSITION_TYPES.GOTO_BACK) {
			setTargetNodeConf({
				validation: null,
				caretPos: null,
				nodeId: 1,
			});
		} else if (previousGoToType === TRANSITION_TYPES.GOTO_BACK && previousGoToType !== e.target.value) {
			setTargetNodeConf({
				validation: null,
				caretPos: null,
				nodeId: '',
			});
		}
	};

	const handleSave = () => {
		if (cannotSubmit) {
			return;
		}

		dispatch(registerStateHistory());

		if (isGoToVariable) {
			dispatch(createUpdateTransitionGoto(goToType, nodeId, '', variable));
		} else {
			let targetNodeName = targetNodeConf.nodeId;

			if (isNewNode && !isGoToBack) {
				dispatch(clearSelectedNodes());
				dispatch(createNode({ newNodeId: targetNodeConf.nodeId, shouldShowActiveNode: true, baseNodeId: nodeId }));
				// The nodeId gets prefixed by the respective group name during creation if groupView is on
				if (node.group) {
					targetNodeName = `${node.group}_${targetNodeConf.nodeId}`;
				}
			}

			if (isGoToReuseUtterance) {
				dispatch(createUpdateTransitionGotoReuseUtterance(nodeId, targetNodeName, goToReuseUtteranceVariable));
			} else {
				dispatch(createUpdateTransitionGoto(goToType, nodeId, targetNodeName));
			}
		}
		dispatch(hideModal());
	};

	const btnText = (() => {
		if (isNewNode && !isGoToBack && !isGoToVariable) {
			return 'createNodeAndTransition';
		} else if (currGoToType) {
			return 'updateTransition';
		} else {
			return 'createTransition';
		}
	})();

	const cannotSubmit = !!(
		(isGoToVariable && !variable) ||
		(!isGoToVariable && (targetNodeConf.validation || !targetNodeConf.nodeId || targetNodeConf.nodeId === nodeId))
	);

	return (
		<SaveCloseModalTemplate
			title={<FormattedMessage {...messages.continueFallback} />}
			saveBtnTitle={<FormattedMessage {...messages[btnText]} />}
			onSave={handleSave}
			isSaveBtnDisabled={cannotSubmit}
			maxWidth="md"
		>
			<Grid container>
				<Grid item xs={12} classes={{ root: classes.formField }}>
					<FormControl component="fieldset">
						<FormLabel component="legend" className={classes.formLabel}>
							<FormattedMessage {...messages.type} />:
						</FormLabel>
						<RadioGroup row value={goToType} onChange={handleGotoTypeChange}>
							<Grid container>
								{GOTO_TRANSITION_TYPES.map((_gotoType, index) => (
									<Grid item xs={6} key={_gotoType + index}>
										<FormControlLabel
											classes={{
												label: classes.label,
											}}
											value={_gotoType}
											control={<Radio color="primary" />}
											label={_gotoType}
										/>
										<InfoButton title={'gotoModal' + _gotoType} />
									</Grid>
								))}
							</Grid>
						</RadioGroup>
					</FormControl>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.formField }}>
					<FormControlLabel
						label={<FormattedMessage {...messages.isGoToVariable} />}
						control={
							<Switch checked={isGoToVariable} onChange={(e, value) => setIsGoToVariable(value)} color="primary" />
						}
					/>
				</Grid>
				<Grid item xs={12} classes={{ root: classes.formField }}>
					{isGoToVariable ? (
						<Autocomplete
							options={variables}
							value={variable}
							onChange={(e, v) => setVariable(v)}
							inputValue={variableInputValue}
							onInputChange={(e, v) => setVariableInputValue(v)}
							blurOnSelect
							renderInput={(params) => (
								<TextField
									{...params}
									autoFocus
									label={<FormattedMessage {...messages.selectVariable} />}
									variant="outlined"
								/>
							)}
						/>
					) : isGoToBack ? (
						<TextField
							autoFocus
							variant="outlined"
							value={targetNodeConf.nodeId}
							label={<FormattedMessage {...messages.numberOfNodesToGoBack} />}
							fullWidth
							onChange={(e) =>
								setTargetNodeConf({
									...targetNodeConf,
									validation: null,
									nodeId:
										isNaN(e.target.value) || e.target.value === '' ? (e.target.value === '' ? '' : 1) : e.target.value,
								})
							}
						/>
					) : (
						<Autocomplete
							freeSolo
							blurOnSelect
							options={nodeIds}
							inputValue={targetNodeConf.nodeId}
							getOptionLabel={String}
							renderInput={(params) => (
								<NodeIdTextField
									autoFocus
									nodeId={targetNodeConf.nodeId}
									label={<FormattedMessage {...messages.targetNode} />}
									handleChange={setTargetNodeConf}
									helperText={
										isNewNode && targetNodeConf.nodeId !== nodeId ? (
											<FormattedMessage {...messages.newNodeInfo} values={{ id: targetNodeConf.nodeId }} />
										) : null
									}
									allowDuplicate
									{...params}
								/>
							)}
							// In case a nodeId from the list is selected and the clear icon is clicked
							onChange={(e, nodeId) =>
								setTargetNodeConf({
									caretPos: null,
									validation: null,
									nodeId: nodeId || '',
								})
							}
							// In case a custom nodeId value not on the list is inputted and the clear icon is clicked
							onInputChange={(e, v, reason) => {
								if (reason === 'clear') {
									setTargetNodeConf({
										caretPos: null,
										validation: null,
										nodeId: '',
									});
								}
							}}
						/>
					)}
				</Grid>
				{isGoToReuseUtterance && (
					<Grid item xs={12} classes={{ root: classes.formField }}>
						<TextField
							autoFocus
							variant="outlined"
							value={goToReuseUtteranceVariable}
							label={<FormattedMessage {...messages.goToReuseUtteranceVariable} />}
							fullWidth
							onChange={(e) => setGoToReuseUtteranceVariable(e.target.value)}
						/>
					</Grid>
				)}
			</Grid>
		</SaveCloseModalTemplate>
	);
};

AddEditGotoModal.propTypes = {
	nodeId: PropTypes.string,
	currGoToNodeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	currGoToType: PropTypes.string,
	reuseUtteranceVariable: PropTypes.string,
};

export default AddEditGotoModal;
