import { produce } from 'immer';
import { path } from 'ramda';
import { ASYNC_ACTION_STATES } from '../../utils/action-utils';
import { actionTypes, ID } from './actions';

const DEFAULT_STATE = {
	request: {
		state: ASYNC_ACTION_STATES.READY,
		utterances: [],
		error: undefined,
	},
};

const utteranceTester = produce((state, { type, payload }) => {
	switch (type) {
		case actionTypes.UTTERANCES_STATS_PENDING:
			state.request.state = ASYNC_ACTION_STATES.PENDING;
			state.request.utterances = [];
			state.request.error = undefined;
			break;
		case actionTypes.UTTERANCES_STATS_SUCCESS:
			state.request.state = ASYNC_ACTION_STATES.SUCCESS;
			state.request.utterances = payload.data.analysis;
			state.request.error = undefined;
			break;
		case actionTypes.UTTERANCES_STATS_FAIL:
			state.request.state = ASYNC_ACTION_STATES.FAIL;
			state.request.utterances = [];
			state.request.error = payload;
			break;
	}
}, DEFAULT_STATE);

export const getUtterancesRequest = path([ID, 'request']);
export const areUtterancesProcessing = (state) => getUtterancesRequest(state).state === ASYNC_ACTION_STATES.PENDING;
export const getUtterances = (state) => getUtterancesRequest(state).utterances ?? [];
export const getUtterancesError = (state) => getUtterancesRequest(state).error;

export default utteranceTester;
