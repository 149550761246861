import { createTheme } from '@mui/material/styles';

/**
 * Global mui theme adjustments for the builder.
 * TODO: VS Transfer src\assets\colorPalette.js here and other mui builder styles.
 */

/* const colors = {
	green: palette.primary.main,
}; */

export const palette = {
	primary: {
		main: '#7CBA2D',
	},
	secondary: {
		dark: '#0F1B2A',
		darker: '#09111C',
		main: '#15242F',
		light: '#69788A',
		lighter: '#AAB9C9',
	},
	// for consistency with regard to colour options in Bubble
	utteranceButton: {
		primary: '#575757',
		secondary: '#15242f',
		success: '#2e7d32',
		danger: '#e53e3e',
		warning: '#ed6c02',
		info: '#0288d1',
		light: '#69788a',
		dark: '#0f1b2a',
		link: '#aab9c9',
	},
};

export default createTheme({
	palette,
	components: {
		MuiDataGrid: {
			styleOverrides: {
				root: {
					padding: '1.5rem !important',
					background: 'white',
					'& .MuiDataGrid-toolbarContainer': {
						marginBottom: '0.5rem',
						'& .MuiButton-root': {
							color: palette.primary.main + ' !important',
							marginRight: '1rem',
						},
					},
					'& .MuiDataGrid-columnHeadersInner': {
						background: palette.primary.main + ' !important',
						color: 'white',
						'& .MuiSvgIcon-root': {
							fill: 'white',
						},
					},
					'& .MuiDataGrid-columnSeparator': {
						display: 'none',
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: '#75808C',
					width: '100%',
					'& .MuiInputLabel-outlined': {
						fontSize: 14,
					},
					'& label.Mui-focused': {
						color: palette.primary.main,
					},
					'& .MuiOutlinedInput-root': {
						borderRadius: 0,
						'& fieldset': {
							borderColor: '#dedede',
						},
						'&:hover fieldset': {
							borderColor: '#8b8b8b',
						},
						'&.Mui-focused fieldset': {
							borderColor: palette.primary.main,
						},
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					'& label.Mui-focused': {
						color: palette.primary.main,
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#75808C',
					fontSize: '1rem',
					fontWeight: 600,
					fontFamily: 'Gilroy',
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					backgroundColor: '#607d8b',
					margin: '0 5px',
					height: 20,
				},
				clickable: {
					'&:hover': {
						backgroundColor: palette.primary.main,
					},
				},
				label: {
					color: 'white',
					fontSize: 10,
					paddingLeft: 5,
					paddingRight: 5,
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					height: '100%',
					padding: '0.5rem 1rem',
					borderRadius: 0,
					boxShadow: '4px 8px 25px rgba(160, 168, 176, 0.6) !important',
					margin: '0.15rem',
					'@media screen and (max-width: 1200px)': {
						padding: '1rem 2rem',
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					width: '100%',
					color: '#75808C',
					fontSize: '1rem',
					fontWeight: 600,
					fontFamily: 'Gilroy',
				},
				underline: {
					width: '100%',
					'&:hover': {
						'&:not(.Mui-disabled)': {
							'&:before': { borderBottom: '1px solid #8b8b8b' },
						},
					},
					'&:after': {
						borderBottom: '2px solid ' + palette.primary.main,
					},
					'&:before': {
						borderBottom: '1px solid #dedede',
					},
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					width: '100%',
				},
				icon: {
					color: '#555',
					right: -5,
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					color: palette.primary.main,
					boxSizing: 'border-box',
				},
				marked: {
					marginBottom: '0!important',
				},
				markLabelActive: {
					left: '0!important',
					fontSize: '10px',
					color: 'rgba(0,0,0,0.54)',
				},
				markLabel: {
					left: '100%!important',
					fontSize: '10px',
					color: 'rgba(0,0,0,0.54)',
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					background: '#DEE4E9',
					transition: 'ease-in-out .15s',
					boxShadow: 'none !important',
					'&.Mui-expanded': {
						borderLeft: '3px solid ' + palette.primary.main,
						margin: '0 0 5px 0',
						'&::before': {
							content: 'none',
						},
					},
					'&::before': {
						content: 'none',
					},
					'&::after': {
						content: '" "',
						position: 'absolute',
						top: 0,
						width: '100%',
						height: '100%',
						transition: 'all 0.8s ease',
						opacity: 0,
						backgroundColor: '#DEE4E9',
						left: 0,
						zIndex: '0',
						transform: 'scaleX(0)',
					},
					'&:hover': {
						cursor: 'pointer',
						'&::after': {
							opacity: 1,
							transform: 'scaleX(1)',
						},
					},
				},
				rounded: {
					marginBottom: 10,
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					padding: 0,
					minHeight: 'auto!important',
					zIndex: '9',
					'&.Mui-expanded': {
						backgroundColor: '#DEE4E9',
					},
				},
				content: {
					fontWeight: '500',
					display: 'flex',
					alignItems: 'center',
					flexWrap: 'wrap',
					borderRight: '1px solid white',
					padding: 0,
					margin: 0,
					'&.Mui-expanded': {
						margin: 0,
					},
				},
				expandIconWrapper: {
					padding: '1rem !important',
					marginRight: '0 !important',
				},
			},
		},
		MuiCollapse: {
			styleOverrides: {
				root: {
					zIndex: '99',
					position: 'relative',
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: 0,
					background: '#DEE4E9',
					flexDirection: 'column',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: 5,
					color: '#555',
				},
				sizeSmall: {
					padding: 5,
					fontSize: 11,
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: '#f3f3f3',
						'&:hover': {
							backgroundColor: '#f3f3f3',
						},
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: 'white',
					fontFamily: 'PuristaBold',
				},
				contained: {
					color: 'white',
					backgroundColor: '#607d8b',
					'&:hover': {
						backgroundColor: '#475a63',
					},
					'&:disabled': {
						backgroundColor: '#607d8b!important',
						cursor: 'not-allowed',
						pointerEvents: 'auto',
					},
				},
				containedPrimary: {
					color: 'white',
					backgroundColor: palette.primary.main,
					'&:hover': {
						backgroundColor: '#6da139',
					},
					'&:disabled': {
						backgroundColor: palette.primary.main + ' !important',
						cursor: 'not-allowed',
						pointerEvents: 'auto',
					},
				},
				containedSecondary: {
					color: 'white',
					backgroundColor: '#ff9800',
					'&:hover': {
						backgroundColor: '#d78000',
					},
					'&:disabled': {
						backgroundColor: '#ff9800!important',
						cursor: 'not-allowed',
						pointerEvents: 'auto',
					},
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					backgroundColor: '#e2c97a!important',
				},
				bar: {
					backgroundColor: '#c5ae65!important',
				},
			},
		},
	},
});
