import { isEmpty, path } from 'ramda';

export const ID = 'confusionMatrix';

export const getConfusionMatrixGeneratingPhase = (state) => state[ID].request.state;
export const getConfusionMatrixError = (state) => state[ID].request.response?.response?.error;
export const getConfusionMatrixData = path([ID, 'matrix']);
export const getConfusionMatrixDataWithAverages = (state) => {
	let { intents, stats, rows } = getConfusionMatrixData(state);

	if (!isEmpty(intents)) {
		const individualScoreAverage =
			stats.reduce((accumulator, { accuracy }) => accumulator + accuracy, 0) / stats.length;
		const groupScoreAverage =
			// eslint-disable-next-line camelcase
			stats.reduce((accumulator, { group_accuracy }) => accumulator + group_accuracy, 0) / stats.length;

		// The arrays are not extensible...
		intents = [...intents, 'AVERAGE'];
		stats = [...stats, { errors: 0, accuracy: individualScoreAverage, group_accuracy: groupScoreAverage }];
		rows = [...rows, {}];
	}

	return { intents, stats, rows };
};
