import { amber, lightGreen, orange } from '@mui/material/colors';

export const REACT_DIAGRAM_UI = {
	GROUP_NODE_HEADER_BG: amber[700],
	SEL_GROUP_NODE_BG: orange[100],
	SEL_NODE_BG: lightGreen[100],
};

export const CUSTOM_NODE_COLORS = {
	RED: '#ef9a9a',
	PURPLE: '#ce93d8',
	INDIGO: '#9fa8da',
	BLUE: '#90caf9',
	CYAN: '#80deea',
	TEAL: '#80cbc4',
	GREEN: '#a5d6a7',
	YELLOW: '#e6ee9c',
	ORANGE: '#ffcc80',
	BROWN: '#bcaaa4',
	DEFAULT: '#eeeeee',
};

export const NODE_ID_TEMPLATE_COLORS = {
	MSG: CUSTOM_NODE_COLORS.GREEN,
	DEC: CUSTOM_NODE_COLORS.TEAL,
	I: CUSTOM_NODE_COLORS.BLUE,
	INT: CUSTOM_NODE_COLORS.BLUE,
	S: CUSTOM_NODE_COLORS.ORANGE,
	ACTION: CUSTOM_NODE_COLORS.ORANGE,
	SAVE: CUSTOM_NODE_COLORS.YELLOW,
	CALL: CUSTOM_NODE_COLORS.PURPLE,
	FALLBACK: CUSTOM_NODE_COLORS.BROWN,
	NO_INPUT: CUSTOM_NODE_COLORS.BROWN,
	NO_MATCH: CUSTOM_NODE_COLORS.BROWN,
	CUSTOMER_HANGUP: CUSTOM_NODE_COLORS.RED,
};
